import React from "react"
import { useForm } from "react-hook-form"
import { addDays, format, differenceInCalendarDays } from "date-fns"
import { Button, Form, Row } from "react-bootstrap"

const LocationDetailsServiceCloseModal = ({
  setModalData,
  refresh,
  srv,
  location,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      date: format(addDays(new Date(), 1), "yyyy-MM-dd"),
      addPickup: false,
      createInvoice: false,
    },
  })

  const submit = async (formData) => {
    try {
      const openedParams = srv.params.filter((prm) => !prm.hasEndDate)
      if (openedParams.length > 1)
        throw new Error("Znalazałem dwa niezamknięte parametry, zgłoś błąd!")

      // check if service closing date is not lower then opened param date:
      if (openedParams.length === 1) {
        const openedParamInd = srv.params.findIndex((prm) => !prm.hasEndDate)
        if (
          differenceInCalendarDays(
            new Date(formData.date),
            new Date(srv.params[openedParamInd].startDate)
          ) < 0
        )
          throw new Error(
            "Data zamknięcia usługi nie może być wcześniejsza niż rozpoczęcia niezamkniętego parametru"
          )
      }

      // chceck if service closing date is not lower then endDate of any closed param
      const closedParams = srv.params.filter((prm) => prm.hasEndDate)
      let dateError = false
      closedParams.forEach((prm) => {
        if (
          differenceInCalendarDays(
            new Date(formData.date),
            new Date(prm.endDate)
          ) < 0
        )
          dateError = true
      })

      if (dateError)
        throw new Error(
          "Data zamknięcia usługi nie może być wcześniajsza od daty zamknięcia parametru"
        )

      const srvEditBody = {
        location_id: location._id,
        srv: srv,
        param: {
          ...openedParams[0],
          hasEndDate: true,
          endDate: `${format(new Date(formData.date), "yyyy-MM-dd")}T12:00`,
        },
        dirtyFields: ["endDate"], // mock dirty fields to adjust to endpoint
      }

      const fetchParam = async () => {
        const res = await fetch("/locations/editSrvParam?ignoreSDE=true", {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(srvEditBody),
        })
        if (res.status !== 200) {
          console.log(res)
          throw new Error(`Błąd: ${await res.text()}`)
        }
      }

      const fetchSrv = async () => {
        const res = await fetch(
          `/locations/editEntity/${location._id}?isNew=false&entity=services`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              data: { ...srv, state: "zamknięta" },
              dirtyFields: { state: true },
              closeSrv: true,
              closeDate: `${format(
                new Date(formData.date),
                "yyyy-MM-dd"
              )}T12:00`,
            }),
          }
        )
        if (res.status !== 200) {
          console.log(res)
          throw new Error(`Błąd: ${await res.text()}`)
        }
      }

      //! SIZE DOES MATTER! Well, not size, but order. First change srv, then param:
      // when doing other way, service changing overwrites param endDate
      await fetchSrv()
      if (openedParams.length !== 0) await fetchParam()

      setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Zmiany zapisane",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "info",
        headerColor: "danger",
        body: `Błąd: ${err.message}`,
      })
    }
  }
  return (
    <Form onSubmit={handleSubmit(submit)}>
      Data zamknięcia:{" "}
      <Form.Control
        {...register("date")}
        as="input"
        type="date"
        className=""
        autoComplete="chrome-off"
      />
      {/* <Form.Check
        {...register('createInvoice')}
        type="checkbox"
        label="generuj fakturę (DO ZROBIENIA)"
        id="createInvoice" /> */}
      <Row className="justify-content-around mt-3">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button type="submit">Zastosuj</Button>
      </Row>
    </Form>
  )
}
export default LocationDetailsServiceCloseModal
