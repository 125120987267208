import { differenceInCalendarDays } from "date-fns"
import { renderInvMakeAlerts } from "../_shared/invoices/utils"

/**
 * Adds uninvoicedParams array to each service
 * @param {Array} locations all fetched locations
 * @param {Date} date day to check if any srv is uninvoiced
 * @returns {Array} locations with uninvoicedParams added to services
 */

const getUninvoicedSrvs = (locations, date) => {
  return locations.map((location) => {
    location.services = location.services.map((srv) => {
      const uninvoicedParams = srv.params.filter((prm) => {
        // don't show params that start after choosen date:
        if (
          differenceInCalendarDays(new Date(date), new Date(prm.startDate)) < 0
        )
          return false
        // don't show closed params:
        else if (
          prm.hasEndDate &&
          differenceInCalendarDays(
            //( when no invoicedTo it returns NaN)
            new Date(prm.invoicedTo),
            new Date(prm.endDate)
          ) >= 0
        )
          return false
        // SHOW not invoiced, or invoiced before choosen date (or on it):
        else if (
          !prm.invoicedTo ||
          differenceInCalendarDays(new Date(date), new Date(prm.invoicedTo)) >=
            0
        )
          return true
        else return false
      })

      return { ...srv, uninvoicedParams: uninvoicedParams }
    })

    return location
  })
}

const submitInvoices = async (
  formData,
  setModalData,
  locations,
  refresh,
  setLocationsSelected
) => {
  try {
    setModalData({ show: false })
    setLocationsSelected([])
    const body = {
      customers: locations.map((loc) => ({
        customer_id: loc.customer._id,
        locations: [
          {
            location: loc,
            services: loc.services.filter(
              (locSrv) => locSrv.uninvoicedParams.length
            ),
          },
        ],
      })),

      invParams: formData,
    }

    if (formData.joinLocations) {
      // join locations for same customer:
      body.customers = body.customers.reduce((a, r) => {
        const customerInd = a.findIndex(
          (cust) => cust.customer_id === r.customer_id
        )
        // no customer in accumulator array:
        if (customerInd === -1) return [...a, r]
        else {
          const newArr = [...a]
          newArr[customerInd].locations.push(...r.locations)
          return newArr
        }
      }, [])
    }

    // console.log(body)
    //todo use only services with uninvoiced params

    const res = await fetch("/invoices/make", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })

    if (res.status === 409)
      return setModalData({
        show: true,
        type: "alert",
        body: "Ktoś edytował lokalizację i/lub usługi. Odśwież stronę aby pobrać aktualne dane",
      })
    if (
      res.status === 500 ||
      res.status === 403 // I use 403 not only for perm check (as in most cases), but also when invoicing params are wrong
    )
      return setModalData({
        show: true,
        type: "alert",
        body: await res.text(),
      })
    if (res.status !== 200) throw res
    else {
      const resJSON = await res.json()
      setModalData({
        show: true,
        type: "info",
        xl: true,
        headerColor: "green",
        header: "Sukces",
        onHide: refresh,
        body: (
          <p>
            Faktura wygenerowana
            <br />
            {resJSON.msg.length ? renderInvMakeAlerts(resJSON.msg) : null}
          </p>
        ),
      })
    }
  } catch (err) {
    console.log(err)
    setModalData({ show: true, type: "alert", body: "Błąd fakturowania" })
  }
}

const sorting = (locations, sortBy) => {
  return locations.sort((a, b) => {
    let aVal = ""
    let bVal = ""

    switch (sortBy.field) {
      case "customerName":
        aVal =
          a.customer?.shortName || a.customer?.name || a.customer?.surname || ""
        bVal =
          b.customer?.shortName || b.customer?.name || b.customer?.surname || ""
        break
      case "locName":
        aVal = a?.name
        bVal = b?.name
        break
      default:
        break
    }

    if (sortBy.order === 1) return aVal.localeCompare(bVal)
    else return bVal.localeCompare(aVal)
  })
}

export { getUninvoicedSrvs, submitInvoices, sorting }
