import React, { useState, useContext, useCallback } from "react"
import { Table, Button, Col, Row, Form } from "react-bootstrap"
import { useDropzone } from "react-dropzone"

import MetaContext from "../../../contexts/metaContext"
import { EmployeesDetailsCtx } from "../Fetch"

import { dateParse } from "../../../utils/formats"

import { uploadFile, deleteFile } from "../Edit/submit"

const EmployeesDetailsFilesModal = (props) => {
  const meta = useContext(MetaContext)
  const ctx = useContext(EmployeesDetailsCtx)
  const [file, setFile] = useState()
  const [showFileNameInput, setShowFileNameInput] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [fileExtension, setFileExtension] = useState(null)
  const [errors, setErrors] = useState(false)
  const [fileNameError, setFileNameError] = useState(false)

  const onDrop = useCallback((files) => {
    if (files.length !== 1) return setErrors(true)
    if (files[0].name.includes("/")) return setFileNameError(true)
    else {
      setErrors(false)
      setFile(files[0])
      //extracting filename (it can be changed by user):
      setFileName(files[0].name.slice(0, files[0].name.lastIndexOf(".")))
      //extracting file extension (it remains):
      setFileExtension(files[0].name.slice(files[0].name.lastIndexOf(".")))
      setShowFileNameInput(true)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    },
    multiple: false,
  })

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="3">
            <Row>
              <Col>Załączniki</Col>
              {/* Adding only by board users and superiors, deleting only by board (or superior with perm.employees.d) */}
              {ctx.user.type.board ||
              (ctx.employee.superior.includes(ctx.user._id) &&
                ctx.user.perm.employees.e) ? (
                <Col xs={{ offset: 6 }}>
                  <Button
                    variant="secondary"
                    className="m-0"
                    style={{ background: isDragActive ? "#69c07b" : null }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? "Dodaj" : "Dodaj"}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        {errors && (
          <tr>
            <td className="bg-warning" colSpan="3">
              Wybierz <u>jeden</u> plik.
            </td>
          </tr>
        )}
        {fileNameError && (
          <tr>
            <td className="bg-warning" colSpan="3">
              W nazwie nie może występować znak "/"
            </td>
          </tr>
        )}
        {showFileNameInput ? (
          <tr>
            <td colSpan="2">
              <Row>
                <p style={{ fontSize: "0.8rem", marginBottom: 0 }}>Nazwa:</p>
              </Row>
              <Row>
                <Form.Control
                  value={fileName}
                  onChange={(e) => {
                    e.preventDefault()

                    if (e.target.value.includes("/"))
                      return setFileNameError(true)
                    else {
                      setFileNameError(false)
                      setFileName(e.target.value)
                    }
                  }}
                />
              </Row>
            </td>
            <td>
              <Button
                variant="secondary"
                className="mt-2"
                disabled={errors || fileNameError}
                onClick={() =>
                  uploadFile(
                    file,
                    props.entityType,
                    `${fileName}${fileExtension}`,
                    props.entity._id,
                    ctx.employee._id,
                    ctx.setModalData,
                    ctx.refresh
                  )
                }
              >
                Zapisz
              </Button>
            </td>
          </tr>
        ) : null}
        {/* render files: */}
        {props.entity.files?.length ? (
          <tr className="bg-primaryLight">
            <td>Nazwa</td>
            <td>Data dodania</td>
            {/* delete button is only available for board members and users with per.employees.d */}
            {ctx.user.type.board ||
            (ctx.employee.superior.includes(ctx.user._id) &&
              ctx.user.perm.employees.d) ? (
              <td></td>
            ) : null}
          </tr>
        ) : (
          <tr>
            <td>Brak załączników</td>
          </tr>
        )}
        {props.entity.files.map((file) => {
          return (
            <tr key={`file-row-${file.name}`}>
              <td
                className="clickable"
                onClick={() => {
                  let path = ""
                  if (file.s3)
                    path = `${meta.filesServer}/s3-files${
                      meta.devEnv ? "/dev" : ""
                    }/employees/${ctx.employee._id}/${props.entityType}/${
                      props.entity._id
                    }/${file.name}`
                  else
                    path = `${meta.filesServer}/files/employees/${ctx.employee._id}/${props.entityType}/${props.entity._id}/${file.name}`

                  return window.open(path, "_blank")
                }}
              >
                {file.name}
              </td>
              <td>{dateParse(file.date)}</td>
              {/* delete button is only available for board members and users with per.employees.d */}
              {ctx.user.type.board ||
              (ctx.employee.superior.includes(ctx.user._id) &&
                ctx.user.perm.employees.d) ? (
                <td>
                  <Button
                    variant="danger"
                    onClick={() =>
                      ctx.setModalData({
                        show: true,
                        type: "confirm",
                        body: "Usunąć załącznik?",
                        func: () =>
                          deleteFile(
                            props.entityType,
                            props.entity._id,
                            ctx.employee._id,
                            file._id,
                            file.name,
                            ctx.setModalData,
                            ctx.refresh
                          ),
                      })
                    }
                  >
                    Usuń
                  </Button>
                </td>
              ) : null}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
export default EmployeesDetailsFilesModal
