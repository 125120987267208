import React from "react"

import Container from "react-bootstrap/Container"
import Table from "react-bootstrap/Table"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const ConsumptionDetailsRender = (props) => {
  // maps through main goods rows and creates array of unique headers (including 'date')
  const mainGoodsHeaders = []
  const createMainGoodsHeaders = () => {
    props.consumption.consumptionDetails.mainGoodsRows.map((row) => {
      const rowGoods = Object.keys(row)
      rowGoods.map((good) => {
        if (good === "number") return null
        else if (!mainGoodsHeaders.includes(good)) {
          return mainGoodsHeaders.push(good)
        } else return null
      })
      return null
    })
  }
  createMainGoodsHeaders()

  return (
    <Container>
      <Row>
        <Col xs="6">
          <h2>Podsumowanie</h2>
          <Table bordered>
            <thead>
              <tr>
                <th>Nazwa</th>
                <th>Jednostka</th>
                <th>Zużycie</th>
              </tr>
            </thead>
            <tbody>
              {props.consumption.consumptionDetails.consumptionsSums.map(
                (consumption) => {
                  const style =
                    consumption.type === "main"
                      ? { backgroundColor: "#d1ffff" }
                      : {}
                  if (consumption.type === "main") {
                    return (
                      <tr style={style} key={consumption._id}>
                        <td>{consumption.name}</td>
                        <td>{consumption.unit}</td>
                        <td>{consumption.quantity}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr style={style} key={consumption._id}>
                        <td>{consumption.name}</td>
                        <td>{consumption.unit}</td>
                        <td>{consumption.quantity}</td>
                      </tr>
                    )
                  }
                }
              )}
            </tbody>
          </Table>
        </Col>
        <Col xs="4">
          <h2>Informacje:</h2>
          <p>
            <b>Dodał: </b>
            {props.consumption.user_id.name}
            <br />
            <b>Oddział:</b>
            {props.consumption.consumptionHeader.branch}
          </p>
          {props.consumption.additionalInfo.length ? (
            <p className="keep-br">
              <b>Dodatkowe informacje:</b> {props.consumption.additionalInfo}
            </p>
          ) : null}
        </Col>
      </Row>
      <h2>Zużycie głównych materiałów</h2>
      <Table className="mb-5" bordered>
        <thead>
          <tr>
            <th>Data</th>
            {mainGoodsHeaders.map((header) => {
              if (header === "number" || header === "date") return null
              else return <th key={`th-${header}`}>{header}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {props.consumption.consumptionDetails.mainGoodsRows.map((row, i) => {
            return (
              <tr key={`tr-${i}-${row}`}>
                {mainGoodsHeaders.map((header) => {
                  if (header !== "number") {
                    return <td key={`td-${i}-${header}`}>{row[header]}</td>
                  } else return null
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default ConsumptionDetailsRender
