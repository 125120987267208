import React from "react"
import { Container, Form, Button } from "react-bootstrap"

const BranchConsumptionForm = (props) => {
  const handleSubmit = (e) => {
    //when trying to send event to parent component I got error due to browerser cleaning event object in midtime
    e.preventDefault()
    const data = {
      year: e.target.year.value,
      month: e.target.month.value,
      branch: e.target.branch.value,
    }
    props.handleSubmit(data)
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit} inline className="mt-4">
        <Form.Label>
          Rok
          <Form.Control
            name="year"
            type="number"
            defaultValue={props.formData.year}
          />
        </Form.Label>
        <Form.Label>
          Miesiąc
          <Form.Control
            name="month"
            type="number"
            defaultValue={props.formData.month}
          />
        </Form.Label>
        <Form.Control as="select" name="branch">
          <option value="all">Wszystkie</option>
          {props.formData.branches.map((branch) => {
            return (
              <option key={branch} value={branch}>
                {branch}
              </option>
            )
          })}
        </Form.Control>
        <Button type="submit">Wczytaj</Button>
      </Form>
    </Container>
  )
}
export default BranchConsumptionForm
