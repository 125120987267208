import React, { useContext, useState } from "react"
import { Row, Table, Col, Button, Container, Form } from "react-bootstrap"
import { format } from "date-fns"

import HistoryTable from "../../utils/HistoryTable"

import LocationsDetailsEditNoteModal from "../Edit/modals/LocationsDetailsEditNoteModal"

import { LocationCtx } from "../Fetch"
import sortByCreationDate from "../../utils/sortByCreationDate"

// import LocationsDetailsEditContactModal from "../Edit/modals/LocationsDetailsEditContactModal"

const LocationsDetailsNotesTable = (props) => {
  const { location, setModalData, refresh, user } = useContext(LocationCtx)

  const [showOneTimeNotes, setShowOneTimeNotes] = useState(false)

  const handleStateChange = async (note) => {
    try {
      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=false&entity=notes`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: {
              _id: note._id,
              state: note.state === "archiwum" ? "aktywna" : "archiwum",
            },
            dirtyFields: { state: true },
          }),
        }
      )
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res

      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: `Notatka ${
          note.state === "archiwum" ? "przywrócona" : "archiwizowana"
        }`,
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd komunikacji z serwerem",
      })
    }
  }

  const renderNoteDetails = (note) => {
    return (
      <Container>
        <Row className="justify-content-between mb-3">
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                header: "Historia notatki",
                body: <HistoryTable history={note.history} />,
              })
            }
          >
            Historia
          </Button>
          {user.perm.customers.e && location.state === "aktywna" && (
            <Button variant="warning" onClick={() => handleStateChange(note)}>
              {note.state === "aktywna" ? "Archiwizuj" : "Przywróć"}
            </Button>
          )}
          {note.state !== "archiwum" &&
            user.perm.customers.e &&
            location.state === "aktywna" && (
              <Button
                variant="secondary"
                className="mr-3"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    hideFooter: true,
                    header: "Edycja notatki",
                    body: (
                      <LocationsDetailsEditNoteModal
                        isNew={false}
                        note={note}
                      />
                    ),
                  })
                }
              >
                Edytuj
              </Button>
            )}
        </Row>
        <Table>
          <tbody>
            <tr className="fixed-values-table">
              <td colSpan="2">Typ</td>
            </tr>
            <tr>
              <td colSpan="2">
                {note.type}
                {note.subtype ? ` - ${note.subtype}` : null}
                {note.eventDate
                  ? ` (${format(new Date(note.eventDate), "yyyy-MM-dd")})`
                  : null}
              </td>
            </tr>
            <tr className="fixed-values-table">
              <td>Data utworzenia</td>
              <td>Użytkownik</td>
            </tr>
            <tr>
              <td>
                {format(new Date(note.history[0].date), "yyyy-MM-dd HH:mm")}
              </td>
              <td>{note.history[0].user.fullName}</td>
            </tr>
            <tr className="fixed-values-table">
              <td>Data ostatniej modyfikacji</td>
              <td>Użytkownik</td>
            </tr>
            <tr>
              <td>
                {format(
                  new Date(note.history[note.history.length - 1].date),
                  "yyyy-MM-dd HH:mm"
                )}
              </td>
              <td>{note.history[note.history.length - 1].user.fullName}</td>
            </tr>
            <tr className="fixed-values-table">
              <td colSpan="2">Treść</td>
            </tr>
            <tr>
              <td colSpan="2">{note.body}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    )
  }
  return (
    <Table id="notesTable">
      <thead>
        <tr>
          <th colSpan="6">
            <Row>
              <Col>Notatki lokalizacji</Col>
              <Col>
                <Button
                  variant="light"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: "Notatki archiwalne",
                      body: <LocationsDetailsNotesTable filter="archiwum" />,
                    })
                  }
                >
                  Archiwum
                </Button>
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  label="Pokaż fakturowe jednorazowe"
                  id="checkbox-showOneTimeNotes"
                  onClick={(e) => setShowOneTimeNotes(e.target.checked)}
                />
              </Col>
              {location.state === "aktywna" && (
                <Col>
                  <Row>
                    <Button
                      variant="secondary"
                      className="ml-auto mr-3"
                      onClick={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          hideFooter: true,
                          header: "Nowa notatka",
                          body: <LocationsDetailsEditNoteModal isNew={true} />,
                        })
                      }
                    >
                      Dodaj
                    </Button>
                  </Row>
                </Col>
              )}
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Rodzaj</td>
          <td className="fixed-values-table">Autor</td>
          <td className="fixed-values-table">Utworzenie</td>
          <td className="fixed-values-table">Modyfikacja</td>
          <td className="fixed-values-table">Treść</td>
        </tr>
        {location.notes
          .filter(
            (note) =>
              note.state === props.filter &&
              // when !showOneTimeNotes -> show only no one-time notes
              (showOneTimeNotes || note.subtype !== "jednorazowa")
          )
          .sort(sortByCreationDate)
          .map((note) => {
            return (
              <tr
                key={`notes-table-${note._id}`}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    header: "Szczegóły notatki",
                    body: renderNoteDetails(note),
                  })
                }
              >
                <td>
                  {note.type}
                  {note.subtype ? ` - ${note.subtype}` : null}
                  {note.eventDate
                    ? ` (${format(new Date(note.eventDate), "yyyy-MM-dd")})`
                    : null}
                </td>
                <td>{note.history[0].user.fullName}</td>
                <td>{format(new Date(note.history[0].date), "yyyy-MM-dd")}</td>
                <td>
                  {format(
                    new Date(note.history[note.history.length - 1].date),
                    "yyyy-MM-dd"
                  )}
                </td>
                <td>{note.body}</td>
              </tr>
            )
          })}
      </tbody>
    </Table>
  )
}
export default LocationsDetailsNotesTable
