import React from "react"
import { Container, Table, Row, Button } from "react-bootstrap"

const HistoryTable = (props) => {
  const history = [...props.history].sort(
    (a, b) => Date.parse(new Date(b.date)) - Date.parse(new Date(a.date))
  )
  return (
    <Container fluid>
      {props.goBack ? (
        <Row>
          <Button onClick={props.goBack}>Wróć</Button>
        </Row>
      ) : null}
      <Table id="historyTable">
        <tbody>
          <tr className="fixed-values-table">
            <td>Rodzaj</td>
            <td>Użytkownik</td>
            <td>Data</td>
            <td>Opis</td>
          </tr>
          {history.map((row) => {
            return (
              <tr key={`history-row-${row._id}`}>
                <td>{row.type}</td>
                <td>{row.user?.fullName || "brak danych"}</td>
                <td>{row.date}</td>
                <td className="keep-br">
                  <div className="text-break">{row.description}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default HistoryTable
