import React, { useContext, useState, useMemo } from "react"

import {
  differenceInCalendarDays,
  format,
  isBefore,
  isAfter,
  startOfDay,
  endOfDay,
  min,
  max,
} from "date-fns"
import { useForm } from "react-hook-form"
import { Table, Form, Button, Spinner } from "react-bootstrap"

import { LocationCtx } from "../Fetch"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"

const LocationsDetailsJobsTab = () => {
  const {
    jobs,

    jobsDates,
    setJobsDates,
    areJobsLoading,
  } = useContext(LocationCtx)

  // const [jobsReady, setJobsReady] = useState(
  //   [...jobs].sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
  //   )

  const [filters, setFilters] = useState({
    state: "all",
    type: "all",
    startDate: jobsDates.start,
    endDate: jobsDates.end,
    locSrv: "all",
  })

  const jobsReady = useMemo(() => {
    return handleFiltering(jobs, filters, jobsDates, setJobsDates)
  }, [filters, jobs, jobsDates, setJobsDates])

  const fetchedJobsBorderDates = useMemo(() => {
    const jobsDates = jobs.map((job) => new Date(job.date))
    return { first: min(jobsDates), last: max(jobsDates) }
  }, [jobs])

  return (
    <>
      <JobsTableFilters
        // dateFilters={dateFilters}
        // setDateFilters={setDateFilters}
        // setJobsReady={setJobsReady}
        setFilters={setFilters}
        filters={filters}
      />
      {areJobsLoading ? (
        <>
          <Spinner animation="border" />
          Pobieram dane
        </>
      ) : (
        <JobsTable
          jobsReady={jobsReady}
          fetchedJobsBorderDates={fetchedJobsBorderDates}
        />
      )}
    </>
  )
}

const JobsTableFilters = ({
  dateFilters,
  setDateFilters,
  setJobsReady,
  setFilters,
  filters,
}) => {
  const { location, configs } = useContext(LocationCtx)

  const { register, handleSubmit } = useForm({
    defaultValues: filters,
  })

  const handleFilters = (formData) => {
    setFilters(formData)
  }

  return (
    <Form inline onSubmit={handleSubmit(handleFilters)} className="mt-3 ml-5">
      stan:{" "}
      <Form.Control
        {...register("state")}
        as="select"
        className=""
        autoComplete="chrome-off"
      >
        <option value="all">wszystkie</option>
        {configs.jobState.map((state) => (
          <option key={`tableFilterState-${state}`}>{state}</option>
        ))}
      </Form.Control>
      typ:
      <Form.Control
        {...register("type")}
        as="select"
        className=""
        autoComplete="chrome-off"
      >
        <option value="all">wszystkie</option>
        {configs.jobType.map((type) => (
          <option key={`tableFilterType-${type}`}>{type}</option>
        ))}
      </Form.Control>
      data OD:
      <Form.Control
        {...register("startDate")}
        as="input"
        type="date"
        className=""
        autoComplete="chrome-off"
      />
      data DO:
      <Form.Control
        {...register("endDate")}
        as="input"
        type="date"
        className=""
        autoComplete="chrome-off"
      />
      usługa:
      <Form.Control
        {...register("locSrv")}
        as="select"
        className=""
        autoComplete="chrome-off"
      >
        <option value="all">wszystkie</option>
        {location.services.map((locSrv) => (
          <option
            value={locSrv._id}
            key={`locSrv-selectOption-${locSrv._id}`}
          >{`${locSrv?.locSrvNo || "?"}. ${
            locSrv?.name || "ZGŁOŚ BŁĄD"
          }`}</option>
        ))}
      </Form.Control>
      <Button type="submit">Filtruj</Button>
    </Form>
  )
}

const JobsTable = ({ jobsReady, fetchedJobsBorderDates }) => {
  const { jobs, setModalData, location, user, configs, refresh, cars } =
    useContext(LocationCtx)
  if (!jobs || !jobs.length) return "brak danych"

  const renderJobsAlert = () => {
    const alertBody = []

    if (
      location.jobsBorderDates &&
      differenceInCalendarDays(
        new Date(location.jobsBorderDates.first),
        new Date(fetchedJobsBorderDates.first)
      )
    )
      alertBody.push(
        `pierwsze zadanie dla lokalizacji: ${format(
          new Date(location.jobsBorderDates.first),
          "yyyy-MM-dd"
        )}`
      )
    if (
      location.jobsBorderDates &&
      differenceInCalendarDays(
        new Date(location.jobsBorderDates.last),
        new Date(fetchedJobsBorderDates.last)
      )
    )
      alertBody.push(
        `ostatnie zadanie dla lokalizacji: ${format(
          new Date(location.jobsBorderDates.last),
          "yyyy-MM-dd"
        )}`
      )

    if (alertBody.length)
      return (
        <tr className="bg-warning">
          <td colSpan="6">
            Nie wszystkie zadania są wyświetlane: {alertBody.join(", ")}{" "}
            <Button
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  body: (
                    <p>
                      Aby przyspieszyć działanie programu, przy otwarciu
                      lokalizacji pobierane są tylko zadania na 3 miesiące
                      wstecz i 1 miesiąc do przodu. Reszta zadań ładowana jest
                      na bieżąco podczas przesuwania kalendarza lub zmiany
                      filtrów. Ten alert sygnalizuje, że nie wszystkie zadania
                      zostały pobrane.
                    </p>
                  ),
                })
              }
            >
              ?
            </Button>
          </td>
        </tr>
      )
  }

  return (
    <Table>
      <tbody id="jobsTable">
        <tr>
          <td className="fixed-values-table">typ</td>
          <td className="fixed-values-table">data</td>
          <td className="fixed-values-table">usługi</td>
          <td className="fixed-values-table">stan</td>
          <td className="fixed-values-table">kierowca</td>
          <td className="fixed-values-table">uwagi</td>
        </tr>
        {renderJobsAlert()}
        {jobsReady.map((job, i) => {
          const services = job.services.map((jobSrv) =>
            location.services.find(
              (locSrv) => locSrv._id === jobSrv.locationServiceRef
            )
          )

          return (
            <tr
              key={`job-row-${job._id}`}
              className="clickable"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: `Szczegóły zadania (${location.name})`,
                  body: (
                    <JobDetailsModal
                      job={job}
                      setModalData={setModalData}
                      locationName={location.name}
                      location={location}
                      user={user}
                      configs={configs}
                      refresh={refresh}
                      parent="location"
                      cars={cars}
                    />
                  ),
                })
              }
              id={`jobsRow-${i}`}
            >
              <td>{job.type}</td>
              <td>{format(new Date(job.date), "yyyy-MM-dd")}</td>
              <td>
                {services
                  .map(
                    (srv) =>
                      `${srv?.locSrvNo || "?"}. ${srv?.name || "ZGŁOŚ BŁĄD"}`
                  )
                  .join(", ")}
              </td>
              <td>{job.state}</td>
              <td id="jobsTable-driverNameCell">
                {job.driver?.fullName || "brak"}
              </td>
              <td>{job.comments}</td>
            </tr>
          )
        })}
        {renderJobsAlert()}
        {!jobsReady.length ? (
          <tr>
            <td>Brak pasujących zadań</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  )
}

const handleFiltering = (allJobs, filters, jobsDates, setJobsDates) => {
  // copy jobs:
  let jobs = [...allJobs]

  //* check if there is need to fetch missing jobs (if new filter dates exclude already fetched = jobsDates) -->
  // if fetching missing jobs needed -> change jobsDates, useEffect from Fetch.js will handle it
  let shouldUpdate = false
  const newJobsDates = { ...jobsDates, exclude: { ...jobsDates } }
  if (
    isBefore(new Date(filters.startDate), startOfDay(new Date(jobsDates.start)))
  ) {
    newJobsDates.start = format(new Date(filters.startDate), "yyyy-MM-dd")
    shouldUpdate = true
  }
  if (isAfter(new Date(filters.endDate), endOfDay(new Date(jobsDates.end)))) {
    newJobsDates.end = format(new Date(filters.endDate), "yyyy-MM-dd")
    shouldUpdate = true
  }
  if (shouldUpdate) setJobsDates(newJobsDates)
  //*<--

  //* run normal filters:
  // DATE filter
  //   start date:
  jobs = jobs.filter(
    (job) =>
      differenceInCalendarDays(
        new Date(job.date),
        new Date(filters.startDate)
      ) >= 0
  )
  //  end Date:
  jobs = jobs.filter(
    (job) =>
      differenceInCalendarDays(new Date(job.date), new Date(filters.endDate)) <=
      0
  )
  // STATE filter
  if (filters.state !== "all")
    jobs = jobs.filter((job) => job.state === filters.state)
  // TYPE filter
  if (filters.type !== "all")
    jobs = jobs.filter((job) => job.type === filters.type)
  // SERVICE filter
  if (filters.locSrv !== "all")
    jobs = jobs.filter((job) => {
      const jobLocSrvsRefs = job.services.map(
        (jobSrv) => jobSrv.locationServiceRef
      )
      if (jobLocSrvsRefs.find((ref) => ref === filters.locSrv)) return true
      else return false
    })

  return jobs
}

export default LocationsDetailsJobsTab
