import React, { useState, useEffect, useContext } from "react"
import { useForm, useFieldArray, Controller } from "react-hook-form"

import { Redirect } from "react-router-dom"

import UserContext from "../../contexts/userContext"
import {
  Table,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap"

import MyModal from "../../utils/Modals"

import { CustomerDetailsForm, DeliveryForm } from "./forms"
import { customValidation, dataSanitize } from "../utils"
import { TransactionHeadersTable } from "../Details/Tables"
import { RenderPriceRow } from "./utils"

const TransactionsEditRender = (props) => {
  const user = useContext(UserContext)
  const [showErrors, setShowErrors] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [modalData, setModalData] = useState({ show: false })

  const roundToHundredth = (n) => {
    return Math.round(n * 100) / 100
  }

  const orderDetailsDefault = {
    orderDetails: {
      prices: props.prices.map((price) => {
        const priceObj = {
          ...price,
          quantity: 0,
          netPrice: roundToHundredth(price.netPrice),
          grossPrice: roundToHundredth(price.netPrice * (price.vat + 1)),
        }
        return priceObj
      }),
    },
    //preventing NaN when choosing our delivery but not yet entered prices:
    realizationDetails: { deliveryDetails: { netPrice: 0, grossPrice: 0 } },
  }

  //setting default values for dev env (only for kris to prevent setting unpermitted branch):
  //* turned off to prevent messing with cypress
  // if (props.isDevEnv && user.name === "kris") {
  //   orderDetailsDefault.seller = "Clipper"
  //   orderDetailsDefault.realizationDetails.branch = "Warszawa"
  //   orderDetailsDefault.realizationDetails.ourDelivery = false
  //   orderDetailsDefault.customerType = "company"
  //   orderDetailsDefault.companyData = {
  //     name: new Date().toLocaleString(),
  //     vatNumber: "111",
  //     street: "asd",
  //     postal: "00001",
  //     city: "jakieś",
  //   }

  //   orderDetailsDefault.paymentDetails = {
  //     proforma: false,
  //     method: "przelew",
  //     saleDocument: "faktura",
  //     deadline: "przedpłata",
  //   }
  // }

  const nF = (n) =>
    new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: "PLN",
    }).format(n)

  const [prices] = useState(props.prices)

  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: props.edit ? props.transaction : orderDetailsDefault,
  })

  const { fields } = useFieldArray({ control, name: "orderDetails.prices" })

  const customerType = watch("customerType", false)

  const [netValue, setNetValue] = useState(0)
  const [grossValue, setGrossValue] = useState(0)

  const netValuesArr = prices.map((price, i) =>
    roundToHundredth(
      watch(`orderDetails.prices.${i}.quantity`) *
        watch(`orderDetails.prices.${i}.netPrice`)
    )
  )
  const grossValuesArr = prices.map((price, i) =>
    roundToHundredth(
      watch(`orderDetails.prices.${i}.quantity`) *
        watch(`orderDetails.prices.${i}.grossPrice`)
    )
  )

  useEffect(() => {
    setGrossValue(grossValuesArr.reduce((a, b) => a + b))

    setNetValue(
      netValuesArr.reduce((a, b) => Number.parseFloat(a) + Number.parseFloat(b))
    )
  }, [netValuesArr, grossValuesArr])

  const onSubmit = async (data) => {
    try {
      if (!user.type.admin && !user.perm.transaction.w) {
        return alert("brak uprawnień")
      }
      if (
        props.edit &&
        !(user.perm.transaction.e || user.perm.transactionsList.e)
      ) {
        return alert("brak uprawnień")
      }

      data.orderDetails.netValueTotal = netValue

      data.transactionsVer = 2 //ver 2 (on clipERP 0.4.4) changed userName to user (with _id and populating)
      // prevent changing user name while editing:
      if (!props.edit) data.user = user._id

      const validation = await customValidation(data, netValue)
      if (validation.length) {
        setShowErrors(validation)
        return
      }
      const clearData = await dataSanitize(data)

      // when editing -> preserve _id, else (=new transaction) add "oczekująca" state
      if (props.edit) clearData._id = props.transaction._id
      else clearData.state = "oczekująca"
      const res = await fetch(
        props.edit ? "/transactions/edit" : "/transactions/new",
        {
          method: props.edit ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(clearData),
        }
      )
      const resJSON = await res.json()
      if (res.status === 200) {
        setModalData({
          id: "success",
          show: true,
          type: "info",
          body: props.edit
            ? "Dane zaktualizowane"
            : "Transakcja zarejestrowana",
          onHide: () => setRedirect(resJSON._id),
        })
        // when changing from alert to modal remember to fire redirect on modal close, not here
        // setRedirect(resJSON._id)
      } else {
        if (resJSON.msg) alert(resJSON.msg)
        else alert(resJSON)
      }
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Container className="mt-2">
      {redirect && <Redirect to={`/transaction/${redirect}`} />}
      {/* {props.isDevEnv && user.name === "kris" ? (
        <p className="bg-warning">
          {
            "DEV env && kris -> default values set, hit submit to reveal all (it won't be submitted since there are no quantities selected)"
          }
        </p>
      ) : null} */}
      {props.edit ? (
        <>
          <Row>
            <TransactionHeadersTable transaction={props.transaction} />
          </Row>
          <Row className="mt-2">
            <Button onClick={() => setRedirect(props.transaction._id)}>
              Wróć
            </Button>
          </Row>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row className="align-content-start mt-2">
          <Col>
            <Form.Control
              {...register("realizationDetails.branch", { required: true })}
              as="select"
              defaultValue={user.defaultBranch ? user.defaultBranch : "plug"}
              autoFocus
            >
              <option value="plug" hidden>
                Oddział
              </option>
              {props.branches.map((branch) => {
                return (
                  <option value={branch} key={`sel-opt-${branch}`}>
                    {branch}
                  </option>
                )
              })}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              {...register("seller", { required: true })}
              as="select"
              defaultValue="plug"
              autoFocus
            >
              <option value="plug" hidden>
                Sprzedający
              </option>
              <option value="Ecoloxx">Ecoloxx</option>
              <option value="Clipper">Clipper</option>
            </Form.Control>
          </Col>
          <Col>
            <Form.Check
              {...register("customerType")}
              type="radio"
              value="privat"
              id="privat"
              label="Os. fiz."
            />

            <Form.Check
              {...register("customerType")}
              type="radio"
              value="company"
              id="company"
              label="Firma/działalność"
            />
          </Col>
        </Row>
        {customerType ? (
          <CustomerDetailsForm
            register={register}
            customerType={customerType}
            setValue={setValue}
            watch={watch}
            setModalData={props.setModalData}
          />
        ) : null}
        <Row>
          <Table bordered className="mt-3">
            <thead>
              <tr>
                <th className="fixed-values-table">Nazwa</th>
                <th className="fixed-values-table">Jm.</th>
                <th>Liczba</th>
                <th className="fixed-values-table">Cena jedn. netto</th>
                <th className="fixed-values-table">VAT</th>
                <th className="fixed-values-table">Cena jedn. brutto</th>

                <th>Wartość netto</th>

                <th>Wartość brutto</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((price, i) => (
                <RenderPriceRow
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  Controller={Controller}
                  control={control}
                  price={price}
                  i={i}
                  user={user}
                  key={price.id}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="hidden-cell" colSpan={6}></td>
                <td id="totalNetValue">{nF(netValue)}</td>
                <td>{nF(grossValue)}</td>
              </tr>
              {watch("realizationDetails.ourDelivery") === "true" ? (
                <tr>
                  <td className="hidden-cell" colSpan={5}></td>
                  <td>Z transportem:</td>
                  <td>
                    {nF(
                      netValue +
                        parseFloat(
                          watch("realizationDetails.deliveryDetails.netPrice")
                        )
                    )}
                  </td>
                  <td>
                    {nF(
                      grossValue +
                        parseFloat(
                          watch("realizationDetails.deliveryDetails.grossPrice")
                        )
                    )}
                  </td>
                </tr>
              ) : null}
            </tfoot>
          </Table>
        </Row>

        <Row>
          <Col>
            {/* payment details */}

            <Form.Check
              {...register("paymentDetails.proforma")}
              type="checkbox"
              label="proforma?"
              className="ml-2 mb-2"
              id="proforma"
            />

            <Form.Control
              {...register("paymentDetails.method")}
              as="select"
              defaultValue="plug"
            >
              <option value="plug" hidden>
                Forma płatności
              </option>
              {props.configs.paymentMethods.map((method) => (
                <option value={method} key={`paymentMethods-option-${method}`}>
                  {method}
                </option>
              ))}
            </Form.Control>

            <Form.Control
              {...register("paymentDetails.saleDocument")}
              as="select"
              defaultValue="plug"
            >
              <option value="plug" hidden>
                Dokument
              </option>
              {props.configs.saleDocuments.map((document) => (
                <option
                  value={document}
                  key={`saleDocuments-option-${document}`}
                >
                  {document}
                </option>
              ))}
            </Form.Control>
            <Form.Control
              {...register("paymentDetails.deadline")}
              as="select"
              defaultValue="plug"
            >
              <option value="plug" hidden>
                Termin płatności
              </option>
              {props.configs.paymentDeadlines.map((deadline) => (
                <option value={deadline} key={`deadlines-option-${deadline}`}>
                  {deadline}
                </option>
              ))}
            </Form.Control>
            {watch("paymentDetails.deadline") === "inny - data" ? (
              <Form.Control
                {...register("paymentDetails.deadlineString")}
                type="date"
              />
            ) : null}
            {watch("paymentDetails.deadline") === "inny - liczba dni" ? (
              <Form.Control
                {...register("paymentDetails.deadlineString")}
                placeholder="termin płatności"
              />
            ) : null}
            {/* delivery */}
            <Form.Control
              {...register("realizationDetails.ourDelivery")}
              as="select"
              defaultValue="plug"
            >
              <option value="plug" hidden>
                Dostawa
              </option>
              <option value={false}>Klienta</option>
              <option value={true}>Nasza</option>
            </Form.Control>
          </Col>
          <Col xs="8">
            {/* Additional info */}
            <Form.Control
              {...register("realizationDetails.additionalInfo")}
              as="textarea"
              cols={80}
              rows="6"
              placeholder="Dodatkowe informacje"
            ></Form.Control>
          </Col>
        </Row>
        {watch("realizationDetails.ourDelivery") === "true" && (
          <DeliveryForm
            register={register}
            setValue={setValue}
            Controller={Controller}
            control={control}
          />
        )}
        <Row>
          <Alert
            className="mt-2 ml-auto"
            variant="danger"
            style={{
              // backgroundColor: "#ef5350",
              display: showErrors ? "block" : "none",
            }}
          >
            <em>Błędy w formularzu:</em>

            <ol>
              {showErrors
                ? showErrors.map((error) => {
                    return <li key={error}>{error}</li>
                  })
                : null}
            </ol>
          </Alert>
        </Row>
        <Row className="mt-3">
          {props.edit && props.transaction.state !== "anulowana" ? (
            <Button
              variant="warning"
              className="mr-auto"
              onClick={() => props.handleCancel()}
            >
              Anuluj transakcję
            </Button>
          ) : null}

          {props.edit && user.perm.transactionsList.d ? (
            <Button
              variant="danger"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "delete",
                  header: "Potwierdź usunięcie transakcji",
                  body: "Czy jesteś pewien, że chcesz usunąć transakcję?",
                  handleDelete: props.handleDelete,
                })
              }
            >
              Usuń
            </Button>
          ) : null}
          <Button
            id="submit-btn"
            type="submit"
            variant="secondary"
            className="button justify-self-end  ml-auto"
          >
            Zapisz
          </Button>
          {/* {user.type.admin ? <DevTool control={control} /> : null} */}
        </Row>
      </Form>
      {/* modal used for confirmation after updating/creating transaction, for showing customers list I use modal from Fetch.js (larger) */}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default TransactionsEditRender
