import React, { useState, useRef } from "react"
import {
  Table,
  Button,
  Spinner,
  Popover,
  SplitButton,
  Dropdown,
  Overlay,
  Row,
} from "react-bootstrap"

import {
  MdLocalPhone,
  MdTextsms,
  MdAlternateEmail,
  MdAccountBalance,
  MdPause,
} from "react-icons/md"
import {
  IoEllipsisHorizontalSharp,
  IoDocumentOutline,
  IoPlay,
} from "react-icons/io5"
import { ImNewTab, ImPlus } from "react-icons/im"
import { differenceInCalendarDays, format, setHours } from "date-fns"
import { DebtCollectionNoteModal } from "./modal"
import {
  handleSortClick,
  renderPaymentDocs,
  renderSortArrow,
} from "./tableUtils"

const DebtCollectionTable = ({
  customers,
  setModalData,
  refresh,
  tablePage,
  setTablePage,
  totalPages,
  file,
  sortBy,
  setSortBy,
  pageSize,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [popover, setPopover] = useState({ show: false })
  const popoverTarget = useRef(null)

  const showPopover = (e, popoverData) => {
    popoverTarget.current = e.target
    setPopover({ show: true, ...popoverData })
  }

  const hidePopover = () => {
    popoverTarget.current = null
    setPopover({ show: false })
  }

  return (
    <>
      <Row className="justify-content-end my-2">
        <PaginationBtns
          keyPrefix={"up"}
          tablePage={tablePage}
          totalPages={totalPages}
          setTablePage={setTablePage}
        />
      </Row>
      <Table striped bordered>
        <thead>
          <tr>
            <th
              onClick={() => handleSortClick("name", sortBy, setSortBy)}
              style={{ cursor: "pointer" }}
            >
              Nazwa {renderSortArrow("name", sortBy)}
            </th>
            {file ? (
              <>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSortClick("deadline", sortBy, setSortBy)}
                >
                  Termin {renderSortArrow("deadline", sortBy)}
                </th>
                <th
                  onClick={() => handleSortClick("debt", sortBy, setSortBy)}
                  style={{ cursor: "pointer" }}
                >
                  Zaległość {renderSortArrow("debt", sortBy)}
                </th>
              </>
            ) : null}
            <th
              onClick={() => handleSortClick("lastAction", sortBy, setSortBy)}
              style={{ cursor: "pointer" }}
            >
              Ostatnie działanie {renderSortArrow("lastAction", sortBy)}
            </th>
            <th>Kontakt</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {customers
            .slice(tablePage * pageSize - pageSize, tablePage * pageSize)
            .map((customer) => {
              return (
                <CustomerRow
                  customer={customer}
                  key={`CustomerRowComp-${customer._id}`}
                  setModalData={setModalData}
                  isFetching={isFetching}
                  setIsFetching={setIsFetching}
                  refresh={refresh}
                  showPopover={showPopover}
                  hidePopover={hidePopover}
                  file={file}
                />
              )
            })}
        </tbody>
      </Table>
      <PaginationBtns
        keyPrefix={"down"}
        tablePage={tablePage}
        totalPages={totalPages}
        setTablePage={setTablePage}
      />

      <Overlay
        target={popoverTarget.current}
        show={popover.show}
        placement="top"
      >
        <Popover>
          {popover.header ? (
            <Popover.Title>{popover.header}</Popover.Title>
          ) : null}
          {popover.body ? (
            <Popover.Content>{popover.body}</Popover.Content>
          ) : null}
        </Popover>
      </Overlay>
    </>
  )
}

const CustomerRow = ({
  customer,
  setModalData,
  isFetching,
  setIsFetching,
  refresh,
  showPopover,
  hidePopover,
  file,
}) => {
  const renderMainContact = () => {
    if (!customer.debtCollectionCtcs?.main) return null

    const mainCtc = customer.debtCollectionCtcs.main

    return (
      <div
        onMouseOver={(e) =>
          showPopover(e, {
            header: mainCtc.name,
            body: (
              <>
                Stanowisko: {mainCtc.position || "bd"}
                <br />
                Notatka: {mainCtc.note || "brak"}
              </>
            ),
          })
        }
        onMouseOut={hidePopover}
      >
        gł: {mainCtc.phone || "brak tel."}{" "}
        {mainCtc.mail ? (
          <a
            href={`mailto:${mainCtc.mail}`}
            key={`invoiceMailBtn-${customer._id}`}
          >
            <Button size="sm">@</Button>
          </a>
        ) : (
          "brak @"
        )}
        <br />
      </div>
      // </OverlayTrigger>
    )
  }

  const renderDebtCollContacts = () => {
    if (!customer.debtCollectionCtcs?.payment?.length) return null
    const debtCollCtcs = customer.debtCollectionCtcs.payment

    const renderContact = (ctc) => {
      return (
        <div
          key={`debtCollCtc-${ctc._id}`}
          onMouseOver={(e) =>
            showPopover(e, {
              header: ctc.name,
              body: (
                <div key={`debtCollCtcPopover-${ctc._id}`}>
                  Stanowisko: {ctc.position || "bd"}
                  <br />
                  Notatka: {ctc.note || "brak"}
                </div>
              ),
            })
          }
          onMouseOut={hidePopover}
        >
          pł: {ctc.phone || "brak tel."}{" "}
          {ctc.mail ? (
            <a
              href={`mailto:${ctc.mail}`}
              key={`invoiceMailBtn-${customer._id}-${ctc._id}`}
            >
              <Button size="sm">@</Button>
            </a>
          ) : (
            "brak @"
          )}{" "}
        </div>
      )
    }

    return debtCollCtcs.map((ctc) => renderContact(ctc))
  }

  const getBackground = () => {
    let background
    switch (customer.debtCollectionCat) {
      case "zielony":
        background = "#9bf3ab"

        break
      case "czerwony":
        background = "#f6a3a2"
        break

      default:
        background = ""
        break
    }

    return background
  }

  return (
    <tr
      key={`customerRow-${customer._id}`}
      style={{ backgroundColor: getBackground() }}
    >
      <td>
        <a
          href={`/CustomersDetails/${customer._id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {customer.shortName || customer.name || "BRAK NAZWY"} <ImNewTab />
        </a>
      </td>
      {file ? (
        <>
          <td
            style={{ cursor: "pointer" }}
            onClick={() =>
              setModalData({
                header: "Dokumenty",
                body: renderPaymentDocs(customer),
                show: true,
                type: "info",
                xl: true,
              })
            }
          >
            {customer.deadline} <br />(
            {differenceInCalendarDays(new Date(), new Date(customer.deadline))}
            dni)
          </td>
          <td
            onClick={() =>
              setModalData({
                header: "Dokumenty",
                body: renderPaymentDocs(customer),
                show: true,
                type: "info",
                xl: true,
              })
            }
          >
            {customer.debt}
          </td>
        </>
      ) : null}
      <td>
        <LastActionIcon
          lastAction={customer.lastAction}
          showPopover={showPopover}
          hidePopover={hidePopover}
        />
      </td>
      <td>
        {renderMainContact()}
        {renderDebtCollContacts()}{" "}
      </td>
      <td>
        <ActionsCell
          customer={customer}
          setModalData={setModalData}
          isFetching={isFetching}
          setIsFetching={setIsFetching}
          refresh={refresh}
        />
      </td>
    </tr>
  )
}

const LastActionIcon = ({ lastAction, showPopover, hidePopover }) => {
  if (!lastAction) return "brak"

  return (
    <div
      onMouseOver={(e) =>
        showPopover(e, {
          header: (
            <>
              {lastAction.history?.[lastAction.history.length - 1].user
                ?.fullName || "brak danych"}
              : {lastAction.subtype || "ogólna"}
            </>
          ),
          body: (
            <>
              {lastAction.eventDate
                ? format(new Date(lastAction.eventDate), "yyyy-MM-dd")
                : ""}
              <br />
              {lastAction.body}
            </>
          ),
        })
      }
      onMouseOut={hidePopover}
    >
      {lastAction.icon}{" "}
      {lastAction.eventDate
        ? format(new Date(lastAction.eventDate), "yyyy-MM-dd")
        : ""}
    </div>
  )
}

const ActionsCell = ({
  customer,
  setModalData,
  isFetching,
  setIsFetching,
  refresh,
}) => {
  const btnsArr = [
    {
      icon: <MdLocalPhone />,
      subtype: "telefon",
    },
    {
      icon: <MdTextsms />,
      subtype: "sms",
    },
    {
      icon: <MdAlternateEmail />,
      subtype: "mail",
    },
    {
      icon: <IoDocumentOutline />,
      subtype: "@ wezwanie",
    },
    {
      icon: <MdAccountBalance />,
      subtype: "@ wezwanie KRD",
    },
  ]

  /* 
  on regular click:
  add note type = "windykacyjna", subtype = depending on btn, eventDate = now, body = ""
  

  on PLUS click user can choose eventDate and type in body
  */

  const submitNote = async (subtype, eventDate, body) => {
    try {
      setIsFetching(true)
      setModalData({ show: false })
      //TODO set event date to 12:00

      const note = {
        type: "windykacyjna",
        subtype: subtype,
        eventDate: format(setHours(new Date(eventDate), 12), "yyyy-MM-dd"),
        body: body,
        state: "aktywna",
      }

      const res = await fetch("/customers/addEntity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entityName: "notes",
          entity: note,
          customer_id: customer._id,
        }),
      })

      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      refresh({
        header: "Sukces",
        body: "Notatka dodana",
        headerBg: "bg-success",
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "info",
        title: "Błąd",
        body: err.message,
      })
    }
  }

  const submitSuspendJobs = async (suspend) => {
    try {
      const res = await fetch(`/customers/updateCustomer/${customer._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer: { jobsSuspended: suspend },
          dirtyFields: { jobsSuspended: true },
          historyLength: customer.history?.length || customer.historyLength,
        }),
      })

      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      refresh({
        header: "Zapisane",
        body: suspend ? "Zadania wstrzymane" : "Zadania odblokowane",
        headerBg: "bg-warning",
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "info",
        title: "Błąd",
        body: err.message,
      })
    }
  }

  const renderSuspendBtn = () => {
    if (isFetching)
      return (
        <Button className="ml-1" disabled={true}>
          <Spinner animation="border" size="sm" />{" "}
        </Button>
      )
    if (customer.jobsSuspended)
      return (
        <Button
          className="ml-1"
          variant="warning"
          onClick={() => submitSuspendJobs(false)}
        >
          <IoPlay />
        </Button>
      )
    if (!customer.jobsSuspended)
      return (
        <Button
          className="ml-1"
          variant="danger"
          onClick={() => submitSuspendJobs(true)}
        >
          <MdPause />
        </Button>
      )
  }

  return (
    <>
      {btnsArr.map((btn) => (
        <SplitButton
          key={`actionBtn-${customer._id}-${btn.subtype}`}
          className="ml-1 mb-1"
          variant="secondary"
          title={
            isFetching ? <Spinner animation="border" size="sm" /> : btn.icon
          }
          onClick={() => submitNote(btn.subtype, new Date(), "")}
          disabled={isFetching}
        >
          <Dropdown.Item>
            <ImPlus
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Dodawanie notatki windykacyjnej",
                  hideFooter: true,
                  body: (
                    <DebtCollectionNoteModal
                      subtype={btn.subtype}
                      submitNote={submitNote}
                      setModalData={setModalData}
                    />
                  ),
                })
              }
            />
          </Dropdown.Item>
        </SplitButton>
      ))}

      <Button
        className="ml-1"
        variant="secondary"
        disabled={isFetching}
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            header: "Dodawanie notatki windykacyjnej",
            hideFooter: true,
            body: (
              <DebtCollectionNoteModal
                subtype={"ogólna"}
                submitNote={submitNote}
                setModalData={setModalData}
              />
            ),
          })
        }
      >
        {isFetching ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <IoEllipsisHorizontalSharp />
        )}
      </Button>
      {renderSuspendBtn()}
    </>
  )
}

const PaginationBtns = ({ tablePage, setTablePage, totalPages, keyPrefix }) => {
  if (totalPages <= 1) return null

  const renderBtns = () => {
    const btns = []
    for (let i = 1; i <= totalPages; i++) {
      btns.push(
        <Button
          size="sm"
          key={`paginationBtn-${keyPrefix}-${i}`}
          variant="primary"
          onClick={() => setTablePage(i)}
          disabled={i === tablePage}
        >
          {i}
        </Button>
      )
    }
    return btns
  }

  return <>{renderBtns()}</>
}

export { DebtCollectionTable }
