import React from "react"

import { Table, Container, Row, Button } from "react-bootstrap"

import { PermissionHeaderTable } from "./Tabels"
import { NotesTable } from "../../utils/NotesTable"
import HistoryFetch from "../../_shared/history/fetch"

const SewagePermissionDetailsRender = ({
  permission,
  setModalData,
  setGoBack,
  setShowEdit,
}) => {
  const handleArchive = async () => {
    try {
      // hiding confirmation modal:
      setModalData({ show: false })

      const data = permission
      //add permission._id to form data:
      data._id = permission._id

      const oldState = data.state //used to show right info to user after handling operation

      // switching state depending on present state
      if (data.state === "archiwum") data.state = "aktywne"
      else data.state = "archiwum"

      const res = await fetch("/sewage/permission/update", {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          permission: data,
          dirtyFields: { state: true },
        }),
      })

      if (res.status !== 200) {
        throw new Error(
          `${res.status} - ${(await res.text()) || "nieokreślony błąd"}`
        )
      }

      setModalData({
        show: true,
        type: "info",
        body:
          // prepering right info message:
          oldState === "archiwum"
            ? "Zezwolenie przywrócone"
            : "Zezwolenie zarchiwizowane",
        func: () => setGoBack(true),
        onHide: () => setGoBack(true),
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd komunikacji z serwerem, kod: ${err.message}`,
      })
    }
  }

  return (
    <Container>
      <p style={{ color: "white" }}>{permission._id}</p>
      <PermissionHeaderTable permission={permission} />

      <Row className="justify-content-between">
        <Button onClick={() => setGoBack(true)}>Wróć</Button>
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: `Historia zezwolenia ${permission.depName}`,
              body: <HistoryFetch entity_id={permission._id} />,
            })
          }
        >
          Historia
        </Button>
        <Button
          variant="warning"
          onClick={() =>
            setModalData({
              show: true,
              type: "confirm",
              body:
                permission.state === "archiwum"
                  ? "Przywrócić zezwolenie?"
                  : "Zarchiwizować zezwolenie?",
              func: handleArchive,
            })
          }
        >
          {permission.state === "archiwum" ? "Przywróć" : "Archiwizuj"}
        </Button>

        <Button variant="outline-secondary" onClick={() => setShowEdit(true)}>
          Edytuj
        </Button>
      </Row>
      <Table bordered className="mt-3">
        <tbody>
          <tr>
            <td className="bg-primary">Rodzaj</td>
            <td className="bg-primary">Gmina</td>
            <td className="bg-primary">Symbol</td>
          </tr>
          <tr>
            <td>{permission.purpose}</td>
            <td>{permission.municipal}</td>
            <td>{permission.permissionNumber}</td>
          </tr>
          <tr>
            <td className="bg-primary">Data ważności</td>
            <td className="bg-primary">Termin przypomnienia</td>
            <td className="bg-primary">Oddział</td>
          </tr>
          <tr>
            <td>{permission.deadline}</td>
            <td>{permission.remindTerm}</td>
            <td>{permission.branch}</td>
          </tr>
          <tr>
            <td colSpan="2" className="bg-primary">
              Osoba do kontaktu
            </td>
            <td className="bg-primary">Adres urzędu</td>
          </tr>
          <tr>
            <td colSpan="2">{permission.depPersonName}</td>
            <td rowSpan="3">
              {permission.depName}
              <br />
              {permission.depStreet}
              <br />
              {permission.depPostal} {permission.depCity}
            </td>
          </tr>
          <tr>
            <td className="bg-primary" colSpan="2">
              Telefon kontaktowy
            </td>
          </tr>
          <tr>
            <td colSpan="2">{permission.depPersonTel}</td>
          </tr>
          <tr>
            <td className="bg-primary" colSpan="2">
              Lista zlewni
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="keep-br">
              {permission.dumpStationsList}
            </td>
          </tr>
        </tbody>
      </Table>
      <NotesTable
        notes={permission.notes || []}
        setModalData={setModalData}
        noteTypes={[
          "ogólna",
          "korespondencja",
          "kontrola",
          "wymagana aktualizacja",
          "inne",
        ]}
        apiAddress={`/sewage/permissionNote/${permission._id}`}
        refresh={() => window.location.reload()}
      />
    </Container>
  )
}
export default SewagePermissionDetailsRender
