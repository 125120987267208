/**
 *
 * @param {object} data report data fetched from API
 * @param {string} srvName name of service
 * @param {string} period period name
 * @returns sum of service amounts for all branches
 */

const calcSrvSum = (data, srvName, period = "current") => {
  return data[period].reduce((sum, branchObj) => {
    const srv = branchObj.services.find(
      (branchSrv) => branchSrv.name === srvName
    )
    if (srv) return (sum += srv?.amount)
    else return sum
  }, 0)
}

export { calcSrvSum }
