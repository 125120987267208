import React, { useContext, useMemo } from "react"
import { useForm } from "react-hook-form"
import {
  format,
  endOfMonth,
  getDate,
  differenceInCalendarDays,
  isWithinInterval,
  min,
  subDays,
  isAfter,
  addDays,
  isBefore,
  max,
  isValid,
} from "date-fns"
import { Row, Button, Form, Alert, Badge, Col } from "react-bootstrap"

import { LocationCtx } from "../Fetch"
import InvModsTable from "../../_shared/invoices/InvModsTable"
import { renderInvMakeAlerts } from "../../_shared/invoices/utils"

const LocationInvoiceMakeModal = ({ services }) => {
  const { location, setModalData, user } = useContext(LocationCtx)

  // set start date for earliest invoicedTo (or param start date when no invoiced to)
  const startDate = useMemo(() => {
    // for each service get date that should be used in invoicing, then return earliest
    const startDates = services.map((srv) => {
      // if first param is invoiced use latest invoicedTo from _all_ params (+1 day)
      // else use earliest param start date

      // get earliest param
      const firstParam = srv.params.reduce((acc, param) => {
        if (isBefore(new Date(param.startDate), new Date(acc.startDate))) {
          return param
        } else {
          return acc
        }
      }, srv.params[0])

      if (firstParam.invoicedTo) {
        const invoicedToDates = srv.params
          .map((param) => {
            if (param.invoicedTo && isValid(new Date(param.invoicedTo))) {
              return new Date(param.invoicedTo)
            } else {
              return null
            }
          })
          .filter((date) => date)

        return addDays(max(invoicedToDates), 1)
      } else {
        return new Date(firstParam.startDate)
      }
    })
    console.log("startDates", startDates)
    return min(startDates)
  }, [services])

  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm({
    defaultValues: {
      invDate: format(new Date(), "yyyy-MM-dd"),
      sellDate: format(new Date(), "yyyy-MM-dd"),
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(
        getDate(new Date()) > 5
          ? endOfMonth(new Date())
          : endOfMonth(subDays(new Date(), 7)),
        "yyyy-MM-dd"
      ),
      preventSettingInvoicedTo: false,
    },
  })
  const watchStartDate = watch("startDate")
  const watchEndDate = watch("endDate")

  const invoicingNotes = useMemo(() => {
    return location.notes.filter((note) => {
      if (note.state !== "aktywna") return false
      else if (note.type === "fakturowa" && note.subtype === "ogólna")
        return true
      else if (
        note.type === "fakturowa" &&
        note.eventDate &&
        // before checking interval check if end date is after start to prevent fns error:
        isAfter(new Date(watchEndDate), new Date(watchStartDate)) &&
        isWithinInterval(new Date(note.eventDate), {
          start: new Date(watchStartDate),
          end: new Date(watchEndDate),
        })
      )
        return true
      else return false
    })
  }, [location, watchStartDate, watchEndDate])

  const submitInv = async (formData) => {
    try {
      const body = {
        customers: [
          {
            customer_id: location.customer._id,
            locations: [{ location: location, services: services }],
          },
        ],

        invParams: formData,
      }

      const res = await fetch("/invoices/make", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })

      if (res.status === 409)
        return setModalData({
          show: true,
          type: "alert",
          body: "Ktoś edytował lokalizację i/lub usługi. Odśwież stronę aby pobrać aktualne dane",
        })
      if (
        res.status === 500 ||
        res.status === 403 // I use 403 not only for perm check (as in most cases), but also when invoicing params are wrong
      )
        return setModalData({
          show: true,
          type: "alert",
          body: await res.text(),
        })
      if (res.status !== 200) throw res
      else {
        const resJSON = await res.json()

        setModalData({
          show: true,
          type: "info",
          xl: true,
          headerColor: "green",
          header: "Sukces",
          onHide: () => window.location.reload(),
          body: (
            <p>
              Faktura wygenerowana
              <br />
              {resJSON.msg.length ? renderInvMakeAlerts(resJSON.msg) : null}
            </p>
          ),
        })
      }
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: "Błąd fakturowania" })
    }
  }

  if (!services.length)
    return <Alert variant="danger">Nie wybrano usług do zafakturowania</Alert>
  return (
    <Form onSubmit={handleSubmit(submitInv)}>
      {location.branch === "TEST" || user.type?.admin ? (
        <Form.Check
          {...register("preventSettingInvoicedTo")}
          type="checkbox"
          label='nie ustawiaj "zafakturowano do"'
          id="preventSettingInvoicedTo"
        />
      ) : null}
      {user.perm.invoicing.w ? (
        <>
          <Row className="justify-content-between mt-2">
            <Button onClick={() => setModalData({ show: false })}>
              Anuluj
            </Button>
            <Button type="submit" variant="secondary" id="submitInv">
              Generuj
            </Button>
          </Row>
        </>
      ) : null}
      <Row>
        <Col>
          Data faktury:{" "}
          <Form.Control
            {...register("invDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col>
          Data sprzedaży:
          <Form.Control
            {...register("sellDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          Fakturuj od:
          <Form.Control
            {...register("startDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
            id="startDate"
          />
        </Col>
        <Col>
          Fakturuj do:
          {errors.endDate && (
            <Badge pill variant="warning">
              Maksymalny okres fakturowania to 270 dni
            </Badge>
          )}
          <Form.Control
            {...register("endDate", {
              validate: (v) => {
                if (
                  differenceInCalendarDays(
                    new Date(v),
                    new Date(watch("startDate"))
                  ) > 270
                )
                  return false
                else return true
              },
            })}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
            id="endDate"
          />
        </Col>
      </Row>
      <InvModsTable
        locations={[location]}
        srvsChoosen={services}
        startDate={watchStartDate}
        endDate={watchEndDate}
        multiLoc={false}
      />
      {invoicingNotes.length ? (
        <>
          <Row>
            <Col>
              <h4>Notatki "fakturowe":</h4>
            </Col>
          </Row>

          <Row>
            <ul>
              {invoicingNotes
                .filter((note) => note.subtype === "ogólna")
                .map((note) => {
                  return <li>{`Ogólna: ${note.body}`}</li>
                })}
            </ul>
          </Row>
          <Row>
            <ul>
              {invoicingNotes
                .filter((note) => note.subtype === "jednorazowa")
                .map((note) => {
                  return (
                    <li>{`${format(new Date(note.eventDate), "yyyy-MM-dd")}: ${
                      note.body
                    }`}</li>
                  )
                })}
            </ul>
          </Row>
        </>
      ) : null}
    </Form>
  )
}

export default LocationInvoiceMakeModal
