import { Button } from "react-bootstrap"
import { BsBook } from "react-icons/bs"

const createManualBtn = (manualScrollId, className = "") => {
  return (
    <a
      href={`https://cliperp.app/manual?scrollTarget=${manualScrollId}`}
      target="_blank"
      rel="noreferrer noopener"
      className={className}
    >
      <Button size="sm">
        <BsBook />
      </Button>
    </a>
  )
}

export { createManualBtn }
