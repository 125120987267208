import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Row,
  Table,
  Button,
  Form,
  Badge,
  Alert,
  Spinner,
} from "react-bootstrap"
import Select from "react-select"

import { locSubmit, createLocName, getMunicipal } from "./utils"
import { ContactsTable } from "./helperComponents"
const LocationEditGeneralModalRender = ({
  user,
  customer,
  isNew,
  location,
  setModalData,
  locationsNames,
  refresh,
  staleDataErrorAlert,
  permissions,
}) => {
  const [fetchError, setFetchError] = useState(null)
  const [permChoosen, setPermChoosen] = useState(
    isNew
      ? null
      : {
          value: location.permission?._id,
          label: location.permission?.municipal,
        }
  )
  const [permErr, setPermErr] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  // use ONLY customer branches that are allowed for user
  // user can't change branch of created location
  const [branchesArr] = useState(
    isNew || user.perm.customers.d
      ? customer.branches.filter(
          (brn) => user.allowedBranches.findIndex((el) => el === brn) !== -1
        )
      : [location.branch]
  )

  let mainNote
  if (!isNew)
    mainNote = location.notes.find(
      (note) => note.state === "aktywna" && note.type === "główna"
    )
  const [defaultValues] = useState(
    isNew
      ? {
          branch: branchesArr[0] || null,
          name: createLocName(locationsNames, customer),
          street: null,
          postal: null,
          city: null,
          description: null,
          hasDriver: false,
          driver: null,
          mainNote: null,
        }
      : {
          ...location,
          mainNote: mainNote?.body || null,
          driver: location.hasDriver
            ? location.driver?._id || null // this tenery is for backward compability
            : null,
        }
  )
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    watch,
  } = useForm({ defaultValues: defaultValues })

  const onSubmit = (formData) => {
    locSubmit(
      formData,
      isNew,
      user,
      customer._id,
      location?._id || null,
      dirtyFields,
      location?.history?.length || 0,
      setModalData,
      setFetchError,
      refresh,
      customer.contacts,
      permChoosen,
      setIsFetching
    )
  }
  return (
    <>
      {staleDataErrorAlert ? (
        <Alert variant="warning">
          Uwaga! Poniższe dane mogą być niezgodne z widocznymi w podglądzie
          szczegółów klienta/lokalizacji. Stało się tak, ponieważ ktoś w
          międzyczasie edytował klienta lub lokalizację. W poniższym formularzu
          wyświetlone są najnowsze dane. Możesz <u>bezpiecznie</u> wprowadzić
          zmiany i je zapisać.
        </Alert>
      ) : null}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Nazwa</td>
              <td className="fixed-values-table">
                Oddział obsługujący{" "}
                {errors.branch && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">
                <Controller
                  name="hasDriver"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Form.Check
                        {...field}
                        inline
                        type="checkbox"
                        id="hasDriver"
                        checked={field.value}
                        onChange={(e) => {
                          if (!e.target.checked) setValue("driver", null)
                          else setValue("driver", "plug")
                          field.onChange(e)
                        }}
                      />
                    )
                  }}
                />
                Domyślny kierowca{" "}
                {errors.driver && (
                  <Badge pill variant="warning">
                    Wybierz kierowcę
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register("name")}
                  as="input"
                  type=""
                  className=""
                />
              </td>
              <td>
                <Form.Control
                  {...register("branch", { required: true })}
                  as="select"
                  type=""
                  className=""
                >
                  {branchesArr.map((branch) => (
                    <option value={branch} key={`select-branches-${branch}`}>
                      {branch}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  {...register("driver", {
                    validate: (v) => {
                      if (watch("hasDriver") && (v === "plug" || !v))
                        return false
                      else return true
                    },
                  })}
                  as="select"
                  type=""
                  className=""
                  disabled={!watch("hasDriver", location?.hasDriver)}
                >
                  <option value="plug" hidden>
                    wybierz
                  </option>

                  {user.drivers.map((driver) => {
                    // show only drivers that match location branch:
                    if (driver.branch[watch("branch")])
                      return (
                        <option
                          value={driver._id}
                          key={`driver-option-${driver.fullName}`}
                        >
                          {driver.fullName}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>

                {/* show or hide checkbox that lets change driver in all planned jobs */}
                {!isNew &&
                  (watch("hasDriver") !== defaultValues.hasDriver ||
                    (watch("hasDriver") &&
                      watch("driver") !== defaultValues.driver)) && (
                    <Form.Check
                      {...register("changeJobsDriver")}
                      type="checkbox"
                      label="zastosuj do zadań"
                      id="allJobsDriverChange"
                    />
                  )}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Ulica, nr budynku{" "}
                {errors.street && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <Form.Control
                  {...register("street", { required: true })}
                  id="locationStreetInput"
                  as="input"
                  type=""
                  className=""
                />
              </td>
            </tr>

            <tr>
              <td className="fixed-values-table">Kod</td>
              <td className="fixed-values-table">
                Miasto{" "}
                {errors.city && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">
                Zezwolenie/gmina{" "}
                <Button
                  onClick={() => {
                    const queryArr = getValues(["postal", "city", "street"])
                    getMunicipal(
                      queryArr.join(" "),
                      setPermChoosen,
                      permissions,
                      setPermErr
                    )
                  }}
                >
                  szukaj
                </Button>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register("postal")}
                  id="locationPostalInput"
                  as="input"
                  type=""
                  className=""
                />
              </td>
              <td>
                <Form.Control
                  {...register("city", { required: true })}
                  id="locationCityInput"
                  as="input"
                  type=""
                  className=""
                  autoComplete="off"
                />
              </td>
              <td>
                <Badge variant="warning">{permErr}</Badge>
                <Select
                  isSearchable={true}
                  value={permChoosen}
                  options={permissions.map((perm) => ({
                    value: perm._id,
                    label: perm.municipal,
                  }))}
                  onChange={setPermChoosen}
                  defaultValue={permChoosen}
                />
              </td>
            </tr>
            {isNew && (
              <>
                <tr>
                  <td className="fixed-values-table" colSpan="3">
                    Notatka "na kontrolkę" - dojazd itp.
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Form.Control
                      {...register("logisticNote")}
                      id="locationLogisticNoteInput"
                      as="textarea"
                      type=""
                      className=""
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fixed-values-table" colSpan="3">
                    Notatka "ogólna"
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Form.Control
                      {...register("mainNote")}
                      id="locationMainNoteInput"
                      as="textarea"
                      type=""
                      className=""
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
        {isNew ? (
          <>
            <h4>Rejestracja wstępna</h4>
            <p>
              (<u>jeśli nie wiesz jak zaplanować zadania</u>, wpisz tu
              informację dla logistyka)
            </p>
            <Form.Control
              {...register("initNote")}
              as="textarea"
              type="textarea"
              className=""
              autoComplete="chrome-off"
            />
          </>
        ) : null}
        {/* TODO !!!!!!!!!!!!!!!!!!!!!!!!! */}
        {/* {isNew ? (
        <CustomersDetailsContactsTable
          embedded={true}
          setContactSelected={setContactSelected}
          contactSelected={contactSelected}
          contacts={customer.contacts.filter()}
          customer={customer}
        />
      ) : null} */}

        <ContactsTable register={register} customer={customer} isNew={isNew} />

        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row className="justify-content-between">
          <Button
            variant="warning"
            onClick={() => setModalData({ show: false })}
            disabled={isFetching}
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Anuluj"}
          </Button>
          {user.type.admin ? (
            <Button onClick={() => console.log(watch())}>?</Button>
          ) : null}
          <Button
            type="submit"
            variant="secondary"
            className="mr-3"
            id="createLocationBtn"
            disabled={isFetching}
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Zapisz"}
          </Button>
        </Row>
      </Form>
    </>
  )
}
export { LocationEditGeneralModalRender }
