import _ from "lodash"

/**
 * fetches jobs using jobsDates obj, which can contain jobsDates.exclude
 * @param {String} loc_id location _id
 * @param {Object} jobsDates object with start and end dates
 * @param {*} setJobs update jobs
 * @param {*} setAreJobsLoading update areJobsLoading
 * @param {*} setModalData update modalData
 * @param {Array} jobs
 * @param {Boolean} override true when refreshing after any change, false when only fetching missing jobs
 * @returns
 */
const jobsFetch = async (
  loc_id,
  jobsDates,
  setJobs,
  setAreJobsLoading,
  setModalData,
  jobs,
  override
) => {
  try {
    setAreJobsLoading(true)
    let queryString = `/jobs/getByLocationId/${loc_id}?sD=${jobsDates.start}&eD=${jobsDates.end}`

    // when there is exclude object -> modify query so, I won't fetch all jobs again, but only needed.
    //( more info in comment at the end of Location/Fetch.js)
    // BUT only if not overriding jobs (I use override when refreshing after any change)

    if (jobsDates.exclude && !override)
      queryString = `${queryString}&excludeSD=${jobsDates.exclude.start}&excludeED=${jobsDates.exclude.end}`

    const res = await fetch(queryString)
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "info",
        body: "Brak uprawnień",
      })
    if (res.status !== 200) throw res
    const resJSON = await res.json()

    let newJobs = []
    if (override) {
      newJobs = resJSON.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
    } else
      newJobs = _.uniqWith([...jobs, ...resJSON], _.isEqual).sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      )
    setJobs(newJobs)
    setAreJobsLoading(false)
    //TODO check if jobsDates !== newDates, if true =>
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd pobierania danych",
    })
  }
}

export { jobsFetch }
