/*
* Readme:
_Alert
_Delete
_Confirm
_Info:
if modalData.reload === true -> button fires window.location.reload(), else only closes modal
Or pass modalData.func to execute after clicking
_Form:
! Necessarily pass additional prop handleSubmit to <MyModal>
because when trying to use handling submit from modalData it takes initial form state
*/

import React, { useContext } from "react"

import UserContext from "../contexts/userContext"
import { Modal, Button, Form } from "react-bootstrap"
import ModalDialog from "react-bootstrap/ModalDialog"
import Draggable from "react-draggable"

const AlertModal = (props) => {
  return (
    <div id="alertModal" style={{ whiteSpace: "pre-wrap" }}>
      <Modal.Header
        className="bg-danger"
        // set cursor (modals by default are draggable, static only when 'draggable:false' is passed)
        style={{
          cursor: props.modalData.draggable === false ? "default" : "move",
        }}
      >
        {props.modalData.header || "Błąd"}
      </Modal.Header>
      <Modal.Body>{props.modalData.body || "nieokreślony błąd"}</Modal.Body>
      <Modal.Footer>
        <Button
          id="closeAlertModalBtn"
          variant="danger"
          onClick={() => {
            if (props.modalData.reload) return window.location.reload()
            else if (props.modalData.func) return props.modalData.func()
            else if (props.modalData.onHide) return props.modalData.onHide()
            else return props.setModalData({ show: false })
          }}
        >
          Zamknij
        </Button>
      </Modal.Footer>
    </div>
  )
}

const DeleteModal = (props) => {
  return (
    <>
      <Modal.Header
        className="bg-danger"
        // set cursor (modals by default are draggable, static only when 'draggable:false' is passed)
        style={{
          cursor: props.modalData.draggable === false ? "default" : "move",
        }}
      >
        {props.modalData.header || "Potwierdź usunięcie"}
      </Modal.Header>
      <Modal.Body>{props.modalData.body || "Usunąć element?"}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => props.modalData.handleDelete()}>
          Usuń
        </Button>
        <Button onClick={() => props.setModalData({ show: false })}>
          Anuluj
        </Button>
      </Modal.Footer>
    </>
  )
}

const ConfirmModal = (props) => {
  return (
    <>
      <Modal.Header // set background color if any passed in props (have to use scss names)
        className={`bg-${
          props.modalData.headerColor || "primaryLight"
        } font-weight-bold`}
        // set cursor (modals by default are draggable, static only when 'draggable:false' is passed)
        style={{
          cursor: props.modalData.draggable === false ? "default" : "move",
        }}
      >
        {props.modalData.header || "Potwierdź operację"}
      </Modal.Header>
      <Modal.Body>{props.modalData.body || "Wykonać?"}</Modal.Body>
      <Modal.Footer>
        <Button
          id="confirmModalAccept"
          variant="secondary"
          onClick={() => {
            if (props.modalData.func) return props.modalData.func()
            else return props.setModalData({ show: false })
          }}
        >
          Potwierdź
        </Button>
        <Button
          onClick={() => {
            props.setModalData({ show: false })
          }}
        >
          Anuluj
        </Button>
      </Modal.Footer>
    </>
  )
}
const FormModal = (props) => {
  return (
    <>
      <Form>
        <Modal.Header
          // set cursor (modals by default are draggable, static only when 'draggable:false' is passed)
          style={{
            cursor: props.modalData.draggable === false ? "default" : "move",
          }}
        >
          {props.modalData.header || "Potwierdź operację"}
        </Modal.Header>
        <Modal.Body>{props.modalData.body || "Wykonać?"}</Modal.Body>
        <Modal.Footer>
          {props.modalData.footer || (
            <span>
              <Button
                type="submit"
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  return props.handleSubmit()
                }}
              >
                Potwierdź
              </Button>
              <Button
                onClick={() => {
                  props.setModalData({ show: false })
                }}
              >
                Anuluj
              </Button>
            </span>
          )}
        </Modal.Footer>
      </Form>
    </>
  )
}

const InfoModal = (props) => {
  return (
    <>
      <Modal.Header
        // set background color if any passed in props (have to use scss names)
        className={`bg-${
          props.modalData.headerColor || "primaryLight"
        } font-weight-bold`}
        // set cursor (modals by default are draggable, static only when 'draggable:false' is passed)
        style={{
          cursor: props.modalData.draggable === false ? "default" : "move",
        }}
      >
        {props.modalData.header || "Info"}
      </Modal.Header>
      <Modal.Body
        className="keep-br"
        // for cypress:
        id={`infoModalBody-${props.modalData.id || null}`}
      >
        {props.modalData.body ||
          "Jeśli widzisz ten napis to zgłoś błąd w programie"}
      </Modal.Body>
      {props.modalData.hideFooter ? null : (
        <Modal.Footer>
          <Button
            id="infoModalClose"
            onClick={() => {
              if (props.modalData.reload) return window.location.reload()
              else if (props.modalData.func) return props.modalData.func()
              else if (props.modalData.onHide) return props.modalData.onHide()
              else return props.setModalData({ show: false })
            }}
          >
            Zamknij
          </Button>
        </Modal.Footer>
      )}
    </>
  )
}

const MyModal = (props) => {
  const user = useContext(UserContext)

  const renderModalInput = () => {
    if (!props.modalData.show) return null
    else if (props.modalData.type === "alert")
      return (
        <AlertModal
          modalData={props.modalData}
          setModalData={props.setModalData}
        />
      )
    else if (props.modalData.type === "delete")
      return (
        <DeleteModal
          modalData={props.modalData}
          setModalData={props.setModalData}
        />
      )
    else if (props.modalData.type === "confirm")
      return (
        <ConfirmModal
          modalData={props.modalData}
          setModalData={props.setModalData}
        />
      )
    else if (props.modalData.type === "form")
      return (
        <FormModal
          modalData={props.modalData}
          setModalData={props.setModalData}
          handleSubmit={props.handleSubmit}
        />
      )
    else if (props.modalData.type === "info")
      return (
        <InfoModal
          modalData={props.modalData}
          setModalData={props.setModalData}
        />
      )
    //just in case of sending wrong props:
    else return null
  }

  const createDialogAs = (dialogProps) => {
    if (props.modalData.draggable === false)
      return <ModalDialog {...dialogProps} />
    else {
      // get window size:
      //( used to prevent moving modal outside of window)
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      }

      return (
        <Draggable
          handle=".modal-header"
          bounds={{
            top: 0, // no moving up to prevent hiding under browser toolbar
            bottom: size.height * 0.75,
            left: -0.5 * size.width,
            right: 0.5 * size.width,
          }}
        >
          <ModalDialog {...dialogProps} />
        </Draggable>
      )
    }
  }

  //! TEMPORARY FIX:
  // when there is dialogAs (used to create draggable modal) it messes up modal that hase useEffect and changes parent state.
  // not sure why and how to fix it. Example is LocationJobsBatchEditFetch...
  // for now I just add condition to check if modal should be draggable here to return modal without dialogAs if needed
  // (shouldn't be needed as I check it also in createDialogAs function)

  if (props.modalData.draggable === false)
    return (
      <Modal
        show={props.modalData.show}
        onHide={
          props.modalData.onHide
            ? () => props.modalData.onHide()
            : () => props.setModalData({ show: false })
        }
        size={props.modalData.xl ? "xl" : null}
        animation={false}
        backdrop={user?.configs?.staticBackdrop ? "static" : true}
      >
        {renderModalInput()}
      </Modal>
    )

  //! <-- END OF TEMPORARY FIX
  return (
    <Modal
      dialogAs={createDialogAs}
      onShow={() => {
        // allow scrolling background when modal is open and draggable:
        const modalOpenEl = document.getElementsByClassName("modal-open")[0]
        if (props.modalData.draggable !== false && modalOpenEl) {
          // override overflow
          // (bootstrap modal has overflow: hidden by default to catch scrolling)
          modalOpenEl.style.overflow = "auto"
          modalOpenEl.style.overflowX = "auto"
          modalOpenEl.style.overflowY = "auto"
        }
      }}
      show={props.modalData.show}
      onHide={
        props.modalData.onHide
          ? () => props.modalData.onHide()
          : () => props.setModalData({ show: false })
      }
      size={props.modalData.xl ? "xl" : null}
      animation={false}
      backdrop={user?.configs?.staticBackdrop ? "static" : true}
    >
      {renderModalInput()}
    </Modal>
  )
}

export default MyModal
