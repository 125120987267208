const checkAccNumber = (accNumber) => {
  if (accNumber.length === 0) return true

  // get rid of whitespaces and create array with spread:
  let newAccNumber = [...accNumber.replace(/\s/g, "")]
  // console.log(newAccNumber)

  // check length:
  if (newAccNumber.length !== 26) {
    console.log("zła długość")
    return false
  }

  // add PL prefix changed according to wiki (https://pl.wikipedia.org/wiki/Mi%C4%99dzynarodowy_numer_rachunku_bankowego):
  newAccNumber.push("2521") // 2521 for 'PL',
  //push first two numbers to end:
  newAccNumber.push(newAccNumber[0])
  newAccNumber.push(newAccNumber[1])
  //remove pushed numbers from beggining:
  newAccNumber = newAccNumber.slice(2)

  newAccNumber = window.BigInt(newAccNumber.join(""))
  if (newAccNumber % 97n === 1n) return true
  else {
    console.log("numer konta po przekształceniach:")
    console.log(newAccNumber)
    console.log("reszta z dzielenia przez 97:")
    console.log(newAccNumber % 97n)

    return false
  }
}

const checkPesel = (pesel) => {
  const peselArr = [...pesel]
    .map((el) => {
      // clearing number:
      if (isNaN(el)) return null
      else return Number.parseInt(el)
    })
    .filter((el) => el !== null)
  // check length:
  if (peselArr.length !== 11) return false

  const controlArr = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1]
  let sum = peselArr.reduce((acc, curr, i) => {
    return (acc += curr * controlArr[i])
  }, 0)
  if (sum % 10 === 0) return true
  else return false
}

export { checkAccNumber, checkPesel }
