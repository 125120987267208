import React from "react"
import { Table } from "react-bootstrap"
import { dateParse } from "../../../utils/formats"

const CustomerDetailsHistoryModal = (props) => {
  const history = [...props.historyArr].sort(
    (a, b) => Date.parse(b.date) - Date.parse(a.date)
  )
  return (
    <Table>
      <thead>
        <tr>
          <th>Rodzaj zmiany</th>
          <th>Data</th>
          <th>Użytkownik</th>
          <th>Opis</th>
        </tr>
      </thead>
      <tbody>
        {history.map((row) => {
          return (
            <tr key={`history-row-${row._id}`}>
              <td>{row.type}</td>
              <td>{dateParse(row.date)}</td>
              <td>{row.user?.fullName || "bd"}</td>
              <td className="keep-br">{row.description}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
export default CustomerDetailsHistoryModal
