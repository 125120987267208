import React, { useContext } from "react"
import { Container, Button, Form, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { CustomerCtx } from "../Fetch"

const CustomerDetailsEditAdditionalModal = () => {
  const { customer, setModalData, refresh, user } = useContext(CustomerCtx)
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({ defaultValues: customer })

  const submit = async (formData) => {
    try {
      const res = await fetch(`/customers/updateCustomer/${customer._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer: formData,
          historyLength: customer.history.length,
          dirtyFields: dirtyFields,
        }),
      })

      if (res.status !== 200) throw new Error(await res.text())
      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        body: "Dane zapisane",
        headerColor: "success",
        onHide: () => {
          setModalData({ show: false })
          refresh()
        },
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: `Błąd:${err.message}` })
    }
  }

  const validateJobsSuspension = (val) => {
    // everyone can suspend jobs:
    if (
      customer.jobsSuspended &&
      val !== true &&
      !user.perm.debtCollection.d &&
      !user.type.board
    )
      return "brak uprawnień do odblokowania zadań"
    else return true
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submit)}>
        {errors.jobsSuspended && (
          <Badge pill variant="warning">
            {errors.jobsSuspended.message}
          </Badge>
        )}
        <Form.Check
          {...register("jobsSuspended", { validate: validateJobsSuspension })}
          type="checkbox"
          label="Zadania wstrzymane"
          id="jobsSuspended"
        />
        <Form.Check
          {...register("marketingConsent")}
          type="checkbox"
          label="Zgoda marketingowa"
          id="marketingConsent"
        />

        <Form.Check
          {...register("locOnInvoice")}
          type="checkbox"
          label="Lokalizacja na FV?"
          id="locOnInvoice"
        />
        <Form.Check
          {...register("printDebtDetails")}
          type="checkbox"
          label="Rozrachunki na FV?"
          id="printDebtDetails"
        />
        <Form.Check
          {...register("customJobCards")}
          type="checkbox"
          label="Specjalne kontrolki"
          id="customJobCards"
        />
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Form>
    </Container>
  )
}
export default CustomerDetailsEditAdditionalModal
