import React from "react"
import { Container, Row, Button } from "react-bootstrap"

import EquipmentEditModal from "./Edit/Modal"

const EquipmentDetailsModal = ({ eqp, setModalData, configs }) => {
  return (
    <Container>
      <Row>
        <Button onClick={() => alert("Do zrobienia")}>Historia</Button>
        <Button onClick={() => alert("Do zrobienia")}>Zmień stan</Button>
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: "Szczegóły sprzętu",
              body: (
                <EquipmentEditModal
                  eqp={eqp}
                  setModalData={setModalData}
                  configs={configs}
                />
              ),
            })
          }
        >
          Edytuj
        </Button>
      </Row>

      <p>typ: {eqp.type}</p>
      <p>nr: {eqp.isTemplate ? "templatka" : eqp.number}</p>

      <p>podtyp: {eqp.subtype}</p>
      <p>charakterystyka: {eqp.char}</p>
      <p>kolor: {eqp.color}</p>
      <p>kategoria: {eqp.category}</p>
      <p>modyfikacje: {eqp.mods}</p>
      {!eqp.isTemplate && (
        <p>
          aktualna lokalizacja:
          {eqp.currLoc ? (
            <>
              {" "}
              ({eqp.currLoc?.inPlace ? "dostarczona" : "zarezerwowana"}){" "}
              <a
                href={`/Locations/${eqp.currLoc._id}`}
                target="_blank"
                rel="noopenr noreferrer"
              >
                {eqp.currLoc.name}
              </a>{" "}
              klient:{" "}
              <a
                href={`/CustomersDetails/${eqp.currLoc.customer._id}`}
                target="_blank"
                rel="noopenr noreferrer"
              >
                {eqp.currLoc.customer.shortName}
              </a>
            </>
          ) : (
            "Sprzęt na placu"
          )}
        </p>
      )}
    </Container>
  )
}
export default EquipmentDetailsModal
