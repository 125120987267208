import React, { useState } from "react"

import Table from "react-bootstrap/Table"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"

import GoodDetails from "./goodDetails"

const GoodsListRender = (props) => {
  const [goodChoosen, setGoodChoosen] = useState()

  if (goodChoosen) {
    return (
      <Container>
        <br />
        <Button onClick={(e) => setGoodChoosen()}>Wróć</Button>
        <GoodDetails good={goodChoosen} />
      </Container>
    )
  } else {
    return (
      <Container id="GoodsListRender">
        <Button
          className="float-right my-4"
          onClick={() => setGoodChoosen(true)}
        >
          Nowe
        </Button>

        <Table hover>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Jednostka</th>
              <th>Priorytet</th>
              <th>Stan</th>
            </tr>
          </thead>
          <tbody>
            {props.goods.map((good) => {
              const style =
                good.type === "main"
                  ? {
                      backgroundColor: "#d1ffff",
                      cursor: "pointer",
                    }
                  : { cursor: "pointer" }
              return (
                <tr
                  style={style}
                  onClick={(e) => {
                    setGoodChoosen(good)
                  }}
                  key={`tr-${good._id}`}
                >
                  <td>{good.name}</td>
                  <td>{good.unit}</td>
                  <td>{good.priority}</td>
                  {good.isActive ? (
                    <td>Aktywny</td>
                  ) : (
                    <td className="bg-danger">Nieaktywny</td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Container>
    )
  }
}
export default GoodsListRender
