import _ from "lodash"
import { Button } from "react-bootstrap"

const renderArchiveBtn = (user, customer, setModalData, refresh, locations) => {
  const toggleState = async () => {
    try {
      const res = await fetch(`/customers/toggleState`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer_id: customer._id,
          historyLength: customer.history.length,
        }),
      })
      if (res.status !== 200)
        throw new Error(`(${res.status}) ${(await res.text()) || ""}`)
      else
        setModalData({
          show: true,
          type: "info",
          header: "Sukces",
          headerColor: "success",
          body: "Zmiana zapisana",
          onHide: refresh,
        })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd komunikacji z serwerem: ${
          err.message || "nieokreślony błąd"
        }`,
      })
    }
  }

  // user can edit only when has perm.customers.e and permission to all customer branches
  //(but allow when it has active locations and is archived, what is error and should not happen, but did):
  if (
    !user.perm.customers.e ||
    _.difference(customer.branches, user.allowedBranches).length !== 0 ||
    (locations.filter((loc) => loc.state === "aktywna").length &&
      customer.state !== "archiwum")
  )
    return null
  else
    return (
      <Button onClick={toggleState} variant="warning">
        {customer.state === "aktywny" ? "Archiwizuj" : "Przywróć"}
      </Button>
    )
}

const locationsFilter = (loc, filter) => {
  const locName = loc.name.toLowerCase()
  const locStreet = loc.street.toLowerCase()
  const locCity = loc.city.toLowerCase()
  const lowerFilter = filter.toLowerCase()
  if (
    locName.includes(lowerFilter) ||
    locStreet.includes(lowerFilter) ||
    locCity.includes(lowerFilter)
  )
    return true
  else return false
}

export { renderArchiveBtn, locationsFilter }
