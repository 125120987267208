import { useMemo, useState } from "react"
import { Row, Button, Form, Badge, Col, Alert, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { format, differenceInCalendarDays, max, min } from "date-fns"

import { srvPriceChange } from "./submits"

const ChangePriceModal = ({ setModalData, srv, location, refresh }) => {
  const [isFetching, setIsFetching] = useState(false)
  const isInvoiced = useMemo(() => {
    const invoicedParams = srv.params.filter((prm) => prm.invoicedTo)
    if (invoicedParams.length) return true
    else return false
  }, [srv])

  const srvStartDate = useMemo(() => {
    return min(srv.params.map((prm) => new Date(prm.startDate)))
  }, [srv])

  const defaultValues = {
    changeDate: format(new Date(), "yyyy-MM-dd"),
    newPrice: srv.netPrice,
    changeType:
      srv.chargeType === "krótki termin" || srv.chargeType === "ryczałt impreza"
        ? "clearChange"
        : null,
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  })

  const submitWrapper = (formData) => {
    setIsFetching(true)
    srvPriceChange(
      formData,
      srv,
      location,
      setModalData,
      refresh,
      setIsFetching
    )
  }

  const renderForm = (changeType) => {
    return (
      <>
        {/* SHOW DATE INPUT AND ALERT ONLY WHEN HAVE TO CREATE INVOICE */}
        {changeType === "withInv" ? (
          <>
            <p className="bg-warning">
              Uwaga! <br />
              Zmiana ceny spowoduje automatyczne wygenerowanie faktury za
              dotychczasowy okres.{" "}
              <b>Koniecznie poinformuj osobę fakturującą!</b>
            </p>

            <Form.Label>
              Data zmiany (od tego dnia będzie obowiązywać nowa cena)
              {errors.changeDate && (
                <Badge pill variant="warning">
                  {errors.changeDate.message}
                </Badge>
              )}
            </Form.Label>
            <Form.Control
              {...register("changeDate", {
                required: "Pole wymagane",
                validate: {
                  notBeforeInvoicedTo: (v) => {
                    return (
                      differenceInCalendarDays(
                        new Date(v),
                        max(
                          srv.params.map(
                            (prm) => new Date(prm.invoicedTo || srvStartDate)
                          )
                        )
                      ) > 1 ||
                      "Data musi być min. o 2 dni większa od 'zafakturowano' lub startu usługi (patrz instr.)"
                    )
                  },
                },
              })}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
              id="changeDate"
            />
          </>
        ) : null}
        <>
          <Form.Label>
            Nowa cena netto:
            {errors.newPrice && (
              <Badge pill variant="warning">
                {errors.newPrice.message}
              </Badge>
            )}
          </Form.Label>
          <Form.Control
            {...register("newPrice", {
              required: "Pole wymagane",
              validate: {
                positive: (v) => v > 0 || "Wpisz wartość większą od zera",
                notEqualOld: (v) =>
                  v !== srv.netPrice || "Nowa cena musi być różna od starej",
              },
            })}
            as="input"
            type="number"
            step="any"
            className=""
            autoComplete="chrome-off"
            id="newPrice"
          />
        </>
        <Row className="justify-content-between mx-3">
          <Button
            onClick={() => setModalData({ show: false })}
            disabled={isFetching}
          >
            Anuluj
          </Button>
          <Button
            type="submit"
            variant="secondary"
            id="submitBtn"
            disabled={isFetching}
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Zapisz"}
          </Button>
        </Row>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit(submitWrapper)}>
      <h4>Rodzaj zmiany</h4>
      <p>Od kiedy ma obowiązywać nowa cena?</p>
      <Row className="mb-2">
        <Col>
          <Form.Check
            {...register("changeType")}
            type="radio"
            label="od daty"
            value="withInv"
            id="changeType"
            disabled={
              srv.chargeType === "krótki termin" ||
              srv.chargeType === "ryczałt impreza"
                ? true
                : false
            }
            data-test="withInv-radio"
          />
        </Col>
        <Col>
          <Form.Check
            {...register("changeType")}
            type="radio"
            label={isInvoiced ? "od ostatniej faktury" : "od początku usługi"}
            value="clearChange"
            id="changeType"
          />
        </Col>
      </Row>
      {srv.chargeType === "krótki termin" ||
      srv.chargeType === "ryczałt impreza" ? (
        <Alert className="bg-secondarySuperLight">
          Dla usług imprezowych nie ma możliwości zmiany ceny od wybranej daty
        </Alert>
      ) : null}
      {watch("changeType") ? renderForm(watch("changeType")) : null}
    </Form>
  )
}

export default ChangePriceModal
