import { useForm } from "react-hook-form"

import { Button, Form, Badge } from "react-bootstrap"
import { differenceInCalendarDays, format } from "date-fns"
import { getDefaultDates } from "../../utils/getDefaultDates"

//TODO add arrows to change months
//TODO disable submit on fetching

const InvStatsForm = ({ setFormData, setInitFetch, isLoading }) => {
  //get default dates depending on current day on month (if < 10 return last month, else return this month)
  const { startDate, endDate } = getDefaultDates(10)

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      dateType: "export",
      includeTest: false,
    },
  })

  const submit = (formData) => {
    setFormData(formData)
    setInitFetch(false)
  }

  return (
    <Form inline className="mt-3" onSubmit={handleSubmit(submit)}>
      <Form.Label className="mr-3 ml-5">Rodzaj daty: </Form.Label>
      {errors.dateType && (
        <Badge pill variant="warning">
          {errors.dateType.message}
        </Badge>
      )}
      <Form.Check
        {...register("dateType")}
        type="radio"
        label="faktury"
        value="invoice"
        id="invDate"
        className="mr-2"
      />
      <Form.Check
        {...register("dateType")}
        type="radio"
        label="wygenerowania"
        value="createdAt"
        id="invDate"
        className="mr-2"
      />
      <Form.Check
        {...register("dateType", { required: "pole wymagane" })}
        type="radio"
        label="eksportu"
        value="export"
        id="invDate"
        className="mr-2"
      />
      <Form.Label className="ml-5">od:</Form.Label>
      {errors.startDate && (
        <Badge pill variant="warning">
          {errors.startDate.message}
        </Badge>
      )}
      <Form.Control
        {...register("startDate", {
          validate: {
            required: (value) => !!value || "pole wymagane",
            smallerThenEndDate: (value) =>
              (differenceInCalendarDays(
                new Date(getValues("endDate")),
                new Date(value)
              ) <= 90 &&
                differenceInCalendarDays(
                  new Date(getValues("endDate")),
                  new Date(value)
                ) >= 0) ||
              "nieprawidłowe daty (maks 90 dni i 'od' < 'do')",
          },
        })}
        as="input"
        type="date"
        autoComplete="chrome-off"
        className="mr-3"
      />

      <Form.Label>do:</Form.Label>
      {errors.endDate && (
        <Badge pill variant="warning">
          {errors.endDate.message}
        </Badge>
      )}
      <Form.Control
        {...register("endDate", {
          required: "pole wymagane",
        })}
        as="input"
        type="date"
        className=""
        autoComplete="chrome-off"
      />

      <Form.Check
        {...register("includeTest")}
        type="checkbox"
        label="uwzględnij TEST"
        id="includeTest"
        className="ml-2"
      />

      <Button
        type="submit"
        className="ml-3"
        variant="secondary"
        disabled={isLoading}
      >
        Pobierz
      </Button>
    </Form>
  )
}

export { InvStatsForm }
