const renderInventoryCell = (eqp, location, jobSrv) => {
  console.log("eqp: ", eqp, "location: ", location, "srv: ", jobSrv)
  const inventoryArr = []

  for (let i = 0; i < eqp.qty; i++) {
    inventoryArr.push(
      <p style={{ whiteSpace: "pre", fontSize: "1rem" }}>
        NR: {"             "} , KOLOR: {"       "}, ZAWIESIA: tak/nie, TYP:
      </p>
    )
  }

  // when job eqp qty doesn't equal location eqp qty add empty cells
  const locSrv = location.services.find(
    (srv) => srv._id === jobSrv.locationServiceRef
  )
  const locEqp = locSrv.equipment.find(
    (locEqpEl) => locEqpEl.ref._id === eqp.ref._id
  )

  const qtyDiff = locEqp.qty - eqp.qty
  if (qtyDiff > 0) {
    inventoryArr.push(
      <hr />,
      <p>
        <b>NIE SERWISOWAĆ, TYLKO DO SPISANIA!</b>
      </p>
    )

    for (let i = 0; i < qtyDiff; i++) {
      inventoryArr.push(
        <p style={{ whiteSpace: "pre", fontSize: "1rem" }}>
          NR: {"             "} , KOLOR: {"       "}, ZAWIESIA: tak/nie, TYP:
        </p>
      )
    }
  }

  return inventoryArr
}

export { renderInventoryCell }
