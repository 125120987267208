import React from "react"
import { Row, Table, Col, Button, Alert } from "react-bootstrap"
import { FcRefresh } from "react-icons/fc"
import ReactTooltip from "react-tooltip"

import LocationChangeEqpNum from "../utils/LocationChangeEqpNum"

const LocationsDetailsEquipmentTable = ({
  services,
  setModalData,
  eqpTypes,
  loc_id,
}) => {
  const renderEqp = () => {
    return services.map((srv) => {
      if (srv.state === "aktywna" && srv.equipment.length) {
        return srv.equipment.map((eqp) => {
          if (!eqp.ref)
            return (
              <tr>
                <td>
                  <Alert variant="danger">ZGŁOŚ BŁĄD (no eqp.ref)</Alert>
                </td>
              </tr>
            )
          else
            return (
              <tr key={`srv-table-${srv._id}-${eqp._id}`}>
                <td>
                  {srv.locSrvNo || "?"}. {srv.name}
                </td>
                <td>{eqp.qty || "nd"}</td>
                <td>{eqp.inPlace}</td>
                <td>{eqp.ref.type}</td>
                <td>{eqp.ref.subtype}</td>
                <td>{eqp.ref.color}</td>
                <td>{eqp.ref.mods.join(", ")}</td>
                <td>
                  {eqp.ref.isTemplate ? "templatka" : eqp.ref.number}{" "}
                  <Button
                    size="sm"
                    variant="secondary"
                    // attributes for tooltip about delivering whole eqp requirement
                    data-tip
                    data-for="eqpChangeBtn"
                    onClick={() =>
                      eqp.qty === eqp.inPlace &&
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        hideFooter: true,
                        body: (
                          <LocationChangeEqpNum
                            setModalData={setModalData}
                            oldEqp={eqp}
                            srv={srv}
                            eqpTypes={eqpTypes}
                            loc_id={loc_id}
                          />
                        ),
                      })
                    }
                  >
                    <FcRefresh />
                    {/* show tooltip only when not all eqp delivered: */}
                    {eqp.qty === eqp.inPlace ? null : (
                      <ReactTooltip id="eqpChangeBtn">
                        Najpierw cały sprzęt musi być dostarczony
                      </ReactTooltip>
                    )}
                  </Button>
                </td>
              </tr>
            )
        })
      } else return null
    })
  }

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="6">
            <Row>
              <Col>Sprzęt</Col>
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Usługa</td>
          <td className="fixed-values-table">Liczba</td>
          <td className="fixed-values-table">Dostarczony?</td>
          <td className="fixed-values-table">Typ</td>
          <td className="fixed-values-table">Podtyp</td>
          <td className="fixed-values-table">Kolor</td>
          <td className="fixed-values-table">Modyfikacje</td>
          <td className="fixed-values-table">Numer</td>
        </tr>
        {renderEqp()}
      </tbody>
    </Table>
  )
}
export default LocationsDetailsEquipmentTable
