import React, { useContext, useState } from "react"
import { Table, Container, Col, Row, Button, Form } from "react-bootstrap"
import _ from "lodash"

import { dateParse, nF } from "../../../utils/formats"

import { useForm, useFieldArray } from "react-hook-form"

import { EmployeesDetailsCtx } from "../Fetch"

import { contractDetailsModal } from "../modals"

import { contractSubmit, deleteContract } from "./submit"

//contract, setModalData, user, configs
const EmployeesDetailsEditContract = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)
  const contract = ctx.employee.contracts[props.contractInd]
  const [arrayFieldRemoved, setArrayFieldRemoved] = useState(false)

  // when agreement (state==="ustalenia") change some table data:
  const state = props.contractInd === -1 ? props.contractState : contract.state

  const onSubmit = async (formData) => {
    if (arrayFieldRemoved) dirtyFields.burdens = true
    await contractSubmit(
      props.contractInd,
      { ...formData, history: contract?.history },
      ctx.employee,
      dirtyFields,
      ctx.setModalData,
      ctx.refresh
    )
  }

  const defaultValues =
    props.contractInd !== -1
      ? {
          ...contract,
          endDate: contract.endDate ? dateParse(contract.endDate, false) : null,
          //   conclusionDate: new Date(),
          conclusionDate: contract.conclusionDate
            ? dateParse(contract.conclusionDate, false)
            : null,
          modificationDate: contract.modificationDate
            ? dateParse(contract.modificationDate, false)
            : null,
        }
      : {
          employerCost: null,
          isHourly: props.isHourly,
        }

  const { handleSubmit, register, watch, setValue, formState, control } =
    useForm({
      defaultValues: defaultValues,
    })

  const { fields, append, remove } = useFieldArray({ control, name: "burdens" })
  const watchBurdens = watch("burdens", [])

  const { dirtyFields } = formState

  // register({ name: "state", value: "aktywna" })
  // register({ name: "_id", value: contract?._id || null })
  register("state", { value: props.contractState })
  if (props.contractInd !== -1) {
    register("state", { value: contract.state })
    register("_id", { value: contract._id })
    register("files", { value: contract.files })
  }

  return (
    <Container>
      <Button
        onClick={() => {
          if (props.contractInd === -1) return ctx.setModalData({ show: false })
          else
            return ctx.setModalData({
              show: true,
              type: "info",
              header: "Szczegóły umowy",
              body: contractDetailsModal(
                contract,
                ctx.setModalData,
                ctx.user,
                ctx.configs,
                ctx.employee
              ),
              xl: true,
            })
        }}
      >
        Wróć
      </Button>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        <Table bordered className="mb-0">
          <tbody>
            <tr>
              <td className="fixed-values-table">Rodzaj umowy</td>
              <td className="fixed-values-table">Firma</td>

              <td className="fixed-values-table">
                {state === "ustalenia"
                  ? "Ustalona data zawarcia"
                  : "Data zawarcia"}
              </td>
              <td className="fixed-values-table">
                {state === "ustalenia" ? "Data ustaleń" : "Data zmiany"}
              </td>
              <td className="fixed-values-table">Data zakończenia</td>
            </tr>
            <tr>
              <td>
                <Col>
                  <Form.Control as="select" {...register("mainType")}>
                    <option value="UP">UP</option>
                    <option value="UZ">UZ</option>
                    <option value="UW">UW</option>
                    <option value="Pow">Pow</option>
                    <option value="UCP">UCP</option>
                    <option value="Inna">Inna</option>
                  </Form.Control>
                  <Form.Check {...register("isHourly")} label="godzinowa" />
                </Col>
              </td>
              <td>
                <Form.Control as="select" {...register("company")}>
                  <option value="Clipper">Clipper</option>
                  <option value="Ecoloxx">Ecoloxx</option>
                </Form.Control>
              </td>

              <td>
                <Form.Control
                  type="date"
                  {...register("conclusionDate")}
                ></Form.Control>
              </td>
              <td>
                <Form.Control
                  type="date"
                  {...register("modificationDate")}
                ></Form.Control>
              </td>
              <td>
                <Form.Control
                  type="date"
                  {...register("endDate")}
                ></Form.Control>

                {/* indefinite time checkbox - if checked -> clear endDate, if unchecked -> insert value from props or today (when new contract or no endDate) */}
                <Form.Check
                  label="nieokreślony"
                  {...register("indefiniteTime")}
                  onClick={() => {
                    if (watch("indefiniteTime"))
                      return setValue("endDate", null)
                    else {
                      const endDate =
                        contract && contract.endDate
                          ? dateParse(contract.endDate, false)
                          : dateParse(new Date(), false)

                      return setValue("endDate", endDate)
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table">Stanowisko</td>
              <td className="fixed-values-table" colSpan="5">
                Notatka do umowy
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control as="select" {...register("position")}>
                  {props.configs.employeePositions.map((position) => (
                    <option key={`employee-position-${position}`}>
                      {position}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td colSpan="5">
                <Form.Control as="textarea" {...register("note")} cols="50" />
              </td>
            </tr>
          </tbody>
        </Table>

        {/* show different tables depending if contract is paid per hour or per month (more details in employeeSchema) */}
        {watch("isHourly") ? (
          <div>
            <Table bordered>
              <tbody>
                <tr className="fixed-values-table">
                  <td></td>
                  <td>Podstawowa brutto</td>
                  <td>Netto/godz</td>
                  <td>Koszt pracodawcy/godz</td>
                </tr>
                <tr>
                  <td className="fixed-values-table">Stawka</td>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("grossSalary")}
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("netSalary")}
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("employerCost")}
                      className="large-number-input"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table bordered>
              <thead>
                <tr>
                  <th colSpan="4">Bonusy miesięczne (nagrody/dodatki)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fixed-values-table">Netto</td>
                  <td className="fixed-values-table">Brutto</td>
                  <td className="fixed-values-table">Koszt</td>
                </tr>

                <tr>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("netBonus")}
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("grossBonus")}
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      step="any"
                      {...register("bonusEmployerCost")}
                      className="large-number-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fixed-values-table">Notatka</td>
                  <td colSpan="2">
                    <Form.Control
                      as="textarea"
                      rows="1"
                      {...register("bonusNote")}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <Table bordered>
            <tbody>
              <tr>
                <td className="fixed-values-table"></td>
                <td className="fixed-values-table">Netto</td>
                <td className="fixed-values-table">Brutto</td>
                <td className="fixed-values-table">Koszt</td>
                <td className="fixed-values-table">Koszt z mtv</td>
              </tr>
              <tr>
                <td className="fixed-values-table">Podstawa</td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("netSalary")}
                    className="large-number-input"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("grossSalary")}
                    className="large-number-input"
                    onChange={(e) =>
                      setValue(
                        "employerCost",
                        _.round(Number.parseFloat(e.target.value) * 1.2028, 2)
                      )
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("employerCost")}
                    className="large-number-input"
                  />
                </td>
                <td>
                  <Row className="justify-content-center">
                    {/* calculate employerCost with Motivation - '|| 0 (or 1)' are to prevent NaN*/}
                    <Col>
                      <p className="my-auto">
                        {nF((watch("employerCost") || 0) * 1.1)}
                      </p>
                    </Col>
                    <Col>
                      <Form.Control {...register("motivation")} as="select">
                        <option value="true">dotyczy</option>
                        <option value="false">nie dotyczy</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table">Nagrody</td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("netBonus")}
                    className="large-number-input"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("grossBonus")}
                    className="large-number-input"
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    step="any"
                    {...register("bonusEmployerCost")}
                    className="large-number-input"
                  />
                </td>
                <td className="fixed-values-table">Notatka do nagród</td>
              </tr>
              <tr>
                <td className="fixed-values-table">Suma</td>
                <td>
                  {nF(
                    Object.values(watch(["netSalary", "netBonus"])).reduce(
                      // || 0 - when new contract, to prevent NaN
                      (acc, curr) => acc + (Number.parseFloat(curr) || 0),
                      0
                    )
                  )}
                </td>
                <td>
                  {nF(
                    Object.values(watch(["grossSalary", "grossBonus"])).reduce(
                      // || 0 - when new contract, to prevent NaN
                      (acc, curr) => acc + (Number.parseFloat(curr) || 0),
                      0
                    )
                  )}
                </td>
                <td>
                  {nF(
                    Object.values(
                      watch(["employerCost", "bonusEmployerCost"])
                    ).reduce(
                      // || 0 - when new contract, to prevent NaN:
                      (acc, curr) => acc + (Number.parseFloat(curr) || 0),
                      0
                    )
                  )}
                </td>

                <td>
                  <Form.Control as="textarea" {...register("bonusNote")} />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <Table>
          <thead>
            <tr>
              <th colSpan="6">
                <Row>
                  <Col>Dodatkowe obciążenia</Col>
                  <Col xs={{ offset: 9 }}>
                    <Button
                      onClick={() =>
                        append({
                          name: "",
                          employeeCost: "",
                          employerCost: "",
                          note: "",
                        })
                      }
                      variant="secondary"
                      size="sm"
                    >
                      Dodaj wiersz
                    </Button>
                  </Col>
                </Row>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="fixed-values-table">
              <td>Nazwa</td>
              <td>Część pracownika</td>
              <td>Część pracodawcy</td>
              <td>Suma</td>
              <td>Notatki</td>
              <td />
            </tr>
            {fields.map((row, i) => {
              // register _id of burden (if it existed later) to keep track of history etc
              if (row._id)
                register({ name: `burdens[${i}]._id`, value: row._id })
              return (
                <tr key={row.id}>
                  <td>
                    <Form.Control
                      key={row.id}
                      {...register(`burdens.${i}.name`)}
                      defaultValue={row.name}
                    />
                  </td>
                  <td>
                    <Form.Control
                      key={row.id}
                      {...register(`burdens.${i}.employeeCost`)}
                      type="number"
                      defaultValue={row.employeeCost}
                      step="any"
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    <Form.Control
                      key={row.id}
                      {...register(`burdens.${i}.employerCost`)}
                      type="number"
                      defaultValue={row.employerCost}
                      step="any"
                      className="large-number-input"
                    />
                  </td>
                  <td>
                    {nF(
                      Number.parseFloat(
                        watch(`burdens[${i}].employerCost`) || 0
                      ) +
                        Number.parseFloat(
                          watch(`burdens[${i}].employeeCost`) || 0
                        )
                    )}
                  </td>
                  <td>
                    <Form.Control
                      key={row.id}
                      {...register(`burdens[${i}].note`)}
                      defaultValue={row.note}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => {
                        // react-hook-form is not setting dirtyField when fieldArray gets removed, I'm handling this other way to make sure that history entry will be created
                        setArrayFieldRemoved(true)
                        remove(i)
                      }}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className="fixed-values-table sum-row">
              <td>Suma</td>
              {/* employee cost sum */}
              <td>
                {watchBurdens.length
                  ? nF(
                      watchBurdens.reduce((acc, curr) => {
                        if (isNaN(Number.parseFloat(curr.employeeCost)))
                          return acc
                        else return acc + Number.parseFloat(curr.employeeCost)
                      }, 0)
                    )
                  : 0}
              </td>
              {/* employer cost sum */}
              <td>
                {watchBurdens.length
                  ? nF(
                      watchBurdens.reduce((acc, curr) => {
                        if (isNaN(Number.parseFloat(curr.employerCost)))
                          return acc
                        else return acc + Number.parseFloat(curr.employerCost)
                      }, 0)
                    )
                  : 0}
              </td>
              {/* total sum */}
              <td>
                {watchBurdens.length
                  ? nF(
                      watchBurdens.reduce((acc, curr) => {
                        if (isNaN(Number.parseFloat(curr.employerCost)))
                          return acc
                        else return acc + Number.parseFloat(curr.employerCost)
                      }, 0) +
                        watchBurdens.reduce((acc, curr) => {
                          if (isNaN(Number.parseFloat(curr.employeeCost)))
                            return acc
                          else return acc + Number.parseFloat(curr.employeeCost)
                        }, 0)
                    )
                  : 0}
              </td>
              <td />
              <td />
            </tr>
          </tfoot>
        </Table>

        <Row>
          {props.user.perm.employees.d && contract ? (
            <Button
              className="ml-2"
              variant="danger"
              onClick={() =>
                ctx.setModalData({
                  show: true,
                  type: "delete",
                  handleDelete: () =>
                    deleteContract(
                      ctx.employee._id,
                      props.contractInd,
                      ctx.setModalData,
                      ctx.refresh
                    ),
                })
              }
            >
              Usuń
            </Button>
          ) : null}

          <Button variant="secondary" type="submit" className="ml-auto mr-3">
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default EmployeesDetailsEditContract
