import { getActionIcon } from "./getActionIcon"

const { differenceInCalendarDays } = require("date-fns")

const prepareCustomers = (customers) => {
  try {
    // for each customer find its last action and contacts (main and all payment contacts)
    // return customer with additional lastAction and paymentContacts

    const customersPrepared = customers.map((customer) => {
      return {
        ...customer,
        lastAction: getLastAction(customer),
        debtCollectionCtcs: getContacts(customer),
      }
    })

    return customersPrepared
  } catch (err) {
    console.log(err)
    throw err
  }
}

const getContacts = (customer) => {
  const mainContact = customer.contacts.find(
    (contact) => contact.type === "główny" && contact.state === "aktywny"
  )
  // preserve only proper mail (some customers have  'bd' or similar)
  if (mainContact) mainContact.mail = checkMail(mainContact.mail)

  const paymentContacts = customer.contacts.filter(
    (contact) => contact.type === "płatności" && contact.state === "aktywny"
  )

  if (paymentContacts.length)
    // preserve only proper mails (some customers have  'bd' or similar)
    paymentContacts.forEach(
      (contact) => (contact.mail = checkMail(contact.mail))
    )

  const contacts = { main: null, payment: [] }
  if (mainContact) contacts.main = mainContact
  if (paymentContacts.length) contacts.payment = paymentContacts

  return contacts
}

const checkMail = (mail) => (mail && mail.includes("@") ? mail : null)

/**
 * finds last debtCollection action for customer. Uses eventDate, or when not present or multiplied -> first history entry date
 * @param {object} customer
 */
const getLastAction = (customer) => {
  let actions = customer.notes.filter(
    (note) => note.type === "windykacyjna" && note.state === "aktywna"
  )
  if (!actions.length) return null

  let lastAction = {}
  // not all notes will have eventDate, use date of first history entry for that missing

  actions = actions.map((note) => {
    if (!note.eventDate) note.eventDate = note.history[0].date

    note.eventDate = new Date(note.eventDate)

    return note
  })

  //TODO check if this sorting is descending
  // sort notes by eventDate
  actions.sort((a, b) => {
    if (a.eventDate > b.eventDate) return -1
    if (a.eventDate < b.eventDate) return 1
    return 0
  })

  // compare first two notes by event date when they are on the same day (use history entry date to get exact date
  // and return earliest one)

  if (
    actions.length > 1 &&
    differenceInCalendarDays(actions[0].eventDate, actions[1].eventDate) === 0
  ) {
    const action0CreationDate = actions[0].history[0].date

    const action1CreationDate = actions[1].history[0].date

    if (action0CreationDate > action1CreationDate) lastAction = actions[0]
    else lastAction = actions[1]
  } else lastAction = actions[0]

  return { ...lastAction, icon: getActionIcon(lastAction) }
}

export { prepareCustomers }
