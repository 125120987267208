import React, { useEffect, useState } from "react"
import { Table, Button, Container, Form, Row } from "react-bootstrap"

const PermissionAssignModal = ({ user, location, setModalData, refresh }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const res = await fetch(
          `/sewage/permissions?branches=${user.allowedBranches.join(
            ","
          )}&state=aktywne`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setPermissions(resJSON.permissions)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [setModalData, user])
  return (
    <div>
      {isLoading ? (
        "Pobieram dane"
      ) : (
        <PermissionAssignForm
          permissions={permissions}
          location={location}
          setModalData={setModalData}
          refresh={refresh}
        />
      )}
    </div>
  )
}

const PermissionAssignForm = ({
  permissions,
  location,
  refresh,
  setModalData,
}) => {
  const [permissionsFiltered, setPermissionsFiltered] = useState([
    ...permissions,
  ])

  const submitPermission = async (permission_id) => {
    try {
      const res = await fetch(`/locations/edit/${location._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          location: { _id: location._id, permission: permission_id },
          dirtyFields: { permission: true },
          historyLength: location.history.length,
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )
      setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Zmiana zapisana",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: err.message })
    }
  }
  return (
    <Container>
      <p>Obecnie: {location.permission?.municipal || "brak"}</p>
      Filtr:
      <Form.Control
        name="query"
        as="input"
        type=""
        className=""
        autoComplete="chrome-off"
        onChange={(e) => {
          const regex = new RegExp(`${e.target.value}`, "gi")

          setPermissionsFiltered(
            permissions.filter((perm) => regex.test(perm.municipal))
          )
        }}
      />
      <Row>
        <Button variant="secondary" className="ml-auto mr-2">
          Zapisz
        </Button>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Pozwolenie</th>
          </tr>
        </thead>
        <tbody>
          {permissionsFiltered.map((permission) => (
            <tr
              key={`permissionRow-${permission._id}`}
              className="clickable"
              onClick={() => submitPermission(permission._id)}
            >
              <td>{permission.municipal}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
export default PermissionAssignModal
