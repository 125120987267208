import React, { useContext } from "react"
import { Table, Container, Row, Button, Form, Badge } from "react-bootstrap"
import { GoAlert } from "react-icons/go"

import { useForm } from "react-hook-form"

import { EmployeesDetailsCtx } from "../Fetch"

import { generalSubmit } from "./submit"
import { checkAccNumber, checkPesel } from "./utils"
import format from "date-fns/format"

const EmployeesDetailsEditGeneral = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)

  const defaultValues = { ...props.employee }

  //* format health check and osh training -->
  if (defaultValues.shouldCheckHealth)
    defaultValues.healthCheck = format(
      new Date(defaultValues.healthCheck),
      "yyyy-MM-dd"
    )
  if (defaultValues.shouldTrainOsh)
    defaultValues.oshTraining = format(
      new Date(defaultValues.oshTraining),
      "yyyy-MM-dd"
    )
  //* <--

  const { register, handleSubmit, formState, watch } = useForm({
    defaultValues: defaultValues,
  })

  const { errors } = formState

  const { dirtyFields } = formState
  register("_id", props.employee._id)
  return (
    <Container>
      <Button
        onClick={() => props.setModalData({ show: false })}
        className="mb-1"
      >
        Wróć
      </Button>

      <Form
        autoComplete="chrome-off"
        onSubmit={handleSubmit((data) =>
          generalSubmit(
            { ...data, history: ctx.employee.history },
            dirtyFields,
            props.setModalData,
            ctx.refresh
          )
        )}
      >
        <Table bordered>
          <thead>
            <tr>
              <th colSpan="4">Dane pracownika</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fixed-values-table">
                Imię
                {errors.firstName && (
                  <div className="bg-warning">
                    {" "}
                    <GoAlert /> Wymagane
                  </div>
                )}
              </td>
              <td className="fixed-values-table">
                Nazwisko
                {errors.lastName && (
                  <p className="bg-warning">
                    {" "}
                    <GoAlert /> Wymagane
                  </p>
                )}
              </td>
              <td className="fixed-values-table">Dzieci</td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register("firstName", { required: true })}
                  autoComplete="chrome-off"
                />
              </td>
              <td>
                <Form.Control
                  {...register("lastName", { required: true })}
                  autoComplete="chrome-off"
                />
              </td>
              <td>
                <Form.Control
                  as="textarea"
                  {...register("kids")}
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <th colSpan="2">Terminy</th>
            </tr>
          </thead>
          <tbody>
            <tr className="fixed-values-table">
              <td>
                Badanie lekarskie{" "}
                {errors.healthCheck && (
                  <Badge pill variant="warning">
                    {errors.healthCheck.message}
                  </Badge>
                )}
              </td>
              <td>Szkolenie BHP</td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  {...register("shouldCheckHealth")}
                  type="checkbox"
                  label="dotyczy"
                  id="shouldCheckHealth"
                />
                {watch("shouldCheckHealth") ? (
                  <Form.Control
                    {...register("healthCheck", {
                      validate: (v) =>
                        !(watch("shouldCheckHealth") && !v) || "Pole wymagane",
                    })}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                ) : null}
              </td>
              <td>
                <Form.Check
                  {...register("shouldTrainOsh")}
                  type="checkbox"
                  label="dotyczy"
                  id="shouldCheckHealth"
                />
                {watch("shouldTrainOsh") ? (
                  <Form.Control
                    {...register("oshTraining", {
                      validate: (v) =>
                        !(watch("shouldCheckHealth") && !v) || "Pole wymagane",
                    })}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                ) : null}
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <th colSpan="4">
                Dane kontaktowe - <b>prywatne</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Mail{" "}
                {errors.privData?.mail && (
                  <Badge pill variant="warning">
                    Błędny adres e-mail
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">Telefon</td>
              <td className="fixed-values-table">
                PESEL{" "}
                {errors.privData?.pesel?.type === "required" && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
                {errors.privData?.pesel?.type === "validate" && (
                  <Badge pill variant="warning">
                    Zła wartość
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  type="mail"
                  {...register("privData.mail", {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  autoComplete="chrome-off"
                />
              </td>
              <td>
                <Form.Control
                  {...register("privData.phone")}
                  autoComplete="chrome-off"
                />
              </td>
              <td>
                <Form.Control
                  id="peselInput"
                  type="number"
                  {...register("privData.pesel", {
                    required: true,
                    validate: (val) => checkPesel(val),
                  })}
                />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Adres
              </td>
              <td className="fixed-values-table">Kod</td>
              <td className="fixed-values-table">Urząd skarbowy</td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register("privData.street")}
                  autoComplete="chrome-off"
                />
              </td>
              <td colSpan="1">
                <Form.Control
                  {...register("privData.postal")}
                  type="text"
                  autoComplete="chrome-off"
                />
              </td>
              <td rowSpan="3">
                <Form.Control
                  {...register("privData.taxOffice")}
                  id="taxOfficeInput"
                  as="textarea"
                  rows="3"
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Miasto
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <Form.Control
                  {...register("privData.city")}
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Nr. konta (bez "PL"){" "}
                {errors.privData?.bankAccount && (
                  <Badge pill variant="warning">
                    Błędny numer konta
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">Bank</td>
            </tr>
            <tr>
              <td colSpan="3">
                <Form.Control
                  {...register("privData.bankAccount", {
                    validate: (val) => checkAccNumber(val),
                  })}
                  autoComplete="chrome-off"
                />
              </td>
              <td>
                <Form.Control
                  {...register("privData.bankName")}
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        {ctx.user.type.board ? (
          <Table id="superiorsTable">
            <thead>
              <tr>
                <th colSpan="2">Wybierz przełożonych</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {/* show all employees without employee being edited and board (board is superior for each employee) */}
                  <div style={{ columnCount: 3 }}>
                    {props.allEmployees.map((empl, i) => {
                      if (
                        empl._id === props.employee._id &&
                        empl.type.board &&
                        !ctx.user.type.admin
                      )
                        return null
                      else
                        return (
                          <Form.Check
                            label={empl.fullName}
                            value={empl._id}
                            {...register(`superior`)}
                            key={`superiorCheckbox-${empl._id}`}
                          />
                        )
                    })}
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        <Row>
          <Button
            type="submit"
            variant="secondary"
            className="ml-auto"
            id="submitBtn"
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default EmployeesDetailsEditGeneral
