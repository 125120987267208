import React from "react"
import { read, utils } from "xlsx"

const TestsParent = () => {
  const readFile = async (e) => {
    const file = e.target.files[0]
    const data = await file.arrayBuffer()
    const wb = read(data)
    console.log(wb)

    const dataParsed = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]) // wb.SheetNames[0] is the name of the first sheet (wb.Sheets is an object with objects)
    console.log(dataParsed)
  }

  const fetchGps = async () => {
    try {
      const res = await fetch("http://localhost:3000/gps/get")
      if (res.status !== 200) throw res
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <input type="file" onChange={readFile} />
      <button onClick={fetchGps}>GPS</button>
    </div>
  )
}
export default TestsParent
