// details of invoice. Clicking on invoice position reveals/hides source information
// button to export shown invoice

import React, { useContext, useState } from "react"
import _ from "lodash"
import { Container, Row, Table, Button, Alert } from "react-bootstrap"
import { format } from "date-fns"
import { ImNewTab } from "react-icons/im"

import UserContext from "../../contexts/userContext"
import HistoryTable from "../../utils/HistoryTable"
import { handleDelete } from "./utils"
import ExportConfirmModal from "./ExportConfirmModal"
import EditInvoiceFetch from "../EditInvoice/Fetch"
import { UndoExportConfirmModal } from "./UndoExportConfirmModal"

const InvListDetailsModal = ({
  invoice,
  setModalData,
  configs,
  refresh,
  isPreview,
}) => {
  const user = useContext(UserContext)
  const [showInvMods, setShowInvMods] = useState(false)

  const canDeleteInvoice = () => {
    if (invoice.exportDates.length === 0 && user.perm.invoicing.d) return true
    else if (invoice.exportDates.length !== 0 && user.type.admin) return true
    else return false
  }

  const renderInvModsRow = (product) => {
    if (!product.invMods.length)
      return (
        <tr className="fixed-values-table">
          <td colSpan="7">Brak modyfikacji</td>
        </tr>
      )
    else
      return (
        <>
          <tr>
            <td className="fixed-values-table" colSpan="7">
              Modyfikacje:
            </td>
          </tr>
          <tr>
            <td colSpan="7">
              <ul>
                {product.invMods.map((invMod) => (
                  <li key={`invModRow-${invMod._id}`}>{`Kwota: ${
                    invMod.amount
                  }, data: ${format(
                    new Date(invMod.applyDate),
                    "yyyy-MM-dd"
                  )}, dodał: ${
                    invMod.history[0]?.user?.fullName || "BŁĄD - brak danych"
                  }, opis:${invMod.desc}`}</li>
                ))}
              </ul>
            </td>
          </tr>
        </>
      )
  }

  const renderHeader = () => {
    if (isPreview === true) return null
    return (
      <>
        <Alert variant="warning">
          Uwaga! <br />
          Dane adresowe klienta (i nabywcy, jeśli jest przypisany do klienta)
          zostaną dodane podczas eksportowania faktur.
          <br />
          <u>Powyższe nie dotyczy danych o płatności</u> (termin, rabat za
          terminowość, forma płatności)
        </Alert>
        <Row className="mb-2 justify-content-between">
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                header: `historia faktury`,
                body: (
                  <HistoryTable
                    history={invoice.history}
                    goBack={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: (
                          <InvListDetailsModal
                            invoice={invoice}
                            setModalData={setModalData}
                            refresh={refresh}
                            configs={configs}
                          />
                        ),
                      })
                    }
                  />
                ),
              })
            }
          >
            Historia
          </Button>
          {canDeleteInvoice() ? (
            <Button
              variant="danger"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "confirm",
                  header: "Potwierdź usunięcie faktury",
                  body: (
                    <p>
                      Czy na pewno chcesz usunąć fakturę? <br /> Operacja jest{" "}
                      <b>nieodwracalna</b>!
                    </p>
                  ),
                  headerColor: "danger",
                  func: () => handleDelete(invoice._id, setModalData, refresh),
                })
              }
            >
              Usuń
            </Button>
          ) : null}

          {user.perm.invoicing.e ? (
            <Button
              variant="secondary"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Edycja faktury",
                  xl: true,
                  body: (
                    <EditInvoiceFetch
                      invoice={invoice}
                      setModalData={setModalData}
                      refresh={refresh}
                    />
                  ),
                })
              }
            >
              Edytuj
            </Button>
          ) : null}
          {invoice.finAppId && user.perm.invoicing.d ? (
            <Button
              variant="danger"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Eksport do Symfonii",
                  xl: true,
                  body: (
                    <UndoExportConfirmModal
                      invoice={invoice}
                      setModalData={setModalData}
                      refresh={refresh}
                    />
                  ),
                })
              }
            >
              Cofnij eksport
            </Button>
          ) : null}
          <Button
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Eksport do Symfonii",
                xl: true,
                body: (
                  <ExportConfirmModal
                    configs={configs}
                    invoices={[invoice]}
                    setModalData={setModalData}
                    refresh={refresh}
                  />
                ),
              })
            }
          >
            Eksportuj
          </Button>
        </Row>
        <p className="m-0" style={{ color: "white" }}>
          {invoice._id}
        </p>
        <Table bordered>
          <tbody>
            {invoice.exportDates.length ? (
              <tr>
                <td className="fixed-values-table">Faktura wyeksportowana:</td>
                <td className="bg-warningLight" colSpan="3">
                  {invoice.exportDates
                    .map((expDate) =>
                      format(new Date(expDate), "yyyy-MM-dd HH:mm")
                    )
                    .join(", ")}
                </td>
                <td className="fixed-values-table">Numer:</td>
                <td>
                  {invoice.finAppNumber && invoice.finAppId ? (
                    <a
                      href={`https://clipper.fakturownia.pl/invoices/${invoice.finAppId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {invoice.finAppNumber} <ImNewTab />
                    </a>
                  ) : (
                    <i>brak</i>
                  )}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="fixed-values-table">Data faktury</td>
              <td>
                {format(
                  new Date(invoice.InvoiceDetails.InvoiceDate),
                  "yyyy-MM-dd"
                )}
              </td>
              <td className="fixed-values-table">Data sprzedaży</td>
              <td>
                {invoice.InvoiceDetails.SellDate ? (
                  format(
                    new Date(invoice.InvoiceDetails.SellDate),
                    "yyyy-MM-dd"
                  )
                ) : (
                  <i>
                    {format(
                      new Date(invoice.InvoiceDetails.InvoiceDate),
                      "yyyy-MM-dd"
                    )}
                  </i>
                )}
              </td>
              <td className="fixed-values-table">Data wygenerowania</td>
              <td>{format(new Date(invoice.createdAt), "yyyy-MM-dd HH:mm")}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Klient</td>
              <td colSpan="5">
                <a
                  href={`/CustomersDetails/${invoice.clientResult.ref._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {invoice.clientResult.ref.shortName ||
                    invoice.clientResult.ref.name}
                  <ImNewTab />
                </a>
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">Rodzaj płatności</td>
              <td colSpan="2">
                {invoice.InvoiceDetails.payments.InvoicePaymentType}
              </td>
              <td className="fixed-values-table">Termin</td>
              <td colSpan="2">
                {invoice.InvoiceDetails.payments.InvoicePaymentDays}
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }

  return (
    <Container>
      {renderHeader()}

      <Row className="my-3">
        <h4>Pozycje</h4>
        <Button
          onClick={() => setShowInvMods(!showInvMods)}
          className="ml-auto mr-2"
        >
          {showInvMods ? "Ukryj modyfikacje" : "Pokaż modyfikacje"}
        </Button>
      </Row>
      <Table>
        <thead>
          <tr>
            <td>Nazwa</td>
            <td>Cena netto</td>
            <td>VAT %</td>
            <td>Cena brutto</td>
            <td>Ilość</td>
            <td>Wartość netto</td>
            <td>Wartość brutto</td>
            {user.type.tester && <td>dane</td>}
          </tr>
        </thead>
        <tbody>
          {invoice.InvoiceDetails.productsResults?.map((product) => {
            return (
              <RenderInvLine
                product={product}
                showInvMods={showInvMods}
                renderInvModsRow={renderInvModsRow}
                key={`productRow-${product._id}`}
                user={user}
              />
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const RenderInvLine = ({ product, showInvMods, renderInvModsRow, user }) => {
  const [showSource, setShowSource] = useState(false)
  return (
    <>
      <tr>
        <td id="productName">{product.productName}</td>
        <td>{product.productInvoicePriceNet}</td>
        <td>{product.productVat}</td>
        <td>{product.productInvoicePrice}</td>
        <td>{product.productQuantity}</td>
        <td>
          {_.round(product.productQuantity * product.productInvoicePriceNet, 2)}
        </td>
        <td>
          {_.round(product.productQuantity * product.productInvoicePrice, 2)}
        </td>
        {user.type.tester && (
          <td>
            <Button onClick={() => setShowSource(!showSource)}>?</Button>
          </td>
        )}
      </tr>
      {showInvMods ? renderInvModsRow(product) : null}
      {showSource && (
        <tr>
          <td className="fixed-values-table">Źródło obliczeń:</td>
          <td colSpan="7">{product.source}</td>
        </tr>
      )}
    </>
  )
}
export default InvListDetailsModal
