import React, { useContext } from "react"
import { Button, Container, Table, Row } from "react-bootstrap"

import UserContext from "../../contexts/userContext"

import { hundrethComma } from "../../utils/formats"

const ConsumptionsReportRender = (props) => {
  const user = useContext(UserContext)
  return (
    <Container className="mt-2">
      <Row>
        <Button href="/consumptionsReport">Wróć</Button>
        {user.perm.consumptionsList.d ? (
          <Button
            className="ml-auto"
            variant="warning"
            onClick={() =>
              props.setModalData({
                show: true,
                type: "confirm",
                body: "Oznaczyć wszystkie zużycia jako zapisane?",
                func: props.handleStateChange,
              })
            }
            id="changeAllToSavedBtn"
          >
            Oznacz wszystkie jako "zapisane"
          </Button>
        ) : null}
      </Row>
      <h3 className="mt-2">Zużycia:</h3>

      <Table>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Koszt magazynowy?</th>
            <th>Zużycie</th>
            <th>Przelicznik jednostek</th>
            <th>Zużycie przeliczone</th>
            <th>Cena jedn.</th>
            <th>Wartość</th>
          </tr>
        </thead>
        <tbody>
          {props.reportData.map((good) => {
            return (
              <tr
                className={good.isWarehouseCost ? "bg-secondary" : null}
                key={`good-row-${good._id}`}
              >
                <td>{good.name}</td>
                <td>{good.isWarehouseCost ? "tak" : "nie"}</td>
                <td>{good.quantity}</td>
                <td>
                  {good.unit}
                  <b>{"->"}</b>
                  {good.finalUnit}
                </td>
                <td>{good.quantity * good.divider}</td>
                {/* display sums after checking if price, quantity and divider are numbers not empty strings: */}
                <td>{good.price && hundrethComma(good.price)}</td>
                <td>
                  {good.price &&
                    hundrethComma(good.price * good.quantity * good.divider)}
                </td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="6">
              <Row>
                <span className="ml-auto mr-2">Suma kosztów magazynowych:</span>
              </Row>
            </td>
            <td>
              {hundrethComma(
                props.reportData.reduce((acc, curr) => {
                  // prevent NaN error when any of numeric properties is undefined or null:
                  if (
                    curr.isWarehouseCost &&
                    curr.quantity &&
                    curr.price &&
                    curr.divider
                  ) {
                    return acc + curr.quantity * curr.price * curr.divider
                  } else return acc
                }, 0)
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="6">
              <Row>
                <span className="ml-auto mr-2">Suma wszystkich:</span>
              </Row>
            </td>
            <td>
              {hundrethComma(
                props.reportData.reduce((acc, curr) => {
                  // prevent NaN error when any of numeric properties is undefined or null:
                  if (curr.quantity && curr.price && curr.divider)
                    return acc + curr.quantity * curr.price * curr.divider
                  else return acc
                }, 0)
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <h3>Dodatkowe uwagi:</h3>
      <Table id="additionalTable">
        <thead>
          <tr>
            <th>Dodał</th>
            <th>Oddział</th>
            <th>Kierowca</th>
            <th>Daty</th>
            <th>Treść</th>
          </tr>
        </thead>
        <tbody>
          {props.additionalInfo.map((row, i) => (
            <tr key={`addInfo-row-${i}`}>
              <td>{row.user?.fullName || "BŁĄD - brak danych"}</td>
              <td>{row.branch}</td>
              <td>{row.driver?.fullName || "BŁĄD - brak danych"}</td>
              <td>{row.dates}</td>
              <td className="keep-br">{row.additionalInfo}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
export default ConsumptionsReportRender
