import React, { useContext, useState, useEffect } from "react"
import _ from "lodash"
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  Col,
  Badge,
} from "react-bootstrap"
import { BsPauseFill } from "react-icons/bs"

import { LogCtx } from "./Fetch"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"
import RoutesPlanningJobsBatchChangeModal from "./modals/JobsBatchChangeModal"

const RoutesPlanningTableTab = () => {
  const { jobs, setModalData, user, configs, refresh, branchSelected, cars } =
    useContext(LogCtx)

  const [jobsFiltered, setJobsFiltered] = useState([...jobs])
  const [filters, setFilters] = useState({ type: "all", driver: "all" })
  const [jobsSelected, setJobsSelected] = useState([])

  useEffect(() => {
    const filtersNames = Object.keys(filters)
    // if all fiters set to 'all' -> return all jobs
    if (_.without(Object.values(filters), "all").length === 0)
      return setJobsFiltered([...jobs])

    // create array of all jobs - I'll remove not fitting jobs later:
    let jobsFilteredArr = [...jobs]

    // apply filters:
    filtersNames.forEach((filter) => {
      // when filter value === "all" -> don't do anything
      if (filters[filter] === "all") return

      // if filtering for driver -> use _id (filter for jobs without driver -> below)
      if (filter === "driver" && filters.driver !== "none")
        _.remove(jobsFilteredArr, (job) => job.driver?._id !== filters.driver)
      // when filtering for jobs without drivers:
      else if (filter === "driver" && filters.driver === "none")
        _.remove(jobsFilteredArr, (job) => job.hasDriver)
      // else use pure value
      else _.remove(jobsFilteredArr, (job) => job[filter] !== filters[filter])
    })

    // If any previously selected jobs don't appear in filtered list -> push them to beggining of array
    const lackingJobs = _.difference(jobsSelected, jobsFilteredArr)
    if (lackingJobs.length !== 0)
      jobsFilteredArr = [...lackingJobs, ...jobsFilteredArr]

    return setJobsFiltered(jobsFilteredArr)
  }, [filters, jobsSelected, jobs])

  return (
    <Container fluid>
      <Row className="justify-content-between">
        <Col xs="10">
          <Row>
            {/* JOB TYPE filter (show all job types) */}
            <Form inline>
              Typ:
              <Form.Control
                name="jobType"
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
                onChange={(e) => {
                  e.preventDefault()
                  setFilters({ ...filters, type: e.target.value })
                }}
              >
                <option value="all">wszystkie</option>
                {configs?.jobType.map((type) => (
                  <option key={`filters-type-${type}`} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
              {/* DRIVERS filter (show only drivers from slected branch) */}
              Kierowca:
              <Form.Control
                name="driver"
                as="select"
                label="Kierowca"
                type=""
                className=""
                autoComplete="chrome-off"
                onChange={(e) => {
                  e.preventDefault()
                  return setFilters({ ...filters, driver: e.target.value })
                }}
              >
                <option value="all">wszyscy</option>
                <option value="none">brak</option>
                {user.drivers.map((drv, i) => {
                  if (
                    drv.branch[branchSelected] &&
                    jobs.find((job) => job.driver?._id === drv._id)
                  )
                    return (
                      <option key={`filters-drv-${drv._id}`} value={drv._id}>
                        {drv.fullName}
                      </option>
                    )
                  else return null
                })}
              </Form.Control>
            </Form>
          </Row>
        </Col>
        <Col>
          <Button
            disabled={jobsSelected.length !== 0 ? false : true}
            className="my-2"
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Masowa Edycja Zadań",
                hideFooter: true,
                body: (
                  <RoutesPlanningJobsBatchChangeModal
                    user={user}
                    jobState={configs.jobState}
                    setModalData={setModalData}
                    jobs={jobsSelected}
                    refresh={refresh}
                    branchSelected={branchSelected}
                  />
                ),
              })
            }
          >
            MEZ
          </Button>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>
              {/* Header checkbox: when change to true -> set all to true and opose way  */}
              <Form.Check
                name="selectAll"
                type="checkbox"
                id="selectAll"
                onChange={(e) => {
                  const boxesArr = jobsFiltered.map((job) => {
                    return document.getElementById(`job-checkbox-${job._id}`)
                  })
                  if (e.target.checked) {
                    boxesArr.forEach((box) => (box.checked = true))
                    setJobsSelected(jobsFiltered)
                  } else {
                    boxesArr.forEach((box) => (box.checked = false))
                    setJobsSelected([])
                  }
                }}
              />
            </th>
            <th>Typ</th>
            <th>Stan</th>
            <th>Klient</th>
            <th>Lokalizacja</th>
            <th>Kierowca</th>
            <th>Samochód</th>
            <th>Adres</th>
            <th>Częst.</th>
          </tr>
        </thead>
        <tbody>
          {jobsFiltered.map((job) => (
            <tr
              className="clickable"
              key={`jobs-table-row-${job._id}`}
              onClick={(e) => {
                // prevent opening modal if checkbox clicked:
                if (e.target.type === "checkbox") return
                else
                  return setModalData({
                    show: true,
                    type: "info",
                    header: "Edycja zadania",
                    body: (
                      <JobDetailsModal
                        parent="Logistics"
                        job={job}
                        user={user}
                        configs={configs}
                        setModalData={setModalData}
                        refresh={refresh}
                        location={job.location}
                        cars={cars}
                      />
                    ),
                  })
              }}
            >
              <td>
                <Form.Check
                  name={`job-checkbox-${job._id}`}
                  type="checkbox"
                  label=""
                  value={job._id}
                  id={`job-checkbox-${job._id}`}
                  onChange={(e) => {
                    const jobsSelectedTmp = [...jobsSelected]

                    if (e.target.checked) jobsSelectedTmp.push(job)
                    else
                      jobsSelectedTmp.splice(
                        jobsSelectedTmp.findIndex((el) => el._id === job._id),
                        1
                      )

                    return setJobsSelected(jobsSelectedTmp)
                  }}
                />
              </td>
              <td>{job.type}</td>
              <td>{job.state}</td>
              <td>
                {job.location?.customer?.jobsSuspended ? (
                  <>
                    <BsPauseFill
                      style={{
                        animationName: "red-alert",
                        animationDuration: "1s",
                        animationIterationCount: "infinite",
                      }}
                    />{" "}
                  </>
                ) : null}
                {job.location?.customer?.shortName || (
                  <b>
                    POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU DZWOŃ
                    DO MNIE!
                  </b>
                )}
              </td>
              <td>
                {job.location?.notes.find(
                  (locNote) =>
                    locNote.type === "rejestracja wstępna" &&
                    locNote.state === "aktywna"
                ) ? (
                  <Badge variant="warning">Rej. wstępna</Badge>
                ) : null}
                {job.location?.name || (
                  <b>
                    POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU DZWOŃ
                    DO MNIE!
                  </b>
                )}
              </td>
              <td>{job.hasDriver ? job.driver.fullName : <b>BRAK</b>}</td>
              <td>{job.car?.regNum}</td>
              <td>
                {job.location?.street || (
                  <b>
                    POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU DZWOŃ
                    DO MNIE!
                  </b>
                )}{" "}
                {job.location?.city || (
                  <b>
                    POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU DZWOŃ
                    DO MNIE!
                  </b>
                )}
              </td>
              <td>{job.location?.jobsFreq || "BŁĄD"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
export default RoutesPlanningTableTab
