import React, { useContext } from "react"
import { Table, Row } from "react-bootstrap"

import { dateParse, nF } from "../../utils/formats"

import { EmployeesDetailsCtx } from "./Fetch"

import { contractDetailsModal } from "./modals"

const EmployeesDetailsContractsTable = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)
  const showBonus = props.contracts.some(
    (contract) => contract.state === props.filter && contract.netBonus
  )
  // if no data - return table with one row:
  if (
    props.contracts.filter((contract) => contract.state === props.filter)
      .length === 0
  )
    return (
      <Table>
        <thead>
          <tr>
            <th colSpan="7">{props.header}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="7">
              <Row className="justify-content-center">Brak danych</Row>
            </td>
          </tr>
        </tbody>
      </Table>
    )

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="8">{props.header}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Rodzaj</td>
          {props.filter === "archiwum" && (
            <td className="fixed-values-table">Poprzedni stan</td>
          )}
          <td className="fixed-values-table">Firma</td>
          <td className="fixed-values-table">
            {props.filter === "ustalenia"
              ? "Planowane zawarcie"
              : "Data zawarcia"}
          </td>
          <td className="fixed-values-table">
            {props.filter === "ustalenia" ? "Data ustalenia" : "Data zmiany"}
          </td>
          <td className="fixed-values-table">Wynagrodzenie netto</td>
          <td className="fixed-values-table">Koszt</td>
          <td className="fixed-values-table">Koszt procentowo</td>
          {showBonus ? (
            <td className="fixed-values-table">Nagrody poza Mtv</td>
          ) : null}
        </tr>
        {props.contracts.map((contract) => {
          if (contract.state === props.filter && !contract.isHourly)
            return (
              <tr
                className="clickable"
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    header: `${ctx.employee.fullName} - szczegóły umowy ("${contract.state}")`,
                    hideFooter: true,
                    body: contractDetailsModal(
                      contract,
                      props.setModalData,
                      props.user,
                      props.configs,
                      ctx.employee,
                      ctx.refresh
                    ),
                    xl: true,
                  })
                }
                key={`contracts-table-row-${contract._id}`}
              >
                <td>
                  {contract.mainType} (
                  {contract.indefiniteTime ? "n-okr" : "okr"})
                </td>
                {props.filter === "archiwum" ? (
                  <td>{contract.preArchState || "bd"}</td>
                ) : null}
                <td>{contract.company}</td>
                <td>{dateParse(contract.conclusionDate, false)}</td>
                <td>
                  {dateParse(
                    contract.modificationDate || contract.conclusionDate,
                    false
                  )}
                </td>
                <td>{nF(contract.netSalary)}</td>
                <td>{nF(contract.employerCost)}</td>
                <td>{`${Math.round(
                  (contract.employerCost / contract.netSalary) * 100
                )}%`}</td>
                {showBonus ? <td>{nF(contract.netBonus)}</td> : null}
              </tr>
            )
          else return null
        })}
      </tbody>
      {/* props.summary is used for active contracts */}
      {props.summary ? (
        <tfoot>
          <tr>
            <td colSpan="4" className="fixed-values-table">
              {/* show one or two rows of summary depending if any contract is with motivation */}
              {props.contracts.some(
                (contract) =>
                  contract.motivation && contract.state === "aktywna"
              )
                ? "Sumy bez Motivation"
                : "Sumy (brak Motivation)"}
            </td>
            <td>
              {nF(
                props.contracts.reduce((acc, curr) => {
                  if (curr.state === props.filter && curr.netSalary)
                    return (acc += curr.netSalary)
                  else return acc
                }, 0)
              )}
            </td>
            <td>
              {nF(
                props.contracts.reduce((acc, curr) => {
                  if (curr.state === props.filter && curr.employerCost)
                    return (acc += curr.employerCost)
                  else return acc
                }, 0)
              )}
            </td>
            {/* calculate % employer cost: */}
            <td>
              {`${Math.round(
                // total employer cost:
                (props.contracts.reduce((acc, curr) => {
                  if (curr.state === props.filter && curr.employerCost)
                    return (acc += curr.employerCost)
                  else return acc
                }, 0) /
                  // total net salary:
                  props.contracts.reduce((acc, curr) => {
                    if (curr.state === props.filter && curr.netSalary)
                      return (acc += curr.netSalary)
                    else return acc
                  }, 0)) *
                  100
              )}%`}
            </td>
            {showBonus ? (
              <td>
                {nF(
                  props.contracts.reduce((acc, curr) => {
                    if (curr.state === props.filter && curr.netBonus)
                      return (acc += curr.netBonus)
                    else return acc
                  }, 0)
                )}
              </td>
            ) : null}
          </tr>
          {/* show second line of summary if any contract has motivation === true, calculate only contracts with motivation */}
          {
            props.contracts.some(
              (contract) => contract.motivation && contract.state === "aktywna"
            ) ? (
              <tr>
                <td colSpan="4" className="fixed-values-table">
                  Sumy z Motivation (II filar, 10% wynagrodzenia)
                </td>
                <td>
                  {nF(
                    props.contracts.reduce((acc, curr) => {
                      // for contracts with motivation:
                      if (
                        curr.state === props.filter &&
                        curr.netSalary &&
                        curr.motivation
                      )
                        return (acc += curr.netSalary * 1.1)
                      // for contracts without motivation (but still has to be included, but without 1.1 multiplier):
                      else if (curr.state === props.filter && curr.netSalary)
                        return (acc += curr.netSalary)
                      else return acc
                    }, 0)
                  )}
                </td>
                <td>
                  {nF(
                    props.contracts.reduce((acc, curr) => {
                      // for contracts with motivation:
                      if (
                        curr.state === props.filter &&
                        curr.employerCost &&
                        curr.motivation
                      )
                        return (acc += curr.employerCost * 1.1)
                      // for contracts without motivation (but still has to be included, but without 1.1 multiplier):
                      else if (curr.state === props.filter && curr.employerCost)
                        return (acc += curr.employerCost)
                      else return acc
                    }, 0)
                  )}
                </td>
                {/* calculate % employer cost: */}
                <td>
                  {`${Math.round(
                    // total employer cost with motivation:
                    ((props.contracts.reduce((acc, curr) => {
                      if (curr.state === props.filter && curr.employerCost)
                        return (acc += curr.employerCost)
                      else return acc
                    }, 0) *
                      1.1) /
                      // total net salary with motivation:
                      (props.contracts.reduce((acc, curr) => {
                        if (curr.state === props.filter && curr.netSalary)
                          return (acc += curr.netSalary)
                        else return acc
                      }, 0) *
                        1.1)) *
                      100
                  )}%`}
                </td>
                {showBonus ? (
                  <td>
                    {nF(
                      props.contracts.reduce((acc, curr) => {
                        if (
                          curr.state === props.filter &&
                          curr.netBonus &&
                          curr.motivation
                        )
                          return (acc += curr.netBonus)
                        else return acc
                      }, 0)
                    )}
                  </td>
                ) : null}
              </tr>
            ) : null
            // <tr>
            //   <td className="fixed-values-table" colSpan="3">
            //     Sumy z Motivation
            //   </td>
            //   <td colSpan="3">Brak Motivation</td>
            // </tr>
          }
        </tfoot>
      ) : null}
    </Table>
  )
}
const EmployeesDetailsHourlyContractsTable = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)
  const showBonus = props.contracts.some(
    (contract) => contract.state === props.filter && contract.netBonus
  )
  // if no data - return table with one row:
  if (
    props.contracts.filter((contract) => contract.state === props.filter)
      .length === 0
  )
    return (
      <Table>
        <thead>
          <tr>
            <th colSpan="7">{props.header}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="7">
              <Row className="justify-content-center">Brak danych</Row>
            </td>
          </tr>
        </tbody>
      </Table>
    )

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="8">{props.header}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Rodzaj</td>
          {props.filter === "archiwum" && (
            <td className="fixed-values-table">Poprzedni stan</td>
          )}
          <td className="fixed-values-table">Firma</td>
          <td className="fixed-values-table">
            {props.filter === "ustalenia"
              ? "Planowane zawarcie"
              : "Data zawarcia"}
          </td>
          <td className="fixed-values-table">
            {props.filter === "ustalenia" ? "Data ustalenia" : "Data zmiany"}
          </td>
          <td className="fixed-values-table">Podstawowa brutto</td>
          <td className="fixed-values-table">Przeliczona netto</td>
          <td className="fixed-values-table">Przeliczona brutto</td>
          {showBonus ? (
            <td className="fixed-values-table">Bonusy netto</td>
          ) : null}
        </tr>
        {props.contracts.map((contract) => {
          if (contract.state === props.filter && contract.isHourly)
            return (
              <tr
                className="clickable"
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    header: `${ctx.employee.fullName} - szczegóły umowy ("${contract.state}")`,
                    body: contractDetailsModal(
                      contract,
                      props.setModalData,
                      props.user,
                      props.configs,
                      ctx.employee,
                      ctx.refresh
                    ),
                    xl: true,
                  })
                }
                key={`contracts-table-row-${contract._id}`}
              >
                <td>
                  {contract.mainType} (
                  {contract.indefiniteTime ? "n-okr" : "okr"})
                </td>
                {props.filter === "archiwum" ? (
                  <td>{contract.preArchState || "bd"}</td>
                ) : null}
                <td>{contract.company}</td>
                <td>{dateParse(contract.conclusionDate, false)}</td>
                <td>
                  {dateParse(
                    contract.modificationDate || contract.conclusionDate,
                    false
                  )}
                </td>
                <td>{nF(contract.grossSalary)}</td>
                <td>{nF(contract.netSalary)}</td>
                <td>{nF(contract.employerCost)}</td>

                {showBonus ? <td>{nF(contract.netBonus)}</td> : null}
              </tr>
            )
          else return null
        })}
      </tbody>
      {/* summary is used ONLY for active contracts */}
      {props.summary ? (
        <tfoot>
          <tr>
            <td colSpan="4" className="fixed-values-table">
              Sumy
            </td>
            <td>
              {nF(
                props.contracts.reduce((acc, curr) => {
                  if (
                    curr.state === props.filter &&
                    curr.grossSalary &&
                    curr.isHourly
                  )
                    return (acc += curr.grossSalary)
                  else return acc
                }, 0)
              )}
            </td>
            <td>
              {nF(
                props.contracts.reduce((acc, curr) => {
                  if (
                    curr.state === props.filter &&
                    curr.netSalary &&
                    curr.isHourly
                  )
                    return (acc += curr.netSalary)
                  else return acc
                }, 0)
              )}
            </td>
            <td>
              {nF(
                props.contracts.reduce((acc, curr) => {
                  if (
                    curr.state === props.filter &&
                    curr.employerCost &&
                    curr.isHourly
                  )
                    return (acc += curr.employerCost)
                  else return acc
                }, 0)
              )}
            </td>

            {showBonus ? (
              <td>
                {nF(
                  props.contracts.reduce((acc, curr) => {
                    if (
                      curr.state === props.filter &&
                      curr.netBonus &&
                      curr.isHourly
                    )
                      return (acc += curr.netBonus)
                    else return acc
                  }, 0)
                )}
              </td>
            ) : null}
          </tr>
        </tfoot>
      ) : null}
    </Table>
  )
}
export { EmployeesDetailsContractsTable, EmployeesDetailsHourlyContractsTable }
