import { Alert } from "react-bootstrap"

/**
 *
 * @param {array} resMsg array of objects, each with custName and alerts props. If alerts empty -> don't show anything for this customer
 */
const renderInvMakeAlerts = (resMsg) => {
  // filter only objects with any alerts
  const alertsToShow = resMsg.filter((custEl) => custEl.alerts.length)

  if (!alertsToShow.length) return null

  return (
    <Alert variant="warning">
      {alertsToShow.map((custEl) => (
        <span key={`invAlert-${custEl.custName}`}>
          <h6>{custEl.custName}:</h6>
          <ul>
            {custEl.alerts.map((alert, i) => (
              <li key={`invAlert-${custEl.custName}-${i}`}>{alert}</li>
            ))}
          </ul>
        </span>
      ))}
    </Alert>
  )
}

export { renderInvMakeAlerts }
