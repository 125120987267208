import React, { useContext, useState } from "react"
import { Row, Table, Button, Form, Alert, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { format } from "date-fns"

import { LocationCtx } from "../../Fetch"

const LocationsDetailsEditNoteModal = (props) => {
  const { setModalData, location, refresh } = useContext(LocationCtx)
  const [fetchError, setFetchError] = useState(null)
  const [customValidationError, setCustomValidationError] = useState()

  const { isNew } = props

  const { handleSubmit, register, formState, watch } = useForm({
    defaultValues: isNew
      ? {
          body: null,
        }
      : {
          ...props.note,
          eventDate: props.note.eventDate
            ? format(new Date(props.note.eventDate), "yyyy-MM-dd")
            : null,
        },
  })

  const { errors } = formState

  const { dirtyFields } = formState

  const submit = async (note) => {
    try {
      // when changing type to "główna" check if there is no other:
      if (
        note.type === "główna" &&
        location.notes.findIndex(
          (note) =>
            note.state === "aktywna" &&
            note.type === "główna" &&
            note._id !== props.note?._id
        ) !== -1
      )
        return setCustomValidationError(
          <p>
            Może być tylko jedna główna notatka dla lokalizacji. <br />
            Albo edytuj obecną główną notatkę, albo zmień jej typ na jakiś inny.{" "}
            <br />
            <b>
              Żeby nie stracić danych możesz zapisać tą notatkę tymczasowo jako
              'inna'
            </b>
          </p>
        )

      // when note type === "fakturowa" there can be only one "ogólna"
      if (
        note.type === "fakturowa" &&
        note.subtype === "ogólna" &&
        location.notes.findIndex(
          (note) =>
            note.state === "aktywna" &&
            note.type === "fakturowa" &&
            note.subtype === "ogólna" &&
            note._id !== props.note?._id
        ) !== -1
      )
        return setCustomValidationError(
          <p>
            Może być tylko jedna ogólna notatka fakturowa dla lokalizacji.
            Edytuj aktualną notatkę ogólną albo archiwizuj ją i dodaj nową.
          </p>
        )

      const body = {
        data: isNew
          ? {
              ...note,
              state: "aktywna",
            }
          : {
              ...note,
              _id: props.note._id,
              state: props.note.state,
            },

        dirtyFields: dirtyFields,
        historyLength: isNew ? 0 : props.note.history.length,
      }

      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=${
          isNew ? "true" : "false"
        }&entity=notes`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status === 409)
        return setFetchError(
          "Przykro mi, ale nie mogę zapisać tej zmiany. W międzyczasie ktoś edytował już tą notatkę. Zanim zapiszesz swoje zmiany musisz odwieżyć stronę. UWAGA! To spowoduje utratę danych, więc najpierw zapisz je gdzieś 'na boku', a potem odśwież stronę (ctrl + R)"
        )
      if (res.status !== 200) {
        console.log(res)
        return setFetchError("Błąd komunikacji z serwerem")
      }
      return refresh({
        header: "Sukces",
        headerColor: "bg-success",
        body: `Notatka zapisana`,
      })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd zapisywania danych")
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">
              Typ{" "}
              {errors.type && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("type", { required: true })}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
              >
                <option value="główna">główna</option>
                <option value="windykacyjna">windykacyjna</option>
                <option value="logistyczna">logistyczna</option>
                <option value="na kontrolkę">na kontrolkę</option>
                <option value="fakturowa">fakturowa</option>
                <option value="rejestracja wstępna">rejestracja wstępna</option>
                <option value="inna">inna</option>
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">
              Treść{" "}
              {errors.body && (
                <Badge pill variant="warning">
                  Ale wiesz, że jak tu nic nie wpiszesz to nie ma sensu? Serio,
                  tu nie ma innych pól, dodajesz natatkę tylko dla typu? Machnij
                  cokolwiek ;)
                </Badge>
              )}
            </td>
          </tr>
          {watch("type") === "fakturowa" ? (
            <>
              <tr>
                <td>
                  <Form.Control
                    {...register("subtype")}
                    as="select"
                    type=""
                    className=""
                    autoComplete="chrome-off"
                  >
                    <option value="ogólna">ogólna</option>
                    <option value="jednorazowa">jednorazowa</option>
                  </Form.Control>
                </td>
              </tr>
              {watch("subtype") === "jednorazowa" ? (
                <tr>
                  <td>
                    Data zdarzenia:
                    <Form.Control
                      {...register("eventDate")}
                      as="input"
                      type="date"
                      className=""
                      autoComplete="chrome-off"
                    />
                  </td>
                </tr>
              ) : null}
            </>
          ) : null}
          <tr>
            <td>
              <Form.Control
                {...register("body", { required: true })}
                as="textarea"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
        </tbody>
      </Table>
      {customValidationError && (
        <Alert variant="warning">{customValidationError}</Alert>
      )}
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Row className="justify-content-between">
        <Button
          variant="warning"
          className="ml-3"
          onClick={() => setModalData({ show: false })}
        >
          Anuluj
        </Button>
        <Button variant="secondary" className="mr-3" type="submit">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export default LocationsDetailsEditNoteModal
