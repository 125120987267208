import React from "react"
import { Table, Alert, Container } from "react-bootstrap"

import { nF } from "../../utils/formats"
import { IncomeReportInvListModal } from "./InvListModal"
import { calcSrvSum } from "./utils"

const IncomeReportRender = ({ data, reportErrors, setModalData }) => {
  return (
    <Container>
      {reportErrors.length ? (
        <Alert variant="warning">
          <b>Uwaga!</b>
          <br />
          Raport może zawierać błędy. W przypadku komunikatu{" "}
          <b>"brak domyślnej kategorii przychodu"</b> poproś o poprawienie
          odpowiedniej usługi Mariusza, Mirka, Łukasza lub mnie. <br />
          <b>W każdym innym przypadku</b> zgłoś to w Jirze{" "}
          <u>załączając zrzut całego ekranu</u> i skopiowaną treść poniższego
          komunikatu (zaznacz {"->"} kopiuj i wklej w Jirze)
          <br />
          {reportErrors.map((err) => (
            <>
              {err}
              <br />
            </>
          ))}
        </Alert>
      ) : null}
      <Table>
        <thead>
          <tr>
            <th>Przychód wg. usług</th>
          </tr>
        </thead>
        <tbody>
          {/* create services headers */}
          <tr className="fixed-values-table">
            <td>Oddział</td>
            {data.allServicesNames.map((srvName) => (
              <td key={`current-serviceNameHeader-${srvName}`}>{srvName}</td>
            ))}
            <td>SUMA</td>
          </tr>
          {/* create branches rows: */}
          {data.current.map((branchObj) => (
            <tr key={`current-branchRow-${branchObj.branch}`}>
              {/* branch name: */}
              <td>{branchObj.branch}</td>
              {data.allServicesNames.map((srvName) => {
                // get this col service:
                const branchSrv = branchObj.services.find(
                  (branchSrv) => branchSrv.name === srvName
                )

                if (branchSrv?.amount) {
                  return (
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        return setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          body: (
                            <IncomeReportInvListModal
                              invoices_ids={branchSrv.invoices}
                              lines={branchSrv.lines}
                            />
                          ),
                        })
                      }}
                    >
                      {nF(branchSrv.amount)}
                    </td>
                  )
                } else
                  return (
                    <td
                      key={`current-branchRow-${branchObj.branch}-${srvName}`}
                    >
                      0,00 zł
                    </td>
                  )
              })}
              {/* get branch sum */}
              <td>
                {nF(branchObj.services.reduce((a, r) => a + r.amount, 0))}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={{ fontWeight: "bold" }}>
            <td>SUMA</td>
            {data.allServicesNames.map((srvName) => (
              <td key={`current-srvSumsRow-${srvName}`}>
                {nF(calcSrvSum(data, srvName, "current"))}
              </td>
            ))}
            <td>
              {nF(
                data.current.reduce(
                  (a, branchObj) =>
                    (a += branchObj.services.reduce(
                      (branchAcc, branchSrv) => (branchAcc += branchSrv.amount),
                      0
                    )),
                  0
                )
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  )
}
export { IncomeReportRender }
