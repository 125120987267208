import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import invoicingServicesTable from "../../pics/manual/invoicingServicesTable.png"
import invoiceDetails from "../../pics/manual/invoiceDetails.png"
import invoicingListMultiexport from "../../pics/manual/invoicingListMultiexport.png"
import locationsList from "../../pics/manual/locationsList.png"
import batchInvoiceModal from "../../pics/manual/batchInvoiceModal.png"
import sageWystawianie from "../../pics/manual/sage-Wystawianie.png"
import locInvMakeModal from "../../pics/manual/locInvMakeModal.png"
import locListInvMakeModal from "../../pics/manual/locListInvMakeModal.png"
import { createJiraBtn } from "../../utils"

const ManualInvoicingSection = (props) => {
  /*
todo:
lista lokalizacji pokazuje tylko aktywnych klientów w przypadku wyszukiwania po kliencie
lista faktur pokazuje tylko faktury w wybranym okresie, przy użyciu klient->faktury pokazuje na 365 dni wstecz, konieczna ręczna zmiana daty OD żeby wyszukać starsze dokumenty
*/

  if (props.user.perm.invoicing.r)
    return (
      <div>
        <Row>
          <Col>
            <h3>Fakturowanie</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <b>Etapy:</b>
            <p>Fakturowanie odbywa się w trzech etapach:</p>
            <ol>
              <li>
                wygenerowanie faktury
                <div>
                  <ul>
                    <li>z poziomu lokalizacji</li>
                    <li>z listy lokalizacji - generowanie "seryjne/masowe"</li>
                  </ul>
                </div>
              </li>
              <li>
                eksport faktur do Symfonii ("Fakturowanie" {"->"} "Lista")
              </li>
              <li>wystawienie faktury w Symfonii i wydruk/wysyłka</li>
            </ol>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Docelowo wszystkie faktury będą wystawiane (generowane) i
              edytowane w clipERPie, Symfonia ma ostatecznie nadawać numer
              fakturze, zajmować się jej wydrukiem/wysyłką/eksportem do
              księgowości i dalszymi działaniami (np. windykacją czy
              rozliczaniem z wpłatami na konto).
            </p>
            <p>
              Na chwilę obecną ten proces nie jest dopracowany, a clipERP
              umożliwia jedynie wystawienie i edycję faktury za najem
              długoterminowy. Po kolei będą dochodzić kolejne elementy
              (wystawianie "dowolne", faktury za krótki termin, faktury za
              sprzedaż, faktury walutowe).
            </p>
            <p>
              W okresie przejściowym proszę o posługiwanie się starym systemem
              lub wystawianie faktur bezpośrednio w Symfonii, zależnie od
              ustaleń.
            </p>
            <p className="bg-dangerLight">
              <b>Uwaga!</b> Wszelkie zmiany naniesione na fakturę w Symfonii nie
              są importowane do clipERPa.{" "}
              <u>
                Proszę nie edytować w Symfonii faktur zaimportowanych z clipERPa
              </u>
              , zamiast tego należy fakturę edytować w clipERPie i ponownie ją
              eksportować (usuwając poprzednią z Symfonii).
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>Generowanie faktur</h4>
          </Col>
        </Row>
        <div>
          <Row>
            <Col>
              <h5>Informacje o fakturach</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                W modalu generowania faktur (niezależnie czy generowanych z
                poziomu lokalizacji, czy z listy lokalizacji) wyświetlają się
                informacje dotyczące generowanych faktur.
                <br />W zależności od sytuacji (przede wszystkim wybranych dat)
                mogą to być notatki fakturowe i/lub modyfikacje faktur.
                <br />
              </p>
              <p>
                Szczegółowy opis notatek fakturowych i modyfikacji znajduje się
                w części "Lokalizacje". Na chwilę obecną nie ma możliwości
                wybrania, które modyfikacje zostaną zastosowane - wyświetlana
                tabela to tylko informacja.{" "}
              </p>
              <p>
                <b>
                  Jeśli jakaś modyfikacja nie powinna dotyczyć generowanej
                  faktury
                </b>{" "}
                należy zamknąć modala generowania faktury, edytować modyfikację
                z poziomu lokalizacji (karta "Szczegóły"), odświeżyć dane i
                ponownie przejść do generowania faktury.
              </p>
              <p>
                Jeśli możliwość wybierania modyfikacji do zastosowania jest
                potrzebna proszę o informację w Jirze jako komentarz do tego
                zgłoszenia: {createJiraBtn("524")}{" "}
              </p>
            </Col>
            <Col>
              <h5>Z poziomu konkretnej lokalizacji:</h5>
              <img src={locInvMakeModal} alt="" style={{ maxWidth: "100%" }} />
              <h5>Z poziomu listy lokalizacji:</h5>
              <img
                src={locListInvMakeModal}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h5>z poziomu lokalizacji:</h5>
            <p>
              Aby wygenerować fakturę należy użyć odpowiedniego przycisku z
              poziomu podglądu lokalizacji.
              <u>
                Przy fakturowaniu końcowomiesięcznym pomocna może być lista
                lokalizacji ("Lokalizacje" w głównym menu), umożliwiająca
                pokazanie lokalizacji zawierających niezafakturowane usługi na
                dany dzień.
              </u>
            </p>

            <h5>z listy lokalizacji:</h5>
            <p>
              Od wersji 1.4.1 możliwe jest generowanie faktur z listy
              lokalizacji.{" "}
              <b>To jedyna metoda, żeby wystawić faktury "łączone"</b> (tzn. dla
              wielu lokalizacji na jednej fakturze).
            </p>
            <p>
              Aby wygenerować faktury należy przy wyszukiwaniu lokalizacji
              zaznaczyć checkbox "Niezafakturowane" i wybrać datę (program
              wyświetli wszystkie lokalizacje, które zawierają przynajmniej
              jedną niezafakturowaną usługę na wskazany dzień, i spełniające
              pozostałe warunki wyszukiwania - oddział, nazwę itd.)
            </p>
            <p>
              Po zaznaczeniu lokalizacji do zafakturowania i kliknięciu
              "Fakturuj zaznaczone" pojawi się modal z parametrami fakturowania,
              analogicznymi jak przy fakturowaniu z lokalizacji.
            </p>
            <p>
              Zaznaczenie checkboxa na dole spowoduje połączenie lokalizacji{" "}
              <Button
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    body: (
                      <>
                        <p>
                          Program wśród wybranych lokalizacji znajdzie te
                          należące do tych samych klientów i połączy je w jeden
                          dokument.
                        </p>
                        <p>
                          Jeśli zaznaczono lokalizacje należące do trzech
                          klientów: dwie dla klienta A, trzy dla B i jedną dla C
                          to bez zaznaczonego checkboxa program wystawi sześć
                          osobnych faktur (po jednej na lokalizację). Jeśli
                          checkbox będzie zaznaczony to efektem będą trzy
                          dokumenty, po jednym dla klienta.
                        </p>
                      </>
                    ),
                  })
                }
              >
                szczegóły
              </Button>
            </p>
          </Col>
          <Col>
            <p>Generowanie faktury z lokalizacji:</p>
            <img
              src={invoicingServicesTable}
              alt=""
              style={{ maxWidth: "100%" }}
            />
            <p>Generowanie faktury z listy:</p>
            <img src={locationsList} alt="" style={{ maxWidth: "100%" }} />
            <hr />
            <img src={batchInvoiceModal} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <Row>
          <h4>Lista faktur</h4>
        </Row>
        <Row>
          <Col>
            <p>
              Wygenerowane faktury widoczne są na liście dostępnej z głównego
              menu ("Fakturowanie" {"->"} "Lista"). Każdą można kliknąć aby
              podejrzeć szczegóły i <u>zweryfikować poprawność</u>.
            </p>
            <div>
              <p>
                W szczegółach faktury można sprawdzić informacje o zastosowanych
                modyfikacjach (patrz część "Lokalizacje") - po kliknięciu
                przycisku "Pokaż modyfikacje".
              </p>
            </div>
            <h5>Eksportowanie</h5>
            <p>
              Wygenerowane faktury można eksportować pojedyńczo z ww. podglądu
              szczegółów (przycisk "Eksportuj"), lub zaznaczając wybrane
              dokumenty i klikając "Eksportuj zaznaczone"
            </p>
          </Col>
          <Col>
            <p>Lista faktur i eksport wielu dokumentów:</p>
            <img
              src={invoicingListMultiexport}
              alt=""
              style={{ maxWidth: "100%" }}
            />
            <p>Podgląd szczegółów faktury:</p>
            <img src={invoiceDetails} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>
              Wyeksportowane faktury trafiają do Symfonii w ciągu około 10-15
              sekund. Aby zobaczyć fakturę w Symfonii należy kliknąć przycisk
              "Odśwież".
            </p>
            <p>
              Pojedynczą fakturę w Symfonii można wystawić klikając ją{" "}
              <u>dwukrotnie</u> na liście {"->"} "edytuj" {"->"} "Wystaw"
            </p>
            <p>
              Można też wystawić i wydrukować/wysłać wiele faktur na raz
              zaznaczając odpowiednie dokumenty i wybierając "Operacje" {"->"}{" "}
              "Wystawianie dokumentów".
              <span className="bg-dangerLight">
                <br />W tej sytuacji ważne jest, żeby{" "}
                <u>
                  <b>nie</b> zaznaczać żadnego z checkboxów zakreślonych na
                  grafice obok!
                </u>
              </span>
            </p>
          </Col>
          <Col>
            <img src={sageWystawianie} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <div>
          <Row>
            <Col>
              <h4>
                Termin płatności od <u>daty otrzymania</u> faktury
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              W przypadku klientów oczekujących terminu płatności zależnego od
              daty otrzymania faktury należy ustawić najdłuższy dostępny termin.
              Faktury dla takich klientów powinny być wysyłane{" "}
              <u>mailowo lub za potwierdzeniem odbioru</u>. Na podstawie daty
              maila (lub daty otrzymania faktury sprawdzonej na potwierdzeniu
              odbioru) należy każdorazowo ustawić odpowiedni termin płatności
              bezpośrednio w Symfonii (podwójne kliknięcie na fakturze {"->"}{" "}
              edytuj {"->"} zmienić termin płatności){" "}
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col>
            <h4>Zmiana "zafakturowano do"</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              W pewnych sytuacjach może pojawić się konieczność ponownego
              wystawienia faktury z poziomu lokalizacji. Aby było to możliwe
              konieczne jest edytowanie wartości "zafakturowano do" odpowiednich
              parametrów.{" "}
              <b>Uprawnienia do tego mają Mariusz, Mirosław, Łukasz i ja.</b> W
              pierwszej kolejności z taką prośbą proszę zwracać się do mnie.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Usuwanie faktur</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Istnieje możliwość usunięcia wygenerowanej faktury z clipERPa,
              jeśli jest taka potrzeba proszę o kontakt.
            </p>
          </Col>
        </Row>
      </div>
    )
  else return null
}
export default ManualInvoicingSection
