import React, { useRef, useState } from "react"
import { Row, Button } from "react-bootstrap"
import { useReactToPrint } from "react-to-print"
import { InvListPrintList } from "./PrintTable"

import { fetchFiltered } from "./utils"

const InvListPrint = ({
  getValues,
  setModalData,
  setPage,
  setIsLoading,
  refresh,
  setSearchParams,
}) => {
  const [invoices, setInvoices] = useState([])
  const printRef = useRef()

  const handlePrintFetch = async () => {
    setIsLoading(true)
    const formData = getValues()
    await fetchFiltered(
      { ...formData, print: true },
      setModalData,
      setInvoices,
      setPage
    )
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: handlePrintFetch,
    onAfterPrint: () => {
      refresh(getValues())
    },
  })

  return (
    <Row>
      <Button onClick={handlePrint} className="ml-auto mr-3 mt-3">
        Drukuj
      </Button>
      <div style={{ display: "none" }}>
        <InvListPrintList ref={printRef} invoices={invoices} />
      </div>
    </Row>
  )
}
export default InvListPrint
