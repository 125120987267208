import React, { useState } from "react"

import { useForm, Controller } from "react-hook-form"
import {
  Table,
  Button,
  Form,
  Badge,
  Container,
  Row,
  Alert,
} from "react-bootstrap"

const RoutesPlanningJobsBatchChangeModal = (props) => {
  const { user, jobState, setModalData, jobs, refresh, branchSelected } = props
  const [fetchError, setFetchError] = useState(false)
  // when any job state is other then planned - user can only edit state
  const blockEdit =
    jobs.filter((job) => job.state !== "zaplanowane").length !== 0
      ? true
      : false
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      state: { apply: false, newValue: null },
      date: { apply: false, newValue: null },
      driver: { apply: false, newValue: null },
      noteAdd: { apply: false, newValue: null },
      noteReplace: { apply: false, newValue: null },
    },
  })

  const submit = async (formData) => {
    try {
      const modificationsArr = Object.keys(formData).filter(
        (key) => formData[key].apply
      )
      if (modificationsArr.length === 0)
        return setFetchError(
          "Wprowadź jakąkolwiek zmianę. Szkoda internetów, serwerów i takich tam na puste przebiegi."
        )

      if (formData.date.apply)
        formData.date.newValue = `${formData.date.newValue}T12:00`

      // when adding driver for job that was without driver -> set 'hasDriver' to true
      if (formData.driver.apply === "true" || formData.driver.apply === true)
        formData.hasDriver = { apply: true, newValue: true }

      // prepare data:
      const data = {
        jobs: jobs.map((job) => {
          return { _id: job._id, historyLength: job.history.length }
        }),
        modifications: formData,
      }

      const res = await fetch("/jobs/batchEdit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status === 409)
        return setFetchError(
          (await res.text()) ||
            "Ktoś już edytował któryś z tych zadań. Zapisz wprowadzone dane w innym źródle, ośwież stronę i spróbuj jeszcze raz"
        )
      if (res.status !== 200)
        return setFetchError("Błąd komunikacji z serwerem")
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        header: "Sukces",
        body: "Zmiany naniesione",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd działania aplikacji")
    }
  }

  return (
    <Container>
      <Alert variant="warning">
        Uwaga!
        <br />
        Wprowadzone zmiany zostaną zastosowane dla WSZYSTKICH wybranych zadań.
        {blockEdit && (
          <p>
            Przynajmniej jedno zadanie ma stan inny niż "zaplanowane" - możliwa
            tylko edycja stanu.
          </p>
        )}
      </Alert>
      <Form onSubmit={handleSubmit(submit)}>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Rodzaj modyfikacji</td>
              <td className="fixed-values-table">Modyfikacja</td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                <Controller
                  name={"state.apply"}
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      label="Stan"
                      id="state.apply"
                      onChange={(e) => {
                        setValue("state.newValue", null)
                        field.onChange(e)
                      }}
                      inline
                    />
                  )}
                />
              </td>
              <td>
                <Form.Control
                  {...register("state.newValue", {
                    validate: (val) => {
                      if (getValues("state.apply") && val) return true
                      else if (
                        getValues("state.apply") === false ||
                        !getValues("state.apply")
                      )
                        return true
                      else return false
                    },
                  })}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={watch("state.apply") ? false : true}
                >
                  {jobState.map((state) => (
                    <option key={`states-select-${state}`} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
                {errors.state && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                <Controller
                  control={control}
                  name={"date.apply"}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      label="Data"
                      onChange={(e) => {
                        setValue("date.newValue", null)
                        field.onChange(e)
                      }}
                      id="date.apply"
                      disabled={blockEdit}
                      inline
                    />
                  )}
                />
              </td>
              <td>
                <Form.Control
                  {...register("date.newValue", {
                    validate: (val) => {
                      if (getValues("date.apply") && val) return true
                      else if (
                        getValues("date.apply") === false ||
                        !getValues("date.apply")
                      )
                        return true
                      else return false
                    },
                  })}
                  as="input"
                  type="date"
                  className=""
                  autoComplete="chrome-off"
                  disabled={watch("date.apply") ? false : true}
                />
                {errors.date && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                <Controller
                  name={"driver.apply"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Form.Check
                        {...field}
                        type="checkbox"
                        label="Kierowca"
                        onChange={(e) => {
                          setValue("driver.newValue", null)
                          field.onChange(e)
                        }}
                        id="driver.apply"
                        disabled={blockEdit}
                        inline
                      />
                    )
                  }}
                />
              </td>
              <td>
                <Form.Control
                  {...register("driver.newValue", {
                    validate: (val) => {
                      if (getValues("driver.apply") && val) return true
                      else if (
                        getValues("driver.apply") === false ||
                        !getValues("driver.apply")
                      )
                        return true
                      else return false
                    },
                  })}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={watch("driver.apply") ? false : true}
                >
                  {user.drivers.map((driver) => {
                    // show only active drivers matching branchSelected (from fetch form)
                    if (
                      driver.state === "aktywny" &&
                      driver.branch[branchSelected]
                    )
                      return (
                        <option
                          key={`drivers-select-${driver._id}`}
                          value={driver._id}
                        >
                          {driver.fullName}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>
                {errors.driver && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                <Controller
                  name={"commentsAdd.apply"}
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      label="Uwagi - dodaj"
                      onChange={(e) => {
                        setValue("commentsAdd.newValue", null)
                        field.onChange(e)
                      }}
                      id="commentsAdd.apply"
                      disabled={
                        watch("commentsReplace.apply") === "true" || blockEdit
                          ? true
                          : false
                      }
                      inline
                    />
                  )}
                />
              </td>
              <td>
                <Form.Control
                  {...register("commentsAdd.newValue")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={watch("commentsAdd.apply") ? false : true}
                />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                <Controller
                  name={"commentsReplace.apply"}
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      label="Uwagi - zastąp"
                      onChange={(e) => {
                        setValue("commentsReplace.newValue", null)
                        field.onChange(e)
                      }}
                      id="commentsReplace.apply"
                      disabled={
                        watch("commentsAdd.apply") === "true" || blockEdit
                          ? true
                          : false
                      }
                      inline
                    />
                  )}
                />
              </td>
              <td>
                <Form.Control
                  {...register("commentsReplace.newValue")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={watch("commentsReplace.apply") ? false : true}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        {watch("commentsReplace.apply") && (
          <Alert variant="warning">
            Uwaga! Notatki dla WSZYSTKICH wybranych zadań zostaną zastąpione tą
            wprowadzoną powyżej!
          </Alert>
        )}
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row className="justify-content-between">
          <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
          <Button type="submit">Zapisz</Button>
        </Row>
      </Form>
    </Container>
  )
}

export default RoutesPlanningJobsBatchChangeModal
