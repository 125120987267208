import React, { useEffect, useState } from "react"

import { Container } from "react-bootstrap"
import MyModal from "../../utils/Modals"

import EquipmentTableForm from "./Form"
import EquipmentTableRender from "./Render"

const EquipmentTableFetch = () => {
  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [configs, setConfigs] = useState(null)
  const [equipment, setEquipment] = useState(null)

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const res = await fetch("/configs/eqpTypes")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchConfigs()
  }, [])
  return (
    <Container fluid>
      {configs && (
        <EquipmentTableForm
          eqpTypes={configs.eqpTypes}
          setEquipment={setEquipment}
          setIsLoading={setIsLoading}
          configs={configs}
          setModalData={setModalData}
        />
      )}
      {isLoading ? null : (
        <EquipmentTableRender
          equipment={equipment}
          setModalData={setModalData}
          configs={configs}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default EquipmentTableFetch
