import { format } from "date-fns"
import React from "react"
import { Row, Col, Button, Form, Badge, Spinner } from "react-bootstrap"

import Select from "react-select"

const IncomeReportForm = ({
  user,
  filters,
  setFilters,
  setRefreshCounter,
  refreshCounter,
  formErrors,
  isLoading,
}) => {
  const branchOptions = user.allowedBranches.map((branch) => ({
    label: branch,
    value: branch,
  }))

  return (
    <Form
      className="mx-2"
      onSubmit={(e) => {
        e.preventDefault()
        setRefreshCounter(refreshCounter + 1)
      }}
    >
      <Row>
        <Col>
          <Row>OD:</Row>
          <Row>
            {formErrors.dates && (
              <Badge pill variant="warning">
                {formErrors.dates}
              </Badge>
            )}
            <Form.Control
              as="input"
              type="date"
              value={
                filters.startDate
                  ? format(new Date(filters.startDate), "yyyy-MM-dd")
                  : null
              }
              onChange={(e) =>
                setFilters({ ...filters, startDate: e.target.value })
              }
              className=""
              autoComplete="chrome-off"
            />
          </Row>
        </Col>
        <Col>
          <Row>DO:</Row>
          <Row>
            <Form.Control
              as="input"
              type="date"
              value={
                filters.endDate
                  ? format(new Date(filters.endDate), "yyyy-MM-dd")
                  : null
              }
              onChange={(e) =>
                setFilters({ ...filters, endDate: e.target.value })
              }
              className=""
              autoComplete="chrome-off"
            />
          </Row>
        </Col>
        <Col>
          <Row>Filia</Row>
          <Row>
            <Select
              options={branchOptions}
              value={filters.branches}
              onChange={(v) => setFilters({ ...filters, branches: v })}
              isMulti={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
            />
          </Row>
        </Col>
        <Col>
          <Row>
            <Form.Check
              type="checkbox"
              label="brutto"
              id="grossSwitch"
              value={filters.gross}
              onChange={(e) =>
                setFilters({ ...filters, gross: e.target.checked })
              }
            />
          </Row>
          <Row>
            <Form.Check
              type="checkbox"
              label="data wystawienia"
              id="invoiceDateSwitch"
              value={filters.invoiceDate}
              onChange={(e) => {
                setFilters({ ...filters, invoiceDate: e.target.checked })
              }}
              disabled={true}
            />
          </Row>
        </Col>

        <Col xs="auto">
          <Button className="mt-3" type="submit">
            {isLoading ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              "Oblicz"
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
export { IncomeReportForm }
