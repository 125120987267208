import React from "react"
import { Table } from "react-bootstrap"
import { format } from "date-fns"
import { CarWorkEdit } from "./edit"

const CarsWorksTable = ({ carsWorks, setModalData, allowedCars, refresh }) => {
  if (!carsWorks?.length)
    return (
      <p className="mt-4 ml-5">Brak danych. Spróbuj ustawić inne filtry.</p>
    )
  else
    return (
      <Table>
        <thead>
          <tr>
            <th>Samochód</th>
            <th>Typ prac</th>
            <th>Data</th>
            <th>Opis</th>
            <th>Koszt</th>
          </tr>
        </thead>
        <tbody>
          {carsWorks.map((work) => (
            <tr
              key={`carWorkRow-${work._id}`}
              className="clickable"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  body: (
                    <CarWorkEdit
                      allowedCars={allowedCars}
                      setModalData={setModalData}
                      isNew={false}
                      carWork={work}
                      refresh={refresh}
                    />
                  ),
                })
              }
            >
              <td>{`${work.car?.brand} ${work.car?.model} ${work.car?.regNum} (${work.car?.name})`}</td>
              <td>{work.type}</td>
              <td>{format(new Date(work.date), "yyyy-MM-dd")}</td>
              <td>{work.desc}</td>
              <td>{work.cost}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    )
}
export { CarsWorksTable }
