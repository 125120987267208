import React from "react"

import { Col, Row, Container } from "react-bootstrap"

import {
  PrivatDetailsTable,
  CompanyDetailsTable,
  OrderDetailsTable,
  DeliveryDetailsTable,
} from "./Tables"

import logoEcoloxx from "../../../pics/Ecoloxx_logo.png"
import logoClipper from "../../../pics/logo_clipper.png"

class TransactionDetailsDeliveryConfirmation extends React.Component {
  // console.log(props)
  // const grossValue = props.transaction.orderDetails.prices
  //   .map((price) => price.grossPrice * price.quantity)
  //   .reduce((a, b) => a + b)

  // constructor(props) {
  //   super(props)
  //   this.state = {: "clipper" }
  // }
  render() {
    return (
      <Container className="p-5">
        <Row className="my-5 justify-content-center">
          <h1>Potwierdzenie dostarczenia</h1>
          <img
            src={
              this.props.transaction.seller === "Clipper"
                ? logoClipper
                : logoEcoloxx
            }
            width="20%"
            className="ml-auto"
            alt="" // to prevent eslint error
          />
        </Row>
        <Row className="mt-3">
          <Col>
            <p style={{ fontSize: "1.5rem" }}>Nabywca</p>
          </Col>
          <Col>
            <p style={{ fontSize: "1.5rem" }}>Sprzedawca</p>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="6">
            {this.props.transaction.customerType === "company" ? (
              <CompanyDetailsTable transaction={this.props.transaction} />
            ) : null}
            {this.props.transaction.customerType === "privat" ? (
              <PrivatDetailsTable transaction={this.props.transaction} />
            ) : null}
          </Col>
          <Col xs="6">
            <CompanyDetailsTable
              transaction={{ companyData: this.props.sellerData }}
            />
          </Col>
        </Row>
        <Row>
          <p style={{ fontSize: "1.1rem" }}>
            {" "}
            Potwierdzam odbiór towarów zgodnie z poniższą listą:
          </p>
        </Row>
        <Row>
          <Col xs="12">
            <OrderDetailsTable
              transaction={this.props.transaction}
              grossValue={this.props.grossValue}
              noPrices={true}
              // grossValue={grossValue}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          {this.props.transaction.realizationDetails.ourDelivery === "true" ? (
            <Col xs="6">
              <DeliveryDetailsTable
                transaction={this.props.transaction}
                deliveryPrint={true}
              />
            </Col>
          ) : (
            <p>Dostawa klienta</p>
          )}
          <Col xs={{ offset: "8" }}>
            <br />
            <p>.........................................................</p>

            <p style={{ fontSize: "1.2rem" }}>data i podpis</p>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default TransactionDetailsDeliveryConfirmation
