import React, { useMemo, useState } from "react"
import {
  Table,
  Row,
  Col,
  Button,
  Container,
  Form,
  Badge,
} from "react-bootstrap"
import { useForm } from "react-hook-form"

import HistoryFetch from "../_shared/history/fetch"

const NotesTable = ({
  notes,
  setModalData,
  noteTypes,
  apiAddress,
  refresh,
}) => {
  const [showAll, setShowAll] = useState(false)
  const notesFiltered = useMemo(() => {
    if (showAll) return notes
    return notes.filter((note) => note.state === "aktywna")
  }, [notes, showAll])

  return (
    <>
      <Row>
        <Col>
          <h3>Notatki</h3>
        </Col>
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Pokaż aktywne" : "Pokaż wszystkie"}
          </Button>
        </Col>
        <Col>
          <Row>
            <Button
              variant="secondary"
              className="ml-auto mr-2"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Tworzenie nowej notatki",
                  hideFooter: true,
                  body: (
                    <NoteEditBody
                      isNew={true}
                      setModalData={setModalData}
                      noteTypes={noteTypes}
                      apiAddress={apiAddress}
                      refresh={refresh}
                    />
                  ),
                })
              }
            >
              Nowa
            </Button>
          </Row>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Rodzaj</th>
            <th>Dodał</th>
            <th>Data dodania</th>
            <th>Treść</th>
          </tr>
        </thead>
        <tbody>
          {notesFiltered.map((note) => {
            return (
              <tr
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Szczegóły notatki",
                    body: (
                      <NoteDetailsBody
                        note={note}
                        setModalData={setModalData}
                        isNew={false}
                        noteTypes={noteTypes}
                        apiAddress={apiAddress}
                        refresh={refresh}
                      />
                    ),
                  })
                }
              >
                <td>{note.type}</td>
                <td>{note.creator || "BŁĄD"}</td>
                <td>{note.creationDate || "BŁĄD"}</td>
                <td>{note.body}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

const NoteDetailsBody = ({
  note,
  setModalData,
  noteTypes,
  apiAddress,
  refresh,
}) => {
  const handleStateChange = () =>
    setModalData({
      show: true,
      type: "info",
      header: "Potwierdź zmianę stanu",
      headerColor: "warning",
      hideFooter: true,
      body: (
        <>
          <p>
            <b>
              Czy na pewno chcesz zmienić stan notatki na "
              {note.state === "aktywna" ? "archiwum" : "aktywna"}"?
            </b>
          </p>
          <Row className="justify-content-between mt-3 mx-3">
            <Button onClick={() => setModalData({ show: false })}>Nie</Button>
            <Button
              variant="warning"
              onClick={() =>
                submitNote(
                  setModalData,
                  apiAddress,
                  {
                    ...note,
                    state: note.state === "aktywna" ? "archiwum" : "aktywna",
                  },
                  false,
                  { state: true },
                  refresh
                )
              }
            >
              Tak
            </Button>
          </Row>
        </>
      ),
    })

  return (
    <Container>
      <Row className="justify-content-between mx-1 mb-3">
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: "Historia notatki",
              body: <HistoryFetch entity_id={note._id} />,
            })
          }
        >
          Historia
        </Button>
        <Button variant="warning" onClick={handleStateChange}>
          {note.state === "aktywna" ? "Archiwizuj" : "Przywróć"}
        </Button>

        <Button
          variant="secondary"
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "Edycja notatki",
              hideFooter: true,
              body: (
                <NoteEditBody
                  isNew={false}
                  setModalData={setModalData}
                  note={note}
                  noteTypes={noteTypes}
                  apiAddress={apiAddress}
                  refresh={refresh}
                />
              ),
            })
          }
        >
          Edytuj
        </Button>
      </Row>

      <p>
        <b>Rodzaj:</b>
        <br /> {note.type}
      </p>
      <p>
        <b>Treść:</b>
        <br />
        {note.body}
      </p>
    </Container>
  )
}

const NoteEditBody = ({
  note,
  setModalData,
  isNew,
  noteTypes,
  apiAddress,
  refresh,
}) => {
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: isNew ? { type: "plug", body: null } : note,
  })
  const submit = (formData) =>
    submitNote(
      setModalData,
      apiAddress,
      { ...note, ...formData },
      isNew,
      dirtyFields,
      refresh
    )
  return (
    <Container>
      <Form onSubmit={handleSubmit(submit)}>
        Rodzaj:
        {errors.type && (
          <Badge pill variant="warning">
            {errors.type.message}
          </Badge>
        )}
        <Form.Control
          {...register("type", {
            required: "Pole wymagane",
            validate: (v) => v !== "plug" || "Pole wymagane",
          })}
          as="select"
          autoComplete="chrome-off"
        >
          <option key={`noteTypeSelect-plug`} value="plug" hidden>
            wybierz
          </option>
          {noteTypes.map((type) => (
            <option key={`noteTypeSelect-${type}`}>{type}</option>
          ))}
        </Form.Control>
        Treść:
        {errors.body && (
          <Badge pill variant="warning">
            {errors.body.message}
          </Badge>
        )}
        <Form.Control
          {...register("body", { required: "Pole wymagane" })}
          as="textarea"
          type=""
          className=""
          autoComplete="chrome-off"
        />
        <Row className="justify-content-between mx-1 mt-3">
          <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
          <Button type="submit" variant="secondary">
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}

const submitNote = async (
  setModalData,
  apiAddress,
  formData,
  isNew,
  dirtyFields,
  refresh
) => {
  try {
    const res = await fetch(apiAddress, {
      method: isNew ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ formData: formData, dirtyFields: dirtyFields }),
    })
    if (res.status !== 200)
      throw new Error(
        `Błąd komunikacji z serwerem: ${res.status} - ${
          (await res.text()) || "nieokreślony błąd"
        }`
      )
    setModalData({
      show: true,
      type: "info",
      header: "Sukces",
      headerColor: "success",
      body: "Zmiany zapisane",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      header: "Błąd",
      body: err.message || "Nieokreślony błąd programu",
    })
  }
}

export { NotesTable }
