// submit export function

const fetchFiltered = async (filters, setModalData, setInvoices, setPage) => {
  try {
    let query = ""
    // create query:
    for (let key in filters) query = `${query}&${key}=${filters[key]}`
    const res = await fetch(`/invoices/getFiltered?${query}`)

    if (res.status !== 200) throw new Error(`${await res.text()}`)

    const resJSON = await res.json()

    // when there are less pages in response then page choosen:
    if (filters.page > resJSON.pages) setPage(resJSON.pages)
    return setInvoices(resJSON)
  } catch (err) {
    console.log(err)
    setModalData({ show: true, type: "alert", body: `Błąd: ${err.message}` })
  }
}

const submitExport = async (
  invArr,
  setModalData,
  setInvoiceExported,
  refresh,
  setIsFetching
) => {
  try {
    setIsFetching(true)
    const res = await fetch("/invoices/export", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        invoicesArr: invArr,
        shouldSetInvExported: setInvoiceExported,
      }),
    })

    if (res.status !== 200)
      throw new Error(`${res.status} - ${await res.text()}`)

    return setModalData({
      show: true,
      type: "info",
      headerColor: "success",
      header: "Sukces",
      body: "Faktura(-y) wyeksportowana(-e)!",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: `Błąd: ${err.message}`,
      onHide: refresh,
    })
    setIsFetching(false) // yes, I know, but it feels better. And I'm in a middle of 4th energy drink.
  }
}

const handleDelete = async (inv_id, setModalData, refresh) => {
  try {
    const res = await fetch(`/invoices/deleteInv?_id=${inv_id}`, {
      method: "DELETE",
    })

    if (res.status !== 200)
      throw new Error(`${res.status} - ${await res.text()}`)

    return setModalData({
      show: true,
      type: "info",
      headerColor: "success",
      header: "Sukces",
      body: "Faktura usunięta!",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({ show: true, type: "alert", body: `Błąd: ${err.message}` })
  }
}

export { fetchFiltered, submitExport, handleDelete }
