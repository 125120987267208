import { useState, useMemo, useEffect } from "react"
import {
  Alert,
  Container,
  Form,
  Row,
  Table,
  Button,
  Col,
  Spinner,
} from "react-bootstrap"
import _ from "lodash"

import renderSpinner from "../../../utils/renderSpinner"

/*
! keep this component outside of main <form> to prevent errors
 Show location services to select (radio)
 When srv selected shows filters and handles fetching eqp
 Shows fetched eqp and allows to choose it and qty
 When re-fetching with new filters -> keeps selected eqp
 
 on each change updates services state from parent. 
 In effect services state should reflect  job.services array expected by api and mongo
 */

const DeliverySrvAndEqpSelect = ({
  setServices,
  services,
  locServices,
  setEqpSelected,
  eqpSelected,
  hideServices, // use when adding delivery after adding service,
  // SELECTED SERVICE MUST BE determined and used in parent component
  // 'services' prop must include only one service (selected one)
}) => {
  const [equipment, setEquipment] = useState([])
  const [isEqpFetched, setIsEqpFetched] = useState(false) // I'll set this to true on first fetch and use it to show alert for user in EqpList
  const [fetchingEqp, setFetchingEqp] = useState(false)

  // get selected service object (needed for srv details like eqp types etc):
  const locSrvSelected = useMemo(() => {
    return (
      locServices.find(
        (locSrv) => locSrv._id === services[0]?.locationServiceRef
      ) || null
    )
  }, [services, locServices])

  return (
    <Container>
      {hideServices ? null : (
        <SrvList
          locServices={locServices}
          services={services}
          setServices={setServices}
          locSrvSelected={locSrvSelected}
        />
      )}
      {services.length ? (
        <EqpFiltersFetch
          setEquipment={setEquipment}
          equipment={equipment}
          locSrvSelected={hideServices ? services[0] : locSrvSelected}
          setIsEqpFetched={setIsEqpFetched}
          setFetchingEqp={setFetchingEqp}
          fetchingEqp={fetchingEqp}
          eqpSelected={eqpSelected}
        />
      ) : null}
      <EqpList
        equipment={equipment}
        setEqpSelected={setEqpSelected}
        eqpSelected={eqpSelected}
        isEqpFetched={isEqpFetched}
        fetchingEqp={fetchingEqp}
      />
    </Container>
  )
}

const SrvList = ({ services, setServices, locServices, locSrvSelected }) => {
  if (!locServices || !locServices.length)
    return (
      <Alert variant="danger">
        Brak usług na lokalizacji, <u>lub błąd działania programu</u>
      </Alert>
    )
  else
    return (
      <Container>
        <h3>Wybierz usługę</h3>
        {locServices
          .filter((srv) => srv.mustHaveEqp && srv.state === "aktywna")
          .map((locSrv) => {
            return (
              <Form.Check
                type="radio"
                label={`${locSrv.locSrvNo || "?"}. ${locSrv.name}`}
                id={`srvChooseRadio-${locSrv._id}`}
                onChange={(e) => {
                  // on change set job services according to mongo schema:
                  setServices([
                    {
                      locationServiceRef: locSrv._id,
                      serviceRef: locSrv.serviceRef,
                      eqp: [], // since it is delivery, eqp will be added later
                    },
                  ])
                }}
                checked={locSrv._id === locSrvSelected?._id ? true : false}
                key={`srvChooseRadio-${locSrv._id}`}
              />
            )
          })}
      </Container>
    )
}

const EqpFiltersFetch = ({
  setServices,
  services,
  setEquipment,
  equipment,
  eqpSelected,
  locSrvSelected,
  setIsEqpFetched,
  setFetchingEqp,
  fetchingEqp,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [eqpTypes, setEqpTypes] = useState([])
  const [fetchError, setFetchError] = useState(null)

  useEffect(() => {
    const fetchEqpTypes = async () => {
      try {
        const res = await fetch(`/configs/eqpTypes`)
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych konfiguracyjnych: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        setEqpTypes(resJSON.eqpTypes)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchError(err.message || "Nieokreślony błąd działania programu")
        setIsLoading(false)
      }
    }

    fetchEqpTypes()
  }, [])

  if (isLoading) return renderSpinner("pobieram rodzaje sprzętu")
  else if (fetchError) return <Alert variant="danger">{fetchError}</Alert>
  return (
    <EqpFilters
      setEquipment={setEquipment}
      equipment={equipment}
      services={services}
      setServices={setServices}
      locSrvSelected={locSrvSelected}
      eqpTypes={eqpTypes}
      setIsEqpFetched={setIsEqpFetched}
      setFetchingEqp={setFetchingEqp}
      fetchingEqp={fetchingEqp}
      eqpSelected={eqpSelected}
    />
  )
}

const EqpFilters = ({
  setEquipment,
  equipment,
  locSrvSelected,
  eqpTypes,
  setIsEqpFetched,
  setFetchingEqp,
  fetchingEqp,
  eqpSelected,
}) => {
  const [fetchError, setFetchError] = useState(null)
  // const [isLoading, setIsLoading] = useState()

  const [eqpType, setEqpType] = useState(locSrvSelected.eqpTypes[0])
  const [isTemplate, setIsTemplate] = useState(
    eqpType === "Toaleta" || eqpType === "Prysznic"
  )
  const [propsFilters, setPropsFilters] = useState({})

  const [eqpSelectedConfigs, setEqpSelectedConfigs] = useState(
    eqpTypes.find(
      (eqpTypesEl) => eqpTypesEl.type === locSrvSelected.eqpTypes[0]
    )
  )

  //# clear filters on type change and set propsFilters to defaults (first arr element):
  useEffect(() => {
    if (eqpType === "Toaleta" || eqpType === "Prysznic") {
      setIsTemplate(true)
    } else {
      setIsTemplate(false)
    }

    const newEqpSelectedConfigs = eqpTypes.find(
      (eqpTypesEl) => eqpTypesEl.type === eqpType
    )
    setEqpSelectedConfigs(newEqpSelectedConfigs)
    setPropsFilters(setDefaultPropsFilter(newEqpSelectedConfigs))
  }, [eqpType, eqpTypes])

  const fetchEqp = async (e) => {
    try {
      e.preventDefault()
      setFetchingEqp(true)
      setFetchError(null)
      const propsArr = ["subtype", "char", "color", "category"] // it is useful in few places later

      let query = { type: eqpType, isTemplate: isTemplate }
      // when not template use only props !== "bez znaczenia"
      if (!isTemplate) {
        propsArr.forEach((prop) => {
          if (propsFilters[prop] && propsFilters[prop] !== "bez znaczenia")
            query[prop] = propsFilters[prop]
        })
        query.mods = propsFilters.mods

        if (propsFilters.number) query.number = propsFilters.number
      }
      // when template just use all:
      else query = { ...query, ...propsFilters }

      //# remove mods array if empty
      if (query.mods?.length === 0) delete query.mods

      //# prevent quering all real toilets:
      if (
        eqpType === "Toaleta" &&
        !isTemplate &&
        Object.keys(query).length === 2 // there is always type and isTemplate
      ) {
        setFetchingEqp(false)
        return setFetchError(
          <p>
            To zapytanie spowoduje pobranie listy <b>wszystkich</b> toalet
            (kilkaset pozycji). Wybierz dodatkowe filtry!
          </p>
        )
      }

      const res = await fetch(`/equipment/getFilteredV2`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      })
      if (res.status !== 200)
        throw new Error(
          `błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      let eqp = await res.json()

      // remove already selected eqp from res:
      const selecteEqp_ids = eqpSelected.map((eqpSelEl) => eqpSelEl.ref)
      eqp = eqp.filter((eqpEl) => !selecteEqp_ids.includes(eqpEl._id))

      // preserve selected eqp objects
      const eqpSelectedObjArr = equipment.filter((eqpEl) =>
        selecteEqp_ids.includes(eqpEl._id)
      )
      // if no eqp fetched show alert and NewEqpRequest btn:
      if (!eqp.length) {
        setIsEqpFetched(true) // it's used to show alert to user when no eqp fetched
        setFetchingEqp(false)
        setEquipment(eqpSelectedObjArr)
        return setFetchError(
          <>
            <p>
              Brak sprzętu dla zapytania{" "}
              {eqpSelectedObjArr.length
                ? "(wyświetlam tylko wcześniej zaznaczony)"
                : null}
              .
            </p>
            <NewEqpRequestForm
              eqpParamsString={`${eqpType}, ${
                isTemplate ? "templatka" : "realny sprzęt"
              }, ${createPropsString(propsFilters)}`}
            />
          </>
        )
      }

      setEquipment([...eqpSelectedObjArr, ...eqp])
      setIsEqpFetched(true) // it's used to show alert to user when no eqp fetched
      setFetchingEqp(false)
    } catch (err) {
      console.log(err)
      setFetchError("Błąd pobierania danych")
    }
  }

  return (
    <>
      <Form onSubmit={(e) => fetchEqp(e)}>
        <h4>Sprzęt - filtr</h4>
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Typ:
              </td>
              <td colSpan="2">
                <Form.Control
                  as="select"
                  className=""
                  autoComplete="chrome-off"
                  onChange={(e) => {
                    e.preventDefault()
                    setEqpType(e.target.value)
                  }}
                >
                  {locSrvSelected.eqpTypes.map((srvEqpType) => (
                    <option
                      value={srvEqpType}
                      key={`eqpTypeSelectOpt-${srvEqpType}`}
                    >
                      {srvEqpType}
                    </option>
                  ))}
                </Form.Control>
              </td>
            </tr>

            {eqpType === "Toaleta" || eqpType === "Prysznic" ? (
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Rodzaj:
                </td>
                <td colSpan="2">
                  <Row>
                    <Col>
                      <Form.Check
                        type="radio"
                        name="isTemplate"
                        label="templatka"
                        checked={isTemplate}
                        id="isTemplate"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setIsTemplate(true)
                            setPropsFilters({ ...propsFilters, number: null })
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="radio"
                        name="isTemplate"
                        label="realny sprzęt"
                        checked={!isTemplate}
                        id="noTemplate"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setIsTemplate(false)
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </td>
              </tr>
            ) : null}

            <PropsFilter
              eqpSelectedConfigs={eqpSelectedConfigs}
              eqpType={eqpType}
              isTemplate={isTemplate}
              setPropsFilters={setPropsFilters}
              propsFilters={propsFilters}
            />
          </tbody>
        </Table>
        {fetchError ? <Alert variant="warning">{fetchError}</Alert> : null}
        <Row className="justify-content-end mr-3">
          <Button type="submit" disabled={fetchingEqp} id="fetchEqp">
            {fetchingEqp ? (
              <Spinner animation="border" size="sm" role="status" />
            ) : (
              "Pobierz"
            )}
          </Button>
        </Row>
      </Form>
    </>
  )
}

const PropsFilter = ({
  eqpSelectedConfigs,
  eqpType,
  isTemplate,
  propsFilters,
  setPropsFilters,
}) => {
  const renderNumberInput = () => {
    if (
      (eqpType === "Prysznic" || eqpType === "Toaleta") &&
      (isTemplate === false || isTemplate === "false")
    )
      return (
        <tr>
          <td className="fixed-values-table" colSpan="2">
            Numer
          </td>
          <td colSpan="2">
            <Form.Control
              as="input"
              type="number"
              className=""
              autoComplete="chrome-off"
              value={propsFilters.number}
              onChange={(e) => {
                e.preventDefault()
                setPropsFilters({ ...propsFilters, number: e.target.value })
              }}
            />
          </td>
        </tr>
      )
    else return null
  }

  const renderPropSelect = (propName) => {
    if (!eqpSelectedConfigs[propName]?.length)
      return (
        <Alert variant="secondary">Niedostępne dla tego typu sprzętu</Alert>
      )
    else
      return (
        <Form.Control
          as="select"
          className=""
          autoComplete="chrome-off"
          value={propsFilters[propName]}
          key={`propSelect-${propName}`}
          onChange={(e) => {
            e.preventDefault()
            setPropsFilters({ ...propsFilters, [propName]: e.target.value })
          }}
        >
          {eqpSelectedConfigs[propName].map((propVal) => (
            <option
              value={propVal}
              key={`propSelectOption-${propName}-${propVal}`}
            >
              {propVal}
            </option>
          ))}
        </Form.Control>
      )
  }

  return (
    <>
      {renderNumberInput()}
      <tr>
        <td className="fixed-values-table">Podtyp</td>
        <td>{renderPropSelect("subtype")}</td>
        <td className="fixed-values-table">Kolor</td>
        <td>{renderPropSelect("color")}</td>
      </tr>
      <tr>
        <td className="fixed-values-table">Charakterystyka</td>
        <td>{renderPropSelect("char")}</td>
        <td className="fixed-values-table">Kategoria</td>
        <td>{renderPropSelect("category")}</td>
      </tr>
      <tr>
        <td colSpan={4} className="fixed-values-table">
          Wyposażenie
        </td>
      </tr>
      <tr>
        <td colSpan={4}>
          {eqpSelectedConfigs.mods?.length ? (
            <Row>
              {eqpSelectedConfigs.mods.map((mod) => (
                <Col>
                  <Form.Check
                    type="checkbox"
                    label={mod}
                    value={mod}
                    id={`mods-${mod}`}
                    key={`modCheckbox-${mod}`}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newPropsFilters = { ...propsFilters }
                        newPropsFilters.mods.push(mod)
                        setPropsFilters(newPropsFilters)
                      } else {
                        const newPropsFilters = { ...propsFilters }
                        _.pull(propsFilters.mods, mod)
                        setPropsFilters(newPropsFilters)
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Alert variant="secondary">
              Brak modyfikacji dla tego typu sprzętu
            </Alert>
          )}
        </td>
      </tr>
    </>
  )
}

const EqpList = ({
  equipment,
  eqpSelected,
  setEqpSelected,
  isEqpFetched,
  fetchingEqp,
}) => {
  if (fetchingEqp) return renderSpinner("pobieram listę sprzętu")
  else if (!equipment?.length) return ""
  else
    return (
      <Table>
        <thead>
          <tr>
            <td></td>
            <td>Typ</td>
            <td>Liczba</td>
            <td>Podtyp</td>
            <td>Charakterystyka</td>
            <td>Kolor</td>
            <td>Kategoria</td>
            <td>Wyposażenie</td>
            <td>Numer</td>
          </tr>
        </thead>
        <tbody>
          {equipment.map((eqp) => (
            <EqpRow
              key={`eqpRow-${eqp._id}`}
              eqp={eqp}
              eqpSelected={eqpSelected}
              setEqpSelected={setEqpSelected}
            />
          ))}
        </tbody>
      </Table>
    )
}

const NewEqpRequestForm = ({ eqpParamsString }) => {
  const [showReqForm, setShowReqForm] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [userComment, setUserComment] = useState("")
  const [mailSending, setMailSending] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async () => {
    try {
      setMailSending(true)
      setFetchError(null)
      const mailBody = `Prośba o dodanie sprzętu: \n\nProszę o dodanie sprzętu o następujących parametrach: ${eqpParamsString}\n\n${
        userComment.length ? `Dodatkowe uwagi:${userComment}\n\n` : ""
      }Możesz skorzystać z funkcji "odpowiedz", żeby wysłać maila do pracownika.`

      const res = await fetch("/misc/mail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mess: mailBody }),
      })
      if (res.status !== 200) {
        setMailSending(false)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
          }`
        )
      }
      setMailSending(false)
      return setSuccess(true)
    } catch (err) {
      console.log(err)
      setFetchError(err.message || "nieokreślony błąd programu")
    }
  }

  return (
    <>
      <Button onClick={() => setShowReqForm(!showReqForm)}>
        {showReqForm ? "Ukryj formularz" : "Poproś o dodanie sprzętu do bazy"}
      </Button>
      {showReqForm ? (
        <>
          <p>
            Wyślij maila o treści:
            <br />
            Proszę o dodanie do katalogu sprzętu o następujących parametrach:{" "}
            <b>{eqpParamsString}</b>
            <br />
            Poniżej możesz dopisać swoje uwagi:
          </p>
          <Form.Control
            as="textarea"
            autoComplete="chrome-off"
            onChange={(e) => setUserComment(e.target.value)}
            disabled={mailSending || success}
          />
          <Row className="justify-content-between mx-3">
            <Button
              variant="secondary"
              onClick={sendMail}
              disabled={mailSending || success}
            >
              Wyślij
            </Button>
          </Row>

          {mailSending ? renderSpinner("wysyłam wiadomość") : null}
          {fetchError ? <Alert variant="danger">{fetchError}</Alert> : null}
          {success
            ? "Wiadomość wysłana, możesz zamknąć formularz przyciskiem na górze"
            : null}
        </>
      ) : null}
    </>
  )
}

const createPropsString = (propsFilters) => {
  let str = ""
  for (const key in propsFilters) {
    if (Object.hasOwnProperty.call(propsFilters, key)) {
      if (key === "mods") str = `${str}, ${propsFilters[key].join(", ")}`
      else {
        const element = propsFilters[key]
        str = `${str}, ${element}`
      }
    }
  }
  return str
}

const EqpRow = ({ eqp, eqpSelected, setEqpSelected }) => {
  const [isSelected, setIsSelected] = useState(
    eqpSelected.find((eqpSelectedEl) => eqpSelectedEl.ref === eqp._id)
  )

  useEffect(() => {
    setIsSelected(
      eqpSelected.find((eqpSelectedEl) => eqpSelectedEl.ref === eqp._id)
    )
  }, [eqpSelected, eqp])

  return (
    <tr>
      <td>
        <Form.Check
          type="checkbox"
          checked={isSelected}
          id={`eqpCheckbox-${eqp._id}`}
          onChange={(e) => {
            // e.preventDefault()

            //# adding
            if (e.target.checked) {
              const newEqpSelected = [...eqpSelected]
              newEqpSelected.push({ ref: eqp._id, qty: null })

              // when checking not template and not unnumbered eqp -> set qty = 1
              if (!eqp.isTemplate && eqp.number !== 0)
                newEqpSelected[newEqpSelected.length - 1].qty = 1
              setEqpSelected(newEqpSelected)
            }
            //# removing:
            else {
              const newEqpSelected = [...eqpSelected]
              // remove eqp by its _id/ref
              const eqpInd = newEqpSelected.findIndex(
                (eqpSelectedEl) => eqpSelectedEl.ref === eqp._id
              )
              newEqpSelected.splice(eqpInd, 1)
              setEqpSelected(newEqpSelected)

              // clear qty input
              const qtyInput = document.getElementById(`eqpQty-${eqp._id}`)

              // in some cases qty input can be disabled
              if (qtyInput?.value) qtyInput.value = ""
            }
          }}
        />
      </td>
      <td>{eqp.type}</td>
      <td>
        <Form.Control
          /*
        disable when not selected,
        or when is not template
        and when number is not 0
      
        */
          readOnly={!isSelected || (!eqp.isTemplate && eqp.number !== 0)}
          as="input"
          type="number"
          className="medium-number-input"
          autoComplete="chrome-off"
          id={`eqpQty-${eqp._id}`}
          value={isSelected?.qty || ""}
          min={0}
          onChange={(e) => {
            const newEqpSelected = [...eqpSelected]
            const eqpInd = newEqpSelected.findIndex(
              (eqpSelectedEl) => eqpSelectedEl.ref === eqp._id
            )

            // check if eqp found as onChange can fire also when clearing input on checkbox uncheck
            if (eqpInd !== -1) {
              newEqpSelected[eqpInd].qty = e.target.value

              setEqpSelected(newEqpSelected)
            }
          }}
        />
      </td>
      <td>{eqp.subtype}</td>
      <td>{eqp.char}</td>
      <td>{eqp.color}</td>
      <td>{eqp.category}</td>
      <td>{eqp.mods?.join(", ") || ""}</td>
      <td>{eqp.isTemplate ? "tmpl." : eqp.number}</td>
    </tr>
  )
}

const setDefaultPropsFilter = (eqpSelectedConfigs) => {
  const newPropsFilters = { mods: [] }
  const propsArr = ["subtype", "char", "color", "category"]

  propsArr.forEach((prop) => {
    if (eqpSelectedConfigs[prop]?.length)
      newPropsFilters[prop] = eqpSelectedConfigs[prop][0]
  })
  return newPropsFilters
}

export { DeliverySrvAndEqpSelect }
