import React, { useState, useContext } from "react"
import { useCookies } from "react-cookie"

import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"

import UserContext from "../contexts/userContext"

import ConsumptionsTabRender from "./consumptionsTabRender"

const ConsumptionsTabFetch = () => {
  const [cookies] = useCookies()
  const user = useContext(UserContext)
  const [consumptions, setConsumptions] = useState()
  const [isLoading, setIsLoading] = useState(true)

  // idea: display summary consumptions per month and below all consumptions table (divide it in two components?). What to do with branches?

  let date = null
  const setDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    if (today.getMonth() === 0 && today.getDate() < 10) {
      const dateObj = { month: 12, year: today.getFullYear() - 1 }
      date = dateObj
    } else if (today.getDate() > 10) {
      date = { year: year, month: today.getMonth() + 1 }
    } else {
      date = { year: year, month: today.getMonth() }
    }
  }
  setDate()

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const dateToFetch = {
        year: e.target.year.value,
        month: e.target.month.value,
      }

      const res = await fetch(
        `/consumptions/getByDate/?month=${dateToFetch.month}&year=${dateToFetch.year}`,
        {
          headers: {
            authorization: cookies.jwt,
            "content-type": "application/json",
          },
          method: "GET",
        }
      )
      const userBranches = Object.keys(user.branch).filter(
        (branch) => user.branch[branch]
      )
      const resJSON = await res.json()
      //set filter consumptions depending on user.branch:
      if (user.type.admin) {
        setConsumptions(resJSON)
      } else {
        const filteredConsumptions = resJSON.filter((consumption) =>
          userBranches.includes(consumption.consumptionHeader.branch)
        )
        setConsumptions(filteredConsumptions)
      }

      setIsLoading(false)
    } catch (err) {
      alert(err)
    }
  }

  return (
    // <div className="content-container">
    <Container>
      <br />
      <Form inline onSubmit={handleSubmit}>
        <Form.Label>Miesiąc:</Form.Label>
        <Form.Control
          type="number"
          name="month"
          defaultValue={date.month}
        ></Form.Control>
        <Form.Label>Rok:</Form.Label>
        <Form.Control
          type="number"
          name="year"
          defaultValue={date.year}
        ></Form.Control>
        <Col xs="2">
          <Button type="submit" variant="secondary">
            Wczytaj
          </Button>
        </Col>
      </Form>
      {isLoading ? null : (
        <ConsumptionsTabRender
          consumptions={consumptions}
          changeIsLoading={() => setIsLoading(true)}
        />
      )}
    </Container>
    // </div>
  )
}
export default ConsumptionsTabFetch
