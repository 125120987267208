import React, { useRef } from "react"
import ReactTooltip from "react-tooltip"
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  DropdownButton,
  Dropdown,
  Alert,
} from "react-bootstrap"
import { ImNewTab } from "react-icons/im"
import { FaSnowflake } from "react-icons/fa"
import { AiOutlinePrinter } from "react-icons/ai"

import { format } from "date-fns"

import { useReactToPrint } from "react-to-print"

import HistoryTable from "../../utils/HistoryTable"
import { renderSrvAndEqp } from "./utils"
import JobEditModal from "./JobEditModal"
import DeliveryConfirmation from "./DeliveryConfirmation"
import JobStateChangeModal from "./JobStateChangeModal"

const JobDetailsModal = (props) => {
  const {
    job,
    setModalData,
    refresh,
    user,
    location,
    configs,
    cars,
    parent,
    allowConfirmationPrint,
    hideHistoryBtn,
  } = props
  const deliveryConfirmation = useRef()
  const handlePrint = useReactToPrint({
    content: () => deliveryConfirmation.current,
  })

  const jobDateString = format(new Date(job.date), "yyyy-MM-dd")

  const renderChangeStateBtn = () => {
    // if perm.jobs.d -> allow any change
    if (user.perm.jobs.d && location.state === "aktywna")
      return (
        <DropdownButton
          variant="warning"
          title="Zmien stan"
          id="changeJobState-dropdown"
        >
          {configs.jobState.map((state) => {
            if (
              job.type === "dostarczenie" &&
              (job.state === "usunięte" ||
                job.state === "odwołane (klient)" ||
                job.state === "odwołane (my)" ||
                job.state === "niewykonane (klient)" ||
                job.state === "niewykonane (my)") &&
              (state === "zaplanowane" ||
                state === "zlecone" ||
                state === "wykonane")
            )
              return null
            else
              return (
                <Dropdown.Item
                  key={`change-state-dropdown-${state}`}
                  onClick={() => {
                    return setModalData({
                      show: true,
                      type: "info",
                      body: (
                        <JobStateChangeModal
                          job={job}
                          location={location}
                          refresh={refresh}
                          setModalData={setModalData}
                          newState={state}
                        />
                      ),
                      hideFooter: true,
                    })
                  }}
                >
                  {state}
                </Dropdown.Item>
              )
          })}
        </DropdownButton>
      )
    // if perm.logistics.d -> allow only 'zaplanowane' <-> 'zlecone' change IF job is not done
    else if (
      user.perm.logistics.d &&
      (job.state === "zaplanowane" || job.state === "zlecone")
    )
      return (
        <DropdownButton
          variant="warning"
          title="Zmien stan"
          id="changeJobState-dropdown"
        >
          {["zaplanowane", "zlecone"].map((state) => {
            return (
              <Dropdown.Item
                key={`change-state-dropdown-${state}`}
                onClick={() => {
                  return setModalData({
                    show: true,
                    type: "info",
                    body: (
                      <JobStateChangeModal
                        job={job}
                        location={location}
                        refresh={refresh}
                        setModalData={setModalData}
                        newState={state}
                      />
                    ),
                    hideFooter: true,
                  })
                }}
              >
                {state}
              </Dropdown.Item>
            )
          })}
        </DropdownButton>
      )
    else return null
  }

  return (
    <Container>
      <Row className="justify-content-between my-2">
        {hideHistoryBtn ? null : (
          <Button
            id="showHistoryBtn"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                header: `historia zadania z dnia ${jobDateString}`,
                body: (
                  <HistoryTable
                    history={job.history}
                    goBack={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: `Szczegóły zadania (${job.location.name})`,
                        body: (
                          <JobDetailsModal
                            job={job}
                            setModalData={setModalData}
                            user={user}
                            jobState={configs.jobState}
                            refresh={refresh}
                            configs={configs}
                            location={location}
                            parent={props.parent}
                          />
                        ),
                      })
                    }
                  />
                ),
              })
            }
          >
            Historia
          </Button>
        )}
        {props.parent !== "location" && (
          <Button
            onClick={() =>
              window.open(
                `/Locations/${job.location._id}`,
                "LocationDetails",
                "noopener noreferrer"
              )
            }
          >
            <ImNewTab />
            Lokalizacja
          </Button>
        )}
        {job.state === "zaplanowane" ? (
          <Button
            variant="danger"
            id="deleteJobBtn"
            onClick={() => {
              return setModalData({
                show: true,
                type: "info",
                body: (
                  <JobStateChangeModal
                    job={job}
                    location={location}
                    refresh={refresh}
                    setModalData={setModalData}
                    newState={"usunięte"}
                  />
                ),
                hideFooter: true,
              })
            }}
          >
            Usuń
          </Button>
        ) : null}
        {renderChangeStateBtn()}
        {job.type === "dostarczenie" && allowConfirmationPrint !== false && (
          <>
            <Button onClick={handlePrint}>
              <AiOutlinePrinter />
            </Button>
            <div style={{ display: "none" }}>
              <DeliveryConfirmation
                ref={deliveryConfirmation}
                job={job}
                location={location}
              />
            </div>
          </>
        )}

        {job.state === "zaplanowane" && location.state === "aktywna" && (
          <Button
            variant="secondary"
            id="editJobBtn"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                hideFooter: true,
                header: `Edycja zadania (${job.type}) z dnia ${jobDateString}`,
                body: (
                  <JobEditModal
                    job={job}
                    setModalData={setModalData}
                    configs={configs}
                    user={user}
                    refresh={refresh}
                    location={location}
                    cars={cars}
                    parent={parent}
                  />
                ),
              })
            }
          >
            Edytuj
          </Button>
        )}
      </Row>
      <p className="m-0" style={{ fontSize: "0.8rem", color: "white" }}>
        {job._id}
      </p>
      {/* 
      SHOW DEBT COLLECTION ALERT:
       */}
      {(job.location?.customer?.jobsSuspended ||
        location?.customer?.jobsSuspended) &&
      (job.state === "zaplanowane" || job.state === "zlecone") ? (
        <Alert
          style={{
            animationName: "red-alert",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            textAlign: "center",
          }}
        >
          <b>! WINDYKACJA - zadanie wstrzymane</b>
        </Alert>
      ) : null}
      <Table bordered>
        <tbody>
          {props.parent !== "location" && (
            <tr>
              <td colSpan="4" className="fixed-values-table">
                <a
                  href={`/CustomersDetails/${job.location?.customer?._id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "DarkSlateGray" }}
                >
                  {job.location?.customer?.shortName || "brak danych"}{" "}
                  <ImNewTab />
                </a>
                <br />
                <a
                  href={`/Locations/${job.location?._id || ""}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "DarkSlateGray" }}
                >
                  {job.location?.street || "brak ulicy"}{" "}
                  {job.location?.city || "brak miasta"} <ImNewTab />
                </a>{" "}
                {job.location?.jobsFreq || ""}
              </td>
            </tr>
          )}
          <tr>
            <td className="fixed-values-table">Data</td>
            <td>{jobDateString}</td>
            <td className="fixed-values-table">Stan</td>
            <td id="jobState">
              {job.isFrozen && (
                <>
                  <FaSnowflake
                    data-tip='<p style="font-size:1rem">Zadanie zostało "zamrożone" tzn. przynajmniej raz zostało odhaczone<br/>
    <b>Jeśli musisz edytować usługi lub sprzęt do tego zadania - skontaktuj się ze
    mną.</b></p>'
                  />{" "}
                  <ReactTooltip html={true} />
                </>
              )}
              {job.state}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Kierowca</td>
            <td id="driver">
              {job.hasDriver
                ? job.driver?.fullName || "brak nazwiska kierowcy"
                : "BRAK"}
            </td>

            <td className="fixed-values-table">Typ</td>
            <td id="type">{job.type}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Samochód</td>
            <td id="car">
              {job.car
                ? `${job.car.regNum} (${job.car.brand} ${job.car.model} "${
                    job.car.name || ""
                  }")`
                : "BRAK"}
            </td>
            <td className="fixed-values-table">Zakres godzinowy</td>
            <td colSpan="3" id="customHours">
              {job.customHours ? `${job.startTime} - ${job.endTime}` : "nie"}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="4">
              Uwagi
            </td>
          </tr>
          <tr>
            <td colSpan="4" id="jobComments">
              {job.comments}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="4">
              Komentarz do realizacji
            </td>
          </tr>
          <tr>
            <td colSpan="4">{job.doneComments}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="4">
              <Row>
                <Col>Usługi i sprzęt</Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td colSpan="4">{renderSrvAndEqp(job, location)}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default JobDetailsModal
