import { differenceInCalendarDays, isValid } from "date-fns"
import _ from "lodash"

import React from "react"
import { Form, Button, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { AiOutlineClear } from "react-icons/ai"

const DebtCollectionFilters = ({ setFilters }) => {
  const { register, handleSubmit } = useForm()

  return (
    <Form onSubmit={handleSubmit(setFilters)} inline>
      <Form.Control
        {...register("query")}
        as="input"
        type="text"
        autoComplete="chrome-off"
        autoFocus
        className="ml-auto mr-3"
        id="query"
      />

      <Button type="submit" className="mr-auto" id="submitBtn">
        Szukaj
      </Button>
    </Form>
  )
}

const DebtCollectionXlsxFilters = ({ setFilters }) => {
  const defaultValues = {
    deadline: { from: -2, to: null },
    debt: { from: 0, to: null },
    noActionSince: null,
    jobsSuspended: "all",
  }

  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  })

  const applyFilters = (formData) => {
    setFilters((prev) => ({ ...prev, ...formData }))
  }

  const clearFilters = () => {
    reset(defaultValues)
    setFilters((prev) => ({
      ...prev,
      ...defaultValues,
    }))
  }

  return (
    <Form inline onSubmit={handleSubmit(applyFilters)}>
      <Row>
        <Form.Group
          controlId="deadline"
          style={{ border: "1px solid grey" }}
          className="mx-2 p-1"
        >
          Termin
          <Form.Label>od: </Form.Label>
          <Form.Control
            {...register("deadline.from")}
            as="input"
            type="number"
            step="1"
            className="medium-number-input"
            autoComplete="chrome-off"
          />
          <Form.Label>do: </Form.Label>
          <Form.Control
            {...register("deadline.to")}
            as="input"
            type="number"
            step="1"
            className="medium-number-input"
            autoComplete="chrome-off"
          />{" "}
          dni
        </Form.Group>
        <Form.Group
          controlId="amount"
          style={{ border: "1px solid grey" }}
          className="mx-2 p-1"
        >
          Kwota
          <Form.Label>od: </Form.Label>
          <Form.Control
            {...register("debt.from")}
            as="input"
            type="number"
            className="large-number-input"
            autoComplete="chrome-off"
          />
          <Form.Label>do: </Form.Label>
          <Form.Control
            {...register("debt.to")}
            as="input"
            type="number"
            className="large-number-input"
            autoComplete="chrome-off"
          />
        </Form.Group>
        <Form.Group
          controlId="noActionSince"
          style={{ border: "1px solid grey" }}
          className="mx-2 p-1"
        >
          Brak akcji od:
          <Form.Control
            {...register("noActionSince")}
            as="input"
            type="number"
            className="medium-number-input"
            autoComplete="chrome-off"
          />
          dni
        </Form.Group>
        <Form.Group
          controlId="jobsSuspended"
          style={{ border: "1px solid grey" }}
          className="ml-2 mr-4 p-1"
        >
          Zadania zawieszone:{" "}
          <Form.Control
            {...register("jobsSuspended")}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            <option value="all">wszystkie</option>
            <option value="tak">tak</option>
            <option value="nie">nie</option>
          </Form.Control>
        </Form.Group>
        <Col>
          <Row className="mt-1">
            <Button type="submit" className="mr-3" id="submitBtn">
              Zastosuj
            </Button>
            <Button
              className="mr-auto"
              id="clearFilters"
              variant="warning"
              onClick={clearFilters}
            >
              <AiOutlineClear />
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

const handleFilters = (filters, customers) => {
  //! this func is used only when file is loaded
  //( else api call is made to get filtered customers )

  let customersFiltered = _.cloneDeep(customers)

  //* filter by name
  if (filters.query) {
    customersFiltered = customersFiltered.filter((customer) => {
      return (
        customer.name?.toLowerCase().includes(filters.query.toLowerCase()) ||
        customer.surname?.toLowerCase().includes(filters.query.toLowerCase()) ||
        customer.shortName?.toLowerCase().includes(filters.query.toLowerCase())
      )
    })
  }

  //* filter by deadline

  // here I use separate variable to handle initial file load when there are no filters.deadline
  // in such case I am using default value of -2
  let deadlineFrom
  if (!filters.deadline) {
    deadlineFrom = -2
  } else {
    deadlineFrom = filters.deadline.from
  }

  if (deadlineFrom) {
    customersFiltered = customersFiltered.filter((customer) => {
      let custDeadline = new Date(customer.deadline)

      // when customers deadline is not valid show it in filters results
      if (!isValid(custDeadline)) {
        return true
      }

      return (
        differenceInCalendarDays(new Date(), custDeadline) >=
        Number.parseInt(deadlineFrom)
      )
    })
  }

  if (filters.deadline?.to) {
    customersFiltered = customersFiltered.filter((customer) => {
      let custDeadline = new Date(customer.deadline)

      // when customers deadline is not valid show it in filters results
      if (!isValid(custDeadline)) {
        return true
      }

      return (
        differenceInCalendarDays(new Date(), custDeadline) <=
        Number.parseInt(filters.deadline.to)
      )
    })
  }

  //* filter by debt

  // here I use separate variable to handle initial file load when there are no filters.debt as in deadline.from

  let debtFrom
  if (!filters.debt) {
    debtFrom = 0
  } else {
    debtFrom = filters.debt.from
  }

  if (debtFrom || debtFrom === 0) {
    customersFiltered = customersFiltered.filter((customer) => {
      return customer.debt >= debtFrom
    })
  }
  if (filters.debt?.to) {
    customersFiltered = customersFiltered.filter((customer) => {
      return customer.debt <= filters.debt.to
    })
  }
  //* filter by noActionSince

  if (filters.noActionSince) {
    customersFiltered = customersFiltered.filter((customer) => {
      const lastAction = customer.lastAction
      // when no last action or no date -> return true
      if (!lastAction) {
        return true
      }

      const lastActionDate = new Date(lastAction.eventDate)
      if (!isValid(lastActionDate)) {
        return true
      }

      return (
        differenceInCalendarDays(new Date(), lastActionDate) >=
        Number.parseInt(filters.noActionSince)
      )
    })
  }
  //* filter by jobsSuspended
  //( only when jobsSuspended is not all )
  if (filters.jobsSuspended && filters.jobsSuspended !== "all") {
    customersFiltered = customersFiltered.filter((customer) => {
      if (filters.jobsSuspended === "tak") {
        return customer.jobsSuspended === true
      } else {
        return customer.jobsSuspended === false
      }
    })
  }

  return customersFiltered
}

export { DebtCollectionFilters, DebtCollectionXlsxFilters, handleFilters }
