import React, { useContext } from "react"
import UserContext from "../../contexts/userContext"
import { useForm } from "react-hook-form"

import { Table, Container, Col, Row, Form, Button } from "react-bootstrap"

import { PermissionHeaderTable } from "./Tabels"

const SewagePermissionDetailsEdit = (props) => {
  const user = useContext(UserContext)

  // setting user branches to use in branch select:
  const branches = Object.keys(user.branch).filter(
    (branch) => user.branch[branch]
  )

  const {
    register,
    handleSubmit,

    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      ...props.permission,
      reports: setReportsDefault(props.permission),
    },
  })

  const onSubmit = async (data) => {
    try {
      let res = {}
      if (props.isNew) {
        res = await fetch("/sewage/permission/new", {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        })
      } else {
        //add permission._id and historyLength to form data:
        data._id = props.permission._id
        data.historyLength = props.permission.historyLength
        res = await fetch("/sewage/permission/update", {
          method: "PUT",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({ permission: data, dirtyFields: dirtyFields }),
        })
      }

      if (res.status !== 200) {
        throw new Error(
          `${res.status} - ${(await res.text()) || "nieokreślony błąd"}`
        )
      }

      props.setModalData({
        show: true,
        type: "info",
        body: props.isNew ? "Dodano zezwolenie" : "Zmiany zapisane",
        func: props.isNew
          ? () => props.setGoBack(true)
          : () => window.location.reload(),
        onHide: props.isNew
          ? () => props.setGoBack(true)
          : () => window.location.reload(),
      })
    } catch (err) {
      console.log(err)
      props.setModalData({
        show: true,
        type: "alert",
        body: `Błąd komunikacji z serwerem: ${err.message}`,
      })
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <p style={{ color: "white" }}>{props.permission._id}</p>

        {/* hide header table when creating new permission */}
        {props.isNew ? null : (
          <PermissionHeaderTable permission={props.permission} />
        )}

        <Row className="justify-content-between">
          {/* when creating new - after clicking "go back" -> go to permissions table, else go to permission details */}
          {props.isNew ? (
            <Button onClick={() => props.setGoBack(true)}>Wróć</Button>
          ) : (
            <Button onClick={() => props.setShowEdit(false)}>Wróć</Button>
          )}
        </Row>

        <Table bordered className="mt-3">
          <tbody>
            <tr>
              <td className="bg-primary">Rodzaj</td>
              <td className="bg-primary">Gmina</td>
              <td className="bg-primary" colSpan="2">
                Symbol
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control {...register("purpose")} as="select">
                  {/* only one for now, in future can be mapped from configs DB */}
                  <option>Zezwolenie - Toalety</option>
                </Form.Control>
              </td>
              <td>
                <Form.Control {...register("municipal", { required: true })} />
                {errors.municipal && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
              </td>
              <td colSpan="2">
                <Form.Control
                  {...register("permissionNumber", { required: true })}
                />
                {errors.permissionNumber && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
              </td>
            </tr>
            <tr>
              <td className="bg-primary">Data ważności</td>
              <td className="bg-primary">Termin przypomnienia</td>
              <td className="bg-primary">Oddział</td>
              <td className="bg-primary">Sprawozdania?</td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register("deadline", { required: true })}
                  type="date"
                />
                {errors.deadline && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
              </td>
              <td>
                <Form.Control
                  {...register("remindTerm", { required: true })}
                  type="number"
                  className="medium-number-input"
                />
                {errors.remindTerm && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
              </td>
              <td>
                <Form.Control
                  {...register("branch", { required: true })}
                  as="select"
                >
                  {branches.map((branch) => (
                    <option key={`select-option-branch-${branch}`}>
                      {branch}
                    </option>
                  ))}
                </Form.Control>
                {errors.branch && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
              </td>

              <td>
                <Form.Check
                  {...register("reports")}
                  type="checkbox"
                  label="tak"
                  id="reports"
                />
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="bg-primary">
                Osoba do kontaktu
              </td>
              <td className="bg-primary" colSpan="2">
                Adres urzędu
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control {...register("depPersonName")} />
              </td>
              <td rowSpan="3" colSpan="2">
                <Form.Label>Nazwa</Form.Label>
                <Form.Control
                  {...register("depName", { required: true })}
                  as="input"
                  type="text"
                />
                {errors.depName && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}

                <Form.Label>Ulica i numer</Form.Label>
                <Form.Control
                  {...register("depStreet", { required: true })}
                  as="input"
                  type="text"
                />
                {errors.depStreet && (
                  <p className="text-danger"> Pole wymagane!</p>
                )}
                <Form.Label>Kod i miasto</Form.Label>
                <Form.Row>
                  <Col xs="2">
                    <Form.Control
                      {...register("depPostal", { required: true })}
                      as="input"
                    />
                    {errors.depPostal && (
                      <p className="text-danger"> Pole wymagane!</p>
                    )}
                  </Col>
                  <Col>
                    <Form.Control
                      {...register("depCity", { required: true })}
                    />
                    {errors.depCity && (
                      <p className="text-danger"> Pole wymagane!</p>
                    )}
                  </Col>
                </Form.Row>
              </td>
            </tr>
            <tr>
              <td className="bg-primary" colSpan="2">
                Telefon kontaktowy
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control {...register("depPersonTel")} />
              </td>
            </tr>
            <tr>
              <td className="bg-primary" colSpan="2">
                Lista zlewni
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control {...register("dumpStationsList")} as="textarea" />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* if archived - disable "save" button and change text */}
        <Row>
          <Button
            variant="secondary"
            type="submit"
            disabled={props.permission.state === "archiwum" ? true : false}
            className="ml-auto"
          >
            {props.permission.state === "archiwum"
              ? "Najpierw przywróć!"
              : "Zapisz"}
          </Button>
        </Row>
      </Form>
    </Container>
  )
}

const setReportsDefault = (perm) => {
  if (!Object.keys(perm).includes("reports")) return true
  if (perm.reports === "1") return true
  if (perm.reports === false || perm.reports === "0" || perm.reports === 0)
    return false
  else return true
}

export default SewagePermissionDetailsEdit
