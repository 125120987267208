import { useForm } from "react-hook-form"
import { Container, Button, Form } from "react-bootstrap"

const LocationsByDistanceForm = ({ setFilters, filters, user, isLoading }) => {
  const { register, handleSubmit } = useForm({ defaultValues: filters })

  const submit = (formData) => setFilters(formData)

  return (
    <Container fluid>
      <Form inline onSubmit={handleSubmit(submit)}>
        oddział:{" "}
        <Form.Control
          {...register("branch")}
          as="select"
          className=""
          autoComplete="chrome-off"
        >
          {user.allowedBranches.map((branch) => (
            <option key={branch} value={branch}>
              {branch}
            </option>
          ))}
        </Form.Control>
        odległość:{" "}
        <Form.Control
          {...register("dist")}
          as="input"
          type="number"
          className=""
          autoComplete="chrome-off"
          step="1"
          min="1"
        />
        {" [km]"}
        <Button type="submit" disabled={isLoading}>
          pobierz
        </Button>
      </Form>
    </Container>
  )
}
export { LocationsByDistanceForm }
