import React, { useEffect, useState } from "react"
import { Container, Modal } from "react-bootstrap"

import ErrorsLogRender from "./LogRender"
import ErrorsDetailsRender from "./DetailsRender"

const ErrorsLogFetch = () => {
  const [errors, setErrors] = useState(false)
  const [errorSelected, setErrorSelected] = useState(false)

  useEffect(() => {
    // ok, I now, but this module for now is only for me. Minimum effort, maximum effect ;)
    const fetchErrors = async () => {
      const res = await fetch("logs/errors")
      if (res.status === 403) return alert("brak uprawnień")
      if (res.status !== 200) return alert("błąd, status !==200")
      const resJSON = await res.json()
      console.log(resJSON)
      setErrors(resJSON)
    }
    fetchErrors()
  }, [])
  return (
    <Container>
      {errors === false ? (
        <p>Wczytuję...</p>
      ) : (
        <ErrorsLogRender errors={errors} setErrorSelected={setErrorSelected} />
      )}
      <Modal
        show={errorSelected}
        onHide={() => setErrorSelected(false)}
        animation={false}
        size="xl"
      >
        <ErrorsDetailsRender error={errorSelected} />
      </Modal>
    </Container>
  )
}
export default ErrorsLogFetch
