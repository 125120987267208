import React from "react"

const Login = () => {
  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const data = new FormData()
      data.append("pass", e.target.pass.value)
      data.append("name", e.target.name.value)
      const res = await fetch("/employee/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pass: e.target.pass.value,
          name: e.target.name.value,
        }),
      })
      if (res.status === 401) return alert("Złe dane logowania")
      if (res.status === 403)
        return alert("Brak dostępu - skontaktuj się z administratorem")
      if (res.status !== 200) {
        const resJSON = await res.json()
        throw resJSON
      }

      window.location.reload()
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }
  return (
    <div style={{ marginTop: "100px", marginLeft: "40%" }}>
      <form onSubmit={handleSubmit} autoComplete="on">
        <label htmlFor="name">Nazwa użytkownika</label>
        <br />
        <input type="text" autoFocus id="name" autoComplete="on"></input>
        <br />
        <label htmlFor="pass">Hasło</label>
        <br />
        <input type="password" id="pass" autoComplete="on"></input>
        <br />
        <input type="submit" id="submit-btn"></input>
      </form>
    </div>
  )
}
export default Login
