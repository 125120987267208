import React, { useContext } from "react"
import { Container, Table, Button } from "react-bootstrap"

import UserContext from "../comp/contexts/userContext"
import MetaContext from "../comp/contexts/metaContext"
import { createJiraBtn } from "./utils"
import { createManualBtn } from "../comp/utils/buttons"

import existingNIP from "./pics/changelog/existingNIP.png"
import showInvLineSource from "./pics/changelog/showInvLineSource.png"
import settlingCheckboxArea from "./pics/changelog/settlingCheckboxArea.png"
import srvTable_v1120 from "./pics/changelog/1-12-0-srvTable.png"
import shortTermNumber from "./pics/changelog/shortTermNumber.png"

const ChangeLog = () => {
  const user = useContext(UserContext)
  const meta = useContext(MetaContext)

  const handleSubmit = async () => {
    try {
      const res = await fetch("/employees/newVersion", {
        headers: { "content-type": "application/json" },
        method: "PUT",
        body: JSON.stringify({ version: meta.version, _id: user.id }),
      })
      if (res.status !== 200) throw res
      else {
        localStorage.removeItem("versionAlertDissmiss")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }
  return (
    <Container fluid className="mt-2" id="changelog">
      <p>
        <b>
          Kliknięcie "Zapoznałem się" jest równoznaczne z odręcznym podpisem!
        </b>
      </p>
      <p>
        Ewentualne zmiany w instrukcji oznaczone są{" "}
        <span className="bg-secondarySuperLight">kolorowym tłem</span>
        <br />
        Przycisk {createManualBtn("")} oznacza, że zapoznanie się z odpowiednim
        fragmentem instrukcji jest obowiązkowe
        <br />
        Widoczność niektórych elementów na poniższej liście zależy od uprawnień
        (podobnie jak w instrukcji).
      </p>
      <div
        className="fixed-top mt-6 pt-1 ml-auto bg-warning"
        style={{ width: "15%", borderRadius: "30px", textAlign: "center" }}
      >
        <p>
          Twoja ostatnia wersja:
          <br />
          <b>{user.appVersion}</b>
        </p>
      </div>
      <Table className="mt-3">
        <thead>
          <tr>
            <th>Wersja i data</th>
            <th>
              Opis zmian
              {user.appVersion !== meta.version ? (
                <Button
                  variant="secondary"
                  className="float-right"
                  onClick={handleSubmit}
                  id="acknowledgeBtn"
                >
                  Zapoznałem się
                </Button>
              ) : null}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              1.15.3
              <br />
              2022-11-06
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>Lista lokalizacji: dodane wyszukiwanie wg miasta</li>
              </ul>

              {user.perm.logistics.r ? (
                <>
                  <h3>Odhaczanie</h3>
                  <ul>
                    <li>
                      Poprawiony błąd powodujący usunięcie zaplanowanego
                      zabrania przy odhaczaniu wcześniejszego, częściowego
                      zabrania.
                    </li>
                  </ul>
                  <h3>Raporty</h3>
                  <ul>
                    <li>Dodany raport "Dostarczenia i zabrania"</li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.15.2
              <br />
              2022-10-23
            </td>
            <td>
              <h3>Ogólne</h3>
              <p>Poprawki techniczne</p>
              {user.perm.locations.r ? (
                <>
                  <h3>Raporty</h3>
                  <ul>
                    <li>Dodany raport "Odległość do lokalizacji"</li>
                  </ul>
                </>
              ) : null}
              {user.perm.invoicing.r ? (
                <>
                  <h3>Fakturowanie</h3>
                  <ul>
                    <li>
                      Poprawki w "Fakturowanie/Statystyki": umożliwienie wyboru
                      jednego dnia i wyłączenie faktur testowych z listy.
                    </li>
                    <li>
                      Dodany wiersz podsumowania w edycji i wystawianiu faktury.
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.15.1
              <br />
              2022-10-16
            </td>
            <td>
              <h3>Lokalizacja</h3>
              <ul>
                <li>
                  Naprawiony "MEZ" (ale tymczasowo musiałem w tym modalu
                  wyłaczyć przeciąganie)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.15.0
              <br />
              2022-10-13
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Dodana możliwość przesuwania modali i przewijania treści pod
                  nimi {createManualBtn("modals")}{" "}
                </li>
              </ul>

              <h3>Szczegóły klienta</h3>
              <ul>
                <li>
                  Przy <u>tworzeniu nowej lokalizacji</u> - od teraz można
                  wprowadzać wielowierszowe notatki korzystając z klawisza enter
                  (wcześniej powodowało to zapisanie lokalizacji).
                </li>
                <li>Poprawione działanie wyszukiwarki faktur klienta</li>
              </ul>

              {user.perm.logistics.r ? (
                <>
                  <h3>Logistyka</h3>
                  <ul>
                    <li>
                      Planowanie tras: dodana możliwość drukowania i
                      eksportowania zadań krótkoterminowych do excela
                    </li>
                    <li>
                      Planowanie tras: dodana możliwość edycji zadań z poziomu
                      tabeli krótkoterminowej
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.invoicing.r ? (
                <>
                  <h3>Fakturowanie</h3>
                  <ul>
                    <li>
                      Dodany komponent "Fakturowanie {"->"} Statystyki" z
                      informacją o liczbie wygenerowanych faktur i fakturach z
                      zerowymi pozycjami
                    </li>
                    <li>
                      Poprawione określanie daty "fakturuj od" przy generowaniu
                      faktury z lokalizacji
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.incomeReport.r ? (
                <>
                  <h3>Raport "statystyki oddziałów"</h3>
                  <ul>
                    <li>
                      Poprawione działanie komponentu - powinno być szybciej i
                      bez zawieszania przy dużej ilości danych (Warszawa).{" "}
                      <br />
                    </li>

                    <li>
                      Dodany przycisk odświeżania danych dla starszych miesięcy
                      w ww. raporcie. Szczegóły w instrukcji{" "}
                      {createManualBtn("branchesStats")}
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.14.3
              <br />
              2022-08-16
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Dodawanie klienta: dodana możliwość pobierania danych na
                  podstawie NIP
                </li>
              </ul>
              {user.perm.transaction.w ? (
                <>
                  <h3>Sprzedaż ("transakcje")</h3>
                  <ul>
                    <li>
                      Naprawione pobieranie danych klienta na podstawie NIP
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.sewageReport.r ? (
                <>
                  <h3>Zezwolenia i sprawozdania kwartalne</h3>
                  <ul>
                    <li>
                      Zmiany w zasadach działania zezwoleń - szczegóły w
                      instrukcji{" "}
                      {createManualBtn("sewagePermissionsAndReports")}
                    </li>
                    <li>Dodane przypomnienia @ dla zezwoleń</li>
                    <li>Dodana wyszukiwarka tekstowa do listy zezwoleń</li>
                    <li>Dodane tworzenie historii edycji zezwolenia</li>
                  </ul>
                </>
              ) : null}
              {user.perm.invoicing.r ? (
                <>
                  <h3>Fakturowanie</h3>
                  <ul>
                    <li>
                      Dodana możliwość zaznaczania lokalizacji do zafakturowaniu
                      z użyciem klawisza SHIFT
                    </li>
                    <li>
                      Przycisk "fakturuj zaznaczone" będzie widoczny również
                      przy przewijaniu listy
                    </li>
                    <li>
                      Checkbox łączenia lokalizacji jednego klienta na jedną
                      fakturę od teraz jest domyślnie zaznaczony
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.debtCollection?.w ? (
                <>
                  <h3>Windykacja</h3>
                  <ul>
                    <li>
                      Naprawione obliczanie terminu płatności przy kilku
                      fakturach
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.14.2
              <br />
              2022-07-21
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>Poprawki techniczne</li>
              </ul>
              {user.perm.sewageReport.r ? (
                <>
                  <h3>Sprawozdania kwartalne</h3>
                  <ul>
                    <li>
                      Naprawiony błąd powodujący ujemne wartości w Warszawie
                    </li>
                    <li>
                      Naprawiony błąd powodujący podwójne generowanie sprawozdań
                      dla części gmin
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.invoicing.r ? (
                <>
                  <h3>Fakturowanie</h3>
                  <ul>
                    <li>
                      Poprawione odświeżanie listy lokalizacji po wygenerowaniu
                      faktury - zaznaczone lokalizacje nie powinny się już
                      pojawiać.
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.logistics.e ? (
                <>
                  <h3>Logistyka</h3>
                  <ul>
                    <li>
                      Planowanie: dodana możliwość ustalania kolejności zadań{" "}
                      {createManualBtn("changeOrder")}
                    </li>
                    <li>
                      Planowanie: w filtrach tabeli będą się wyświetlać tylko
                      kierowcy, którzy mają przypisane jakiekolwiek zadanie
                    </li>

                    <li>
                      Odhaczanie: przy odhaczaniu zabrania dodane sprawdzanie,
                      czy wcześniej odhaczono dostarczenie, szczegóły w
                      instrukcji. {createManualBtn("jobsSettling")}
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.debtCollection?.w ? (
                <>
                  <h3>Windykacja</h3>
                  <ul>
                    <li>
                      Dodane nowe filtry do wykorzystania{" "}
                      <u>po wgraniu pliku</u>{" "}
                      {createManualBtn("debtCollectionXlsxFilters")}
                    </li>
                    <li>
                      Poprawione działanie wyszukiwarki po wgraniu pliku (teraz
                      sprawdza też nazwę skróconą i nazwisko klienta)
                    </li>
                    <li>
                      W tabeli windykacyjnej dodane kolorowanie wierszy zależnie
                      od kategorii klienta
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.14.1
              <br />
              2022-06-21
            </td>
            <td>
              <p>Poprawki techniczne</p>
              {user.perm.debtCollection?.w ? (
                <>
                  <h3>Windykacja</h3>
                  <ul>
                    <li>
                      Dodana możliwość wczytywania arkusza Excel z Symfonii{" "}
                      {createManualBtn("debtColl-excelFile")}
                    </li>
                    <li>
                      Dodana możliwość sortowania (od najmniejszych do
                      największych i vice versa) po kliknięciu w nagłówek tabeli
                    </li>
                    <li>
                      Dodana tzw. "paginacja" - wyświetlanie maks. 25 klientów w
                      tabeli (jeśli jest więcej wyników pojawiają się przyciski
                      do wyboru strony)
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.14.0
              <br />
              2022-06-12
            </td>
            <td>
              <h3>Lista klientów</h3>
              <p>
                Dodany przycisk <Button>@</Button> dla przyspieszenia wysyłki
                faktur, szczegóły w instrukcji{" "}
                {createManualBtn("customersListInvMailBtn")}{" "}
              </p>
              <h3>Klient - szczegóły</h3>
              <ul>
                <li>
                  Dodana możliwość wgrywania plików - przycisk w karcie
                  "Dodatkowe".
                </li>
                <li>Dodana "kategoria windykacyjna"</li>
                <li>
                  Dodana możliwość wstrzymywania seriwsów ("Dodatkowe {"->"}{" "}
                  Edytuj") {createManualBtn("jobsSuspended")}{" "}
                </li>
              </ul>
              <h3>Windykacja</h3>
              <p>
                Dodana duża liczba funkcjonalności związanych z windykacją, poza
                poniższym proszę o zapoznanie się również z instrukcją:{" "}
                {createManualBtn("debtCollection")}
              </p>
              <ul>
                <li>Zawieszanie zadań</li>
                <li>
                  Kategorie windykacyjne klienta ("zielony"/"zwykły"/"czerwony")
                </li>
                <li>Powiadomienia @ o zawieszeniu i zmianie kategorii </li>
                <li>
                  Podtypy dla notatek windykacyjnych ("telefon"/"mail"/"sms"
                  itd.)
                </li>
              </ul>

              {user.perm.logistics.e ? (
                <>
                  <h3>Planowanie</h3>
                  <ul>
                    <li>
                      Dodany alert o wstrzymaniu zadań (windykacja:{" "}
                      {createManualBtn("jobsSuspended")}) (
                      <b>również na kontrolkach</b>)
                    </li>
                  </ul>
                  <h3>Odhaczanie</h3>
                  <ul>
                    <li>
                      Dodatkowe informacje przy zamykaniu usługi: data
                      rozpoczęcia i lista sprzętu (po najechaniu na okrągłą
                      ikonę)
                    </li>
                    <li>
                      Poprawione działanie zamykania usług: w niektórych
                      przypadkach usługi wyświetlały się podwójnie{" "}
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.incomeReport.r ? (
                <>
                  <h3>"Statystyki dzienne"</h3>
                  <p>
                    Dodana możliwość podglądania źródła liczby toalet (po
                    kliknięciu na oddział). <b>Funkcja stricte techniczna</b> do
                    wyjaśniania niezgodności. Do dopracowania jeśli okaże się
                    przydatna na co dzień (<u>proszę o info</u>).
                  </p>
                </>
              ) : null}
              {user.perm.carsManage.r ? (
                <>
                  <h3>Samochody</h3>
                  <ul>
                    <li>Nowy formularz danych samochodu</li>
                    <li>
                      Dodana możliwość wgrywania plików (szczegóły samochodu{" "}
                      {"->"} "Dokumenty" {"->"} "Dodaj" ).
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.sewageReport.r ? (
                <>
                  <h3>Sprawozdania kwartalne</h3>
                  <ul>
                    <li>Naprawione pokazywanie listy sprawozdań</li>
                    <li>Naprawione generowanie sprawozdań zerowych</li>
                  </ul>
                </>
              ) : null}
              <h3>Szczegóły lokalizacji</h3>
              <ul>
                <li>
                  Naprawiony błąd w numerowaniu usług na lokalizacji - numer
                  zwiększał się przy każdej edycji szczegółów usługi.
                </li>
                <li>
                  Dodana możliwość podglądania właściwości usługi (np. czy jest
                  sprzętowa) z poziomu lokalizacji ("Szczegóły" {"->"} przycisk
                  "Właściwości usługi"){" "}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.13.3
              <br />
              2022-04-10
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                Dodane narzędzie do zbierania informacji o błędach. Działa w tle
                i bez Waszej ingerencji, ale potrzebuje komunikować się z
                osobnym serwerem, dlatego{" "}
                <u>
                  jeśli korzystacie z wtyczek typu AdBlock, no-script, Duck Duck
                  Go itd, albo wbudowanych w przeglądarkę blokerów to proszę o
                  dodanie cliperp.app do listy wyjątków.
                </u>
              </ul>

              <h3>Lokalizacja</h3>
              <ul>
                <li>
                  Naprawiony błąd powodujący dodawanie błędnych liczb sprzętu
                  przy edycji dostarczenia
                </li>

                <li>
                  Dodana "Kategoria przychodu" przy dodawaniu i edycji usługi.
                  Poza osobno ustalonymi przypadkami{" "}
                  <b>należy zostawić domyślną</b> {createManualBtn("incomeCat")}
                </li>
                <li>
                  Poprawione działanie pola "Zezwolenie/gmina" przy edycji
                  lokalizacji (od teraz powinno się pokazywać wcześniej wybrane
                  zezwolenie){" "}
                </li>
              </ul>
              <h3>Fakturowanie</h3>
              <ul>
                <li>
                  Lista lokalizacji: zaznaczone lokalizacje będą od teraz
                  zapamiętywane nawet przy ponownym wciśnięciu "Szukaj". <br />
                  (czyli mogę przykładowo wyszukać klienta ABC, zaznaczyć do
                  fakturowania, potem wyszukać CDE {"->"} na liście wyników
                  nadal będzie zaznaczony ABC, więc mogę zafakturować obu)
                  <br />
                  <b>
                    Wyjątkiem jest zmiana daty "Niezafakturowane na dzień".
                  </b>{" "}
                  W przypadku tej zmiany lista lokalizacji jest czyszczona i
                  wymagane jest ponowne pobranie.
                </li>
                <li>
                  Dodana wysyłka maila w przypadku automatycznego wygenerowania
                  faktury przy zmianie ceny usługi
                </li>
                <li>
                  Lista lokalizacji: wyłączone pokazywanie anulowanych usług
                </li>
                <li>
                  Naprawiony błąd powodujący podwójne zafakturowanie zadania
                  przy usługach "za serwis", jeśli zadanie było "na skraju"
                  (tzn. zadanie 15.04, wystawiona faktura za okres 01-15 i potem
                  kolejna pod koniec miesiąca za okres 01-31)
                </li>
              </ul>
              <h3>Szczegóły klienta</h3>
              <ul>
                <li>Dodana możliwość archiwizowania notatek klienta</li>
              </ul>
              <h3>Logistyka</h3>
              <ul>
                <li>
                  Odhaczanie: dodana możliwość wyboru usługi do zamknięcia przy
                  odhaczaniu zabrania
                </li>
                <li>
                  Odhaczanie: dodana możliwość zamykania usług bezsprzętowych i
                  takich z zaplanowaną datą zamknięcia
                </li>{" "}
                <li>
                  Odhaczanie: dodane wysyłanie powiadomienia @ do osoby
                  odpowiedzialnej za fakturowanie, jeśli przy odhaczaniu
                  zamknięto usługę.{" "}
                  <u>
                    Odhaczanie zadań z zamknięciem usługi może przez to trwać
                    kilka sekund dłużej
                  </u>
                </li>
                <li>
                  planowanie: dodany przycisk "Zamień kierowców" (Planowanie{" "}
                  {"->"} Podsumowanie, zastąpił przycisk "Przenieś zadania")
                  służący do wymiany zadań między dwoma kierowcami (zamiast
                  przepisywania zadań jednego kierowcy do drugiego)
                </li>
                <li>
                  Raporty {"->"} Zadania - wydruk: poprawione działanie raportu,
                  żeby uwzgledniał skrajne daty
                </li>
                <li>
                  Planowanie {"->"} Krótki termin: dodane sortowanie zadań wg
                  godziny
                </li>
                <li>
                  Planowanie {"->"} Krótki termin: dodana tabeli podsumowania
                  sprzętu w karcie "Krótki termin"
                </li>
                <li>
                  Techniczna zmiana w sposobie drukowania informacji o sprzęcie
                  na kontrolkach. Wszystko powinno być ok, ale{" "}
                  <b>przez pierwsze dni</b> proszę o zwrócenie uwagi na wydruki.
                </li>
              </ul>

              {user.perm.incomeReport?.w ? (
                <>
                  <h3>Raporty</h3>
                  <ul>
                    <li>
                      Statystyki oddziału - zmieniony sposób liczenia serwisów,
                      szczegóły w raporcie pod przyciskiem z "?"
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.13.2
              <br />
              2022-04-01
            </td>
            <td>
              <h3>Lokalizacja</h3>
              <ul>
                <li>Dodany filtr tekstowy przy wyborze usługi</li>
                <li>
                  Lista lokalizacji: dodane wyszukiwanie wg dat (po zaznaczeniu
                  "Tylko imprezy")
                </li>
              </ul>

              {user.perm.incomeReport.w ? (
                <>
                  <h3>Raporty</h3>
                  <ul>
                    <li>
                      Raport przychodu: dodana możliwość podejrzenia szczegółów
                      pozycji raportu
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.servicesManage.w ? (
                <>
                  <h3>Zarządzanie usługami</h3>
                  <p>
                    <i>(tylko dla osób z odpowiednimi uprawnieniami)</i>
                  </p>
                  <ul>
                    <li>Dodane filtry w tabeli zarządzania usługami</li>
                    <li>
                      Dodane pole "priorytet", szczegóły w instrukcji:{" "}
                      {createManualBtn("srvPriority")}
                    </li>
                    <li>
                      Dodany przycisk "Szukaj lokalizacji" w podglądzie usługi.
                      Powoduje otworzenie nowej karty z listą lokalizacji, na
                      których jest dodana usługa
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.13.1
              <br />
              2022-03-28
            </td>
            <td>
              <h3>Logistyka/planowanie:</h3>
              <ul>
                <li>
                  dodana karta "Krótki termin" (mocno robocza i w trakcie
                  budowy, uwagi można zgłaszać w {createJiraBtn(991)})
                </li>
              </ul>
              <h3>Lista lokalizacji:</h3>
              <ul>
                <li>wyłączone wyświetlanie zamkniętych i anulowanych usług</li>
              </ul>
              <h3>Dodawane i edycja zadań:</h3>
              <ul>
                <li>usunięte wyświetlanie nieaktywnych kierowców</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.13.0
              <br />
              2022-03-23
            </td>
            <td>
              <h3>Lokalizacja:</h3>
              <ul>
                <li>Przyspieszone wczytywanie podglądu lokalizacji</li>
                <li>
                  Zmiana formularza wyboru sprzętu przy dodawaniu dostarczenia.
                </li>
              </ul>
              <h3>Logistyka</h3>
              <ul>
                <li>Dodane drukowanie kontrolek imprezowych</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.12.1
              <br />
              2022-03-17
            </td>

            <td>
              <h3>Lokalizacje:</h3>
              <ul>
                <li>
                  Dodana możliwość podejrzenia faktury, jaka zostanie
                  wygenerowana (przycisk "Symulacja faktury" w tabeli usług)
                </li>
                <li>
                  Drobna zmiana układu w karcie "Ogólne" dla poprawienia
                  czytelności
                </li>
              </ul>
              <h3>Logistyka:</h3>
              <ul>
                <li>
                  Poprawiony błąd przy odhaczaniu zabrania z zamknięciem usługi
                </li>
                <li>
                  Poprawione drukowanie informacji dot. sprzętu na kontrolce
                </li>
                <li>
                  Dodane drukowanie informacji o usługach podrzędnych (zmiana
                  "na szybko" głównie na potrzeby usługi "Dezynfekcja",
                  szczegóły w mailu)
                </li>
              </ul>

              <h3>Usługi podrzędne</h3>
              <p>
                <i>
                  Komponent w trakcie testów, szczegóły w zmianach dla 1.12.0
                </i>
              </p>
              <ul>
                <li>
                  Dodane aktualizowanie usług podrzędnych w zaplanowanych
                  zadaniach
                </li>

                <li>
                  Naprawiony błąd powodujący usunięcie usług podrzędnych przy
                  edycji usługi głównej (np. ceny albo opisu)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.12.0
              <br />
              2022-03-10
            </td>
            <td>
              <h2>Ważna zmiana dot. usług</h2>
              <p>
                Od tej wersji zmieniłem sposób rozliczania usług "za serwis". W
                związku z tym wymagane będzie wprowadzenie ręcznych poprawek na
                części lokalizacji. Wszystko jest opisane w instrukcji{" "}
                {createManualBtn("perPcsSrv")} (lektura obowiązkowa).
                <br />W razie pytań kierownicy mieli dodatkowe szkolenie z tego
                zakresu, oni też przekażą Wam szczegóły organizacyjne.
              </p>
              <p>
                Ta zmiana rozwiązuje problem opisany w moim mailu z dn.
                2022-02-22 a propos częściowych serwisów (kombinowanie z
                dodawaniem osobnych parametrów). Po naniesieniu ww. zmian w
                przypadku serwisu częściowego <u>nie trzeba będzie</u> nic
                zmieniać w usłudze, wystarczy ustawić odpowiednią liczbę sprzętu
                dla zadania (przy planowaniu lub odhaczaniu).
              </p>

              <h3>
                Usługi podrzędne (imprezy, dezynfekcja, łączenie usług na
                fakturze) - testy
              </h3>
              <p>
                Udostępniam wstępną wersję obsługi usług podrzędnych. Będą
                wykorzystywane przy:
              </p>

              <ul>
                <li>
                  imprezach (usługa imprezowa z usługami podrzędnymi typu serwis
                  toalet, sprzątanie, wynajem ogrodzeń itd.),
                </li>
                <li>
                  dla dezynfekcji i analogicznych usług (wynajem i serwis toalet
                  z usługą podrzędną "dezynfekcja", zamiast dwóch osobnych
                  usług),{" "}
                </li>
                <li>
                  oraz jeśli klient życzy sobie jedną pozycję na fakturze dla
                  kilku usług.
                </li>
              </ul>
              <p className="bg-warning">
                To ciągle jest na etapie testów,{" "}
                <b>
                  nie wolno wykorzystywać usług podrzędnych dla prawdziwych
                  lokalizacji.
                </b>
                <br />
                Osoby chętne do przetestowania tej funkcjonalności proszę o
                kontakt. Oczywiście zachęcam - im lepiej to przetestujemy tym
                mniej problemów będzie po wdrożeniu.
              </p>
              <h3>Reszta zmian:</h3>
              <h4>Klient szczegóły</h4>
              <ul>
                <li>Dodana wyszukiwarka w karcie lokalizacje</li>
                <li>Lokalizacje archiwalne domyślnie ukryte</li>
              </ul>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  (instrukcja) Poprawiony opis parametrów usługi w instrukcji
                  (+grafiki) {createManualBtn("params")} (nie wyróżniłem
                  kolorowym tłem, bo nie ma nowych informacji, tylko inaczej
                  podane)
                </li>
                <li>
                  (instrukcja) Dodana informacja o błędach w parametrach{" "}
                  {createManualBtn("paramsBugs")}
                </li>
                <li>
                  Ukrycie parametrów usługi, od teraz wyświetla się podsumowanie
                  (daty od-do dla całej usługi, liczba{" "}
                  <u>na dzień dzisiejszy</u> i planowane jej zmiany, data
                  zafakturowania).
                  <br />
                  Parametry można wyświetlić zaznaczając odpowiedni checkbox.
                  <br />
                  <img
                    src={srvTable_v1120}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </li>
                <li>
                  dodanie nowego sposobu zmiany liczby dla usługi
                  {createManualBtn("srvQtyChange")}
                </li>
                <li>
                  Dodana możliwość przypisania do lokalizacji numeru ze starego
                  programu (karta szczegóły {"->"} przycisk "Stary numer")
                </li>
                <li>
                  Dodana numeracja dla usług krótkoterminowych ("krótki termin"
                  i "ryczałt impreza")
                  <br />
                  <img
                    src={shortTermNumber}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </li>
                <li>
                  Tabela wyboru zadania (jeśli więcej niż dwa jednego dnia):
                  zmiana kolumny "godziny" na "usługi"{" "}
                </li>
              </ul>
              <h4>Lista lokalizacji</h4>
              <ul>
                <li>Dodane wyszukiwanie po numerze lokalizacji</li>
                <li>
                  Dodany opis działania wyszukiwarki w instrukcji (odnośnik w
                  pkt poniżej)
                </li>
                <li>
                  Dodany przycisk {createManualBtn("locationsSearch")} w
                  wyszukiwarce lokalizacji
                </li>
              </ul>
              <h4>Fakturowanie</h4>
              <ul>
                <li>
                  Poprawiony komunikat po wystawieniu faktury (np. o braku zadań
                  do zafakturowania) - dodany podział na klientów
                </li>
                <li>
                  Dodany komunikat po zafakturowaniu o konieczności poprawienia
                  liczby w usługach "za serwis".{" "}
                  <b>
                    Od teraz każda usługa "za serwis" powinna mieć liczbę = 1
                  </b>{" "}
                  (szczegóły powyżej i w instrukcji).{" "}
                  <u>
                    W przypadku wystąpienia tego komunikatu należy edytować
                    odpowiednie lokalizacje
                  </u>{" "}
                  (po wystawieniu faktur)
                </li>
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>
                  Odhaczanie: poprawiony błąd powodujący nie zdejmowanie sprzętu
                  z lokalizacji przy odhaczaniu jeśli tego samego dnia było
                  dostarczenie.
                </li>
                <li>Planowanie: dodany komunikat o zadaniach imprezowych</li>
                <li>
                  Odhaczanie: od teraz filtry (kierowca, typ zadania) działają
                  też na dolną tabelę
                </li>
                <li>Odhaczanie: dodane wyświetlanie liczby widocznych zadań</li>
              </ul>
              <h4>Samochody:</h4>
              <ul>
                <li>
                  Dodane wysyłanie powiadomień @ o zbliżającym się terminie
                  przeglądu/ubezpieczenia
                </li>
                <li>
                  Dodany stan samochodu - sprzedane nie powinny pojawiać się w
                  powiadomieniach i na listach wyboru
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.11.6
              <br />
              2022-02-28
            </td>
            <td>
              <h4>Lista lokalizacji</h4>
              <p>Kalibracja wyszukiwarki</p>
            </td>
          </tr>
          <tr>
            <td>
              1.11.5
              <br />
              2022-02-28
            </td>
            <td>
              <h4>Lista lokalizacji</h4>
              <p>
                Poprawione działanie wyszukiwarki, dodane wyszukiwanie wg numeru
                NIP klienta
              </p>
            </td>
          </tr>
          <tr>
            <td>
              1.11.4
              <br />
              2022-02-21
            </td>
            <td>
              <h4>Ogólne</h4>
              <ul>
                <li>
                  Poprawione/uporządkowane wyświetlanie usług i sprzętu w
                  podglądzie zadania
                </li>
                <li>
                  Notatki i kontakty na lokalizacji i u klienta: dodane
                  sortowanie od najnowszych
                </li>
              </ul>
              <h4>Usługi podrzędne</h4>
              <ul>
                <li>
                  naprawione działanie programu przy dodaniu zadania z
                  niezaznaczonymi usługami podrzędnymi
                </li>
                <li>
                  dodana możliwość utworzenia zadania tylko z usługami
                  podrzędnymi (bez sprzętu)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.11.3
              <br />
              2022-02-20
            </td>
            <td>
              <p>
                Poprawiony komunikat błędu w przypadku fakturowania lokalizacji
                z nieprawidłowo dodaną usługą.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              1.11.2
              <br />
              2022-02-17
            </td>

            <td>
              <h3>Lokalizacja</h3>
              <p>
                Poza poniższymi zmianami wprowadziłem rozwiązanie umożliwiające
                łączenie usług ("usługi podrzędne").{" "}
                <b>
                  To jest na razie w fazie testów,{" "}
                  <u>proszę nie korzystać na prawdziwych lokalizacjach</u>
                </b>
                . Przy następnej aktualizacji (1.12.0) ta funkcjonalność będzie
                już przetestowana i gotowa do wykorzystania.
              </p>
              <ul>
                <li>
                  uproszczona zmiana ceny usługi, od teraz wystarczy kliknąć
                  "Edycja" {"->"} "cena" przy usłudze i wprowadzić odpowiednie
                  dane - powinno być bardziej intuicyjnie.
                </li>
                <li>
                  lista lokalizacji: przyspieszenie działania (po otwarciu nie
                  trzeba czekać na pobranie wszystkich lokalizacji)
                </li>
                <li>
                  Dodana możliwość zmiany typu zadania (serwis {"<->"} zabranie)
                  w edycji zadania (po kliknięciu w kalendarzu/tabeli)
                </li>
                <li>
                  Dodana możliwość wyszukiwania tylko lokalizacji, na których są
                  usługi krótkoterminowe
                </li>
              </ul>

              <h3>Logistyka</h3>
              <ul>
                <li>
                  Odhaczanie: poprawione działanie nowego modala do zmiany typu
                  zadania (serwis {"<->"} zabranie), żeby odpinał usługi
                  bezsprzętowe przy zmianie na zabranie {createJiraBtn(850)}
                </li>
                <li>
                  Kontrolka: dodanie info o kierowcy, który ostatnio był na
                  lokalizacji {createManualBtn("jobCardCashParagraph")}{" "}
                  (paragraf zaczynający się od "Ostatnio")
                </li>
                <li>Kontrolka: zmiany w układzie wydruku</li>
                <li>
                  poprawione działanie funkcji "dodaj uwagi do następnego
                  zadania"
                </li>
                <li>Planowanie: możliwość zmiany typu w edycji zadania</li>
              </ul>
              <h3>Klienci</h3>
              <ul>
                <li>
                  dodana obsługa kont bankowych klienta (karta "Dodatkowe") i{" "}
                  <u>wyszukiwania</u> wg numeru lub właściciela konta
                </li>
              </ul>

              {user.perm.servicesManage.w ? (
                <>
                  <h3>Zarządzanie usługami</h3>
                  <p>
                    <i>(tylko dla osób z odpowiednimi uprawnieniami)</i>
                    <br />
                    Zgodnie z obietnicą poprawiłem trochę wygląd szczegółów
                    usługi i edycję. Szału może nie ma, ale to nadal moduł
                    techniczny...
                    <br /> Zabezpieczyłem też dodawanie usługi bez domyślnego
                    opisu.
                  </p>
                </>
              ) : null}
              {user.perm.eqpManage.w ? (
                <>
                  <h3>Zarządzanie sprzętem</h3>
                  <p>
                    <i>(tylko dla osób z odpowiednimi uprawnieniami)</i>
                    <br />
                    Zmiana w formularzu dodawania/edycji sprzętu: zablokowana
                    możliwość dodawania templatek innych niż toalety i
                    prysznice.
                    <br />
                    Poprawiłem też listę sprzętu, nie trzebać klikać "pobierz",
                    żeby pojawił się przycisk "dodaj".
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.11.1
              <br />
              2022-02-09
            </td>
            <td>
              <h3>Lokalizacje</h3>
              <p>
                Lokalizacje od teraz będą dostawały swój numer, który będzie
                drukował się na kontrolce i potwierdzeniu dostarczenia{" "}
                {createManualBtn("locNumbering")} (info bezpośrednio pod tytułem
                "Lokalizacja").
              </p>
              <h3>Logistyka (planowanie, odhaczanie, kontrolki)</h3>
              <ul>
                <li>
                  dodana informacja o częstotliwości serwisów na lokalizacji (na
                  tabelach planowania, odhaczania, w podglądach szczegółów i na
                  kontrolce) {createManualBtn("logisticJobsFreq")}
                </li>
                <li>
                  dodane drukowanie numeru lokalizacji na kontrolce i
                  potwierdzeniu dostarczenia
                </li>
              </ul>
              {user.perm.incomeReport.r ? (
                <>
                  <h3>Statystyki oddziału</h3>
                  <p>Naprawione liczenie przychodu</p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.11.0
              <br />
              2022-02-06
            </td>
            <td>
              <h3>Błędy</h3>
              <p>
                Naprawiony błąd, przez który przy zamykaniu usługi nie były
                edytowane zadania. <br />
                <u>
                  Czyli mimo zamknięcia usługi zadania nadal są zaplanowane.
                </u>
                <br /> Jeśli na jakiejś lokalizacji występują błędne zadania
                (tzn. z podpiętą usługą, która jest zamknięta) to{" "}
                <b>należy to poprawić przy użyciu MEZ</b> (dotyczy tylko usług
                bez naszego sprzętu, np. "Serwis TK", szambo itd)
              </p>
              <h3>Ogólne</h3>
              <p>
                Dodany przycisk {createManualBtn("manualBtnParagraph")} -
                wciśnięcie powoduje otwarcie w <b>nowej karcie</b> instrukcji
                ustawionej na odpowiedniej treści.
                <br />
                Przycisk będzie się pojawiał na liście zmian (i oznacza{" "}
                <u>obowiązek</u> zapoznania się z odpowiednim fragmentem) oraz w
                różnych miejscach programu, które mogą wymagać dodatkowych
                wyjaśnień.
              </p>
              <h3>Fakturowanie</h3>
              <p>
                Umożliwienie fakturowania zaplanowanych i zleconych zadań (dla
                usług naliczanych "za serwis").
              </p>
              <h3>Lokalizacja</h3>
              <ul>
                <li>
                  Dodana obsługa rejestracji wstępnej
                  {createManualBtn("initRegHeader")}
                </li>
                <li>
                  Dla usług z naliczeniem "za serwis" (np. toalety klienta):
                  zablokowana możliwość dodawania zadań wcześniejszych niż data
                  "zafakturowano do" (czyli nie można dodać serwisu w okresie,
                  za który już była wystawiona faktura)
                </li>
              </ul>
              <h5>Kalendarz</h5>
              <p>
                Ogólne poprawki graficzne: dodany skrót nazwy miesiąca, różne
                kolory tła dla kolejnych miesięcy (rok inny niż bieżący
                wyróżniony na czerwono)
              </p>
              <h3>Logistyka - planowanie i kontrolka</h3>
              <ul>
                <li>
                  Jeśli w zadaniach na dany dzień jest jakiekolwiek z usługą
                  inną niż "toaletowa", to zostanie wyświetlony komunikat
                </li>
                <li>
                  Kontrolki klienta: zmiana na "jedna lokalizacja - jedna
                  kontrolka" (nawet jeśli tego samego klienta i robiona przez
                  tego samego kierowcę)
                </li>
                <li>
                  Przypisywanie samochodów:{" "}
                  <ul>
                    <li>
                      wycięte samochody/pojazdy "inne" (zostały ciężarowe i
                      osobowe)
                    </li>
                    <li>
                      na liście będą tylko samochody z odziałów użytkownika
                    </li>
                    <li>
                      ukryte samochody z oddziału "TEST" (można włączyć
                      checkboxem)
                    </li>
                    <li>
                      dodana możliwość ukrywania samochodów na liście (potrzebne
                      uprawnienie do edycji samochodów, które mają m.in. Mariusz
                      i Mirek)
                    </li>
                  </ul>
                </li>
                <li>
                  Zablokowane "przełamywanie" informacji na kontrolce. Jeśli coś
                  nie zmieści się w całości na jednej stronie to jest
                  przenoszone na kolejną
                </li>
                <li>
                  Dodany wydruk imienia i nazwiska kierowcy. Wymaga włączenia
                  wydruku nagłówka i stopki (czyli tego co jest potrzebne do
                  numeracji stron) oraz drukowania kontrolek osobno dla każdego
                  kierowcy.
                </li>
                <li>
                  Dodana kwota gotówki do pobrania.{" "}
                  <u>
                    Przez pierwsze tygodnie proszę o dokładną weryfikację, czy
                    kwota jest prawidłowa.
                  </u>{" "}
                  (dodałem info o sposobie obliczania do instrukcji - zapoznanie
                  się <b>nie</b>
                  obowiązkowe, chyba że wystąpią jakieś błędy{" "}
                  {createManualBtn("jobCardCashParagraph")})
                </li>
                <li>
                  Kontrolka: poprawiony błąd powodujący drukowanie
                  nieprawidłowej liczby sprzętu
                </li>
                <li>
                  Potwierdzenie dostarczenia: dodana informacja jeśli usługa
                  krótkoterminowa
                </li>
                <li>Inne, drobne zmiany graficzne na kontrolce</li>
              </ul>
              <h3>Logistyka - odhaczanie</h3>
              <ul>
                <li>
                  Zmiana nazwy lokalizacji na adres (ulica + miasto) w modalu
                  edycji
                </li>
                <li>
                  Po kliknięciu na zadania zamiast modala z odhaczaniem pojawia
                  się modal ze szczegółami, umożliwiający edycję zadania (
                  zmianę liczby sprzętu i <u>typu</u> z zabrania na serwis i
                  odwrotnie).
                </li>
                <li>
                  Nagłówek tabeli (z przyciskami "Wykonane" i "Edycja" pozostaną
                  widoczne przy przewijaniu
                </li>
                <li>
                  (z katgorii "drobne ułatwienia") do zaznaczenia/odznaczenia
                  checkboxa nie trzeba klikać bezpośrenio w niego, wystarczy
                  kliknąć w pole na którym się znajduje
                  <img
                    src={settlingCheckboxArea}
                    alt=""
                    style={{ maxWidth: "100%" }}
                  />
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.10.0
              <br />
              2022-02-01
            </td>
            <td>
              <h3>Dla wszystkich</h3>
              <p>
                W tej wersji przeprowadziłem dużą aktualizację techniczną.
                Ogólnie nie powinniście zauważyć żadnych zmian z tym związanych,
                jeśli wszystko poszło ok.
                <br />
                Ale mimo to bądźcie wyczuleni na dziwne działania programu i po
                zapisaniu/edycji weryfikujcie czy wszystko jest ok.
                <br />
                (Takie sytuacje będą się powtarzać co kilka-kilkanaście tygodni
                - podobnie jak co jakiś czas aktualizujecie telefony i
                komputery. Niby zazwyczaj wszystko jest ok, ale zawsze jest
                ryzyko, że coś się wysypie)
              </p>
              <h3>Lokalizacje - usługi ze sprzętem klienta</h3>
              <p>
                Usługa "Serwis TK" od teraz wymaga podpięcia sprzętu. Dzięki
                temu można łatwiej obsłużyć sytuacje typu "serwis tylko częśći
                toalet klienta". Szczegóły w instrukcji (wystarczy wyszukać
                frazę "Usługi związane ze sprzętem klienta").
              </p>
              <h3>Logistyka - planowanie</h3>
              <ul>
                <li>
                  Dodany checkbox "pokaż odhaczone" - po zaznaczeniu na mapie i
                  w tabeli widać wszystkie zadania (a nie tylko zaplanowane i
                  zlecone)
                </li>
                <li>
                  Dodany wydruk "kontrolki klienta" - szczegóły w instrukcji
                  ("Dodatkowe parametry" w części "Klienci")
                </li>
              </ul>
              {user.perm.servicesManage.w ? (
                <>
                  <h3>Zarządzanie usługami</h3>
                  <p>
                    <i>(tylko dla osób z odpowiednimi uprawnieniami)</i>
                    <br />
                    Dodany checkbox "sprzęt klienta" przy dodawaniu/edycji
                    usługi. Szczegóły do wyjaśnienia osobiście albo w instrukcji
                    - trzeba włączyć notatki i wyszukać (ctrl + f) "Zarządzanie
                    usługami", chodzi o paragraf poniżej.
                  </p>
                </>
              ) : null}
              {user.perm.eqpManage.w ? (
                <>
                  <h3>Zarządzanie sprzętem</h3>
                  <p>
                    <i>(tylko dla osób z odpowiednimi uprawnieniami)</i>
                    <br />
                    Dodany typ "Sprzęt klienta" - to taka specjalna templataka
                    do podpinania do usług ze sprzętem klienta. Może być tylko
                    jeden sprzęt każdego podtypu - na razie dodałem toaletę i
                    "inne", w razie potrzeb będę dodawał kolejne. W razie pytań
                    szczegóły wyjaśnię osobiście.
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.9.3
              <br />
              2022-01-23
            </td>
            <td>
              <h3>Kontrolka</h3>
              <ul>
                <li>Dodane drukowanie kontaktu "na kontrolkę"</li>
                <li>Powiększony napis "Dostarczenie" i "Zabranie"</li>
              </ul>
              <h3>Lokalizacje</h3>
              <ul>
                <li>
                  Zablokowana możliwość przywracania zarchiwizowanej lokalizacji
                </li>
                <li>
                  Dodana możliwość pokazywania tylko lokalizacji bez
                  przypisanego zezwolenia na liście lokalizacji
                </li>
              </ul>
              {user.perm.incomeReport.r ? (
                <>
                  <h3>Statystyki oddziału</h3>
                  <p>Naprawione liczenie średniej liczby toalet</p>
                </>
              ) : null}
              {user.perm.carsManage.r ? (
                <>
                  <h3>Zarządzanie samochodami</h3>
                  <p>
                    Dodane pole "rok produkcji" - w miarę możliwości proszę o
                    usupełnienie
                  </p>
                </>
              ) : null}
              <h3>Sprawozdania kwartalne</h3>
              <ul>
                <li>Nowy moduł do generowania sprawozdań</li>
                <li>Do zezwolenia dodany checkbox "wysyłać sprawozdania"</li>
                {user.type.tester ? (
                  <li>
                    (tylko dla osób z uprawnieniem testera) W instrukcji dodana
                    notatka wyjaśniająca sposób obliczania sprawozdań
                  </li>
                ) : null}
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.9.2
              <br />
              2022-01-12
            </td>
            <td>
              <p>
                Tymczasowo wyłączone sprawdzanie duplikatów NIPu przy
                rejestracji klienta
              </p>
            </td>
          </tr>
          <tr>
            <td>
              1.9.1
              <br />
              2022-01-09
            </td>
            <td>
              <p>Drobne poprawki</p>
              {user.perm.incomeReport.r ? (
                <>
                  <h4>Statystyki oddziałów</h4>
                  <p>
                    Dodany podział serwisów na długi termin, krótki termin i
                    toalety klienta (rozwijane strzałką w odpowiedniej rubryce).
                    <br />
                    Poprawione wyświetlanie i błędy powodujące nieprawidłowe
                    przydzielenie przychodu z nowych faktur (wystawionych po
                    1.9.0)
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.9.0
              <br />
              2022-01-02
            </td>

            <td>
              <h3>Dla wszystkich!</h3>
              <p>
                <i>również w nieprzeniesionych oddziałach</i>
                <br />
              </p>
              <ul>
                <li>
                  Częstotliwość logowania: od teraz do clipERPa trzeba logować
                  się codziennie
                </li>
                <li>
                  Automatyczne wylogowanie powinno następować w nocy (między
                  23:00 a 01:00) - nie powinno zdarzać się, że wyloguje Was w
                  ciągu dnia (np. podczas dodawania klienta/lokalizacji itp.).{" "}
                  <b>
                    Jeśli coś takiego się wydarzy {"->"}
                    <u>koniecznie</u> proszę o informację.
                  </b>
                </li>
              </ul>
              {user.perm.incomeReport?.r ? (
                <>
                  <h3>Raporty i statystyki</h3>
                  <h5>Przychód</h5>
                  <p>
                    <i>
                      Nie ma i w najbliższym czasie nie będzie instrukcji dot.
                      raportu przychodu, dlatego dokładniejszy opis:
                    </i>
                  </p>
                  <p>
                    Zmiana sposoby budowy raportu przychodu - do tej pory
                    program korzystał z nazwy zafakturowanej usługi ("Wynajem i
                    serwis toalet", "Wynajem ogrodzeń" itd, to co macie w tabeli
                    przy wybieraniu usługi dla lokalizacji). <br />
                    Od teraz usługi mają zdefiniowaną informację do której
                    kolumny ma trafić przychód. Info o krótkim lub długim
                    terminie (KT/DT) ustalana jest na podstawie{" "}
                    <b>sposobu naliczenia</b>.<br />W przyszłości umożliwi to
                    dodawanie usług łączonych (np. "usługa imprezowa"
                    zawierająca toalety, ogrodzenia i sprzątanie) i jednocześnie
                    prawidłowe dzielenie tego w raporcie. Ale na razie kategorie
                    przychodu są "na sztywno" ustalane dla każdej usługi.
                  </p>
                  <h5>Statystyki oddziałów</h5>
                  <p>
                    Nowy raport z podstawowymi danymi miesięcznymi o oddziale.
                    Szczegóły na temat sposobu obliczania każdej pozycji można
                    podejrzeć klikając przycisk ze znakiem zapytania.{" "}
                    <b>Proszę o czujność</b> przy korzystaniu z tych danych i
                    zgłaszanie jak coś nie będzie się zgadzało.
                    <br />
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.8.1
              <br />
              2021-12-27
            </td>
            <td>Poprawienie automatycznego ustalania okresu fakturowania</td>
          </tr>
          <tr>
            <td>
              1.8.0
              <br />
              2021-12-19
            </td>
            <td>
              <h4>Klient</h4>
              <ul>
                <li>
                  "Termin płatności od daty dostarczenia faktury" - dodana
                  informacja w instrukcji ("Klienci" i "Faktury")
                </li>
                <li>
                  Aktualizacja instrukcji o sposób określania adresu @ do
                  wysyłki faktury
                </li>
              </ul>
              <h4>Lokalizaje</h4>
              <ul>
                <li>
                  Dodana obsługa usług imprezowych, szczegóły na szkoleniach (
                  <b>na razie proszę nie korzystać!</b>).
                </li>
                <li>
                  Zablokowana możliwość przywracania lokalizacji z archiwum
                  jeśli klient też jest zarchiwizowany
                </li>
                <li>Dodana informacja dlaczego nie można zamknąć usługi</li>
                <li>
                  Tabela wyboru usługi (przy dodawaniu usługi na lokalizacji):
                  dodana informacja czy usługa jest sprzętowa/bezsprzętowa i czy
                  są wymagane zadania.
                </li>
                <li>
                  Naprawione wstawianie domyślnej ceny przy dodawaniu usługi
                </li>
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>
                  Odhaczanie: ułatwiona obsługa sytuacji braku kierowcy w
                  odhaczanych zadaniach {createJiraBtn(593)}
                </li>
              </ul>
              {user.perm.incomeReport ? (
                <>
                  <h4>Raporty</h4>
                  <ul>
                    <li>
                      Dodany raport przychodu wg oddziału z podziałem na usługi
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.invoicing?.w ? (
                <>
                  <h4>Fakturowanie</h4>
                  <ul>
                    <li>
                      wyłączona możliwość "nie ustawiaj daty zafakturowano do" w
                      przypadku fakturowania w jakimkolwiek oddziale poza "TEST"
                      (generowanie faktury z poziomu lokalizacji)
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.carsManage.r && (
                <>
                  <h4>Zarządzanie samochodami</h4>
                  <p>
                    Zarządzanie samochodami przeniesione z menu konfiguracyjnego
                    do głownego (przycisk "Samochody")
                  </p>
                </>
              )}
              {user.perm.carWork.r && (
                <>
                  <h4>Naprawy/przeglądy samochodów</h4>
                  <p>
                    Dodany komponent do ewidencjonowania napraw i przeglądów
                    samochodów. Szczegóły w instrukcji.
                  </p>
                </>
              )}
              {user.type.tester && (
                <>
                  <h4>Dla osób z uprawnieniem do testowania:</h4>
                  <p>
                    dodana możliwość podglądu jak została skalkulowana każda
                    pozycja na fakturze (przycisk "?" w podglądzie szczegółów
                    faktury)
                  </p>
                  <img
                    src={showInvLineSource}
                    alt=""
                    style={{ maxWidth: "80%" }}
                  />
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>
              1.7.2
              <br />
              2021-12-08
            </td>
            <td>Poprawki komponentu "Do zrobienia"</td>
          </tr>
          <tr>
            <td>
              1.7.1
              <br />
              2021-12-07
            </td>
            <td>
              Naprawiony eksport faktur (nie było możliwości ponownego
              wyeksportowania faktury {createJiraBtn(570)})
            </td>
          </tr>
          <tr>
            <td>
              1.7.0
              <br />
              2021-12-05
            </td>
            <td>
              <h4>Dla wszystkich pracowników we wszystkich oddziałach:</h4>
              <p>
                Na początku instrukcji dodałem ważną informację, proszę o
                lekturę (instrukcja {"->"} tekst w czerwonym polu na samym
                początku).{" "}
                <b>
                  Kliknięcie "Zapoznałem się" powyżej potwierdza również
                  przeczytanie ww. tekstu.
                </b>
              </p>
              <hr />
              <h3>Pozostałe zmiany:</h3>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Zmiany związane z wdrożeniem obsługi wystawiania faktur z
                  innym nabywcą i odbiorcą.
                  <br />
                  <b>
                    Obowiązkowo dla <u>wszystkich</u> pracowników oddziałów
                    Poznań, Kraków i Katowice:
                  </b>{" "}
                  zapoznanie się z instrukcją ("Klienci"/"Nabywca i odbiorca")
                </li>

                <li>
                  Pola "rabat za terminową płatność" i "eFaktura" przeniesione
                  do formularza edycji głównych danych klienta
                </li>
                <li>Na karcie "Lokalizacje" dodane wyświetlanie miasta</li>
              </ul>
              <h4>Lokalizacja</h4>
              <ul>
                <li>
                  Naprawiony błąd uniemożliwiający fakturowanie z lokalizacji w
                  niektórych przypadkach
                </li>
                <li>
                  Dodana możliwość edytowania za pomocą MEZ zadań dotyczących
                  konkretnej usługi - szczegóły w instrukcji
                  ("Lokalizacja"/"Masowa Edycja Zadań (MEZ)"/"Zadania dotyczące
                  konkretnej usługi")
                </li>
                <li>
                  Instrukcja: dodana informacja o postępowaniu w przypadku
                  zmiany liczby sprzętu dla usługi świadczonej przez nas na
                  lokalizacji (np. dostarczenie dodatkowych toalet).
                </li>
                <li>
                  Włączona możliwość korzystania z MEZ - edycja usług i sprzętu
                  dla wszystkich użytkowników.
                </li>
              </ul>
              <h4>"Do zrobienia"</h4>
              <ul>
                <li>
                  Zmienione powiadomienia @ - od teraz jeden zbiorczy mail
                  zamiast osobnej wiadomości o każdym zadaniu
                </li>
                <li>
                  Dodana możliwość tworzenia "propozycji" - zadań dla
                  przełożonych, które mogą przyjąć lub odrzucić. Szczegóły w
                  instrukcji (na samym dole).
                </li>
              </ul>
              <h4>Planowanie tras</h4>
              <ul>
                <li>
                  Dodana informacja o liczbie lokalizacji i sprzętu dla kierowcy
                  w karcie "Podsumowanie"
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.6.5
              <br />
              2021-11-21
            </td>
            <td>
              <h4>Poprawki błędów</h4>
              <ul>
                <li>Naprawione działanie MEZ w planowaniu tras </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.6.4
              <br />
              2021-11-18
            </td>
            <td>
              <h4>Poprawki błędów</h4>
              <ul>
                <li>
                  Dodawanie lokalizacji - naprawione importowanie kontaktów
                  klienta
                </li>
                <li>
                  Dodawanie/edycja lokalizacji - naprawione wyszukiwanie gmin
                  jeśli w adresie są polskie znaki
                </li>
                <li>
                  Pracownicy - naprawione dodawanie i edycja umów i notatek
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              1.6.3
              <br />
              2021-11-17
            </td>
            <td>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Naprawiony błąd uniemożliwiający edycję danych klienta bez
                  kontaktu głównego
                </li>
                <li>
                  Dodane wyświetlanie NIP dla klientów nie będących płatnikami
                  VAT
                </li>
              </ul>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Lista zadań: włączone domyślne filtrowanie zadań na 3 miesiące
                  wstecz i 1 miesiąc do przodu
                </li>
                <li>
                  Dodana możliwość wybrania wielu kontaktów klienta przy
                  tworzeniu lokalizacji (wszystkie poza glownym zostaną dodane
                  jako "inny")
                </li>
                <li>
                  Dodany wybór zezwolenia w edycji lokalizacji + możliwość
                  wyszukania pasującej gminy na pdostawie wprowadzonego adresu
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.6.2
              <br />
              2021-11-08
            </td>
            <td>
              <h4>Ogólne</h4>
              <ul>
                <li>
                  Od tej wersji przy planowaniu zadań trzeba podać datę
                  zakończenia (maksymalnie na 3 lata od "dziś"). Szczegóły w
                  instrukcji (Lokalizacja {"->"} Zadania {"->"} Zasady
                  określania harmonogramu)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.6.0
              <br />
              2021-11-06
            </td>
            <td>
              <h4>Ogólne</h4>
              <ul>
                <li>
                  W instrukcji dodana informacja o postępowaniu w przypadku
                  zduplikowanych klientów (część "Klienci")
                </li>
                <li>
                  W instrukcji dodana informacja o planowaniu zadań (część
                  "Lokalizacja")
                </li>
              </ul>
              <h4>Lokalizacje i fakturowanie</h4>
              <ul>
                <li>
                  Dodana funkcjonalność "modyfikacji faktury" umożliwiająca
                  udzielenie jednorazowego rabatu lub doliczenia jakiejś kwoty.
                  Szczegóły w instrukcji (część "Lokalizacja")
                  <ul>
                    <li>
                      Dodane wyświetlanie notatek fakturowych i modyfikacji przy
                      generowaniu faktury. Szczegóły w instrukcji (rozdział
                      "Fakturowanie") - do zapoznania się przez osoby
                      wystawiające faktury.
                    </li>
                    <li>
                      W podglądzie szczegółów faktury dodana możliwość
                      wyświetlenia zastosowanych modyfikacji (przycisk "Pokaż
                      modyfikacje")
                    </li>
                  </ul>
                </li>
                <li>Dodana obsługa rabatu za terminową płatność.</li>
                <li>
                  Naprawiony błąd uniemożliwiający generowanie faktur z poziomu
                  lokalizacji przez wciśnięcie pustego checkboxa
                </li>
                <li>
                  Poprawione ustawianie dat przy fakturowaniu z poziomu
                  lokalizaji. "Fakturuj od" powinno ustawić się odpowiednio do
                  wybranych usług, "fakturuj do" na koniec obecnego miesiąca,
                  lub poprzedniego - jeśli w momencie genrowania jest 5. dzień
                  miesiąca lub wcześniej.
                </li>
              </ul>
              <h4>Zezwolenia</h4>
              <ul>
                <li>Dodana możliwość tworzenia notatek dla zezwolenia</li>
              </ul>
              {user.perm.todos.r ? (
                <>
                  <h4>"Do zrobienia"</h4>
                  <ul>
                    <li>Dodany filtr stanu zadania</li>
                    <li>
                      Dodana możliwość zmiany stanu zadania z podglądu
                      szczegółów
                    </li>
                    <li>
                      Dodany przycisk tworzenia zadania do stopki (dostępny z
                      każdego miejsca programu)
                    </li>
                    <li>
                      Naprawione wyświetlanie danych po otwarciu zadania w nowej
                      karcie
                    </li>
                    <li>
                      Dodana wysyłka maila z przypomnieniem do osoby, która
                      utworzyła zadanie i tej, która jest aktualnie przypisana
                    </li>
                    <li>
                      Dodana możliwość sortowania po priorytecie, dacie i stanie
                      zadania
                    </li>
                    <li>
                      Dodany znacznik "<b>!!!</b>" dla przeterminowanych zadań
                    </li>
                  </ul>
                </>
              ) : null}
              {user.type.tester ? (
                <>
                  <h4>Dla testerów:</h4>
                  <p>
                    W instrukcji dodałem możliwość ukrycia moich notatek
                    dostępnych tylko dla Was (przyciski pokaż/ukryj notatki na
                    górze instrukcji, domyślnie="ukryj")
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.5.2
              <br />
              2021-10-17
            </td>
            <td>
              <h4>Ogólne</h4>
              <p>
                <b>
                  Zmiana adresu e-mail do wysyłki powiadomień (
                  <u>np. o zmianie stanu transakcji RLine</u>) na
                  'admin@cliperp.app'
                </b>
              </p>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Przy próbie zarejestrowania klienta na NIP, który już jest w
                  bazie można przejść do szczegółów tego już zarejestrowanego.{" "}
                  {createJiraBtn(451)}
                </li>
              </ul>
              <img src={existingNIP} alt="" style={{ maxWidth: "30%" }} />
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Dodana możliwość anulowania usługi (dopóki nie ma żadnego
                  zadania innego niż zaplanowane)
                </li>
                <li>
                  Dodany filtr typu usługi i rodzaju naliczenia przy dodawaniu
                  usługi
                </li>
              </ul>
              <h4>Lista lokalizacji</h4>
              <ul>
                <li>
                  dodana możliwość sortowania (A-Z albo Z-A) po nazwie klienta i
                  lokalizacji
                </li>
                <li>
                  dodany filtr stanu lokalizacji (aktywna/archiwum/wszystkie)
                </li>
                <li>dodana cena obok nazwy usługi</li>
                <li>dodany adres lokalizacji</li>
              </ul>
              <h4>Zadania</h4>
              <ul>
                <li>
                  Odwołanie, niewykonanie i usunięcie dostarczenia usuwa sprzęt,
                  który miał być dostarczony z zaplanowanych i zleconych zadań,
                  oraz z lokalizacji. <b>Operacja jest nieodwracalna</b>{" "}
                  {createJiraBtn(452)}
                </li>
                <li>
                  Naprawiony błąd powodujący wysypanie się programu po
                  edytowaniu zamrożonego zadania
                </li>
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>Dodanie logistyki do instrukcji - część "Planowanie"</li>
              </ul>
              <h5>Logistyka - Odhaczanie</h5>
              <ul>
                <li>
                  Zablokowana możliwość oznaczania dostarczenia jako
                  niewykonane/odwołane bez zaplanowania dostarczenia
                  zastępczego.{" "}
                  <u>
                    Na chwilę obecną dostarczenie może być
                    odwołane/niewykonane/usunięte tylko{" "}
                    <b>z poziomu lokalizacji</b>
                  </u>
                </li>
              </ul>
              {user.type.tester ? (
                <>
                  <h4>Nowy komponent: "Do zrobienia"</h4>
                  <p>
                    Dla osób z uprawnieniami testerów udostępniam nowy komponent
                    - listę zadań do zrobienia. Szczegóły w intrukcji.
                  </p>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.5.1
              <br />
              2021-09-05
            </td>
            <td>
              <h4>Lista zmian</h4>
              <ul>
                <li>
                  Dodana informacja o wersji programu, dla której ostatnio
                  kliknięto "zapoznałem się"
                </li>
                <li>
                  Na liście zmian od teraz może się pojawiać tego typu przycisk:{" "}
                  {createJiraBtn(1)} (ten symbol przed liczbą to logo Jiry ;) )
                  - po kliknięciu w nowej karcie otworzy się zgłoszenie Jiry,
                  którego dotyczy zmiana (może wymagać ponownego zalogowania).
                </li>
              </ul>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Przeniesiony przycisk "archiwizuj/przywróć" z karty
                  "Dodatkowe" do nagłówka
                </li>
                <li>
                  Przycisk pojawia się tylko jeśli: nie ma aktywnych lokalizacji
                  oraz użytkownik ma uprawnienia do wszystkich oddziałów, do
                  których przypisany jest klient
                </li>
                <li>
                  Poprawione działanie archiwizacji: po zarchiwizowaniu nie
                  można wprowadzać żadnych zmian ani rejestrować nowych
                  lokalizacji
                </li>
              </ul>
              <h4>Faktury</h4>
              <ul>
                <li>
                  Naprawione wystawianie faktur dowolnych ("Fakturowanie {"->"}{" "}
                  nowa")
                </li>
                <li>
                  Naprawiony błędny termin płatności przy eksporcie faktur po
                  edycji
                </li>
                <li>Poprawki błędów fakturowania usług "za serwis"</li>
                <li>
                  Dodane wyświetlanie notatek fakturowych w fakturowaniu masowym
                  (listalokalizacji {"->"} fakturuj zaznaczone)
                </li>
                <li>
                  Notatki fakturowe <u>jednorazowe</u> domyślnie ukryte na
                  liście notatek {createJiraBtn(350)}
                </li>
                <li>
                  <h5>Lista faktur:</h5>
                  <ul>
                    <li>Poprawione wyświetlanie danych w podglądzie faktury</li>
                    <li>
                      Na liście faktur data wygenerowania faktury zmieniona na
                      datę faktury (data wygenerowania - kiedy faktycznie
                      utworzono fakturę w programie, data faktury - data
                      wprowadzona w modalu)
                      {createJiraBtn(412)}
                    </li>
                    <li>Rozbudowane filtry wyszukiwania faktur</li>
                    <li>Dodana możliwość drukowania listy faktur</li>
                  </ul>
                </li>
              </ul>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Naprawiony błąd uniemożliwiający edycję zadań z poziomu
                  kalendarza w przypadku, gdy danego dnia były więcej niż dwa.{" "}
                  {createJiraBtn(357)}
                </li>
                <li>
                  Dodana możliwość zmiany ceny usługi w trakciej jej trwania
                  przy użyciu jednego przycisku {createJiraBtn(226)}
                </li>
                <li>
                  Naprawione nieprawidłowe zachowanie przy dodawaniu zadania{" "}
                  {createJiraBtn(421)}
                </li>
                <li>
                  Naprawione nieprawidłowe naliczanie usług 'za serwis' (program
                  doliczał serwisy wykonane przed okresem fakturowania){" "}
                  {createJiraBtn(422)}
                </li>
                <li>
                  Naprawiony błąd uniemożliwiający zmianę kierowcy we wszystkich
                  zadaniach przy zmianie domyślnego kierowcy lokalizacji
                </li>
                <li>
                  Naprawiony błąd uniemożliwiający zmianę domyślnego kierowcy
                  lokalizacji i we wszystkich zaplanowanych zadaniach przy
                  edycji pojedyńczego zadania
                </li>
                <li>
                  Poprawiona archiwizacja lokalizacji: wyłączona możliwość
                  jakiejkolwiek edycji zarchiwizowanej lokalizacji
                </li>
                <li>
                  Dodany alert jeśli usługa sprzętowa nie ma przypisanego
                  żadnego sprzętu{createJiraBtn(401)}
                </li>
                <li>
                  Dodana możliwość tworzenia notatki fakturowej przy zmianie
                  stanu zadania na niewykonane - szczegóły w instrukcji
                  (Lokalizacja {"->"} notatki)
                </li>
                <li>
                  Lista lokalizacji (z zaznaczonym "Niezafakturowane"): dodana
                  możliwość zaznaczania wszystkich checkboxów
                  {createJiraBtn(395)}
                </li>
                <li>
                  Lista lokalizacji (z zaznaczonym "Niezafakturowane"): dodana
                  możliwość odwracania zaznaczenia checkboxów{" "}
                  {createJiraBtn(395)}
                </li>
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>
                  Dodana możliwość automatycznego tworzenia nowego dostarczenia
                  jeśli odhaczane dostarczenie nie zostało zrealizowane{" "}
                  {createJiraBtn(42)}
                </li>
                <li>
                  Dodana możliwość tworzenia notatek fakturowych przy odhaczaniu
                  zadań jako niewykonane - szczegóły w instrukcji (Lokalizacja{" "}
                  {"->"} notatki)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.5.0
              <br />
              2021-08-15
            </td>
            <td>
              <p>
                <h3>Prośba!</h3> Dawajcie znać, jeśli musicie "oszukiwać"
                program (np. wirtualne dostarczenia/zabrania, wpisywanie
                nieprawdziwych danych itd.) - to oznacza, że coś jest do
                poprawy. Jak nie wiem, że coś jest do poprawy to nie poprawię
                ;).
              </p>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Dodany opis działania Masowej Edycji Zadań do instrukcji.
                </li>
                <li>
                  Dodana możliwość masowej edycji <u>usług i sprzętu</u> przez
                  MEZ z poziomu lokalizacji. Wymaga to dodatkowych uprawnień
                  (kierownicy), ale proszę wszystkich o zapoznanie się ze
                  szczegółami w intrukcji (Lokalizacje {"->"} MEZ)
                </li>
                <li>
                  Dodana możliwość masowej edycji zadań co wybrany dzień,
                  tydzień lub co któreś wystąpienie, szczegóły również w
                  instrukcji
                </li>
                <li>Dodany filtr typu zadania do masowej edycji zadań</li>
                <li>Naprawione działanie notatki fakturowej jednorazowej</li>
                <li>
                  Lista zadań: dodana kolumna "usługi" oraz filtrowanie wg usług
                  przypisanych do zadania.
                </li>
              </ul>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Zmiana domyślnego terminu płatności - zamiast pola do wpisania
                  dowolnej wartości trzeba wybrać jedną ze zdefiniowanych. To
                  wymaganie narzucone przez Symfonię, w razie potrzby dodam
                  kolejne terminy.
                </li>
                <li>Poprawione wyświetlanie oddziałów na liście klientów</li>
              </ul>
              <h4>Faktury</h4>
              <ul>
                <li>Naprawione eksportowanie faktur gotówkowych</li>
                <li>
                  Naprawiony błędny eksport ceny jednostkowej brutto jako netto
                </li>
              </ul>
              <h4>Usługi</h4>
              <ul>
                <li>
                  Zmiana typu naliczania "za sztukę" {"->"} "za serwis".{" "}
                  <b>Uwaga!</b> przypominam, że usługi "za serwis" przy
                  fakturowaniu powodują naliczenie{" "}
                  <u>tylko za wykonane serwisy</u>
                  (nie dostarczenia czy zabrania).
                  <br />
                  Po tej zmianie nadal mogą pojawiać się usługi z naliczeniem
                  "za sztukę", ale będą stopniowo znikać. Zachowanie programu
                  przy fakturowaniu będzie takie samo dla usług naliczanych "za
                  sztukę" i "za serwis".
                </li>
                {user.perm.servicesManage.w && (
                  <li>
                    Dodana tzw. walidacja pól przy edycji i dodawaniu usług,
                    tzn. nie można dodać/edytować usługi dopóki wszystkie
                    wymagane pola nie są wypełnione.
                  </li>
                )}
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>Poprawiona kolejność współrzędnych na kontrolce</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.4.2
              <br />
              2021-08-06
            </td>
            <td>
              <ul>
                <li>Naprawiony błąd eksportowania faktur</li>
              </ul>
              <h4>Pracownicy</h4>
              <ul>
                <li>
                  Zmiana sposobu tworzenia historii ("stara wartość {"->"} nowa
                  wartość" zamiast samej starej wartości)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.4.1
              <br />
              2021-08-05
            </td>
            <td>
              <h4>Ogólne</h4>
              <ul>
                <li>
                  Wyszukiwarka na liście lokalizacji szuka też po nazwie klienta
                  (wyszukuje tylko <u>aktywnych</u> klientów)
                </li>
              </ul>
              {user.perm.invoicing.r ? (
                <>
                  <h4>Fakturowanie</h4>
                  <ul>
                    <li>
                      Poprawiony formularz edycji faktury - dodane zaokrąglanie,
                      naprawiony błąd przy zmianie VATu
                    </li>
                    <li>Dodane wyświetlanie usług na liście lokalizacji</li>
                    <li>
                      Dodana możliwość fakturowania z listy lokalizacji (tylko
                      przy zaznaczonym checkboxie "Niezafakturowane")
                    </li>
                    <li>
                      <u>
                        Dodana możliwość wystawiania jednej faktury dla kilku
                        lokalizacji
                      </u>
                    </li>
                    <li>
                      Dodana obsługa znacznika {`{adresLok}`} przy wystawianiu
                      faktur (tzn. jeśli w usłudze na danej lokalizacji jest w
                      opisie {`{adresLok}`} to przy generowaniu faktury zostanie
                      podmieniony na faktyczny adres danej lokalizacji).
                    </li>
                    <li>
                      Dodana konieczność potwierdzenia przy ponownym eksporcie
                      faktur
                    </li>
                    <li>
                      Dodana możliwość wystawiania faktury "dowolnej"
                      (Fakturowanie {"->"} Nowa). <b>UWAGA!</b> To na razie
                      funkcja eksperymentalna{" "}
                      <u>proszę nie używać inaczej niż do testów!</u>
                    </li>
                    <li>
                      (Fakturowanie {"->"} lista) Dodane wyszukiwanie faktur po
                      nazwie klienta{" "}
                    </li>
                    <li>
                      Dodany podgląd faktur wybranego klienta (w oknie ze
                      szczegółami klienta: przycisk "faktury")
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.logistics.w ? (
                <>
                  <h4>Logistyka</h4>
                  <ul>
                    <li>
                      Przy przypisywaniu samochodów można wykorzystać samochody
                      z innego oddziału, do którego użytkownik ma uprawnienia.
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.4.0
              <br />
              2021-07-25
            </td>
            <td>
              <h4>Ogólne</h4>
              <ul>
                <li>
                  Aktualizacja komponentu zarządzającego wszystkimi
                  formularzami. <b>To duża zmiana</b>, która powinna być dla Was
                  nieodczuwalna, jednak może spowodować błędy jeśli coś poszło
                  nie tak. Proszę o uwagę przy każdym dodawaniu/edycji
                  transakcji/zużycia/klienta/lokalizacji (również adresów i
                  kontaktów) i zgłaszanie błędów przez Jirę.
                </li>
              </ul>
              <h4>Klienci i lokalizacje</h4>
              <ul>
                <li>
                  Dodane oznaczenie "(imp.)" na liście klientów w pierwszej
                  kolumnie. Jeśli się pojawia oznacza to, że klient został
                  zaimportowany ze starego programu. Będzie to potrzebne przy
                  porządkowaniu zduplikowanych klientów, szczegóły niedługo
                </li>
                <li>
                  Dodana możliwość ręcznego archiwizowania lokalizacji (karta
                  "Szczegóły").
                </li>
                <li>
                  Poprawione archiwizowanie klienta - przed wykonaniem operacji
                  program sprawdzi czy wszystkie lokalizacje są zarchiwizowane.
                  <ul>
                    <li>
                      Przycisk archiwizowania przeniesiony do karty "Dodatkowe"
                    </li>
                  </ul>
                </li>
              </ul>
              {user.perm.invoicing.r ? (
                <>
                  <h4>Fakturowanie</h4>
                  <ul>
                    <li>
                      Poprawione działanie listy faktur i dodany filtr oddziału
                    </li>
                    <li>Dodana możliwość usuwania wygenerowanych faktur</li>
                    <li>
                      Dodany opis fakturowania do instrukcji. <b>Uwaga!</b>{" "}
                      Wyjątkowo lektura nie jest obowiązkowa - wszystko powiem
                      podczas szkolenia z fakturowania.
                    </li>
                    <li>Dodana możliwość edycji wygenerowanej faktury</li>
                    <li>
                      Dodana możliwość zmiany wartości "zafakturowano do" dla
                      parametru - tylko kierownicy, Łukasz i ja.
                    </li>
                  </ul>
                </>
              ) : null}
              {user.perm.transaction.r ? (
                <>
                  <h4>Sprzedaż RLine</h4>
                  <ul>
                    <li>
                      Zmiana terminów płatności na 0, 7, 14, 30.
                      <br /> "0" oznacza przedpłatę lub płatność przy odbiorze.
                      Nadal istnieje możliwość wyboru dowolnego terminu/daty.
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.3.0
              <br />
              2021-07-16
            </td>
            <td>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Nowy rodzaj notatki: "fakturowa". Taka notatka będzie
                  wyświetlać się przy fakturowaniu na danej lokalizacji,
                  szczegóły w <b>instrukcji</b> (dodałem również ogólny opis
                  notatek - proszę o zapoznanie się).
                </li>
              </ul>
              {user.perm.invoicing.r ? (
                <>
                  <h4>Fakturowanie</h4>
                  <ul>
                    <li>Dodana możliwość zbiorczego eksportowania faktur</li>
                    <li>Dodana historia faktury</li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.2.0
              <br />
              2021-07-04
            </td>
            <td>
              <h4>Klienci</h4>
              <ul>
                <li>
                  Instrukcja usupełniona o opis wyszukiwarki klientów i sposób
                  działania w przypadku klientów "wieloodziałowych" -{" "}
                  <b>proszę o zapoznanie się</b>
                </li>
              </ul>
              <h4>Lokalizacje</h4>
              <ul>
                <li>
                  Dodana możliwość przypisania zezwolenia (karta
                  "Lokalizacja/Szczegóły") -{" "}
                  <b>proszę o uzupełnienie już dodanych lokalizacji!</b>
                </li>
                <li>
                  Zamknięte usługi domyślnie są ukryte, chyba że któraś z nich
                  nie jest w całości zafakturowana.
                </li>
                <li>
                  Usunięty alert niezgodnej liczby sprzętu dla zamkniętych usług
                </li>
              </ul>
              <h4>Zadania</h4>
              <ul>
                <li>
                  Dodana możliwość drukowania potwierdzeń dostarczenia
                  (szczegóły zadania {"->"} ikona drukarki).
                </li>
              </ul>
              {user.perm.logistics.w ? (
                <>
                  <h4>Logistyka</h4>
                  <ul>
                    <li>
                      Dodana obsługa samochodów - przy planowaniu tras należy
                      przypisać każdemu kierowcy samochód (ikona samochodu w
                      tabeli w karcie "Podsumowanie").{" "}
                      <b>
                        Proszę o zapoznanie się z instrukcją (część "Zadania")
                      </b>
                    </li>
                    <li>
                      Dodany wydruk potwierdzeń dostarczenia przy wydruku
                      kontrolek
                    </li>
                    <li>
                      Poprawiona obsługa zabrań i zamykanie usług przy
                      odhaczaniu zabrania
                    </li>
                    <li>
                      W odhaczaniu dodana możliwość przejścia do lokalizacji lub
                      klienta (przy odhaczaniu pojedyńczego zadania lub edycji
                      wielu). Nazwa lokalizacji i nazwa klienta są linkami, ich
                      kliknięcie spowoduje otwarcie odpowiednich danych w nowej
                      karcie{" "}
                    </li>
                  </ul>
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              1.1.0
              <br />
              2021-05-30
            </td>
            <td>
              <h4>Ogólne i lokalizacje</h4>
              <ul>
                <li>
                  <b>Zmiana zasad numerowania wersji</b> (szczegóły w
                  instrukcji: "Zasady ogólne/Zmiany i wersje programu")
                </li>
                {user?.perm.locations?.r ? (
                  <li>
                    Dodany raport (do wydruku) listy zadań dla danego klienta -
                    szczegóły w instrukcji (rozdział Zadania)
                  </li>
                ) : null}
                {user?.perm.transactionsList.r || user?.perm.transaction.w ? (
                  <li>Przeniesienie raportu sprzedaży RLine do "Raporty"</li>
                ) : null}
                <li>
                  Od teraz czas na zapoznanie się ze zmianami to 7 dni,{" "}
                  <b>
                    po tym terminie nie można nic zrobić do momentu kliknięcia
                    "Zapoznałem się"
                  </b>
                </li>
                <li>
                  Dodana wyszukiwarka lokalizacji - szczegóły poniżej w części
                  "Fakturowanie"
                </li>
                <li>
                  Ważna informacja w instrukcji dotycząca różnicy między
                  usługami "Wynajem i serwis" i "Wynajem" (część "Lokalizacja" w
                  intrukcji, wyróżniona kolorowym tłem).
                </li>
                <li>Dodana możliwość przesuwania kalendarza</li>
              </ul>
              <h4>Logistyka</h4>
              <ul>
                <li>
                  Zmiana sposobu drukowania kontrolek - od teraz drukowane są
                  dla zaznaczonych kierowców, dla ułatwienia dodany checkbox do
                  zaznaczania wszystkich jednym kliknięciem (w nagłówku tabeli)
                </li>
              </ul>
              <h4>Fakturowanie</h4>
              <ul>
                <li>
                  Lista niezafakturowanych lokalizacji przeniesiona do
                  wyszukiwarki lokalizacji (górne menu {"->"} "Lokalizacje" ).
                  Wyszukanie lokalizacji z jakąkolwiek usługą niezafakturowaną
                  na wybrany dzień wymaga zaznaczenia checkboxa.
                </li>
              </ul>
              <h4>Dane pracownika</h4>
              <ul>
                <li>
                  Zmieniony sposób tworzenia historii (zamiast samej poprzedniej
                  wartości jest teraz "stara wartość {"->"} nowa wartość)
                </li>
                <li>
                  Wyłączone wysyłanie powiadomień mailowych przy zmianach
                  administracyjnych (np. uprawnień)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              1.0.0
              <br />
              2021-05-23
            </td>
            <td>
              <h3>Lokalizacje</h3>
              <ul>
                <li>
                  Zablokowana możliwość dodawania zadań bez sprzętu dla usług,
                  które sprzętu wymagają
                </li>
                <li>
                  Dodana możliwość zmiany sprzętu (np. templatka {"->"} realny
                  sprzęt, lub podmianki sprzętu numerowanego) - z poziomu tabeli
                  sprzętu na lokalizacji
                </li>
                <li>
                  Zamknięcie usługi z poziomu tabeli usług powoduje usunięcie
                  wszystkich zadań zaplanowanych po dacie zamknięcia (lub
                  usunięcie tej usługi jeśli zadanie miało przypisane kilka
                  usług)
                </li>
                <li>
                  Dodane numery usług - każda nowa usługa na lokalizacji dostaje
                  swój kolejny numer, używany potem przy dodawaniu i edycji
                  zadań (działa tylko dla nowych usług, dla dodanych przed
                  deployem wyświetla się "?")
                </li>
                <li>
                  Dodany przycisk "Pomoc" w nagłówku. Po kliknięciu pojawi się
                  informacja jak rozwiązać najczęstsze problemy
                </li>
                <li>
                  Dopisany fragment instrukcji o usługach i parametrach
                  (rozdział Lokalizacje). <b>Każdego proszę o zapoznanie się</b>
                  , niezależnie od wcześniejszego stanu wiedzy, w ramach
                  przypomnienia i uporządkowania informacji.
                </li>
              </ul>
              <h3>Zadania</h3>
              <ul>
                <li>
                  Poprawione wyświetlanie parametrów sprzętu:
                  <ul>
                    <li>przy dodawaniu zadań</li>
                    <li>
                      w podglądzie szczegółów zadania (lokalizacja i planowanie
                      tras)
                    </li>
                  </ul>
                </li>
                <li>
                  Odhaczenie zabrania jako wykonane powoduje usunięcie zabranego
                  sprzętu z wszystkich zadań późniejszych od tego zabrania
                </li>
                <li>
                  Odhaczenie zabrania jako wykonane, jeśli dotyczy całego
                  sprzętu dla danej usługi umożliwia jej zamknięcie (to było już
                  wcześniej), w przypadku potwierdzenia program usunie wszystkie
                  zadania zaplanowane po dacie zamknięcia (tzn. dacie zabrania).
                  W przypadku zadań z kilkoma usługami zamykana usługa zostanie
                  usunięta z zadania.
                </li>
              </ul>
              <h3>Logistyka</h3>
              <ul>
                <li>Zmniejszony rozmiar markera na mapie </li>
              </ul>
              <h3>Klienci</h3>
              <ul>
                <li>
                  Od teraz wyszukiwarka klientów ignoruje spacje. Tzn. szukajac
                  klienta "Budo-Krusz" można wpisać "Budo -Krusz", "Budo -
                  Krusz", "b u d o -krusz" itd - wszystko spowoduje prawidłowe
                  wyszukanie tego klienta{" "}
                </li>
              </ul>

              <h3>Fakturowanie</h3>
              <ul>
                <li>Dodana możliwość fakturowania usług "za sztukę"</li>
                <li>Dodana lista faktur</li>
              </ul>

              {user.perm.eqpManage.r && (
                <>
                  <h3>Zarządzanie sprzętem </h3>
                  <b>"Koło zębate" {"->"} "Sprzęt - zarządzanie"</b>
                  <ul>
                    <li>
                      Dodana możliwość wyszukiwania wszystkich templatek
                      (checkbox "Pobierz wszystkie" powoduje zignorowanie
                      podtypu, charakterystyki itd. w efekcie wyświetlane są
                      wszystkie templatki danego typu)
                    </li>
                  </ul>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>
              0.6.2
              <br />
              2021-04-25
            </td>
            <td>
              <h3>Dane pracownika</h3>
              <ul>
                <li>
                  Naprawione wprowadzanie danych osobistych przez pracownika.
                </li>
              </ul>
              <h3>Sprzęt</h3>
              <ul>
                <li>
                  W instrukcji dodana informacja wyjaśniająca obsługę sprzętu
                  przez clipERP (do zapoznania się w trakcie testów)
                </li>
              </ul>

              <h3>Pracownicy</h3>
              <ul>
                <li>
                  Dodana możliwość edycji podstawowych danych pracownika przez
                  niego samego. Funkcja włączana czasowo, szczegóły w mailu.
                </li>
                <li>
                  Dodane powiadomienia mailowe o zmianach w podstawowych danych
                  pracownika, szczegóły w instrukcji.
                </li>
              </ul>

              {user.type.tester && (
                <>
                  <h3>Zmiany w nowych komponentach </h3>
                  <p>
                    (widoczne tylko dla testerów i trochę mniej dopracowane.{" "}
                    <b>
                      Tu nie ma wszystkich zmian, tylko te najważniejsze i/lub
                      nie wymienione gdzie indziej
                    </b>{" "}
                    )
                  </p>

                  <h4>
                    Duże zmiany w obsłudze sprzętu, dostarczeń, zabrań itd.
                  </h4>
                  <p>
                    Proszę o przeczytanie części "Sprzęt" w instrukcji. Wiele
                    dotychczasowych zasad/sposobów pracy jest teraz
                    nieaktualnych, działajcie zgodnie z tym, co jest w
                    instrukcji.
                  </p>

                  <ul>
                    <li>
                      W kalendarzu lokalizacji dodałem podświetlanie daty jeśli
                      jest aktywny jakiś parametr
                    </li>
                    <li>
                      Uporządkowałem nazewnictwo w parametrach zadania (chodzi o
                      "Uwagi", "Uwagi do realizacji", "Komentarz" itd).{" "}
                      <b>
                        Od teraz są "Uwagi" dodawane przy tworzeniu zadania lub
                        jego edycji przed zleceniem i "komentarz" wpisywany przy
                        odhaczaniu (docelowo: przez kierowcę na eKontrolce)
                      </b>
                    </li>
                    <li>
                      Przy dodawaniu serwisu/serwisów (nie dotyczy dostarczeń i
                      zabrań) dodałem sprawdzanie, czy wszystkie usługi podpięte
                      pod to zadanie są aktywne w dniu serwisu (tzn. jest jakiś
                      parametr, którego daty obejmują dzień dodawanego serwisu).
                      <br />
                      <b>
                        Jest niuans z tym związany - szczegóły w nagraniu 01 z
                        katalogu testerzy/s017
                      </b>
                    </li>
                    <li>
                      Poprawiłem działanie filtra w tabeli w planowaniu tras.
                      Teraz działa jak są zadania bez kierowców (patrz #61),{" "}
                      <u>
                        dodatkowo można odfiltrować zadania, które nie mają
                        przypisanego kierowcy
                      </u>
                    </li>
                    <li>Dodałem filtry w karcie "Zadania" w lokalizacji</li>
                    <li>Dodane fakturowanie dla usług z ceną miesięczną</li>
                    <li>
                      Dodałem automatyczne ukrywanie zakończonych parametrów
                      (data zakończenia = zafakturowano do) i checkboxa do ich
                      pokazywania
                    </li>
                    <li>Poprawki w obsłudze sprzętu, szczegóły w filmie 02</li>
                    <li>
                      Poprawiłem zamykanie usług. Program będzie pytał, czy
                      zamknąć usługę przy odhaczaniu jako wykonane zabrania,
                      które dotyczy całego sprzętu podpiętego pod tą usługę.
                      Jednocześnie żeby ręcznie zamknąć usługę (szczegóły
                      lokalizacji {"->"} tabela usług {"->"} przycisk "zamknij")
                      nie może na niej być żadnego sprzętu
                    </li>
                    <li>
                      Zmieniłem filozofię obsługi sprzętu:
                      <ul></ul>
                    </li>
                    <li>
                      Dodałem możliwość edycji dodatkowych szczegółów klienta
                      (eFaktura, zgody marketingowe itd)
                    </li>
                  </ul>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td>
              0.6.1
              <br />
              2021-03-??
            </td>
            <td>
              {user.perm.transaction.r || user.perm.transaction.w ? (
                <>
                  <h3>Transakcje</h3>
                  <ul>
                    <li>
                      Kierownicy mogą zmienić stan zaproformowanej transakcji na
                      "oczekująca" (tzn. odblokować do edycji)
                    </li>
                  </ul>
                </>
              ) : null}

              <h3>Pracownicy</h3>
              <ul>
                <li>
                  Dodana możliwość edycji podstawowych danych pracownika przez
                  niego samego. Funkcja włączana czasowo, szczegóły w mailu.
                </li>
                <li>
                  Dodane powiadomienia mailowe o zmianach w podstawowych danych
                  pracownika, szczegóły w instrukcji.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              0.6.0
              <br />
              2021-03-15
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Zmiany bez wpływu na interfejs użytkownika (można bez
                  konsekwencji klikać "Zapoznałem się")
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              0.5.4
              <br />
              2021-02-24
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Zmiany techniczne - bez wpływu na interfejs użytkownika (można
                  bez konsekwencji klikać "Zapoznałem się")
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              0.5.3
              <br />
              2021-02-07
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Od teraz mogę ustawić użytkownikowi domyślny oddział - będzie
                  on automatycznie wybrany przy dodawaniu transakcji czy
                  zużycia.
                </li>
                <li>
                  Zmieniłem domyślne działanie tła modali - szczegóły w
                  instrukcji (w części 1. Informacje ogólne)
                </li>
              </ul>
              <h3>Pracownicy</h3>
              <ul>
                <li>
                  Zmiana sposobu wyświetlania - taraz tabele są rozciągnięte na
                  cały ekran.
                </li>
              </ul>

              {user.perm.transactionsList.r ? (
                <>
                  <h3>Transakcje (sprzedaż RLine)</h3>
                  <p>
                    W wyszukiwarce danych klienta ze starej bazy dodane
                    ograniczenie - trzeba wpisać min. 3 znaki. (to kwestia
                    techniczna, w przyszłości usunę to ograniczenie)
                  </p>
                </>
              ) : null}
              {/* {user.perm.consumption.w ? (
                <span>
                  <h3>Magazyny</h3>
                  <ul>
                    <li></li>
                  </ul>
                </span>
              ) : null} */}
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              {/* <h3>Pracownicy</h3>
              <ul>
                {user.perm.employees.e ? (
                  <>
                    <li></li>
                  </>
                ) : null}
                <li></li>
              </ul>
              {user.type.board ? (
                <ul>
                  <li>(tylko zarząd:)</li>
                </ul>
              ) : null} */}

              {user.perm.customers.r && (
                <>
                  <h3>Klienci</h3>
                  <ul>
                    <li>
                      Wyłączyłem obowiązkowy REGON przy rejestracji/dodawaniu
                      klienta {"->"} można nie wpisywać, ale jak się wpisze to
                      będzie sprawdzony, czy jest ok.
                    </li>
                    <li>
                      No i dodałem możliwość rejestrowania nowych klientów.
                      Pamiętajcie że to będzie widoczne dla wszystkich (przez
                      dodawanie transakcji {"->"} wyszukaj w bazie, więc bez
                      niecenzuralnych danych :P
                    </li>
                  </ul>
                </>
              )}

              {/* {user.perm.transactionsList.r ? (
                // <span>
                //   <h3>Raport sprzedaży</h3>
                //   <ul>
                //     <li>
                //       Poprawiony błąd domyślnej daty w formularzu wyboru okresu
                //       raportu
                //     </li>
                //   </ul>
                // </span>
              ) : null} */}
              {/* <h3>Poprawki i błędy</h3>
              <ul>
                <li>
                  Wykryty błąd związany z zamykaniem modala, szczegóły w mailu.
                  Poprawka w kolejnej wersji.
                </li>
                {user.perm.transaction.w ? (
                  <li>
                    Poprawiony błąd: "na liście transakcji wyświetlana jest suma
                    bez uwzględnienia kosztów transportu"
                  </li>
                ) : null}
              </ul> */}
            </td>
          </tr>
          <tr>
            <td>
              0.5.2
              <br />
              2021-01-24
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Zmiana działania przycisku "Wyślij wiadomość" (na dole po
                  prawej). Teraz otworzy się modal (nowe okienko), a po wysłaniu
                  program wróci do poprzedniego stanu - nie utracicie efektów
                  pracy itp.
                </li>
                <li>
                  Zmiana działania alertu o nowej wersji - szczegóły w
                  instrukcji (1. Informacje ogólne {"->"} Informacje o zmianach)
                  <ul>
                    <li>
                      Przy okazji krótkie info o techniczne w instrukcji {"->"}{" "}
                      "Nieaktualne grafiki", kilka akapitów ponad częścią
                      "Informacje o zmianach".
                    </li>
                  </ul>
                </li>
              </ul>

              {/* {user.perm.transactionsList.r ? (
                <>
                  <h3>Transakcje (sprzedaż RLine)</h3>
                  <p>
                    Nowy komponent: "Raport sprzedaży", szczegóły w instrukcji.
                  </p>
                </>
              ) : null} */}
              {user.perm.consumption.w ? (
                <span>
                  <h3>Magazyny</h3>
                  <ul>
                    <li>
                      Naprowiony błąd powodujący usuwanie części wprowadzonych
                      danych przy kasowaniu jednego z wierszy
                    </li>
                  </ul>
                </span>
              ) : null}
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Pracownicy</h3>
              <ul>
                {user.perm.employees.e ? (
                  <>
                    <li>
                      Dodane zapamiętywanie otwartej karty, czyli np. po
                      dodaniu/edycji notatki okno przeładuje się z od razu
                      otwartą kartą "Notatki" (wcześniej po zapisaniu zmiany
                      otwierała się karta "Ogólne"
                    </li>
                    <li>
                      Zmiana działania listy pracowników - po kliknięciu na
                      pracownika otwiera się nowa zakładka w przeglądarce z
                      podglądem szczegółów. Dzięki temu w starej zakładce nadal
                      jest dostępna lista - można otworzyć kilku pracowników,
                      nie zeruje się filtr ("Archiwum") i wyszukiwarka.
                    </li>
                  </>
                ) : null}
                <li>
                  Przepisałem duży fragment instrukcji dot. komponentu
                  "Pracownicy", dodałem wyjaśnienie zmian w umowach - proszę o
                  lekturę.
                </li>
              </ul>
              {user.type.board ? (
                <ul>
                  <li>
                    (tylko zarząd:)Przeniesiony przycisk zmiany stanu umowy. W
                    przyszłości to samo dla notatek, na razie wrzucam w mało
                    pilne.
                  </li>
                </ul>
              ) : null}

              {/* {user.perm.transactionsList.r ? (
                // <span>
                //   <h3>Raport sprzedaży</h3>
                //   <ul>
                //     <li>
                //       Poprawiony błąd domyślnej daty w formularzu wyboru okresu
                //       raportu
                //     </li>
                //   </ul>
                // </span>
              ) : null} */}
              {/* <h3>Poprawki i błędy</h3>
              <ul>
                <li>
                  Wykryty błąd związany z zamykaniem modala, szczegóły w mailu.
                  Poprawka w kolejnej wersji.
                </li>
                {user.perm.transaction.w ? (
                  <li>
                    Poprawiony błąd: "na liście transakcji wyświetlana jest suma
                    bez uwzględnienia kosztów transportu"
                  </li>
                ) : null}
              </ul> */}
            </td>
          </tr>
          <tr>
            <td>
              0.5.1
              <br />
              2021-01-12
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Poprawiona obsługa tzw. modali (wyskakujących okienek z
                  informacjami o zapisaniu, konieczności potwierdzenia czy
                  szczegółowymi danymi).
                </li>

                <li>
                  "Przykleiłem" menu nawigacyjne, czyli przy przewijaniu w dół
                  na dłuższych ekranach będzie ono nadal widoczne.
                </li>
              </ul>

              {/* {user.perm.transactionsList.r ? (
                <>
                  <h3>Transakcje (sprzedaż RLine)</h3>
                  <p>
                    Nowy komponent: "Raport sprzedaży", szczegóły w instrukcji.
                  </p>
                </>
              ) : null} */}
              {/* <h3>Magazyny</h3> */}
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Pracownicy</h3>
              <p>
                (w związku z poniższymi punktami wprowadziłem kilka zmian w
                instrukcji, proszę o zapoznanie się - są standardowo wyróżnione
                kolorem tła)
              </p>
              <ul>
                <li>
                  Dodana tabela z dodatkowymi obciążeniami (multisport,
                  ubezpieczenie)
                </li>
                <li>Dodana obsługa załączania plików do umów i notatek</li>
                <li>Dodana obsługa umów "godzinowych"</li>
                <li>
                  Poprawione tabele z podsumowaniem umów - naprawione liczenie
                  sum, dodany procentowy koszt pracodawcy
                </li>
                <li>
                  Poprawiony błąd powodujący zapisywanie w historii zbyt wielu
                  danych przy edycji "Dane kontaktowe - prywatne" (przed:
                  zapisywały się wartości wszystkich pól z tej tabeli; teraz:
                  zapisują się tylko wartości zmienionych pól)
                </li>
                <li>
                  Zablokowana możliwość edycji danych "zarchiwizowanego"
                  pracownika
                </li>
                {user.perm.employees.e && (
                  <span>
                    <li>
                      Poprawione działanie przycisku "wróć" przy dodawaniu nowej
                      umowy
                    </li>
                    <li>
                      Dadane imię i nazwisko pracownika w zakładkach "Umowy" i
                      "Notatki" - żeby się nie pogubić
                    </li>
                  </span>
                )}
              </ul>
              {user.perm.transactionsList.r ? (
                <span>
                  <h3>Raport sprzedaży</h3>
                  <ul>
                    <li>
                      Poprawiony błąd domyślnej daty w formularzu wyboru okresu
                      raportu
                    </li>
                  </ul>
                </span>
              ) : null}
              {/* <h3>Poprawki i błędy</h3>
              <ul>
                <li>
                  Wykryty błąd związany z zamykaniem modala, szczegóły w mailu.
                  Poprawka w kolejnej wersji.
                </li>
                {user.perm.transaction.w ? (
                  <li>
                    Poprawiony błąd: "na liście transakcji wyświetlana jest suma
                    bez uwzględnienia kosztów transportu"
                  </li>
                ) : null}
              </ul> */}
            </td>
          </tr>
          <tr>
            <td>
              0.5.0
              <br />
              2020-12-26
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  <b>Ważna</b> informacja o uprawnieniach w instrukcji (część
                  "Ogólne)
                </li>
                <li>Nowy komponent: "Pracownicy", szczegóły w instrukcji.</li>
                <li>
                  W instrukcji zacząłem dodawać moje plany na zmiany/rozwój
                  (żółte tło) - gdyby coś było dla Was ważniejsze od innych
                  rzeczy to dawajcie znać.
                </li>
              </ul>

              {user.perm.transactionsList.r ? (
                <>
                  <h3>Transakcje (sprzedaż RLine)</h3>
                  <p>
                    Nowy komponent: "Raport sprzedaży", szczegóły w instrukcji.
                  </p>
                </>
              ) : null}
              {/* <h3>Magazyny</h3> */}
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Poprawki i błędy</h3>
              <ul>
                <li>
                  Wykryty błąd związany z zamykaniem modala, szczegóły w mailu.
                  Poprawka w kolejnej wersji.
                </li>
                {user.perm.transaction.w ? (
                  <li>
                    Poprawiony błąd: "na liście transakcji wyświetlana jest suma
                    bez uwzględnienia kosztów transportu"
                  </li>
                ) : null}
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              0.4.4
              <br />
              2020-12-06
            </td>
            <td>
              {/* <h3>Ogólne</h3> */}

              {/* <h3>Transakcje (sprzedaż RLine)</h3> */}

              {/* <h3>Magazyny</h3> */}

              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              {/* <h3>Poprawki</h3> */}

              {user.type.admin ? (
                <span>
                  <h3>DEV</h3>
                  <p>
                    W transakcjach zmiana sposobu zapisywania daych o userze.
                    było zapisywane czyste userName, teraz jest employee._id.
                    Temat wyszedł przy pracy nad raportem sprzedaży RLine, przy
                    filtrowaniu po użytkownikach. Wszystko powinno być wstecznie
                    kompatybilne, problem będzie przy generowaniu raportu
                    sprzedaży jeśli zmieni się nazwa użytkownika - przeżyję ;).
                  </p>
                </span>
              ) : null}
            </td>
          </tr>
          <tr>
            <td>
              0.4.3
              <br />
              2020-12-06
            </td>
            <td>
              {/* <h3>Ogólne</h3> */}

              {/* <h3>Transakcje (sprzedaż RLine)</h3> */}

              {/* <h3>Magazyny</h3> */}

              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Poprawki</h3>
              <ul>
                <li>
                  Usunięty błąd filtra "archiwum" w wyszkiwarce klientów ze
                  starej bazy: poprawne oznaczanie klienta jako aktywnego (jest
                  faktura lub serwis w ciągu ostatnich dwóch lat) i archiwalnego
                  (jest faktura od 2 do 7 lat wstecz, starsi klienci nie są
                  importowani).
                </li>
                <li>
                  Poprawione przekierowanie do nowego modułu dodawania zużycia
                  materiałów (w przypadku braku uprawnień do dodawania
                  transakcji sprzedaży RLine)
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              0.4.2
              <br />
              2020-11-22
            </td>
            <td>
              <h3>Ogólne</h3>

              <h3>Transakcje (sprzedaż RLine)</h3>

              <h3>Magazyny</h3>
              <p>
                Wszystko napisane od nowa - wygląda podobnie, ale{" "}
                <b>
                  <u>BARDZO proszę</u>
                </b>{" "}
                o uważne przyglądanie się, czy wszystko działa jak powinno.
                <br />
                Nowe komponenty nie są kompatybilne z poprzednimi, czyli np. na
                liście nie będą wyświetlały się zużycia dodane przed zmianą.
                Wszystkie szczegóły opisałem w instrukcji, nowe fragmenty są{" "}
                <span className="bg-secondarySuperLight">
                  oznaczone kolorowym tłem.
                </span>
                <br />
                Nowe/zmienione funkcje:
              </p>
              <ul>
                <li>Dodana możliwość edycji zużycia</li>
                <li>Dodane stany zużycia - "oczekujące" i "zapisane"</li>
                {user.perm.consumptionsList.r ? (
                  <>
                    <li>
                      Możliwość generowania raportów z dowolnego zakresu
                      miesięcy dla wybranego oddziału i/lub kierowcy - zamiast
                      starego komponentu "sumy oddziałowe"
                    </li>
                    <li>
                      Wyliczanie kosztów magazynowych w ww. raporcie na
                      podstawie uśrednionej ceny materiałów
                    </li>
                  </>
                ) : null}
              </ul>
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Poprawki</h3>
              <p>
                Sprzedaż RLine: Poprawiony wydruk potwierdzenia dostarczenia dla
                osoby fizycznej
              </p>
              <p></p>
            </td>
          </tr>
          <tr>
            <td>
              0.4.1
              <br />
              2020-11-03
            </td>
            <td>
              <h3>Ogólne</h3>

              <h3>Transakcje (sprzedaż RLine)</h3>
              <ul>
                <li>
                  Dodana możliwość pobierania danych klienta z bazy starego
                  programu - szczegóły w instrukcji (oznaczone kolorem)
                </li>
                <li>
                  Poprawiony wydruk potwierdzenia dostarczenia na podstawie uwag
                  Mirka i Piotra (niestety potwierdzenie robi się "długie" -
                  przy większej liczbie produktów może się niezbyt estetycznie
                  przełamać na drugą stronę)
                </li>
                <li>Dodanie nowych stanów transakcji - dzięki dla Piotra!</li>
              </ul>
              <p>
                <u>Koniecznie</u> proszę o zapoznanie się z modyfikacjami w
                instrukcji (oznaczone kolorowym tłem)
              </p>
              <h3>Magazyny</h3>
              <p>
                Dodana kolumna "Dni miesiąca" w tabeli Magazyn/Lista - sugestia
                od Adama, dzięki!
              </p>
              {/* <h3>Sprawozdania kwartalne i zezwolenia</h3> */}
              <h3>Poprawki</h3>
              <p>
                Zlokalizowany i (mam nadzieję) naprawiony błąd powodujący
                wyświetlenie alertu o nowej wersji mimo wcześniejszego
                kliknięcia "Zapoznałem się" na liście zmian.
              </p>
              <p></p>
            </td>
          </tr>
          <tr>
            <td>
              0.4.0
              <br />
              2020-10-09
            </td>
            <td>
              <h3>Ogólne</h3>

              <p>
                Poprawiona instrukcja - dodany spis treści z linkami do
                poszczególnych części + zmiany w związku z nowymi funkcjami.
              </p>

              <h3>Transakcje (sprzedaż RLine)</h3>
              <ul>
                <li>
                  Po dodaniu transakcji powiadomienie wysyłane jest także do
                  osoby odpowiedzialnej za faktury w odpowiednim oddziale
                </li>
                <li>Dodana możliwość wydruku potwierdzenia dostarczenia</li>
                <li>Na liście transakcji dodana kolumna "Liczba toalet"</li>
              </ul>
              <h3>Magazyny</h3>
              <p>
                Zablokowane zapisywanie zużycie przy wciśnięciu "Enter" w
                trakcie wprowadzania - teraz jest to możliwe tylko przez
                wciśnięcie guzika "Zapisz".
                <br /> Dodawanie wiersza nadal tylko przez "+"
              </p>
              <h3>Sprawozdania kwartalne i zezwolenia</h3>
              <p>
                Dodany moduł do obsługi zezwoleń i wydruku sprawozdań
                kwartalnych.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              0.3.0
              <br />
              2020-08-28
            </td>
            <td>
              <h3>Ogólne</h3>
              <ul>
                <li>
                  Dodany niniejszy rejestr zmian i alerty po zmianie wersji
                  programu
                  <ul>
                    <li>
                      Alerty będą się pojawiać co jakiś czas do momentu
                      potwierdzenia zapoznania się z najnowszymi zmianami {"->"}{" "}
                      guzik powyżej
                    </li>
                  </ul>
                </li>
              </ul>
              <h3>Transakcje (sprzedaż RLine)</h3>
              <ul>
                <li>Dodane bardziej szczegółowe informacje dot. płatności</li>
                <li>
                  Zmiana układu formularza -{" "}
                  <b>
                    <u>
                      proszę o sugestie jeśli można jeszcze coś poprawić/ułatwić
                    </u>
                  </b>
                </li>
                <li>
                  Dodane statusy transakcji:
                  <ul>
                    <li>
                      Po zapisaniu transakcja otrzymuje status "oczekująca"
                    </li>
                    <li>Po zafakturowaniu {"->"} "zafakturowano"</li>
                  </ul>
                </li>
                <li>
                  Dodana możliwość edycji transakcji -{" "}
                  <b>tylko przy statusie "oczekująca"</b>
                  <ul>
                    <li>
                      Jest możliwość cofnięcia zafakturowanej transakcji, żeby
                      ją edytować, uprawnienia do tego ma Basia, Łukasz i ja
                    </li>
                  </ul>
                </li>
                <li>
                  W tabeli <i>Lista transakcji</i> domyślnie wyświetlają się
                  tylko transakcje "oczekujące", można to przełączyć przyciskiem
                  na górze
                </li>
              </ul>
              <h3>Magazyny</h3>
              <p>
                Kilka zmian ułatwiających wprowadzanie danych, szczegółowo
                opisanych w intrukcji
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default ChangeLog
