import React from "react"

const LogsDetails = ({ log }) => {
  return (
    <div>
      <p>{JSON.stringify(log, null, 2)}</p>
    </div>
  )
}
export default LogsDetails
