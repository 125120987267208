import React, { useState } from "react"
import { Container, Row, Button } from "react-bootstrap"

import CustomersFetch from "../../Fetch"

const AddBuyer = ({ customer, setModalData, refresh }) => {
  const [buyerChoosen, setBuyerChoosen] = useState(null)

  const handleChooseBuyer = async () => {
    try {
      const res = await fetch(`/customers/updateCustomer/${customer._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          historyLength: customer.history?.length || customer.histLength,
          customer: { buyer: buyerChoosen ? buyerChoosen._id : null },
          dirtyFields: { buyer: true },
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Zmiana nabywcy zapisana",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || "Błąd działania programu",
      })
    }
  }
  return (
    <Container fluid>
      <p>
        Aktualny nabywca:{" "}
        {customer.buyer?.shortName || customer.buyer?.name || "brak"}
      </p>

      <p>
        Wybrany nabywca:{" "}
        {buyerChoosen?.shortName || buyerChoosen?.name || "brak"}
      </p>

      <Row>
        <Button
          variant="danger"
          className="ml-5"
          onClick={() => setBuyerChoosen(null)}
        >
          Usuń nabywcę
        </Button>
        <Button
          variant="secondary"
          className="ml-5"
          onClick={handleChooseBuyer}
        >
          Zapisz
        </Button>
      </Row>
      <hr />
      <h5>Wyszukiwarka klientów</h5>
      <CustomersFetch embedded={true} setCustomer={setBuyerChoosen} />
    </Container>
  )
}
export default AddBuyer
