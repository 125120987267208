import React, { useState, useEffect } from "react"
import { useTable, usePagination, useSortBy } from "react-table"
import { useHistory } from "react-router-dom"
import { Table, Container, Button, Row } from "react-bootstrap"
import { MdArrowBack, MdArrowForward } from "react-icons/md"

import { dateParse } from "../utils/formats"

const TransactionsRender = (props) => {
  const history = useHistory()
  const [data, setData] = useState(props.transactions)
  const [columns] = useState([
    {
      Header: "Nazwa/Nazwisko",
      accessor: "customerName",
      Cell: (cell) => {
        if (cell.row.original.customerType === "company")
          return <p>{cell.row.original.companyData.name}</p>
        else if (cell.row.original.customerType === "privat")
          return (
            <p>
              {cell.row.original.privatData.name}{" "}
              {cell.row.original.privatData.surname}
            </p>
          )
      },
    },
    {
      Header: "Data dodania",
      accessor: "createdAt",
      Cell: (cell) => {
        return dateParse(cell.row.original.createdAt)
      },
    },
    {
      Header: "Suma netto",
      Cell: (cell) => {
        let total = Number.parseFloat(
          cell.row.original.orderDetails.netValueTotal
        )
        if (
          cell.row.original.realizationDetails.ourDelivery === "true" ||
          cell.row.original.realizationDetails.ourDelivery === true
        )
          total += Number.parseFloat(
            cell.row.original.realizationDetails.deliveryDetails.netPrice
          )
        return <p>{nF(total)}</p>
      },
    },
    {
      Header: "Liczba toalet",
      Cell: (table) => {
        // type is added on v 0.4.0, for backward compability I had to add filtering also for names
        const toiletsQuantity = table.row.original.orderDetails.prices
          .filter(
            (price) =>
              price.type === "toaleta" ||
              price.name === "Toaleta RLine - niebieska" ||
              price.name === "Toaleta RLine - zielona" ||
              price.name === "Toaleta RLine - inna"
          )
          .reduce((a, b) => a + b.quantity, 0)
        return toiletsQuantity
      },
    },
    { Header: "Oddział", accessor: "realizationDetails.branch" },
    {
      Header: "Pracownik",
      accessor: (row) => row.user?.fullName || row.userName, // due to change in 0.4.4
      id: "userName",
    },
    { Header: "Sprzedawca", accessor: "seller" },
    {
      Header: "Stan",
      accessor: "state",
      // Cell: (cell) => {
      //   const state = cell.row.original.state
      //   if (state === "oczekująca") {
      //     return <p>{state}</p>
      //   } else if (state === "zafakturowana") {
      //     return <p>{state}</p>
      //   } else return null
      // },
    },
  ])

  // refresh data after filters applied in transactionsFetch
  useEffect(() => {
    setData(props.transactions)
  }, [props.transactions])

  const nF = (n) =>
    new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: "PLN",
    }).format(n)
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: [{ id: "createdAt", desc: true }],
      },
    },
    useSortBy,
    usePagination
  )
  return (
    // <div className="transactions-table-container">
    // <table {...getTableBodyProps} className="transactions-table">
    <Container
      fluid
      className={props.embedded ? null : "mx-5"}
      id="transactionsRender"
    >
      <Table bordered className="mt-4">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                  className="pretty-table-header"
                >
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  // when ebedded (for ex in RLineSellReport) -> open in new tab
                  if (props.embedded)
                    return window.open(
                      `/transaction/${row.original._id}`,
                      "_blank"
                    )
                  else return history.push(`/transaction/${row.original._id}`)
                }}
                className="transaction-details-tr"
              >
                {row.cells.map((cell) => {
                  if (
                    cell.column.id === "state" &&
                    cell.value === "oczekująca"
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="bg-warning"
                        // style={{ background: "info" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  } else if (
                    cell.column.id === "state" &&
                    cell.value === "zafakturowana"
                  ) {
                    return (
                      <td {...cell.getCellProps()} className="bg-success">
                        {cell.render("Cell")}
                      </td>
                    )
                  } else if (
                    cell.column.id === "state" &&
                    cell.value === "zaproformowana"
                  ) {
                    return (
                      <td {...cell.getCellProps()} className="bg-successLight">
                        {cell.render("Cell")}
                      </td>
                    )
                  } else if (
                    cell.column.id === "state" &&
                    cell.value === "anulowana"
                  ) {
                    return (
                      <td {...cell.getCellProps()} className="bg-danger">
                        {cell.render("Cell")}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="pretty-table-data"
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
          {props.transactions.length === 0 ? (
            <tr>
              <td colSpan={6}>Brak danych</td>
            </tr>
          ) : null}
        </tbody>
      </Table>
      <Row className="justify-content-end align-items-center">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
          // style={{ maxHeight: "30px" }}
        >
          <MdArrowBack />
        </Button>
        <b>{pageIndex + 1}</b> z {pageOptions.length}
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
        >
          <MdArrowForward />
        </Button>
        {/* below element giving possibility to go to certain page, for now I dont need it */}
        {/* <div>Idź do:</div>
            <input 
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
      </Row>
      <Row className="justify-content-end mt-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          className="mr-4"
        >
          {
            // setting pagination options - how many rows can user display
            [5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Pokaż {pageSize}
              </option>
            ))
          }
        </select>
      </Row>
    </Container>
  )
}
export default TransactionsRender

/*
Nazwa/nazwisko|Data dodania|Suma netto|Oddział|Pracownik|Status
*/
