const entityAdd = async (
  entityName,
  entity,
  customer,
  setFetchError,
  setModalData,
  refresh
) => {
  try {
    const res = await fetch("/customers/addEntity", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        entityName: entityName,
        entity: entity,
        customer_id: customer._id,
      }),
    })
    if (res.status === 403) return setFetchError("Brak uprawnień")
    if (res.status !== 200) return setFetchError("Błąd komunikacji z serwerem")

    return refresh({
      header: "Sukces",
      headerColor: "bg-success",
      body: "Zmiany zapisane",
    })
  } catch (err) {
    console.log(err)
    setFetchError("Błąd działania programu")
  }
}
const entityEdit = async (
  entityName,
  entity,
  dirtyFields,
  historyLength,
  customer,
  setFetchError,
  setModalData,
  refresh
) => {
  try {
    const res = await fetch("/customers/editEntity", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        entityName: entityName,
        entity: entity,
        dirtyFields: dirtyFields,
        historyLength: historyLength,
        customer_id: customer._id,
      }),
    })
    if (res.status === 403) return setFetchError("Brak uprawnień")
    if (res.status === 409)
      return setFetchError(
        "Ktoś w międzyczasie edytował ten wpis, zachowaj dane w innym źródle (word, notatnik, kartka papieru, etc.) i odśwież stronę"
      )
    if (res.status !== 200) return setFetchError("Błąd komunikacji z serwerem")

    return refresh({
      header: "Sukces",
      headerColor: "bg-success",
      body: "Zmiany zapisane",
    })
  } catch (err) {
    console.log(err)
    setFetchError("Błąd działania programu")
  }
}

export { entityAdd, entityEdit }

/*
router.post("/addEntity", customersCtrl.addEntity)
router.put("/editEntity", customersCtrl.editEntity)
*/
