import { useState } from "react"
import { endOfMonth, format, startOfMonth } from "date-fns"
import renderSpinner from "../../utils/renderSpinner"
import { useForm } from "react-hook-form"
import { Row, Col, Button, Form, Alert } from "react-bootstrap"
import InvListDetailsModal from "../../invoicing/InvList/DetailsModal"

const LocationDetailsInvPreviewModal = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [inv, setInv] = useState(null)
  const [errors, setErrors] = useState(null)

  return (
    <div>
      <Alert variant="secondary">
        Podgląd jest generowany dla wszystkich aktywnych usług na lokalizacji
        (niezależnie, czy checkboxy są zaznaczone)
      </Alert>
      <InvPreviewForm
        setInv={setInv}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setErrors={setErrors}
        location={location}
      />
      {isLoading ? renderSpinner("przygotowuję podgląd faktury...") : null}
      {inv ? <InvListDetailsModal invoice={inv} isPreview={true} /> : null}
      {errors ? <Alert variant="danger">{errors}</Alert> : null}
    </div>
  )
}

const InvPreviewForm = ({
  setInv,
  setIsLoading,
  setErrors,
  location,
  isLoading,
}) => {
  //TODO: set startDate to first day of earliest param from all services

  const { register, handleSubmit } = useForm({
    defaultValues: {
      startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    },
  })

  const submit = async (formData) => {
    try {
      setIsLoading(true)
      const res = await fetch("/invoices/make", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: await JSON.stringify({
          customers: [
            {
              customer_id: location.customer._id,
              locations: [
                {
                  location: location,
                  services: location.services.filter(
                    (srv) => srv.state === "aktywna"
                  ),
                },
              ],
            },
          ],
          invParams: {
            invDate: format(new Date(), "yyyy-MM-dd"),
            sellDate: format(new Date(), "yyyy-MM-dd"),
            startDate: formData.startDate,
            endDate: formData.endDate,
            preventSettingInvoicedTo: true,
            preview: true,
          },
        }),
      })

      if (res.status !== 200)
        throw new Error(
          `${res.status} - ${
            (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
          }`
        )

      const resJSON = await res.json()

      if (resJSON.invPreview?.length) {
        const invReady = { ...resJSON.invPreview[0], createdAt: new Date() }
        setInv(invReady)
      } else
        setErrors("program nie wygenerował żadnej faktury dla podanych dat")
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setErrors(`Błąd: ${err.message || "nieokreślony błąd programu"}`)
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)} className="mb-3">
      <Row>
        <Col>
          Data od:
          <Form.Control
            {...register("startDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col>
          Data do:
          <Form.Control
            {...register("endDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Button type="submit" disabled={isLoading}>
          Generuj
        </Button>
      </Row>
    </Form>
  )
}

export { LocationDetailsInvPreviewModal }
