import React from "react"
import { Row, Col } from "react-bootstrap"
import jobsCountQtyExmp from "../../pics/manual/jobsCountQtyExmp.png"
import planningCarsAssign from "../../pics/manual/planningCarsAssign.png"

const ManualJobsSection = (props) => {
  //todo clipMENU import priv: full name as name, change whenever changing something in customer
  //todo type (klasyfikacja) explain
  if (props.user.perm.locations.r)
    return (
      <div>
        <Row>
          <Col>
            <h3>Zadania</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <h4>Przypisywanie samochodów</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            {props.user.perm.logistics.w ? (
              <>
                <p style={{ fontSize: "1.2rem" }} className="bg-dangerLight">
                  W clipERPie układanie tras opiera się na kierowcach (nie
                  samochodach jak w starym programie).
                </p>
                <p>
                  Co prawda można przypisywać samochody do zadań pojedyńczo (w
                  edycji zadania), ale standardowo samochód powinien być
                  przypisany przed wydrukiem kontrolki za pomocą przycisku na
                  karcie "Planowanie/Podsumowanie". <br />
                  <b>
                    Uwaga! użycie tego przycisku powoduje nadpisanie informacji
                    o samochodzie dla wszystkich zadań danego kierowcy.
                  </b>
                </p>
              </>
            ) : null}
          </Col>
          <Col>
            <img src={planningCarsAssign} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h4>Raport zadań</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Generowanie raportu zadań możliwe jest z komponentu "Raporty"{" "}
              {"->"} "Zadania - wydruk"
            </p>
            <p>
              Po ustawieniu filtrów, wybraniu klienta i pobraniu danych
              wyświetlany jest podgląd raportu, aby go wydrukować trzeba kliknąć
              ikonę drukarki.
            </p>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <h5>Zasady liczenia ilości sprzętu</h5>W zależności od usług, jakich
            dotyczyło zadanie, ilość liczona jest w następujący sposób:
            <ul>
              <li>
                usługa sprzętowa
                <ul>
                  <li>
                    sprzęt nienumerowany/templatki - liczona jest ilość podana w
                    parametrach zadania (zrzut ekranu obok)
                  </li>
                  <li>sprzęt numerowany - liczona jest każda sztuka sprzętu</li>
                </ul>
              </li>
              <li>
                usługa bezsprzętowa (np. serwis toalety klienta) - liczona jest
                ilość podana dla parametru aktualnego dla daty zadania
              </li>
            </ul>
          </Col>
          <Col>
            <img src={jobsCountQtyExmp} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
      </div>
    )
  else return null
  //* NOTATKI:
  // else
  //   return (
  //     <div>
  //       <h2>Zadania</h2>
  //       <h3>Wstępniak</h3>

  //       <h3>Dodawanie zadań</h3>
  //       <p>program dodaje zadania do końca roku</p>
  //       <p>
  //         typ - CC - toaleta Clippera (ClipClop), TK - Toaleta klienta. Jeśli
  //         inne typy potrzebne (np montaż ogrodzeń) {"->"} występują częściej niż
  //         kilka razy w roku {"->"} info do mnie
  //       </p>
  //       <p>dni tygodnia - niewymagane przy jednorazowym</p>
  //       <p>
  //         data rozpoczęcia - przy jednorazownym jest to data wykonania. inaczej
  //         program wyszuka pierwszy dzień pasujący do wybranego dnia tygodnia PO
  //         dacie rozpoczęcia i od tego dnia doda zadania {"->"} NIE TRZEBA
  //         WPROWADZAĆ DATY ZGODNEJ Z DNIEM TYGODNIA
  //       </p>
  //       <p>
  //         Data zakończenia - zaznaczyć jeśli znana. Nie musi być w tym roku
  //         (przy przepisywaniu zadań na nowy rok zostanie uwzględniona. Np
  //         rozpoczęcie 2021-01-01, zakończenie 2022-06-01 {"->"} przy generowaniu
  //         dodadzą się serwisy do końca roku, przy przenoszeniu do czerwca
  //       </p>
  //       <p>
  //         WYMAGANE ręczne dodanie dostarczenia i zabrania
  //         <b>może przed RC wdrożę, może nie...</b>
  //       </p>
  //       <h3>Podgląd szczegółów</h3>
  //       <p>Uwagi a uwagi do realizacji</p>
  //       <p>
  //         Uwagi - wpisywane przy dodawaniu zadania, np do kogo dzwonić, zabrać
  //         klucz do bramy itp
  //       </p>
  //       <p>
  //         Uwagi do realizacji - wpisywane przy zmianie stanu (odhaczaniu
  //         kontrolek). Np. brama zamknięta, następnym razem zabrać nitownicę,
  //         klient odwołał telefonicznie dnia XXX, chcą kolejną toaletę. Kiedyś to
  //         będzie jedno z pól dostępnych na eKontrolce.
  //       </p>
  //       <h3>Przypadki zmiany stanu i uprawnienia</h3>

  //       <ol>
  //         <li>Dodanie zadania</li>
  //         <li>Edycja zadania (tylko zaplanowanego)</li>
  //         <li>Usunięcie zadania - odwołanie przed wydrukiem kontrolki</li>
  //         <li>Zlecenie zadania - wydrukowanie kontrolki z danym zadaniem</li>
  //         <li>
  //           Rezygnacja - odwołanie ZLECONEGO ("wydrukowanego") zadania przed
  //           dojazdem na miejsce
  //         </li>
  //         <li>
  //           Niewykonanie - oznaczenie jako niewykonane (z winy naszej lub
  //           klienta)
  //         </li>
  //         <li>Wykonanie - oznaczenie jako wykonane</li>
  //         <li>
  //           Cofanie "zlecone {"->"} zaplanowane" (edycja już wydrukowanej
  //           kontrolki)
  //         </li>
  //         <li>Dowolna zmiana stanu bez żadnych ograniczeń</li>
  //       </ol>
  //       <p>
  //         Podstawowe uprawnienie to dodawanie, edycja i usuwanie zaplanowanych
  //         zadań (1, 2, 3) - wg. mnie powinni mieć wszyscy pracownicy biurowi
  //       </p>
  //       <p>
  //         Poziom wyżej to zlecanie zadań, czyli de facto układanie tras i
  //         drukowanie kontrolek, a także odhaczanie (4, 5, 6, 7).
  //         <br />
  //         <b>
  //           Zwracam uwagę, że tylko z tym uprawnieniem można realizować pkt. 5
  //         </b>
  //         . Łukasz zaznaczał, że jak wrócimy do powiadomień sms/@ to takie
  //         sytuacje będą częste (rezygnacja z serwisu po otrzymaniu przypomnienia
  //         przez klienta). Kusiło mnie, żeby umożliwić to wszystkim pracownikom,
  //         ale wydaje mi się, że takie informacje powinny "przepływać" przez
  //         logistyka.
  //       </p>
  //       <p>
  //         Jeszcze wyżej - możliwość cofnięcia zleconego zadania do zaplanowanego
  //         (czyli edycja kontrolki).
  //         <br />
  //         Na razie wszyscy układający trasy raczej dostaną to uprawnienie, ale
  //         wyobrażam sobie, że kiedyś ktoś z kierowników może chcieć to
  //         zablokować. Np. żeby wiedzieć o wszystkich zamianach w kontrolce.
  //       </p>
  //       <p>
  //         Najwyższe uprawnienie to taki super-użytkownik... czyli kierownik ;).
  //         Może dowolnie zmieniać stan każdego zadania, odblokowując je do edycji
  //         itp.
  //       </p>
  //       <h3>Raport zadań</h3>
  //       <p>
  //         Zasady liczenia ilości: dla templatek i nienumerowanego brana jest
  //         ilość (zrzut ekranu z formularza dodawania zadania?), z numerowanego
  //         liczone są konkkretne ilości, dla usług bezsprzętowych brana jest
  //         ilość z parametru aktualnego dla zadania
  //       </p>
  //     </div>
  //   )
}

export default ManualJobsSection
