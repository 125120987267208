import { format, subDays } from "date-fns"
import { useState, useContext, createContext } from "react"
import { Button, Container, Form, Row } from "react-bootstrap"
import UserContext from "../../contexts/userContext"
import { InventorySettlingRender } from "./Render"
import renderSpinner from "../../utils/renderSpinner"

const InventorySettlingCtx = createContext()

const InventorySettlingFetch = () => {
  const user = useContext(UserContext)

  const [query, setQuery] = useState({
    date: format(subDays(new Date(), 1), "yyyy-MM-dd"),
    branch: user.defaultBranch || user.allowedBranches[0],
  })
  const [jobs, setJobs] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [inventoryFormState, setInventoryFormState] = useState({})

  const fetchJobs = async (e) => {
    try {
      e.preventDefault()
      setIsFetching(true)
      const res = await fetch(
        `/jobs/getByDate/${query.date}?branch=${query.branch}`
      )

      if (res.status !== 200) {
        throw new Error(
          `${res.status} - ${(await res.text()) || "nieokreślony błąd"}`
        )
      }

      const resJSON = await res.json()
      console.log("resJSON: ", resJSON)
      setJobs(resJSON)
      setInventoryFormState(prepareFormState(resJSON))
    } catch (err) {
      console.log("error: ", err)
      alert("Nie udało się pobrać danych")
    } finally {
      setIsFetching(false)
    }
  }

  const handleSave = async () => {
    try {
      /* 
send data to server
location-service-eqp: colors, numbers, types
loc123.srv456.eqp789:{colors:["red", "blue"], numbers:["123", "321"], types:["std", "lux"]}
!use locationSErviceRef!!!
!fields can be empty! Create array with empty strings!

input field name should be: loc123.srv456.eqp789.colors.i etc.
for parsing/accessing value use (example in runjs):
function getValue(obj, path) {
  return path.split('.').reduce((acc, key) => acc[key], obj);
}

#SERVER
to each location.services.equipment add colors, numbers, types fields of array type and put data accordingly
!PRESERVE EMPTY STRINGS!

?WHAT TO DO ON PICKUPS? Probably I should handle removing arrays elements to match eqp.qty

*/
      setIsFetching(true)
      setInventoryFormState({})
      setJobs([])

      const res = await fetch("/locations/writeInventoryToLocations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ inventory: inventoryFormState }),
      })

      if (res.status !== 200) {
        throw new Error(
          `${res.status} - ${(await res.text()) || "nieokreślony błąd"}`
        )
      }

      await fetchJobs({ preventDefault: () => {} })
    } catch (err) {
      console.log("error: ", err)
      alert("Nie udało się pobrać danych")
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <InventorySettlingCtx.Provider
      value={{ jobs, inventoryFormState, setInventoryFormState }}
    >
      <Container className="my-3" fluid>
        <Row>
          <Form onSubmit={(e) => fetchJobs(e)} inline className="mx-auto">
            Dzień:{" "}
            <Form.Control
              type="date"
              value={query.date}
              onChange={(e) =>
                setQuery((prev) => ({ ...prev, date: e.target.value }))
              }
            />
            Oddział:{" "}
            <Form.Control
              as="select"
              value={query.branch}
              onChange={(e) =>
                setQuery((prev) => ({ ...prev, branch: e.target.value }))
              }
            >
              {user.allowedBranches.map((branch) => (
                <option key={branch} value={branch}>
                  {branch}
                </option>
              ))}
            </Form.Control>
            <Button type="submit">Wczytaj</Button>
          </Form>
          <Button variant="secondary" onClick={handleSave}>
            Zapisz
          </Button>
        </Row>
        {isFetching ? (
          renderSpinner("pobieram dane...")
        ) : (
          <Row>{jobs.length ? <InventorySettlingRender /> : "brak danych"}</Row>
        )}
      </Container>
    </InventorySettlingCtx.Provider>
  )
}

const prepareFormState = (jobs) => {
  const formState = {}
  jobs.forEach((job) => {
    job.services.forEach((srv) => {
      // find location service to print all equipment, not only assigned to job
      const locSrv = job.location.services.find(
        (locSrvEl) => locSrvEl._id === srv.locationServiceRef
      )
      if (locSrv.equipment.length === 0) {
        return
      }
      locSrv.equipment.forEach((eqp) => {
        if (
          eqp.ref.type === "Toaleta" &&
          srv.serviceRef.chargeType !== "krótki termin" &&
          srv.serviceRef.chargeType !== "ryczałt impreza"
        ) {
          if (!formState[job.location._id]) {
            formState[job.location._id] = {}
          }
          if (!formState[job.location._id][srv.locationServiceRef]) {
            formState[job.location._id][srv.locationServiceRef] = {}
          }
          if (
            !formState[job.location._id][srv.locationServiceRef][eqp.ref._id]
          ) {
            formState[job.location._id][srv.locationServiceRef][eqp.ref._id] =
              getLocData(eqp)
          }
        }
      })
    })
  })
  console.log("formState: ", formState)
  return formState
}

const getLocData = (eqp) => {
  const inventoryObj = {}

  // when no inventory data in location equipment, create empty arrays
  const props = ["colors", "numbers", "types", "liftSystems"]

  props.forEach((prop) => {
    const defaultVal = prop === "liftSystems" ? false : ""
    if (!eqp[prop]) {
      inventoryObj[prop] = new Array(eqp.qty).fill(defaultVal)
    } else {
      // check if array length matches qty
      // when shorter, fill with empty elements
      if (eqp[prop].length < eqp.qty) {
        const additionalElements = new Array(eqp.qty - eqp[prop].length).fill(
          defaultVal
        )
        inventoryObj[prop] = [...eqp[prop], ...additionalElements]
      }
      // when longer, cut to match qty
      else if (eqp[prop].length > eqp.qty) {
        inventoryObj[prop] = eqp[prop].splice(eqp.qty)
      }
      // when length matches qty set
      else {
        inventoryObj[prop] = eqp[prop]
      }
    }
  })
  return inventoryObj
}

export { InventorySettlingFetch, InventorySettlingCtx }

//TODO
/* 


- [ ] ?don't print inventory cells for Boels and TLC?
- [ ] handle pickups
- [ ] add inventory changing to location
- [ ] add spinner

#opt
- [ ] add inventory changes history


#testing

- [ ] check handling two jobs for the same location - both when done by same driver and two different drivers

#msc

*/
