import React from "react"

import { Button } from "react-bootstrap"

const TransactionsListFilters = (props) => {
  if (props.filterCrit === "all") {
    return (
      <Button
        onClick={() => props.handleFilter("default")}
        className="mt-2"
        style={{ marginLeft: "80%" }}
        variant="secondary"
      >
        Pokaż niezamknięte
      </Button>
    )
  } else if (props.filterCrit === "default") {
    return (
      <Button
        id="showAll"
        onClick={() => props.handleFilter("all")}
        className="mt-2"
        style={{ marginLeft: "80%" }}
        variant="secondary"
      >
        Pokaż wszystkie
      </Button>
    )
  } else return null
}
export default TransactionsListFilters
