import React, { useState } from "react"
import { Button, Row, Spinner } from "react-bootstrap"

const UndoExportConfirmModal = ({ invoice, setModalData, refresh }) => {
  const [isFetching, setIsFetching] = useState(false)

  const undoExport = async () => {
    try {
      setIsFetching(true)
      const res = await fetch("/invoices/undoExport", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          invoice,
        }),
      })

      if (res.status !== 200) {
        throw new Error(`${res.status} - ${await res.text()}`)
      } else {
        refresh()
      }
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd: ${err.message}`,
        onHide: refresh,
      })
    } finally {
      setIsFetching(false)
    }
  }
  return (
    <div>
      <p class="bg-danger p-2 rounded">
        UWAGA!
        <br />
        Zatwierdzenie <u>usunie</u> dokument z programu do fakturowania.
        <br />
        <b>Może to zaburzyć numerację</b> jeśli usuwana faktura nie jest
        ostatnią. W takim przypadku <b>musisz samodzielnie nanieść poprawki.</b>
        <br />
        <br />
        <b>
          Ponowny eksport faktury nie gwarantuje, że będzie miała tan sam numer!
        </b>
      </p>
      <Row className="justify-content-around">
        <Button disabled={isFetching}>
          {isFetching ? <Spinner animation="border" size="sm" /> : "Anuluj"}
        </Button>
        <Button variant="danger" disabled={isFetching} onClick={undoExport}>
          {isFetching ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Rozumiem, usuń eksport"
          )}
        </Button>
      </Row>
    </div>
  )
}
export { UndoExportConfirmModal }
