import React from "react"
import { format, subDays } from "date-fns"
import { useForm } from "react-hook-form"
import { Container, Row, Button, Form, Badge } from "react-bootstrap"

const JobsFixturesAddRender = (props) => {
  const {
    register,
    handleSubmit,

    formState: {
      errors,
    },
  } = useForm({
    defaultValues: {
      date: format(subDays(new Date(), 1), "yyyy-MM-dd"),
      branch: props.user.defaultBranch,
    },
  })
  const submit = async ({ date, branch, type, quantity }) => {
    try {
      const res = await fetch(
        `/jobs/makeFixtures?date=${date}&branch=${branch}&type=${type}&quantity=${quantity}`,
        { method: "POST" }
      )
      if (res.status === 403) return alert("brak uprawnień")
      if (res.status === 500) {
        const resText = await res.body.text()
        return alert(resText)
      }
      if (res.status !== 200) throw res
      else window.location.reload()
    } catch (err) {
      console.log(err)
      alert("no nie pykło :(")
    }
  }
  return (
    <Container>
      <Form onSubmit={handleSubmit(submit)}>
        Data{" "}
        {errors.date && (
          <Badge pill variant="warning">
            Pole wymagane
          </Badge>
        )}
        <Form.Control
          {...register('date', { required: true })}
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off" />
        Typ
        <Form.Control
          {...register('type')}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off">
          {props.jobType.map((type) => (
            <option key={`select-jobType-${type}`} value={type}>
              {type}
            </option>
          ))}
        </Form.Control>
        Oddział
        <Form.Control
          {...register('branch')}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off">
          {props.user.allowedBranches.map((branch) => (
            <option key={`select-branch-${branch}`} value={branch}>
              {branch}
            </option>
          ))}
        </Form.Control>
        Liczba (<span className="bg-warning">NA LOKALIZACJĘ</span>)
        {errors.quantity && (
          <Badge pill variant="warning">
            Pole wymagane
          </Badge>
        )}
        <Form.Control
          {...register('quantity', { max: 100, min: 1, required: true })}
          as="input"
          type="number"
          step="1"
          className=""
          autoComplete="chrome-off" />
        <p className="bg-warning">
          Po zaakceptowaniu wygenerowane zostaną zadania dla każdej lokalizacji.
          Cała reszta parametrów zostanie zaciągnięta z lokalizacji (np.
          kierowca) albo dodana automatycznie (np. w polu Uwagi: "fixtures")
        </p>
        <p>
          Do zadań zostaną dodane wszystkie usługi z lokalizacji z całym
          sprzętem podpiętym pod usługę.
        </p>
        <p>
          UWAGA! Przy generowaniu fixtures pominę lokalizacje, które nie mają
          przypisanego domyślnego kierowcy.
        </p>
        <Row>
          <Button type="submit">Dawaj</Button>
        </Row>
      </Form>
    </Container>
  );
}
export default JobsFixturesAddRender
