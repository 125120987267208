import React, { useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"

const ConsumptionsReportForm = (props) => {
  const [selectedBranch, setSelectedBranch] = useState(false)
  const [drivers, setDrivers] = useState(() => {
    return props.employees.filter((employee) => employee.type.driver)
  })

  // when user selects branch -> set drivers that belongs only to this branch
  useEffect(() => {
    if (!selectedBranch) return // to prevent running on initial render
    if (selectedBranch === "all")
      return setDrivers(() =>
        props.employees.filter((employee) => employee.type.driver)
      )

    const branchDrivers = props.employees.filter((employee) => {
      const employeeBranches = []
      for (let branch in employee.branch) {
        if (employee.branch[branch]) employeeBranches.push(branch)
      }

      return (
        employee.type.driver &&
        employeeBranches.findIndex((branch) => branch === selectedBranch) !== -1
      )
    })
    setDrivers(branchDrivers)
  }, [selectedBranch, props.employees])
  return (
    <Form
      inline
      onSubmit={props.handleSubmit}
      className="mt-3"
      id="ConsumptionsReportForm"
    >
      <Form.Label>Rok:</Form.Label>
      <Form.Control
        type="number"
        name="year"
        className="medium-number-input"
        defaultValue={props.date.year}
      ></Form.Control>
      <Form.Label>Miesiąc OD:</Form.Label>
      <Form.Control
        type="number"
        name="month"
        className="medium-number-input"
        defaultValue={props.date.month}
      ></Form.Control>
      <Form.Label>Miesiąc DO:</Form.Label>
      <Form.Control
        type="number"
        name="month2"
        className="medium-number-input"
        defaultValue={props.date.month}
      ></Form.Control>

      <Form.Label>Oddział:</Form.Label>
      <Form.Control
        as="select"
        name="branch"
        defaultValue="all"
        onChange={(e) => setSelectedBranch(e.target.value)}
      >
        <option value="all">wszystkie</option>
        {Object.keys(props.user.branch).map((branch) => {
          if (props.user.branch[branch])
            return (
              <option value={branch} key={branch}>
                {branch}
              </option>
            )
          else return null
        })}
      </Form.Control>
      <Form.Label>Kierowca:</Form.Label>
      <Form.Control as="select" name="driver" defaultValue="plug">
        <option value="all">wszyscy</option>
        {drivers.map((driver) => (
          <option value={driver._id} key={driver._id}>
            {driver.fullName}
          </option>
        ))}
      </Form.Control>

      <Button type="submit" variant="secondary" id="consReportGetBtn">
        Wczytaj
      </Button>
    </Form>
  )
}
export default ConsumptionsReportForm
