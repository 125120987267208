import React, { useState, useContext, useEffect } from "react"
import UserContext from "../../contexts/userContext"

import { Container, Modal } from "react-bootstrap"

import MyModal from "../../utils/Modals"

import ConsumptionsListForm from "./Form"
import ConsumptionsListRender from "./Render"

const ConsumptionsListFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [consumptions, setConsumptions] = useState()
  const [formDataLoading, setFormDataLoading] = useState(true)
  const [employees, setEmployees] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [date] = useState(() => {
    const today = new Date()
    const year = today.getFullYear()
    if (today.getMonth() === 0 && today.getDate() < 10) {
      return { month: 12, year: today.getFullYear() - 1 }
    } else if (today.getDate() > 10) {
      return { year: year, month: today.getMonth() + 1 }
    } else {
      return { year: year, month: today.getMonth() }
    }
  })

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await fetch("/employees/all", {
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        setEmployees(resJSON)
        setFormDataLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchEmployees()
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const dataToFetch = {
        year: e.target.year.value,
        month: e.target.month.value,
        branch: e.target.branch.value === "all" ? "all" : e.target.branch.value,
        driver: e.target.driver.value === "all" ? "all" : e.target.driver.value,
      }

      const res = await fetch(
        `/consumptions/getFiltered/?month=${dataToFetch.month}&year=${dataToFetch.year}&driver=${dataToFetch.driver}&branch=${dataToFetch.branch}&consumptionsVer=2`,
        {
          method: "GET",
        }
      )
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res

      const resJSON = await res.json()
      setConsumptions(resJSON)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd pobierania danych",
      })
    }
  }
  return (
    <Container>
      {formDataLoading ? (
        <p>Wczytuję dane formularza</p>
      ) : (
        <ConsumptionsListForm
          date={date}
          user={user}
          employees={employees}
          handleSubmit={handleSubmit}
        />
      )}

      {isLoading ? null : (
        <ConsumptionsListRender
          consumptions={consumptions}
          setModalData={setModalData}
        />
      )}
      <Modal
        show={modalData.show}
        onHide={() => setModalData(false)}
        animation={false}
      >
        <MyModal modalData={modalData} setModalData={setModalData} />
      </Modal>
    </Container>
  )
}
export default ConsumptionsListFetch
