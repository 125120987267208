import React from "react"
import { Table, Form } from "react-bootstrap"
const ContactsTable = ({ customer, register, isNew }) => {
  const activeContacts = customer.contacts.filter(
    (ctc) => ctc.state === "aktywny"
  )

  const renderNotePreview = (ctc) => {
    if (!ctc.note) return null
    if (ctc.note.length > 100) return ctc.note.slice(0, 100)
    else return ctc.note
  }
  if (!activeContacts.length || !isNew) return null
  else
    return (
      <>
        <h4>Kontakty do zaimportowania</h4>
        <Table>
          <thead>
            <tr>
              <th colSpan="6">
                Lista <u>aktywnych</u> kontaktów klienta - zaznacz aby dodać do
                lokalizacji
              </th>
            </tr>
            <tr>
              <th>DZ</th>
              <th>Rodzaj</th>
              <th>Imię i nazwisko/nazwa</th>
              <th>Telefon</th>
              <th>Mail</th>
              <th>Notatki</th>
            </tr>
          </thead>
          <tbody>
            {activeContacts.map((ctc) => {
              return (
                <tr key={`custContactsRow-${ctc._id}`}>
                  <td>
                    <Form.Check
                      {...register("custContacts")}
                      type="checkbox"
                      id="custContacts"
                      value={ctc._id}
                    />
                  </td>
                  <td>{ctc.type}</td>
                  <td>{ctc.name}</td>
                  <td>{ctc.phone}</td>
                  <td>{ctc.mail}</td>
                  <td>{renderNotePreview(ctc)}</td>
                </tr>
              )
            })}
            <tr>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </>
    )
}

// rodzaj, imię i nazwisko/nazwa telefon mail notatki
export { ContactsTable }
