import React, { useContext } from "react"
import { Table, Container, Col, Row, Button } from "react-bootstrap"
import { format } from "date-fns"

import { dateParse } from "../../utils/formats"
import { EmployeesDetailsCtx } from "../Details/Fetch"

import { historyModal } from "./modals"
import {
  EmployeesDetailsContractsTable,
  EmployeesDetailsHourlyContractsTable,
} from "./tables"
import EmployeesDetailsEditGeneral from "./Edit/General"

const EmployeesDetailsGeneral = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)
  const calculateEmploymentTime = () => {
    const employmentTime =
      new Date() -
      new Date(
        props.employee.contracts.reduce((acc, curr) => {
          if (new Date(curr.conclusionDate) < acc && curr.state === "aktywna")
            return new Date(curr.conclusionDate)
          else return acc
        }, new Date())
      )

    const oneYear = 1000 * 60 * 60 * 24 * 365
    const oneMonth = 1000 * 60 * 60 * 24 * 30.4167

    return `${Math.floor(employmentTime / oneYear)} l. ${Math.round(
      (employmentTime % oneYear) / oneMonth
    )} m.`
  }

  const activeContracts = {
    normal: ctx.employee.contracts.filter(
      (contract) => contract.state === "aktywna" && !contract.isHourly
    ).length,
    hourly: ctx.employee.contracts.filter(
      (contract) => contract.state === "aktywna" && contract.isHourly
    ).length,
  }

  const renderEditBtn = () => {
    // prepare flag:
    let canEdit = false

    // check if configs allows employees to edit their own data:
    if (ctx.configs.openEmplDataEdit && ctx.employee._id === ctx.user._id)
      canEdit = true
    // check if user is from board:
    else if (ctx.user.type.board && ctx.employee.state !== "archiwum")
      canEdit = true
    // check if user is superior of employee and has perm to edit:
    else if (
      ctx.user.perm.employees.e &&
      ctx.employee.superior.findIndex((sup_id) => sup_id === ctx.user._id) !==
        -1
    )
      canEdit = true

    if (canEdit)
      return (
        <Button
          id="editGeneralBtn"
          onClick={() =>
            props.setModalData({
              show: true,
              type: "info",
              xl: true,
              header: "Edycja danych pracownika",
              hideFooter: true,
              body: (
                <EmployeesDetailsEditGeneral
                  employee={props.employee}
                  allEmployees={props.allEmployees}
                  setModalData={props.setModalData}
                />
              ),
            })
          }
          variant="secondary"
          size="sm"
        >
          Edytuj
        </Button>
      )
    else return null
  }

  return (
    <Container fluid>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="4">
              <Row>
                <Col xs={{ span: 2 }}>Dane pracownika</Col>
                <Col xs={{ offset: 7 }}>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() =>
                      ctx.setModalData(
                        historyModal(
                          ctx.employee.history,
                          "Historia zmian",
                          () => ctx.setModalData({ show: false })
                        )
                      )
                    }
                  >
                    Historia
                  </Button>
                </Col>
                <Col>{renderEditBtn()}</Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Imię</td>
            <td className="fixed-values-table">Nazwisko</td>
            <td className="fixed-values-table">Oddziały</td>
            <td className="fixed-values-table">Stanowisko</td>
          </tr>
          <tr>
            <td>{props.employee.firstName}</td>
            {/* <td>{employeesDetailsCtx.employee.firstName}</td> */}
            <td>{props.employee.lastName}</td>
            <td>
              {Object.keys(props.employee.branch)
                .filter((branch) => props.employee.branch[branch])
                .join(", ")}
            </td>
            <td>
              {props.employee.contracts
                .map((contract) => {
                  if (contract.state === "aktywna") return contract.position
                  else return null
                })
                .filter((position) => position)
                .join(", ")}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Zatrudniony</td>
            <td className="fixed-values-table">Staż</td>
            <td className="fixed-values-table">Przełożeni</td>
            <td className="fixed-values-table" colSpan="1">
              Dzieci
            </td>
          </tr>
          <tr>
            <td>
              {dateParse(
                props.employee.contracts.reduce((acc, curr) => {
                  if (
                    new Date(curr.conclusionDate) < acc &&
                    curr.state === "aktywna"
                  )
                    return new Date(curr.conclusionDate)
                  else return acc
                }, new Date()),
                false
              )}
            </td>
            <td>{calculateEmploymentTime()}</td>

            {/* Show superior(-s), in employee it is stored as array of ids, so have to match from props.allEmployees to get names */}
            <td>
              {props.employee.superior
                .map(
                  (superior_id) =>
                    props.allEmployees.find((empl) => empl._id === superior_id)
                      ?.fullName
                )
                .join(", ")}
            </td>
            <td colSpan="2">{props.employee.kids}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Badania lekarskie - termin</td>
            <td>
              {props.employee.shouldCheckHealth
                ? format(new Date(props.employee.healthCheck), "yyyy-MM-dd")
                : "nd."}
            </td>
            <td className="fixed-values-table">Szkolenie BHP - termin</td>
            <td>
              {props.employee.shouldTrainOsh
                ? format(new Date(props.employee.oshTraining), "yyyy-MM-dd")
                : "nd."}
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="fixed-values-table">
              Główna notatka
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              {props.employee.notes.find(
                (note) => note.type === "główna" && note.state === "aktywna"
              )?.body || <p>Brak notatki</p>}
            </td>
          </tr>
        </tbody>
      </Table>
      {activeContracts.normal !== 0 ? (
        <EmployeesDetailsContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          header="Aktualne umowy miesięczne"
          summary={true}
          filter="aktywna"
          user={props.user}
          employee={props.employee}
        />
      ) : null}
      {activeContracts.hourly !== 0 ? (
        <EmployeesDetailsHourlyContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          header="Aktualne umowy godzinowe"
          summary={true}
          filter="aktywna"
          user={props.user}
          employee={props.employee}
        />
      ) : null}
      {!activeContracts.normal && !activeContracts.hourly && (
        <Table>
          <thead>
            <tr>
              <th>Umowy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brak aktywnych umów</td>
            </tr>
          </tbody>
        </Table>
      )}

      <Table bordered>
        <thead>
          <tr>
            <th colSpan="5">
              Dane kontaktowe - <b>służbowe</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Mail
            </td>
            <td className="fixed-values-table">Telefon</td>
            <td className="fixed-values-table" colSpan="1">
              Nazwa użytkownika clipERP
            </td>
            <td className="fixed-values-table" colSpan="1">
              Wersja programu
            </td>
          </tr>
          <tr>
            <td colSpan="2">{props.employee.mail}</td>
            <td>{props.employee.phone}</td>
            <td>{props.employee.name}</td>
            <td>{props.employee.appVersion}</td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="4">
              Dane kontaktowe - <b>prywatne</b>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Mail
            </td>
            <td className="fixed-values-table">Telefon</td>
            <td className="fixed-values-table">PESEL</td>
          </tr>
          <tr>
            <td colSpan="2">{props.employee.privData?.mail}</td>
            <td>{props.employee.privData?.phone}</td>
            <td>{props.employee.privData?.pesel}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Adres
            </td>
            <td className="fixed-values-table">Kod</td>
            <td className="fixed-values-table">Urząd skarbowy</td>
          </tr>
          <tr>
            <td colSpan="2">{props.employee.privData?.street}</td>
            <td>{props.employee.privData?.postal}</td>
            <td className="keep-br" rowSpan="3" id="taxOffice">
              {props.employee.privData?.taxOffice}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Miasto
            </td>
          </tr>
          <tr>
            <td colSpan="3">{props.employee.privData?.city}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Nr. konta
            </td>
            <td className="fixed-values-table">Bank</td>
          </tr>
          <tr>
            <td colSpan="3">{props.employee.privData?.bankAccount}</td>
            <td>{props.employee.privData?.bankName}</td>
          </tr>
        </tbody>
      </Table>
      <div id="emplHistoryLength" style={{ color: "white" }}>
        {props.employee.history.length}
      </div>
    </Container>
  )
}
export default EmployeesDetailsGeneral
