import React, { useEffect, useState } from "react"
import "./App.scss"

// import "bootstrap/dist/css/bootstrap.min.css"
// import "./custom.scss"

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import { useCookies } from "react-cookie"
import MyModal from "./comp/utils/Modals"
import checkVersion from "./utils/checkVersion"

import UserContext from "./comp/contexts/userContext"
import MetaContext from "./comp/contexts/metaContext"

import ErrorBoundary from "./utils/errorBoundary"

import Login from "./comp/users/login"
import NavBar from "./comp/nav/navBar"
import ConfigNav from "./comp/nav/configNav"
import Desktop from "./comp/nav/desktop"
import TestsParent from "./comp/utils/Tests/Parent"
import TransactionsFetch from "./comp/Transactions/transactionsFetch"
import TransactionDetailsFetch from "./comp/Transactions/Details/Fetch"
import TransactionsEditFetch from "./comp/Transactions/Edit/Fetch"
import EmployeesManage from "./comp/users/employeesManage"
import ConsumptionAddFetch from "./comp/warehouse/consumptionAddFetch"
import ConsumptionsTabFetch from "./comp/warehouse/consumptionsTabFetch"
import BranchConsumptionFetch from "./comp/warehouse/branchConsumptionFetch"
import GoodsListFetch from "./comp/warehouse/goodsListFetch"

// consumptions - new
import ConsumptionsEditFetch from "./comp/Consumptions/Edit/Fetch"
import ConsumptionsListFetch from "./comp/Consumptions/List/Fetch"
import ConsumptionsDetailsFetch from "./comp/Consumptions/Details/Fetch"
import ConsumptionsReportFetch from "./comp/Consumptions/Report/Fetch"

//sewage:
import SewagePermissionsFetch from "./comp/Sewage/Permissions/Fetch"
import SewagePermissionDetailsFetch from "./comp/Sewage/PermissionDetails/Fetch"
import { SewageReport_v2TableFetch } from "./comp/Sewage/Report_v2/table/fetch.js"

//customers:
import CustomersFetch from "./comp/Customers/Fetch"
import { CustomersDetailsFetch } from "./comp/Customers/Details/Fetch"
// import CustomersEditFetch from "./comp/Customers/Edit/Fetch"

//    clipMenu customers:
import CustomersClipMenuDetailsFetch from "./comp/Customers/ClipMenuDetails/Fetch"

// employees:
import { EmployeesDetailsFetch } from "./comp/Employees/Details/Fetch"
import EmployeesListFetch from "./comp/Employees/List/Fetch"

//reports:
//    RLine sell:
import RLineSellReportFetch from "./comp/reports/RLineSellReport/Fetch"
import JobsPrintFetch from "./comp/reports/JobsPrint/Fetch"
import IncomeReportFetch from "./comp/reports/Income/Fetch"
import { BranchesStatsFetch } from "./comp/reports/BranchesStats/fetch"
import { DailyStatsFetch } from "./comp/reports/DailyStats/Fetch"
import { EqpCompliance } from "./comp/reports/EqpCompliance/EqpCompliance"
import { LocationsByDistanceFetch } from "./comp/reports/LocationsByDistance/Fetch"
import { EqpChangeReportFetch } from "./comp/reports/EqpChange/Fetch"

// #Locations:
import { LocationsDetailsFetch } from "./comp/Location/Fetch"
import LocationsListFetch from "./comp/LocationsList/Fetch"

//# Equipment
import EquipmentTableFetch from "./comp/Equipment/Table/Fetch"

//# Logistics
import { RoutesPlanningFetch } from "./comp/logistics/RoutesPlanning/Fetch"
import LogisticsUtilsNav from "./comp/logistics/utils/LogisticsUtilsNav"
import { RoutesSettlingFetch } from "./comp/logistics/RoutesSettling/Fetch"
import { InventorySettlingFetch } from "./comp/logistics/InventorySettling/Fetch.js"

//# Invoicing
import InvListFetch from "./comp/invoicing/InvList/Fetch"
import NewInvoice from "./comp/invoicing/EditInvoice/NewInvoice"

//# Cars
import CarsTableFetch from "./comp/cars/CarsTableFetch"
import { CarsWorksFetch } from "./comp/cars/works/fetch"

//other:
import Manual from "./doc/manual/Manual"
import Tech from "./doc/tech"
import ChangeLog from "./doc/ChangeLog"
import Footer from "./comp/footer/footer"
import SendMail from "./comp/utils/sendMail"
import ErrorsLogFetch from "./comp/Logs/Errors/LogFetch"
import { PermTable } from "./doc/tables" //used in nav bar when admin && devEnv to have quick look on perms
import ServicesManageTableFetch from "./comp/admin/ServicesManage/TableFetch"
import LogsFetch from "./comp/admin/Logs/Fetch"
import TodosListFetch from "./comp/todos/list/fetch"
import TodosDetailsFetch from "./comp/todos/details/fetch"
import { DebtCollection } from "./comp/DebtCollection/DebtCollection"

import renderSpinner from "./comp/utils/renderSpinner"
import { InvStatsFetch } from "./comp/invoicing/InvStats/Fetch"

function App() {
  const [user, setUser] = useState()
  const [meta, setMeta] = useState() // to use in context provider, that stores meta-data (app version, devEnv etc)
  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [cookies] = useCookies()
  const [redirect, setRedirect] = useState(false)
  const [verOverdated, setVerOverdated] = useState(false)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (cookies.jwt === "undefined" || cookies.jwt === undefined) {
          setUser(undefined)
          setMeta(undefined)
          setIsLoading(false)
          return
        }
        const res = await fetch("/checkJWT/")
        if (res.status === 401) {
          setUser(null)
          return setIsLoading(false)
        }
        if (res.status === 403)
          return alert("Brak dostępu, skontaktuj się z administratorem")
        const resJSON = await res.json()
        if (res.status === 200) {
          setUser(resJSON.employee)
          setMeta(resJSON.meta)

          // set favicon -->
          if (resJSON.meta.devEnv)
            document.getElementById("favicon").href = "/favicon-dev.ico"
          else document.getElementById("favicon").href = "/favicon.ico"
          //<--

          if (resJSON.meta.version !== resJSON.employee.appVersion) {
            checkVersion(
              resJSON.meta.version,
              resJSON.employee.appVersion,
              resJSON.meta.versionDate,
              setModalData,
              setVerOverdated,
              setRedirect
            )
          }

          setIsLoading(false)
        } else {
          setUser(undefined)
          setMeta(undefined)
          setIsLoading(false)
        }
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    fetchUser()
  }, [cookies])

  const compRouters = () => {
    // when user didn't submit that he acknowleged changes for more then 7 days, allow only manual (and configNav to get to manual)
    // all other requests should be redirected to changeLog
    if (verOverdated)
      return (
        <Switch>
          <Route path="/manual" exact component={Manual} />
          <Route path="/config" exact component={ConfigNav} />
          <Route path="/" component={ChangeLog} />
        </Switch>
      )
    return (
      <>
        <Route
          path="/"
          exact
          // component={
          //   user.perm.transaction.w ? TransactionAddFetch : ConsumptionAddFetch
          // }
          render={(props) => {
            if (user.configs.showDesktop) return <Desktop />
            if (user.perm.transaction.w)
              return <TransactionsEditFetch {...props} newTransaction={true} />
            if (!user.perm.transaction.w)
              return <ConsumptionsEditFetch isNew={true} />
          }}
        />
        <Route
          path="/AddTransaction"
          exact
          render={(props) => (
            <TransactionsEditFetch {...props} newTransaction={true} />
          )}
        />
        <Route path="/transactions/" exact component={TransactionsFetch} />
        <Route
          path="/transaction/:id"
          exact
          render={(props) => <TransactionDetailsFetch {...props} />}
        />
        <Route
          path="/transactionEdit/:id"
          exact
          render={(props) => <TransactionsEditFetch {...props} />}
        />

        <Route path="/config" exact component={ConfigNav} />
        <Route path="/manual" exact component={Manual} />
        <Route path="/tech" exact component={Tech} />
        <Route path="/ChangeLog" exact component={ChangeLog} />
        <Route path="/tests" exact component={TestsParent} />
        <Route path="/consumption-old" exact component={ConsumptionAddFetch} />
        <Route
          path="/consumptionEdit/:id"
          exact
          component={ConsumptionsEditFetch}
        />
        <Route
          path="/consumption/:id"
          exact
          component={ConsumptionsDetailsFetch}
        />
        <Route path="/consumptionsTab" exact component={ConsumptionsTabFetch} />
        <Route
          path="/consumptionsList"
          exact
          component={ConsumptionsListFetch}
        />
        <Route
          path="/consumptionsReport"
          exact
          component={ConsumptionsReportFetch}
        />
        <Route
          path="/branchConsumption" // OLD
          exact
          component={BranchConsumptionFetch}
        />
        <Route path="/goodsList" exact component={GoodsListFetch} />
        <Route
          path="/SewagePermissions"
          exact
          component={SewagePermissionsFetch}
        />
        <Route
          path="/SewagePermissionDetails/:_id"
          exact
          component={SewagePermissionDetailsFetch}
        />

        <Route
          path="/SewageReports_v2"
          exact
          component={SewageReport_v2TableFetch}
        />

        {/* CUSTOMERS: */}
        <Route path="/CustomersList" exact component={CustomersFetch} />
        <Route
          path="/CustomersDetails/:_id"
          exact
          component={CustomersDetailsFetch}
        />
        {/* <Route
          path="/CustomersEdit/:_id"
          exact
          component={CustomersEditFetch}
        /> */}

        {/* clipMenu CUSTOMERS - not in nav bar, working only after selecting customer on customers list */}
        <Route
          path="/CustomersClipMenuDetails/:_id"
          exact
          component={CustomersClipMenuDetailsFetch}
        />

        {/* EMPLOYEES */}
        <Route path="/Employees/List" exact component={EmployeesListFetch} />
        <Route
          path="/Employees/Details/:_id"
          exact
          component={EmployeesDetailsFetch}
        />

        {/* REPORTS */}

        <Route
          path="/Reports/RLineSell"
          exact
          component={RLineSellReportFetch}
        />
        <Route path="/Reports/JobsPrint" exact component={JobsPrintFetch} />
        <Route path="/Reports/Income" exact component={IncomeReportFetch} />
        <Route
          path="/Reports/BranchesStats"
          exact
          component={BranchesStatsFetch}
        />
        <Route path="/Reports/DailyStats" exact component={DailyStatsFetch} />
        <Route path="/Reports/EqpCompliance" exact component={EqpCompliance} />
        <Route
          path="/Reports/LocationsByDistance"
          exact
          component={LocationsByDistanceFetch}
        />
        <Route
          path="/Reports/EqpChangeReport"
          exact
          component={EqpChangeReportFetch}
        />

        {/* LOCATIONS */}

        <Route path="/Locations/:_id" exact component={LocationsDetailsFetch} />
        <Route path="/LocationsList" exact component={LocationsListFetch} />

        {/* EQUIPMENT */}

        <Route path="/EquipmentTable" exact component={EquipmentTableFetch} />

        {/* LOGISTICS */}

        <Route path="/RoutesPlanning" exact component={RoutesPlanningFetch} />
        <Route path="/LogisticsUtils" exact component={LogisticsUtilsNav} />
        <Route path="/RoutesSettling" exact component={RoutesSettlingFetch} />
        <Route
          path="/InventorySettling"
          exact
          component={InventorySettlingFetch}
        />

        {/* INVOICING */}

        <Route path="/Invoicing/List" exact component={InvListFetch} />
        <Route path="/Invoicing/New" exact component={NewInvoice} />
        <Route path="/Invoicing/Stats" exact component={InvStatsFetch} />

        {/* CARS */}
        <Route path="/CarsTable" exact component={CarsTableFetch} />
        <Route path="/CarsWorks" exact component={CarsWorksFetch} />

        {/* OTHER */}

        <Route path="/employees" exact component={EmployeesManage} />
        <Route path="/send-mail" exact component={SendMail} />
        <Route path="/errorsLog" exact component={ErrorsLogFetch} />
        <Route path="/permTable" exact component={PermTable} />
        <Route
          path="/ServicesManageTable"
          exact
          component={ServicesManageTableFetch}
        />
        <Route path="/Logs" exact component={LogsFetch} />
        <Route path="/Todos" exact component={TodosListFetch} />
        <Route path="/Todos/:_id" exact component={TodosDetailsFetch} />
        <Route path="/DebtCollection" exact component={DebtCollection} />
      </>
    )
  }

  const renderApp = () => {
    if (isLoading) {
      return renderSpinner()
    } else {
      return (
        <UserContext.Provider value={user}>
          <MetaContext.Provider value={meta}>
            <ErrorBoundary setModalData={setModalData}>
              <Router>
                {redirect ? <Redirect to={redirect} /> : null}
                <div className="App">
                  <header className="App-header sticky-top">
                    <NavBar />
                  </header>
                  <Switch>
                    <Route path="/login" exact component={Login} />
                    {!user ? <Login /> : compRouters()}
                  </Switch>
                  {!user ? null : <Footer setModalData={setModalData} />}

                  <div style={{ height: "50px" }}>
                    {/* prevent footer from hiding stuff */}
                  </div>
                </div>
              </Router>
              <MyModal modalData={modalData} setModalData={setModalData} />
            </ErrorBoundary>
          </MetaContext.Provider>
        </UserContext.Provider>
      )
    }
  }
  return renderApp()
}

export default App
