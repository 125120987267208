import { format, startOfTomorrow, addDays } from "date-fns"

const getServiceDefaultValues = (srv, location, isNew) => {
  let defaultValues = {}

  if (isNew) {
    defaultValues = {
      vat: srv.defaultVat,
      invLine: srv.defaultInvLine,
      netPrice: srv.defaultNetPrice || null,
      incomeCat: srv.defaultIncomeCat || "Toalety",
      params: [
        {
          qty: 1,
          startDate: format(startOfTomorrow(new Date()), "yyyy-MM-dd"),
          hasEndDate: srv.chargeType === "ryczałt impreza" ? true : false,
        },
      ],
    }

    if (srv.chargeType === "ryczałt impreza") {
      defaultValues.hasEndDate = true
      defaultValues.params[0].endDate = format(
        addDays(new Date(), 7),
        "yyyy-MM-dd"
      )
    }

    if (srv.chargeType === "krótki termin") defaultValues.initPeriod = 7

    // for non short term set "add delivery" as default (as long as service needs eqp and there are coords added)
    if (
      srv.chargeType !== "krótki termin" &&
      srv.chargeType !== "ryczałt impreza" &&
      location.coords.coordinates.length &&
      srv.mustHaveEqp
    )
      defaultValues.addDelivery = true

    // for "za serwis" set qty to 1
    if (srv.chargeType === "za serwis") defaultValues.params[0].qty = 1
  } else {
    defaultValues = { ...srv }

    defaultValues.params[0].startDate = format(
      new Date(defaultValues.params[0].startDate),
      "yyyy-MM-dd"
    )

    if (defaultValues.params[0].hasEndDate && defaultValues.params[0].endDate)
      defaultValues.params[0].endDate = format(
        new Date(defaultValues.params[0].endDate),
        "yyyy-MM-dd"
      )

    // use income category
    if (defaultValues.incomeCat && defaultValues.incomeCat.length) {
      defaultValues.incomeCat = defaultValues.incomeCat[0].name
    } else defaultValues.incomeCat = "Toalety"
  }

  return defaultValues
}

export { getServiceDefaultValues }
