import { OverlayTrigger, Popover } from "react-bootstrap"

const cutString = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text
  }
  if (text.length > maxLength) {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>{text}</Popover.Content>
      </Popover>
    )

    return (
      <OverlayTrigger trigger={["hover", "focus"]} overlay={popover}>
        <div>{text.substring(0, maxLength) + " [...]"}</div>
      </OverlayTrigger>
    )
  }
}
export { cutString }
