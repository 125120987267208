import React, { useContext, useState } from "react"
import { Row, Table, Button, Form, Alert, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { LocationCtx } from "../../Fetch"

const LocationsDetailsEditContactModal = (props) => {
  const { setModalData, location, refresh } = useContext(LocationCtx)
  const [fetchError, setFetchError] = useState(null)
  const [customValidationError, setCustomValidationError] = useState()

  const { isNew } = props

  const { handleSubmit, register, getValues, formState } = useForm({
    defaultValues: isNew
      ? {
          name: null,
          type: null,
          phone: null,
          mail: null,
          fax: null,
          note: null,
        }
      : { ...props.contact },
  })

  const { errors } = formState

  const { dirtyFields } = formState

  const submit = async (contact) => {
    try {
      // when changing type to "główny" check if there is no other:
      if (
        contact.type === "główny" &&
        location.contacts.findIndex(
          (ctc) =>
            ctc.state === "aktywny" &&
            ctc.type === "główny" &&
            ctc._id !== props.contact?._id
        ) !== -1
      )
        return setCustomValidationError(
          <p>
            Może być tylko jeden główny kontakt dla lokalizacji. <br />
            Albo edytuj obecny kontakt główny, albo zmień jego typ na jakiś
            inny. <br />
            <b>
              Żeby nie stracić danych możesz zapisać ten kontakt tymczasowo jako
              'inny'
            </b>
          </p>
        )
      const body = {
        data: isNew
          ? {
              ...contact,
              state: "aktywny",
            }
          : {
              ...contact,
              _id: props.contact._id,
              state: props.contact.state,
            },

        dirtyFields: dirtyFields,
        historyLength: isNew ? 0 : props.contact.history.length,
      }

      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=${
          isNew ? "true" : "false"
        }&entity=contacts`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status === 409)
        return setFetchError(
          "UWAGA! Próbujesz zapisać zmianę w danych, które ktoś w międzyczasie edytował. Żeby to zrobić musisz najpierw odświeżyć stronę, żeby pobrać najnowsze informacje. Zapisz wprowadzone dane (na kartce, w notatniku czy w wordzie - wszystko jedno), potem wciśnij jednocześnie 'ctrl + R' żeby pobrać śiweżutkie dane."
        )
      if (res.status !== 200) {
        console.log(res)
        return setFetchError("Błąd komunikacji z serwerem")
      }

      return refresh({
        header: "Sukces",
        headerColor: "bg-success",
        body: `Kontakt zapisany`,
      })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd zapisywania danych")
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">
              Typ{" "}
              {errors.type && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
            <td className="fixed-values-table" colSpan="2">
              Imię i nazwisko/nazwa{" "}
              {errors.name && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("type", { required: true })}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
              >
                <option value="główny">główny</option>
                <option value="dyżurny">dyżurny</option>
                <option value="na kontrolkę">na kontrolkę</option>
                <option value="inny">inny</option>
              </Form.Control>
            </td>
            <td colSpan="2">
              <Form.Control
                {...register("name")}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">
              Mail{" "}
              {errors.mail?.type === "atLeastOneContact" && (
                <Badge pill variant="warning">
                  Wpisz przynajmniej jedno z: mail, telefon, fax
                </Badge>
              )}
              {errors.mail?.type === "pattern" && (
                <Badge pill variant="warning">
                  Błędny mail
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">
              Telefon{" "}
              {errors.mail?.type === "atLeastOneContact" && (
                <Badge pill variant="warning">
                  inaczej to nie jest "kontakt" ;)
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">
              Fax{" "}
              {errors.mail?.type === "atLeastOneContact" && (
                <Badge pill variant="warning">
                  ewentualnie dodaj notatkę do lokalizacji?
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("mail", {
                  validate: {
                    atLeastOneContact: () =>
                      Object.values(getValues(["mail", "phone", "fax"])).join(
                        ""
                      ).length !== 0,
                  },
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td>
              <Form.Control
                {...register("phone")}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td>
              <Form.Control
                {...register("fax")}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Notatka
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Form.Control
                {...register("note")}
                as="textarea"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
        </tbody>
      </Table>
      {customValidationError && (
        <Alert variant="warning">{customValidationError}</Alert>
      )}
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Row className="justify-content-between">
        <Button
          variant="warning"
          className="ml-3"
          onClick={() => setModalData({ show: false })}
        >
          Anuluj
        </Button>
        <Button variant="secondary" className="mr-3" type="submit">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export default LocationsDetailsEditContactModal
