import React, { useState, useEffect } from "react"

import { Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"

import LogsForm from "./Form"
import LogsRender from "./Render"

const LogstFetch = () => {
  const [modalData, setModalData] = useState({ show: false })
  const [employees, setEmployees] = useState(null)
  const [logs, setLogs] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("/employees/all")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setEmployees(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [])

  return (
    <Container fluid>
      {employees && (
        <LogsForm
          employees={employees}
          setIsLoading={setIsLoading}
          setLogs={setLogs}
          setModalData={setModalData}
        />
      )}
      {isLoading ? (
        "pobieram dane"
      ) : (
        <LogsRender logs={logs} setModalData={setModalData} />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default LogstFetch
