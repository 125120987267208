import React, { useContext, useState, useEffect } from "react"
import _ from "lodash"
import { isBefore, differenceInCalendarDays } from "date-fns"
import { Col, Row, Form, Button, Table, Alert, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { LocationCtx } from "../Fetch"

import LocationsDetailsEqpFetch from "./Eqp/LocationsDetailsEqpFetch"
import { getServiceDefaultValues } from "./utils"
import { createManualBtn } from "../../utils/buttons"

const LocationsDetailsServiceEditModal = ({
  serviceChoosen,
  isNew,
  setStep,
}) => {
  const { location, setModalData, refresh } = useContext(LocationCtx)
  const [fetchError, setFetchError] = useState(null)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: getServiceDefaultValues(serviceChoosen, location, isNew),
  })

  // clear endDate when hasEndDate is unchecked:
  const watchHasEndDate = watch("params.0.hasEndDate")
  useEffect(() => {
    if (!watchHasEndDate) setValue("params.0.endDate", null)
  }, [watchHasEndDate, setValue])

  const submit = async (service) => {
    try {
      const body = {
        data: {
          ...service,
          invLine: service.invLine,
          mustHaveJobs: serviceChoosen.mustHaveJobs,
          mustHaveEqp: serviceChoosen.mustHaveEqp,
          type: serviceChoosen.type,
          name: serviceChoosen.name,
          chargeType: serviceChoosen.chargeType,
          state: "aktywna",
          eqpTypes: serviceChoosen.eqpTypes,
          unit: serviceChoosen.unit,
          custEqp: serviceChoosen.custEqp,
          canHaveSubSrv: serviceChoosen.canHaveSubSrv,
          canBeSubSrv: serviceChoosen.canBeSubSrv,
        },
      }

      if (
        serviceChoosen.chargeType === "za serwis" &&
        service.params[0].qty !== 1
      )
        return setFetchError(
          "Usługa 'za serwis' nie może mieć liczby innej niż 1"
        )

      //add income category:
      body.data.incomeCat = [{ name: service.incomeCat, share: 100 }]

      if (isNew) {
        body.data.serviceRef = serviceChoosen._id
        // set service ordinal number (add 1 to max of all locations No or set to 1)
        body.data.locSrvNo =
          _.max(
            location.services
              .map((locSrv) => locSrv.locSrvNo)
              .filter((el) => el)
          ) + 1 || 1
      }
      // check dates

      // delete tech 'addDelivery' property
      delete body.data.addDelivery

      // set time to noon:
      body.data.params[0].startDate = `${service.params[0].startDate}T12:00`

      if (body.data.params[0].hasEndDate)
        body.data.params[0].endDate = `${service.params[0].endDate}T12:00`

      // add dirty fields if editing service
      if (!isNew) body.dirtyFields = dirtyFields

      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=${isNew}&entity=services`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status !== 200) {
        console.log(res)
        return setFetchError("Błąd komunikacji z serwerem")
      }
      if (service.addDelivery || serviceChoosen.custEqp) {
        const resJSON = await res.json()

        setModalData({
          show: true,
          type: "info",
          xl: true,
          header: service.custEqp
            ? "Wybierz sprzęt klienta"
            : "Wybierz sprzęt do dostarczenia",
          onHide: refresh,
          body: (
            <LocationsDetailsEqpFetch
              location={location}
              setModalData={setModalData}
              refresh={refresh}
              srv={resJSON}
              isDelivery={true}
            />
          ),
        })
      } else
        setModalData({
          show: true,
          type: "info",
          header: "Sukces",
          headerColor: "success",
          body: "Dopisano usługę",
          onHide: refresh,
        })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd zapisywania danych")
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      {serviceChoosen.chargeType === "krótki termin" ||
      serviceChoosen.chargeType === "ryczałt impreza" ? (
        <ShortTermServiceForm
          serviceChoosen={serviceChoosen}
          register={register}
          errors={errors}
          watch={watch}
          location={location}
        />
      ) : (
        <StandardServiceForm
          serviceChoosen={serviceChoosen}
          register={register}
          errors={errors}
          watch={watch}
          location={location}
        />
      )}
      <Row>
        <Col>
          <Row className="justify-content-around">
            <Button
              variant="warning"
              onClick={() => setModalData({ show: false })}
            >
              Anuluj
            </Button>
            {isNew ? (
              <Button onClick={() => setStep("chooseService")}>
                Wróć do wyboru
              </Button>
            ) : null}
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <Button
              variant="secondary"
              type="submit"
              className="mr-3"
              id="submitSrv"
            >
              {/* add delivery when user chooses to, or when service with customerEqp */}
              {watch("addDelivery") || serviceChoosen.custEqp
                ? "Zapisz i dodaj sprzęt"
                : "Zapisz"}
            </Button>
          </Row>
        </Col>
      </Row>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
    </Form>
  )
}

const StandardServiceForm = ({
  serviceChoosen,
  register,
  errors,
  watch,
  location,
}) => {
  const incomeCategories = [
    "Toalety",
    "Sprzęt",
    "Kontenery",
    "Ogrodzenia",
    "Toalety klienta",
    "Szambo",
    "Sprzątanie",
    "Sprzedaż",
    "Transport",
    "Inna",
  ]

  return (
    <Table>
      <tbody>
        <tr>
          <td className="fixed-values-table">Nazwa</td>
          <td className="fixed-values-table">Typ</td>
          <td className="fixed-values-table">Naliczenie</td>
          <td className="fixed-values-table">jm.</td>
          <td className="fixed-values-table">Kat. przychodu</td>
          <td className="fixed-values-table">Dodaj dostarczenie</td>
        </tr>
        <tr>
          <td>{serviceChoosen.name}</td>
          <td>{serviceChoosen.type}</td>
          <td>{serviceChoosen.chargeType}</td>
          <td>{serviceChoosen.unit}</td>
          <td>
            {errors.incomeCat && (
              <Badge pill variant="warning">
                {errors.incomeCat.message}
              </Badge>
            )}
            <Form.Control
              {...register("incomeCat", { required: "pole wymagane" })}
              as="select"
              className=""
              autoComplete="chrome-off"
            >
              {incomeCategories.map((cat) => (
                <option key={`incomeCatOpt-${cat}`} value={cat}>
                  {cat}
                </option>
              ))}
            </Form.Control>
          </td>
          <td>
            {location.coords.coordinates.length ? null : (
              <Badge pill>
                Aby dodać dostarczenie najpierw wprowadź współrzędne
              </Badge>
            )}
            {serviceChoosen.mustHaveEqp ? (
              <Form.Check
                {...register("addDelivery")}
                type="checkbox"
                label="tak"
                id="addDelivery"
                disabled={location.coords.coordinates.length ? false : true}
              />
            ) : (
              "nie dotyczy"
            )}
          </td>
        </tr>
        <tr>
          <td className="fixed-values-table">
            Cena netto{" "}
            {errors.netPrice && (
              <Badge pill variant="warning">
                Wpisz dodatnią wartość
              </Badge>
            )}
          </td>
          <td className="fixed-values-table">
            Vat{" "}
            {errors.vat && (
              <Badge pill variant="warning">
                Wpisz dodatnią wartość
              </Badge>
            )}
          </td>
          <td className="fixed-values-table">Cena brutto</td>
          <td className="fixed-values-table">
            Liczba{" "}
            {errors.params && errors.params[0]?.qty && (
              <Badge pill variant="warning">
                Wpisz dodatnią wartość
              </Badge>
            )}
          </td>
          <td className="fixed-values-table">Od</td>
          <td className="fixed-values-table">
            <Form.Check
              inline
              {...register("params.0.hasEndDate")}
              type="checkbox"
              label=""
              id="hasEndDate"
            />{" "}
            Do{" "}
            {errors.params && errors.params[0]?.endDate && (
              <Badge pill variant="warning">
                Błąd!
              </Badge>
            )}
            {errors.params && errors.params[0]?.endDate?.type === "exists" && (
              <Badge pill variant="warning">
                Wprowadź datę zakończenia
              </Badge>
            )}
            {errors.params &&
              errors.params[0]?.endDate?.type === "greaterThenStartDate" && (
                <Badge pill variant="warning">
                  Data DO musi być większa lub równa dacie OD!
                </Badge>
              )}
          </td>
        </tr>
        <tr>
          <td>
            <Form.Control
              {...register("netPrice", {
                validate: (v) => {
                  if (v <= 0) return false
                  else return true
                },
              })}
              as="input"
              type="number"
              className=""
              step="any"
            />
          </td>
          <td>
            <Form.Control
              {...register("vat", {
                required: "Pole wymagane",
                validate: (v) => {
                  if (v < 0) return false
                  else return true
                },
              })}
              as="input"
              type="number"
              className=""
              autoComplete="chrome-off"
            />
          </td>
          <td>
            {_.round(
              Number.parseFloat(watch("netPrice")) *
                ((Number.parseFloat(watch("vat")) + 100) / 100),
              2
            ) || 0}
          </td>
          <td>
            <Form.Control
              {...register("params.0.qty", {
                validate: (v) => {
                  if (v <= 0) return false
                  else return true
                },
              })}
              as="input"
              type="number"
              className=""
              step="any"
              readOnly={serviceChoosen.chargeType === "za serwis"}
            />
          </td>
          <td>
            <Form.Control
              {...register("params.0.startDate")}
              as="input"
              type="date"
              className=""
              id="serviceStartDate"
            />
          </td>
          <td>
            <Form.Control
              {...register("params.0.endDate", {
                validate: {
                  exists: (v) => {
                    if (watch("params.0.hasEndDate") && !v) return false
                    else return true
                  },
                  greaterThenStartDate: (v) => {
                    if (
                      watch("params.0.hasEndDate") &&
                      isBefore(
                        new Date(v),
                        new Date(watch("params.0.startDate"))
                      )
                    )
                      return false
                    else return true
                  },
                },
              })}
              as="input"
              type="date"
              className=""
              disabled={!watch("params.0.hasEndDate")}
            />
          </td>
          <td></td>
        </tr>
        {serviceChoosen.chargeType === "za serwis" ? (
          <tr>
            <td colSpan="6">
              <Alert variant="warning">
                Wybrano usługę z naliczeniem "za serwis".{" "}
                <b>Od wersji 1.12.0</b> taka usługa musi mieć liczbę = 1.
                Szczegóły: {createManualBtn("perPcsSrv")}
              </Alert>
            </td>
          </tr>
        ) : null}

        <tr>
          <td className="fixed-values-table" colSpan="6">
            Opis na FV
          </td>
        </tr>
        <tr>
          <td colSpan="5">
            {errors.invLine && (
              <Badge pill variant="warning">
                {errors.invLine.message}
              </Badge>
            )}
            <Form.Control
              {...register("invLine", { required: "pole wymagane" })}
              as="textarea"
              type=""
              className=""
              id="invLine"
            />
          </td>
          <td>{"{dataOd}, {dataDo}, {dataZadania}, {nazwaLok}"}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const ShortTermServiceForm = ({
  serviceChoosen,
  register,
  errors,
  watch,
  location,
}) => {
  return (
    <Table>
      <tbody>
        <tr>
          <td className="fixed-values-table">Nazwa</td>
          <td>{serviceChoosen.name}</td>
          <td className="fixed-values-table">Typ</td>
          <td>{serviceChoosen.type}</td>
          <td className="fixed-values-table">Naliczenie</td>
          <td>{serviceChoosen.chargeType}</td>
        </tr>

        <tr>
          <td className="fixed-values-table">Od</td>
          <td colSpan="2">
            <Form.Control
              {...register("params.0.startDate")}
              as="input"
              type="date"
              className=""
              id="serviceStartDate"
            />
          </td>
          <td className="fixed-values-table">
            <Form.Check
              {...register("params.0.hasEndDate", {
                validate: {
                  flatRate: (v) => {
                    if (serviceChoosen.chargeType === "ryczałt impreza" && !v)
                      return false
                    else return true
                  },
                },
              })}
              type="checkbox"
              label="Do"
              id="hasEndDate"
            />
            {errors.params &&
              errors.params[0]?.hasEndDate?.type === "flatRate" && (
                <Badge pill variant="warning">
                  Obowiązkowe dla ryczałtu
                </Badge>
              )}
          </td>
          <td colSpan="2">
            <Form.Control
              {...register("params.0.endDate", {
                validate: {
                  exists: (v) => {
                    if (watch("params.0.hasEndDate") && !v) return false
                    else return true
                  },
                  greaterThenStartDate: (v) => {
                    if (
                      watch("params.0.hasEndDate") &&
                      isBefore(
                        new Date(v),
                        new Date(watch("params.0.startDate"))
                      )
                    )
                      return false
                    else return true
                  },
                  // for flat rate end date must not be older then 30 days from start date
                  flatRate: (v) => {
                    if (
                      serviceChoosen.chargeType === "ryczałt impreza" &&
                      (!v ||
                        differenceInCalendarDays(
                          new Date(v),
                          new Date(watch("params.0.startDate"))
                        ) > 30)
                    )
                      return false
                    else return true
                  },
                },
              })}
              as="input"
              type="date"
              className=""
              disabled={!watch("params.0.hasEndDate")}
            />
            {errors.params && errors.params[0]?.endDate && (
              <Badge pill variant="warning">
                Błąd!
              </Badge>
            )}
            {errors.params && errors.params[0]?.endDate?.type === "exists" && (
              <Badge pill variant="warning">
                Wprowadź datę zakończenia
              </Badge>
            )}
            {errors.params &&
              errors.params[0]?.endDate?.type === "greaterThenStartDate" && (
                <Badge pill variant="warning">
                  Data DO musi być większa lub równa dacie OD!
                </Badge>
              )}
            {errors.params && errors.params[0]?.endDate?.type === "flatRate" && (
              <Badge pill variant="warning">
                Ryczałt {"->"} maks 30 dni
              </Badge>
            )}
          </td>
        </tr>

        {/* ======= different form for flat rate and short term */}
        {/* ========= FLAT RATE: */}
        {serviceChoosen.chargeType === "ryczałt impreza" ? (
          <>
            <tr>
              <td className="fixed-values-table">VAT</td>
              <td>
                <Form.Control
                  {...register("vat", {
                    required: "Pole wymagane",
                    validate: (v) => {
                      if (v < 0 || !v) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  autoComplete="chrome-off"
                />
                {errors.vat && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table" colSpan="2">
                Cena netto:
              </td>

              <td>
                <Form.Control
                  {...register("netPrice", {
                    validate: (v) => {
                      if (v <= 0) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  step="any"
                />
                {errors.netPrice && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td>
                (
                {_.round(
                  Number.parseFloat(watch("netPrice")) *
                    ((Number.parseFloat(watch("vat")) + 100) / 100),
                  2
                ) || 0}{" "}
                brutto)
              </td>
            </tr>
          </>
        ) : (
          // =============== STANDARD SHORT TERM:
          <>
            <tr>
              <td className="fixed-values-table">VAT</td>
              <td>
                <Form.Control
                  {...register("vat", {
                    required: "Pole wymagane",
                    validate: (v) => {
                      if (v < 0 || !v) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  autoComplete="chrome-off"
                />
                {errors.vat && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table" colSpan="2">
                <Row>
                  <Col xs="auto">Cena za pierwsze</Col>
                  <Col xs="auto">
                    <Form.Control
                      {...register("initPeriod", {
                        validate: (v) => {
                          if (v <= 0 || v > 14 || !v) return false
                          else return true
                        },
                      })}
                      as="input"
                      type="number"
                      className="small-number-input"
                      autoComplete="chrome-off"
                      step="1"
                      min="1"
                      max="14"
                    />
                  </Col>
                  <Col xs="auto">dni:</Col>
                </Row>
                {errors.initPeriod && (
                  <Badge pill variant="warning">
                    Wpisz wartość całkowitą z zakresu 1-14
                  </Badge>
                )}
              </td>

              <td>
                <Form.Control
                  {...register("netPrice", {
                    validate: (v) => {
                      if (v <= 0) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  step="any"
                />
                {errors.netPrice && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td>
                (
                {_.round(
                  Number.parseFloat(watch("netPrice")) *
                    ((Number.parseFloat(watch("vat")) + 100) / 100),
                  2
                ) || 0}{" "}
                brutto)
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                Cena za każdy kolejny dzień:
              </td>
              <td>
                <Form.Control
                  {...register("additionalPrice", {
                    validate: (v) => {
                      if (v <= 0 || !v) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  step="any"
                />
                {errors.additionalPrice && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td>
                (
                {_.round(
                  Number.parseFloat(watch("additionalPrice")) *
                    ((Number.parseFloat(watch("vat")) + 100) / 100),
                  2
                ) || 0}{" "}
                brutto)
              </td>
              <td className="fixed-values-table">Cena za serwis:</td>
              <td>
                <Form.Control
                  {...register("jobPrice", {
                    validate: (v) => {
                      if (v <= 0 || !v) return false
                      else return true
                    },
                  })}
                  as="input"
                  type="number"
                  className="medium-number-input"
                  step="any"
                />
                {errors.jobPrice && (
                  <Badge pill variant="warning">
                    Wpisz dodatnią wartość
                  </Badge>
                )}
              </td>
              <td>
                (
                {_.round(
                  Number.parseFloat(watch("jobPrice")) *
                    ((Number.parseFloat(watch("vat")) + 100) / 100),
                  2
                ) || 0}{" "}
                brutto)
              </td>
            </tr>
          </>
        )}
        <tr>
          <td colSpan={6} className="fixed-values-table">
            Kalkulacja/opis
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <Form.Control
              {...register("calculation")}
              as="textarea"
              className=""
              autoComplete="chrome-off"
            />
          </td>
        </tr>
        <tr>
          <td className="fixed-values-table" colSpan="6">
            Opis na FV (znaczniki:{" "}
            {"{dataOd}, {dataDo}, {dataZadania}, {nazwaLok}"})
          </td>
        </tr>
        <tr>
          <td colSpan="6">
            {errors.invLine && (
              <Badge pill variant="warning">
                {errors.invLine.message}
              </Badge>
            )}
            <Form.Control
              {...register("invLine", { required: "pole wymagane" })}
              as="textarea"
              type=""
              className=""
              id="invLine"
            />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
export default LocationsDetailsServiceEditModal
