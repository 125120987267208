import { format } from "date-fns"

const renderNoDriverJobsAlert = (noDriverJobs) => {
  if (noDriverJobs.length > 10)
    return (
      <p>
        <b>UWAGA!</b> Brak kierowcy dla ponad 10 zadań!
      </p>
    )
  else
    return `Brak kierowcy dla zadań w dniach: ${noDriverJobs
      .map((job) => format(new Date(job), "yyyy-MM-dd"))
      .join(", ")}`
}

export { renderNoDriverJobsAlert }
