import React from "react"
import { Form, Button, Col, Container } from "react-bootstrap"

const SewagePermissionsFetchForm = (props) => {
  return (
    <Container id="SewagePermissionsFetchForm">
      <Form
        onSubmit={(e) => {
          e.preventDefault()

          props.fetchPermissions(
            e.target.branch.value,
            e.target.state.value,
            e.target.query.value
          )
        }}
        inline
        className="justify-content-center"
      >
        <Col xs="auto">
          <Form.Label>
            Szukaj:
            <Form.Control
              as="input"
              name="query"
              className=""
              autoComplete="chrome-off"
            />
          </Form.Label>
        </Col>
        <Col xs="2">
          <Form.Label>
            Oddział:
            <Form.Control
              as="select"
              name="branch"
              // if user went back to table from details -> set previous search params
              defaultValue={props.defaultData?.branch}
            >
              <option>wszystkie</option>
              {props.userBranches.map((branch) => (
                <option key={`select-option-branch-${branch}`}>{branch}</option>
              ))}
            </Form.Control>
          </Form.Label>
        </Col>
        <Col xs="2">
          <Form.Label>
            Status:
            <Form.Control
              as="select"
              name="state"
              // if user went back to table from details -> set previous search params
              defaultValue={props.defaultData?.state}
            >
              <option key="select-option-aktywne">aktywne</option>
              <option key="select-option-archiwum">archiwum</option>
              <option key="select-option-wszystkie">wszystkie</option>
            </Form.Control>
          </Form.Label>
        </Col>
        <Col xs="2">
          <Button type="submit">Wczytaj</Button>
        </Col>
      </Form>
    </Container>
  )
}
export default SewagePermissionsFetchForm
