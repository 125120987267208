import React, { useContext } from "react"

import { Button, Col, Row, Container } from "react-bootstrap"

import UserContext from "../contexts/userContext"
import MetaContext from "../contexts/metaContext"

import SendMail from "../utils/sendMail"
import TodosDetailsEdit from "../todos/details/edit"

const Footer = (props) => {
  const user = useContext(UserContext)
  const meta = useContext(MetaContext)

  return (
    <Container
      fluid
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        // width: "100%",
        backgroundColor: meta?.devEnv ? "#ef5350" : "#c1d5e0",
        // height: "auto",
        // display: "grid",
        // gridTemplateColumns: "1fr 1fr",
      }}
      className="py-1"
    >
      <Row>
        {/* prevent undefined errors when not logged in: */}
        {user === undefined ? (
          <p>Niezalogowano</p>
        ) : (
          <>
            <Col xs="8">
              <p style={{ margin: "5px" }}>
                Wersja: {meta.version} z dnia {meta.versionDate} || zalogowano:{" "}
                {user ? user.name : "brak"}
              </p>
            </Col>
            {user ? (
              <Col>
                <Row>
                  <Button
                    size="sm"
                    className="ml-auto mr-2"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: "Wyślij wiadomość",
                        body: <SendMail setModalData={props.setModalData} />,
                        xl: true,
                        hideFooter: true,
                      })
                    }
                  >
                    Wyślij wiadomość
                  </Button>
                </Row>
              </Col>
            ) : null}
            {user.perm.todos?.w ? (
              <Col>
                <Row>
                  <Button
                    size="sm"
                    variant="secondary"
                    className="ml-auto mr-2"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: "Wyślij wiadomość",
                        body: (
                          <TodosDetailsEdit
                            setModalData={props.setModalData}
                            isNew={true}
                            refresh={() => window.location.reload()}
                          />
                        ),
                        xl: true,
                        hideFooter: true,
                      })
                    }
                  >
                    Dodaj zadanie
                  </Button>
                </Row>
              </Col>
            ) : null}
          </>
        )}
      </Row>
    </Container>
  )
}
export default Footer
