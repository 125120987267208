import React, { useState, useEffect, useContext } from "react"
import { Spinner } from "react-bootstrap"
import { format } from "date-fns"

import { LocationCtx } from "../Fetch"
import LocationJobsBatchEdit from "./LocationJobsBatchEdit"
//$ this component only forces fetching all jobs and renders batch modal after location jobs are updated
//( this is because I added fetching only some jobs some time later,
//( propably now I should move part of logic from LocationJobsBatchEdit here...
const LocationJobsBatchEditFetch = () => {
  const {
    setAreJobsLoading,
    areJobsLoading,
    setJobsDates,
    jobsDates,
    location,
  } = useContext(LocationCtx)

  // using this state to prevent rendering form befor updating jobs:
  const [isLoading, setIsLoading] = useState(true)

  // force fetching all jobs:
  useEffect(() => {
    const oldJobsDates = { ...jobsDates }
    setAreJobsLoading(true)
    setJobsDates({
      start: format(new Date(location.jobsBorderDates.first), "yyyy-MM-dd"),
      end: format(new Date(location.jobsBorderDates.last), "yyyy-MM-dd"),
      exclude: { start: oldJobsDates.start, end: oldJobsDates.end },
    })
    // now, as 'areJobsLoading' === true I can change 'isLoading'
    setIsLoading(false)

    // I don't want to fire this effect more than once, eslint doesn't like it ;)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (isLoading || areJobsLoading)
    return (
      <>
        <Spinner animation="border" />
        Pobieram dane
      </>
    )
  else return <LocationJobsBatchEdit />
}
export default LocationJobsBatchEditFetch
