import React, { useState, useEffect } from "react"
import { Modal, Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"

import CustomersClipMenuDetailsRender from "./Render"

const CustomersClipMenuDetailsFetch = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [customer, setCustomer] = useState()
  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const res = await fetch(
          `/customers/getClipMenuCustomerById/${props.match.params._id}`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setCustomer(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    fetchCustomer()
  }, [props.match])

  return (
    <Container>
      {isLoading ? (
        <p>Pobieram dane</p>
      ) : (
        <CustomersClipMenuDetailsRender
          customer={customer}
          setModalData={setModalData}
        />
      )}
      <Modal
        show={modalData.show}
        onHide={() => setModalData({ show: false })}
        animation={false}
      >
        <MyModal modalData={modalData} setModalData={setModalData} />
      </Modal>
    </Container>
  )
}
export default CustomersClipMenuDetailsFetch
