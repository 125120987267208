import { Table } from "react-bootstrap"
import CustomersNoteDetailsModal from "../entities/CustomersNoteDetailsModal"

const CustomersDetailsArchNotesModal = ({ notes, setModalData }) => {
  //TODO add 'wróć' button to note details modal to return to list of notes
  return (
    <Table>
      <tbody>
        <tr>
          <td>Rodzaj</td>
          <td>Dodał</td>
          <td>Treść</td>
        </tr>
        {notes.map((note) => {
          const noteBody =
            note.body.length > 80 ? note.body.slice(0, 80) + "..." : note.body
          return (
            <tr
              key={`archNoteRow-${note._id}`}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  body: <CustomersNoteDetailsModal note={note} />,
                  xl: true,
                  header: (
                    <p className="mb-0">
                      Szczegóły notatki (rodzaj: {note.type})
                    </p>
                  ),
                })
              }
            >
              <td>{note.type}</td>
              <td>
                {note.history[0]?.user?.fullName || "import automatyczny"}
              </td>
              <td>{noteBody}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
export { CustomersDetailsArchNotesModal }
