import React, { useContext, useState, useMemo } from "react"
import { differenceInCalendarDays, isAfter, isValid } from "date-fns"

import { Container, Row, Table, Col, Button, Alert } from "react-bootstrap"

import { GrMap } from "react-icons/gr"

import { LocationCtx } from "../Fetch"

import HistoryTable from "../../utils/HistoryTable"

import LocationsDetailsEditMapModal from "../Edit/modals/LocationsDetailsEditMapModal"
import LocationsDetailsCalendar from "../utils/LocationsDetailsCalendar"
import LocationsDetailsServicesTable from "../tables/LocationsDetailsServicesTable"
import LocationsDetailsEquipmentTable from "../tables/LocationsDetailsEquipmentTable"
import { LocationEditGeneralModalFetch } from "../Edit/GeneralModal/Fetch"
import { renderNoDriverJobsAlert } from "../utils/alerts"

const LocationsDetailsGeneralTab = () => {
  const {
    location,
    setModalData,
    user,
    refresh,
    configs,
    showSrvParams,
    setShowSrvParams,
  } = useContext(LocationCtx)

  // show closed services only when any of them is not invoiced, else hide by default:
  const [showClosedSrv, setShowClosedSrv] = useState(
    location.services.filter(
      (srv) =>
        srv.state === "zamknięta" &&
        !srv.params.find((prm) => {
          const endDate = prm.endDate ? new Date(prm.endDate) : null
          const invoicedTo = prm.invoicedTo ? new Date(prm.invoicedTo) : null
          if (!isValid(endDate) || !isValid(invoicedTo)) return false

          return differenceInCalendarDays(endDate, invoicedTo) <= 0
        })
    ).length !== 0
  )

  const mainContact = location.contacts.find(
    (contact) => contact.state === "aktywny" && contact.type === "główny"
  )
  const mainNote = location.notes.find(
    (note) => note.state === "aktywna" && note.type === "główna"
  )

  // check if there are any services that require equipment but there is no eqp for them:
  const srvMustHaveEqpCheck = useMemo(() => {
    return location.services.filter(
      (locSrv) =>
        locSrv.state === "aktywna" &&
        locSrv.mustHaveEqp &&
        !locSrv.equipment.length
    )
  }, [location])

  // check if any service has different qty of eqp then there is really applied. Use array to write which cause problems in Alert:
  const srvEqpQtyCheck = location.services.filter((srv) => {
    // prevent alert for services other then "wynajem toalet" and for closed services
    if (
      srv.name !== "Wynajem toalet" ||
      srv.state === "zamknięta" ||
      srv.state === "anulowana"
    )
      return false

    // find most actual param:
    const actualParam = srv.params.reduce(
      (acc, red) => {
        if (isAfter(new Date(red.startDate), new Date(acc.startDate)))
          return red
        else return acc
      },
      { startDate: new Date(0) }
    )

    // check if last param qty equals equipment length

    return Number.parseFloat(actualParam.qty) !== srv.equipment.length
  })

  const handleInitRegNoteStateChange = async (note) => {
    try {
      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=false&entity=notes`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ data: { ...note, state: "archiwum" } }),
        }
      )

      if (res.status !== 200)
        throw new Error(
          `Błąd: ${
            (await res.text()) || "nieokreślony błąd komunikacji ze serwerem"
          }`
        )
      return refresh()
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || "nieokreślony błąd programu",
      })
    }
  }

  //TODO move all alerts to utils (?create ../utils/LocationsAlerts.js to render all alerts?)
  return (
    <Container fluid className="mt-2">
      {location.customer.jobsSuspended ? (
        <Alert
          style={{
            animationName: "red-alert",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            textAlign: "center",
          }}
        >
          <b>! WINDYKACJA - ZADANIA WSTRZYMANE !</b>
        </Alert>
      ) : null}
      {location.notes
        .filter(
          (note) =>
            note.type === "rejestracja wstępna" && note.state === "aktywna"
        )
        .map((note) => (
          <Alert variant="danger">
            <Row>
              REJESTRACJA WSTĘPNA: {note.body}
              <Button
                onClick={() => handleInitRegNoteStateChange(note)}
                variant="secondary"
                size="sm"
                className="ml-auto"
              >
                Zarejestrowana
              </Button>
            </Row>
          </Alert>
        ))}
      <Row>
        {srvMustHaveEqpCheck.length ? (
          <Alert variant="danger" className="mx-1">
            Usługi <b>sprzętowe</b> bez sprzętu:
            <br />
            {`${srvMustHaveEqpCheck
              .map(
                (srv) =>
                  `${srv?.locSrvNo || null}. ${srv?.name || "ZGŁOŚ BŁĄD"}`
              )
              .join(", ")}`}
          </Alert>
        ) : null}

        {/* jobs without driver alert: */}
        {location.alerts?.noDriverJobs?.length ? (
          <Alert variant="danger" className="mx-1">
            {renderNoDriverJobsAlert(location.alerts.noDriverJobs)}
          </Alert>
        ) : null}

        {/* no default driver alert: */}
        {location.hasDriver && location.driver?._id ? null : (
          <Alert variant="danger">
            <b>Brak domyślnego kierowcy</b>
          </Alert>
        )}

        {/* no jobs alert: */}
        {location.alerts?.noJobs?.length ? (
          <Alert variant="warning" className="mx-1">
            Usługi, które powinny mieć zadania cykliczne, ale nie ma nic
            zaplanowangeo:
            <br />
            {`${location.alerts.noJobs
              .map(
                (srv) =>
                  `${srv?.locSrvNo || null}. ${srv?.name || "ZGŁOŚ BŁĄD"}`
              )
              .join(", ")}`}
          </Alert>
        ) : null}

        {/* no permission/municipal */}
        {!location.permission?._id ? (
          <Alert variant="warning">Brak przypisanej gminy/zezwolenia!</Alert>
        ) : null}

        {srvEqpQtyCheck.length !== 0 && (
          <Alert variant="warning" className="mx-1">
            Usługi z niezgodną ilością sprzętu:{" "}
            {srvEqpQtyCheck
              .map(
                (srv) =>
                  `${srv?.locSrvNo || null}. ${srv?.name || "ZGŁOŚ BŁĄD"}`
              )
              .join(", ")}
          </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="2">
                  <Row>
                    <Col>
                      Szczegóły lokalizacji (oddział: {`${location.branch}`})
                    </Col>
                    <Col>
                      <Row className="justify-content-end">
                        <Button
                          variant="primarySuperDark"
                          className="mx-2 "
                          onClick={() =>
                            setModalData({
                              show: true,
                              type: "info",
                              xl: true,
                              header: `Historia edycji danych lokalizacji "${location.name}"`,
                              body: <HistoryTable history={location.history} />,
                            })
                          }
                        >
                          Historia
                        </Button>
                        {user.allowedBranches.findIndex(
                          (branch) => branch === location.branch
                        ) !== -1 &&
                          user.perm.locations.e &&
                          location.state === "aktywna" && (
                            <Button
                              variant="secondary"
                              className="mx-2"
                              onClick={() =>
                                setModalData({
                                  show: true,
                                  type: "info",
                                  xl: true,
                                  header: `Edycja danych lokalizacji "${location.name}"`,
                                  body: (
                                    <LocationEditGeneralModalFetch
                                      isNew={false}
                                      locHistLength={location.history.length}
                                      loc_id={location._id}
                                      cust_id={location.customer._id}
                                      user={user}
                                      setModalData={setModalData}
                                      refresh={refresh}
                                      custHistLength={
                                        location.customer.histLength
                                      }
                                    />
                                  ),
                                })
                              }
                              id="editLocBtn"
                            >
                              Edytuj
                            </Button>
                          )}
                        {location.state === "aktywna" && (
                          <Button
                            variant="secondary"
                            id="coordsBtn"
                            className="mx-2"
                            onClick={() =>
                              setModalData({
                                show: true,
                                type: "info",
                                xl: true,
                                hideFooter: true,
                                header: `Mapa dla lokalizacji ${location.name}`,
                                body: <LocationsDetailsEditMapModal />,
                              })
                            }
                          >
                            <GrMap />
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Nazwa
                </td>
              </tr>
              <tr>
                <td colSpan="2">{location.name}</td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Adres
                </td>
              </tr>
              <tr>
                <td colSpan="2">{`${location.street || ""}, ${
                  location.postal || ""
                } ${location.city || ""}`}</td>
              </tr>

              <tr>
                <td className="fixed-values-table">Dojazd - uwagi</td>
                <td className="fixed-values-table">Kierowca domyślny</td>
              </tr>
              <tr>
                <td>
                  {location.notes.find(
                    (note) =>
                      note.state === "aktywna" && note.type === "na kontrolkę"
                  )?.body || "brak notatki 'na kontrolkę'"}
                </td>
                <td id="locationDefaultDriver">
                  {(location.hasDriver && location.driver?.fullName) || "brak"}
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Notatka
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {mainNote ? `${mainNote.body}` : "brak głównej notatki"}
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Kontakt
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  {mainContact
                    ? `${mainContact.name || ""} ${mainContact.phone || ""} ${
                        mainContact.mail || ""
                      } ${mainContact.fax ? `fax: ${mainContact.fax}` : ""}`
                    : "brak głównego kontaktu"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col style={{ userSelect: "none" }}>
          <LocationsDetailsCalendar />
        </Col>
      </Row>
      <Row>
        <h4>Usługi:</h4>
        <LocationsDetailsServicesTable
          filter={["aktywna"]}
          showSrvParams={showSrvParams}
          setShowSrvParams={setShowSrvParams}
        />
      </Row>

      <Row>
        <h4>Usługi zamknięte i anulowane:</h4>
      </Row>
      <Row>
        {showClosedSrv ? (
          <Button onClick={() => setShowClosedSrv(false)}>Ukryj</Button>
        ) : (
          <Button onClick={() => setShowClosedSrv(true)}>Pokaż</Button>
        )}
        {showClosedSrv && (
          <LocationsDetailsServicesTable filter={["zamknięta", "anulowana"]} />
        )}
      </Row>
      <hr />
      <Row>
        <h4>Sprzęt:</h4>
        <LocationsDetailsEquipmentTable
          services={location.services}
          setModalData={setModalData}
          eqpTypes={configs.eqpTypes}
          loc_id={location._id}
        />
      </Row>
    </Container>
  )
}
export default LocationsDetailsGeneralTab
