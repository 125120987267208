import React from "react"
import { Row, Col, Button } from "react-bootstrap"

import chooseSrvModal from "../../pics/manual/chooseSrvModal.png"
import addSrvModal from "../../pics/manual/addSrvModal.png"
import srvTable from "../../pics/manual/srvTable.png"
import changeSrvPrice from "../../pics/manual/changeSrvPrice.png"
import jobsBatchEdit from "../../pics/manual/jobsBatchEdit.png"
import jobsBatchEditFreqDays2 from "../../pics/manual/jobsBatchEditFreq-days-2.png"
import jobsBatchEditFreqDays2v2 from "../../pics/manual/jobsBatchEditFreq-days-2v2.png"
import jobsBatchEditFreqDays3 from "../../pics/manual/jobsBatchEditFreq-days-3.png"
import jobsBatchEditFreqWeeks2 from "../../pics/manual/jobsBatchEditFreq-weeks-2.png"
import jobsBatchEditFreqWeeks3 from "../../pics/manual/jobsBatchEditFreq-weeks-3.png"
import jobsBatchEditFreqOcc2 from "../../pics/manual/jobsBatchEditFreq-occ-2.png"
import jobsBatchEditFreqOcc3 from "../../pics/manual/jobsBatchEditFreq-occ-3.png"
import srvAndEqpBatchEdit from "../../pics/manual/srvAndEqpBatchEdit.png"
import invNoteAdding from "../../pics/manual/invNoteAdding.png"
import invModModal from "../../pics/manual/invModModal.png"
import locationEqpCheckAlert from "../../pics/manual/locationEqpCheckAlert.png"
import jobStates from "../../pics/manual/jobStates.png"
import addJobsModal from "../../pics/manual/addJobsModal.png"
import locationCalendar from "../../pics/manual/locationCalendar.png"
import locationAddShortTermSrv from "../../pics/manual/locationAddShortTermSrv.png"
import CustEqpEdit from "../../pics/manual/locations/custEqpEdit.png"
import locationInitReg from "../../pics/manual/locations/locationInitReg.png"
import subSrvExtraFeeJobDetails from "../../pics/manual/locations/subSrvExtraFeeJobDetails.png"
import subSrvEditModal from "../../pics/manual/locations/subSrvEditModal.png"
import paramsDiag from "../../pics/manual/locations/paramsDiag.png"
import paramsBugs from "../../pics/manual/locations/paramsBugs.png"
import srvQtyChange from "../../pics/manual/locations/srvQtyChange.png"
import srvQtyChangeConfirm from "../../pics/manual/locations/srvQtyChangeConfirm.png"
import { createManualBtn } from "../../../comp/utils/buttons"

const ManualLocationsSection = (props) => {
  //todo clipMENU import priv: full name as name, change whenever changing something in customer
  //todo type (klasyfikacja) explain

  /*
todo:
lista lokalizacji pokazuje tylko aktywnych klientów w przypadku wyszukiwania po kliencie
*/
  if (!props.user.perm.customers.r) return null
  else
    return (
      <div>
        <Row>
          <Col>
            <h2>LOKALIZACJA</h2>
            <p id="locNumbering">
              <b>Numerowanie lokalizacji</b>: każda rejestrowana lokalizacja
              otrzymuje swój numer. Numery naliczają się od początku roku i są
              prezentowane (na kontrolkach, w podglądzie itp.) w formie
              "2022-123"
            </p>
          </Col>
        </Row>
        <div>
          <Row>
            <Col>
              <h3 id="initRegHeader">Rejestracja wstępna</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Jeśli osoba dodająca lokalizację nie wie na jaki dzień
                zaplanować zadania, to może wpisać szczegóły w pole{" "}
                <b>"Rejestracja wstępna"</b>. Lokalizacja zarejestruje się z
                dodatkową notatką "rejestracja wstępna", której treść będzie
                wyświetlona i wyróżniona na ekranie lokalizacji.
                <br />
                Dodatkowo osoby odpowiedzialne za logistykę w danym oddziale
                otrzymają maila informującego o rejestracji wstępnej.
              </p>
              <p>
                <b>
                  Mimo dodania ww. informacji, nadal należy uzupełnić tak dużo
                  informacji jak to możliwe (np. dodać współrzędne,
                  skonfigurować usługi itd.)
                </b>
              </p>
              <p>
                Zadania z lokalizacji, która została zarejestrowana tylko
                wstępnie będą wyróżnione na ekranie planowania tras.
              </p>
              <p>
                Po dokończeniu rejestracji (czyli zaplanowaniu zadań zgodnie z
                notatką) należy kliknąć przycisk "Zarejestrowana".
              </p>
            </Col>
            <Col>
              <img src={locationInitReg} alt="" style={{ maxWidth: "100%" }} />
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <h3 id="loc-services">Usługi</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Do lokalizacji dodawane są usługi (wynajem toalet, opróżnianie
              szamba itd.).
            </p>
            <p>
              "Naliczenie" definiuje rodzaj ceny: czy jest "za dzień", "za
              miesiąc" czy "za serwis".{" "}
              <u>
                Naliczenie "za serwis" oznacza, że klient jest fakturowany za
                każdy wykonany serwis
              </u>
            </p>
            <p>
              Usługi można łączyć, dzięki temu możliwe jest fakturowanie z
              osobną ceną za wynajem i serwis. Wystarczy dodać dwie usługi.
            </p>
            <p>Usługi dzielą się na:</p>
            <ul>
              <li>
                Sprzętowe - przy planowaniu zadania wymagane będzie wskazanie
                sprzętu, którego ma dotyczyć
              </li>
              <li>
                Bezsprzętowe - nie ma możliwości zaplanowania dostarczenia czy
                wybrania sprzętu, którego ma dotyczyć serwis
              </li>
              <li>
                Ze sprzętem klienta - sprzęt edytuje się bez planowania
                dostarczenia/zabrania.
              </li>
            </ul>
            <p>
              Poniższe informacje dotyczą przede wszystkim usług
              wykorzystywanych do obsługi długoterminowej. Usługi
              krótkoterminowe (
              <b>
                tzn. o rodzaju naliczenia "krótki termin" i "ryczałt impreza"
              </b>
              ) nieco się różnią. Szczegółowy opis poniżej (część "usługi
              krótkoterminowe").
            </p>
          </Col>
          <Col>
            <img src={chooseSrvModal} alt="" style={{ maxWidth: "100%" }} />{" "}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>
              Po wybraniu usługi należy podać jej dane. Istnieje też możliwość
              zmiany treści faktury ("opis na FV"). Przy wprowadzaniu treści
              można użyć znaczników (np. {`{dataOd}`}). Za każdym razem przy
              generowaniu faktury znacznik zostanie podmieniony na faktyczne
              dane. Można to wykorzystać również do podawania numeru
              umowy/zamówienia itd.
            </p>
            <Button
              onClick={() =>
                props.setModalData({
                  show: true,
                  type: "info",
                  body: (
                    <>
                      <p>
                        Np. przy dodawaniu usługi wpisano "Wynajem ogrodzeń od{" "}
                        {`{dataOd}`} do {`{dataDo}`}" {"->"} faktura za kwiecień
                        2021 będzie miała pozycję o treści "Wynajem ogrodzeń od
                        2021-04-01 do 2021-04-30"{" "}
                      </p>
                      <p>
                        Inny przykład: przy dodawaniu usługi wpisano "Usługa
                        opróżniania zbiornika bezodpływowego w dniach{" "}
                        {`{dataZadania}`}, umowa nr. 123/01/02, zamówienie
                        01-123." faktura za dwa serwisy w styczniu będzie miała
                        pozycję o treści "Usługa opróżniania zbiornika
                        bezodpływowego w dniach 2021-01-01, 2021-01-15, umowa
                        nr. 123/01/02, zamówienie 01-123."
                      </p>
                    </>
                  ),
                })
              }
            >
              Przykład
            </Button>
            <p>
              Dodając usługę sprzętową można od razu zaplanować dostarczenie
              (działanie domyślne).
            </p>
            <div>
              W przypadku "normalnego" wynajmu toalet należy wybierać usługę
              "Wynajem i <u>serwis</u> toalet". Usługa "Wynajem toalet" jest{" "}
              <u>bezsprzętowa</u> i powinna występować tylko z usługą "Serwis
              toalet CC" na potrzeby kontraktów rozliczanych w trybie "cena za
              wynajem + cena za każdy serwis"
            </div>
          </Col>
          <Col>
            <img src={addSrvModal} alt="" style={{ maxWidth: "100%" }} />{" "}
          </Col>
        </Row>
        <hr />
        <div id="incomeCat">
          <Row>
            <Col>
              <h4>Kategoria przychodu</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              Przy dodawaniu lub edycji szczegółów usługi można wybrać kategorię
              przychodu. Pole służy do określenia w jakie miejsce w raporcie
              przychodu (intensywnie wykorzystywanym przez kierowników i zarząd)
              trafi faktura za wybraną usługę.
              <br />
              <b>
                W większości przypadków należy zostawić domyślnie wybraną
                kategorię
              </b>{" "}
              (lub wybrać odpowiednią, jeśli żadna się nie wstawiła).
              Odstępstwem są usługi dla firm Boels, TLC itp., gdzie dodajemy
              usługę "Serwis toalet CC".{" "}
              <u>
                W takich sytuacjach należy wybrać kategorię "Toaleta klienta"
              </u>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col>
            <h4 id="params">Parametry usługi</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Parametr to sposób na powiedzenie programowi, że w danym zakresie
              dat ma przyjąć do fakturowania wskazaną w parametrze liczbę.
              <br />
              Parametry są widoczne pod daną usługą po zaznaczeniu checkboxa
              "pokaż parametry"
            </p>
          </Col>
          <Col>
            <img src={srvTable} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>
              Przykładowo usługa naliczana "za dzień" z ceną 12 zł może mieć
              parametr z datami 2022-03-02 - 2022-03-10 i liczbą 2, oraz
              parametr z datą rozpoczęcia 2022-03-11, bez daty zakończenia i z
              liczbą 3 (grafika obok). W tym przykładzie program przy
              fakturowaniu marca policzy 9 dni * 2 szt = 18 (pierwszy parametr)
              i doda 21 dni * 3 szt = 62 (drugi parametr). Sumę (80) pomnoży
              przez cenę 12 zł. <br />
              Analogicznie będą wyglądały obliczenia dla ceny miesięcznej, tylko
              program wyliczy odpowiednie proporcje, zasada ta sama.
            </p>
            <p>
              Parametry można edytować, choć zależnie od okoliczności niektóre
              pola mogą być zablokowane.
            </p>
            <p>
              <b>Zmiana liczby dla usługi</b> polega na dodaniu nowego
              parametru, albo zmianie istniejącego zależnie od daty zmiany i czy
              była wystawiona faktura. Więcej szczegółów w dalszej części
              instrukcji {createManualBtn("qtyOverride")}
            </p>
            <p>
              Parametry służą też do określania dat trwania usługi. Usługa
              rozpoczyna się (pod kątem fakturowania) od daty rozpoczęcia
              pierwszego parametru i kończy z datą ostatniego. <br />
              Jeśli ostatni parametr nie ma daty zakończenia to usługa jest
              bezterminowa.
            </p>
          </Col>

          <Col>
            <img src={paramsDiag} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Parametry a liczba sprzętu</h5>
            <p className="bg-danger">
              Uwaga! Program w stopniu minimalnym weryfikuje zgodność "liczby" z
              parametru i sprzętu, który faktycznie jest dostarczony i
              obsługiwany na lokalizacji. Można dodać "wynajem toalet" z liczbą
              = 3, a dostarczyć i obsługiwać jedną toaletę. Przy wystawianiu
              faktury wykorzystana będzie liczba z parametru (3).
              <br />
              <Button
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    body: (
                      <p>
                        Ze względu np. na ogrodzenia. Klient może sobie życzyć
                        rozliczania za metr bieżący. Przy zadaniach podawana
                        jest konkretna liczba przęseł (żeby ułatwić kierowcy),
                        więc już liczba z parametru nie będzie zgodna z liczbą
                        sprzętu. Do tego dochodzą złączki, podstawy itd., więc
                        nie będzie to zgodne z liczbą, która powinna pojawić się
                        na fakturze.
                      </p>
                    ),
                  })
                }
              >
                Dlaczego tak?
              </Button>
              <br />
              Aby zmniejszyć ryzyko błędów, w przypadku kiedy ilość z usługi nie
              jest zgodna z ilością sprzętu dostarczonego, program wyświetla
              ostrzeżenie pod kalendarzem.
            </p>
          </Col>
          <Col>
            <img
              src={locationEqpCheckAlert}
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5 id="paramsBugs">Błędne parametry</h5>
            <p>
              Grafika obok przedstawia sytuacje, które nie powinny się zdarzyć.
              <br />W pierwszej sytuacji jest dodany parametr od 02 do 08 i
              kolejny od 11, czyli powstaje 2-dniowa dziura. Spowoduje to błąd
              przy fakturowaniu lub niezafakturowanie tych dwóch dni.
              <br />W drugiej sytuacji parametry nachodzą na siebie w dniach 21
              i 22, co również może spowodować błąd programu przy fakturowaniu.
            </p>
            <p>
              Osoba, która jako pierwsza zgłosi w Jirze, że udało jej się
              wytworzyć sytuację jak powyżej (i opisze w jaki sposób) otrzyma
              nagrodę 100 zł. Zaznaczam, że dotyczy to parametrów{" "}
              <u>w ramach jednej usługi</u>
            </p>
          </Col>
          <Col>
            <img src={paramsBugs} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5>Zmiana ceny</h5>
            <p>
              Zmiana ceny usługi w trakcie jej trwania spowoduje przy
              wystawianiu faktury użycie nowej ceny od daty "zafakturowano do",{" "}
              <u>niezależnie kiedy nastąpiła zmiana</u>.<br />
              Aby tego uniknąć należy najpierw wygenerować fakturę za okres
              poprzedzający zmianę, a następnie wprowadzić nową cenę.
            </p>
            <p>
              Dla ułatwienia od wersji 1.5.1 istnieje przycisk "Zmień cenę",
              który robi to za jednym razem: wystawia fakturę i zmienia cenę
              usługi.
            </p>
            <p>
              To działanie ma swoje skutki uboczne opisane w modalu zmiany ceny
              (widocznym na zrzucie obok).
            </p>
            <p>
              "Data zmiany" to data od której zacznie obowiązywac nowa cena.{" "}
              <u>
                Aby umożliwić automatyczne wygenerowanie faktury w związku ze
                zmianą ceny konieczne jest wybranie daty "odległej" co najmniej
                o dwa dni od ostatniej daty "zafakturowano do" lub daty
                rozpoczęcia usługi (ponieważ musi być min. jeden dzień do
                zafakturowania).
              </u>
            </p>
          </Col>
          <Col>
            <img src={changeSrvPrice} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h5 id="srvQtyChange">
              Zmiana sprzętu i liczby w usłudze (dodanie lub zabranie)
            </h5>
            <p className="bg-danger">
              Prawidłowa zmiana liczby <b>wymaga</b> zrozumienia koncepcji
              parametrów usługi, opisanej wyżej {createManualBtn("params")}
            </p>
            <p>
              Poniża lista opisuje kroki potrzebne do zmiany liczby, w przypadku
              gdy klient potrzebuje dodatkowego sprzętu (albo zmniejszenia
              liczby wynajmowanego).
            </p>

            <p className="bg-warning">
              Nie ma możliwości zmiany liczby dla usług "za serwis", szczegóły:{" "}
              {createManualBtn("perPcsSrv")}
            </p>
            <p>
              Istnieją dwie metody zmiany liczby dla usługi. Pierwsza,
              "trudniejsza", polega na bezpośredniej modyfikacji parametrów.
              <br />
              Druga, wprowadzona w 1.12.0 upraszcza ten proces - program
              wykonuje część operacji za użytkownika,{" "}
              <b>ale nadal operuje na parametrach</b> (np. automatycznie zmienia
              ich daty lub dodaje nowe).
              <br />
            </p>
            <p>
              O ile druga metoda wystarczy dla zdecydowanej większości sytuacji,
              o tyle w bardziej skomplikowanych przypadkach może zmniejszyć
              przejrzystość, a nawet spowodować błędy. Dlatego nadal należy
              rozumieć działanie parametrów, a w razie wątpliwości... pytać.
            </p>
            <p id="qtyOverride">
              <b>
                Korzystanie z prostszej metody <u>ze wskazaniem daty</u>{" "}
                powoduje <u>nadpisanie</u> wartości "liczba" dla wszystkich
                parametrów po tej dacie.{" "}
                <Button
                  onClick={() =>
                    props.setModalData({
                      show: true,
                      type: "info",
                      body: "Usługa ma parametr z liczbą 5 od stycznia do maja i 8 od czerwca. Edycja liczby łatwiejszą metodą na 6 szt. od kwietnia spowoduje dodanie parametru z liczbą 6 od kwietnia do czerwca i nadpisanie liczby 'czerwcowego' parametru, który od teraz będzie miał liczbę = 6.",
                    })
                  }
                >
                  przykład
                </Button>
              </b>
              .<br />
              Jeśli program wykryje takie ryzyko to wyświetli komunikat (drugi
              zrzut obok).
            </p>
            <p>
              <b>Żeby zaplanować stopniowe zmiany liczby</b> (np 2{"->"}6{"->"}8
              {"->"}5) z wykorzystaniem nowej metody należy postępować "od dziś
              w przyszłość" (np. zmienić liczbę na 6 od kwietnia, potem na 8 od
              czerwca, potem na 5 od listopada itd).
              <br />
              Albo po prostu wykorzystać starą metodę (edycję parametrów)
            </p>
            <p className="bg-danger">
              <b>
                Żadna z powyższych metod nie powoduje (jeszcze) automatycznej
                zmiany liczby sprzętu.
              </b>{" "}
              Mowa tylko o zmianie liczby wykorzystywanej na fakturze, jeśli
              konieczna jest zmiana liczby sprzętu,{" "}
              <b>należy to zrobić oddzielnie</b>, zgodnie z poniższą procedurą.
            </p>

            <ol>
              <li>(opcjonalnie) Zmienić cenę zgodnie z opisem wyżej.</li>
              <li>
                Zmienić liczbę w usłudze (do faktury):
                <ul>
                  <li>
                    <b>"Łatwiejsza", nowa metoda:</b>
                    <ol>
                      <li>Przy usłudze kliknąć "Edycja" {"->"} "liczba"</li>
                      <li>
                        Wpisać nową liczbę i ewentualnie datę od której ma
                        nastąpić zmiana
                      </li>
                    </ol>
                  </li>
                  <li>
                    <b>"Trudniejsza", ale dokładniejsza metoda:</b>
                    <ol>
                      <li>
                        Określić datę końca obecnego parametru (do kiedy klient
                        ma być liczony za dotychczasową liczbę sprzętu): kliknąć
                        parametr {"->"} podać datę zakończenia {"->"} zapisać.
                      </li>
                      <li>Dodać nowy parametr ze zaktualizowaną liczbą</li>
                    </ol>
                  </li>
                </ul>
              </li>
              <li>
                Zmienić liczbę sprzętu (do logistyki): zaplanować odpowiednie
                zadania (dostarczenie/zabranie) - daty nie muszą być zgodne z
                określonymi w powyższym punkcie.
                <br />
                <u>
                  Parametry usługi są wykorzystywane przy fakturowaniu, daty
                  dostarczenia/zabrania przy generowaniu kontrolek
                </u>{" "}
                <Button
                  onClick={() =>
                    props.setModalData({
                      show: true,
                      type: "info",
                      body: "Np. klient chce więcej toalet od piątku, ale nam łatwiej dostarczyć w środę. Wystarczy dodać nowy parametr usługi (fakturowanie) od piątku, a dostarczenie nanieść na środę. Przez chwilę program wyświetli alert o niezgodnej liczbie sprzętu, ale poza tym będzie ok: klient zostanie zafakturowany na nową liczbę od piątku, a kierowca dostarczy toaletę w środę.",
                    })
                  }
                >
                  Przykład
                </Button>
              </li>
              <li>
                (tylko dla dostarczeń) Dodanie dostarczonego sprzętu do już
                zaplanowanych zadań przy użyciu MEZ (opcja "edytuj usługi i
                sprzęt", szczegóły poniżej w części "Masowa Edycja Zadań (MEZ)")
              </li>
              <li>
                W przypadku zabierania sprzętu, po odhaczeniu zabrania program
                automatycznie usunie sprzęt z zaplanowanych zadań
              </li>
            </ol>
            <p className="bg-danger">
              <b>Uwaga!</b>
              <br />
              Zadania zaplanowane na danej lokalizacji powinny odzwierciedlać
              rzeczywistość. Czyli w przypadku dodawania nowego sprzętu/usług,
              które będą obsługiwane w ramach już zaplanowanych zadań (np.
              dochodzą nowe toalety albo nowa usługa obsługiwana podczas
              wcześniej zaplanowanych serwisów toalet) to{" "}
              <b>
                zamiast tworzyć nowe zadanie należy zmodyfikować już istniejące
              </b>
              . Można skorzystać z MEZ - "Masowej Edycji Zadań" opisanej
              poniżej. Jeśli w jakiejś sytuacji MEZ nie wystarczy -{" "}
              <b>proszę o natychmiastowy kontakt</b>
            </p>
          </Col>
          <Col>
            <img
              src={srvQtyChange}
              alt=""
              style={{ maxWidth: "100%", height: "350px" }}
            />
            <img
              src={srvQtyChangeConfirm}
              alt=""
              style={{ maxWidth: "100%", height: "450px" }}
            />
          </Col>
        </Row>
        <div>
          <hr />

          {/*
          perPcs services:
          when there is eqp (our or customers) invoice is created based on qty of serviced eqp
          when there is no eqp invoice is created by multipling jobs done and price (qty ALWAYS 1)
          
          
          
          
          */}
          <Row>
            <Col>
              <h4 id="perPcsSrv">Usługi naliczane "za serwis"</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Usługi "za serwis" nie mają liczby</b> (a dokładniej: zawsze
                mają liczbę = 1)
              </p>
              <p>
                Fakturując usługi naliczane "za serwis" program sprawdza liczbę
                wykonanych serwisów. Jeśli dla danej usługi przy serwisie był
                podpięty jakiś sprzęt (usługa sprzętowa albo sprzęt klienta) to
                jego liczba jest mnożona przez cenę usługi. Jeśli nie ma sprzętu
                (usługa bezsprzętowa) to program obciąża za każdy serwis ceną z
                usługi.
              </p>

              <p>
                <b>Usługi sprzętowe i toalety klienta:</b>
              </p>
              <p>
                Dodając usługę należy wpisać cenę za serwis{" "}
                <u>jednej toalety</u> (a nie np. za jeden serwis trzech toalet).
                Program sam sprawdzi, ile toalet było podpiętych pod wykonane
                serwisy (na tej samej zasadzie dla sprzętów innych niż toalety).
              </p>
              <p>
                <b>Usługi bezsprzętowe (szambo, sprzątanie itd.):</b>
              </p>
              <ul>
                <li>
                  jeśli zadanie dla danej usługi może być w przyszłości wykonane
                  częściowo (np. dwa szamba na lokalizacji, może się zdarzyć, że
                  do jednego nie będzie dostępu) - należy dodać osobne usługi (w
                  przykładzie trzeba dwa razy zarejestrować usługę "opróżnienie
                  szamba"). Serwisy planujemy dla obu usług, ale jeśli którymś
                  razem będzie zrobione tylko jedno to wystarczy odznaczyć jedną
                  usługę.
                </li>
                <li>
                  jeśli zadania są niepodzielne (np. sprzątanie) - wystarczy
                  dodać usługę raz z odpowiednią ceną. Wyjątkowe przypadki
                  trzeba obsłużyć modyfikacją faktury{" "}
                  {createManualBtn("7-invMods")}.
                </li>
              </ul>
              <h5>
                Postępowanie z usługami zarejestrowanymi przed wersją 1.12.0
              </h5>
              <p>
                Od wersji 1.12.0 nie ma możliwości dodania liczby dla usługi "za
                serwis". <br />W okresie przejściowym usługi już zarejestrowane
                w ten sposób zostaną prawidłowo zafakturowane (program weźmie
                pod uwagę liczbę), ale ostatecznie{" "}
                <u>wszystkie lokalizacje powinny zostać poprawione</u> (tym
                bardziej, że ostatecznie to ułatwi pracę - mniej kombinowania z
                ceną, liczbą i modyfikacjami faktur dla takich usług)
                <br />
              </p>
              <p>
                Dla ułatwienia na liście lokalizacji dodałem checkbox "DO
                POPRAWY". Po zaznaczeniu program wyszuka wszystkie lokalizacje,
                które mają usługę "za serwis", dla której liczba jest różna od
                1.
              </p>
              <p>Poprawianie liczby:</p>
              <ul>
                <li>
                  na lokalizacji znaleźć usługę "za serwis" z liczbą inną niż 1
                  (<u>trzeba zaznaczyć "pokaż parametry"</u>)
                </li>
                <li>
                  określić, czy konieczna jest zmiana ceny:
                  <ul>
                    <li>
                      jeśli w zadaniach <u>dla tej usługi</u> był podpięty
                      sprzęt i jego liczba jest zgodna z liczbą <u>w usłudze</u>
                      : nie powinno być potrzeby zmiany ceny usługi
                    </li>
                    <li>
                      jeśli dla usługi nie ma sprzętu: należy zmienić cenę
                      usługi mnożąc ją przez liczbę (np. "Sprzątanie" 2 szt. po
                      150 zł {"->"} 1 szt. 300 zł). <br />
                      Trzeba tak zrobić, bo program przy fakturowaniu potraktuje
                      każdy serwis jako 1 szt i pomnoży przez cenę.
                    </li>
                    <li>
                      jeśli jest sprzęt, ale liczba w zadaniach nie zgadza się z
                      liczbą w usłudze (np. "Serwis toalet CC" 3 szt. po 75 zł,
                      ale w zadaniach są tylko dwie): należy wyjaśnić która
                      wersja jest prawidłowa.{" "}
                      <b>Dane w programie mają odzwierciedlać rzeczywistość</b>!
                      Należy ustawić cenę zgodnie z tym, jak program ma naliczyć
                      fakturę za serwis <u>każdej sztuki sprzętu.</u>
                    </li>
                    <li>
                      a w razie sytuacji wątpliwych: wysłać mi maila z linkiem i
                      prośbą o sprawdzenie
                    </li>
                  </ul>
                </li>
                <li>
                  do zmiany ceny najlepiej skorzystać z menu "edycja {"->"}{" "}
                  zmiana ceny" wybierając opcję "od ostatniej faktury/od
                  rozpoczęcia usługi"
                </li>
                <li>zapisać zmiany</li>
                <li>
                  jeśli cena jest już prawidłowa to należy (znowu) zaznaczyć
                  checkbox "pokaż parametry" i kliknąć parametr z liczbą inną
                  niż 1.
                </li>
                <li>
                  w edycji parametru nie trzeba nic zmieniać, wystarczy wcisnąć
                  "zapisz". Program (zgodnie z wyświetlonym komunikatem)
                  automatycznie zmieni liczbę dla tego parametru na 1
                </li>
                <li>
                  powtórzyć powyższe dla wszystkich parametrów wszystkich usług
                  "za serwis" na lokalizacji.
                </li>
              </ul>
              <p>
                Po powyższej operacji lokalizacja nie powinna już pojawić się w
                wyszukiwarce z zaznaczoną opcją "DO POPRAWY" i zafakturuje się
                zgodnie z nowymi zasadami.
              </p>
            </Col>
          </Row>

          <>
            <Row>
              <Col>
                <h4 id="subSrvs">Usługi podrzędne</h4>
              </Col>
            </Row>
            <p className="bg-dangerLight" style={{ fontSize: "1.5rem" }}>
              <b>
                Uwaga! Moduł w trakcie testów! Poza dodawaniem dezynfekcji do
                wynajmu/serwisowania toalet proszę nie korzystać na prawdziwych
                lokalizacjach bez mojej zgody.
              </b>
            </p>
            <Row>
              <Col>
                <p>
                  Niektóre usługi umożliwiają podpiącie usług podrzędnych.{" "}
                  <b>Przykładowym wykorzystaniem</b> jest podpięcie usługi
                  "dezynfekcja" do wynajmu toalet, albo kilku różnych usług (np.
                  sprzątanie, ogrodzenia i dozór) do usługi imprezowej.
                  <br />
                  Usługi podrzędne można też wykorzystać jeśli klient chce
                  połączyć kilka usług w jedną pozycję na fakturze. Dla
                  przypomnienia: jedna usługa na lokalizacji = jedna linia
                  faktury. Natomiast usługa podrzędna nie powoduje powstania
                  nowej linii faktury (z wyjątkiem opisanym w części "Usługi
                  podrzędne a faktura")
                </p>
                <h5>Dodawanie usług podrzędnych</h5>
                <p>
                  Usługi podrzędne podpina się po dodaniu normalnej usługi,
                  korzystając z przycisku edycja {"->"} usługi podrzędne.
                </p>
                <p>
                  Zaznaczenie checkboxa przy usłudze umożliwia uwzględnienie jej
                  w zadaniach.
                </p>
                <p>
                  Wpisanie liczby w kolumnie "Doliczyć za serwis" powoduje
                  automatyczne dodanie tej kwoty do faktury (szczegóły poniżej w
                  części "Usługi podrzędne - dodatkowa opłata").
                </p>
                <p>
                  Wpisanie czegokolwiek w kolumnie "Info na fakturę" spowoduje,
                  że doliczona kwota będzie widoczna w dodatkowej pozycji na
                  fakturze (jeśli nic nie jest wpisane, zwiększana jest kwota
                  usługi głównej, <b>bez dodawania nowej pozycji</b>, szczegóły
                  w części "Usługi podrzędne a faktura")
                </p>
                <p>
                  Wciśnięcie przycisku "Skrócona instrukcja" na górze modala
                  powoduje wyświetlenie podstawowych informacji dotyczących
                  obsługi usług podrzędnych.{" "}
                  <b>
                    To służy tylko odświeżeniu informacji i nie zastąpi lektury
                    niniejszej instrukcji.
                  </b>
                </p>
                <p>
                  Po podpięciu usługi podrzędne pojawiają się w edycji zadań,
                  MEZ i odhaczaniu pod sprzętem.
                </p>
                <h5 id="subSrvJobsEdit">
                  Dodawanie usług podrzędnych a <b>zadania</b>
                </h5>
                <p className="bg-danger">
                  Edycja usług podrzędnych powoduje automatyczną zmianę
                  wszystkich zaplanowanych zadań{" "}
                  <Button
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        body: (
                          <>
                            <p>
                              Przykładowa lokalizacja ma dwie usługi: "1.
                              Wynajem i opróżnianie toalet" oraz "2. Opróżnianie
                              szamba"
                            </p>
                            <p>
                              Przy dodaniu usługi podrzędnej "dezynfekcja"{" "}
                              <u>dla toalet</u> program automatycznie podepnie
                              ją dla każdego <u>zaplanowanego</u> zadania, które
                              uwzględnia tą usługę (czyli dla wszystkich
                              serwisów dotyczących usługi "1. Wynajem i
                              opróżnianie toalet").
                            </p>
                            <p>
                              Jeśli potem odznaczę usługę "dezynfekcja" to
                              program usunie ją ze wszystkich zaplanowanych
                              zadań, które mają podpiętą usługę główną ("1.
                              Wynajem i opróżnianie toalet")
                            </p>
                            <p>
                              Powyższe zmiany <u>nie będą miały wpływu</u> na
                              szambo, ponieważ usługi podrzędne są
                              dodawane/odejmowane z usługi "1. Wynajem i
                              opróżnianie toalet".{" "}
                            </p>
                          </>
                        ),
                      })
                    }
                  >
                    Przykład
                  </Button>
                </p>
                <p>
                  Naniesione automatycznie zmiany zawsze można poprawić
                  korzystając z MEZ (edycja usług i sprzętu)
                </p>
                <hr />
                <h5>Usługi podrzędne - dodatkowa opłata</h5>
                <p>
                  Dla niektórych usług można dodać opłatę. Wpisana kwota
                  zostanie doliczona do faktury.{" "}
                  <u>
                    Niezależnie od rodzaju naliczenia dla usługi głównej, opłata
                    za usługi podrzędne zawsze jest naliczana "za serwis"
                  </u>
                  . <br />
                  Czyli jeśli w polu "Doliczyć za serwis" jest wpisana
                  jakakolwiek liczba <u>i była podpięta do serwisów</u> to
                  zostanie pomnożona przez liczbę <b>wykonanych</b> serwisów i
                  sprzętu, którego dotyczył serwis.{" "}
                  <Button
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: (
                          <>
                            <p>
                              W poniższym przykładzie doliczone zostanie 35 zł
                              za serwis każdej toalety (serwis musi być
                              "wykonany"), czyli do faktury zostanie doliczone
                              70 zł.
                              <br /> Jeśli takich serwisów wykonano 3 (w sumie 6
                              toalet) to dopłata wyniesie 210 zł.
                            </p>
                            <img src={subSrvExtraFeeJobDetails} alt="" />
                          </>
                        ),
                      })
                    }
                  >
                    przykład
                  </Button>
                </p>
                <p>
                  Jeśli jakaś usługa nie umożliwia dodania dodatkowej opłaty, a
                  powinna <b>to należy zgłosić to kierownikowi</b>
                </p>
                <h5>Usługi podrzędne a faktura</h5>
                <p>
                  W kolumnie "Info na fakturę" można dodać opis, jaki ma
                  pojawiać się na fakturze. Jeśli jest tam cokolwiek wpisane to{" "}
                  <u>na fakturze pojawi się kolejna pozycja</u>. W innym wypadku
                  dodatkowa opłata jest doliczana do ceny jednostkowej usługi
                  głównej.
                  <br />
                  Pole "Info na fakturę" działa analogicznie jak "Opis na fv"
                  przy dodawaniu usługi głównej. Można tam wpisywać znaczniki
                  typu {"{adresLok}"} itp. Wciśnięcie przycisku "wczytaj opis"
                  powoduje dodanie domyślnego opisu dla usługi.
                </p>
              </Col>
              <Col>
                <img
                  src={subSrvEditModal}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </Col>
            </Row>
          </>

          <Row>
            <Col>
              <h4>Usługi krótkoterminowe</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <i>
                  (klasycznie usługa krótkoterminowa to taka, która trwa poniżej
                  30 dni, ale <b>możliwe są odstępstwa</b> - szczegóły u
                  kierowników
                </i>
              </p>
              <p>
                Do obsługi krótkoterminowej służą dwa rodzaje usług (rozróżniane
                po <b>rodzaju naliczenia</b>):
              </p>
              <h5>"krótki termin"</h5>
              <p>
                To "standardowa" usługa imprezowa, wyceniana wg schematu "cena X
                za pierwszy tydzień, potem Y za każdy kolejny dzień. Każdy
                serwis poza dostarczeniem i zabraniem to Z".
                <br />
                Przy dodawaniu usługi działa większość zasad opisanych powyżej,
                jednak należy podać wszystkie ceny (X, Y i Z w powyższym
                przykładzie).
                <br />
                Dodatkowo istnieje możliwość podania innego okresu, w którym
                obowiązuje pierwsza, zryczałtowana cena X.{" "}
                <b>
                  Okres ten można zmienić z domyślnych 7 dni na inny po
                  akceptacji kierownika
                </b>
                . Maksymalny okres wynosi 14 dni.
              </p>
              <h5>"ryczałt impreza"</h5>
              <p>
                Usługa służąca do obsługi bardziej skomplikowanych imprez.
                Należy podać tylko cenę za całość imprezy. Dodatkowo wymagane
                jest opisanie, z czego składa się cena w okienku "kalkulacja".
              </p>
              <h5>Usługi długo- i krótkoterminowe - różnice</h5>
              <p>
                Usługi z naliczeniem "krótki termin" i "ryczałt impreza"
                podlegają dodatkowym ograniczeniom:
              </p>
              <ul>
                <li>może być tylko jeden parametr</li>
                <li>liczba zawsze wynosi 1</li>
                <li>
                  edycja ceny możliwa tylko przez podgląd szczegółów imprezy
                  ("szczegóły" {"->"} "edytuj")
                </li>
                <li>
                  edycja ceny możliwa tylko do momentu wygenerowania pierwszej
                  faktury
                </li>
                <li>
                  tylko "ryczałt impreza": maksymalny czas trwania to miesiąc
                </li>
                <li>
                  tylko "ryczałt impreza": wymagane dodanie opisu, skąd wzięła
                  się cena (okienko "kalkulacja")
                </li>
              </ul>
            </Col>
            <Col>
              <p>Dodawanie usługi z naliczeniem "krótki termin":</p>
              <img
                src={locationAddShortTermSrv}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
        </div>
        <hr />

        <Row>
          <Col>
            <h4>Usługi związane ze sprzętem klienta</h4>
            <p>
              <i>(serwisowanie toalet klienta itp)</i>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Od wersji 1.10.0 zmienił się sposób obsługi usług typu "Serwis
              TK". Przy dodawaniu takiej usługi na lokalizacji należy podpiąć
              sprzęt, jak przy usłudze sprzętowej. <br />
              Różnicą jest, że taki sprzęt nie wymaga dostarczenia ani zabrania
              - dodaje się go przy dodawaniu usługi.
            </p>
            <p>
              <b>Edycja sprzętu klienta na lokalizacji</b> - aby zmienić liczbę
              toalet/sprzętu należącego do klienta dla danej usługi należy z
              menu "Edycja" obok tej usługi wybrać pozycję "sprzęt" (zrzut
              obok).
              <br />
              <b>
                Wprowadzone dane <u>nadpiszą</u> dotychczasowy sprzęt podpięty
                do tej usługi.
              </b>
              <br />
            </p>
            <p>
              Dzięki temu podejściu można zaplanować zadania obejmujące nie
              wszystkie toalety klienta na lokalizacji, odhaczyć serwis tylko
              części toalet itd.
            </p>
            <p>
              <b>Fakturowanie:</b> jeśli dla usługi rozliczanej{" "}
              <u>"za serwis"</u> jest podpięty sprzęt klienta to program do
              fakturowania bierze liczbę sprzętu faktycznie przeserwisowanego w
              każdym zadaniu (ignoruje liczbę z parametru usługi).
            </p>
          </Col>
          <Col>
            <img src={CustEqpEdit} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        {props.user.perm.servicesManage.w ? (
          <div>
            <Row>
              <Col>
                <h4>Zarządzanie usługami</h4>

                <p>
                  <i>Widoczne tylko przy odpowiednich uprawnieniach</i>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p id="srvPriority">
                  <b>Priorytet usługi</b> - przy edycji/dodawaniu usługi można
                  ustawić jej priorytet. Chodzi o to, w jakiej kolejności usługi
                  są wyświetlane przy dodawaniu ich na lokalizacji.
                  <u>Im wyższy priorytet</u> tym wyżej będzie na liście. Można
                  stosować dowolne wartości w rozsądnym zakresie (powiedzmy od
                  -30 000 do + 30 000).
                  <br />
                  Można korzystać z liczb ujemnych, jeśli chcemy coś na koniec
                  listy zepchnąć. Usługi bez dodanego priorytetu są traktowane
                  jakby miały zero.
                  <br />
                  Podsumowując: im wyższy priorytet, tym wyżej usługa będzie na
                  liście ( Wynajem toalet powinien mieć wysoki priorytet, np.
                  1000, a Najem placu -1000, żeby się nie plątał)
                  <br />
                  <b>
                    Zmiany priorytetu mają wpływ tylko na listę wyboru usługi na
                    lokalizacji, nie na listę w Zarządzaniu.
                  </b>
                </p>
              </Col>
            </Row>
          </div>
        ) : null}
        <hr />
        <Row>
          <Col>
            <h4>Zadania</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <b>Zadania</b> dzielą się na serwisy, dostarczenia i zabrania.
              <br />
              Każde zadanie musi mieć jeden z następujących stanów:
            </p>

            <ul>
              <li>zaplanowane</li>
              <li>zlecone</li>
              <li>wykonane</li>
              <li>odwołane - z winy naszej lub klienta: "my"/"klient"</li>
              <li>niewykonane - z winy naszej lub klienta: "my"/"klient"</li>
              <li>usunięte</li>
              <li>archiwum</li>
            </ul>
            <p>
              <i>
                "Przepływ" stanów przedstawia zrzut obok, dostępny też z poziomu
                każdej lokalizacji po kliknięciu przycisku "Legenda" nad
                kalendarzem.
              </i>
              <br />
              <b>
                Standardowo każde zadanie najpierw jest zaplanowane, po
                wydrukowaniu kontrolki zmienia stan na "zlecone", potem jest
                "wykonane" a na koniec dostaje stan "archiwum".
              </b>
            </p>
            <p>
              W przypadku rezygnacji z zadania, w zależności od obecnego stanu,
              zmieni się ono na:
            </p>
            <ul>
              <li>
                Usunięte - jeśli zrezygnowaliśmy z realizacji przed wydrukiem
                kontrolek
              </li>
              <li>
                Odwołane - jeśli było już "zlecone" (kontrolki wydrukowane)
              </li>
              <li>Niewykonane - jeśli kierowca już dojechał na miejsce</li>
            </ul>
            <p>
              Jeśli zadanie jest odwołane lub niewykonane trzeba wybrać z czyjej
              winy: naszej lub klienta
            </p>
            <p>
              <b>
                Na kalendarzu domyślnie nie widać zadań usuniętych i
                zarchiwizowanych, aby je pokazać należy zaznaczyć checkbox
                "pokaż wszystkie"
              </b>
            </p>
            <h5>Kalendarz - objaśnienia</h5>
            <p>
              Data zaznaczona kolorem oznacza, że w danym dniu jest przynajmniej
              jedna aktywna usługa.
              <br />
              Cała komórka wypełniona kolorem to oznaczenie "dzisiaj" <br />
              Ikony w komórkach oznaczają poszczególne zadania, najechanie na
              ikonę wyświetla podstawowe informacje, kliknięcie pozwala
              podejrzeć wszystkie szczegóły i przejść do edycji.
              <br />
              <b>Informacje zawarte w ikonach:</b>
            </p>
            <ul>
              <li>Litera w środku oznacza Serwis/Zabranie/Dostarczenie</li>
              <li>Kolor oznacza stan zadania zgodnie z diagramem</li>
              <li>
                Kształt zależy od rodzaju usługi. Wszystkie kształty opisane są
                w legendzie dostępnej po kliknięciu przycisku nad kalendarzem.
                Jeśli danego dnia są więcej niż dwa zadania wyświetlany jest
                specjalny kształt (jak 15 na zrzucie obok) - po kliknięciu
                trzeba wybrać zadanie z listy.
              </li>
            </ul>
          </Col>
          <Col>
            <img src={locationCalendar} alt="" style={{ maxWidth: "100%" }} />
            <img src={jobStates} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Dodawanie zadań</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Zadania można dodawać klikając przycisk "Dodaj zadania" nad
              kalendarzem, lub symbol plusa w wybranym dniu. Pojawiający się
              modal jest taki sam, ale w drugim przypadku{" "}
              <u>
                zostanie automatycznie wstawiona data wybranego dnia, a
                częstotliwość będzie ustawiona na "jednorazowo"
              </u>
            </p>
            <hr />
            <h5>Zasady określania harmonogramu</h5>
            <p>
              W przypadku zadań z częstotliwością inną niż "jednorazowo" program
              umożliwi wybór dat od-do i dnia/dni tygodnia. <b></b>
            </p>
            <p>
              Przy dodawaniu zadań program bierze pod uwagę oba te kryteria
              łącznie. Czyli jeśli "data rozpoczęcia" jest ustawiona na datę,
              która wypada w <u>poniedziałek</u>, a "dzień tygodnia" jest
              ustawiony na <u>czwartek</u> to program zaplanuje zadania{" "}
              <u>zaczynając od pierwszego czwartku</u> następującego po wybranej
              dacie rozpoczęcia, analogicznie przy dacie zakończenia (ostatni
              czwartek przed datą zakończenia).
              <br />
              <b>Data zakończenia jest obowiązkowa</b> i nie może być późniejsza
              niż <u>trzy lata</u> od dnia planowania zadań.
              <br />
              Jeśli ustawiona data będzie się zbliżać, a usługa będzie nadal
              aktywna, to program poinformuje o konieczności ręcznego
              przedłużenia zadań.
            </p>
            <p>
              <b>Uwaga!</b>
              <br />
              Data dostarczenia sprzętu nie ma wpływu na fakturowanie. Klient
              zostanie obciążony od daty rozpoczęcia usługi, dostarczenie może
              być wcześniej lub później.{" "}
              <u>
                To jest celowy zabieg, dzięki któremu można dostarczać/zabierać
                sprzęt w najwygodniejszym dla nas momencie, bez wpływu na
                fakturę.
              </u>
            </p>
          </Col>
          <Col>
            <img src={addJobsModal} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h3>Karta "Szczegóły"</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <h4>Notatki</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            Na karcie "Szczegóły" można dodawać i edytować notatki dla
            lokalizacji.
            <p>Typy notatek:</p>
            <ul>
              <li>
                Główna - najważniejsze informacje dotyczące lokalizacji.{" "}
                <b>Może być tylko jedna główna notatka</b>, jej treść wyświetla
                się również na karcie "Ogólne" w polu "Notatka" (dawniej "Uwagi
                do lokalizacji")
              </li>
              <li>
                Windykacyjna - informacje kluczowe dla windykacji (np. kontakt
                do płatności)
              </li>
              <li>Logistyczna - informacje kluczowe dla logistyki</li>
              <li>
                Na kontrolkę - informacje ważne dla kierowców.{" "}
                <b>
                  Treść tej notatki zostanie automatycznie dodana do kontrolki
                  kierowcy
                </b>
                .
              </li>
              <li>
                Fakturowa - informacje ważne przy wystawianiu faktur. W
                przypadku wybrania tego typu pojawiają się dodatkowe pola:{" "}
                <ul>
                  <li>
                    Podtyp - ogólna lub jednorazowa. <b>Ogólna</b> będzie
                    wyświetlona zawsze przy wystawianiu faktury,{" "}
                    <b>jednorazowa</b> tylko zgodnie z poniższym podpunktem:
                  </li>
                  <li>
                    Data zdarzenia - pole dostępne przy wybraniu podtypu
                    "jednorazowa". Notatka taka pojawi się <u>tylko jeśli</u>{" "}
                    okres fakturowania będzie obejmował wybraną datę zdarzenia
                  </li>
                </ul>
              </li>
              <li>
                Inna - wszystko, co nie mieści się w powyższych kategoriach.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Notatki fakturowe - dodawanie</h5>
            <p>
              Notatkę fakturową można też dodać przy zmianie stanu zadania (np.
              po kliknięciu zadania w kalendarzu, "Zmiana stanu" na zrzucie
              obok) i przy odhaczaniu ("Odhaczanie" na zrzucie obok). W obu
              sytuacjach, jeśli zaznaczony jest checkbox "dodaj notatkę
              fakturową", to jej pierwsza część zostanie wygenerowana
              automatycznie, w okienku formularza (żółte ramki na zrzucie obok)
              można dopisać własne uwagi.
              <br />
              <u>
                Pozostawienie pustego okienka spowoduje <b>utworzenie</b>{" "}
                notatki zawierającej tylko tekst wygenerowany automatycznie
              </u>
            </p>
          </Col>
          <Col>
            <img src={invNoteAdding} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h4 id="7-invMods">Modyfikacje faktur</h4>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>
              Modyfikacja faktury to sposób na dodanie jednorazowego rabatu (lub
              doliczenia) do wybranej usługi. Modyfikacja zostanie wyświetlona i
              uwzględniona podczas generowania faktury.
              <br />
              Aby udzielić rabatu należy wpisać kwotę ze znakiem "-", inaczej
              wpisana kwota zostanie <b>doliczona</b> do faktury.
            </p>
            <p>
              Podczas dodawania modyfikacji należy wskazać datę zastosowania -
              jeśli podczas fakturowania data ta "wpadnie" w zakres dat
              generowanej faktury to modyfikacja zostanie zastosowana.
            </p>
            <p>
              Pole <b>Info na fakturę</b> trafi do opisu danej pozycji (linii)
              na fakturze. Np. jeśli w polu wpisano "wyjątkowe zabrudzenie" to
              na fakturze pojawi się dopisek "doliczono kwotę 50 zł: wyjątkowe
              zabrudzenie".
              <br />
              Pole to nie jest wymagane.
            </p>
            <p>
              <b>Opis</b> - pole na podanie bardziej szczegółowej informacji,{" "}
              <u>tylko do użytku wewnętrznego</u>
            </p>
            <p>
              Jeśli Checkbox <b>Pokazywać na fakturze</b> nie jest zaznaczony to
              na fakturze nie pojawią się żadne dodatkowe informacje.{" "}
              <u>Cena ulegnie zmianie</u>
            </p>
            <p>
              Uwaga techniczna na temat naliczania modyfikacji:
              <br />
              Aby zachować poprawność faktury, modyfikacja wpływa na{" "}
              <u>cenę jednostkową</u>. Przykładowo: usługa z naliczeniem "za
              dzień" i ceną 10 zł. Przy fakturowaniu za 30 dni{" "}
              <u>wartość netto</u> wyniesie 300 zł (10 zł * 30 dni). Przy
              zastosowaniu modyfikacji "rabat 30 zł" cena jednostkowa spadnie do
              9 zł, więc wartość netto wyniesie 270 zł (9 zł * 30 dni) - rabat
              został uwzględniony w odpowiedniej kwocie.
              <br />
              Problem pojawi się jeśli kwoty modyfikacji nie będą się dzielić
              "równo". Jeśli w powyższym przykładzie rabat wyniesie 50 zł to
              cena jednostkowa zostanie obniżona o 1,6666666(6) = 1,67 zł (czyli
              do 8,33 zł) a wartość netto wyniesie 249,90 (8,33 zł * 30 dni) -{" "}
              <b>realnie udzielony rabat to 50,10 zł</b>
              <br />
              <Button
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    body: "Program przy generowaniu faktury liczy ilość dni/miesięcy/serwisów i mnoży przez zdefiniowaną cenę usługi. Aby uwzględnić modyfikację, jej wartość jest dzielona przez obliczoną ilość i dodawana/odejmowana od pierwotnej ceny usługi",
                  })
                }
              >
                Info na temat sposobu obliczania
              </Button>
            </p>
          </Col>
          <Col>
            <img src={invModModal} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>

        <div>
          <Row>
            <Col>
              <h3>Masowa Edycja Zadań (MEZ)</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Żółty przycisk "MEZ" widoczny nad kalendarzem umożliwia
                jednoczesne modyfikowanie wielu zadań.
                <br />
                Przy pomocy MEZ można zmieniać tylko <b>zaplanowane</b> zadania.
              </p>
              <p>
                Najpierw należy wybrać, czy zmiana ma dotyczyć zadań z
                konkretnego zakresu dat, czy wszystkich.
              </p>
              <p>
                W dalszej części okna można wybrać kryteria.{" "}
                <u>
                  Modyfikacje zostaną zastosowane <b>tylko</b> do zadań
                  spełniających <b>wszystkie</b> wybrane kryteria
                </u>
                .
              </p>
              <h5>Częstotliwość</h5>

              <p>
                <i>
                  Zachęcam do zapoznania się z przykładami dostępnymi po
                  kliknięciu odpowiedniego przycisku.
                </i>
              </p>
              <ul>
                <li>
                  "co X dni" - program naniesie zmiany na wszystkie zadania
                  występujące co wskazaną liczbę dni (przyjmując za początek
                  "data OD" jeśli wybrano zakres dat, lub datę pierwszego
                  zadania spełniającego kryteria wyszukiwania).{" "}
                  <Button
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: <JobsBatchEditDaysEx />,
                      })
                    }
                  >
                    przykłady
                  </Button>
                </li>
                <li>
                  "co X tygodni" - program naniesie zmiany na wszystkie zadania
                  występujące w tygodniu pierwszego zadania spełniającego
                  kryteria (lub tygodniu "daty OD" jeśli wybrano zakres dat) i
                  wszystkich zadaniach w tygodniach następujących co określoną
                  liczbę.
                  <Button
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: <JobsBatchEditWeeksEx />,
                      })
                    }
                  >
                    przykłady
                  </Button>
                </li>
                <li>
                  "co X wystąpień" - program zastosuje zmiany dla pierwszego
                  zadania (lub pierwszego z wybranego zakresu dat) i każdego
                  kolejnego "przeskakując" o wskazaną liczbę
                  <Button
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: <JobsBatchEditOccuranceEx />,
                      })
                    }
                  >
                    przykłady
                  </Button>
                </li>
              </ul>
              <div>
                <h5>Zadania dotyczące konkretnej usługi</h5>
                <p>
                  W wersji 1.7.0 dodałem kolejną opcję filtrowania zadań:
                  "Dotyczące usługi". W polu wyboru pojawiają się aktywne usługi
                  dla lokalizacji, po wybraniu którejkolwiek program zastosuje
                  zmiany tylko dla zadań, które dotyczą wybranej usługi. <br />
                  Po wybraniu usługi pojawia się checkbox "zadania dotyczące
                  tylko tej usługi" - jeśli będzie zaznaczony, to edycja będzie
                  dotyczyć tylko zadań, które mają podpiętą tylko i wyłącznie
                  wybraną usługę.
                  <Button
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        body: (
                          <p>
                            Przykład: lokalizaja z dwiema usługami, serwisy są
                            zaplanowane dwa razy w tygodniu: w poniedziałki
                            serwis dotyczy obu usług, w czwartki tylko drugiej.
                            <br />
                            Jeśli w MEZ wybiorę usługę 1 to edycja obejmie tylko
                            zadania poniedziałkowe (bo tylko wtedy są zadania
                            dotyczące tej usługi).
                            <br />
                            Jeśli w MEZ wybiorę usługę 2 i nie zaznaczę
                            checkboxa "zadania dotyczące tylko tej usługi" to
                            edycja będzie dotyczyć wszystkich zadań (bo zarówno
                            w poniedziałek jak w czwartek serwis dotyczy usługi
                            2)
                            <br />
                            Jeśli wybiorę usługę 2 i zaznaczę checkbox to edycja
                            obejmie tylko zadania czwartkowe (bo w poniedziałek
                            serwis dotyczy obu usług, a nie tylko drugiej).
                          </p>
                        ),
                      })
                    }
                  >
                    przykład
                  </Button>
                </p>
              </div>
              <hr />
              <p>
                Poniżej trzeba zaznaczyć rodzaj modyfikacji. Zależnie od
                wybranego rodzaju inne mogą być niedostępne (np. nie można
                jednocześnie usunąć zadania i zmienić kierowcy). Zmiany można
                wprowadzać w formularzu wyświetlanym poniżej (różnym w
                zależności od typu zmiany).
              </p>
              <p>
                Po zaakceptowaniu wyświetli się modal z podsumowaniem zmian do
                wprowadzenia. Zmiany zapisywane są w historii każdego
                zmienionego zadania
              </p>
            </Col>
            <Col>
              <img src={jobsBatchEdit} alt="" style={{ maxWidth: "100%" }} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h4>Edycja usług i sprzętu</h4>
              <p>
                <u>Ten typ modyfikacji wymaga dodatkowych uprawnień</u>
              </p>
              <p>
                Po wybraniu edycji usług i sprzętu i kliknięciu "Zastosuj"
                pojawia się modal umożliwiający wybranie usług i sprzętu.{" "}
                <b>
                  Wybrane parametry <u>nadpiszą (usuną)</u> poprzednie dane we
                  wszystkich zadaniach spełniających kryteria.
                </b>
                <br />
                Uwaga! W przypadku wybrania edycji usług <u>
                  wszystkie zmiany
                </u>{" "}
                dotyczyć będą tylko zadań "serwis". Chodzi również o zmiany w
                uwagach, kierowcy itd.
              </p>
              <p>
                Zatwierdzenie zmian z przykładu obok spowoduje, że wszystkie{" "}
                <u>serwisy</u> spełniające wskazane w poprzednim kroku kryteria,
                będą miały podpięte wybrane usługi z wskazanymi liczbami
                sprzętu.
              </p>
            </Col>
            <Col>
              {" "}
              <img
                src={srvAndEqpBatchEdit}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 id="locationsSearch">Lista lokalizacji</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Program znajduje lokalizacje, które spełniają <u>wszystkie</u>{" "}
                wskazane kryteria.
                <br />
                Wyjątkiem jest wyszukiwanie po numerze lokalizacji, wtedy brany
                jest pod uwagę tylko numer i oddział,{" "}
                <u>pozostałe kryteria nie mają znaczenia.</u>
              </p>
              <p>
                <b>Pole "wyszukiwarka":</b> program znajduje lokalizacje, dla
                których wpisana fraza zgadza się z następującymi polami:{" "}
              </p>

              <ul>
                <li>Lokalizacja: nazwa, ulica, miasto, kod pocztowy</li>
                <li>Klient: nazwa i nazwa skrócona</li>
              </ul>
              <div>
                <h4>
                  Wyszukiwanie usług krótkoterminowych ("krótki termin" i
                  "ryczałt impreza")
                </h4>
                <p>
                  Po zaznaczeniu "Tylko imprezy" program wyszukuje tylko
                  lokalizacje, na których są usługi krótkoterminowe.
                </p>

                <p>
                  Dodatkowo można podać daty od-do (lub tylko jedną z nich).
                  Program wyszuka wtedy lokalizacji z imprezami, których daty
                  choćby zahaczają o podany zakres{" "}
                  <Button
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        body: (
                          <>
                            <p>
                              Wyszukiwanie z datami od 05 do 15 pokaże
                              lokalizacje z usługami:
                            </p>
                            <ul>
                              <li>od 10 do 12</li>
                              <li>od 01 do 20</li>
                              <li>od 01 do 10</li>
                              <li>od 10 do 20</li>
                              <li>
                                oraz wszystkie rozpoczynające się przed 15 i bez
                                daty zakończenia
                              </li>
                            </ul>
                            <p>Ale nie pokaże:</p>
                            <ul>
                              <li>od 01 do 04</li>
                              <li>od 16 do 30 (lub bez daty)</li>
                              <li>itd.</li>
                            </ul>
                          </>
                        ),
                      })
                    }
                  >
                    Przykład
                  </Button>
                </p>
              </div>
            </Col>
          </Row>
        </div>

        {/* =================== notes below ======================= */}
        {props.user.type.tester && props.showNotes && (
          <>
            <h2>
              Poniżej notatki - widoczne tylko dla osób z dodatkowymi
              uprawnieniami i nieobowiązkowe
            </h2>
            <h3>Zarządzanie usługami</h3>
            <p>
              checkbox <b>sprzęt klienta</b> - zaznaczenie powoduje, że do
              usługi trzeba podpiąć sprzęt klienta. Różni się od usługi
              sprzętowej tym, że nie ma możliwości dodawania dostarczenia -
              sprzęt podpina się i odpina modyfikując liczbę przy usłudze.
            </p>
            <p>
              Tworzenie lokalizacji - wybór kontaktów klienta - główny kontakt
              klienta {"->"} główny kontakt lokalizacji, wszystkie pozostałe
              jako "inne" na lokalizacji
            </p>
            <p>
              Dodać opis anulowania usług (tylko jeśli żadne zadanie nie jest
              inne niż zaplanowane)
            </p>
            <p>
              usługa "za serwis" - liczone są tylko <b>serwisy</b>, które są{" "}
              <b>wykonane</b>
            </p>
            <h3>Historia</h3>
            <h3>Dodawanie</h3>

            <h3>Karta "Ogólne"</h3>

            <h4>
              Usługi {"<=>"} Sprzęty {"<=>"} Zadania{" "}
            </h4>

            <p>Templatka, sprzęt numerowany i nienumerowany</p>

            <p>
              (wstępna wizja - może się zmienić. Będzie mieć znaczenie w
              zasadzie po wdrożeniu realnej inwentaryzacji. TYLKO GDZIE TO
              OPISAĆ - tu czy przy zadaniach?) Sprzęt przypisany do zadania
              zachowuje się różnie w zależności od stanu ZADANIA. Dla zadań w
              stanie 'zaplanowane' i 'zlecone' w przypadku podglądu danych
              przypisanego sprzętu pobierane są aktualne informacje z komponentu
              Sprzęt (opisać przykład). W momencie odhaczania zadania (zmiana na
              stan inny niż wymienione wcześniej) sprzęt staje się 'zamrożony'
              czyli niezależnie od późniejszych zmian, w podglądzie tego zadania
              widać informacje z momentu odhaczania. Zadania w innym stanie nie
              można edytować, więc nie ma też możliwości zmiany sprzętu
            </p>
            <p>
              Sprzęt można dodać tylko spośrób tych już dodanych do lokalizacji
            </p>

            <p>
              dla userow z dodatkowym uprawnieniem (cofanie stanu zadania): po
              zamrożeniu sprzętu i cofnięciu zadania do zaplanowanego przy
              edycji wyświetli się: 1. sprzęt zamrożony pod zadaniem, 2. sprzęt
              AKTUALNIE dostępny na lokalizacji.
              <br />
              Odpięcie sprzętu spod takiego zadania powoduje jego znikniecie
              (zostaje tylko w historii)
            </p>
            <h3>Karta "Szczegóły"</h3>
            <h4>Tabela "Kontakty"</h4>
            <h4>Tabela "Notatki"</h4>
            <p>Wyjaśnienie notatki głównej - tu czy w ogólnych?</p>
            <p>
              (pomysł) notatka "logistyczna" - automatycznie dodaje się do uwag
              w kontrolce. Może być tylko jedna (jak główna)
            </p>
            <p>
              Brak synchronizacji między katalogiem usług i usługami dodanymi do
              lokalizacji
            </p>
            <p>
              W momencie dodawania usługi do lokalizacji jest ona mrożona (tylko
              że z wyjątkiem kształtu ikony. )
            </p>

            <p>Można dodawać sprzęt tylko właściwy dla usługi</p>

            <p>
              parametry usługi można edytować: jeśli nie była zafakturowana{" "}
              {"->"} można edytować liczbę i obie daty, jeśli była zafakturowana{" "}
              {"->"} można edytować tylko datę zakończenia (aby zmienić liczbę -
              utwórz nowy parametr){" "}
            </p>
            <p>
              Wyszukiwarka dla sprzętu z modyfikacjami: szuka każdego sprzętu, z
              zaznaczoną moddyfikacją, jeśli nic nie zaznaczone to wyszukuje
              wszystkie
              <br />
              Ale przy dodawaniu zadania zawsze widać cały sprzęt dla usługi,
              niezależnie od filtrów
            </p>

            <p>
              Przy dodawaniu zadań sprawdzana jest zgodność z datami parametrów
              (rozdzielenie jednorazowo i powtarzalne)
            </p>
            <p>
              Sprzęt może być tylko w jednym miejscu, nawet jeśli jest tylko
              zaplanowany
            </p>
            <p>
              Obsługa liczby sprzętu - templatki i zerówki mogą mieć liczbę,
              inne nie
            </p>
            <p>
              Potwierdzenia dostarczenia - domyślnie wydruk przy planowaniu,
              jest możliwość z szczegółów zadania
            </p>
            <h4>Dodawanie zadań</h4>
            <p>
              nie można dodać zadań z usługą "za serwis" i datą wcześniejszą niż
              zafakturowano do
            </p>
          </>
        )}
      </div>
    )
}

const JobsBatchEditDaysEx = () => (
  <>
    <h4>Legenda:</h4>
    <p>
      Czerwone pole - dni spełniające kryterium częstotliwości
      <br />
      Czerwona ramka - zadania, które zostaną zmienione
    </p>
    <hr />
    <p>
      Data OD: 2021-08-02, Częstotliwość: co <b>dwa</b> dni{" "}
    </p>
    <img src={jobsBatchEditFreqDays2} alt="" />
    <hr />
    <p>
      Data OD: 2021-08-
      <u>
        <b>03</b>
      </u>
      , Częstotliwość: co <b>dwa</b> dni{" "}
    </p>
    <img src={jobsBatchEditFreqDays2v2} alt="" />
    <hr />
    <p>
      Data OD: 2021-08-02, Częstotliwość: co <b>trzy</b> dni{" "}
    </p>
    <img src={jobsBatchEditFreqDays3} alt="" />
  </>
)
const JobsBatchEditWeeksEx = () => (
  <>
    <h4>Legenda:</h4>
    <p>
      Czerwone pole - dni spełniające kryterium częstotliwości
      <br />
      Czerwona ramka - zadania, które zostaną zmienione
    </p>
    <hr />
    <p>
      Data OD: <b>2021-08-04</b>, Częstotliwość: co <b>dwa</b> tygodnie{" "}
    </p>
    <img src={jobsBatchEditFreqWeeks2} alt="" />
    <hr />
    <p>
      Data OD:{" "}
      <b>
        2021-08-<u>03</u>
      </b>
      , Częstotliwość: co <b>trzy</b> tygodnie{" "}
    </p>
    <img src={jobsBatchEditFreqWeeks3} alt="" />
  </>
)

const JobsBatchEditOccuranceEx = () => (
  <>
    <h4>Legenda:</h4>
    <p>
      Czerwone pole - dni spełniające kryterium częstotliwości
      <br />
      Czerwona ramka - zadania, które zostaną zmienione
    </p>
    <hr />
    <p>
      Data OD: 2021-08-02, Częstotliwość: co <b>dwa</b> wystąpienia{" "}
    </p>
    <img src={jobsBatchEditFreqOcc2} alt="" />
    <hr />
    <p>
      Data OD:{" "}
      <b>
        2021-08-<u>05</u>
      </b>
      , Częstotliwość: co <b>trzy</b> wystąpienia{" "}
    </p>
    <img src={jobsBatchEditFreqOcc3} alt="" />
  </>
)

export default ManualLocationsSection
