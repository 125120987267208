import { getQuarter, getYear, subMonths } from "date-fns"

import React from "react"
import { Row, Button, Form, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"
import renderSpinner from "../../utils/renderSpinner"

const SewageReportv2NewReportModal = ({ user, setModalData, refresh }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      branch: user.defaultBranch || user.allowedBranches[0],
      quarter: getQuarter(subMonths(new Date(), 1)),
      year: getYear(subMonths(new Date(), 1)),
      author: user.fullName,
    },
  })

  const submit = async (formData) => {
    try {
      setModalData({
        show: true,
        type: "info",
        header: "Info",
        body: renderSpinner("Generuję sprawozdanie..."),
        hideFooter: true,
      })
      const res = await fetch("/sewage/createReport_v2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
          }`
        )
      refresh()
      const resText = await res.text()
      return setModalData({
        show: true,
        type: "info",
        body: (
          <Button href={resText} download={true}>
            Kliknij aby pobrać sprawozdanie
          </Button>
        ),
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `${err.message || "nieokreślony błąd działania programu"}`,
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      Oddział:
      <Form.Control
        {...register("branch")}
        as="select"
        className=""
        autoComplete="chrome-off"
      >
        {user.allowedBranches.map((branch) => (
          <option key={`branchSelectOpt-${branch}`}>{branch}</option>
        ))}
      </Form.Control>
      Kwartał:
      {errors.quarter && (
        <Badge pill variant="warning">
          {errors.quarter.message}
        </Badge>
      )}
      <Form.Control
        {...register("quarter", { required: "Pole wymagane" })}
        as="input"
        type="number"
        className=""
        autoComplete="chrome-off"
        min="1"
        max="4"
      />
      Rok:
      {errors.year && (
        <Badge pill variant="warning">
          {errors.year.message}
        </Badge>
      )}
      <Form.Control
        {...register("year", { required: "Pole wymagane" })}
        as="input"
        type="number"
        className=""
        autoComplete="chrome-off"
        min="2021"
      />
      Nieczystości przekazane na <b>wszystkie</b> zlewnie w m<sup>3</sup>:
      {errors.sewageQty && (
        <Badge pill variant="warning">
          {errors.sewageQty.message}
        </Badge>
      )}
      <Form.Control
        {...register("sewageQty", { required: "Pole wymagane" })}
        as="input"
        type="number"
        className=""
        autoComplete="chrome-off"
        min="1"
        step="any"
      />
      Imię i nazwisko sporządzającego
      {errors.author && (
        <Badge pill variant="warning">
          {errors.author.message}
        </Badge>
      )}
      <Form.Control
        {...register("author", { required: "Pole wymagane" })}
        as="input"
        type="text"
        className=""
        autoComplete="chrome-off"
      />
      <Row className="justify-content-between mt-3">
        <Button variant="warning" onClick={() => setModalData({ show: false })}>
          Anuluj
        </Button>
        <Button variant="secondary" type="submit">
          Generuj
        </Button>
      </Row>
    </Form>
  )
}
export { SewageReportv2NewReportModal }
