import React from "react"
import { Button, Row } from "react-bootstrap"

const LocationsDetailsSrvCancelModal = ({
  location,
  srv,
  setModalData,
  refresh,
}) => {
  const submit = async () => {
    try {
      setModalData({ show: false })
      const res = await fetch("/locations/cancelSrv", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          srv_id: srv._id,
          srvHistoryLength: srv.history.length,
          loc_id: location._id,
          locationHistoryLength: location.history.length,
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "Nieokreślony błąd programu"
          }`
        )
      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        body: "Zmiana naniesiona",
        headerColor: "success",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "info", body: err.message })
    }
  }
  return (
    <div>
      <p>
        Czy na pewno chcesz anulować tą usługę? <br />
        <b>Operacja jest nieowracalna!</b>
      </p>
      <Row className="justify-content-between mx-2">
        <Button
          variant="secondary"
          onClick={() => setModalData({ show: false })}
        >
          Wróć
        </Button>
        <Button variant="danger" onClick={submit}>
          Potwierdź
        </Button>
      </Row>
    </div>
  )
}
export default LocationsDetailsSrvCancelModal
