const sortCustomers = (sortBy, customers) => {
  //* NAME
  if (sortBy.field === "name") {
    return customers.sort((a, b) => {
      const nameA = a.shortName?.toUpperCase() || a.name.toUpperCase()
      const nameB = b.shortName?.toUpperCase() || b.name.toUpperCase()
      if (sortBy.order === 1) {
        return nameA.localeCompare(nameB)
      } else {
        return nameB.localeCompare(nameA)
      }
    })
  }

  //* deadline
  if (sortBy.field === "deadline") {
    return customers.sort((a, b) => {
      const deadlineA = new Date(a.deadline) || 0

      const deadlineB = new Date(b.deadline) || 0

      if (sortBy.order === -1) {
        return deadlineA - deadlineB
      } else {
        return deadlineB - deadlineA
      }
    })
  }

  //* debt
  if (sortBy.field === "debt") {
    return customers.sort((a, b) => {
      const debtA = a.debt
      const debtB = b.debt
      if (sortBy.order === 1) {
        return debtA - debtB
      } else {
        return debtB - debtA
      }
    })
  }
  //* lastAction

  if (sortBy.field === "lastAction") {
    // when no eventDate for action it is set in prepareCustomers from historyEntry
    return customers.sort((a, b) => {
      const lastActionA = a.lastAction?.eventDate || 0
      const lastActionB = b.lastAction?.eventDate || 0
      if (sortBy.order === 1) {
        return lastActionA - lastActionB
      } else {
        return lastActionB - lastActionA
      }
    })
  }
}

export { sortCustomers }
