import { useEffect, useState, useContext, useRef } from "react"
import { Container } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"
import { LocationsByDistanceForm } from "./Form"
import { LocationsByDistanceMap } from "./Map"
import { LocationsByDistanceRender } from "./Render"

/* 
! MAP DISLAIMER
creating this component I wanted to prevent reloading map on each refresh
instead I wanted to load it only once and then just refresh markers
thats why I separate appending googleMapsScript (here in fetch) and rendering map (in render)

*/

const LocationsByDistanceFetch = () => {
  const user = useContext(UserContext)

  const mapRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [initialFetch, setInitialFetch] = useState(true)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [modalData, setModalData] = useState({ show: false })
  const [filters, setFilters] = useState({
    branch: user.defaultBranch || user.allowedBranches[0],
    dist: 50,
  })
  const [data, setData] = useState(null)
  const [branchCoords, setBranchCoords] = useState(null)
  const [configs, setConfigs] = useState(null)

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const res = await fetch(`/configs/GOOGLE_MAPS_API_KEY`)

        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych: ${res.status} - ${await res.text()}`
          )
        const resJSON = await res.json()

        setConfigs(resJSON)
        setInitialFetch(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `${err.message || "Błąd pobierania danych"}`,
        })
        setIsLoading(false)
      }
    }
    fetchConfigs()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await fetch(
          `/locations/getByDistanceToBase?branch=${filters.branch}&dist=${
            filters.dist * 1000
          }`
        )

        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych: ${res.status} - ${await res.text()}`
          )
        const resJSON = await res.json()
        setData(resJSON.locations)
        setBranchCoords(resJSON.branchCoords)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
        setIsLoading(false)
      }
    }

    fetchData()
  }, [filters])

  // add google map script element
  useEffect(() => {
    // add googleMapScript after fetching configs
    if (
      !document.getElementById("googleMapsScript") &&
      configs?.GOOGLE_MAPS_API_KEY
    ) {
      const script = document.createElement("script")
      script.src = `https://maps.googleapis.com/maps/api/js?key=${configs.GOOGLE_MAPS_API_KEY}`
      script.id = "googleMapsScript"
      document.body.appendChild(script)
      setMapLoaded(true)
    }
  }, [configs?.GOOGLE_MAPS_API_KEY])

  const renderContent = () => {
    if (initialFetch) return renderSpinner("ładuję komponent...")
    if (isLoading) {
      renderSpinner("pobieram dane...")
    } else
      return <LocationsByDistanceRender locations={data} configs={configs} />
  }

  return (
    <Container fluid>
      <LocationsByDistanceForm
        setFilters={setFilters}
        filters={filters}
        user={user}
        isLoading={isLoading}
      />
      {/* I render map here not in render to prevent reloading it on each refresh */}
      {mapLoaded && !isLoading ? (
        <LocationsByDistanceMap
          locations={data}
          branchCoords={branchCoords}
          configs={configs}
          mapRef={mapRef}
          filters={filters}
        />
      ) : null}
      {renderContent()}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export { LocationsByDistanceFetch }
