import React, { useContext, useState } from "react"
import _ from "lodash"
import { Container, Row, Button, Form, Alert } from "react-bootstrap"

import { LogCtx } from "../Fetch"

const RoutesPlanningChangeDriverModal = () => {
  const { user, jobs, date, setModalData, refresh, branchSelected } =
    useContext(LogCtx)

  const [fetchError, setFetchError] = useState(null)

  // create array of drivers that appears in jobs. First get uniq ids:
  const driversIds = _.uniq(
    jobs.map((job) => {
      if (job.hasDriver) return job.driver._id
      else return null
    })
  )
  // then re-create driversobjects, BUT using user.drivers to prevent accessing drivers that user has no permissions to:
  const oldDrivers = driversIds
    .map((_id) => {
      if (_id) return user.drivers.find((driver) => driver._id === _id)
      else return "BRAK"
    })
    .filter((el) => el)

  const submit = async (e) => {
    try {
      e.preventDefault()

      const res = await fetch("/jobs/swapDrivers", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          date: date,
          driverA: document.getElementById("select-driverA").value,
          driverB: document.getElementById("select-driverB").value,
          branch: branchSelected,
        }),
      })
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status === 409)
        return setFetchError(
          "Ktoś już edytował któryś z tych zadań. Zapisz wprowadzone dane w innym źródle, ośwież stronę i spróbuj jeszcze raz"
        )
      if (res.status !== 200)
        return setFetchError("Błąd komunikacji z serwerem")
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        header: "Sukces",
        body: "Zmiany naniesione",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd działania aplikacji")
    }
  }

  return (
    <Container>
      <Form onSubmit={(e) => submit(e)}>
        <Alert variant="danger">
          <b>Uwaga!</b>W wersji 1.13.3 działanie tego komponentu{" "}
          <b>uległo zmianie</b>!<br />
          Od teraz zamiast przepisywać zadania z jednego kierowcy do drugiego
          program <u>wymieni</u> zadania między kierowcami.
          <br />
          (aby uzyskać poprzedni efekt należy w karcie "Tabela" przefiltrować
          zadania wg kierowcy, zaznaczyć wszystkie i skorzystać z MEZ)
        </Alert>
        <p>Zamień zadania między kierowcami:</p>
        <Form.Control
          name="driverA"
          id="select-driverA"
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
        >
          {oldDrivers.map((driver) => (
            <option
              key={`old-driver-select-${driver._id || "noDriver"}`}
              value={driver._id || "BRAK"}
            >
              {driver.fullName || "BRAK"}
            </option>
          ))}
        </Form.Control>
        <p>i:</p>
        <Form.Control
          name="driverB"
          id="select-driverB"
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
        >
          {user.drivers.map((driver) => {
            if (driver.state === "aktywny" && driver.branch[branchSelected])
              return (
                <option
                  key={`new-driver-select-${driver._id}`}
                  value={driver._id}
                >
                  {driver.fullName}
                </option>
              )
            else return null
          })}
        </Form.Control>
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row className="justify-content-between">
          <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
          <Button type="submit">Potwierdź</Button>
        </Row>
      </Form>
    </Container>
  )
}
export default RoutesPlanningChangeDriverModal
