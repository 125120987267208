import { useEffect, useState } from "react"
import { format, subDays } from "date-fns"
import { Container, Button, Form } from "react-bootstrap"

import renderSpinner from "../../utils/renderSpinner"

import { DailyStatsRender } from "./Render"
import MyModal from "../../utils/Modals"

const DailyStatsFetch = () => {
  const [date, setDate] = useState(format(subDays(new Date(), 1), "yyyy-MM-dd"))
  const [isLoading, setIsLoading] = useState(true)
  const [stats, setStats] = useState(null)
  const [modalData, setModalData] = useState({ show: false })
  const [refreshCounter, setRefreshCounter] = useState(0)
  const refresh = () => {
    setRefreshCounter(refreshCounter + 1)
  }

  useEffect(() => {
    const getStats = async () => {
      try {
        setIsLoading(true)
        setStats(null)
        const res = await fetch("/reports/dailyStats", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            date: date || format(subDays(new Date(), 1), "yyyy-MM-dd"), // second for initial fetch, when date state is not set yet
          }),
        })
        if (res.status !== 200)
          throw new Error(
            `Błąd komunikacji z serwerem: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
            }`
          )

        const resJSON = await res.json()

        setStats(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "info",
          headerColor: "danger",
          header: "Błąd",
          body: err.message || "nieokreślony błąd działania programu",
        })
        setIsLoading(false)
      }
    }

    getStats()
    // to prevent inserting date in dependencies array:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter])

  const renderStats = () => {
    if (isLoading) return renderSpinner("pobieram dane")
    else if (!stats) return "brak danych"
    else return <DailyStatsRender stats={stats} />
  }

  return (
    <Container>
      <Form
        inline
        onSubmit={(e) => {
          e.preventDefault()
          refresh()
        }}
      >
        <Form.Control
          as="input"
          type="date"
          value={date}
          autoComplete="chrome-off"
          onChange={(e) =>
            setDate(format(new Date(e.target.value), "yyyy-MM-dd"))
          }
        />
        <Button type="submit" disabled={isLoading}>
          Pobierz
        </Button>
      </Form>
      {renderStats()}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}

export { DailyStatsFetch }
