import React, { useMemo } from "react"
import { useForm } from "react-hook-form"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Badge,
  Table,
} from "react-bootstrap"
import {
  format,
  min,
  differenceInCalendarDays,
  endOfMonth,
  isWithinInterval,
  startOfDay,
  endOfDay,
  isAfter,
  addDays,
} from "date-fns"
import _ from "lodash"

import { submitInvoices } from "./utils"
import InvModsTable from "../_shared/invoices/InvModsTable"

const BatchInvoicingModal = ({
  locations,
  setModalData,
  showUninvoicedDate,
  refresh,
  setLocationsSelected,
}) => {
  const startDate = useMemo(() => {
    // get array of all unoinvoiced params from all locations
    const uninvoicedParamsArr = _.flattenDeep(
      locations.map((loc) =>
        loc.services.map((locSrv) => [...locSrv.uninvoicedParams])
      )
    )

    // console.log(uninvoicedParamsArr)
    const startDatesArr = uninvoicedParamsArr.map((prm) =>
      prm.invoicedTo
        ? addDays(new Date(prm.invoicedTo), 1)
        : new Date(prm.startDate)
    )

    // console.log(startDatesArr)
    return format(min(startDatesArr), "yyyy-MM-dd")
  }, [locations])

  // get all uninvoiced services to use in InvModsTable
  const srvsChoosen = useMemo(() => {
    const srvsArr = []
    // for each location find (filter) services that have any uninvoiced param for choosen date
    locations.forEach((loc) => {
      srvsArr.push(
        loc.services.filter((locSrv) => locSrv.uninvoicedParams.length)
      )
    })

    // flatten result so each service is separate array element:
    return _.flatten(srvsArr)
  }, [locations])

  const defaultValues = {
    endDate: format(new Date(showUninvoicedDate), "yyyy-MM-dd"),
    startDate: startDate,
    invDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    sellDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    joinLocations: true,
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: defaultValues })
  const watchStartDate = watch("startDate")
  const watchEndDate = watch("endDate")

  // create array of all invoicing notes that apply to choosen invoicing period:
  const invNotes = useMemo(() => {
    //prevent error when end date is removed
    if (!watchEndDate) return []

    const notes = []
    // for each location search through notes and get all general invoicing notes and all one-time notes that apply to invoicing period
    locations.forEach((loc) => {
      const locNotes = loc.notes.filter((locNote) => {
        if (locNote.type !== "fakturowa") return false
        if (
          locNote.type === "fakturowa" &&
          locNote.subtype === "ogólna" &&
          locNote.state !== "archiwum"
        )
          return true
        // must be one-time invoicing note, so check if is within choosen interval
        // before checking interval check if end date is after start to prevent fns error:
        if (isAfter(new Date(watchEndDate), new Date(watchStartDate)))
          return isWithinInterval(new Date(locNote.eventDate), {
            start: startOfDay(new Date(defaultValues.startDate)),
            end: endOfDay(new Date(watchEndDate)),
          })
        else return false
      })
      if (locNotes.length) notes.push({ location: loc, invNotes: locNotes })
    })

    return notes
  }, [watchEndDate, locations, defaultValues.startDate, watchStartDate])

  return (
    <Container>
      <Form
        onSubmit={handleSubmit((formData) =>
          submitInvoices(
            formData,
            setModalData,
            locations,
            refresh,
            setLocationsSelected
          )
        )}
      >
        <Row>
          <Col>Data faktury:</Col>
          <Col>
            {errors.invDate && (
              <Badge pill variant="warning">
                {errors.invDate.message}
              </Badge>
            )}
            <Form.Control
              {...register("invDate", { required: "Pole wymagane" })}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Col>
          <Col>Data sprzedaży:</Col>
          <Col>
            {errors.sellDate && (
              <Badge pill variant="warning">
                {errors.sellDate.message}
              </Badge>
            )}
            <Form.Control
              {...register("sellDate", { required: "Pole wymagane" })}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Col>
        </Row>
        <Row>
          <Col>Fakturuj od:</Col>
          <Col>
            <Form.Control
              {...register("startDate")}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
              readOnly={true}
            />
          </Col>
          <Col>Fakturuj do:</Col>
          <Col>
            {errors.endDate && (
              <Badge pill variant="warning">
                {errors.endDate.message}
              </Badge>
            )}
            <Form.Control
              {...register("endDate", {
                validate: {
                  required: (v) => !!v || "Pole wymagane",
                  notEarlierThenChoosenDate: (v) => {
                    if (
                      differenceInCalendarDays(
                        new Date(defaultValues.endDate),
                        new Date(v)
                      ) <= 0
                    )
                      return true
                    else
                      return "Data nie może być wcześniejsza od wybranej na liście"
                  },
                },
              })}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Form.Check
              {...register("joinLocations")}
              type="checkbox"
              label="Połącz lokalizacje tych samych klientów w pojedyncze faktury"
              id="joinLocations"
            />
          </Col>
        </Row>
        <InvModsTable
          locations={locations}
          srvsChoosen={srvsChoosen}
          startDate={watchStartDate}
          endDate={watchEndDate}
          multiLoc={true}
        />
        {invNotes.length ? (
          <Table className="mt-2">
            <thead>
              <tr className="bg-warning">
                <th>Notatki fakturowe dla wybranych lokalizacji:</th>
              </tr>
            </thead>
            <tbody>
              {invNotes.map((locNotes) => (
                <React.Fragment
                  key={`locationInvoicingNotes-${locNotes.location._id}`}
                >
                  <tr className="fixed-values-table">
                    <td>{`${locNotes.location.customer.shortName}, lokalizacja: ${locNotes.location.street}, ${locNotes.location.city} (${locNotes.location.name})`}</td>
                  </tr>
                  {locNotes.invNotes.map((note) => (
                    <tr key={`invoicingNote-${note._id}`}>
                      <td>
                        {`(${note.subtype}${
                          note.subtype === "jednorazowa"
                            ? ` - ${format(
                                new Date(note.eventDate),
                                "yyyy-MM-dd"
                              )}`
                            : ""
                        }) - ${note.history[0].user.fullName || "bd"}: `}
                        <b>{note.body}</b>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        ) : null}
        <Row>
          <Button type="submit" variant="secondary" className="ml-auto mr-3">
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default BatchInvoicingModal
