/**
 * returns dates to use as defaults for invoices forms. If today is < buffer returns last month, else returns this month
 * @param {Number} buffer (default 10) number of calendar days after which should return curr month
 * @returns {{startDate:date, endDate:date}} object with start and end dates
 */

import { endOfMonth, getDate, startOfMonth, subMonths } from "date-fns"

const getDefaultDates = (buffer = 10) => {
  if (buffer < 1) throw new Error("buffer must be >= 1")
  if (buffer > 28) throw new Error("buffer must be <= 28")

  const today = new Date()

  if (getDate(today) < buffer) {
    return {
      startDate: startOfMonth(subMonths(today, 1)),
      endDate: endOfMonth(subMonths(today, 1)),
    }
  } else return { startDate: startOfMonth(today), endDate: endOfMonth(today) }
}
export { getDefaultDates }
