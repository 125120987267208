import React, { useState, useEffect, useContext } from "react"
import { Redirect } from "react-router-dom"
import UserContext from "../../contexts/userContext"

import { Modal, Button, Container, Row } from "react-bootstrap"

import SewagePermissionsFetchForm from "./FetchForm"
import SewagePermissionsRender from "./Render"

const SewagePermissionsFetch = (props) => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState({ isReady: false })
  const [permissions, setPermissions] = useState()
  const [redirect, setRedirect] = useState(false) // triggers redirect to creating new permission

  const [userBranches, setUserBranches] = useState([])

  //state to save search query (to handle geting back from details):
  const [tableParams, setTableParams] = useState(
    props.location.state?.tableParams ? props.location.state.tableParams : false
  )

  const fetchPermissions = async (branch, state, query) => {
    try {
      setIsLoading(true)

      // set tableParams to preserve query when getting back to table from details:
      setTableParams({ branch: branch, state: state })

      if (branch === "wszystkie") branch = userBranches.join(",")

      const res = await fetch(
        `sewage/permissions?branches=${branch}&state=${state}&q=${query}`
      )
      if (res.status === 403) {
        setModalData({
          header: "brak uprawnień",
          show: true,
          body: "jeśli potrzebujesz dostępu do tego modułu - skontaktuj się mailowo lub przez 'Wyślij wiadomość'",
          type: "alert",
        })
        return
      }
      if (res.status !== 200) {
        const resBody = await res.text()
        throw resBody
      }
      const resJSON = await res.json()

      setPermissions(resJSON.permissions)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      const errModal = {
        header: "błąd pobierania danych",
        show: true,
        type: "alert",
      }
      setModalData(errModal)
    }
  }

  useEffect(() => {
    const getFormData = async () => {
      try {
        // set branches from cookie:
        const branches = Object.keys(user.branch).filter(
          (branch) => user.branch[branch]
        )
        setUserBranches(branches)

        setFormData({ isReady: true })

        //if there is state 'tableParams' it means it was redirected from permissionDetails, so I'm running fetch to retrive results:
        if (props.location.state?.tableParams) {
          // if 'wszystkie' branches selected - use resJSON (userBranches state will not be set yet), else use branch from props
          if (props.location.state.tableParams.branch === "wszystkie") {
            fetchPermissions(
              branches.join(","),
              props.location.state.tableParams.state
            )
          } else {
            fetchPermissions(
              props.location.state.tableParams.branch,
              props.location.state.tableParams.state
            )
          }
        }
      } catch (err) {
        console.log(err)
        setModalData({
          type: "alert",
          show: true,
          body: "Błąd pobierania danych",
        })
      }
    }
    getFormData()

    //prevent "missing dependency" error caused by using "fetchPermissins" from outside hook
    //eslint-disable-next-line
  }, [props, user])

  //todo use utils/Modals.js
  const modalAlert = () => {
    return (
      <div>
        <Modal.Header className="bg-danger">
          {modalData.header || "Błąd"}
        </Modal.Header>
        <Modal.Body>{modalData.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
        </Modal.Footer>
      </div>
    )
  }

  const modalInfo = () => {
    return (
      <Container>
        <Modal.Header>{modalData.header || "Info"}</Modal.Header>
        <Modal.Body>{modalData.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => window.location.reload()}>Zamknij</Button>
        </Modal.Footer>
      </Container>
    )
  }

  return (
    <Container fluid className="mt-4">
      {/* if 'create new' button pressed: */}
      {redirect ? (
        <Redirect
          to={{
            pathname: `/SewagePermissionDetails/new`,
            state: {
              tableParams: tableParams,
            },
          }}
        />
      ) : null}
      {/* render header form after data fetched: */}
      {formData.isReady ? (
        <SewagePermissionsFetchForm
          userBranches={userBranches}
          fetchPermissions={fetchPermissions}
          defaultData={
            props.location.state?.tableParams ? (
              props.location.state.tableParams
            ) : (
              <p>Pobieram informacje o dostępnych parametrach</p>
            )
          }
        />
      ) : null}

      <Row>
        <Button
          variant="secondary"
          className="ml-auto mr-5"
          onClick={() => setRedirect(true)}
        >
          Utwórz nowe
        </Button>
      </Row>
      {isLoading ? null : (
        <SewagePermissionsRender
          permissions={permissions}
          tableParams={{
            tableParams: tableParams,
            setTableParams: setTableParams,
          }}
        />
      )}
      <Modal
        show={modalData.show}
        onHide={() => setModalData({ show: false })}
        animation={false}
      >
        {modalData.type === "info" ? modalInfo() : null}
        {modalData.type === "alert" ? modalAlert() : null}
      </Modal>
    </Container>
  )
}
export default SewagePermissionsFetch
