import React from "react"
import { format, isValid } from "date-fns"
import _ from "lodash"
import { nF } from "../../../utils/formats"
import { Table, Row, Col } from "react-bootstrap"
import { GrCheckbox } from "react-icons/gr"

import DeliveryConfirmation from "../../../_shared/jobs/DeliveryConfirmation"

import { createEqpString } from "../../../utils/createEqpString"
import { renderJobRow } from "./renderJobRow"
import { prepareDeliveriesArr } from "./utils"
import { prepareLocationsArr } from "./prepareLocationsArr"

const emptyLine = (
  <div
    style={{
      border: "3px solid black",
      padding: "5px",
      marginBottom: "15px",
      pageBreakInside: "avoid",
    }}
  >
    <Table bordered className="jobCard">
      <tbody>
        <tr>
          <td style={{ width: "80%" }}>
            <sup>Nazwa klienta, lokalizacja</sup>
          </td>
          <td className="fixed-values-table bg-primaryLight">Godzina:</td>
          <td className="fixed-values-table">Czytelny podpis:</td>
        </tr>
        <tr style={{ height: "60px" }}>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
    <Table bordered className="jobCard">
      <tbody>
        <tr>
          <td style={{ height: "110px" }}>
            <sup>
              Rodzaj i{" "}
              <b>
                <u>liczba</u>
              </b>{" "}
              kabin
            </sup>
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
)
class RoutesPlanningJobCard extends React.Component {
  render() {
    const { drivers, date, driversSelected } = this.props

    return (
      <div style={{ lineHeight: "110%" }}>
        {drivers.map((driver) => {
          if (
            driversSelected.findIndex((drv_id) => drv_id === driver._id) === -1
          )
            return null

          // prepare deliveries by creating array of cloned jobs that contain only long term deliveries:
          //( short term cards are printed separate)
          const deliveriesArr = prepareDeliveriesArr(driver.jobs)

          //* since jobs on driver card are gruped by location I am preparing array of objects:
          //* [{loc_id: , locDetails: {} jobs:[]}, ... ]
          const locationsArr = prepareLocationsArr(driver.jobs)

          return (
            <React.Fragment key={`jobCardDriverRow-${driver._id}`}>
              <div
                style={{
                  fontSize: driver.largeJobCardFont ? "1.5rem" : null,
                }}
              >
                <Row className="justify-content-around">
                  <Col>
                    <h4>{driver.fullName}</h4>
                  </Col>
                  <Col>
                    Samochód:{" "}
                    {driver.cars?.map((car) => car?.regNum).join(", ") ||
                      "BRAK"}
                  </Col>
                  <Col>{format(new Date(date), "yyyy-MM-dd")}</Col>
                </Row>
                <Table
                  bordered
                  size="sm"
                  style={{
                    fontSize: driver.largeJobCardFont ? "1.5rem" : null,
                  }}
                  className="jobCard"
                >
                  <tbody>
                    <tr>
                      <td className="fixed-values-table">Licznik - początek</td>
                      <td style={{ width: "25%" }}></td>
                      <td className="fixed-values-table">Godzina - początek</td>
                      <td style={{ width: "25%" }}></td>
                    </tr>
                    <tr>
                      <td className="fixed-values-table">Licznik - koniec</td>
                      <td></td>
                      <td className="fixed-values-table">Godzina - koniec</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="fixed-values-table">Paliwo</td>
                      <td style={{ width: "25%" }}></td>
                      <td className="fixed-values-table">Czas pracy</td>
                      <td style={{ width: "25%" }}></td>
                    </tr>
                    <tr>
                      <td className="fixed-values-table">Przyjęta gotówka</td>
                      <td></td>
                      <td className="fixed-values-table">Czas przygotowań</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                {locationsArr.map((location) => {
                  return (
                    <div
                      style={{
                        border: "3px solid black",
                        padding: "5px",
                        marginBottom: "15px",
                        pageBreakInside: "avoid",
                      }}
                      key={`locationDiv-${location._id}`}
                    >
                      {location.locDetails.jobsSuspended ? (
                        <h5>
                          <b>!!! WINDYKACJA</b> - serwisy <b>WSTRZYMANE</b> -
                          NIE WYKONYWAĆ BEZ POTWIERDZENIA Z BIURA!
                        </h5>
                      ) : null}
                      <Table
                        bordered
                        size="sm"
                        style={{
                          fontSize: driver.largeJobCardFont ? "1.5rem" : null,
                          pageBreakInside: "avoid",
                        }}
                        className="jobCard"
                      >
                        <tbody>
                          <tr>
                            <td rowSpan="2" style={{ width: "5%" }}></td>
                            <td rowSpan="2" className="fixed-values-table ">
                              <h2>{location.qty}</h2>
                            </td>
                            <td className="fixed-values-table" colSpan="2">
                              {location.locDetails.customer} -{" "}
                              {location.locDetails.name}
                            </td>
                            <td className="fixed-values-table bg-primaryLight">
                              Godzina:
                            </td>
                            <td className="fixed-values-table">
                              Czytelny podpis:
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="border-right-0"
                              style={{ fontSize: "1.2rem" }}
                            >
                              <b>{location.locDetails.address}</b>
                              <br />

                              {location.locDetails.locNumber
                                ? `nr: ${location.locDetails.locNumber}`
                                : null}

                              <p style={{ fontSize: "90%" }} className="mb-0">
                                {location.locDetails.jobsFreq
                                  ? ` (${location.locDetails.jobsFreq})`
                                  : null}
                                {"  "}
                                {location.locDetails.coords
                                  // for paraller 52nd:
                                  // dist 0-180 = 8453 * 2 = 16906 km - LENGTH of paraller
                                  // 16906/360 = ~47 km/degree
                                  // 0,00001 dg = 0,4 m
                                  .map((coord) => _.round(coord, 5))
                                  .join("; ")}
                              </p>
                            </td>
                            <td
                              style={{ fontSize: "80%" }}
                              className="border-left-0"
                            >
                              Ostatnio:{" "}
                              {isValid(new Date(location.lastDoneOnDay))
                                ? format(
                                    new Date(location.lastDoneOnDay),
                                    "yyyy-MM-dd"
                                  )
                                : ""}
                              <br />
                              {location.lastDoneBy}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          {location.locDetails.paymentMethod === "Gotówka" && (
                            <tr>
                              <td colSpan="5">
                                <h4>
                                  <b className="bg-secondary">
                                    GOTÓWKA ({nF(location.locDetails.cash, 2)})
                                  </b>
                                </h4>
                              </td>
                            </tr>
                          )}
                          {location.locDetails.directions ? (
                            <tr>
                              <td className="fixed-values-table">Uwagi</td>
                              <td colSpan="4">
                                {location.locDetails.directions}
                              </td>
                            </tr>
                          ) : null}
                          {location.locDetails.contacts ? (
                            <tr>
                              <td className="fixed-values-table">Kontakty</td>
                              <td colSpan="4">
                                {location.locDetails.contacts}
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                      {location.jobs.map((job) => renderJobRow(driver, job))}
                    </div>
                  )
                })}

                {/* EMPTY PLACES */}
                {emptyLine}
                {emptyLine}
                {emptyLine}

                <Table
                  bordered
                  style={{
                    fontSize: driver.largeJobCardFont ? "1.5rem" : null,
                  }}
                  className="jobCard"
                >
                  <tbody>
                    <tr>
                      <td
                        className="fixed-values-table"
                        style={{ width: "25%" }}
                      >
                        Zlanych nieczystości
                      </td>
                      <td colSpan="3"></td>
                    </tr>
                    <tr>
                      <td
                        className="fixed-values-table"
                        style={{ width: "25%" }}
                      >
                        Zlanych nieczystości
                      </td>
                      <td colSpan="3"></td>
                    </tr>

                    <tr>
                      <td className="fixed-values-table">Uwagi?</td>
                      <td style={{ width: "15%" }}>
                        <GrCheckbox /> tak
                      </td>
                      <td
                        className="fixed-values-table"
                        style={{ width: "25%" }}
                      >
                        Podpis kierowcy
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                {/* {driverArrInd === drivers.length - 1 ? null : ( */}
                <div className="page-break" />
                {/* )} */}
              </div>
              {deliveriesArr.length !== 0
                ? deliveriesArr.map((delivery, deliveriesArrInd) => (
                    <>
                      <DeliveryConfirmation
                        job={delivery}
                        location={delivery.location}
                      />
                      {/* {deliveriesArrInd === deliveriesArr.length - 1 ? null : ( */}
                      <div className="page-break" />
                      {/* )} */}
                    </>
                  ))
                : null}
              {driver.customerCardsJobs.length
                ? renderCustJobCards(driver.customerCardsJobs, driver, date)
                : null}
              {driver.shortTermJobs.length
                ? renderShortTermCards(driver)
                : null}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

const renderCustJobCards = (jobs, driver, date) => {
  const custCards = []

  jobs.forEach((job) => {
    const qty = job.services.reduce((acc, jobSrv) => {
      let srvQty = 0

      if (jobSrv.eqp?.length)
        srvQty = jobSrv.eqp.reduce(
          (eqpAcc, jobSrvEqp) => eqpAcc + jobSrvEqp.qty || 1,
          0
        )
      else srvQty = 1
      return acc + srvQty
    }, 0)

    custCards.push({
      customer: job.location.customer.name,
      _id: job.location.customer._id,
      locations: [
        {
          name: `${job.location.city} ${job.location.street}`,
          qty: qty,
          _id: job.location._id,
        },
      ],
    })
  })

  return custCards.map((custCard, custCardsInd) => (
    <React.Fragment
      key={`customerJobCard-driver${driver._id}-customer${custCard._id}`}
    >
      <Row className="justify-content-between">
        <Col>
          <h3>Potwierdzenie wykonania serwisów</h3>
        </Col>
        <Col>
          <h4>{format(new Date(date), "yyyy-MM-dd")}</h4>
        </Col>
      </Row>
      <p>(egzemplarz dla klienta)</p>
      <h3>{custCard.customer}</h3>
      <Table
        style={{
          fontSize: driver.largeJobCardFont ? "1.5rem" : null,
        }}
      >
        <tbody>
          <tr>
            <td>Adres lokalizacji</td>
            <td>Liczba</td>
            <td>Godzina realizacji</td>
            <td>
              <b>Czytelny</b> podpis potwierdzającego wykonanie usługi
            </td>
          </tr>
          {custCard.locations.map((loc) => (
            <tr key={`customerJobCard-locationRow-${loc._id}`}>
              <td>{loc.name}</td>
              <td>{loc.qty}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* {custCardsInd === custCards.length - 1 ? null : ( */}
      <div className="page-break" />
      {/* )} */}
    </React.Fragment>
  ))
}

const renderShortTermCards = (driver) => {
  if (!driver.shortTermJobs.length)
    return <h1>ZGŁOŚ BŁĄD PROGRAMU (brak zadań imprezowych)</h1>

  const shortSrvsCards = []
  driver.shortTermJobs.forEach((job) => {
    // get only short term services:
    const jobShortTermSrvs = job.services.filter(
      (jobSrv) =>
        jobSrv.serviceRef.chargeType === "ryczałt impreza" ||
        jobSrv.serviceRef.chargeType === "krótki termin"
    )

    jobShortTermSrvs.forEach((jobSrv) => {
      shortSrvsCards.push(createShortTermCard(job, jobSrv))
    })
  })

  return shortSrvsCards
}

const createShortTermCard = (job, jobSrv) => {
  // get location service details:
  const locSrv = job.location.services.find(
    (locSrvEl) => locSrvEl._id === jobSrv.locationServiceRef
  )

  // prepare job cmments from job comments and location notes:
  let jobCommentsStr = job.comments || ""
  if (job.location.notes.length)
    job.location.notes.forEach((note) => {
      if (note.state === "aktywna" && note.type === "na kontrolkę")
        jobCommentsStr = `${jobCommentsStr} ${note.body}`
    })

  return (
    <>
      <Table bordered>
        <tbody>
          <tr>
            <td colSpan="2">
              <h2>KONTROLKA IMPREZOWA</h2>
            </td>
            <td>
              <h3>{locSrv.shortTermNo ? `nr ${locSrv.shortTermNo}` : null}</h3>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <h2>
                {job.driver.fullName} (samochód: {job.car?.regNum})
              </h2>
            </td>
            <td>
              <h2>{format(new Date(job.date), "yyyy-MM-dd")}</h2>
            </td>
          </tr>
        </tbody>
      </Table>
      {job.location.customer.jobsSuspended ? (
        <h3>
          <br />
          <b>!!! WINDYKACJA </b> - NIE WYKONYWAĆ BEZ POTWIERDZENIA Z BIURA!
        </h3>
      ) : null}{" "}
      <Table bordered>
        <tbody>
          <tr>
            <td>
              <h2>{job.type}</h2>
            </td>
            {job.startTime ? (
              <td>
                <h3>od {job.startTime}</h3>
              </td>
            ) : null}
            {job.endTime ? (
              <td>
                <h3>do {job.endTime}</h3>
              </td>
            ) : null}
          </tr>
          <tr>
            <td colSpan="3">
              <h5>Klient: {job.location.customer.name}</h5>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <h3>
                Adres: {job.location.street} {job.location.city} (
                {job.location.name}){" "}
              </h3>
            </td>
          </tr>

          <tr>
            <td colSpan="3">
              <div>
                <h3>
                  {job.type === "dostarczenie" ? "Dostarczyć" : null}
                  {job.type === "zabranie" ? "Zabrać" : null}
                  {job.type === "serwis" ? "Serwis" : null}:
                </h3>
                <ul>
                  {jobSrv.eqp.map((jobSrvEqp) => (
                    <li
                      style={{ fontSize: "x-large" }}
                      key={`shortTermCard-eqpEl-${job._id}-${jobSrv._id}-${jobSrvEqp._id}`}
                    >
                      {createEqpString(jobSrvEqp)}
                      {jobSrv.subSrv.length
                        ? `+ ${jobSrv.subSrv
                            .map((subSrv) => subSrv.name)
                            .join(", ")}`
                        : null}
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <tbody>
          {jobCommentsStr.length ? (
            <tr>
              <td colSpan="2">
                <h4>Uwagi: {jobCommentsStr}</h4>
              </td>
            </tr>
          ) : null}
          <tr>
            <td colSpan="2" style={{ fontSize: "large" }}>
              <b>KONTAKT:</b> {getCustContact(job)}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <tbody>
          <tr>
            <td colSpan="2">
              {job.type === "dostarczenie" ? (
                <p>
                  Potwierdzam odbiór ww. sprzętu (potwierdzenie jest
                  jednoznaczne z przejęciem odpowiedzialności za sprzęt, chyba
                  że inne, pisemne, ustalenia wskazują inaczej. Do celów
                  rozliczeniowych w przypadku uszkodzenia/zniszczenia sprzętu
                  przyjmowana jest jego wartość odtworzeniowa)
                </p>
              ) : (
                <p>Potwierdzam prawidłowe wykonanie serwisu ww. sprzętu</p>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <br />
              <br />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              (<b>czytelny</b> podpis przedstawiciela{" "}
              {job.location.customer.name})
            </td>
            <td>(podpis pracownika Clippera)</td>
          </tr>
        </tbody>
      </Table>
      <div className="page-break" />
    </>
  )

  //TODO add "na kontrolkę" location note
}

const getCustContact = (job) => {
  const contacts = job.location.contacts

  if (!contacts?.length) return "brak danych"

  const jobCardContacts = contacts.filter(
    (ctc) => ctc.type === "na kontrolkę" && ctc.state === "aktywny" && ctc.phone
  )
  if (jobCardContacts.length)
    return jobCardContacts.map((ctc) => `${ctc.name}: ${ctc.phone}`).join(", ")

  const mainContacts = contacts.filter(
    (ctc) => ctc.type === "główny" && ctc.state === "aktywny" && ctc.phone
  )
  if (mainContacts.length)
    return mainContacts.map((ctc) => `${ctc.name}: ${ctc.phone}`).join(", ")

  return "brak danych"
}

export default RoutesPlanningJobCard
