import React, { useState } from "react"
import { useForm } from "react-hook-form"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/esm/Row"

const GoodDetails = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const newGood = props.good === true ? true : false

  const { register, handleSubmit } = useForm({
    defaultValues: props.good,
  })
  const onSubmit = async (data) => {
    try {
      if (!newGood) data._id = props.good._id

      const res = await fetch(`/goods/${newGood ? "new" : "update"}`, {
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
        method: newGood ? "POST" : "PUT",
      })
      if (res.status === 200) {
        window.location.reload()
      } else {
        const resJSON = await res.json()
        throw resJSON
      }
    } catch (err) {
      alert(err)
    }
  }

  const handleDelete = async () => {
    try {
      setShowDeleteModal(false)
      const res = await fetch(`/goods/remove/${props.good._id}`, {
        method: "DELETE",
      })
      if (res.status === 200) window.location.reload()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Container>
      <Col xs="6">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Label>Nazwa</Form.Label>
          <Form.Control {...register("name")} type="text" />
          <Form.Label>Rodzaj</Form.Label>
          <Form.Control {...register("type")} as="select">
            <option value="main">Główne</option>
            <option value="normal">Normalne</option>
          </Form.Control>
          <Form.Label>Aktywny?</Form.Label>
          <Form.Check {...register("isActive")}></Form.Check>
          <Form.Label>Jednostka</Form.Label>
          <Form.Control {...register("unit")} type="text" />
          <Form.Label>Priorytet</Form.Label>
          <Form.Control {...register("priority")} type="number" />
          <Form.Label>Jednostka ostateczna</Form.Label>
          <Form.Control {...register("finalUnit")} type="text" />
          <Form.Label>
            Dzielnik (jednostka{" -> "}jednostka ostateczna)
          </Form.Label>
          <Form.Control
            {...register("divider")}
            type="number"
            disabled={newGood ? false : true}
          />
          <Form.Label>Cena</Form.Label>
          <Form.Control
            {...register("price")}
            type="number"
            step="0.01"
            disabled={newGood ? false : true}
          />
          <Form.Label>Sumować w kosztach?</Form.Label>
          <Form.Check {...register("isWarehouseCost")}></Form.Check>
          <br />
          <Row>
            <Button type="submit">Zapisz</Button>
            <Button
              type="button"
              onClick={() => setShowDeleteModal(true)}
              variant="danger"
              className="ml-auto"
            >
              Usuń
            </Button>
          </Row>
        </Form>
      </Col>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        animation={false}
      >
        <Modal.Body>Usunąć?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowDeleteModal(false)
            }}
          >
            Ni
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            Ano
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
export default GoodDetails
