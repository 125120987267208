// import React from "../../../../node_modules/react"

import { Table } from "react-bootstrap"

import { dateParse, nF } from "../../utils/formats"

const PrivatDetailsTable = (props) => {
  const { name, surname, street, city, postal } = props.transaction.privatData
  return (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan="2">Dane klienta - os. fiz.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Imię i nazwisko:</td>
          <td>
            {name} {surname}
          </td>
        </tr>
        <tr>
          <td rowSpan="2" className="fixed-values-table">
            Adres:
          </td>
          <td>{street}</td>
        </tr>
        <tr>
          <td>
            {postal.slice(0, 2) + "-" + postal.slice(2, 5)} {city}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

const CompanyDetailsTable = (props) => {
  const { name, vatNumber, street, city, postal } =
    props.transaction.companyData
  return (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan="2">Dane klienta - firma</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Nazwa:</td>
          <td id="CompanyDetailsTable-customerName">{name}</td>
        </tr>
        <tr>
          <td className="fixed-values-table">NIP:</td>
          <td>{vatNumber}</td>
        </tr>
        <tr>
          <td rowSpan="2" className="fixed-values-table">
            Adres:
          </td>
          <td>{street}</td>
        </tr>
        <tr>
          <td>
            {postal.slice(0, 2) + "-" + postal.slice(2, 5)} {city}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

const DeliveryDetailsTable = (props) => {
  if (props.transaction.realizationDetails.ourDelivery === "true") {
    const { street, name, homeNumber, postal, city, phone } =
      props.transaction.realizationDetails.deliveryDetails.deliveryAddress
    return (
      // <Container>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="2">
              {props.deliveryPrint ? "Dane dostawy" : "Nasza dostawa:"}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Data</td>
            <td>
              {
                props.transaction.realizationDetails.deliveryDetails
                  .deliveryDate
              }
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Nazwa</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Telefon</td>
            <td>{phone}</td>
          </tr>
          <tr>
            <td rowSpan="2" className="fixed-values-table">
              Adres
            </td>
            <td>
              {street} {homeNumber}
            </td>
          </tr>
          <tr>
            <td>
              {postal.slice(0, 2) + "-" + postal.slice(2, 5)} {city}
            </td>
          </tr>
        </tbody>
      </Table>
      // </Container>
    )
  } else if (props.transaction.realizationDetails.ourDelivery === "false") {
    return (
      // <Container>
      <Table bordered>
        <thead>
          <tr>
            <th>Transport klienta</th>
          </tr>
        </thead>
      </Table>
      // </Container>
    )
  } else {
    return null
  }
}

const TransactionHeadersTable = (props) => {
  return (
    <Table bordered>
      <tbody>
        <tr>
          <td className="bg-primaryLight">Data utworzenia</td>
          <td>{dateParse(props.transaction.createdAt)}</td>
          <td className="bg-primaryLight">Pracownik</td>
          <td>
            {/* Due to change in 0.4.4 */}
            {props.transaction.user.fullName || props.transaction.userName}
          </td>
          <td className="bg-primaryLight">Data ostatniej edycji</td>
          <td>{dateParse(props.transaction.updatedAt)}</td>
          <td className="bg-primaryLight">Stan</td>
          <td id="stateCell">{props.transaction.state}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const OrderDetailsTable = (props) => {
  const grossValue = props.grossValue

  return (
    <Table bordered className="mt-2">
      <thead>
        <tr>
          <th className="fixed-values-table">Nazwa</th>
          <th className="fixed-values-table">Jm.</th>
          <th>Liczba</th>
          {props.noPrices ? null : ( // when rendering delivery confirmation - hide prices
            <>
              <th>Cena netto</th>
              <th className="fixed-values-table">VAT</th>
              <th>Cena brutto</th>
              <th>Wartość netto</th>
              <th>Wartość brutto</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {props.transaction.orderDetails.prices.map((price, i) => {
          return (
            <tr key={price.name}>
              <td className="fixed-values-table">{price.name}</td>
              <td>{price.unit}</td>
              <td>{price.quantity}</td>
              {props.noPrices ? null : ( // when rendering delivery confirmation - hide prices
                <>
                  <td>{nF(price.netPrice)}</td>
                  <td>{price.vat * 100}%</td>
                  <td>{nF(price.grossPrice)}</td>

                  {/* net value */}
                  <td>{nF(price.netPrice * price.quantity)}</td>
                  {/* gross value */}
                  <td>
                    {nF(price.grossPrice * price.quantity)}
                    {/* {nF(
                    roundToHundredth(price.netPrice * (price.vat + 1)) *
                      price.quantity
                  )} */}
                  </td>
                </>
              )}
            </tr>
          )
        })}
        {props.noPrices ? null : ( // when rendering delivery confirmation - hide prices
          <>
            {/* if our delivery - render additional row with prices: */}
            {props.transaction.realizationDetails.ourDelivery === "true" ? (
              <tr>
                <td className="fixed-values-table">Transport</td>
                <td>-</td>
                <td>1</td>
                <td>
                  {nF(
                    props.transaction.realizationDetails.deliveryDetails
                      .netPrice
                  )}
                </td>
                <td>23%</td>
                <td>
                  {nF(
                    props.transaction.realizationDetails.deliveryDetails
                      .grossPrice
                  )}
                </td>
                <td>
                  {nF(
                    props.transaction.realizationDetails.deliveryDetails
                      .netPrice
                  )}
                </td>

                <td>
                  {nF(
                    props.transaction.realizationDetails.deliveryDetails
                      .grossPrice
                  )}
                </td>
              </tr>
            ) : null}
          </>
        )}
      </tbody>
      {props.noPrices ? null : (
        <tfoot>
          <tr>
            <td className="hidden-cell" colSpan={6}></td>
            <td id="productsNetPricesTotal">
              {nF(props.transaction.orderDetails.netValueTotal)}
            </td>
            <td>{nF(grossValue)}</td>
          </tr>
          {props.transaction.realizationDetails.ourDelivery === "true" ? (
            <tr>
              <td className="hidden-cell" colSpan={5}></td>
              <td>Z transportem:</td>
              <td>
                {nF(
                  props.transaction.orderDetails.netValueTotal +
                    props.transaction.realizationDetails.deliveryDetails
                      .netPrice
                )}
              </td>
              <td>
                {nF(
                  grossValue +
                    props.transaction.realizationDetails.deliveryDetails
                      .grossPrice
                )}
              </td>
            </tr>
          ) : null}
        </tfoot>
      )}
    </Table>
  )
}

const TransactionInfoTable = (props) => {
  const renderInvoiceType = () => {
    switch (props.transaction.realizationDetails.invoiceType) {
      case "proforma":
        return "proforma"
      case "invoice":
        return "faktura"
      case "receipt":
        return "paragon"
      default:
        return null
    }
  }
  const renderPaymentType = () => {
    switch (props.transaction.realizationDetails.payment) {
      case "transfer":
        return "przelew"
      case "cash":
        return "gotówka"
      case "card":
        return "karta"
      default:
        return null
    }
  }
  // 2020-08-24 I added paymentDetails to transaction, and there are some change in transaction details layout.
  if (props.transaction.paymentDetails) {
    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="2">Szczegóły:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bg-primaryLight align-items-start">
              Dodatkowe informacje:
            </td>
            <td>{props.transaction.realizationDetails.additionalInfo}</td>
          </tr>
          <tr>
            <td className="bg-primaryLight">Oddział:</td>
            <td>{props.transaction.realizationDetails.branch}</td>
          </tr>
          <tr>
            <td className="bg-primaryLight">Sprzedający:</td>
            <td>{props.transaction.seller}</td>
          </tr>
        </tbody>
      </Table>
    )
  }
  return (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan="2">Szczegóły:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="bg-primaryLight align-items-start">
            Dodatkowe informacje:
          </td>
          <td>{props.transaction.realizationDetails.additionalInfo}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Oddział:</td>
          <td>{props.transaction.realizationDetails.branch}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Sprzedający:</td>
          <td>{props.transaction.seller}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Dokument:</td>
          <td>{renderInvoiceType()}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Płatność:</td>
          <td>{renderPaymentType()}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const PaymentDetailsTable = (props) => {
  return (
    <Table bordered>
      <thead>
        <tr>
          <th colSpan="2">Płatność:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="bg-primaryLight">Proforma?</td>
          <td>{props.transaction.paymentDetails.proforma ? "tak" : "nie"}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Metoda płatności</td>
          <td>{props.transaction.paymentDetails.method}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Dokument</td>
          <td>{props.transaction.paymentDetails.saleDocument}</td>
        </tr>
        <tr>
          <td className="bg-primaryLight">Termin</td>
          <td>
            {props.transaction.paymentDetails.deadlineString
              ? props.transaction.paymentDetails.deadlineString
              : props.transaction.paymentDetails.deadline}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
export {
  PrivatDetailsTable,
  CompanyDetailsTable,
  DeliveryDetailsTable,
  TransactionHeadersTable,
  OrderDetailsTable,
  TransactionInfoTable,
  PaymentDetailsTable,
}
