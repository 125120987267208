import _ from "lodash"

const fetchAllowedCars = async (
  setModalData,
  setAllowedCars,
  setCarsOptions,
  filters,
  setFilters
) => {
  const res = await fetch("/cars/getAllowedCars")
  if (res.status === 403)
    return setModalData({
      show: true,
      type: "info",
      body: "Brak uprawnień",
    })
  if (res.status !== 200)
    throw new Error(
      `Błąd pobierania danych: ${res.status} - ${await res.text()}`
    )
  const resJSON = await res.json()
  setAllowedCars(resJSON)
  // set options for select with groups 'ciężarowy', 'osobowy', 'przyczepa':
  const carsGrouped = [
    { label: "ciężarowy", options: [] },
    { label: "osobowy", options: [] },
    { label: "przyczepa", options: [] },
  ]

  resJSON.forEach((car) => {
    switch (car.type) {
      case "ciężarowy":
        carsGrouped[0].options.push({
          value: car._id,
          label: `${car.brand} ${car.model} ${car.regNum} (${car.name})`,
        })
        break
      case "osobowy":
        carsGrouped[1].options.push({
          value: car._id,
          label: `${car.brand} ${car.model} ${car.regNum} (${car.name})`,
        })
        break
      case "przyczepa":
        carsGrouped[2].options.push({
          value: car._id,
          label: `${car.brand} ${car.model} ${car.regNum} (${car.name})`,
        })
        break

      default:
        break
    }
  })
  setCarsOptions(carsGrouped)

  const defaultCars = _.flatten(carsGrouped.map((group) => group.options))
  setFilters({ ...filters, cars: defaultCars })
}

const fetchCarsWorks = async (filters) => {
  const query = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    cars: filters.cars?.map((car) => car.value),
    type: filters.type?.map((type) => type.value),
  }
  const res = await fetch("/cars/getCarsWorks", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(query),
  })

  if (res.status !== 200)
    throw new Error(
      `${res.status} - ${(await res.text()) || "nieokreślony błąd"}`
    )

  const resJSON = await res.json()

  return resJSON
}

export { fetchAllowedCars, fetchCarsWorks }
