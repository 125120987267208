import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"

import MyModal from "../utils/Modals"

import CustomersSearchForm from "./SearchForm"
import CustomersTable from "./Table"
import { sortCustomers } from "./utils"
import renderSpinner from "../utils/renderSpinner"
// when using from comp other then list -> use embedded = true
const CustomersFetch = (props) => {
  const [customers, setCustomers] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState({ show: false })
  const [choosenCustomer, setChoosenCustomer] = useState(false)

  const fetchCustomers = async (query, archive) => {
    try {
      setIsLoading(true)
      setCustomers([])
      const res = await fetch(`/customers?query=${query}&archive=${archive}`)
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: "brak uprawnień",
        })
      if (res.status !== 200) throw res

      const resJSON = await res.json()
      setCustomers(resJSON.customers.sort(sortCustomers))
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "błąd pobierania danych",
      })
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Klienci</title>
      </Helmet>
      {choosenCustomer ? (
        <Redirect to={`/CustomersClipMenuDetails/${choosenCustomer}`} />
      ) : null}

      <CustomersSearchForm
        fetchCustomers={fetchCustomers}
        embedded={props.embedded}
        setModalData={setModalData}
      />

      {isLoading ? renderSpinner() : null}
      {customers && customers.length ? (
        <CustomersTable
          customers={customers}
          embedded={props.embedded}
          setCustomer={props.setCustomer} // used when embedded
          setChoosenCustomer={setChoosenCustomer} // used to display details
        />
      ) : null}

      <MyModal setModalData={setModalData} modalData={modalData} />
    </Container>
  )
}
export default CustomersFetch
