import { useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import UserContext from "../../comp/contexts/userContext"
import {
  Button,
  Container,
  ToggleButton,
  Row,
  ButtonGroup,
  Col,
} from "react-bootstrap"
import { Helmet } from "react-helmet"

import MyModal from "../../comp/utils/Modals"

import ManualGeneralSection from "./sections/General"
import ManualEmployeesSection from "./sections/Employees"
import ManualCustomersSection from "./sections/Customers"
import ManualJobsSection from "./sections/Jobs"
import ManualLocationsSection from "./sections/Locations"
import ManualEqpSection from "./sections/Equipment"
import ManualInvoicingSection from "./sections/Invoicing"
import ManualLogisticsSection from "./sections/Logistics"
import ManualTodosSection from "./sections/Todos"
import { ManualCarsSection } from "./sections/Cars"
import { ManualDebtCollectionSection } from "./sections/DebtCollection"
import { ManualReportsSection } from "./sections/Reports"

import transactionAddImg from "../pics/manual/transactionAdd.png"
import consumptionAddImg from "../pics/manual/consumptionAdd.png"
import consumptionsReport from "../pics/manual/consumptionsReport.png"
import sellReportFilter from "../pics/manual/sellReportFilter.png"
import sellReportSummary from "../pics/manual/sellReportSummary.png"
import sellReportStats from "../pics/manual/sellReportStats.png"
import sellReportTransactionsList from "../pics/manual/sellReportTransactionsList.png"
import sewagePermissionsReportsCheckbox from "../pics/manual/sewagePermissionsReportsCheckbox.png"

const Manual = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [showNotes, setShowNotes] = useState(false)
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const scrollQuery = new URLSearchParams(search)
      const scrollTrgt = document.getElementById(
        scrollQuery.get("scrollTarget")
      )
      scrollTrgt?.scrollIntoView({ block: "center" })
    }
  }, [search])

  return (
    <Container fluid>
      <Helmet>
        <title>Instrukcja</title>
      </Helmet>
      <div style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Row className="justify-content-between">
          <h1>Instrukcja obsługi programu</h1>
          {user.type.tester ? (
            <ButtonGroup toggle>
              {[
                { name: "Pokaż notatki", value: true },
                { name: "Ukryj notatki", value: false },
              ].map((opt) => {
                return (
                  <ToggleButton
                    type="radio"
                    key={`toggleNotes-${opt.value}`}
                    checked={showNotes === opt.value}
                    variant="outline-primary"
                    onChange={() => setShowNotes(opt.value)}
                  >
                    {opt.name}
                  </ToggleButton>
                )
              })}
            </ButtonGroup>
          ) : null}
        </Row>
        <p>
          <b>UWAGA!</b>
        </p>
        <p>
          Każdego użytkownika programu <u>bezwzględnie</u> obowiązuje znajomość
          (i zrozumienie) części "1. Informacje ogólne"
        </p>

        <p className="bg-danger">
          Wszelkie informacje wprowadzane do programu mają <u>obowiązkowo</u>{" "}
          odzwierciedlać rzeczywistą sytuację. "Oszukiwanie" programu jest
          możliwe tylko za moim pisemnym pozwoleniem.
          <br />W przypadku braku takiego pozwolenia wszelkie odstępstwa (na
          przykład, ale nie wyłącznie, dodawanie "sztucznych/wirtualnych"
          dostarczeń/zabrań, nie wprowadzanie całego naszego sprzętu na
          lokalizacji itd.) może skutkować konsekwencjami dyscyplinarnymi, jako
          narażające spółkę na straty.
          <br />
        </p>
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              body: (
                <>
                  <p>Głównymi zadaniami clipERPa są:</p>
                  <ul>
                    <li>
                      Obsługa procesów w Clipperze, przede wszystkim terminowego
                      świadczenia usług i wystawiania prawidłowych faktur.
                    </li>
                    <li>
                      Ułatwienie Wam pracy przez autmatyzację jak największej
                      ilości zadań.
                    </li>
                    <li>
                      Dostarczanie aktualnej i <u>poprawnej</u> informacji o
                      bieżącym stanie spółki dla zarządu i kierowników.
                      Informacji używanych przy podejmowaniu istotnych decyzji,
                      ale również np. do rozliczania kierowców.
                    </li>
                  </ul>
                  <p>
                    Jeśli dane przez Was wprowadzane będą nieprawidłowe, to
                    każde z tych zadań będzie zagrożone. Dotyczy to również pkt
                    2 - często ominięcie programu spowoduje, że na dalszych
                    etapach nie będziecie mogli korzystać z narzędzi
                    ułatwiających pracę.
                  </p>
                  <p>
                    Dodatkowo wychodzę z założenia, że zazwyczaj jeśli musicie
                    oszukiwać program, to znaczy że jest on niewystarczająco
                    dobry. Chętnie go poprawię, tylko muszę o niedostatkach
                    wiedzieć. Ostatecznym celem jest realizacja powyższych
                    zadań, w tym ułatwienia Wam pracy.
                  </p>
                </>
              ),
            })
          }
        >
          Dlaczego to ważne?
        </Button>

        <hr />
        <h2>Legenda</h2>
        <ul>
          <li className="bg-secondarySuperLight">
            Takim jasno-niebieskim tłem oznaczone są fragmenty, które zmieniłem
            lub dodałem od poprzedniej wersji
          </li>
          <li className="bg-dangerLight">
            Czerwonym tłem oznaczam istotne fragmenty dotyczące sposobu używania
            programu, związane głównie z ograniczeniami technicznymi czy
            wybranymi przeze mnie rozwiązaniami
          </li>
          <li className="bg-warningLight">
            Na żółto oznaczam planowane w kolejnych wersjach zmiany czy kierunki
            rozwoju, jeśli jakieś są dla Was szczególnie przydatne proszę o
            informację, zwiększę priorytet.
          </li>
        </ul>
        <h2>Spis treści</h2>
        <p>
          <i>
            (dostęp do poszczególnych punktów zależy od uprawnień, dlatego może
            brakować niektórych punktów)
          </i>
        </p>
        <ol>
          <a href="#1">
            <li>Informacje ogólne</li>
          </a>
          {user.perm.transaction.r || user.perm.transaction.w ? (
            <a href="#2">
              <li>Sprzedaż RLine</li>
            </a>
          ) : null}
          {user.perm.consumption.w || user.perm.consumptionsList.r ? (
            <a href="#3">
              <li>Magazyny</li>
            </a>
          ) : null}
          {user.perm.sewageReport.r ? (
            <a href="#4">
              <li>Sprawozdania kwartalne i zezwolenia</li>
            </a>
          ) : null}

          <a href="#5">
            <li>Pracownicy</li>
          </a>
          {user.perm.customers?.r ? (
            <a href="#6">
              <li>Klienci</li>
            </a>
          ) : null}
          {user.perm.locations.r ? (
            <a href="#7">
              <li>Lokalizacje</li>
            </a>
          ) : null}
          {user.perm.locations.r ? (
            <a href="#8">
              <li>Zadania</li>
            </a>
          ) : null}
          <a href="#9">
            <li>Sprzęt</li>
          </a>
          {user.perm.invoicing.r ? (
            <a href="#10">
              <li>Fakturowanie</li>
            </a>
          ) : null}
          {user.perm.logistics.r ? (
            <a href="#11">
              <li>Logistyka</li>
            </a>
          ) : null}
          {user.perm.todos.r ? (
            <a href="#12">
              <li>"Do zrobienia" - lista zadań użytkownika</li>
            </a>
          ) : null}
          {user.perm.carsManage.r || user.perm.carWork.r ? (
            <a href="#13">
              <li>Samochody</li>
            </a>
          ) : null}
          <a href="#14">
            <li>Windykacja</li>
          </a>
          {user?.perm.locations?.r ||
          user?.perm.transactionsList.r ||
          user?.perm.transaction.w ||
          user?.perm.incomeReport?.r ? (
            <a href="#15">
              <li>Raporty</li>
            </a>
          ) : null}
        </ol>
        <ManualGeneralSection />
        <h2 id="2">2. Sprzedaż RLine</h2>
        <h3>Wstęp</h3>
        <p>
          Formularz zgłaszania sprzedaży wykorzystujcie w następujących
          sytuacjach:
        </p>
        <ul>
          <li>gdy sprzedaż jest na Ecoloxx i/lub</li>
          <li>
            gdy sprzedajcie RLine lub coś bezpośrednio związanego – jak nie
            jesteście pewni to Basia/Łukasz/ja zdecydujemy indywidualnie.
          </li>
        </ul>
        <p>
          Czyli jeśli sprzedaż jest na Ecoloxx - zawsze clipERP. Jeśli sprzedaż
          jest na Clippera, ale dotyczy RLine lub pokrewnych - clipERP.
        </p>
        <h4>Statusy transakcji</h4>
        <p>
          Transakcja po dodaniu otrzymuje automatycznie status{" "}
          <b>"oczekująca"</b>.
        </p>
        <p>
          Jeśli do transakcji miała być wystawiona proforma, to po jej wydruku
          status jest zmieniany* na <b>"zaproformowana"</b>.
        </p>
        <p>
          Po wystawieniu faktury status zmieniany* jest na{" "}
          <b>"zafakturowana"</b> i nie będzie już domyślnie widoczna na liście
          transakcji - uznajemy ją za zakończoną (
          <u>nadzór nad płatnościami jest poza programem</u>
          ).
        </p>
        <p>
          Każdy użytkownik może <b>anulować</b> transakcję (swoją lub dowolną -
          zależnie od uprawnień), ale tylko jeśli ma ona stan "oczekująca" lub
          "zaproformowana"
          <br />
          Anulowana transakcja nie jest domyślnie widoczna na liście transakcji,
          ale można ją zobaczyć po przełączeniu filtra na wszystkie transakcje
          (przycisk na górze po prawej).
        </p>

        <p>
          <b>Transakcję można edytować tylko</b> gdy jej stan to "oczekująca".
          Możliwość przywrócenia tego stanu (czyli odblokowania do edycji) mają
          kierownicy w przypadku transakcji zaproformowanej, a w każdym innym:
          Basia, Łukasz i ja.
        </p>

        <p>
          ______________
          <br />* zmiana jest ręcznie dokonywana przez wystawcę dokumentu
        </p>
        <h3>Dodawanie transakcji</h3>
        <p>
          Obecnie domyślny ekran po zalogowaniu. Umożliwa dodanie transakcji,
          czyli de facto "zamówienie" wystawienia fakatury(/profromy/paragonu).
        </p>
        <p>
          Szczegóły formularza opisuję poniżej, pamiętajcie że{" "}
          <b>
            wszystko czego nie da się ująć w ramach przewidzianych pól, zawsze
            możecie wpisać w pole "<i>Dodatkowe informacje</i>"
          </b>
        </p>
        <img
          src={transactionAddImg}
          width="50%"
          alt="widok formularza dodawania transakcji"
        />
        <ol>
          <li>
            Po wybraniu odpowiedniej opcji wyświetli się formularz do podania
            szczegółowych danych
          </li>
          <li>
            Główna tabela, wyświetlająca wszystkie aktualne pozycje cennika.
            Domyślnie wyświetlone są aktualnie obowiązujące ceny, ale możecie je
            dowolnie zmieniać. Po wpisaniu ceny netto brutto wylicza się
            automatycznie i vice versa.
          </li>
          <li>
            Do wyboru dostawa klienta - czyli odbiór osobisty, albo nasza - po
            wybraniu naszej wyświetli się formularz do wpisania szczegółowych
            danych, w tym ustalonej ceny (doliczy się do sumarycznej kwoty
            zamówienia)
          </li>
        </ol>
        <p>
          Po zapisaniu transakcji program wysyła maila z powiadomieniem do
          pracownika, który ją dodał, osoby odpowiedzialnej za faktury w
          odpowiednim oddziale, Basi, Łukasza i mnie
        </p>
        <h3>Pobieranie zewnętrznych danych</h3>
        <p>
          W trakcie dodawania transakcji są dwie możliwości pobrania
          zewnętrznych danych klienta:
        </p>
        <ol>
          <li>
            Pobranie danych z KRS - tylko dla "Firma/działalność", po wpisaniu
            numeru NIP należy wcisnąć przycisk obok okienka
          </li>
          <li>
            Pobieranie danych z bezy starego programu (<b>clipMENU</b>) - dane
            staram się importować mniej więcej raz na miesiąc. Po kliknięciu
            "Wyszukaj w bazie" pojawi się okno wyszukiwania w które można
            wpisać: fragment nazwy, ulicy (wielkość liter bez znaczenia),
            fragment numerów: NIP, PESEL, REGON (
            <b>koniecznie bez myślników i spacji</b>).
            <br />
            Po kliknięciu odpowiedniego rekordu dane wpiszą się w odpowiednie
            pola.
            <br />
            <i>UWAGA</i> W przypadku osób fizycznych program zakłada, że w
            starym systemie było wpisane 'IMIĘ NAZWISKO' a nie 'NAZWISKO IMIĘ'
            <br />
            Podpowiedź: w przypadku naszej dostawy program może też uzupełnić
            dane dostawy (na podstawie adresu korespondencyjnego z clipMENU).
            Żeby to zrobić, <u>przed</u> wyszukaniem klienta w bazie należy
            wybrać opcję "Nasza dostawa", tak żeby pojawił się odpowiedni
            formularz.
          </li>
        </ol>
        <h3>Lista transakcji</h3>
        <p>
          Użytkownik ma możliwość przeglądania transakcji, które dodał przy
          pomocy menu {"->"} Lista transakcji.
        </p>
        <p>
          Domyślnie na liście wyświetlane są tylko transakcje oczekujące na
          zafakturowanie.
        </p>
        <h3>Szczegóły i edycja transakcji</h3>
        <p>
          Po kliknięciu odpowiedniej transakcji wyświetlane są jej szczegóły i
          (w zależności od uprawnień i statusu transakcji) możliwość edycji,
          wygenerowania potwierdzenie odbioru dla klienta i zmiany statusu.{" "}
        </p>
        <p>
          <b>Potwierdzenie dostawy</b> może przy większej liczbie zamówionych
          produktów "przełamać" się na nową stronę. Na chwilę obecną, gdyby tak
          się zdarzyło, trzeba samodzielnie przygotować potwierdzenie w wordzie
          (tabele z podglądu powinny się całkiem dobrze kopiować).
        </p>
        <h4>Edycja transakcji</h4>
        <div className=" p-3 mb-2">
          <p className="bg-dangerLight">
            Aby możliwa była edycja transakcji, musi mieć ona stan "oczekująca"
            lub "zaproformowana" - zafakturowane i anulowane transakcje są
            zablokowane do edycji. W przypadku konieczności edycji
            "zablokowanej" (zafakturowanej lub anulowanej) transakcji można
            skorzystać z odpowiedniego przycisku na jej <b>podglądzie</b> -
            spowoduje to wysłanie maila do Basi, Łukasz i mnie
          </p>
        </div>
        <p className="bg-dangerLight">
          Ze względów technicznych nie jest możliwa edycja transakcji dodanych
          przed wersją 0.3.0 (2020-08-25)
        </p>
        <p>
          Aby edytować transakcję należy znaleźć ją na liście, przejść do
          podglądu i kliknąć "edytuj". Edycja funkcjonuje analogicznie do
          dodawania nowej transakcji, przynajmniej powinien, gdyby było inaczej{" "}
          {"->"} patrz punkt o błędach.
        </p>
        <p>
          Gdyby zdarzyły się zmiany w cenniku między dodaniem transakcji a jej
          edycją:
        </p>
        <ul>
          <li>
            Pozycje, które pierwotnie były dodane ({"=>"}liczba inna niż 0),
            zachowają swoje ceny z momentu utworzenia transakcji
          </li>
          <li>Reszta pozycji będzie miała aktualne ceny z cennika</li>
          <li>
            Jeśli jakaś pozycja została usunięta z cennika, ale w transakcji
            miała liczbę różną od 0 to zostanie wyświetlona na dole tabeli
          </li>
          <li>
            Jeśli jakaś pozycja została usunięta z cennika i nie było jej w
            transakcji, to przepadło ;). Zawsze można dopisać w uwagach.
          </li>
        </ul>
        {user.perm.transactionsList.r ? (
          <div>
            <h3>Raport sprzedaży</h3>
            <p>
              Raport podsumowuje sprzedaż zgodnie z parametrami wybranymi przez
              użytkownika w filtrze:
            </p>
            <img
              src={sellReportFilter}
              alt=""
              className="border border-black"
            />
            <p>
              Po kliknięciu "Generuj" raport zostanie utworzony i wyświetlony w
              formie trzech tabel:
            </p>
            <h4>Tabela "Podsumowanie":</h4>
            <img
              src={sellReportSummary}
              alt=""
              className="border border-black"
            />
            <ul>
              <li>Liczone są tylko transakcje "zafakturowane"</li>
              <li>
                <u>W przypadku użycia filtra na pracowniku i/lub oddziale</u>{" "}
                pojawia się dodatkowa kolumna pokazująca procentowy udział
                odfiltrowanej sprzedaży w całości "per wiersz" (na podstawie sum
                netto)
                <Button
                  size="sm"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      body: (
                        <>
                          <p>
                            Przyjmijmy, że mamy następujące statystyki
                            sprzedaży: Warszawa 10 sztuk, Kraków 9 sztuk, ale tu
                            mamy dwóch pracowników: Genowefa sprzedała 6 a
                            Kazimierz 3. Do tego Genowefa sprzedała jeszcze 3 na
                            rzecz Katowic. W sumie mamy sprzedane 22 toalety.
                            Dla ułatwienia powiedzmy, że cena każdej toalety =
                            1000 zł netto i{" "}
                            <u>nigdzie nie było żadnej innej sprzedaży</u>
                          </p>
                          <p>
                            Z filtrem domyślnym (wszyscy pracownicy we
                            wszystkich oddziałach i dla wszystkich firm, tzn.
                            Clippera i Ecoloxxu):
                            <ul>
                              <li>
                                Transport (bo pojawia się zawsze ze względów
                                technicznych): 0 zł
                              </li>
                              <li>Toaleta: 22 000 zł</li>
                            </ul>
                          </p>
                          <p>Wybieramy filtr dla Warszawy i widzimy:</p>
                          <ul>
                            <li>Transport: 0 zł i 0% udziału</li>
                            <li>
                              Toaleta 10 000 i 45% udziału w sprzedaży (bo 10/22
                              = 0,45)
                            </li>
                          </ul>
                          <p>Filtr: Genowefa </p>
                          <ul>
                            <li>Transport 0 zł i 0%</li>
                            <li>
                              Toaleta: 9 000 zł i 41% (6 dla Krakowa, 3 dla
                              Katowic {"=>"} 9/22 = 0,41)
                            </li>
                          </ul>
                          <p>Filtr Kraków i Genowefa</p>
                          <ul>
                            <li>Transport: 0 zł i 0%</li>
                            <li>Toaleta 6 000 zł i 27% (6/22 = 0,27)</li>
                          </ul>
                        </>
                      ),
                    })
                  }
                >
                  Przykład
                </Button>
              </li>
            </ul>
            <p className="bg-warningLight ">
              W przyszłości planuję dodać osobną mini-tabelę sumującą sprzedaż
              toalet (w obecnej tabeli sprzedaż toalet np. niebieskich i
              zielonych jest pokazywana osobno). Wyliczany będzie oczywiście
              udział w całości na powyższych zasadach, ale również w ujęciu
              ilościowym, nie tylko po przychodzie.
              <br />
              Dodatkowo do usunięcia są wiersze z wartościami zerowymi
              (pojawiają się w przypadku filtrowania po oddziale i/lub
              pracowniku)
            </p>
            <h4>Tabela "Dane szczegółowe" (statystyki):</h4>
            <img src={sellReportStats} className="border border-black" alt="" />
            <p>
              Tabela przedstawia podstawowe statystyki wszystkich transakcji w
              wybranym okresie (i dla pracownika i/lub oddziału, jeśli
              zaznaczone). Tutaj już nie obowiązuje zliczanie tylko transakcji
              zafakturowanych, więc mamy dane o wszystkich transakcjach, również
              oczekujących, anulowanych itd.
            </p>
            <h4>Tabela "Lista transakcji"</h4>
            <img
              src={sellReportTransactionsList}
              className="border border-black"
              alt=""
            />
            <p>
              Na liście wyświetlone są wszystkie transakcje (niezależnie od
              stanu), które spełniają kryteria filtrowania (tzn. są w wybranym
              okresie, mają przypisany odpowiedni oddział, firmę i pracownika)
              <br />
              <b>Po kliknięciu transakcji otworzy się ona w nowej zakładce</b>
            </p>
          </div>
        ) : null}
        {user.perm.consumption.w ? (
          <div>
            <h2 id="3">3. Magazyny</h2>

            <i className="bg-dangerLight p-1">
              Wszystkie komponenty dotyczące magazynów od wersji 0.4.2 nie są
              kompatybilne z poprzednimi. Tzn. zużycia dodane w poprzednich
              wersjach <u>nie będą widoczne</u> na liście ani w podumowaniach.
              <br />
              Dostęp do danych wprowadzonych przed aktualizacją jest możliwy z
              dodatkowego menu po kliknięciu kółka zębatego na górze po prawej
            </i>
            <h3>Wyjaśnienie pojęć</h3>
            <ul>
              <li>
                <b>Zużycie</b> - pojedyńczy wpis w bazie danych, dodawany po
                kliknięciu "zapisz" na ekranie dodawania zużycia magazynowego.
                Pojedyńcze zużycie może zawierać dane dotyczące wielu różnych
                materiałów i wielu dni, ale zawsze ma przypisane: jednego
                kierowcę, jeden oddział i jeden konkretny miesiąc.
              </li>
              <li id="3.materialy">
                <b>Materiały główne</b> - to materiały, które są najczęściej
                zużywane albo podlegają "wzmożonej kontroli". W oknie
                wprowadzania/edycji zużycia są wyświetlane w górnej tabeli,
                można je dodawać z podziałem na dni (jeden wiersz w górnej
                tabeli - jeden dzień).
              </li>
              <li>
                <b>Materiały normalne</b> - materiały inne niż główne. Przy
                dodawaniu zużycia wyświetlają się w dolnej tabeli, nie ma
                możliwości rozdzielania ich zużycia na konkretne dni, trzeba
                ręcznie je dodawać i w odpowiednim polu wpisać sumę dla całego
                okresu, który jest wprowadzany.
              </li>
            </ul>

            <h3>Dodawanie zużycia magazynowego</h3>
            <p>
              Ekran jest przystosowany do papierowego "Zamówienia dziennego", z
              którego korzystają kierowcy do zgłaszania zapotrzebowania.
            </p>
            <p className="bg-dangerLight text-center">
              Zużycia można podawać z maksymalną dokładnością{" "}
              <b>do dwóch miejsc po przecinku.</b>
            </p>
            <p>Proponuję korzystać z formularza na jeden z dwóch sposobów:</p>
            <ol>
              <li>Jeden wiersz - jedna kartka</li>
              <li>
                W jednym wierszu suma wszystkich zamówień dla danego kierowcy z
                danego miesiąca/okresu
              </li>
            </ol>
            <div>
              Przy korzystaniu z pierwszej metody można sobie ułatwić
              wprowadzania dzięki następującym "sztuczkom":
              <ol>
                <li>
                  Klawisz "Tab" umożliwia przeskakiwanie między polami jak na
                  schemacie poniżej
                </li>
                <li>
                  Wciśnięcie na klawiaturze przycisku "+" dodaje wiersz (tak jak
                  wciśnięcie guzika "dodaj wiersz")
                </li>
                <li>
                  Po dodaniu wiersza kursor automatycznie ustawia się w polu
                  "data"
                </li>
              </ol>
              <p>
                Powyższe umożliwia szybkie wypełnienie całej górnej tabeli tylko
                przy użyciu klawiatury.
              </p>
              <img
                src={consumptionAddImg}
                width="50%"
                alt="widok formularza dodawania zużycia"
              />
              <p>
                Polecam metodę pierwszą, mniejsza szansa na literówki, ale się
                nie upieram. Korzystając z metody drugiej (czyli w jednym
                wierszu zużycia zbiorcze za kilka/naście/dziesiąt dni)
                <b>
                  {" "}
                  proszę ustawić <em>datę tego wiersza = 0</em>
                </b>
              </p>
            </div>
            <p>
              Zużycie pozostałych materiałów można wpisywać w dolnej tabeli, lub
              jeśli nie ma tam odpowiedniego - w dodatkowych informacjach (
              <u>z dokładnym i jednoznacznym podaniem jednostek miary</u>) jeśli
              jakaś pozycja będzie się często pojawiać to wprowadzę ją do
              tabeli.
            </p>
            <p>
              Po wpisaniu wszystkich informacji zapisujemy dane i przechodzimy
              do następnego kierowcy
            </p>
            <h4>Uwagi techniczne</h4>
            <p>
              Na chwilę obecną arkusz nie ma tzw. walidacji, czyli przed
              zapisaniem nie sprawdza czy wprowadzono wartości i czy są one
              poprawne.{" "}
              <b>
                bardzo proszę o uważne sprawdzanie danych przed zapisaniem, żeby
                nie mnożyć błędów
              </b>{" "}
            </p>
            <p>
              Nie ma żadnych przeciwwskazań, żeby zużycia dodawać na bieżąco -
              codziennie, co tydzień, dowolnie.
            </p>
            <p>
              ______________
              <br />* Domyślnie program wpisuje poprzedni miesiąc jeżeli
              wypełniamy arkusz przed 10 dniem miesiąca i obecny w każdym innym
              terminie
            </p>

            <h3>Lista</h3>
            <p>
              Komponent "Lista" umożliwia przeglądanie wszystkich zużyć tak, jak
              zostały wprowadzone. Kliknięcie danej pozycji wyświetla wszystkie
              informacje jakie zostały wprowadzone przy dodawaniu zużycia.
              Umożliwia też przejście do <b>edycji zużycia.</b>
            </p>
            {user.perm.consumptionsList.r ? (
              <>
                <h3>Raport</h3>
                <img
                  src={consumptionsReport}
                  width="50%"
                  alt="widok raportu zużycia"
                  className="mb-4"
                />
                <p>
                  Tu można wygenerować podsumowanie wszystkich zużyć według
                  różnych kryteriów:
                </p>
                <ul>
                  <li>
                    Zakres miesięcy, przy czym nie ma możliwości wybrania
                    miesięcy z przełomu rok (np. od 2020.11 do 2021.01). Jeśli
                    będzie to potrzebne - proszę o informację
                  </li>
                  <li>
                    Wybranego oddziału lub wszystkich - przy czym "wszystkie" to
                    wszystkie do jakich użytkownik ma uprawnienia
                  </li>
                  <li>
                    Wybranego kierowcy, wszystkich kierowców przypisanych do
                    wybranego oddziału lub wszystkich kierowców we wszystkich
                    oddziałach (w zakresie do którego użytkownik ma uprawnienia)
                  </li>
                </ul>
                <p>
                  W górnej tabeli podsumowane są zużycia wszystkich materiałów
                  (niezależnie czy chodzi o{" "}
                  <a href="#3.materialy">
                    materiały główne czy normalne - kliknij, żeby przejść do
                    definicji
                  </a>
                  ). Dodatkowo zużycia są przeliczone na jednostki podstawowe,
                  czyli używane w RW trafiających do księgowości.
                  <br />
                  Dolna tabela służy głównie do wyświetlania dodatkowych uwag
                  wpisanych przez użytkownika przy wprowadzaniu zużycia.
                </p>
                <h4>Koszt materiałów</h4>
                <p>
                  W górnej tabeli wyliczane są koszty zużycia na podstawie
                  średniej ceny danego materiału. <br />
                  Materiały są rozdzielone na "koszt magazynowy" i resztę
                  zgodnie z procedurą magazynową:
                </p>
                <ul>
                  <li>
                    Koszt magazynowe - chodzi o materiały rozliczane przez
                    magazyn, które są doliczane do wyniku oddziału na podstawie
                    RW. A inaczej: te, których zakup jest opisywany jako
                    "MAGAZYN".
                  </li>
                  <li>
                    reszta - materiały, których koszt trafił do oddziałów na
                    podstawie faktur opisywanych na bieżąco przy użyciu kont
                    "5XX"
                  </li>
                </ul>
              </>
            ) : null}
            <h3>Edycja i stan zużycia</h3>
            <p>
              Od wersji 0.4.2 istnieje możliwość edytowania dodanego zużycia.
              Podlega to jednak pewnym ograniczeniom:
            </p>
            <ul>
              <li>
                Nie można edytować zużyć dodanych we wcześniejszych wersjach
              </li>
              <li>
                Nie można edytować zużycia w stanie "zapisane" - oznacza to, że
                zostało już uwzględnione w zestawieniu księgowym. W przypadku
                błędu/korekty proszę o maila.
              </li>
              <li>
                Mogą się zdarzyć zmiany w "katalogu materiałów", na podstawie
                którego tworzone są tabele w oknie dodawania zużycia. Zmiany te
                nie będą miały wpływu na już wprowadzone zużycia i ich
                edytowanie. Przykłady:
                <ul>
                  <li>
                    po dodaniu do katalogu materiałów pozycji "Kwas" będzie ona
                    widoczna w nowym zużyciu, ale nie pojawi się przy edycji
                    zużycia, które było utworzone przed jej dodaniem.
                  </li>
                  <li>
                    Podobnie przy usuwaniu: jeśli kiedyś usunę pozycję "Zapach
                    Cabana" to nie będzie ona widoczna przy dodawaniu nowego
                    zużycia, ale nadal będzie można edytować ilość wykorzystanej
                    Cabany przy zużyciach sprzed jej usunięcia.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <b>Wyjaśnienie tzw. "stanów" zużycia:</b> każde zużycie po dodaniu
              otrzymuje stan "oczekujące" (w domyśle: na przesłanie do
              księgowości). Po wygenerowaniu na jego podstawie dokumentu
              księgowego, który trafi do biura księgowego, stan jest zmianiany
              na "zapisane", co równocześnie blokuje możliwość edycji zużycia -
              chodzi o to, żeby uniknąć wprowadzania zmian bez informowania
              księgowości.
              <br />
              <b>
                Gdyby jednak zdarzyło się, że zużycie "zapisane" trzeba zmienić
                - prosze o informację.
              </b>{" "}
              Jest to oczywiście możliwe i konieczne, tylko muszę wiedzieć, że
              będzie konieczność wygenerowania nowego RW.
            </p>
          </div>
        ) : null}
        {user.perm.sewageReport.r ? (
          <div
            id="4"
            className="bg-secondarySuperLight"
            // ver 1.14.3
          >
            <Row>
              <Col>
                <h2 id="sewagePermissionsAndReports">
                  4. Zezwolenia i sprawozdania kwartalne
                </h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Zezwolenia - podstawowe informacje</h3>
                <p>
                  Każde zezwolenia może mieć dwa stany: aktywne albo archiwum
                  (zmiana możliwa z podglądu szczegółów zezwolenia).
                  <br />
                  <u>
                    <b>Niezależnie od stanu zezwolenia</b> program wygeneruje
                    sprawozdanie, jeśli data ważności mieści się w kwartale
                    wybranym dla sprawozdania.
                  </u>
                </p>
                <p>
                  Generowanie zezwoleń można wyłączyć przy użyciu checkboxa
                  "Sprawozdania?" - zrzut obok
                </p>
                <p>
                  <b>Lista zlewnii</b> - w tym polu należy wpisać zlewnie
                  (najlepiej jedną), które powinny pojawić się na
                  sprawozdaniach.
                </p>
                <h3>Przypomnienia o odnowieniu zezwolenia</h3>
                <p>
                  Program wyśle maila o zbliżającym się terminie odnowienia
                  zezwolenia do kierownika właściwego oddziału. <br />
                  <b>
                    Program nie przypomina o terminie zezwoleń zarchiwizowanych
                  </b>
                </p>
                <h3>Generowanie sprawozdań</h3>
                <p>
                  Sprawozdanie kwartalne można wygenerować wchodząc na
                  "Sprawozdania" {"->"} "Ścieki" i klikając "Generuj".
                  <br />W tym miejscu dostępna jest także lista już
                  wygenerowanych sprawozdań do pobrania. Nazwa pliku zawsze
                  zaczyna się od liter "SKN", następnie jest oznaczenie oddziału
                  (np. "KRA"), rok i miesiąc <b>(ostatni miesiąc kwartału)</b>.
                </p>
                <p>
                  Jeśli dla danego oddziału i kwartału wygenerowano więcej niż
                  jeden plik to program automatycznie doda do nazwy datę i czas.
                </p>
              </Col>
              <Col>
                <img
                  src={sewagePermissionsReportsCheckbox}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </Col>
            </Row>
          </div>
        ) : null}
        {user.type.tester && showNotes ? (
          <div>
            <h2>Sprawozdanie v2 - notatki (tylko dla testerów)</h2>
            <p>
              <i>
                Poniższe to raczej notatki dla mnie na wypadek bardziej
                szczegółowej kontroli + plany na dalszy rozwój algorytmu.
              </i>
            </p>
            <p>
              Sprawozdania są sporządzane na podstawie oddziału przypisanego do
              danego zezwolenia. Czyli nie ma znaczenia, do jakiego oddziału
              była przypisana lokalizacja, ważne jest oddział zezwolenia które
              było wskazane jako właściwe dla tej lokalizacji.
            </p>
            <p>
              Do sprawozdania brane są zadania "serwis" i "zabranie". Przed
              liczeniem odejmowane są usługi nie dotyczące toalet lub szamba.{" "}
            </p>
            <p>
              Liczenie zaczyna się od ustalenia ile było serwisów dużych
              (szmaba) i małych - to zależy od pojemności beczki samochodu,
              który je wykonywał ({">="} 10 - zadanie duże).
            </p>
            <p>
              Jeśli pod zadanie jest podpięta usługa sprzętowa to do liczby
              zadań brana jest liczba sprzętu, jeśli nie ma sprzętu to program
              bierze 1 (UWAGA! Dla zadań dużych zawsze bierze 1{" "}
              <u>dla każdej usługi przy zadaniu</u>)
            </p>
            <p>
              Liczba zadań jest określana dla całego oddziału oraz dla każdego
              zezwolenia z osobna
            </p>
            <p>
              na podstawie liczby zadań dla całego oddziału program liczy ile
              jest ścieku na zadanie wg wzoru: ('ilość ścieku' - 'liczba zadan
              dużych' * 10m3)/'liczba zadań małych'
              <br />
              (tak, z góry przyjąłem, że każde zadanie duże to pełna beczka)
            </p>
            <p>
              Potem dla każdego zezwolenia program mnoży liczbę zadań dużych *
              10m3 i liczbę zadań małych * 'wyliczona ilość ścieku na zadanie',
              suma jest wstawiana w odpowiednie pole w sprawozdaniu.
            </p>
            <p>
              Plany:
              <br />
              dodać możliwość wpisywania kilku zlewni dla gminy i procentowy
              podział wykorzystania
              <br />
              (po e-kontrolce) Określać liczbę ścieku na podstawie zlania
              dodanego w kontrolce, dopisywać ją w każdym serwisie
            </p>
          </div>
        ) : null}

        <ManualEmployeesSection setModalData={setModalData} user={user} />
        <div id="6">
          <ManualCustomersSection setModalData={setModalData} user={user} />
        </div>
        <div id="7">
          <ManualLocationsSection
            setModalData={setModalData}
            user={user}
            showNotes={showNotes}
          />
        </div>
        <div id="8">
          <ManualJobsSection setModalData={setModalData} user={user} />
        </div>
        <div id="9">
          <ManualEqpSection setModalData={setModalData} user={user} />
        </div>
        <div id="10">
          <ManualInvoicingSection setModalData={setModalData} user={user} />
        </div>
        <div id="11">
          <ManualLogisticsSection
            setModalData={setModalData}
            user={user}
            showNotes={showNotes}
          />
        </div>
        <div id="12">
          <ManualTodosSection setModalData={setModalData} user={user} />
        </div>
        <div id="13">
          <ManualCarsSection setModalData={setModalData} user={user} />
        </div>
        <div id="14">
          <ManualDebtCollectionSection
            setModalData={setModalData}
            user={user}
          />
        </div>
        <div id="15">
          <ManualReportsSection setModalData={setModalData} user={user} />
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <MyModal modalData={modalData} setModalData={setModalData} />
      </div>
    </Container>
  )
}
export default Manual
