import React from "react"
import logoClipper from "../../../pics/logo_clipper.png"
import { Table, Row } from "react-bootstrap"
import { format } from "date-fns"

import { CreateEqpLine } from "./utils"

class DeliveryConfirmation extends React.Component {
  render() {
    const { job, location } = this.props

    const mainAddress = location.customer.addresses.find(
      (adr) => adr.type === "główny" && adr.state === "aktywny"
    )

    const checkShowers = () => {
      let isThereShower = false
      job.services.forEach((jobSrv) => {
        jobSrv.eqp.forEach((jobSrvEqp) => {
          if (jobSrvEqp.ref.type === "Prysznic") isThereShower = true
        })
      })
      return isThereShower
    }

    return (
      <Table bordered>
        <tbody>
          <tr>
            <td>
              <Row>
                <img
                  src={logoClipper}
                  height="40px"
                  alt=""
                  className="ml-3 mr-5"
                />
                <h3>Potwierdzenie dostarczenia sprzętu</h3>
              </Row>
            </td>
            <td>Data: {format(new Date(job.date), "yyyy-MM-dd")}</td>
            <td>
              Nr lokalizacji:{" "}
              <h3>
                <b>{`${location.numberYear || ""}-${location.number || ""}`}</b>
              </h3>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <h4>Dane klienta</h4>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {mainAddress ? (
                <p>
                  {`${location.customer.name} ${location.customer.surname}`}
                  <br />
                  {`${mainAddress.street}, ${mainAddress.postal} ${mainAddress.city}`}
                </p>
              ) : (
                <p>
                  ...............................
                  <br />
                  .......................
                </p>
              )}
              {location.customer.vatNumber ? (
                <p>{location.customer.vatNumber}</p>
              ) : null}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <h4>Lokalizacja i uwagi</h4>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {location.street} {location.postal} {location.city}
              <br />
              {job.comments}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <h4>Dane kontaktowe</h4>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {location.contacts
                .filter(
                  (contact) => contact.state === "aktywny" && contact.phone
                )
                .map((contact) => (
                  <p
                    key={`contactLine-${contact._id}`}
                  >{`Kontakt "${contact.type}": ${contact.name} - ${contact.phone}`}</p>
                ))}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <h4>Usługi i sprzęt</h4>
            </td>
          </tr>
          {/* map each service and eqp for it: */}
          {job.services.map((jobSrv) => {
            const locSrv = location.services.find(
              (locSrv) => locSrv._id === jobSrv.locationServiceRef
            )
            return (
              <React.Fragment key={`serviceRowContainer-${jobSrv._id}`}>
                <tr key={`serviceRow-1-${jobSrv._id}`}>
                  <td>{locSrv.name}</td>
                  <td>
                    {locSrv.netPrice} za {locSrv.unit} + {locSrv.vat}% VAT
                  </td>
                </tr>
                <tr key={`serviceRow-2-${jobSrv._id}`}>
                  <td colSpan="3">
                    <Table bordered>
                      <tbody>
                        <tr>
                          <td>Sprzęt</td>
                          <td>Numer</td>
                        </tr>
                        {jobSrv.eqp.map((jobEqp) => {
                          return (
                            <tr key={`eqpRow-${jobEqp._id}`}>
                              <td>{CreateEqpLine(jobEqp, false)}</td>
                              <td></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
          <tr>
            <td colSpan="3">
              Oświadczam, że jestem upoważniony do zaciągania zobowiązań w
              imieniu ww. klienta. Potwierdzenie dostarczenia sprzętu jest
              równoznaczne z przeniesieniem odpowiedzialności za niego na
              klienta. <br />
              Jeśli inne postanowienia nie stanowią inaczej to w przypadku kabin
              sanitarnych (toaleta, prysznic itp.) za wartość rozliczeniową
              przyjmuje się 3000 zł netto (dla kabin dla niepełnosprawnych 6000
              zł), w przypadku ogrodzeń 200 zł za element, inny sprzęt: zgodnie
              z cenami rynkowymi.
            </td>
          </tr>
          {checkShowers() ? (
            <tr>
              <td colSpan="3">
                <ol>
                  <li>
                    Prysznic z podgrzewaczem elektrycznym należy{" "}
                    <b>bezwzględnie</b> podłączyć do instalacji z uziemieniem
                    lub "zerem"
                  </li>
                  <li>
                    Podłączenie powinno być realizowane przez elektryka z
                    uprawnieniami
                  </li>
                  <li>
                    Po podłączeniu uprawniony elektryk musi dokonać pomiaru
                    skuteczności ochrony przeciwporażeniowej
                  </li>
                  <li>
                    Klient ponosi pełną odpowiedzialność za prawidłową
                    instalację kabiny prysznicowej
                  </li>
                </ol>
              </td>
            </tr>
          ) : null}

          <tr>
            <td colSpan="3">
              <Table>
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "right",
                        verticalAlign: "bottom",
                        height: "80px",
                        width: "50%",
                      }}
                    >
                      Czytelny podpis klienta
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        verticalAlign: "bottom",
                        height: "80px",
                      }}
                    >
                      Podpis kierowcy
                    </td>
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }
}
export default DeliveryConfirmation
