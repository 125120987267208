import { Button } from "react-bootstrap"
import { FaJira } from "react-icons/fa"

const createJiraBtn = (jiraId) => {
  return (
    <a
      href={`https://cliperp.atlassian.net/browse/ERP-${jiraId}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Button size="sm">
        <FaJira /> {jiraId}
      </Button>
    </a>
  )
}

export { createJiraBtn }
