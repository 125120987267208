import React, { useMemo, useContext } from "react"
import { useTable, useSortBy, usePagination } from "react-table"
import { Table, Button, Container, Row } from "react-bootstrap"

import UserContext from "../contexts/userContext"

import { MdArrowBack, MdArrowForward } from "react-icons/md"
import { createInvoiceMailBtn } from "./utils"

const CustomersTable = (props) => {
  const user = useContext(UserContext)
  const data = useMemo(() => props.customers, [props])
  const columns = useMemo(
    () => [
      {
        Header: "Nazwa skrócona",
        id: "name",
        accessor: (row) => {
          let shortname = ""
          if (row.isPrivat) {
            shortname = `${row.name} ${row.surname || ""} ${
              row.isOld ? "(imp.)" : ""
            }`
          } else shortname = `${row.shortName} ${row.isOld ? "(imp.)" : ""}`
          return shortname
        },
      },
      {
        Header: "Ulica",
        id: "street",
        accessor: (row) => {
          const mainAddress = row.addresses.find(
            (address) =>
              address.type === "główny" && address.state === "aktywny"
          )
          if (mainAddress) return mainAddress.street
          else return "bd"
        },
      },
      {
        Header: "Miasto",
        id: "city",
        accessor: (row) => {
          const mainAddress = row.addresses.find(
            (address) =>
              address.type === "główny" && address.state === "aktywny"
          )
          if (mainAddress) return mainAddress.city
          else return "bd"
        },
      },
      {
        Header: "Oddział",
        id: "branch",
        accessor: (row) => {
          if (row.branches.length) return row.branches.join(", ")
          else if (row.isOld) return row.oldData.oldBranch || null
          else return null
        },
      },
      { Header: "NIP", accessor: "vatNumber" },
      { Header: "stan", accessor: "state" },
      {
        Header: "@ fv",
        accessor: (row) => createInvoiceMailBtn(row, user),
      },
    ],
    [user]
  )

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        pageIndex: 0,
        sortBy: [{ id: "createdAt", desc: true }],
        hiddenColumns: props.embedded ? ["@ fv"] : [],
      },
    },
    useSortBy,
    usePagination
  )
  return (
    <Container>
      <Table bordered className="mt-4">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                  className="pretty-table-header"
                >
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e) => {
                  // if click target is button don't do anything
                  if (e.target.type === "button") return
                  // if list is used as embedded component (for ex in transactions to fill customer data) clicking row fires func from parent component, else - redirects to details
                  if (props.embedded) return props.setCustomer(row.original)
                  else
                    return window.open(
                      `/CustomersDetails/${row.original._id}`,
                      "CustomerDetails",
                      "noopener noreferrer"
                    )
                }}
                className="transaction-details-tr"
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="pretty-table-data">
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row className="justify-content-end align-items-center">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
          // style={{ maxHeight: "30px" }}
        >
          <MdArrowBack />
        </Button>
        <p>
          <b>{pageIndex + 1}</b> z {pageOptions.length}
        </p>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
        >
          <MdArrowForward />
        </Button>
        {/* below element giving possibility to go to certain page, for now I dont need it */}
        {/* <div>Idź do:</div>
            <input 
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
      </Row>
      <Row className="justify-content-end mt-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          className="mr-4"
        >
          {
            // setting pagination options - how many rows can user display
            [5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Pokaż {pageSize}
              </option>
            ))
          }
        </select>
      </Row>
    </Container>
  )
}
export default CustomersTable
