import React, { useState, useMemo } from "react"
import { Container, Row, Button, Form, Spinner } from "react-bootstrap"
import { format } from "date-fns"

import { submitExport } from "./utils"

const ExportConfirmModal = ({ invoices, configs, setModalData, refresh }) => {
  const exportedInvoices = useMemo(
    () => invoices.filter((inv) => inv.exportDates.length),
    [invoices]
  )

  const [cantExport, setCantExport] = useState(!!exportedInvoices.length)
  const [isFetching, setIsFetching] = useState(false)
  const zeroInvoices = useMemo(() => {
    return invoices.filter(
      (inv) =>
        !inv.InvoiceDetails.productsResults.length ||
        !inv.InvoiceDetails.payments.InvoiceBaseCurrency.InvoiceProductsCost
    )
  }, [invoices])

  if (!invoices.length)
    return (
      <Container className="bg-warningLight">
        <p>Wybierz przynajmniej jedną fakturę</p>
      </Container>
    )
  if (zeroInvoices.length) {
    return (
      <Container className="bg-warningLight">
        <p>Nie można eksportować zerowej faktury</p>
      </Container>
    )
  }
  return (
    <Container>
      <p>
        {invoices.length > 1
          ? "Wyeksportować zaznaczone faktury?"
          : "Wyeksportować fakturę?"}
      </p>
      {exportedInvoices.length ? (
        <div className="bg-dangerLight">
          {/* margins for content: */}
          <div className="m-1">
            <p>
              <b>Uwaga!</b>
              <br />
              Wykryłem ponowny eksport faktur(-y):
            </p>
            <ul>
              {exportedInvoices.map((inv) => {
                return (
                  <li>
                    Klient: {inv.clientResult.ref.shortName}, data:{" "}
                    {format(
                      new Date(inv.InvoiceDetails.InvoiceDate),
                      "yyyy-MM-dd"
                    )}
                    , kwota brutto:{" "}
                    {
                      inv.InvoiceDetails.payments.InvoiceBaseCurrency
                        .InvoiceProductsCost
                    }
                  </li>
                )
              })}
            </ul>
            <Form>
              <Form.Check
                type="checkbox"
                label="Potwierdzam usunięcie faktur(-y) z Symfonii"
                value=""
                id="reexportConfirmCheckbox"
                onChange={(e) => {
                  if (e.target.checked) setCantExport(false)
                  else setCantExport(true)
                }}
              />
            </Form>
          </div>
        </div>
      ) : null}
      <Row className="justify-content-around">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button
          variant="secondary"
          disabled={cantExport || isFetching}
          onClick={() =>
            submitExport(
              invoices.map((inv) => inv._id),
              setModalData,
              configs.devSettings?.setInvoiceExported || true,
              refresh,
              setIsFetching
            )
          }
        >
          {isFetching ? <Spinner animation="border" size="sm" /> : "Potwierdź"}
        </Button>
      </Row>
    </Container>
  )
}
export default ExportConfirmModal
