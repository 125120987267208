import { Button, Dropdown } from "react-bootstrap"

import { submitTodo } from "./submit"
import TodosDetailsEdit from "./edit"

const renderEmployeeOptions = (user, isProposition, board) => {
  // clear value choosen

  // user can always assign himself:
  let res = [
    <option value={user._id} key={`subordinateSelect-${user._id}`}>
      {user.fullName}
    </option>,
  ]

  // board member can assign anyone:
  //( for board members api adds all users to subordinates)
  if (user.type.board === true) {
    return [
      ...res,
      ...user.subordinates.map((sub) => (
        <option value={sub._id} key={`subordinateSelect-${sub._id}`}>
          {sub.fullName}
        </option>
      )),
    ]
  }

  // for proposition user can choose his superiors:
  if (isProposition) {
    res = user.superior.map((sup) => (
      <option value={sup._id} key={`employeeSelectSuperior-${sup._id}`}>
        {sup.fullName}
      </option>
    ))
  }

  // when user has additional perm he can choose also board members:
  if (isProposition && user.canAddBoardTodos)
    board.forEach((member) =>
      res.push(
        <option value={member._id} key={`employeeSelectBoard-${member._id}`}>
          {member.fullName}
        </option>
      )
    )

  // for normal todo user can choose only himself and subordinates:
  if (!isProposition) {
    user.subordinates.forEach((subordinate) =>
      res.push(
        <option
          value={subordinate._id}
          key={`employeeSelectSubordinate-${subordinate._id}`}
        >
          {subordinate.fullName}
        </option>
      )
    )
  }

  return res
}

const renderTodoEditBtns = (todo, user, setModalData, refresh, board) => {
  const btnsArr = []
  //* if is proposition and assigned to user -> show accept/reject btns
  if (todo.isProposition && todo.employee._id === user._id)
    btnsArr.push(
      <>
        <Button
          variant="warning"
          onClick={() =>
            submitTodo(
              setModalData,
              { ...todo, state: "odrzucone" },
              refresh,
              false,
              { state: true }
            )
          }
        >
          Odrzuć
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "przyjęcie zadania",
              body: (
                <TodosDetailsEdit
                  isNew={false}
                  todo={todo}
                  setModalData={setModalData}
                  refresh={refresh}
                  propositionAccepted={true}
                />
              ),
            })
          }
        >
          Przyjmij
        </Button>
      </>
    )

  //* if is normal todo assigned for user -> show change state dropdown
  if (!todo.isProposition && todo.employee._id === user._id)
    btnsArr.push(
      <Dropdown key="stateDropdown">
        <Dropdown.Toggle variant="warning">Zmień stan</Dropdown.Toggle>
        <Dropdown.Menu>
          {[
            "weryfikacja",
            "zlecone",
            "w realizacji",
            "do sprawdzenia",
            "zrobione",
            "odrzucone",
          ].map((state) => {
            if (state === todo.state) return null
            else
              return (
                <Dropdown.Item
                  key={`stateDropdown-${state}`}
                  onClick={() =>
                    submitTodo(
                      setModalData,
                      { ...todo, state: state },
                      refresh,
                      false,
                      { state: true }
                    )
                  }
                >
                  {state}
                </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )

  /* 
  *user can edit when:
  - todo is proposition and he created it
  - todo is not proposition and is assigned to user
  - todo is not proposition and is assigned to users subordinate
  - user is board member
  */

  if (
    (todo.isProposition && todo.creator._id === user._id) ||
    (!todo.isProposition && todo.employee._id === user._id) ||
    (!todo.isProposition &&
      user.subordinates.find((sub) => sub._id === todo.employee._id)) ||
    user.type.board
  )
    btnsArr.push(
      <Button
        variant="secondary"
        onClick={() => {
          return setModalData({
            show: true,
            type: "info",
            header: "edycja zadania",
            body: (
              <TodosDetailsEdit
                isNew={false}
                todo={todo}
                setModalData={setModalData}
                refresh={refresh}
                board={board}
              />
            ),
          })
        }}
      >
        Edytuj
      </Button>
    )

  return btnsArr
}

export { renderEmployeeOptions, renderTodoEditBtns }
