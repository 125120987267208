import React, { useState, useEffect } from "react"
import { Button, Container, Row, Col } from "react-bootstrap"

import MyModal from "../../utils/Modals"
import ServicesManageEditModal from "./EditModal"
import { ServicesManageFilters } from "./Filters"

import ServicesManageTableRender from "./TableRender"

const ServicesManageTableFetch = () => {
  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [services, setServices] = useState(null)
  const [servicesFiltered, setServicesFiltered] = useState([])
  const [configs, setConfigs] = useState(null)
  const [filters, setFilters] = useState({
    name: "",
    type: "wszystkie",
    chargeType: "wszystkie",
    mustHaveEqp: "wszystkie",
    state: "wszystkie",
  })

  const refresh = () => {
    //TODO: refactor useEffect to fetch configs only one, then on refresh only get services
    //TODO: use toast
    setRefreshCounter(refreshCounter + 1)
  }

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setModalData({ show: false })
        setIsLoading(true)
        setServices([])
        const res = await fetch("/services/getAll")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setServices(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    const fetchConfigs = async () => {
      try {
        const res = await fetch("/configs/eqpTypes,calendarIcons")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    Promise.all([fetchServices(), fetchConfigs()]).then(() =>
      setIsLoading(false)
    )
  }, [refreshCounter])

  useEffect(() => {
    if (services?.length && filters) {
      const srvsFiltered = services.filter((srv) => {
        let isOk = true

        // go through all filters, if any evaluates to false set isOk to false and break loop
        for (const filter in filters) {
          if (filter === "name" && filters.name) {
            const nameSearch = new RegExp(filters[filter], "gi")
            const nameTest = nameSearch.test(srv.name)
            if (!nameTest) {
              isOk = false
              break
            }
          } else if (
            filter !== "name" && // prevent this condition when filter === "name", but is empty string
            filters[filter] !== "wszystkie" &&
            srv[filter] !== filters[filter]
          ) {
            isOk = false
            break
          }
        }

        return isOk
      })

      setServicesFiltered(srvsFiltered)
    }
  }, [services, filters])

  return (
    <Container>
      <Row>
        <Col>
          <h3>Lista usług</h3>
        </Col>
        <Col>
          <Button
            className="ml-auto mr-3"
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                hideFooter: true,
                header: "Dodawanie/edycja usługi",
                body: (
                  <ServicesManageEditModal
                    srv="new"
                    setModalData={setModalData}
                    configs={configs}
                    refresh={refresh}
                  />
                ),
              })
            }
          >
            Dodaj
          </Button>
        </Col>
      </Row>
      <Row>
        <ServicesManageFilters setFilters={setFilters} filters={filters} />
      </Row>
      {isLoading ? (
        "pobieram dane..."
      ) : (
        <ServicesManageTableRender
          setModalData={setModalData}
          services={servicesFiltered}
          configs={configs}
          refresh={refresh}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default ServicesManageTableFetch
