import React, { useState, useEffect, useContext } from "react"
import { Container, Spinner } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import TodosDetailsRender from "./render"

const TodosDetailsFetch = ({ match }) => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [todo, setTodo] = useState()
  const [board, setBoard] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const refresh = () => window.location.reload()

  useEffect(() => {
    const fetchTodo = async (params) => {
      try {
        const res = await fetch(`/todos/getById/${match.params._id}`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setTodo({ ...resJSON, deadline: new Date(resJSON.deadline) })
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    const fetchBoard = async (params) => {
      try {
        const res = await fetch(`/employees/getBoard`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setBoard(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchAll = async () => {
      await Promise.all([fetchTodo(), fetchBoard()])
      setIsLoading(false)
    }

    fetchAll()
  }, [setModalData, match])
  return (
    <Container fluid>
      {isLoading ? (
        <>
          <Spinner animation="border" />
          Pobieram dane
        </>
      ) : (
        <TodosDetailsRender
          todo={todo}
          board={board}
          isModal={false}
          setModalData={setModalData}
          refresh={refresh}
          user={user}
        />
      )}
      <MyModal setModalData={setModalData} modalData={modalData} />
    </Container>
  )
}
export default TodosDetailsFetch
