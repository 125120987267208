import React from "react"
import { Table } from "react-bootstrap"

import EquipmentDetailsModal from "../DetailsModal"
const EquipmentTableRender = ({ equipment, setModalData, configs }) => {
  const eqpState = (eqp) => {
    if (eqp.isTemplate) return null
    if (!eqp.currLoc) return <td className="bg-success">nie</td>
    else if (eqp.currLoc.inPlace) return <td className="bg-danger">tak</td>
    else return <td className="bg-warning">rez.</td>
  }

  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="7">Sprzęt </th>
        </tr>
      </thead>
      <tbody>
        <tr className="fixed-values-table">
          <td>Typ</td>
          <td>Podtyp</td>
          <td>Charakterystyka</td>
          <td>Kolor</td>
          <td>Kategoria</td>
          <td>Mod.</td>
          <td>Numer</td>
          <td>Zajęta?</td>
        </tr>
        {equipment.map((eqp) => (
          <tr
            key={`eqp-row-${eqp._id}`}
            className="clickable"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                header: "Szczegóły sprzętu",
                body: (
                  <EquipmentDetailsModal
                    eqp={eqp}
                    setModalData={setModalData}
                    configs={configs}
                  />
                ),
              })
            }
          >
            <td>{eqp.type}</td>
            <td>{eqp.subtype}</td>
            <td>{eqp.char}</td>
            <td>{eqp.color}</td>
            <td>{eqp.category}</td>
            <td>{eqp.mods}</td>
            <td>{eqp.isTemplate ? "templatka" : eqp.number}</td>
            {eqpState(eqp)}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
export default EquipmentTableRender
