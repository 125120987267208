import React from "react"
import { Button, Form, Container, Row } from "react-bootstrap"

const SendMail = (props) => {
  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const data = { mess: e.target.mess.value }

      const res = await fetch("/misc/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      if (res.status !== 200) {
        const resJSON = await res.json()
        throw resJSON
      }
      props.setModalData({
        show: true,
        type: "info",
        body: "Wiadomość wysłana",
        headerColor: "success",
        onHide: () => props.setModalData({ show: false }),
      })
    } catch (err) {
      alert(err)
    }
  }
  return (
    <Container>
      <p>
        Wyślij wiadomość na mail krzysiek@clipper.pl. Nazwa użytkownika zostanie
        załączona automatycznie.
      </p>
      <Form onSubmit={handleSubmit} id="sendMailForm">
        <Form.Control as="textarea" name="mess" rows="15" cols="100" />
        <br />
        <Row>
          <Button
            variant="primary"
            onClick={() => props.setModalData({ show: false })}
          >
            Anuluj
          </Button>
          <Button type="submit" variant="secondary" className="ml-auto">
            Wyślij
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default SendMail
