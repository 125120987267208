import React from "react"
import { toNumber } from "lodash"
import { Form, Button } from "react-bootstrap"

const roundToHundredth = (n) => {
  return Math.round(n * 100) / 100
}
const nF = (n) =>
  new Intl.NumberFormat("pl-PL", {
    style: "currency",
    currency: "PLN",
  }).format(n)

const RenderPriceRow = ({
  register,
  watch,
  setValue,
  Controller,
  control,
  price,
  i,
  user,
}) => {
  // registering "static" fields to have consistent data
  register(`orderDetails.prices.${i}.name`, {
    value: price.name,
  })
  register(`orderDetails.prices.${i}.unit`, {
    value: price.unit,
  })
  register(`orderDetails.prices.${i}.vat`, {
    value: price.vat,
  })
  register(`orderDetails.prices.${i}._id`, {
    value: price._id,
  })

  if (price.type) {
    register(`orderDetails.prices.${i}.type`, {
      value: price.type,
    })
  }

  return (
    <tr
      //add background to rows where quantity !== 0
      //but on first render inputs are not registered so condition cant be "!==0", I had to add default value to watch
      //and reverse conditional for everything to work
      className={
        toNumber(watch(`orderDetails.prices.${i}.quantity`)) === 0
          ? //   ||
            // watch(`orderDetails.prices[${i}].quantity`, "0") === ""
            null
          : "bg-secondarySuperLight"
      }
      id={price.id}
    >
      <td className="fixed-values-table">{price.name}</td>
      <td className="fixed-values-table">{price.unit}</td>
      {/* quantity */}
      <td>
        <Form.Control
          {...register(`orderDetails.prices.${i}.quantity`)}
          type="number"
          // name={`${price.name}.quantity`}

          className="small-number-input"
          step="any"
        />
      </td>
      {/* <td className="fixed-values-table">{nF(price.netPrice)}</td> */}

      <td>
        <Controller
          control={control}
          name={`orderDetails.prices.${i}.netPrice`}
          render={({ field }) => (
            <Form.Control
              {...field}
              type="number"
              onChange={(e) => {
                setValue(
                  `orderDetails.prices.${i}.grossPrice`,
                  roundToHundredth(e.target.value * (1 + price.vat))
                )
                field.onChange(e)
              }}
              step="any"
              className="large-number-input"
            />
          )}
        />
      </td>
      <td className="fixed-values-table">{price.vat * 100}%</td>
      <td>
        <Controller
          control={control}
          name={`orderDetails.prices.${i}.grossPrice`}
          render={({ field }) => (
            <Form.Control
              {...field}
              type="number"
              onChange={(e) => {
                setValue(
                  `orderDetails.prices.${i}.netPrice`,
                  roundToHundredth(e.target.value / (1 + price.vat))
                )
                field.onChange(e)
              }}
              step="any"
              className="large-number-input"
            />
          )}
        />
      </td>

      {/* net value */}
      <td name={`orderDetails.prices.${i}.netValue`} id={`netValueTd.${i}`}>
        {nF(
          roundToHundredth(
            watch(`orderDetails.prices.${i}.quantity`) *
              watch(`orderDetails.prices.${i}.netPrice`)
          )
        )}
      </td>
      {/* gross value */}
      <td id={`grossValueTd.${i}`}>
        {nF(
          roundToHundredth(
            watch(`orderDetails.prices.${i}.quantity`) *
              watch(`orderDetails.prices.${i}.grossPrice`)
          )
        )}
        {user.type.admin ? (
          <Button
            onClick={() => console.log(watch(`orderDetails.prices.${i}`))}
          >
            ?
          </Button>
        ) : null}
      </td>
    </tr>
  )
}

export { RenderPriceRow }
