import React, { useState, useEffect } from "react"
import _ from "lodash"
import {
  Container,
  Row,
  Table,
  Col,
  Button,
  Form,
  Badge,
} from "react-bootstrap"
import { useForm } from "react-hook-form"

import { format } from "date-fns"
import { getSrvsActiveOnDate } from "../utils/servicesHelpers"

const LocationsDetailsInvModTable = ({ location, setModalData, refresh }) => {
  const [showAll, setShowAll] = useState(false)
  //TODO ERP-518

  const renderInvModRow = (invMod) => {
    // create state shortcut:
    let shortcut = ""
    switch (invMod.state) {
      case "oczekująca":
        shortcut = "ocz."
        break
      case "zafakturowana":
        shortcut = "zaf."
        break
      case "usunięta":
        shortcut = "us."
        break
      default:
        shortcut = "BŁĄD"
    }

    // get service name:
    const service = location.services.find(
      (srv) => srv._id === invMod.locSrvRef
    )

    return (
      <tr
        key={`invModRow-${invMod._id}`}
        className="clickable"
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            hideFooter: true,
            header: "Edycja",
            body: (
              <InvModEditModal
                location={location}
                setModalData={setModalData}
                isNew={false}
                refresh={refresh}
                invMod={invMod}
              />
            ),
          })
        }
      >
        <td>{shortcut}</td>
        <td>{invMod.amount}</td>
        <td>{`${service?.locSrvNo || "?"}. ${
          service?.name || "BŁĄD - brak danych"
        }`}</td>
        <td>{format(new Date(invMod.applyDate), "yyyy-MM-dd")}</td>
        <td>{invMod.history[0]?.user?.fullName || "BŁĄD - brak danych"}</td>
        <td>{invMod.desc}</td>
      </tr>
    )
  }
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="6">
            <Row>
              <Col>Modyfikacje faktur</Col>
              <Col>
                <Button variant="light" onClick={() => setShowAll(!showAll)}>
                  {showAll ? "Pokaż oczekujące" : "Pokaż wszystkie"}
                </Button>
              </Col>
              <Col>
                <Row>
                  <Button
                    variant="secondary"
                    className="ml-auto mr-3"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        hideFooter: true,
                        header: "Dodawanie modyfikacji faktury",
                        body: (
                          <InvModEditModal
                            location={location}
                            setModalData={setModalData}
                            isNew={true}
                            refresh={refresh}
                          />
                        ),
                      })
                    }
                    id="addInvModBtn"
                  >
                    Dodaj
                  </Button>
                </Row>
              </Col>
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="fixed-values-table">
          <td>Stan</td>
          <td>Kwota</td>
          <td>Usługa</td>
          <td>Data</td>
          <td>Użytkownik</td>
          <td>Opis</td>
        </tr>
        {location.invMods?.length ? (
          location.invMods
            .filter((invMod) => {
              if (showAll) return true
              else return invMod.state === "oczekująca"
            })
            .map((invMod) => renderInvModRow(invMod))
        ) : (
          <tr>
            <td>Brak danych</td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const InvModEditModal = ({
  invMod,
  location,
  setModalData,
  isNew,
  refresh,
}) => {
  const defaultValues = isNew
    ? {
        applyDate: format(new Date(), "yyyy-MM-dd"),
        locSrvRef: "plug",
        showInvInfo: true,
        invNote: "",
        amount: null,
      }
    : { ...invMod, applyDate: format(new Date(invMod.applyDate), "yyyy-MM-dd") }
  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
    getValues,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  const [services, setServices] = useState([])

  // I coudnt handle services select input to act as I wanted with RHF (see comment on end of file),
  // so this input is controlled by separate state
  const [srvChoosen, setSrvChoosen] = useState(
    isNew ? "plug" : invMod.locSrvRef
  )
  // my validation (used for service select)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    const activeSrvs = getSrvsActiveOnDate(new Date(), location.services)

    setServices(activeSrvs)
  }, [location, setError, setValue])

  // establish if discount or charge and show appriopriate badge: -->
  const watchAmount = watch("amount", null)
  const showAmountBadge = () => {
    if (watchAmount > 0) return <Badge variant="secondary">Obciążenie</Badge>
    else if (watchAmount < 0) return <Badge variant="danger">Rabat</Badge>
    else return null
  }
  // <--

  const submit = async (formData) => {
    try {
      setFormErrors({})
      // validate service choosen, see comments (above in states declaration and at the end of file)
      if (srvChoosen === "plug" && !services.length)
        return setFormErrors({
          ...formErrors,
          locSrvRef: "brak usług w wybranym dniu",
        })
      // and just in case:
      if (srvChoosen === "plug")
        return setFormErrors({ ...formErrors, locSrvRef: "pole wymagane" })

      formData.locSrvRef = srvChoosen

      if (isNew) formData.state = "oczekująca"

      // set dirty fields and include service select if dirty:
      const dirty = { ...dirtyFields }
      if (!isNew && invMod.locSrvRef !== srvChoosen) dirty.locSrvRef = true

      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=${isNew}&entity=invMods`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: formData,
            historyLength: isNew ? null : invMod.history.length,
            dirtyFields: isNew ? null : dirty,
          }),
        }
      )
      if (res.status !== 200) {
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      }
      setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Dane zapisane",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        header: "Błąd",
        body: err.message,
      })
    }
  }
  return (
    <Container>
      {!isNew ? (
        <>
          Stan:
          <Form.Control
            {...register("state")}
            as="select"
            autoComplete="chrome-off"
          >
            <option value="oczekująca">oczekująca</option>
            <option value="zafakturowana">zafakturowana</option>
            <option value="usunięta">usunięta</option>
          </Form.Control>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(submit)}>
        Kwota: {showAmountBadge()}
        {errors.amount && (
          <Badge pill variant="warning">
            {errors.amount.message}
          </Badge>
        )}
        <Form.Control
          {...register("amount", {
            required: "pole wymagane",
            validate: (v) => (v !== 0 && v !== "0") || "pole wymagane",
          })}
          as="input"
          type="number"
          className=""
          autoComplete="chrome-off"
          step="any"
          id="invModAmount"
        />
        Data zastosowania:
        {errors.applyDate && (
          <Badge pill variant="warning">
            {errors.applyDate.message}
          </Badge>
        )}
        <Form.Control
          {...register("applyDate", { required: "pole wymagane" })}
          as="input"
          type="date"
          autoComplete="chrome-off"
          onChange={(e) => {
            const activeSrvs = getSrvsActiveOnDate(
              new Date(e.target.value),
              location.services
            )

            setServices(activeSrvs)
            if (!activeSrvs.length)
              setFormErrors({
                ...formErrors,
                locSrvRef: "brak usług w wybranym dniu",
              })
            else if (
              !_.includes(
                activeSrvs.map((srv) => srv._id),
                getValues("locSrvRef")
              )
            )
              document.getElementById("srvSelect").value = "plug"
          }}
          id="invModApplyDate"
        />
        Usługa:
        {/* see comment in states declarations */}
        {formErrors.locSrvRef && (
          <Badge pill variant="warning">
            {formErrors.locSrvRef}
          </Badge>
        )}
        {/* !!! THIS IS NOT IN REACT-HOOK-FORM !!! */}
        <Form.Control
          as="select"
          onChange={(e) => setSrvChoosen(e.target.value)}
          value={srvChoosen}
          autoComplete="chrome-off"
          id="srvSelect"
        >
          <option value="plug" hidden>
            wybierz
          </option>
          {services.map((srv) => (
            <option value={srv._id}>
              {srv.locSrvNo || "?"}. {srv.name}
            </option>
          ))}
        </Form.Control>
        Info na fakturę:
        <Form.Control
          {...register("invNote")}
          as="input"
          type="text"
          autoComplete="chrome-off"
        />
        <Form.Check
          {...register("showInvInfo")}
          type="checkbox"
          label="Pokaż na fakturze"
          id="showInvInfo"
        />
        Opis:
        {errors.desc && (
          <Badge pill variant="warning">
            {errors.desc.message}
          </Badge>
        )}
        <Form.Control
          {...register("desc", { required: "pole wymagane" })}
          as="textarea"
          className=""
          autoComplete="chrome-off"
        />
        {!isNew && srvChoosen !== invMod.locSrvRef ? (
          <Row className="bg-danger p-2 my-2">
            Uwaga! W trakcie edycji nastąpiła zmiana wybranej usługi
          </Row>
        ) : null}
        <Row className="mx-2 justify-content-between">
          <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
          <Button variant="secondary" type="submit">
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default LocationsDetailsInvModTable

/*
services are calculated on each date change. I wanted select to preserve service choosen as long, as it is
available in activeSrvs. But there was one situation it wasn't working:
srv1 - active from 1 to 20
srv2 - active from 10 to 30
when I choose date=== 25 and srv2, and then change date to 15 it caused input to change to srv1
even though srv2 was still in activeSrvs

Adding separate state and setting input value to it solved problem. But when I tried to use Controller to do this
with RHF I got error (propably because of calculating service options on the run)
*/
