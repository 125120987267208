import { useState } from "react"
import { differenceInCalendarDays, format, isValid, max, min } from "date-fns"
import {
  Container,
  Row,
  Badge,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap"
import { useForm } from "react-hook-form"
import { createManualBtn } from "../../utils/buttons"

const LocationDetailsEditSrvQtyModal = ({
  srv,
  location,
  refresh,
  setModalData,
  user,
}) => {
  const [validationError, setValidationError] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  const [apiAlerts, setApiAlerts] = useState("") // used to display alerts, that didn't block change, just info
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({ defaultValues: { hasEndDate: false } })

  const submit = async (formData, event, confirmed = false) => {
    try {
      setIsLoading(true)

      //TODO handle no-eqp perPcs services updating? Or not? It will be shown only when params are reveald, maybe just add info to manual to ignore it?
      //! and make sure that it will not be counted for inv, WHEN !mustHaveEqp && chargetype === "za serwis" always use qty = 1
      // that way it is not breaking change ;)

      const { qty, useDate, date } = formData

      //* check if there is possibility to override planned qty changes and show alert -->
      const changeFutureParamsDate = checkPlannedParams(date, srv)
      if (changeFutureParamsDate && !confirmed)
        return setValidationError(
          <p>
            Ta usługa ma zaplanowaną zmianę liczby. Akceptacja zmiany spowoduje{" "}
            <b>nadpisanie</b> wszystkich zaplanowanych liczb od daty{" "}
            {format(new Date(changeFutureParamsDate), "yyyy-MM-dd")}
            <br />
            (wyjaśnienie: {createManualBtn("qtyOverride")})
            <br />
            <br />
            <Button
              variant="warning"
              onClick={() => submit(formData, event, true)}
            >
              Nadpisz
            </Button>
            <Button
              onClick={() => {
                setIsLoading(false)
                setValidationError(null)
              }}
            >
              Anuluj
            </Button>
          </p>
        )
      //* <--

      //* when all params closed and choosen date > last end date -> return error
      if (!srv.params.find((prm) => !prm.endDate)) {
        // get largest endDate
        const maxEndDate = max(srv.params.map((prm) => new Date(prm.endDate)))
        if (differenceInCalendarDays(new Date(date), maxEndDate) > 0) {
          setIsLoading(false)
          return setValidationError(
            <p>Wybrana data jest późniejsza od daty zamknięcia usługi.</p>
          )
        }
      }

      const res = await fetch("/locations/srvPrmQtyChange", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          newQty: qty,
          date: useDate ? date : null,
          loc_id: location._id,
          srv_id: srv._id,
          locHistoryLength: location.history?.length || location.historyLength,
          srvHistoryLength: srv.history?.length || srv.historyLength,
        }),
      })
      if (res.status !== 200) {
        setIsLoading(false)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      }

      const resText = await res.text()
      if (resText.length !== 0) setApiAlerts(resText)
      else
        return refresh({
          header: "Sukces",
          headerColor: "bg-success",
          body: `Liczba dla usługi ${srv.locSrvNo || ""}. ${
            srv.name
          } zmieniona`,
        })
    } catch (err) {
      console.log(err)
      setSubmitError(err.message || "nieokreślony błąd komunikacji z serwerem")
    }
  }

  if (srv.chargeType === "za serwis")
    return (
      <Container>
        <Alert variant="warning">
          Usługi naliczane "za serwis" nie mają liczby, faktura jest obliczana
          na podstawie wykonanych serwisów {createManualBtn("perPcsSrv")}
        </Alert>
        <Row>
          <Button
            className="ml-auto mr-3"
            onClick={() => setModalData({ show: false })}
          >
            Zamknij
          </Button>
        </Row>
      </Container>
    )

  return (
    <Container id="editSrvQtyModal">
      <Form onSubmit={handleSubmit(submit)}>
        <Row>
          Zmień liczbę <b>używaną do fakturowania</b> na{"  "}
          <Form.Control
            {...register("qty", { required: "pole wymagane" })}
            as="input"
            type="number"
            step="any"
            className="medium-number-input"
            autoComplete="chrome-off"
          />
          {errors.qty && (
            <Badge pill variant="warning">
              {errors.qty.message}
            </Badge>
          )}
        </Row>
        <Row className="mt-2">
          <Col xs="auto">
            <Form.Check
              {...register("useDate")}
              type="checkbox"
              label="Zmiana ma nastąpić od daty: "
              id="hasEndDate"
            />
          </Col>
          <Col xs="auto">
            <Form.Control
              {...register("date")}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
              readOnly={!watch("useDate")}
            />
            {!watch("useDate") && watch("date") ? (
              <Badge variant="warning">
                Pole zostanie wyczyszczone przy zapisie
              </Badge>
            ) : null}
          </Col>
        </Row>{" "}
        <Row>
          <Alert variant="warning">
            Zmiana nie ma wpływu na liczby sprzętu w <b>zadaniach</b>. Pamiętaj,
            żeby osobno edytować zadania jeśli to konieczne!
          </Alert>
        </Row>
        {validationError ? (
          <Alert variant="danger">{validationError}</Alert>
        ) : null}
        {submitError ? <Alert variant="danger">{submitError}</Alert> : null}
        {apiAlerts ? (
          <Row>
            <Alert variant="warning">{apiAlerts}</Alert>
            <Button
              variant="secondary"
              onClick={() =>
                refresh({
                  header: "Sukces",
                  headerColor: "bg-success",
                  body: `Liczba dla usługi ${srv.locSrvNo || ""}. ${
                    srv.name
                  } zmieniona`,
                })
              }
            >
              {user.isFemale
                ? "Zapoznałem się, zamknij komunikat"
                : "Zapoznałam się, zamknij komunikat"}
            </Button>
          </Row>
        ) : null}
        <Row className="justify-content-between mx-3 mt-3">
          <Button
            variant="warning"
            disabled={isLoading}
            onClick={() => setModalData({ show: false })}
          >
            Anuluj
          </Button>
          <Button type="submit" variant="secondary" disabled={isLoading}>
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}

/**
 *
 * @param {*} date date to apply change choosen by user
 * @param {Object} srv service object with params
 * @returns date if there is risk of changing planned params qty and false if there is not
 */
const checkPlannedParams = (date, srv) => {
  // if there is date choosen -> check if any param is starting after this date
  if (date) {
    const futureParams = srv.params.filter(
      (prm) =>
        differenceInCalendarDays(new Date(date), new Date(prm.startDate)) < 0
    )

    if (futureParams.length) return date
  }
  // if there is no date choosen -> change will occur from start of srv or invoicedTo
  else {
    // first check if any param is invoiced and get biggest invoicedTo date if so
    // and check if there are any params starting after this date
    const invoicedParams = srv.params.filter(
      (prm) => prm.invoicedTo && isValid(new Date(prm.invoicedTo))
    )
    if (invoicedParams.length) {
      const lastInvoicedTo = max(
        invoicedParams.map((prm) => new Date(prm.invoicedTo))
      )
      const lastStartDate = max(
        srv.params.map((prm) => new Date(prm.startDate))
      )
      if (differenceInCalendarDays(lastInvoicedTo, lastStartDate) < -1)
        //(+1 day as there can be closed param and new one starting next day)
        return lastInvoicedTo
    }
    // if srv has no invoicedTo date change will occur from start date,
    // so if there are 2 or more params -> show alert
    else {
      if (srv.params.length > 1)
        return min(srv.params.map((prm) => new Date(prm.startDate)))
    }
  }
  // when all checks passed -> return false
  return false
}

export { LocationDetailsEditSrvQtyModal }
