import { Table } from "react-bootstrap"

import TodosDetailsRender from "../details/render"
const todosStates = [
  "weryfikacja",
  "zlecone",
  "w realizacji",
  "do sprawdzenia",
  "zrobione",
  "odrzucone",
]

const handleSorting = (sortBy, todos) => {
  // .sort() changes original array, which caused problems with rendering
  // (table was sorted with one-render delay, I think that state updated after rerender - stale state error)
  // so I have to create copy:
  const todosCopy = [...todos]
  return todosCopy.sort((a, b) => {
    if (sortBy.field === "state") {
      const aStateInd = todosStates.findIndex((el) => el === a.state)
      const bStateInd = todosStates.findIndex((el) => el === b.state)
      return sortBy.order * (aStateInd - bStateInd)
    } else if (sortBy.order < 0) return a[sortBy.field] - b[sortBy.field]
    else return b[sortBy.field] - a[sortBy.field]
  })
}

const renderPropositionsTable = (propositions, setModalData, refresh, user) => {
  if (!propositions.length) return null
  return (
    <>
      <h5>Propozycje</h5>
      <Table>
        <thead>
          <tr>
            <td>Utworzył</td>
            <td>Przypisany</td>
            <td>Tytuł</td>
            <td>Opis</td>
          </tr>
        </thead>
        <tbody>
          {propositions.map((proposition) => (
            <tr
              className="clickable"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Szczegóły",
                  body: (
                    <TodosDetailsRender
                      todo={proposition}
                      setModalData={setModalData}
                      isModal={true}
                      refresh={refresh}
                      user={user}
                    />
                  ),
                })
              }
              key={`propositionRow-${proposition._id}`}
            >
              <td>{proposition.creator?.fullName || "BŁĄD"}</td>
              <td>{proposition.employee?.fullName || "BŁĄD"}</td>
              <td>{proposition.name}</td>
              <td>{proposition.desc}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export { handleSorting, renderPropositionsTable }
