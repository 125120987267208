import {
  getClosableJobSrvs,
  SrvClosingTable,
} from "../../_shared/jobs/srvClosingTable/SrvClosingTable"

const submitJobsBatchEdit = async (
  data,
  setFetchError,
  setModalData,
  refresh,
  jobs
) => {
  try {
    if (!jobs.length)
      return setModalData({
        show: true,
        type: "info",
        headerColor: "warning",
        body: "Brak zadań do zmiany",
      })

    // //* handling pickups and closing services:

    const srvClosingPickups = []

    const pickups = jobs.filter((job) => job.type === "zabranie")

    // if there is any pickup -> check if any closes service
    if (pickups.length && data.modifications.state?.newValue === "wykonane") {
      pickups.forEach((job) => {
        const closableSrvs = getClosableJobSrvs(job)

        if (closableSrvs.length) {
          srvClosingPickups.push(job)
        }
      })

      if (srvClosingPickups.length) {
        return setModalData({
          show: true,
          type: "info",
          xl: true,
          header: "Wybierz usługi do zamknięcia",
          hideFooter: true,
          body: (
            <SrvClosingTable
              jobs={srvClosingPickups}
              fetchData={data}
              submit={submitNormalJobs}
              refresh={refresh}
              setModalData={setModalData}
            />
          ),
        })
        // return closingPickupsModal(data, srvToClose, setModalData, refresh)
      }
    }

    await submitNormalJobs(data, refresh, setModalData)
    // return refresh()
  } catch (err) {
    console.log(err)

    setFetchError(`Błąd: ${err.message}`)
  }
}

const submitNormalJobs = async (data, refresh, setModalData) => {
  const res = await fetch("/jobs/batchEdit", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })

  if (res.status !== 200) {
    console.log(res)
    throw new Error(`Błąd programu: ${res.status}: ${await res.text()}`)
  }

  // handle showing alerts (jobs settled, but not 100% successfully)

  const resText = await res.text()
  if (resText?.length) {
    return setModalData({
      show: true,
      type: "info",
      body: (
        <p style={{ whiteSpace: "pre-wrap" }}>
          Podczas nanoszenia zmian wystąpiły następujące problemy:
          <br />
          {resText}
        </p>
      ),
      headerColor: "warning",
      onHide: refresh,
    })
  } else {
    return refresh({
      headerColor: "bg-success",
      header: "SUKCES",
      body: "Zmiany zostały zapisane",
    })
  }
}

export { submitJobsBatchEdit }
