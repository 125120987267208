import React, { useEffect, useState } from "react"
import { Container, Button, Alert, Row } from "react-bootstrap"

import renderSpinner from "../../utils/renderSpinner"

const SewageReportv2DownloadReportModal = ({ s3Key }) => {
  const [fetchErr, setFetchErr] = useState(null)
  const [s3SignedUrl, setS3SignedUrl] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`/sewage/getS3Url`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ key: s3Key }),
        })
        if (res.status === 403) return setFetchErr("Brak uprawnień")
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych: ${res.status} - ${await res.text()}`
          )
        const resText = await res.text()
        setS3SignedUrl(resText)
      } catch (err) {
        console.log(err)
        setFetchErr(`${err.message || "Błąd pobierania danych"}`)
      }
    }
    fetchData()
  }, [s3Key])
  return (
    <Container>
      {s3SignedUrl ? (
        <>
          <Row className="justify-content-center my-3">
            <Button href={s3SignedUrl} download={true}>
              Kliknij aby pobrać sprawozdanie
            </Button>
          </Row>
        </>
      ) : (
        renderSpinner("Przygotowuję plik")
      )}
      {fetchErr ? <Alert variant="danger">{fetchErr}</Alert> : null}
    </Container>
  )
}
export { SewageReportv2DownloadReportModal }
