import React from "react"
import { Container, Table, Button, Row } from "react-bootstrap"

const CustomersClipMenuDetailsRender = (props) => {
  return (
    <Container className="mt-2">
      <Row>
        <Button className="ml-auto mb-1" variant="secondary">
          Importuj do clipERP
        </Button>
      </Row>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="3">
              <Row className="justify-content-center">
                Dane klienta z ClipMENU
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Rodzaj</td>
            <td className="fixed-values-table">Nazwa skrócona</td>
            <td className="fixed-values-table">Oddział</td>
          </tr>
          <tr>
            <td>{props.customer.oldType}</td>
            <td>{props.customer.oldShortName}</td>
            <td>{props.customer.branch}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Pełna nazwa
            </td>
            <td className="fixed-values-table">NIP</td>
          </tr>
          <tr>
            <td colSpan="2">{props.customer.oldFullName}</td>
            <td> {props.customer.oldVatNumber} </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Ulica, nr budynku, nr mieszkania
            </td>
            <td className="fixed-values-table">REGON</td>
          </tr>
          <tr>
            <td colSpan="2">{props.customer.oldStreet}</td>
            <td> {props.customer.regon} </td>
          </tr>
          <tr>
            <td className="fixed-values-table">kod pocztowy</td>
            <td className="fixed-values-table" colSpan="2">
              Miasto
            </td>
          </tr>
          <tr>
            <td> {props.customer.oldPostal} </td>
            <td colSpan="2">{props.customer.oldCity}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan="3">Dane korespondencyjne</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Nazwa
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {props.customer.oldCorrName + " " + props.customer.oldCorrName2}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Ulica, nr budynku, nr mieszkania
            </td>
          </tr>
          <tr>
            <td colSpan="3">{props.customer.oldCorrStreet}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">kod pocztowy</td>
            <td className="fixed-values-table" colSpan="2">
              Miasto
            </td>
          </tr>
          <tr>
            <td> {props.customer.oldCorrPostal} </td>
            <td colSpan="2">{props.customer.oldCorrCity}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan="3">Reprezentacja</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nazwa (imię i nazwisko)</td>
            <td className="fixed-values-table">telefon</td>
            <td className="fixed-values-table">fax</td>
          </tr>
          <tr>
            <td>{props.customer.oldRep}</td>
            <td>{props.customer.oldPhone}</td>
            <td>{props.customer.oldFax}</td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="4">Płatności i szczegóły</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Metoda płatności</td>
            <td className="fixed-values-table">Termin płatności</td>
            <td className="fixed-values-table">Płatnik VAT?</td>
            <td className="fixed-values-table">Drukować adres na FV?</td>
          </tr>
          <tr>
            <td>{props.customer.oldDefaultPaymentMethod}</td>
            <td>{props.customer.oldDeafultDeadline}</td>
            <td>
              {props.customer.oldVatPayer === "0" ? "Nie" : null}
              {props.customer.oldVatPayer === "1" ? "Tak" : null}
              {props.customer.oldVatPayer !== "1" &&
              props.customer.oldVatPayer !== "0"
                ? "BŁĄD"
                : null}
            </td>
            <td>
              {props.customer.oldLocOnInvoice === "0" ? "Nie" : null}
              {props.customer.oldLocOnInvoice === "1" ? "Tak" : null}
              {props.customer.oldLocOnInvoice !== "1" &&
              props.customer.oldLocOnInvoice !== "0"
                ? "BŁĄD"
                : null}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">
              Stan{" "}
              <Button
                onClick={() =>
                  props.setModalData({
                    show: true,
                    type: "info",
                    body:
                      '"Archiwum" - klient, który nie miał faktury ani serwisu przez ostatnie dwa lata',
                  })
                }
              >
                ???
              </Button>
            </td>
            <td className="fixed-values-table">Numer klienta w ClipMENU</td>
            <td className="fixed-values-table" colSpan="2">
              identyfikator
            </td>
          </tr>
          <tr>
            <td>{props.customer.state}</td>
            <td>{props.customer.autoinc}</td>
            <td colSpan="2">{props.customer._id}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default CustomersClipMenuDetailsRender
