import { useEffect, useState, useContext } from "react"
import { Container } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"

import { EqpChangeReportForm } from "./Form"
import { EqpChangeReportSummary } from "./Summary"
import { EqpChangeReportTable } from "./Table"
import { addSrvType, createEqpSummary, getDrivers } from "./utils"

const EqpChangeReportFetch = () => {
  const user = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState({ show: false })
  const [filters, setFilters] = useState(null)
  const [data, setData] = useState(null)
  const [cars, setCars] = useState(null)
  const [configs, setConfigs] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const res = await fetch(
          `/reports/eqpChange?startDate=${filters.startDate}&endDate=${filters.endDate}&branch=${filters.branch}&driver=${filters.driver}`,
          { method: "GET" }
        )
        if (res.status !== 200)
          throw new Error(
            `Błąd komunikacji z serwerem: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )

        const resJSON = await res.json()

        // add service type (longTerm/shortTerm) to each job service
        //( used later in filters etc)
        addSrvType(resJSON)

        const summary = createEqpSummary(resJSON)

        // create array of drivers from fetched jobs to use in table filters
        const drivers = getDrivers(resJSON)

        setData({ jobs: resJSON, summary, drivers })
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `${err.message || "Błąd pobierania danych"}`,
        })
      } finally {
        setIsLoading(false)
      }
    }

    const fetchCars = async () => {
      try {
        const res = await fetch(`/cars/getFiltered`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            branches: user.allowedBranches, // don't know location branch yet, so I fetch cars for all user branches
            query: "",
            type: "all",
          }),
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych samochodów: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        return setCars(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }
    const fetchConfigs = async () => {
      try {
        const res = await fetch(`/configs/jobState`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych konfiguracyjnych: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    const fetchAll = async () => {
      await Promise.all([fetchData(), fetchCars(), fetchConfigs()])
    }

    if (filters) fetchAll()
  }, [filters, user])

  const renderContent = () => {
    if (isLoading) return renderSpinner("pobieram dane...")
    if (!data) return null
    return (
      <>
        <EqpChangeReportSummary summary={data.summary} />
        <hr />
        <h4>Zadania:</h4>
        <EqpChangeReportTable
          jobs={data.jobs}
          drivers={data.drivers}
          setModalData={setModalData}
          user={user}
          cars={cars}
          configs={configs}
        />
      </>
    )
  }

  return (
    <Container fluid>
      <EqpChangeReportForm
        isLoading={isLoading}
        setFilters={setFilters}
        user={user}
        setModalData={setModalData}
      />
      {renderContent()}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export { EqpChangeReportFetch }
