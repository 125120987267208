import {
  MdLocalPhone,
  MdTextsms,
  MdAlternateEmail,
  MdAccountBalance,
} from "react-icons/md"
import { IoEllipsisHorizontalSharp, IoDocumentOutline } from "react-icons/io5"

const getActionIcon = (action) => {
  let icon

  switch (action.subtype) {
    case "telefon":
      icon = <MdLocalPhone />
      break
    case "sms":
      icon = <MdTextsms />
      break
    case "mail":
      icon = <MdAlternateEmail />
      break
    case "@ wezwanie KRD":
      icon = <MdAccountBalance />
      break
    case "@ wezwanie":
      icon = <IoDocumentOutline />
      break

    default:
      icon = <IoEllipsisHorizontalSharp />
      break
  }
  return icon
}

export { getActionIcon }
