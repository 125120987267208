const createJobTooltip = (job) => {
  // create basic info:
  let content = `
    <b>Klient:</b><br/>
    ${job.location.customer.shortName}<br/>
    <b>Lokalizacja:</b></br>
    ${job.location.name}<br/>
    ${job.location.street} ${job.location.city}<br/>
    <hr/>
    <b>stan:</b> ${job.state}<br/>
    <b>typ:</b>${job.type}<br/>
    <b>kierowca:</b> ${
      job.hasDriver ? job.driver?.fullName || "BRAK" : "BRAK"
    } <br/>
    <b>samochód:</b> ${job.car?.regNum || "BRAK"} <br/>
    `

  // add conditional details:
  if (job.customHours)
    content = `${content}<b>GODZINY:</b><br/>${job.startTime} - ${job.endTime}<br/>`

  // create services details:
  const services = job.services
    .map((srv) => {
      let srvString = ""
      const serviceDetails = job.location.services.find(
        (locSrv) => locSrv._id === srv.locationServiceRef
      )
      if (!serviceDetails)
        return `ZGŁOŚ BŁĄD: nie znaleziono usługi lokalizacji dla zadania: ${job._id}. usługa ${srv.locationServiceRef}, globalnie: ${srv.serviceRef}`

      srvString = `<b>${serviceDetails.name}</b>`
      if (srv.eqp.length)
        // if any equipment -> add colon ;)
        srvString = `${srvString}:`
      // and list eqipment:
      srv.eqp.forEach(
        (eqp) =>
          (srvString = `${srvString}<br/>
       ${eqp.ref.type} ${eqp.ref.subtype} ${eqp.ref.char || ""} ${
            eqp.ref.color || ""
          } ${eqp.ref.category || ""}
      `)
      )

      return srvString
    })
    .join("<br/>")

  if (!services)
    content = `${content}<br/>ZGŁOŚ BŁĄD: brak usług dla zadania ${job._id}`
  else
    content = `
    ${content}<hr/>
    Usługi:<br/>${services}`

  return content
  // qty - type - subtype
  // if (job.equipment.length !== 0) {
  //   const equipmentArr = job.equipment.map(
  //     (eqp) =>
  //       `${eqp.qty} x ${eqp.ref.name} ${eqp.ref.type} ${
  //         eqp.ref.subtype
  //       } ${eqp.ref.number || "templatka"}<br/>`
  //   )
  //   content = `${content}<b>Sprzęt:</b><br/>${equipmentArr.join(
  //     ""
  //   )}`
  // }
}

/**
 *
 * @param {object} job job object
 * @returns count of equipment for job
 */

const countEqp = (job) => {
  //* for each job service sum its equipment quantity

  const eqpQty = job.services.reduce((eqpCount, jobSrv) => {
    // check if this job service has any equipment:
    if (!jobSrv.eqp?.length) return eqpCount
    // if this job service has equipment -> add it's qty to sum
    else
      return (
        eqpCount +
        jobSrv.eqp.reduce((srvEqpCount, jobSrvEqp) => {
          return srvEqpCount + jobSrvEqp.qty
        }, 0)
      )
  }, 0)

  return eqpQty
}

export { createJobTooltip, countEqp }
