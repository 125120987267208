import React, { Fragment, useContext } from "react"
import { Row } from "react-bootstrap"

import { LogCtx } from "./Fetch"

const RoutesPlanningMapTab = () => {
  const { map, user, branchSelected } = useContext(LogCtx)
  const { mapRef } = map

  return (
    <div>
      <div style={{ height: "500px" }} ref={mapRef} />
      <h4>Legenda:</h4>
      <Row>
        {user.drivers.map((drv) => {
          if (drv.branch[branchSelected])
            return (
              <Fragment key={`mapTabLegendDriverColor-${drv._id}`}>
                <span
                  style={{
                    backgroundColor: drv.markerColor || "black",
                    width: "1rem",
                    height: "1rem",
                  }}
                ></span>{" "}
                - {drv.fullName}
                {"  "}
              </Fragment>
            )
          else return null
        })}
      </Row>
    </div>
  )
}
export default RoutesPlanningMapTab
