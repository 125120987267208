import React, { useState, useContext, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie"

import UserContext from "../contexts/userContext"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"
import { Modal } from "react-bootstrap"

const ConsumptionMainTable = (props) => {
  // eslint-disable-next-line
  const [cookies] = useCookies()
  const user = useContext(UserContext)
  const [showModal, setShowModal] = useState(false)
  // eslint-disable-next-line
  const [errors, setErrors] = useState({})
  // I use separate form for header, to prevent handling all changes in one function
  const [consumptionHeader, setConsumptionHeader] = useState({
    branch: null,
    driver: null,
    year: props.dateToDisplay.year,
    month: props.dateToDisplay.month,
  })

  const [normalGoods, setNormalGoods] = useState(
    props.goods
      .filter((good) => good.type === "normal")
      .map((good) => {
        return { ...good, quantity: 0 }
      })
  )

  //each row is an object with properties of: date, number //of row//, and as many properties as there is goods in props, each of it has name of good
  //below Im creating object with 'goods properties' to use in creating rows
  const goodsRow = props.goods
    .filter((good) => {
      if (good.type === "main") {
        return good
      } else {
        return null
      }
    })
    .reduce(
      (acc, cur) => ({
        ...acc,
        [cur.name]: 0,
      }),
      {}
    )

  const [rows, setRows] = useState([
    {
      number: 0,
      date: 0,
      ...goodsRow,
    },
  ])
  //sum for each good, initializied with zeros:
  const [sums, setSums] = useState(
    props.goods
      .filter((good) => {
        if (good.type === "main") {
          return good
        } else {
          return null
        }
      })
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: 0,
        }),
        {}
      )
  )
  //add row on button click:
  const addRow = useCallback(
    (e) => {
      const newRow = [
        {
          ...goodsRow,
          number: rows.length, //first row is ===0, next wil be ===1 and so on
          date: 0,
        },
      ]

      // const newRowsState = rows.concat(newRow)
      const newRowsState = [...rows, ...newRow]

      setRows(newRowsState)
    },
    [goodsRow, rows]
  )

  // add row with "+" button. Event listener refer to state from the moment it was created. To have actual state in it I need to mount it on every state change
  // Below effect is mounting event listener and cleaning it up after every 'rows' state change.
  // ref: https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
  useEffect(() => {
    const keypressHandle = (e) => {
      // let user use '+' inside additionalInfo
      if (e.target.name === "additionalInfo") return
      // if '+' pressed anywhere else -> add row. preventDefault is mainly to prevent entering '+' character in number inputs
      // when it happens it is impossible to delete it with javascript, as I can't "get to it" as it's not value.
      // For ex. running command e.target.value = "1" after entering '+' in this input ends with '1+' string in it
      // also entering '+' in number input is not firing form change, so I have to handle it here
      else if (e.key === "+") {
        e.preventDefault()
        addRow()
        return
        //prevent submitting on Enter press:
      } else if (e.key === "Enter") e.preventDefault()
      else return
    }
    window.addEventListener("keypress", keypressHandle)
    return () => window.removeEventListener("keypress", keypressHandle)
  }, [rows, addRow])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      return alert("Komponent nieaktualny!")
      // // basic validation:
      // if (consumptionHeader.branch === null) {
      //   setErrors({ branch: true })
      //   return
      // }
      // if (consumptionHeader.driver === null) {
      //   setErrors({ driver: true })
      //   return
      // }

      // if (!user.type.admin && !user.perm.consumption.w) {
      //   alert("brak uprawnień!")
      //   return
      // }
      // //consumption details contains two objects: mainGoodsRows with details (=>each row) and
      // //consumptions sums with sum of main goods rows per good name and normal goods summary consumptions

      // //create main goods object:
      // const mainGoods = props.goods
      //   .filter((good) => good.type === "main")
      //   .map((good) => {
      //     const quantity = rows.reduce((acc, cur) => {
      //       return acc + cur[good.name]
      //     }, 0)

      //     return {
      //       ...good,
      //       quantity: quantity,
      //     }
      //   })
      // //create object to send:
      // const data = {
      //   consumptionHeader: consumptionHeader,
      //   consumptionDetails: {
      //     mainGoodsRows: rows,
      //     consumptionsSums: [...mainGoods, ...normalGoods],
      //   },
      //   additionalInfo: e.target.additionalInfo.value,
      //   user_id: user.id,
      // }

      // const res = await fetch("/consumptions/add/", {
      //   method: "POST",
      //   headers: {
      //     authorization: cookies.jwt,
      //     "content-type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // })

      // if (res.status !== 200) throw res
      // else setShowModal(true)
    } catch (err) {
      const resText = await err.text()
      alert(resText)
    }
  }

  const handleChange = (e) => {
    e.preventDefault()

    //on each event there are different things to do depending where change occured
    //creating normalGoodsNames array to check if change occured in normal goods part:
    const normalGoodsNames = props.goods
      .filter((good) => good.type === "normal")
      .map((good) => good.name)

    if (normalGoodsNames.includes(e.target.name)) {
      const newNormalGoods = normalGoods.map((good) => {
        //well, i'm not 100% sure why I did this...:
        if (good.name === e.target.name) {
          good.quantity = parseFloat(e.target.value)
        }
        return good
      })
      setNormalGoods(newNormalGoods)
      return
    }

    //if change occured in additional info, ignore it - it will be gatherd when handling submit
    if (e.target.name === "additionalInfo") return

    //from now it is known, that change occured in main goods table:
    //target names are addressed like that: [row-number].good-name(or date or row number)
    const targetNameArr = e.target.name.split(".")
    const rowNumber = targetNameArr[0]
    const property = targetNameArr[1]

    const changedValue = Math.round(e.target.value * 100) / 100
    //create row-object with new data from appropriate row from state:
    const newRow = rows[rowNumber]
    //put new value in right place in this row
    newRow[property] = Number.parseFloat(changedValue)
    //get all rows and replace changed with new one. Not sure why I done it, just gonna leave it like this if it's working...
    const newRowsState = rows
    newRowsState[rowNumber] = newRow

    setRows(newRowsState)
    //get sums state:
    const newSums = { ...sums }
    //map through rows and sum them (propably I could and should sum only changed properties in all rows, using above 'property' constans, maybe later...)
    for (const prop in sums) {
      const sum = rows.map((row) => row[prop]).reduce((a, b) => a + b)
      newSums[prop] = sum
    }
    setSums(newSums)
  }

  return (
    <Container fluid>
      <br />
      <Form inline>
        <Row
          style={{
            alignItems: "baseline",
            justifyItems: "center",
          }}
        >
          <Col xs="auto">
            <Form.Label>
              Rok:
              <Form.Control
                type="number"
                defaultValue={props.dateToDisplay.year}
                className="small-number-input"
                onBlur={(e) => {
                  e.preventDefault()

                  setConsumptionHeader({
                    ...consumptionHeader,
                    year: parseInt(e.target.value),
                  })
                }}
              />
            </Form.Label>
          </Col>
          <Col xs="auto">
            <Form.Label>
              Miesiąc:
              <Form.Control
                type="number"
                defaultValue={props.dateToDisplay.month}
                className="small-number-input"
                onBlur={(e) => {
                  e.preventDefault()

                  setConsumptionHeader({
                    ...consumptionHeader,
                    month: parseInt(e.target.value),
                  })
                }}
              />
            </Form.Label>
          </Col>
          <Col xs="auto">
            <Form.Control
              as="select"
              name="branch"
              defaultValue="plug"
              onChange={(e) => {
                e.preventDefault()

                setConsumptionHeader({
                  ...consumptionHeader,
                  branch: e.target.value,
                })
              }}
            >
              <option value="plug" hidden>
                Oddział
              </option>
              {Object.keys(user.branch).map((branch) => {
                if (user.branch[branch])
                  return (
                    <option value={branch} key={branch}>
                      {branch}
                    </option>
                  )
                else return null
              })}
            </Form.Control>
            {errors.branch && <p className="text-danger">wybierz oddział</p>}
          </Col>
          <Col xs="auto">
            {/* Show driver select only when branch is selected */}
            {consumptionHeader.branch === null ? null : (
              <Form.Control
                as="select"
                name="driver"
                defaultValue="plug"
                onChange={(e) => {
                  e.preventDefault()

                  setConsumptionHeader({
                    ...consumptionHeader,
                    driver: e.target.value,
                  })
                }}
              >
                <option value="plug" hidden>
                  Kierowca
                </option>
                {props.employees.map((employee) => {
                  return employee.type.driver &&
                    employee.branch[consumptionHeader.branch] ? (
                    <option value={employee.name} key={employee.name}>
                      {employee.name}
                    </option>
                  ) : null
                })}
              </Form.Control>
            )}
            {errors.driver && <p className="text-danger">wybierz kierowcę</p>}
          </Col>
        </Row>
      </Form>

      {/* Main goods table: */}
      <Form onSubmit={handleSubmit} onChange={handleChange}>
        <Row>
          <Table className="m-2" bordered>
            <thead>
              <tr>
                <th rowSpan="2">
                  Data <br />
                  <Button
                    onClick={addRow}
                    style={{
                      height: "auto",
                      margin: "1px",
                      padding: "5px 10px",
                      fontWeight: "700",
                      letterSpacing: "0.5px",
                    }}
                    type="button"
                    size="sm"
                    variant="secondary"
                  >
                    dodaj wiersz
                  </Button>
                </th>
                {/* create headers from props.goods */}
                {props.goods.map((good) => {
                  if (good.type === "main") {
                    return <th key={`${good.name}-header`}>{good.name}</th>
                  } else {
                    return null
                  }
                })}
              </tr>
              {/* create second header row with good unit */}
              <tr>
                {props.goods.map((good) => {
                  if (good.type === "main") {
                    return <th key={`${good.name}-unit-header`}>{good.unit}</th>
                  } else {
                    return null
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {/* create row: |day|good1|good2|good3|...| based on rows state, when adding row this state gets updated*/}
              {rows.map((row) => {
                return (
                  <tr key={`${row.number}.tr`}>
                    {/* create date input and sets on last input if there are more then one */}
                    <td key={`${row.number}.date-cell`}>
                      <Form.Control
                        type="number"
                        key={`${row.number}.date`}
                        name={`${row.number}.date`}
                        autoFocus={
                          row.number === rows.length - 1 && row.number > 0
                            ? true
                            : false
                        }
                      />
                    </td>
                    {/* create input for every good. Each name is created as "row-number.good-name" which is used to target row state to change*/}

                    {props.goods.map((good) => {
                      if (good.type === "main") {
                        return (
                          <td key={`${row.number}.${good.name}-cell`}>
                            <Form.Control
                              type="number"
                              name={`${row.number}.${good.name}`}
                              key={`${row.number}.${good.name}`}
                              step="any"
                            />
                          </td>
                        )
                      } else {
                        return null
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>Sumy:</td>
                {Object.values(sums).map((sum, i) => (
                  <td key={`sum-cell-${i}`}>{sum}</td>
                ))}
              </tr>
              <tr className="bg-primaryLight">
                <td rowSpan="2">
                  <Button
                    onClick={addRow}
                    style={{
                      height: "auto",
                      margin: "1px",
                      padding: "5px 10px",
                      fontWeight: "700",
                      letterSpacing: "0.5px",
                    }}
                    type="button"
                    size="sm"
                    variant="secondary"
                  >
                    dodaj wiersz
                  </Button>
                </td>
                {/* create headers from props.goods */}
                {props.goods.map((good) => {
                  if (good.type === "main") {
                    return <td key={`${good.name}-header`}>{good.name}</td>
                  } else {
                    return null
                  }
                })}
              </tr>
              {/* create second header row with good unit */}
              <tr className="bg-primaryLight">
                {props.goods.map((good) => {
                  if (good.type === "main") {
                    return <td key={`${good.name}-unit-header`}>{good.unit}</td>
                  } else {
                    return null
                  }
                })}
              </tr>
            </tfoot>
          </Table>
        </Row>
        <Row>
          <Col>
            <Table bordered>
              {/* Table with normal goods consumption */}
              <thead>
                <tr>
                  <th colSpan="3">Inne:</th>
                </tr>
                <tr>
                  <th>Materiał</th>
                  <th>Jednostka</th>
                  <th>Zużycie</th>
                </tr>
              </thead>
              <tbody>
                {normalGoods.map((good, i) => {
                  return (
                    <tr key={`tr-${good}-${i}`}>
                      <td>{good.name}</td>
                      <td>{good.unit}</td>
                      <td>
                        <Form.Control
                          type="number"
                          size="sm"
                          name={good.name}
                          step="any"
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Form.Control
              as="textarea"
              placeholder="Dodatkowe informacje, dane, notatki..."
              cols="80"
              rows="10"
              name="additionalInfo"
              style={{
                gridColumn: "2",
                gridRow: "2",
                // justifySelf: "center",
                // alignSelf: "center",
              }}
            />
          </Col>
        </Row>
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Form>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Body>Zużycie zapisane</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false)
              window.location.reload()
            }}
          >
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
export default ConsumptionMainTable
