import React from "react"
import { Container, Row, Button } from "react-bootstrap"
import { ImNewTab } from "react-icons/im"
import { format } from "date-fns"

import HistoryFetch from "../../_shared/history/fetch"

import { renderTodoEditBtns } from "./utils"

//! This is meant to be both modal body and separate component opened in new tab
// when opened in tab it ALWAYS has to go through TodosDetailsFetch
//TODO add edit btn, when details opened in modal - allow to go back from edit to details, if opened from tab and "wróć" pressed it should close modal

const TodosDetailsRender = ({
  todo,
  setModalData,
  isModal,
  refresh,
  user,
  board,
}) => {
  return (
    <Container fluid>
      <Row className="justify-content-around">
        {isModal ? (
          <>
            <Button
              href={`/Todos/${todo._id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ImNewTab />
            </Button>
          </>
        ) : null}
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "Historia zadania",
              xl: true,
              body: <HistoryFetch entity_id={todo._id} />,
            })
          }
        >
          Historia
        </Button>
        {renderTodoEditBtns(todo, user, setModalData, refresh, board)}
      </Row>
      <p>Przypisany: {todo.employee.fullName}</p>
      <p>Autor: {todo.creator?.fullName}</p>
      <p>Tytuł: {todo.name}</p>
      <p>Opis: {todo.desc}</p>
      <p>Tagi: {todo.tags.join(", ")}</p>
      {todo.isProposition ? null : (
        <>
          <p>Stan: {todo.state}</p>
          <p>Priorytet: {todo.priority}</p>
          <p>Termin: {format(todo.deadline, "yyyy-MM-dd")}</p>
        </>
      )}
      <p>
        Obserwujący:{" "}
        {todo.followers
          .map((follower) => follower?.fullName || "BŁĄD - zgłoś!")
          .join(", ")}
      </p>
    </Container>
  )
}
export default TodosDetailsRender
