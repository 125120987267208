import { format } from "date-fns"
import { useState, useEffect, useCallback, useMemo } from "react"

import {
  Container,
  Row,
  Table,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { BsCloudDownload } from "react-icons/bs"

import renderSpinner from "../../utils/renderSpinner"

/**
filePath - FULL string to add in front of file name (e.g. "employees/1/")
*/

//TODO add sorting (ERP-1079)
//TODO add handling history (ERP-1081, blocked by ERP-322)

const Files = ({
  bucketSuffix,
  filePath,
  entityId, // seems I'm not using this now, but will be useful in the future (to add file info to db)
  goBack,
  prefix,
  isAdmin,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [fetchError, setFetchError] = useState()
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [showDev, setShowDev] = useState(false)

  useEffect(() => {
    const fetchFilesList = async () => {
      try {
        setIsLoading(true)
        const res = await fetch(
          `/misc/s3/getList?bucket=${bucketSuffix}&prefix=${prefix}&showDev=${
            showDev || false
          }`
        )

        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania listy plików: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )

        const resJSON = await res.json()

        setFiles(resJSON)

        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchError(err.message)
        setIsLoading(false)
      }
    }

    fetchFilesList()
  }, [bucketSuffix, entityId, prefix, refreshCounter, showDev])

  const refreshTable = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Container fluid>
      {fetchError ? <Alert variant="danger">{fetchError}</Alert> : null}
      {isAdmin ? (
        <Form.Check
          type="checkbox"
          label="showDev"
          onChange={(e) => {
            setShowDev(e.target.checked)
          }}
          id="showDev-checkbox"
        />
      ) : null}
      {isLoading ? (
        renderSpinner("pobieram listę plików")
      ) : (
        <>
          <UploadFile
            bucketSuffix={bucketSuffix}
            filePath={filePath}
            refreshTable={refreshTable}
            // I put goBack in UploadFile to have one row of buttons:
            goBack={goBack}
          />
          <FilesTable
            files={files}
            bucketSuffix={bucketSuffix}
            refreshTable={refreshTable}
          />
        </>
      )}
    </Container>
  )
}

const FilesTable = ({ files, bucketSuffix, refreshTable }) => {
  const [query, setQuery] = useState("")

  const filterHandler = (file) => {
    if (!query) return true

    const fileName = file.Key.split("/").pop()

    return fileName.toLowerCase().includes(query.toLowerCase())
  }

  return (
    <>
      <Row className="my-2">
        <Col xs={{ span: 4, offset: 8 }}>
          <Form.Control
            placeholder="szukaj"
            as="input"
            type="text"
            className="ml-auto mr-2"
            autoComplete="chrome-off"
            onChange={(e) => setQuery(e.target.value)}
          />
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Nazwa pliku</th>
            <th>Data modyfikacji</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files.filter(filterHandler).map((file) => (
            <FileRow
              file={file}
              bucketSuffix={bucketSuffix}
              key={file.Key}
              refreshTable={refreshTable}
            />
          ))}
        </tbody>
      </Table>
    </>
  )
}

const FileRow = ({ file, bucketSuffix, refreshTable }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [error, setError] = useState(null)
  const [showDeleteConf, setShowDeleteConf] = useState(false)
  const [fileNameInput, setFileNameInput] = useState("")

  const fileName = useMemo(() => file.Key.split("/").pop(), [file])

  const getFileUrl = async (fileKey) => {
    try {
      setIsLoading(true)
      const res = await fetch(
        `/misc/s3/getS3Url?bucket=${bucketSuffix}&key=${fileKey}`
      )

      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania pliku: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      window.open(await res.text(), "_blank", "noopener noreferrer")
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setError(err.message)
      setIsLoading(false)
    }
  }

  const deleteFile = async () => {
    try {
      setIsDeleting(true)
      const res = await fetch(
        `/misc/s3/deleteS3File?bucket=${bucketSuffix}&key=${file.Key}`,
        { method: "DELETE" }
      )
      if (res.status !== 200)
        throw new Error(
          `Błąd usuwania pliku: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      setIsDeleting(false)
      return refreshTable()
    } catch (err) {
      console.log(err)
      setError(err.message)
      setIsDeleting(false)
    }
  }

  const getFileName = (fileKey) => {
    // when from dev folder - add DEV to file name
    if (fileKey.includes("dev/")) return `(DEV!) ${fileName}`
    else return fileName
  }

  const deleteConf = () => {
    if (showDeleteConf)
      return (
        <tr>
          <td colSpan="3">
            <Alert variant="warning">
              <Row>
                <Col xs="4">
                  <p>
                    Aby usunąć plik wpisz jego nazwę (z rozszerzeniem - po
                    kropce) i zatwierdź
                  </p>
                </Col>
                <Col xs="4">
                  <Form.Control
                    as="input"
                    type="text"
                    autoFocus={true}
                    autoComplete="chrome-off"
                    onChange={(e) => {
                      setFileNameInput(e.target.value)
                    }}
                    // prevent pasting in:
                    value={fileNameInput}
                    onPaste={(e) => {
                      e.preventDefault()
                      setFileNameInput("")
                    }}
                    // add 'Enter' key handling:
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        deleteFile()
                      }
                    }}
                  />
                </Col>
                <Col xs="3">
                  <Row>
                    <Button
                      variant="danger"
                      disabled={fileNameInput !== fileName || isDeleting}
                      onClick={() => deleteFile()}
                    >
                      {isDeleting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Usuń"
                      )}
                    </Button>
                    <Button
                      className="ml-auto mr-1"
                      variant="secondary"
                      disabled={isDeleting}
                      onClick={() => {
                        setFileNameInput("")
                        setShowDeleteConf(false)
                      }}
                    >
                      Anuluj
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Alert>
          </td>
        </tr>
      )
    else return null
  }

  return (
    <>
      <tr>
        <td onClick={() => getFileUrl(file.Key)} style={{ cursor: "pointer" }}>
          {isLoading ? <Spinner animation="border" size="sm" /> : null}
          {getFileName(file.Key)}
          <BsCloudDownload className="ml-2" />
        </td>
        <td>{format(new Date(file.LastModified), "yyyy-MM-dd HH:mm")}</td>
        <td>
          <Button
            variant="danger"
            onClick={() => setShowDeleteConf(true)}
            disabled={showDeleteConf}
          >
            Usuń
          </Button>
        </td>
      </tr>
      {deleteConf()}
      {error ? (
        <tr>
          <td>
            <Alert variant="danger">{error}</Alert>
          </td>
        </tr>
      ) : null}
    </>
  )
}

const UploadFile = ({ bucketSuffix, filePath, refreshTable, goBack }) => {
  const [error, setError] = useState(null)
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileExt, setFileExt] = useState(null)
  const [showUploadForm, setShowUploadForm] = useState(false)

  const onDrop = useCallback(
    (files) => {
      // prevent dragging other file when one is choosen:
      if (showUploadForm) return
      if (files.length !== 1)
        return setError(
          <Alert>
            Wybierz <u>jeden</u> plik w formacie pdf, jpg lub png.
          </Alert>
        )
      if (files[0].name.includes("/"))
        return setError(<Alert>Nazwa pliku nie może zawierać znaku "/".</Alert>)
      else {
        setError(false)
        setFile(files[0])
        //extracting filename (it can be changed by user):
        setFileName(files[0].name.slice(0, files[0].name.lastIndexOf(".")))
        //extracting file extension (it remains):
        setFileExt(files[0].name.slice(files[0].name.lastIndexOf(".")))
        setShowUploadForm(true)
      }
    },
    [showUploadForm]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    },
    multiple: false,
  })

  // upload file using FormData api
  const uploadFile = async (e) => {
    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("bucketSuffix", bucketSuffix)
      formData.append("key", `${filePath}${fileName}${fileExt}`)

      const res = await fetch("/misc/s3/uploadFile", {
        body: formData,
        method: "POST",
      })

      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      setFile(null)
      setShowUploadForm(false)

      return refreshTable()
    } catch (err) {
      console.log(err)
      setError(err.message || "nieokreślony błąd programu")
    }
  }

  return (
    <Container>
      <Row>
        <Button onClick={goBack}>Wróć</Button>
        <Button
          // disable btn when any file choosen
          disabled={showUploadForm}
          variant="secondary"
          className="ml-auto mr-1"
          style={{
            background: isDragActive && !showUploadForm ? "#69c07b" : null,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive && !showUploadForm ? "Upuść tutaj!" : "Dodaj"}
        </Button>{" "}
        {error}
      </Row>

      {showUploadForm ? (
        <Row className="my-3">
          <Col xs="auto">nazwa pliku:</Col>
          <Col xs="6">
            <Form.Control
              as="input"
              type="text"
              className=""
              autoComplete="chrome-off"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs="auto">{fileExt}</Col>
          <Col>
            <Row>
              <Button
                variant="warning"
                onClick={() => {
                  setShowUploadForm(false)
                  setFile(null)
                }}
              >
                Anuluj
              </Button>
              <Button
                className="ml-auto"
                variant="secondary"
                onClick={uploadFile}
              >
                Zapisz
              </Button>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Container>
  )
}
export { Files }
