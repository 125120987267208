import React, { useContext, useState } from "react"

import UserContext from "../comp/contexts/userContext"
import { Redirect } from "react-router-dom"
import { Modal, Container, Button } from "react-bootstrap"

import { PermTable, TransactionStatusPerms } from "./tables"
import DetailsEditTemplate from "./DetailsEditTemplate"
const Tech = () => {
  const user = useContext(UserContext)
  const [modal, setModal] = useState(false)

  return (
    <Container>
      {user.type.admin ? null : <Redirect to="/" />}
      <h1>"Panie, kto to panu tak spierdolił?!"</h1>
      <h2>czyli dokumentacja techniczna ClipERP3</h2>
      <em>
        Nauczony doświadczeniem, wolę zostawić trochę wyjaśnień dla biedaka,
        który będzie musiał się opieokwać tym programem - czy to z powodu mojej
        absencji, czy lenistwa
      </em>
      <p>
        Przede wszystkim, weź pod uwagę, że pierwszy element tego programu
        odpaliłem pod koniec lipca 2020, a uczyć się programowania zacząłem w
        styczniu, więc i tak można traktować jak cud, że cokolwiek tu działa...
      </p>
      <p>
        Od wersji 1.8.0 dodaję kolejne wpisy od gory, żeby się nie doktoryzować,
        gdzie co powinno trafić. Czyli raczej forma bloga ;). Postaram się też
        dodawać oznaczenie wersji.
      </p>
      <h2>Ikony (1.14.1)</h2>
      <p>
        Żeby za każdym razem nie szukać jakiej ikony trzeba użyć dodałem post w
        github discussions z gotowymi importami.
      </p>
      <h2>Dodawanie/edycja encji</h2>
      <p>
        od 1.8.0 testuję nową formę rozróżnienia edycji i dodawania nowych
        wpisów. Zamiast korzystać z headera zapytania zawsze rzucam "PUT", a
        backend rozróżnia czy ma dodać nowe czy edytować na podstawie
        body.isNew. Mniej endpointów, mniej wydziwiania na froncie...{" "}
        <b>Przykład: carWorks</b>
      </p>
      <h2>Testy</h2>
      <p>Za to zabrałem się w okolicach wersji 0.6.0... Tak wiem...</p>
      <p>Na chwilę obecną korzystam tylko z cypressa do testów głównie e2e.</p>
      <h3>cypress</h3>
      <p>
        w mongo developerskim zakładam uzytkowników z przedrostkiem 'cy', żeby
        testować uprawnienia. Testowanie submitów rozwiązałem (na razie) przez
        przkazywanie w funkcji ostatecznie fetchującej propsa "id" do modala
        informacyjnego. Po prostu w teście oczekuję, że pojawi się element z
        id="infoModalBody-success", przy czym infoModalDoby jest zaszyte w
        modalu, a jako props przekazuję część po myślniku.
      </p>
      <p>
        <b>Zamknięcie modala informacyjnego</b>: "#infoModalClose"
      </p>
      <h2>Logowanie</h2>
      <p>
        Na wstępnie trzeba wyjaśnić, że mam trochę schizę jeśli chodzi o zmiany
        w danych. W wielu miejscach tworzę historię (np kontakty klienta), do
        tego chcę mieć jak najlepszą informację w loggerze (zapisaną w mongo).
        Nie znalazłem rozwiązania, które by mi odpowiadało (zależy mi na
        informacji, przez który endpoint było obsłużone żądanie i jaki status
        zwróciło + opcjonalnie informacje o res.body), więc trochę
        zagmatwałem...
      </p>

      <p>Są dwa osobne logi:</p>
      <ul>
        <li>
          błędy - zapisywany każdy błąd po stronie backendu, działa to zgodnie z
          zaleceniami expressjs: na końcu api.js stoi middleware do obsługi
          błędów zapisujący do mongo, każda po rzuceniu błędu loguje go na
          konsolę i puszcza next(err).
        </li>
        <li>
          normalny - loguje się każdy request do serwera, dokumenty mongo mają
          ustawiony TTL, np. 2 dni jak dev, 30 dla wszystkich poza POST || PUT
          || DELETE (te 180 dni) itd, szczegóły w kodzie (miscCtrl.js)
          <br />
          update 0.6.1: Tworzę dwa rodzaję logów (rozróżnione kluczem
          loggerVersion).
          <br />
          Pierwszy, podstawowy zapisuje się podczas przechodzenia requestu przez
          middleware 'looger'. Ten sam logger podpina też pod req.log dane,
          które zapisał - od wersji 0.6.1 pisząc endpointa dorzucam tam dane (np
          nazwę endpointa, kod res.status czy informacje o res, np długość
          arraya) i po wysłaniu odpowiedzi zapisuję tego loga.Ustawienia TTL jw.
          (bo podpięte w loggerze)
        </li>
      </ul>
      <h2>Serwowanie i pobieranie plików statycznych</h2>
      <p>
        (kolejny wpis po miesiącach od ostatniego... chyba powinienem to w
        formie bloga prowadzić)
        <hr />Z pobieraniem plików (np. raporty kwartalne dla gmin) mam
        zamieszanie, bo różne rozwiązania przyjmowałem. Generalnie w zmiennych
        środowiskowych na backendzie jest wpis FILES_SRV. W wersji dev to jest
        localhost, w wersji prod adres cliperp.app. Tego stringa podpinam do
        cookiesów ('meta') i wstawiam wszędzie, gdzie jest potrzebne pobieranie.
      </p>
      <p>
        W wersji 1.4.1 w końcu ogarnąłem, że można z expressa wysłać plik przez
        res.downolad() i zamierzam z tego korzystać w przyszłości (planuje
        przeniesienie plików na s3 albo analoga, ale chcę zachować kontrolę,
        więc klient będzie uderzał do API, które będzie pobierać odpowiedni plik
        z S3, wcześniej sprawdzając JWT, a nie bezpośrednio do aws)
      </p>
      <h2>Baza 'configs'</h2>
      <p className="bg-danger">do opisania</p>
      <h2>Autoryzacja</h2>
      <p>
        na początku frontend obsługiwał ciasteczka (pisałem już, że się uczę,
        nie?) i dorzucał je do zapytania, w niektórych miejscach to jeszcze
        mogło tak zostać, sukcesywnie usuwam, obecnie jest JWT w cookies, z
        terminem tygodniowym
      </p>
      <h2>Uprawnienia:</h2>
      <p>
        Kiedyś wymyśliłem coś, co nazwałem "uprawnieniami matrycowymi". Nie
        łudzę się, że nikt wcześniej na to nie wpadł, ale mimo to uważam pomysł
        za swój i dużo bardziej elastyczny od typowych "zestawów uprawnień"
        (kierownik, fakturzystka, logistyk itd)
        <br />
        Chodzi o to, że nadawanie uprawnień użytkownikowi możliwe jest dla
        każdego komponentu w zakresie r-w-e-d (czytaj, twórz, edytuj, usuwaj).
        Dzięki temu mam dużo większą elastyczność, kto dostaje jakie
        uprawnienia.... <br />
        Szczegółowy opis co daje dane uprawnienie dla danego komponentu znajduje
        się w tabeli tebles.js.PermTable - poniżej guzik.
      </p>
      <p>
        Możnaby dodać jeszcze jeden wymiar, tzn. oddziały (np. użytkownik dla
        jednego oddziału ma uprawenienie do komponentu "d" a dla innego tylko
        "r") i myślę o tym, ale na chwilę pisania tego akapitu wymagałoby to
        mnóstwa roboty, więc na razie zostawiam jak jest, tzn{" "}
        <b>
          uprawnienia funkcjonują tak samo w ramach każdego oddziału, do którego
          użytkownik jest przypisany.
        </b>
      </p>
      <Button
        onClick={() => setModal({ show: true, body: <PermTable /> })}
        variant="info"
        className="m-1"
      >
        Opisy poszczególnych uprawnień:
      </Button>

      <h3>Uprawnienia administracyjne</h3>
      <p>
        Wpisywanie wszędzie wyjątku dla .type.admin jest trochę bez sensu, więc
        po prostu przy zapisywaniu użytkownika (lub zmian) jeśli w backendzie
        wykryję .type.admin===true to wszystkie pozostałe uprawnienia też
        wrzucam na true.
      </p>

      <h3>Aktualizacja uprawnień</h3>
      <p>
        Po dodaniu nowego uprawnienia (lub skasowaniu niepotrzebnego) można użyć
        przycisku "Aktualizuj uprawnienia" - przeczesze wszystkich userów
        (generalnie user === employee z .type===user) i usunie doda nowe
        uprawnienie z falsami wszędzie i usunie nieobecne w dokumencie "params"
        w db configs
      </p>
      <h2>Historia encji</h2>
      <p>
        Może to brak zaufania do pracowników, ale prawie od samego początku
        staram się dla wszystkich istotniejszych encji tworzyć wpisy historii, w
        których widać wszystkie wprowadzone zmiany. Początkowo historia była
        zagnieżdżona bezpośrednio w obiekcie, ale to powoduje problemy z
        wydajnością (np przy pobieraniu zadań dla lokalizacji, każde ma swoją
        historię). Dlatego od 1.5.2 zacząłem wdrażać nowy sposób: wpisy historii
        przechowywane są w osobnej bazie, w każdym jest odnośnik do _id
        elementu, którego dotyczy. Generuje to kilka komplikatorów, chociażby
        przy pobieraniu danych muszę dodatkowo odpytać kolekcję historyentries,
        żeby ustalić liczbę wpisów (wykorzystywane potem do sprawdzenia stale
        data edit), czasem będę też musiał podpiąć wpisy <u>creator</u> i{" "}
        <u>creationDate</u>, żeby pokazać je np. na liście ("creationDate" żeby
        uniknąć konfliktu z createdAt z mongoose).
      </p>
      <p>
        <b>
          Pierwszyą encją, dla której w 100% wdrożyłem nową metodę są notatki do
          pozwoleń, wszystkie zależności widać w API (sewageCtrl:
          getPermissionById, editPermNote i createPermNote) i komponencie
          /utils/NotesTable
        </b>
      </p>
      <h2>Lista stanów</h2>
      <h3>Pracownik (employee)</h3>
      <ul>
        <li>aktywny</li>
        <li>archiwum</li>
      </ul>
      <h3>Umowa pracownika (employee.contract)</h3>
      <ul>
        <li>aktywna</li>
        <li>ustalenia</li>
        <li>archiwum</li>
      </ul>

      <h2>ChangeLog</h2>
      <p>
        W związku ze specyfiką pracowników Clippera, trochę musiałem zagmatwać
        kwestię changeloga. <br />
        Założenia:
        <ul>
          <li>pracownik dostaje powiadomienie o nowej wersji</li>
          <li>
            zapoznanie się z changelogiem i potwierdza kliknięciem w odpowiedni
            przycisk
          </li>
          <li>
            user może odłożyć zapoznanie na później - w innej sytuacji (gdyby
            modal wyskakiwał przy każdym renderze) klikałby na odwal się, a nie
            o to chodzi...
          </li>
        </ul>
      </p>
      <p>
        Żeby to obsłużyć wprowadziłem następujące encje:
        <ul>
          <li>
            w bazie danych employee.appVersion - aktualizowane po potwierdzeniu
            zapoznania się ze zmianami
          </li>
          <li>
            ciasteczko 'meta' z aktualną wersją (przy okazji dorzucam info o
            dacie wersji i środowisku)
          </li>
          <li>
            ciasteczko 'lastSeen' - na backendzie przy okazji sprawdzania JWT
            dorzucam Date
          </li>
        </ul>
      </p>
      <p>
        W changelogu wyświetla się guzik - po kliknięciu zmienia wartość
        employee.appVersion na tą z ciasteczka.
      </p>
      <p>
        Działa to tak: jeśli wersja użyszkodnika różni się od aktualnej z
        ciasteczka i lastSeen różni się od obecnej chwili o więcej niż godzinę
        to użytkownik dostaje modala z informacją o nowej wersji. Ma możliwość
        zamknąć i dalej działać - modal odpali się po dwóch godzinach od
        ostatniego requestu.
      </p>
      <h2>Layout</h2>

      <h3>Podgląd i edycja pozycji</h3>

      <Button
        onClick={() => setModal({ show: true, body: <DetailsEditTemplate /> })}
        variant="info"
        className="m-1"
      >
        Templatka
      </Button>
      <h3>Kolorki</h3>
      <table>
        <thead>
          <tr>
            <th>Kolor</th>
            <th>zwykły</th>
            <th>jasny</th>
            <th>ciemny</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>primary</td>
            <td style={{ backgroundColor: "#90a4ae" }}>#90a4ae</td>
            <td style={{ backgroundColor: "#c1d5e0" }}>#c1d5e0</td>
            <td style={{ backgroundColor: "#62757f" }}>#62757f</td>
          </tr>
          <tr>
            <td>secondary</td>
            <td style={{ backgroundColor: "#4dd0e1" }}>#4dd0e1</td>
            <td style={{ backgroundColor: "#88ffff" }}>#88ffff</td>
            <td style={{ backgroundColor: "#009faf" }}>#009faf</td>
          </tr>
          <tr>
            <td>alerts/danger</td>
            <td
              colSpan={3}
              style={{ backgroundColor: "#ef5350" }}
              align="center"
            >
              #ef5350
            </td>
          </tr>
          <tr>
            <td>warning</td>
            <td colSpan={3} className="bg-warning" align="center">
              #ecef52
            </td>
          </tr>
          <tr>
            <td>success</td>
            <td colSpan={3} className="bg-success" align="center">
              #69c07b
            </td>
          </tr>
        </tbody>
      </table>
      <h4>Przyciski:</h4>
      <p>
        <Button>Bez wpływu na dane</Button>
        <br />
        np "wróć" albo wydruk potwierdzenia
        <hr />
        <Button variant="secondary">Zmiana danych</Button>
        <br /> edycje, zapisywanie, generowanie, "naturalna" zmiana statusu itd
        - pożądane zmiany danych
        <hr />
        <Button variant="outline-primary">Przejście do edycji</Button>
        <hr />
        <Button variant="warning">Niedestrukcyjne "usunięcie"</Button>
        <br /> głównie archiwizacja, ale też "wsteczna" zmiana statusu
        <hr />
        <Button variant="danger">Destrukcyjne</Button>
        <br />
        Usuwanie
        <hr />
      </p>
      <h2>Struktura danych</h2>
      <h3>Baza 'configs'</h3>
      <p>
        Dla ułatwienia, dane konfiguracyjne trzymam w bazie configs. Potrzebne
        dokumenty fetchuje podając nazwę w parametrach zapytania (np.
        "/configs/arrConfig,stringConfig"). Backend odsyła obiekt w schemacie{" "}
        {"{arrConfig:[val1,val2,...], stringConfig:value}"}. Szczegóły w kodzie
        configsCtrl...
      </p>
      <h1>Obsługa błędów</h1>
      <h2>React</h2>
      <p>
        Tutaj też dużo zamieszania - z braku doświadczenia i ciągłych zmian
        koncepcji. W momencie pisania tych słów wygląda to nastepująco (przykład
        w transactionsFetch.js):
      </p>
      <ul>
        <li>
          Każdy komponent w którym mogą pojawić się błędy ma dodanego
          bootstrapowego modala i stan modalData
        </li>
        <li>
          modalData=
          <ul>
            <li>show - boolean, steruje wyświetlaniem modala</li>
            <li>header</li>
            <li>body</li>
            <li>
              type - dopiero planuję dodać, będzie sterować typem modala
              (ostrzeżenie/informacyjny/wybór)
            </li>
          </ul>
        </li>
      </ul>
      <p>
        W funkcjach z fetchem działa to tak: sprawdzam status odpowiedzi, jeśli
        !== 200 to próbuję zalogować na konsoli res.text() a rzucam res. W
        części 'catch' loguję na konsolę cały błąd (w razie gdyby nie był
        rzucony przeze mnie) i ustawiam modalDate=
        {`{header:"błąd pobierania", show:true}`} (bez body)
      </p>
      <p>
        W innych sytuacjach zależnie od kontekstu, jeśli dla danej sytuacji
        używam Router.Redirect albo window.location.reload() to dodaję warunek,
        który zapobiegnie odpaleniu redirecta przed zamknięciem modala, coby się
        użyszkodnik mógł na spokojnie zapoznać.
      </p>
      <p>
        Tak czy inaczej zawsze dodaję jakieś logowanie błędu na konsoli (poza
        oczywistymi sytuacjami jak brak uprawnień)
      </p>
      <h1>Integracja ze starymi bazami</h1>
      <p>
        Do wgrywania danych z baz paradoxa używam programu clipreader - osobne
        repo. To jest nałatane (łącznie z modyfikacją pakietu do czytania baz),
        ale jakoś idzie się połapać.
      </p>
      <p>
        W przypadku klientów integracja jest jednostronna, można utworzyć nowego
        klienta z klienta clipMENU, ale to już jest osobna encja
      </p>
      <h1>Transakcje</h1>
      <p>
        W ramach eksperymentów, moduł do przeglądania transakcji napisałem bez
        sensu. Tzn po wyborze z listy dane transakcji przerzucane są przez
        propsy, żeby uniknąć kolejnego fetcha (tak na serio to jest powód -
        jednak płynniej to działa). Jednocześnie chciałem w mailach wysyłać
        linka bezpośrednio do transakcji, więc dopisałem komponent
        TransactionDetailsFetchSingle, który pobiera konkretną transakcję na
        podstawie id i wyświetla moduł szczegółów puszczając jej dane przez
        propsa.
      </p>
      <h3>Statusy transakcji</h3>
      <Button
        onClick={() =>
          setModal({ show: true, body: <TransactionStatusPerms /> })
        }
        variant="info"
        className="m-1"
      >
        Tabela
      </Button>

      <p>
        Wysyłanie powiadomień następuje przy dodaniu transakcji, jej edycji i
        anulowaniu, mail zawsze do zarządu (Basia, Łukasz, ja), usera i osoby
        fakturującej w oddziale (dla transakcji Clippera):
      </p>
      <h2>Magazyny</h2>
      <h3>Dodawanie i edycja transakcji</h3>
      <p>
        Dodawanie i obsług zużycia materiałów jest mocno dopasowana do firmowych
        procedur. Formularz ma umożliwiać łatwe wprowadzanie danych na podstawie
        funkcjonujących w firmie druków. Zamysł jest taki, żeby pracownik raz na
        kilka dni (maks miesiąc)siadał i posortowane kierowcami karty DZIENNEGO
        zużycia wprowadzał w jeden formularz. Stąd podział na dwie kategorie
        materiałów - główne i normalne. Główne (częściej zużywane przez
        kierowców) to te wprowadzane w górnej tabeli, która daje możliwość
        wprowadzania zużycia z rozbiciem na poszczególne dni. Normalne są
        wprowadzane per zużycie - przy wprowadzaniu kilku kart dziennych user
        musi to na boku zsumować.
      </p>
      <p>
        <b>Walidacja</b> - bardzo podstawowa, żeby zachować elastyczność. User
        może chcieć dodać tylko zużycie normalnego, tylko głównego albo tylko
        uwagi - pełna dowolność, nawet puste zużycie nic nie psuje. Wymagam
        tylko wybrania oddziału i kierowcy
        <br />
        <b>Zapisywanie danych</b> działa na zasadzie snapshotu materiałów (w tym
        wszystkich ich parametrów) aktywnych w momencie wprowadzania zużycia.
        Dlatego przy późniejszej edycji nie ma możliwości dodania materiału,
        którego wcześniej nie było, można operować tylko na danych z momentu
        zapisania zużycia.
        <br />
        <b>Tworzenie raportu</b> - co robi rapot jest wpisane w instrukcji dla
        usera. Ważne jest, że w związku z powyższą metodą snapshotu, musi być
        zachowana niezmienność takich parametrów jak cena i przelicznik (Hugon
        wie, czemu nazwałem to "divider", skoro się mnoży...). A to dlatego, że
        do obliczeń wartości w tabeli brane są dane z pierwszego wystąpienia
        danego materiału. <br /> Czyli jeśli do raportu wpadnie pięć zużyć, w
        pierwszym divider dla danego materiału === 5 to potem już nie sprawdzam
        czy jest taki sam dla pozostałych (zużycia poszczególnych materiałów są
        sumowane po _id nie nazwie, choć i to przerobiłem - facepalm.png).
        Wolałem zblokować edycję materiałów, niż się kopać z obsługą spójności,
        sorry za lenistwo :P.
        <br />
        <b>Generalne zasada przy edycji materiałów</b> brzmi więc: jeśli jest
        zmiana w cenie lub przeliczniku to dodajemy nowy materiał, choćby z taką
        samą nazwą, po prostu będą dwie pozycje w tabeli do ręcznego zsumowania.
      </p>
      <h2>Sewage report - kwartalne sprawozdanie oddanych nieczystości</h2>
      <p>
        kwestii ideologicznych i prawnych tego sprawozdania nie będę tłumaczył,
        jest to poopisywane na .gov, nasi pracownicy też coś tam wiedzą
        <br />
        Struktura i załączniki - też wiedza ogólnodostępna
        <br /> Istotne są dwie rzeczy:
        <br />
        - sprawozdania ustawodawca wprowadził z myślą o szambiarkach, gdzie
        można dosyć dokładnie (co do m3) ustalić ilość odebranego ścieku
        <br />
        - nikt tego nie sprawdza
        <br />
        w przypadku kibli nie da się ustalić ile ścieku z której gminy trafiło
        do której zlewni. Samochód robi trasę przez kilka gmin, w każdej jednej
        toalecie jest locowo pomiędzy 20 a 200 litrów ścieku. Możnaby instalować
        liczniki, ale są one piekielnie drogie i mało trwałe, a przecież i tak
        nikt tego nie czyta... <br />
        Dlatego do sprawozdania pytam użytkownika o ogólną ilość ścieku z danego
        kwartału (w przyszłości może wprowadzę per zlewnia, ale na razie to
        wystarczy), i liczę średnią dzieląc to przez sumaryczną liczbę
        obsłużonych toalet (z bazy, logika w kodzie). Do każdego sprawozdania
        wpisuję tą średnią pomnożoną przez liczbę toalet obsłużonych na terenie
        danej gminy.
      </p>
      <h2>Logika klient-umowa-lokalizacja-(serwis)-faktura</h2>
      <p>
        Generalnie przyjąłem, że każda z wymienionych w tytule encji jest
        przechowywana w osobnej bazie. Relacja jest (prawie) zawsze 1:n, czyli
        jeden klient zawiera dowolnie wiele umów, każdą z nich na dowolnie wiele
        lokalizacji, na każdej z nich wykonujemy dowolnie wiele serwisów i
        wystawiamy dowolnie wiele faktur.
      </p>
      <p>
        Wyjątki: umowa może mieć przypisanych kilku klientów - patrz niżej
        (Kartoteka klientów - wystawianie faktur) i jedna faktura może "zbierać"
        wiele serwisów.
      </p>
      <h2>Kartoteka klientów</h2>
      <h3>(chora) Logika wystawiania faktur</h3>
      <p>Na fakturze widnieją, lub moga widnieć:</p>
      <ul>
        <li>Sprzedawca - musi być</li>
        <li>
          Nabywca - musi być, nie musi korzystać z zakupu, ani za niego płacić,
          ot zawiłości prawa podatkowego. W 95% nabywca jest również płatnikiem
          i korzystającym
        </li>
        <li>
          Płatnik - podmiot płacący za fakturę, pole nieobowiązkowe podatkowo,
          ale klienci często wymagają takiego rozdziału
        </li>
        <li>
          Korzystający - jw. aczkolwiek zazwyczaj jest albo-albo (faktura z
          nabywcą i płatnikiem albo faktura z nabywcą i korzystającym)
        </li>
      </ul>
      <p>
        W związku z pozyższym "uproszczeniem" zafundowanym przez przepisy
        przyjąłem następującą logikę:
      </p>
      <ol>
        <li>faktura wystawiana jest na podstawie umowy</li>
        <li>przy dodawaniu umowy trzeba wskazać/dodać nabywcę</li>
        <li>
          przy dodawaniu umowy <u>można</u> wskazać korzystającego i/lub
          płatnika
        </li>
      </ol>
      <p>
        Każdy ze wskazanych podmiotów musi istnieć w bazie klientów, albo jest
        dodawany. Powiązania między kontrahentami przechowuję u każdego z nich w
        atrybucie bindings - szczegóły w kodzie (customersModel.js)
      </p>

      <h3>Technikalia kartoteki klientów</h3>
      <p>
        Kartoteka jest przewidziana do użycia jako osobny moduł oraz jako
        zagnieżdżony (w scenariuszu: dodaję transakcję/lokalizację, klikam dodaj
        klienta, otwiera się modal z szukajką, po wyszukaniu i kliknięciu
        odpowiedniego wiersza zwracane są dane klienta)
      </p>
      <p>
        Żeby użyć zagnieżdżonego komponentu trzeba wywołać (najlepiej w modalu){" "}
        {"<CustomerFetch />"} z następującymi propsami:
      </p>
      <ul>
        <li>embedded=true</li>

        <li>
          setCustomer - funkcja, która jako arg otrzyma dane klienta do dalszych
          działań1
        </li>
      </ul>
      <h2>Sprzęt (equipment)</h2>
      <p>
        Są dwa typy sprzętu, oba trzymam w bazie equipment, a rozróżniam po
        isTemplate.
        <br />
        na szybko (wyjaśnienie czemu tak a nie inaczej - poniżej):
      </p>
      <ul>
        <li>
          isTemplate === true - to templatka/wzorzec. Może być przypisywana do
          dowolnej liczby lokalizacji
        </li>
        <li>
          isTemplate === false - rzeczywisty sprzęt - może być przypisywany w
          danej chwili tylko do jednej lokalizacji
        </li>
      </ul>
      <p>
        Templatki obsługują dwie sytuacje. Raz, że w momencie dodawania
        lokalizacji user nie wie, który konkretnie egzemplarz sprzętu pojedzie
        na lokalizację (kibel Shrodingera?). Dwa, że może to być sprzęt
        nie-nasz, albo niepodlegający szczegółowej inwentaryzacji (odpowiednio:
        kontener podnajmowany od innej firmy, albo nasz kosz na śmieci).
      </p>
      <p>
        Dlatego używam templatek, czyli wzorców/szablonów sprzętu. Jesli chodzi
        o nasz sprzęt podlegający ewidencji w domyśle zostanie ona zamieniona na
        faktyczny/rzeczywisty sprzęt gdy dostaniemy info o konkretnym numerze od
        pracownika dostarczającego. W przypadku sprzętów niepodlegających
        ewidencji (w tym podnajmowanych) templatka zostaje przez cały czas
        wynajmu
      </p>
      <h3>Struktura Lokalizacja - usługa - sprzęt - zadanie</h3>
      <p>
        Pod lokalizację podpinam usługi (tworzę snapshot, nie referencję) i
        zadania. Sprzęt jest podpinany pod usługi w formie referencji do
        globalnej kolekcji
      </p>
      <p>Aaaale:</p>

      <h3>Zadania</h3>
      <p>Zadanie ma arcyważną flagę isFrozen. O co cho:</p>
      <p>
        Zamysł jest taki, że dopóki zadanie jest zaplanowane/zlecone to
        interesuje mnie obecny stan usługi (z lokalizacji) i sprzętu (globalny).
        Przy zmianie.odhaczaniu zadania chcę zachować info o usługach i sprzęcie
        z momentu odhaczania. Dlatego:
      </p>
      <p>
        Przy dodawaniu zadania trzymam w nim referencje do usług LOKALIZACJI
        (czyli tego snapshota, nie do kolekcji services). Pod każdą z tych usług
        trzymam sprzęt w postaci referencji do GLOBALNEJ KOLEKCJI equipment i
        ilość sprzętu, którego dotyczy dane zadanie (qty)
      </p>
      <p>
        W momencie przechodzenia zadania do stanu innego niż zaplanowane i
        zlecone zamrażam stan usług i sprzętu - robię snapshota i wrzucam go do
        frozenSrv i zmieniam flagę isFrozen
      </p>

      <h2>Komponent Planowanie (RoutesPlanning)</h2>
      <p>
        Tu jest dużo burdelu dotyczącego map, pewnie wiele wynika z tego, że
        czegoś nie zrozumiałem. Ale tak czy inaczej wymaga to wyjaśnienia...
      </p>
      <p>
        Mam trzy refy dotyczące map. mapRef - tu jest div z mapą, map - sam
        obiekt mapy (new window.google.maps.Map). Tu trochę nie kumam o co
        chodzi, bo jak używałem zwykłej zmiennej i potem w useEffect tworzyłem
        mapę z window.google.maps i przypisywałem ją do tej zmiennej to mi
        eslint krzyczał, że muszę to ogarnąć w refie. Więc ogarnąłem...
        <br />
        Trzeci ref powstał później jak walczyłem z map.fitBounds(), żeby
        wyświetlać mapę dopasowaną do wyświetlonych markerów. Niestety jeśli
        mapa aktualnie nie była wyświetlana (display:none) to googiel uznawał,
        że div ma rozmiar 0, więc jak potem się przełączyło kartę
        (display:block) to pokazywało całą Europę.
        <br /> (i teraz będzie śmiesznie) żeby to ogarnąć użyłem useEffect z
        depArr = [isMapLoading (zmieniane w funkcji inicjalizującej mapę i
        wrzucającej ją w map.current), tabSelected (no chyba wiadomo?)]
      </p>
      <p>
        Poza tym efektem są jeszcze inne, pierwszy odpala fetche na domyślnych
        parametrach i po ich zmianie (albo przy wymuszeniu refresha), drugi
        inicjalizuje mapę, tzn jeśli nie ma tagu script to go wrzuca w DOM i
        odpala funkcję inicjalizującą o której wyżej (przy użyciu eventListnera
        "load"), a jeśli jest (czyli pewnie były świeże dane pobrane z api) to
        osobno odpala funkcję tworzenia mapy.
      </p>
      <h2>Odhaczanie zadań (RoutesSettling)</h2>
      <p>
        Ważne info: w przypadku dostarczeń/zabrań i oznaczeniu zadań jako
        wykonane uruchamiana jest dodatkowa funkcja submitEqpChangeJobs, która
        uderza do osobnego endpointa, który to odpowiada tylko za wprowadzenie
        odpowiednich zmian w usługach na lokalizacji (zmiana stanu jest w
        standardowym procesie)
      </p>
      <h2>Fakturowanie</h2>
      <p>
        Na chwilę obecną dane klienta i usług używane przy wystawianiu faktury
        są wstawiane podczase eksportu (w invExporter). To, że przy generowaniu
        faktury też je wstawiam nie ma znaczenia. Wynika to z mojego zamotania i
        sztywności webAPI od Symfonii
      </p>

      <Modal
        show={modal.show}
        onHide={() => setModal({ show: false })}
        animation={false}
        dialogClassName=" modal-max-width"
        style={{ width: "90%", marginLeft: "5%" }}
      >
        {/* <Modal.Header>Jej, modal....</Modal.Header> */}
        <Modal.Body>{modal.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal({ show: false })}>Zamknij</Button>{" "}
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
export default Tech
