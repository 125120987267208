import _ from "lodash"
const basicMarkerSvg =
  "M -40 -40 L 40 -40 L 40 40 L -40 40 Z M 0 120 Q 15 75 50 45 Q 75 0 50 -50 Q 0 -93 -50 -50 Q -75 0 -50 45 Q -15 75 0 120 Z"

const multipleMarkerSvg = `M 0 120 Q 15 75 50 45 Q 75 0 50 -50 Q 0 -93 -50 -50 Q -75 0 -50 45 Q -15 75 0 120 Z`
const colorsArr = [
  "red",
  "blue",
  "green",
  "yellow",
  "violet",
  "grey",
  "orange",
  "brown",
  "pink",
  "black",
  "white",
]

const createMarker = (services) => {
  // create array of uniq icons paths from all jobs services:
  const iconPaths = _.uniq(services.map((srv) => srv.serviceRef.iconPath))

  if (iconPaths.length === 1)
    return `${services[0].serviceRef.iconPath} M 0 120 Q 15 75 50 45 Q 75 0 50 -50 Q 0 -93 -50 -50 Q -75 0 -50 45 Q -15 75 0 120 Z`
  else if (iconPaths.length > 1)
    return "M 0 -40 Q 40 -20 40 30 Q 0 50 -40 30 Q -40 -20 0 -40 Z M 0 120 Q 15 75 50 45 Q 75 0 50 -50 Q 0 -93 -50 -50 Q -75 0 -50 45 Q -15 75 0 120 Z"
  else
    return "M 0 -40 L 30 40 L -40 -10 L 40 -10 L -30 40 Z M 0 120 Q 15 75 50 45 Q 75 0 50 -50 Q 0 -93 -50 -50 Q -75 0 -50 45 Q -15 75 0 120 Z"
}

export { basicMarkerSvg, colorsArr, multipleMarkerSvg, createMarker }
