import React, { useContext, useRef, useState } from "react"
import UserContext from "../../contexts/userContext"
import { Redirect, Link } from "react-router-dom"

import ReactToPrint from "react-to-print"

import {
  Table,
  Container,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap"

import { AiOutlinePrinter } from "react-icons/ai"

import {
  PrivatDetailsTable,
  CompanyDetailsTable,
  DeliveryDetailsTable,
  TransactionHeadersTable,
  OrderDetailsTable,
  TransactionInfoTable,
  PaymentDetailsTable,
} from "./Tables"

import TransactionDetailsDeliveryConfirmation from "./DeliveryConfirmation"
import { handleStateChange } from "../utils"
import MyModal from "../../utils/Modals"

const TransactionDetailsRender = (props) => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [redirect] = useState()

  //used for state flow (button rendering and state update)
  const [nextState] = useState(() => {
    const state = props.transaction.state
    if (props.transaction.paymentDetails.proforma && state === "oczekująca")
      return "zaproformowana"
    else if (state === "oczekująca") return "zafakturowana"
    else if (state === "zaproformowana") return "zafakturowana"
    else return false
  })

  const [canEdit] = useState(() => {
    // "normal" edit - when user has perm and transaction state allows:
    if (
      ((user.perm.transaction.e && user.name === props.transaction.userName) ||
        user.perm.transactionsList.e) &&
      (props.transaction.state === "oczekująca" ||
        props.transaction.state === "zaproformowana")
    )
      return true
    // edit for "super users" - no matter what state
    else if (user.perm.transactionsList.d) return true
  })
  // const [showConfirmation, setShowConfirmation] = useState(false)

  const componentRef = useRef()

  const grossValue = props.transaction.orderDetails.prices
    .map((price) => price.grossPrice * price.quantity)
    .reduce((a, b) => a + b)

  //setting seller data to pass it into delivery confirmation
  const sellerData =
    props.transaction.seller === "Clipper"
      ? {
          name: "Clipper",
          street: "Pana Tadeusza 6B",
          city: "Kraków",
          vatNumber: "1130013397",
          postal: "30727",
        }
      : {
          name: "Ecoloxx",
          street: "Pana Tadeusza 6B",
          city: "Kraków",
          vatNumber: "6793186208",
          postal: "30727",
        }

  const renderTransactionDetails = () => {
    return (
      <Container>
        {redirect ? <Redirect to={`/transaction/${redirect}`} /> : null}
        <Row>
          <p style={{ color: "white" }}>{props.transaction._id}</p>
          <TransactionHeadersTable transaction={props.transaction} />
        </Row>
        <Row className="justify-content-between">
          <Col>
            <Button onClick={() => props.backToTable()}>Wróć</Button>
          </Col>
          <Col>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <AiOutlinePrinter />
                  potwierdzenie
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={new Date().toString()}
            />
          </Col>

          {/* NEXT STATE button */}
          {user.perm.transactionState.w && nextState ? (
            <Col>
              <Row>
                <p className="mt-1 mb-0">Zmień na: </p>
                <Button
                  id="nextStateBtn"
                  variant="secondary"
                  onClick={() =>
                    handleStateChange(
                      props.transaction._id,
                      nextState,
                      props.setModalData,
                      () => window.location.reload()
                    )
                  }
                >
                  {nextState}
                </Button>
              </Row>
            </Col>
          ) : null}

          {/* EDIT button */}
          {canEdit ? (
            <Col>
              <Link
                to={{
                  pathname: `/transactionEdit/${props.transaction._id}`,
                }}
              >
                <Button variant="outline-primary">Edytuj</Button>
              </Link>
            </Col>
          ) : null}

          {/* STATE change */}

          {/* managers can change state back to oczekująca from zaproformowana. '!user.perm.transactionState.d' is to hide this dropdown from superuser */}
          {user.perm.transactionState.e &&
            !user.perm.transactionState.d &&
            props.transaction.state === "zaproformowana" && (
              <Col>
                <DropdownButton title="Zmień stan" variant="warning">
                  {["oczekująca"].map((state) => (
                    <Dropdown.Item
                      variant="primary"
                      key={`state-chenge-${state}`}
                      onClick={() =>
                        handleStateChange(
                          props.transaction._id,
                          state,
                          props.setModalData,
                          () => {
                            window.location.reload()
                          }
                        )
                      }
                    >
                      {state}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            )}

          {/* super users can change to any state */}
          {user.perm.transactionState.d ? (
            <Col>
              <DropdownButton title="Zmień stan" variant="warning">
                {[
                  "oczekująca",
                  "zaproformowana",
                  "zafakturowana",
                  "anulowana",
                ].map((state) => (
                  <Dropdown.Item
                    variant="primary"
                    key={`state-chenge-${state}`}
                    onClick={() =>
                      handleStateChange(
                        props.transaction._id,
                        state,
                        props.setModalData,
                        () => {
                          window.location.reload()
                        }
                      )
                    }
                  >
                    {state}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          ) : null}

          {/* UNLOCK button - only for users without transactionState.e */}
          {!(
            props.transaction.state === "oczekująca" ||
            props.transaction.state === "zaproformowana"
          ) && !user.perm.transactionState.e ? (
            <Button
              variant="secondary"
              onClick={() => props.showUnlockRequestForm()}
            >
              Poproś o odblokowanie
            </Button>
          ) : null}
        </Row>
        <Row>
          <Col xs="12">
            <OrderDetailsTable
              transaction={props.transaction}
              grossValue={grossValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            {props.transaction.customerType === "company" ? (
              <CompanyDetailsTable transaction={props.transaction} />
            ) : null}
            {props.transaction.customerType === "privat" ? (
              <PrivatDetailsTable transaction={props.transaction} />
            ) : null}
          </Col>
          <Col xs="4">
            <TransactionInfoTable transaction={props.transaction} />
          </Col>
          <Col xs="4">
            {/* 2020-08-24 I added payment details to transaction and needed to change details layout */}
            {/* additionaly in TransactionInfoTable there is condition to decide what varsion of table to display */}
            {props.transaction.paymentDetails ? (
              <PaymentDetailsTable transaction={props.transaction} />
            ) : (
              <Table bordered>
                <thead className="bg-secondarySuperLight">
                  <tr>
                    <th>Stary typ transakcji</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      W nowych transakcjach (z dodatkowymi szczegółami nt.
                      płatności) tu pojawi się tabela z danymi
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <DeliveryDetailsTable transaction={props.transaction} />
          </Col>
        </Row>
        <MyModal modalData={modalData} setModalData={setModalData} />
        {/* <Modal
          show={modalData.show}
          onHide={() => setModalData({ show: false })}
          animation={false}
        >
          <Modal.Header className="bg-danger">
            {modalData.type === "delete" ? "Usuwanie transakcji?" : "Błąd"}
          </Modal.Header>
          <Modal.Body>{modalData.msg}</Modal.Body>
          <Modal.Footer>
            {modalData.type === "delete" ? (
              <Row className="justify-content-between">
                <Col>
                  <Button
                    onClick={() => setModalData({ show: false })}
                    autoFocus
                  >
                    Anuluj
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button onClick={() => setModalData({ show: false })}>
                Zamknij
              </Button>
            )}
          </Modal.Footer>
        </Modal> */}

        <div style={{ display: "none" }}>
          <TransactionDetailsDeliveryConfirmation
            ref={componentRef}
            transaction={props.transaction}
            sellerData={sellerData}
            grossValue={grossValue}
          />
        </div>
      </Container>
    )
  }
  return renderTransactionDetails()
}
export default TransactionDetailsRender
