import React, { useState, useEffect, useContext } from "react"
import { Redirect } from "react-router-dom"

import { Form } from "react-bootstrap"

import UserContext from "../../contexts/userContext"

import MyModal from "../../utils/Modals"

import TransactionDetailsRender from "./Render"

const TransactionDetailsFetch = (props) => {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState(false)
  const [transaction, setTransaction] = useState()
  const [showTable, setShowTable] = useState(false)
  const [unlockRequestMsg, setUnlockRequestMsg] = useState()

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const res = await fetch(`/transactions/${props.match.params.id}`)
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })

        setTransaction(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          type: "alert",
          body: "Błąd pobierania danych",
          show: true,
        })
      }
    }
    fetchTransaction()
  }, [user.perm, props.match.params.id])

  const showUnlockRequestForm = async () => {
    setModalData({
      show: true,
      type: "form",
      body: (
        <Form.Control
          as="textarea"
          rows="5"
          name="msg"
          autoFocus
          onChange={(e) => {
            e.preventDefault()
            setUnlockRequestMsg(e.target.value)
          }}
        />
      ),
    })
  }

  const handleUnlockRequest = async () => {
    try {
      const res = await fetch(
        `/transactions/unlockRequest/${transaction._id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ msg: unlockRequestMsg }),
        }
      )
      if (res.status !== 200) throw res
      setModalData({
        show: true,
        type: "info",
        body: "Prośba o odblokowanie wysłana",
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: "Błąd wysyłki" })
    }
  }

  return (
    <div>
      {isLoading ? null : (
        <TransactionDetailsRender
          transaction={transaction}
          backToTable={() => setShowTable(true)}
          setModalData={setModalData}
          showUnlockRequestForm={showUnlockRequestForm}
        />
      )}
      {showTable ? <Redirect to="/transactions" /> : null}
      {/* <Modal
        show={modalData?.show}
        onHide={() => setModalData(false)}
        animation={false}
      >
        <Modal.Header className="bg-danger">{modalData.header}</Modal.Header>
        <Modal.Body>{modalData.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalData(false)}>Zamknij</Button>
        </Modal.Footer>
      </Modal> */}

      <MyModal
        modalData={modalData}
        setModalData={setModalData}
        handleSubmit={handleUnlockRequest}
      />
    </div>
  )
}
export default TransactionDetailsFetch
