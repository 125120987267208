const generalSubmit = async (
  data,
  customer_id,
  setModalData,
  setIsFetching,
  refresh,
  setAlert
) => {
  try {
    setIsFetching(true)
    const isNew = customer_id === -1 ? true : false

    const res = await fetch(
      `/customers/${isNew ? "add" : `updateCustomer/${customer_id}`}`,
      {
        method: isNew ? "POST" : "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    )
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "info",
        body: "Brak uprawnień",
        headerColor: "warning",
      })

    // when not 200 -> check if res contains json
    if (
      res.status !== 200 &&
      res.headers.get("content-type").includes("application/json")
    ) {
      // parse response:
      const resJSON = await res.json()
      // if response contains customer_id (so there is error from duplicating nip number) -> show alert with link to existing customer details
      if (resJSON.customer_id)
        return setAlert(
          <p>
            Klient o podanym numerze NIP już istnieje.
            <a
              href={`/CustomersDetails/${resJSON.customer_id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "black" }}
              className="bg-light"
            >
              Podgląd
            </a>
          </p>
        )
      // else just show msg:
      else
        return setAlert(
          `Błąd: ${res.status} - ${
            resJSON.msg || "błąd komunikacji z serwerem"
          }`
        )
    } else if (res.status !== 200)
      throw new Error(
        `${res.status} - ${(await res.text()) || "błąd komunikacji z serwerem"}`
      )
    if (isNew) {
      const resJSON = await res.json()
      setModalData({ show: false })

      // prevent opening new tab when testing:
      if (!resJSON.cypress) {
        window.open(
          `/CustomersDetails/${resJSON.customer_id}`,
          "Customer Details",
          "noopener noreferrer"
        )
      }
      return window.location.reload()
    }
    setModalData({
      show: true,
      type: "info",
      header: "Sukces",
      body: "Dane zapisane",
      headerColor: "success",
      onHide: () => {
        setModalData({ show: false })
        refresh()
      },
    })
  } catch (err) {
    console.log(err)
    setIsFetching(false)
    // use alert to prevent closing modal
    return alert(`Błąd: ${err.message || "nieokreślony błąd programu"}`)
  }
}

export { generalSubmit }
