import React, { useState, useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import { Redirect } from "react-router-dom"

import UserContext from "../../contexts/userContext"

import MyModal from "../../utils/Modals"

import TransactionsEditRender from "./Render"

import { roundToHundredth } from "../../utils/formats"

import { handleStateChange } from "../utils"

const TransactionsEditFetch = (props) => {
  const [cookies] = useCookies()
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState(false)
  const [transaction, setTransaction] = useState()
  const [showTable, setShowTable] = useState(false)
  const [prices, setPrices] = useState()
  const [configs, setConfigs] = useState()
  const [redirectTo, setRedirectTo] = useState(false)

  const userBranches = Object.keys(user.branch).filter(
    (branch) => user.branch[branch]
  )

  useEffect(() => {
    //*when adding new transaction - fetch only actual prices:
    const fetchPrices = async () => {
      try {
        const res = await fetch("/prices/all", {
          method: "GET",
        })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setPrices(resJSON.sort((a, b) => a.priority - b.priority))
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "błąd pobierania danych",
        })
      }
    }

    //*when editing transaction - its a bit more complicated:

    // idea is to present form containing all actual prices and prices which are not longer in DB, but were used in transactions
    // if price was used, it should have value === past value (it means, value from moment of creating/last edit)

    // first i had to fetch transaction and prices in one func, hence Im working with both:
    const fetchTransactionAndPrices = async () => {
      try {
        if (!user.perm.transaction.r && !user.perm.transactionsList.r)
          throw new Error("Brak uprawnień")

        const prices = await fetch("/prices/all", {
          method: "GET",
        })
        const pricesJSON = await prices.json()

        // setPrices(pricesJSON.sort((a, b) => a.priority - b.priority))

        const transaction = await fetch(
          `/transactions/${props.match.params.id}`
        )
        if (transaction.status !== 200) {
          const resText = await transaction.text()

          throw new Error(resText)
        }

        const transactionJSON = await transaction.json()

        // first I create array of actual prices, but I have to find if it is present in transaction, if so Im changing quantity and netPrice
        const preparedPrices = await pricesJSON.map((price) => {
          const priceFound = transactionJSON.orderDetails.prices.find(
            (transactionPrice) => {
              return transactionPrice._id === price._id
            }
          )

          price.quantity = priceFound ? priceFound.quantity : 0
          // if price was used in transaction, take netPrice from transaction (to prevent inconsistency if prices db was changed)
          price.netPrice = priceFound ? priceFound.netPrice : price.netPrice
          return price
        })

        // then Im mapping through transaction prices and checking if price is in prepared prices, if no, Im adding it to new array
        const lackingPrices = transactionJSON.orderDetails.prices
          .map((transactionPrice) => {
            const priceFound = preparedPrices.find(
              (price) => price._id === transactionPrice._id
            )

            if (priceFound) return null
            else {
              return transactionPrice
            }
          })
          .filter((price) => price) // removing "undefined"

        // I want to keep actual prices priority-sort and display lacking prices on bottom of table:
        const pricesMixed = [
          ...preparedPrices.sort((a, b) => a.priority - b.priority),
          ...lackingPrices,
        ]

        // finally (and its crucial for setting default values) Im replacing prices in transaction AND fetched prices with the same array.
        // that is because Im using prices to render table and transaction to set default values, if there are any differences it would crush
        transactionJSON.orderDetails.prices = pricesMixed
          // Im also adding gross. in prices DB Im not keeping gross, but calculate while fetching for transactionAdd. But in transaction I'm storing net and gross, so I have to add it manually
          // I could do it while working on preparedPrices, but here it looks better ;)
          .map((price) => {
            const grossPrice = price.netPrice * (price.vat + 1)
            return { ...price, grossPrice: roundToHundredth(grossPrice) }
          })

        setTransaction(transactionJSON)
        setPrices(pricesMixed)
      } catch (err) {
        throw err
      }
    }

    const fetchConfigs = async () => {
      try {
        const res = await fetch(
          "/configs/paymentDeadlines,saleDocuments,paymentMethods"
        )
        const resJSON = await res.json()
        setConfigs(resJSON)
      } catch (err) {
        alert(err)
      }
    }
    // when editing transaction:
    if (props.newTransaction)
      Promise.all([fetchPrices(), fetchConfigs()])
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.log(err)
          setModalData({
            header: "błąd",
            body: err.message,
            show: true,
          })
        })
    // when adding new transaction
    else {
      Promise.all([fetchTransactionAndPrices(), fetchConfigs()])
        .then(() => setIsLoading(false))
        .catch((err) => {
          console.log(err)
          setModalData({
            header: "błąd",
            body: err.message,
            show: true,
          })
        })
    }
  }, [user.perm, props.match.params.id, props.newTransaction])

  const handleDelete = async () => {
    try {
      setModalData(false)
      const res = await fetch(`/transactions/remove/${props.match.params.id}`, {
        method: "DELETE",
      })

      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "brak uprawnień",
        })

      if (res.status === 200) {
        return setModalData({
          show: true,
          type: "info",
          body: "Transakcja usunięta",
          onHide: () => {
            setRedirectTo("/transactions/")
          },
        })
      } else {
        throw res
      }
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: "Błąd wysyłki żądania" })
    }
  }

  const handleCancel = async () => {
    try {
      setModalData({
        show: "true",
        type: "confirm",
        body: "Czy na pewno chcesz anulować transakcję?",
        func: () =>
          handleStateChange(transaction._id, "anulowana", setModalData, () => {
            setModalData({ show: false })
            setShowTable(true)
          }),
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: "Błąd wysyłki żądania" })
    }
  }

  const renderTransactionEdit = () => {
    if (props.newTransaction)
      return (
        <div id="renderTransactionEdit">
          {isLoading ? null : (
            <TransactionsEditRender
              backToTable={() => setShowTable(true)}
              prices={prices}
              configs={configs}
              branches={userBranches}
              isDevEnv={cookies.meta?.devEnv ? true : false}
              setModalData={setModalData}
            />
          )}
          {showTable ? <Redirect to="/transactions" /> : null}

          <MyModal modalData={modalData} setModalData={setModalData} />
        </div>
      )
    else
      return (
        <div>
          {isLoading ? null : (
            <TransactionsEditRender
              edit={true}
              transaction={transaction}
              prices={prices}
              configs={configs}
              branches={userBranches}
              isDevEnv={cookies.meta?.devEnv ? true : false}
              setModalData={setModalData}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
            />
          )}

          {showTable ? <Redirect to="/transactions" /> : null}

          <MyModal modalData={modalData} setModalData={setModalData} />
        </div>
      )
  }

  return (
    <>
      {redirectTo ? <Redirect to={redirectTo} /> : null}
      {renderTransactionEdit()}
    </>
  )
}
export default TransactionsEditFetch
