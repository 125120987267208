import React, { useContext } from "react"
import { Table } from "react-bootstrap"

import { CustomerCtx } from "../Fetch"

import CustomersAddressDetailsModal from "../entities/CustomersAddressDetailsModal"

const CustomersDetailsAddressesTable = (props) => {
  const { setModalData } = useContext(CustomerCtx)
  const { tableHeader, addresses } = props
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="4">{tableHeader}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="fixed-values-table">
          <td>Typ</td>
          <td>Nazwa</td>
          <td>Adres</td>
          <td>Notatka</td>
        </tr>
        {addresses.map((adr) => (
          <tr
            key={`addresses-table-${adr._id}`}
            className="clickable"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                body: <CustomersAddressDetailsModal address={adr} />,
              })
            }
          >
            <td>{adr.type}</td>
            <td>{adr.name}</td>
            <td>{`${adr.street}, ${adr.postal} ${adr.city}`}</td>
            <td>{adr.note}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
export default CustomersDetailsAddressesTable
