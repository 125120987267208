import React, { useContext } from "react"
import { Container, Table, Button, Row, Col } from "react-bootstrap"
import { isSameDay } from "date-fns"
import { LocationCtx } from "../Fetch"

import LocationsDetailsContactsTable from "../tables/LocationsDetailsContactsTable"
import LocationsDetailsNotesTable from "../tables/LocationsDetailsNotesTable"
import PermissionsAssignModal from "../utils/PermissionAssignModal"
import LocationsDetailsInvModTable from "../tables/LocationsDetailsInvModTable"
import { LocationDetailsAddOldNumberModal } from "../Edit/modals/AddOldNumber"

const LocationsDetailsAdditionalTab = () => {
  const { location, setModalData, user, refresh } = useContext(LocationCtx)

  const handleStateChange = async () => {
    try {
      if (location.state === "archiwum")
        return setModalData({
          show: true,
          type: "info",
          headerColor: "warning",
          header: "Operacja zablokowana",
          body: "Przywracanie zarchiwizowanej lokalizacji jest zablokowane, zamiast tego dodaj lokalizację od nowa.",
        })

      // check if all services are closed and invoiced:

      const openedServices = location.services.filter((srv) => {
        //service must be closed or cancelled:
        if (srv.state === "aktywna") return true
        if (srv.state === "anulowana") return false
        // all params must be invoiced:
        let hasUninvoicedParam = false
        srv.params.forEach((prm) => {
          if (
            !(
              prm.hasEndDate &&
              isSameDay(new Date(prm.endDate), new Date(prm.invoicedTo))
            )
          )
            hasUninvoicedParam = true
        })
        if (hasUninvoicedParam) return true
        else return false
      })

      if (openedServices.length)
        return setModalData({
          show: true,
          type: "alert",
          header: "Nie można archiwizować",
          body: (
            <>
              <p>
                Lokalizacja zawiera niezamknięte usługi, lub nie wszystkie
                parametry zostały zafakturowane:
              </p>
              <ul>
                {openedServices.map((srv) => (
                  <li key={`openedServicesListElement-${srv._id}`}>
                    {srv.locSrvNo || ""}. {srv.name}
                  </li>
                ))}
              </ul>
            </>
          ),
        })

      const data = {
        location: {
          ...location,
          state: location.state === "aktywna" ? "archiwum" : "aktywna",
        },
        dirtyFields: { state: true },
        historyLength: location.history.length,
      }
      // remove history to prevent mongoose errors:
      delete data.location.history

      const res = await fetch(`/locations/edit/${location._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (res.status !== 200)
        throw new Error(
          `${(await res.text()) || "błąd komunikacji z serwerem"} `
        )
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Stan lokalizacji zmieniony",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd: ${err.message || "błąd działania programu"}`,
      })
    }
  }
  return (
    <Container fluid>
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="3">
              <Row className="justify-content-between">
                <Col>Dodatkowe informacje</Col>
                <Col>
                  <Row>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          header: "Dodawanie numeru z clipMenu",
                          body: <LocationDetailsAddOldNumberModal />,
                        })
                      }
                    >
                      Stary numer
                    </Button>
                    {user.perm.locations.e ? (
                      <Button
                        variant="warning"
                        className="ml-auto mr-2"
                        onClick={handleStateChange}
                      >
                        {location.state === "archiwum"
                          ? "Przywróć"
                          : "Archiwizuj"}
                      </Button>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="fixed-values-table">
            <td>Termin płatności</td>
            <td>Metoda płatności</td>
            <td>
              <Row className="ml-2">
                Pozwolenie{" "}
                <Button
                  className="ml-auto mr-2"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Przypisywanie zezwolenia",
                      body: (
                        <PermissionsAssignModal
                          user={user}
                          location={location}
                          setModalData={setModalData}
                          refresh={refresh}
                        />
                      ),
                    })
                  }
                >
                  Przypisz
                </Button>
              </Row>
            </td>
          </tr>
          <tr>
            <td>{location.customer.defaultDeadline}</td>
            <td>{location.customer.defaultPaymentMethod}</td>
            <td>{location.permission?.municipal || "Brak"} </td>
          </tr>
        </tbody>
      </Table>
      <LocationsDetailsContactsTable filter="aktywny" />
      <LocationsDetailsNotesTable filter="aktywna" />
      <LocationsDetailsInvModTable
        getAll={false}
        location={location}
        setModalData={setModalData}
        refresh={refresh}
      />
    </Container>
  )
}
export default LocationsDetailsAdditionalTab
