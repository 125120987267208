import React from "react"

import { Form, Row, Button } from "react-bootstrap"

const EmployeesListForm = (props) => {
  return (
    <Row className="justify-content-center mt-2">
      <Form onSubmit={(e) => props.handleSubmit(e)} inline autoComplete="off">
        <Form.Label>
          Szukajka:
          <Form.Control name="search" id="searchInput" />
        </Form.Label>

        <Form.Label>
          Oddział:
          <Form.Control as="select" name="branch" id="branch-select">
            <option value="all">wszystkie</option>
            {Object.keys(props.user.branch)
              .filter((branch) => props.user.branch[branch])
              .map((branch) => (
                <option value={branch} key={`branch-select-${branch}`}>
                  {branch}
                </option>
              ))}
          </Form.Control>
        </Form.Label>

        <Form.Label>
          Stan:
          <Form.Control as="select" name="state">
            <option value="aktywny">aktywni</option>
            <option value="all">wszyscy</option>
            <option value="archiwum">archiwalni</option>
          </Form.Control>
        </Form.Label>
        <Form.Label>
          Firma
          <Form.Control as="select" name="company">
            <option value="all">wszystkie</option>
            <option value="Ecoloxx">Ecoloxx</option>
            <option value="Clipper">Clipper</option>
          </Form.Control>
        </Form.Label>

        <Button type="submit" id="submitBtn">
          Wczytaj
        </Button>
      </Form>
    </Row>
  )
}
export default EmployeesListForm
