// table of fetched invoices (with checkboxes)
// cols: customer, location, srv names, net and gross values, is exported
// on row click -> show details modal

import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Table, Button, Form, Row } from "react-bootstrap"
import { format } from "date-fns"

import InvListDetailsModal from "./DetailsModal"
import { fetchFiltered } from "./utils"
import ExportConfirmModal from "./ExportConfirmModal"

const InvListRender = ({
  invoices,
  setModalData,
  page,
  setPage,
  configs,
  refresh,
  searchParams,
  setInvoices,
}) => {
  const [invSelected, setInvSelected] = useState([])

  useEffect(() => {
    // set checked/indeterminate of checkAll checkbox depending on quantity of selected invoices
    const setCheckAll = () => {
      if (invSelected.length === invoices.invoices.length) {
        document.getElementById("checkAll").checked = true
        document.getElementById("checkAll").indeterminate = false
      } else if (invSelected.length === 0) {
        document.getElementById("checkAll").checked = false
        document.getElementById("checkAll").indeterminate = false
      } else {
        document.getElementById("checkAll").checked = false
        document.getElementById("checkAll").indeterminate = true
      }
    }

    if (invSelected && invoices.invoices) setCheckAll()
  }, [invSelected, invoices])
  const renderPagesBtns = (pages) => {
    const pagesBtns = []
    for (let i = 0; i < pages; i++)
      pagesBtns.push(
        <Button
          key={`InvListPaginationBtn-${i + 1}`}
          onClick={async () => {
            await fetchFiltered(
              { ...searchParams, page: i + 1 },
              setModalData,
              setInvoices,
              setPage
            )
            setPage(i + 1)
          }}
          variant={page === i + 1 ? "secondary" : "primary"}
        >
          {i + 1}
        </Button>
      )
    return pagesBtns
  }
  if (invoices.pages === 0) return "Brak faktur"
  else
    return (
      <>
        <Row>
          {renderPagesBtns(invoices.pages)}
          <Button
            className="ml-auto mr-3"
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Eksport do Symfonii",
                body: (
                  <ExportConfirmModal
                    configs={configs}
                    invoices={invSelected}
                    setModalData={setModalData}
                    refresh={refresh}
                  />
                ),
              })
            }
          >
            Eksportuj zaznaczone
          </Button>
        </Row>
        <Table id="InvListRender">
          <thead>
            <tr>
              <td>
                <Form.Check
                  name="checkAll"
                  type="checkbox"
                  id="checkAll"
                  onChange={(e) => {
                    // update state and all checkboxes:
                    if (e.target.checked) {
                      setInvSelected(invoices.invoices.map((inv) => inv))
                      invoices.invoices.forEach((inv) => {
                        document.getElementById(
                          `invCheckbox-${inv._id}`
                        ).checked = true
                      })
                    } else {
                      setInvSelected([])

                      invoices.invoices.forEach((inv) => {
                        document.getElementById(
                          `invCheckbox-${inv._id}`
                        ).checked = false
                      })
                    }
                  }}
                />
              </td>
              <td>Data faktury</td>
              <td>Klient</td>
              <td>Usługi</td>
              <td>Oddział</td>
              <td>Wartość netto</td>
              <td>Wartość brutto</td>
              <td>Wyeksportowana</td>
            </tr>
          </thead>
          <tbody>
            {invoices.invoices?.map((inv) => {
              return (
                <tr
                  key={`InvListRender-TableRow-${inv._id}`}
                  className="clickable"
                  id={`invRow-${inv._id}`}
                  onClick={(e) => {
                    if (e.target.type !== "checkbox")
                      setModalData({
                        show: true,
                        type: "info",
                        body: (
                          <InvListDetailsModal
                            invoice={inv}
                            setModalData={setModalData}
                            configs={configs}
                            refresh={refresh}
                          />
                        ),
                        xl: true,
                      })
                  }}
                >
                  <td>
                    <Form.Check
                      name={`invCheckbox-${inv._id}`}
                      value={inv._id}
                      id={`invCheckbox-${inv._id}`}
                      onChange={(e) => {
                        if (e.target.checked)
                          setInvSelected([...invSelected, inv])
                        else setInvSelected(_.pull([...invSelected], inv))
                      }}
                    />
                  </td>
                  <td>
                    {format(
                      new Date(inv.InvoiceDetails.InvoiceDate),
                      "yyyy-MM-dd"
                    )}
                  </td>
                  <td>
                    {inv.clientResult?.ref?.shortName || "BRAK nazwy klienta"}
                  </td>
                  <td>
                    {inv.InvoiceDetails.productsResults
                      .map((prod) => prod.ref?.name)
                      .join(", ")}
                  </td>
                  <td>{inv.branch || "bd."}</td>
                  <td>
                    {
                      inv.InvoiceDetails?.payments?.InvoiceCurrency
                        ?.InvoiceProductsCost
                    }
                  </td>
                  <td>{_.round(inv.grossSum, 2)}</td>
                  <td>{inv.exportDates.length ? "tak" : "nie"}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {renderPagesBtns(invoices.pages)}
      </>
    )
}
export default InvListRender
