import React, { useState } from "react"
import {
  Container,
  Row,
  Table,
  Badge,
  Button,
  Form,
  Alert,
} from "react-bootstrap"

import { format } from "date-fns"

import { useForm, Controller } from "react-hook-form"

import JobDetailsModal from "./JobDetailsModal"
import { ChooseSrvAndEqp, createJobSrvArr } from "./utils"
// import LocationsDetailsEquipmentTable from "../../tables/LocationsDetailsEquipmentTable"
// import LocationsDetailsEquipmentTable from "../../tables/LocationsDetailsEquipmentTable"

const JobEditModal = (props) => {
  const {
    setModalData,
    configs,
    user,
    refresh,
    location,
    job,
    services,
    cars,
    parent,
  } = props

  const [fetchError, setFetchError] = useState(null)

  const defaultValues = {
    ...props.job,
    customHours: props.job.customHours ? "true" : "false",
    date: format(new Date(job.date), "yyyy-MM-dd"),
    driver: props.job.hasDriver ? props.job.driver?._id : null,
    // services: services,
    services: createJobSrvArr(location?.services || services, job),
    car: props.job.car?._id || "",

    //* important: when changing state from 'zaplanowane'/'zlecone' to any other I'm freezing jobs equipment (to keep it's state from moment of job execution)
    //* hence it needs some more work to add ability to edit equipment when state EVER WAS other then 'zaplanowane' or 'zlecone', for now
    //* I'm just not showing equipment table in such case (no fields -> no register)
  }

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState,
    getValues,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  const { errors } = formState

  const { dirtyFields } = formState

  const submit = async (formData) => {
    try {
      const body = {
        job: {
          ...formData,
          _id: job._id,
          date: `${formData.date}T12:00`,
          // RHF prepares array of all locations eqp, I want only selected (ref!==false)
          equipment: formData.equipment?.filter((eqp) => eqp.ref),
          // api endpoint requires req.body.lob.location to be populated
          location: location,
        },
        dirtyFields: dirtyFields,
        historyLength: job.history.length,
        changeLocDriver: formData.changeLocDriver,
      }

      delete body.job.changeLocDriver // just delete it from job data, as I put it directly in req.body

      if (job.type !== "dostarczenie") {
        // clean up services and equipment info if job is not frozen:
        if (!job.isFrozen) {
          // cleaning services from not choosen:
          body.job.services = body.job.services.filter(
            (srv) => srv.locationServiceRef
          )

          // adding ref to global services collection
          body.job.services = body.job.services.map((srv) => {
            // find global location _id:
            const serviceRef = location.services.find(
              (locSrv) => locSrv._id === srv.locationServiceRef
            ).serviceRef
            return { ...srv, serviceRef: serviceRef }
          })

          // cleaning choosen services equipment from not choosen:
          body.job.services.forEach((srv, i) => {
            // when srv without eqp -> create empty array
            // when srv needs to have jobs I prevent this from happening  in form
            body.job.services[i].eqp = body.job.services[i].eqp
              ? srv.eqp.filter((eqp) => eqp.ref)
              : []
          })

          // when job is frozen:
        } else {
          body.job.services = job.services.map((jobSrv) => ({
            ...jobSrv,
            serviceRef: jobSrv.serviceRef._id,
          }))
        }
      } else {
        // add service params:
        body.job.services[0].locationServiceRef =
          job.services[0].locationServiceRef
        body.job.services[0].serviceRef = job.services[0].serviceRef._id

        // clear equipment array:
        body.job.services[0].eqp = body.job.services[0].eqp?.filter(
          (eqp) => eqp.ref
        )
      }

      // check if job has services requiring equipment

      body.job.services.forEach((jobSrv) => {
        // find location service (to get it's params)
        const locSrv = location.services.find(
          (locService) => locService._id === jobSrv.locationServiceRef
        )
        if (!locSrv)
          throw new Error(`brak usługi lokalizacji (jobSrv._id: ${jobSrv._id})`)

        if (locSrv.mustHaveEqp && !jobSrv.eqp.length && !jobSrv.subSrv.length)
          throw new Error("Wybierz przynajmniej jeden sprzęt/usługę podrzędną")
      })

      if (job.type === "dostarczenie") {
        const res = await fetch(`/jobs/deliveryEdit/${job._id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
        if (res.status !== 200) throw new Error(await res.text())
      } else {
        const res = await fetch("/jobs/edit", {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
        if (res.status === 403) return setFetchError("Brak uprawnień")
        if (res.status === 409)
          return setFetchError(
            "Niestety ktoś właśnie edytował to zadanie. Musisz odświeżyć to okno, żeby pobrać najświeższe dane. UWAGA! Wprowadzone przez Ciebie zmiany zostaną utracone - upewnij się, że dasz radę je odtworzyć."
          )
        if (res.status !== 200) {
          console.log(res)
          return setFetchError("Błąd komunikacji z serwerem")
        }
      }
      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: "Dane zadania zmienione",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError(`błąd: ${err.message}`)
    }
  }

  return (
    <Container id="editJobModalBody">
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Form onSubmit={handleSubmit(submit)}>
        <Row className="justify-content-between my-2">
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: `Szczegóły zadania (${
                  location?.name || job.location.name
                })`,
                body: (
                  <JobDetailsModal
                    job={job}
                    setModalData={setModalData}
                    location={location}
                    services={location.services}
                    locationName={location.name}
                    jobState={job.state}
                    user={user}
                    refresh={refresh}
                    configs={configs}
                    parent={parent}
                  />
                ),
              })
            }
            className="ml-2"
            id="goBackBtn"
          >
            Wróć
          </Button>
          {user.type.admin && (
            <Button
              onClick={() => {
                console.log(job)
                console.log(watch())
                console.log(dirtyFields)
              }}
            >
              ?
            </Button>
          )}
          <Button
            type="submit"
            variant="secondary"
            className="mr-2"
            id="submitBtn"
          >
            Zapisz
          </Button>
        </Row>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">
                Data{" "}
                {errors.date && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
              <td>
                <Form.Control
                  {...register("date", { required: true })}
                  as="input"
                  type="date"
                  className=""
                  autoComplete="chrome-off"
                  id="date"
                />
              </td>
            </tr>
            {job.type !== "dostarczenie" ? (
              <tr>
                <td className="fixed-values-table">Typ</td>
                <td>
                  <Form.Control
                    {...register("type")}
                    as="select"
                    className=""
                    autoComplete="chrome-off"
                  >
                    <option>serwis</option>
                    <option>zabranie</option>
                  </Form.Control>
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="fixed-values-table">
                <Controller
                  control={control}
                  name={"hasDriver"}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      checked={field.value}
                      inline
                      type="checkbox"
                      id="hasDriver"
                      onChange={(e) => {
                        if (e.target.checked) setValue("driver", "plug")
                        else setValue("driver", null)
                        field.onChange(e)
                      }}
                    />
                  )}
                />
                Kierowca{" "}
                {errors.driver && (
                  <Badge pill variant="warning">
                    Wybierz kierowcę
                  </Badge>
                )}
              </td>
              <td>
                <Form.Control
                  {...register("driver", {
                    validate: (v) => {
                      if (watch("hasDriver") && (!v || v === "plug"))
                        return false
                      else return true
                    },
                  })}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={!watch("hasDriver")}
                  id="driverSelect"
                >
                  <option value="plug" hidden>
                    wybierz
                  </option>
                  {user.drivers.map((driver) => {
                    // show only active drivers that match with location branch
                    // when using from Location I don't have populated job.location
                    // when using from Logistics I don't have props.location
                    // aaand thats why I use conditional chaining and || operator ;)
                    if (
                      driver.state === "aktywny" &&
                      driver.branch[location?.branch || job.location?.branch]
                    )
                      return (
                        <option
                          value={driver._id}
                          key={`driver-option-${driver._id}`}
                        >
                          {driver.fullName}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>
              </td>
            </tr>
            {job.state === "zaplanowane" &&
              (watch("hasDriver") !== job.hasDriver ||
                (watch("hasDriver") &&
                  watch("driver") !== job.driver?._id)) && (
                <tr>
                  <td className="fixed-values-table" colSpan="2">
                    <Form.Check
                      inline
                      {...register("changeLocDriver")}
                      type="checkbox"
                      label={
                        <>
                          Zastosuj zmianę kierowcy w lokalizacji i{" "}
                          <b>wszystkich zaplanowanych zadaniach</b>?
                        </>
                      }
                      id="changeLocDriver"
                    />
                  </td>
                </tr>
              )}
            {cars?.length && user.perm.logistics.w ? (
              <tr>
                <td className="fixed-values-table">Samochód</td>
                <td>
                  <Form.Control
                    {...register("car")}
                    as="select"
                    type=""
                    className=""
                    autoComplete="chrome-off"
                    id="carSelect"
                  >
                    <option value="">brak</option>
                    {cars
                      // when using from location I pass all cars from user allowed branches, thus I have to filter it:
                      .filter(
                        (car) =>
                          car.branch === job.branch && car.state === "aktywny"
                      )
                      .map((car) => (
                        <option value={car._id} key={`carSelect-${car._id}`}>
                          {car.regNum}
                        </option>
                      ))}
                  </Form.Control>
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="fixed-values-table">Zakres godzinowy</td>
              <td>
                <Form.Check
                  inline
                  {...register("customHours")}
                  type="radio"
                  label="tak"
                  value="true"
                  id="customHours-true"
                />
                <Form.Check
                  inline
                  {...register("customHours", { required: true })}
                  type="radio"
                  label="nie"
                  value="false"
                  id="customHours-false"
                />
              </td>
            </tr>
            {watch("customHours") === "true" ? (
              <>
                <tr>
                  <td className="fixed-values-table">
                    czas OD{" "}
                    {errors.startTime && (
                      <Badge pill variant="warning">
                        Pole wymagane
                      </Badge>
                    )}
                  </td>
                  <td className="fixed-values-table">
                    czas DO{" "}
                    {errors.endTime?.type === "required" && (
                      <Badge pill variant="warning">
                        Pole wymagane
                      </Badge>
                    )}
                    {errors.endTime?.type === "cantBeBeforeStart" && (
                      <Badge pill variant="warning">
                        zakończenie przed rozpoczęciem, serio?
                      </Badge>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Control
                      {...register("startTime", { required: true })}
                      as="input"
                      type="time"
                      className=""
                      autoComplete="chrome-off"
                      id="startTime"
                    />
                  </td>
                  <td>
                    <Form.Control
                      {...register("endTime", {
                        required: true,
                        validate: {
                          cantBeBeforeStart: (val) => {
                            const startH = getValues("startTime").slice(0, 2)
                            const startM = getValues("startTime").slice(3, 5)
                            const endH = val.slice(0, 2)
                            const endM = val.slice(3, 5)
                            if (endH > startH) return true
                            if (endH === startH && endM > startM) return true
                            else return false
                          },
                        },
                      })}
                      as="input"
                      type="time"
                      className=""
                      autoComplete="chrome-off"
                      id="endTime"
                    />
                  </td>
                </tr>
              </>
            ) : null}
            <tr>
              <td className="fixed-values-table" colSpan="4">
                Uwagi
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register("comments")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  id="jobCommentsInput"
                />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="4">
                Komentarz do realizacji
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register("doneComments")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          </tbody>
        </Table>

        <ChooseSrvAndEqp
          location={location}
          register={register}
          control={control}
          Controller={Controller}
          watch={watch}
          isFrozen={job.isFrozen}
          srvFrozen={job.srvFrozen}
          eqpTypes={configs.eqpTypes}
          job={job}
          setValue={setValue}
          setModalData={setModalData}
        />
      </Form>
    </Container>
  )
}
export default JobEditModal
