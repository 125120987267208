import React, { useContext, useState, useEffect } from "react"
import { Container, Button, Row, Alert } from "react-bootstrap"

import { LocationCtx } from "../../Fetch"

const LocationsDetailsEditMapModal = () => {
  const {
    initMap,
    mapRef,
    formRef,
    location,
    getMarkerPosition,
    refresh,
    setModalData,
  } = useContext(LocationCtx)
  const [initialRender, setInitialRender] = useState(true)
  const [fetchError, setFetchError] = useState(null)

  useEffect(() => {
    if (initialRender) {
      initMap()
      formRef.current.value = `${location.street || ""} ${
        location.city || ""
      } ${location.postal || ""}`
      setInitialRender(false)
    }
  }, [
    formRef,
    initMap,
    initialRender,
    location.street,
    location.city,
    location.postal,
  ])

  const submitCoords = async () => {
    try {
      const coords = getMarkerPosition()
      if (!coords)
        return setFetchError(
          "najpierw ustaw marker (kliknij okienko wyszukiwarki nad mapą, wpisz adres jeśli nie załadował się automatycznie i wybierz pozycję z autopodpowiedzi)"
        )
      else setFetchError(null)
      const data = {
        location: {
          _id: location._id,
          coords: { type: "Point", coordinates: [coords.lng, coords.lat] },
        },
        dirtyFields: { coords: true },
      }
      const res = await fetch(`/locations/edit/${location._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status !== 200) {
        console.log(res)
        return setFetchError("Błąd komunikacji z serwerem")
      }

      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: "Nowe współrzędne zapisane",
        onHide: refresh,
      })
    } catch (err) {
      setFetchError("Błąd zapisywania współrzędnych")
      console.log(err)
    }
  }

  return (
    <Container>
      <input type="text" ref={formRef} size="50" id="mapSearchInput" />
      <div
        id="map"
        style={{ height: "500px" }}
        ref={mapRef}
        className="mt-5"
      ></div>

      {fetchError && (
        <Alert variant="danger" className="mt-2">
          {fetchError}
        </Alert>
      )}
      <Row className="mt-3 justify-content-around">
        <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
        <Button
          id="saveCoords"
          className="ml-auto"
          variant="secondary"
          onClick={submitCoords}
        >
          Zapisz
        </Button>
      </Row>
    </Container>
  )
}
export default LocationsDetailsEditMapModal
