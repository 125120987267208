import React, { useEffect, useState } from "react"
import _ from "lodash"
import {
  Container,
  Form,
  Button,
  Row,
  Table,
  Badge,
  Col,
  Alert,
  Spinner,
} from "react-bootstrap"
import { useForm } from "react-hook-form"

import { MdSystemUpdateAlt } from "react-icons/md"

import { CustomerParams, fetchCustomerData } from "../utils"
import { generalSubmit } from "../submit"
import {
  prepare,
  validatePesel,
  validateVatNumber,
  validateRegon,
  atLeastOne,
} from "../generalPreSubmit"

const CustomersDetailsEditGeneralModal = (props) => {
  //! not using customer ctx because this comp is also used to add new customer. All data is passed via props

  const { isNew, customer, setModalData, refresh, user } = props
  const [alert, setAlert] = useState(null)
  const [fetchingCustomerData, setFetchingCustomerData] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const mainAddressInd = isNew
    ? null
    : customer.addresses.findIndex(
        (add) => add.state === "aktywny" && add.type === "główny"
      )
  const mainContactInd = isNew
    ? null
    : customer.contacts.findIndex(
        (cont) => cont.state === "aktywny" && cont.type === "główny"
      )

  const { register, watch, handleSubmit, formState, setValue, getValues } =
    useForm({
      defaultValues: isNew
        ? { isPrivat: null, isFarmer: null, debtCollectionCat: "zwykły" }
        : {
            ...customer,
            // RHF needs string for boolean values:
            isPrivat: customer.isPrivat ? "true" : "false",
            isVatPayer: customer.isVatPayer ? true : false,
            isFarmer: customer.isFarmer ? true : false,

            // set main address values:
            mainAddress: {
              street: customer.addresses[mainAddressInd]?.street || null,
              city: customer.addresses[mainAddressInd]?.city || null,
              postal: customer.addresses[mainAddressInd]?.postal || null,
            },
            // set main contact values:
            mainContact: {
              name: customer.contacts[mainContactInd]?.name || null,
              mail: customer.contacts[mainContactInd]?.mail || null,
              phone: customer.contacts[mainContactInd]?.phone || null,
              fax: customer.contacts[mainContactInd]?.fax || null,
              note: customer.contacts[mainContactInd]?.note || null,
            },
          },
    })

  const { errors } = formState

  // in RHF docs is information about changing dirtyFields to dirty:
  const dirtyFields = formState.dirtyFields || formState.dirty

  // when changing privat <-> businness change isVatPayer
  const watchIsPrivat = watch("isPrivat")
  useEffect(() => {
    if (watchIsPrivat === "true") setValue("isVatPayer", false)
    else setValue("isVatPayer", true)
  }, [watchIsPrivat, setValue])

  // generating shortname - but only for new customers, as it would override previously entered data:
  const watchForShortName = watch(["name", "surname"])
  useEffect(() => {
    if (watchIsPrivat === "true" && isNew)
      setValue("shortName", watchForShortName.join(" "))
    else if (isNew) setValue("shortName", watchForShortName[0])
    else return
  }, [watchForShortName, setValue, watchIsPrivat, isNew])

  const onSubmit = (data) => {
    if (isNew) {
      return generalSubmit(
        { ...data, state: "aktywny" },
        -1,
        setModalData,
        setIsFetching,
        refresh,
        setAlert
      )
    }
    const dataReady = prepare(
      data,
      dirtyFields,
      mainContactInd,
      mainAddressInd,
      customer
    )
    generalSubmit(dataReady, customer._id, setModalData, setIsFetching, refresh)
  }

  const renderBranchesForm = () => {
    // only user with customers.d can edit not-new customer branches
    if (!isNew && !user.perm.customers.d) return null
    // if customer is new -> show checkboxes for user.allowedBranches
    else if (isNew)
      return (
        <>
          <tr>
            <td className="fixed-values-table" colSpan="4">
              Oddziały{" "}
              {errors.branches && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              {user.allowedBranches.map((branch) => {
                return (
                  <Form.Check
                    inline
                    {...register("branches", { required: true })}
                    type="checkbox"
                    label={branch}
                    value={branch}
                    id={`${branch}Checkbox`}
                    key={`customer-branches-checkboxes-${branch}`}
                  />
                )
              })}
            </td>
          </tr>
        </>
      )
    // if customer is not new and user can edit customer branches (perm.customer.d) -> merge customer branches and user.allowedBranches
    // user can change only chackbox representing branch that he is allowed to
    else {
      const branchesArr = _.union(user.allowedBranches, customer.branches)
      return (
        <>
          <tr>
            <td className="fixed-values-table" colSpan="4">
              Oddziały{" "}
              {errors.branches && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              {branchesArr.map((branch) => {
                return (
                  <Form.Check
                    inline
                    {...register("branches", { required: true })}
                    type="checkbox"
                    label={branch}
                    value={branch}
                    id={branch}
                    disabled={
                      user.allowedBranches.findIndex(
                        (userBranch) => userBranch === branch
                      ) === -1
                        ? true
                        : false
                    }
                    key={`customer-branches-checkboxes-${branch}`}
                  />
                )
              })}
            </td>
          </tr>
        </>
      )
    }
  }

  const renderPrivatForm = () => {
    return (
      <Table bordered id="privatForm">
        <tbody>
          <tr>
            <td className="fixed-values-table">
              Imię{" "}
              {errors.name && (
                <Badge pill variant="warning" className="ml-auto">
                  wymagane
                </Badge>
              )}
            </td>

            <td className="fixed-values-table" colSpan="2">
              Nazwisko{" "}
              {errors.surname && (
                <Badge pill variant="warning" className="ml-auto">
                  wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("name", {
                  required: true,
                })}
                as="input"
                className=""
              />
            </td>
            <td colSpan="2">
              <Form.Control
                {...register("surname", { required: true })}
                as="input"
                className=""
              />
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">
              Nazwa skrócona{" "}
              {errors.shortName && (
                <Badge pill variant="warning" className="ml-auto">
                  Pole wymagane
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">
              PESEL{" "}
              {errors.pesel && (
                <Badge pill variant="warning" className="ml-auto">
                  Błędny PESEL
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("shortName", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td>
              <Form.Control
                {...register("pesel", {
                  validate: (pesel) => validatePesel(pesel),
                })}
                as="input"
                type=""
                className=""
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }
  const renderBusinessForm = () => {
    return (
      <Table bordered id="businessForm">
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Nazwa{" "}
              {errors.name && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">
              NIP{" "}
              {errors.vatNumber?.type === "validate" && (
                <Badge pill variant="warning" className="ml-auto">
                  błędny NIP
                </Badge>
              )}
              {errors.vatNumber?.type === "required" && (
                <Badge pill variant="warning" className="ml-auto">
                  wymagane
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Form.Control
                id="nameInput"
                {...register("name", { required: true })}
                as="input"
                type=""
                className=""
              />
            </td>
            <td>
              <Row>
                <Col>
                  <Form.Control
                    id="businessVatNumberInput"
                    {...register("vatNumber", {
                      validate: (vatNumber) => validateVatNumber(vatNumber),
                      required: true,
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    variant="secondary"
                    disabled={fetchingCustomerData}
                    onClick={() =>
                      fetchCustomerData(
                        getValues("vatNumber"),
                        setValue,
                        setFetchingCustomerData,
                        setAlert
                      )
                    }
                  >
                    {fetchingCustomerData ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <MdSystemUpdateAlt />
                    )}
                  </Button>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Nazwa skrócona{" "}
              {errors.shortName && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">
              REGON{" "}
              {errors.regon?.type === "validate" && (
                <Badge pill variant="warning" className="ml-auto">
                  błędny REGON
                </Badge>
              )}
            </td>
            <td className="fixed-values-table">Nr rejestrowy</td>
          </tr>
          <tr>
            <td colSpan="2">
              <Form.Control
                id="shortNameInput"
                {...register("shortName", { required: true })}
                as="input"
                type=""
                className=""
              />
            </td>
            <td>
              <Form.Control
                {...register("regon", {
                  validate: (regon) => {
                    if (regon.length === 0) return true
                    else return validateRegon(regon)
                  },
                })}
                as="input"
                type=""
                className=""
              />
            </td>
            <td>
              <Form.Control
                {...register("regNumber")}
                as="input"
                type=""
                className=""
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const renderCommonForm = () => {
    return (
      <>
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table">Dane do faktury</td>
              <td className="fixed-values-table">Rabat za płatność (w %)</td>

              <td className="fixed-values-table">
                Termin płatności{" "}
                {errors.defaultDeadline && (
                  <Badge pill variant="warning" className="ml-auto">
                    wymagane
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">Metoda</td>
            </tr>
            <tr>
              <td>
                <Form.Check
                  label="Rolnik?"
                  {...register("isFarmer")}
                  id="isFarmer"
                />

                <Form.Check
                  label="Płatnik VAT?"
                  {...register("isVatPayer")}
                  id="isVatPayer"
                />
                <Form.Check
                  label="eFaktura?"
                  {...register("eInvoice")}
                  id="eInvoice"
                />
                {watch("isVatPayer") && watch("isPrivat") === "true" ? (
                  <>
                    NIP:
                    <Form.Control
                      {...register("vatNumber", { required: true })}
                      as="input"
                    />
                  </>
                ) : null}
              </td>
              <td>
                <Form.Control
                  as="input"
                  label="Rabat za terminową płatność?"
                  {...register("discountForPayment")}
                  id="discountForPayment"
                  type="number"
                  step="any"
                  className="medium-number-input"
                />
                <Form.Check
                  {...register("showDiscountForPayment")}
                  type="checkbox"
                  label="Pokazywać na fakturze?"
                  id="showDiscountForPayment"
                />
              </td>
              <td>
                <Form.Control
                  id="defaultDeadlineInput"
                  {...register("defaultDeadline", { required: true })}
                  as="select"
                  type="number"
                  className="small-number-input"
                >
                  <option value="0">0</option>
                  <option value="7">7</option>
                  <option value="14">14</option>
                  <option value="21">21</option>
                  <option value="30">30</option>
                </Form.Control>
                <i>(przedpłata {"=>"} 0)</i>
              </td>
              <td>
                <Form.Control
                  {...register("defaultPaymentMethod")}
                  as="select"
                  type=""
                  className=""
                >
                  <option>Przelew</option>
                  <option>Gotówka</option>
                  <option>Karta</option>
                </Form.Control>
              </td>
            </tr>
            {/* show branches checkboxes */}
            {renderBranchesForm()}
          </tbody>
        </Table>
        {isNew && (
          <Table bordered>
            <tbody>
              <tr>
                <td colSpan="4" className="fixed-values-table">
                  Adres zameldowania/rejestrowy (ulica, nr posesji, nr
                  mieszkania)
                  {errors.mainAddress?.street && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagane
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <Form.Control
                    id="streetInput"
                    {...register("mainAddress.street", {
                      required: watch("isPrivat") === "true" ? false : true,
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Miasto{" "}
                  {errors.mainAddress?.city && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagane
                    </Badge>
                  )}
                </td>
                <td className="fixed-values-table">
                  Kod pocztowy{" "}
                  {errors.mainAddress?.postal && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagane
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Form.Control
                    id="cityInput"
                    {...register("mainAddress.city", {
                      required: watch("isPrivat") === "true" ? false : true,
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
                <td>
                  <Form.Control
                    id="postalInput"
                    {...register("mainAddress.postal", {
                      required: watch("isPrivat") === "true" ? false : true,
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        {isNew && (
          <Table bordered>
            <tbody>
              <tr>
                <td className="fixed-values-table">Główna notatka</td>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    id="mainNoteInput"
                    {...register("mainNote")}
                    as="textarea"
                    type=""
                    className=""
                    autoComplete="chrome-off"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* MAIN CONTACT - show only for new customer */}
        {isNew && (
          <Table bordered>
            <tbody>
              <tr>
                <td className="fixed-values-table">Osoba kontaktowa</td>
                <td className="fixed-values-table">
                  Mail{" "}
                  {(errors.mainContact?.mail?.type === "validate" ||
                    errors.mainContact?.phone ||
                    errors.mainContact?.fax) && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagany mail lub tel. lub fax
                    </Badge>
                  )}
                  {errors.mainContact?.mail?.type === "pattern" && (
                    <Badge pill variant="warning" className="ml-auto">
                      nieprawidłowy mail
                    </Badge>
                  )}
                </td>
                <td className="fixed-values-table">
                  Telefon{" "}
                  {(errors.mainContact?.mail?.type === "validate" ||
                    errors.mainContact?.phone ||
                    errors.mainContact?.fax) && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagany mail lub tel. lub fax
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    id="mainContactNameInput"
                    {...register("mainContact.name")}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
                <td>
                  <Form.Control
                    id="mainContactMailInput"
                    {...register("mainContact.mail", {
                      validate: () =>
                        atLeastOne(
                          watch([
                            "mainContact.mail",
                            "mainContact.phone",
                            "mainContact.fax",
                          ])
                        ),
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
                <td>
                  <Form.Control
                    id="mainContactPhoneInput"
                    {...register("mainContact.phone", {
                      validate: () =>
                        atLeastOne(
                          watch([
                            "mainContact.mail",
                            "mainContact.phone",
                            "mainContact.fax",
                          ])
                        ),
                    })}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="2">
                  Notatka do kontatku
                </td>
                <td className="fixed-values-table">
                  Fax{" "}
                  {(errors.mainContact?.mail?.type === "validate" ||
                    errors.mainContact?.phone ||
                    errors.mainContact?.fax) && (
                    <Badge pill variant="warning" className="ml-auto">
                      wymagany mail lub tel. lub fax
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Form.Control
                    id="mainContactNoteInput"
                    {...register("mainContact.note")}
                    as="textarea"
                    type=""
                    className=""
                  />
                </td>
                <td>
                  <Form.Control
                    {...register("mainContact.fax")}
                    as="input"
                    type=""
                    className=""
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
        <Table>
          <tbody>
            <tr>
              <td colSpan="4" className="fixed-values-table">
                Parametry
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <CustomerParams
                  register={register}
                  watch={watch}
                  customer={customer}
                  user={user}
                  errors={errors}
                  isNew={isNew}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }

  return (
    <Container>
      <Row>
        <Button className="ml-3" onClick={() => setModalData({ show: false })}>
          Wróć
        </Button>
        {isNew && props.devEnv && watch("isPrivat") === "false" && (
          <Button
            variant="warning"
            onClick={() => {
              setValue("name", `fixture ${new Date()}`)
              setValue("shortName", "fixture")
              setValue("vatNumber", 1130010397)
              setValue("defaultDeadline", 5)
              setValue("branches", props.user.allowedBranches[0])
              setValue("mainContact.phone", 5)
              setValue("mainAddress", {
                city: "miasto testowe",
                street: "ulica testowa",
                postal: 11111,
              })
            }}
          >
            Fixture
          </Button>
        )}
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="chrome-off">
        {/* is private? - rest form depends on this choice - ONLY FOR CREATING NEW*/}
        {isNew && (
          <Row className="justify-content-around">
            <Form.Label>
              <Form.Check
                inline
                type="radio"
                {...register("isPrivat")}
                value="true"
              />
              Os. fiz. bez działaności
            </Form.Label>
            <Form.Label>
              <Form.Check
                id="businessRadio"
                inline
                type="radio"
                {...register("isPrivat")}
                value="false"
              />
              Os. fiz. z działalnością/firma/organizacja itd.
            </Form.Label>
          </Row>
        )}
        {alert ? <Alert variant="danger">{alert}</Alert> : null}
        {watch("isPrivat") === "true" ? renderPrivatForm() : null}
        {watch("isPrivat") === "false" ? renderBusinessForm() : null}
        {watch("isPrivat") ? renderCommonForm() : null}
        {alert ? <Alert variant="danger">{alert}</Alert> : null}
        <Row>
          <Button
            disabled={isFetching}
            variant="danger ml-auto mr-5"
            onClick={() => setModalData({ show: false })}
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Anuluj"}
          </Button>
          <Button
            disabled={isFetching}
            type="submit"
            variant="secondary"
            className="ml-3 mr-3"
            id="submitBtn"
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Zapisz"}
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default CustomersDetailsEditGeneralModal
