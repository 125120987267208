import React, { useState, useEffect } from "react"

import { Row, Col, Form } from "react-bootstrap"

const HistoryFilters = ({ users, setHistoryFiltered, history }) => {
  const [filters, setFilters] = useState({ user: "all", type: "all" })
  useEffect(() => {
    const newHistory = history.filter((historyEntry) => {
      let isOk = true
      // user filtering:
      if (filters.user !== "all" && historyEntry.user._id !== filters.user)
        isOk = false

      // type filtering
      if (filters.type !== "all" && historyEntry.type !== filters.type)
        isOk = false

      return isOk
    })
    setHistoryFiltered(newHistory)
  }, [filters, history, setHistoryFiltered])

  return (
    <Form>
      <Row>
        <Col xs="auto">
          <Row>Użytkownik</Row>
          <Row>
            <Form.Control
              as="select"
              className=""
              autoComplete="chrome-off"
              onChange={(e) => setFilters({ ...filters, user: e.target.value })}
            >
              <option value="all">Wszyscy</option>
              {users.map((usr) => (
                <option value={usr._id} key={`usersOptions-${usr._id}`}>
                  {usr.fullName}
                </option>
              ))}
            </Form.Control>
          </Row>
        </Col>
        <Col xs="auto">
          <Row>Rodzaj</Row>
          <Row>
            <Form.Control
              as="select"
              className=""
              autoComplete="chrome-off"
              onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            >
              <option value="all">Wszystkie</option>
              <option value="utworzenie">utworzenie</option>
              <option value="edycja">edycja</option>
              <option value="edycja auto">edycja auto</option>
            </Form.Control>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}
export default HistoryFilters
