// show form, initial fetch for not exported and from default branch
// show button to export checked/unchecked
import React, { useContext, useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { endOfMonth, startOfMonth, format, subDays } from "date-fns"
import { Helmet } from "react-helmet"

import UserContext from "../../contexts/userContext"
import MetaContext from "../../contexts/metaContext"

import MyModal from "../../utils/Modals"

import InvListRender from "./Render"
import InvListForm from "./Form"
import { fetchFiltered } from "./utils"

const InvListFetch = (props) => {
  const user = useContext(UserContext)
  const meta = useContext(MetaContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isInitial, setIsInitial] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [configs, setConfigs] = useState([])
  const [invoices, setInvoices] = useState(null)
  const [page, setPage] = useState(1)

  // set default search params:
  let initSearchParams = {
    show: "unexported",
    // set start date for beggining of this month if today >= 10 day, else set for previous month
    startDate: format(startOfMonth(subDays(new Date(), 10)), "yyyy-MM-dd"),
    endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    branch: user.defaultBranch || "all",
    pageSize: 50,
    dateType: "invoice",
  }
  // when there is customer_id sent via search query -> fetch invoices for this customer using _id, and set startDate for one year backwards
  if (props.location.search) {
    const params = new URLSearchParams(props.location.search)

    initSearchParams = {
      ...initSearchParams,
      _id: params.get("_id"),
      q: params.get("name"),
      startDate: format(subDays(new Date(), 365), "yyyy-MM-dd"),
    }
  }

  const [searchParams, setSearchParams] = useState(initSearchParams)

  useEffect(() => {
    const initialFetch = async () => {
      // errors handeled in fetchFiltered()
      setIsLoading(true)
      await fetchFiltered(
        { ...searchParams, page: page },
        setModalData,
        setInvoices,
        setPage
      )

      // when on dev env there is possibility to prevent setting invoice as exported:
      if (meta.devEnv === true) {
        let configs = await fetch("/configs/devSettings")
        configs = await configs.json()
        setConfigs(configs)
      } else setConfigs({ devSettings: { setInvoiceExported: true } })
      setIsLoading(false)
    }
    if (isInitial) {
      initialFetch()
      setIsInitial(false)
    }
  }, [user, page, searchParams, meta, isInitial])

  const refresh = async (
    // when using refresh in print components I pass choosen search params to update and fetch data according to it:
    newSearchParams = null
  ) => {
    try {
      if (newSearchParams) setSearchParams(newSearchParams)

      setModalData({ show: false })
      setIsLoading(true)
      await fetchFiltered(
        // when new search params passed use it to create query - state won't update that fast
        { ...(newSearchParams || searchParams), page: page },
        setModalData,
        setInvoices,
        setPage
      )
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd programu: ${err.message || "błąd pobierania danych"}`,
      })
    }
  }

  return (
    <Container fluid id="InvList">
      <Helmet>
        <title>Lista faktur</title>
      </Helmet>
      <InvListForm
        setInvoices={setInvoices}
        user={user}
        setModalData={setModalData}
        setSearchParams={setSearchParams}
        setPage={setPage}
        searchParams={searchParams}
        setIsLoading={setIsLoading}
        refresh={refresh}
      />

      {isLoading ? (
        "pobieram dane..."
      ) : (
        <InvListRender
          invoices={invoices}
          setModalData={setModalData}
          page={page}
          setPage={setPage}
          configs={configs}
          refresh={refresh}
          setInvoices={setInvoices}
          searchParams={searchParams}
        />
      )}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default InvListFetch
