import React, { useMemo } from "react"
import { useTable, useSortBy, usePagination } from "react-table"
import { Table, Button, Container, Row } from "react-bootstrap"

import { MdArrowBack, MdArrowForward } from "react-icons/md"

import { dateParse } from "../../utils/formats"

const ErrorsLogRender = (props) => {
  const data = useMemo(() => props.errors, [props])
  const columns = useMemo(
    () => [
      {
        Header: "message",
        accessor: "message",
      },
      {
        Header: "origin",
        accessor: "origin",
      },
      {
        Header: "createdAt",
        id: "createdAt",
        Cell: (table) => dateParse(table.row.original.createdAt),
      },
    ],
    []
  )

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: [{ id: "createdAt", desc: true }],
      },
    },
    useSortBy,
    usePagination
  )
  return (
    <Container>
      <Table bordered className="mt-4">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                  className="pretty-table-header"
                >
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => props.setErrorSelected(row.original)}
                className="transaction-details-tr"
              >
                {row.cells.map((cell) => {
                  if (
                    cell.column.id === "state" &&
                    cell.value === "oczekująca"
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="bg-warning"
                        // style={{ background: "info" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  } else if (
                    cell.column.id === "state" &&
                    cell.value === "zafakturowana"
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="bg-success"
                        // style={{ background: "green" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="pretty-table-data"
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row className="justify-content-end align-items-center">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
          // style={{ maxHeight: "30px" }}
        >
          <MdArrowBack />
        </Button>
        <p>
          <b>{pageIndex + 1}</b> z {pageOptions.length}
        </p>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
        >
          <MdArrowForward />
        </Button>
        {/* below element giving possibility to go to certain page, for now I dont need it */}
        {/* <div>Idź do:</div>
            <input 
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
      </Row>
      <Row className="justify-content-end mt-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          className="mr-4"
        >
          {
            // setting pagination options - how many rows can user display
            [5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Pokaż {pageSize}
              </option>
            ))
          }
        </select>
      </Row>
    </Container>
  )
}
export default ErrorsLogRender
