import { useContext, useState } from "react"
import { Container, Button, Form } from "react-bootstrap"
import { format } from "date-fns"

import UserContext from "../../contexts/userContext"

/**
 * ! use only driver jobs as prop!!!
 */
const CarAssign = ({ driver, cars, setModalData, jobs, date, refresh }) => {
  const user = useContext(UserContext)
  const [showTestCars, setShowTestCars] = useState(false)

  const submit = async (e) => {
    try {
      e.preventDefault()
      const body = {
        jobs: jobs.map((job) => ({
          _id: job._id,
          historyLength: job.history.length,
        })),
        modifications: { car: { apply: true, newValue: e.target.car.value } },
      }
      const res = await fetch("/jobs/batchEdit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd ${res.status}: ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )
      else refresh()
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || "nieokreślony błąd programu",
      })
    }
  }

  return (
    <Container>
      <Form onSubmit={submit}>
        Wszystkie zadania kierowcy {driver.fullName} z dnia{" "}
        {format(new Date(date), "yyyy-MM-dd")} przypisz do samochodu:
        <Form.Control as="select" name="car">
          {cars
            .filter((car) => {
              if (car.state === "archiwum") return false
              let shouldShow = false
              if (user.allowedBranches.includes(car.branch)) shouldShow = true
              if (car.branch === "TEST" && !showTestCars) shouldShow = false
              if (car.hideForLogistic) shouldShow = false
              return shouldShow
            })
            .map((car) => (
              <option value={car._id} key={`carSelectOption-${car._id}`}>
                {car.regNum} ({car.brand} {car.model} "{car.name}")
              </option>
            ))}
        </Form.Control>
        <Form.Check
          name="showTestCars"
          type="checkbox"
          label="pokaż samochody oddziału TEST"
          onChange={(e) => setShowTestCars(e.target.checked)}
        />
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Form>
    </Container>
  )
}
export default CarAssign
