import { useForm } from "react-hook-form"
import { Row, Button, Form, Alert } from "react-bootstrap"

import { submit } from "./utils"

//TODO add validation

const CarSellEdit = ({ car, refresh, setModalData, user }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      deregisterDate: car.deregisterDate,
      sellInvoice: car.sellInvoice,
      sellAmount: car.sellAmount,
      sellDate: car.sellDate,
      insuranceTerminationDate: car.insuranceTerminationDate,
      isSold: car.state === "sprzedany" ? true : false,
    },
  })

  const watchIsSold = watch("isSold")

  const submitWrapper = (formData) => {
    /* 
My idea to use 'isSold' field to set 'state' field caused some combination in handling submit
I have to create my own dirtyFields object and check if 'isSold' changed etc
Thats why this code looks so strange
*/

    let dataReady = { ...car }
    let dirtyFieldsReady = { ...dirtyFields, state: dirtyFields.isSold }

    // if not sold clear all sell data
    if (!formData.isSold) {
      dataReady.state = "aktywny"
      dataReady.sellDate = null
      dataReady.sellAmount = null
      dataReady.sellInvoice = null
      dataReady.insuranceTerminationDate = null
      dataReady.deregisterDate = null

      // just to create history entry:
      dirtyFieldsReady = {
        sellDate: true,
        sellAmount: true,
        sellInvoice: true,
        insuranceTerminationDate: true,
        deregisterDate: true,
      }
    }

    // if sold set sell data
    if (formData.isSold) {
      dataReady.state = "sprzedany"
      dataReady.sellDate = formData.sellDate
      dataReady.sellAmount = formData.sellAmount
      dataReady.sellInvoice = formData.sellInvoice
      dataReady.insuranceTerminationDate = formData.insuranceTerminationDate
      dataReady.deregisterDate = formData.deregisterDate
    }

    return submit(
      dataReady,
      dirtyFieldsReady,
      car,
      false,
      refresh,
      setModalData
    )
  }

  const renderSellForm = () => {
    if (!watchIsSold)
      return (
        <Alert variant="secondary" className="my-5">
          Aby wypełnić dane sprzedaży zaznacz checkbox powyżej.
          <br />
          <b>
            Jeśli samochód był oznaczony jako sprzedany, zapisanie teraz
            spowoduje{" "}
            <span className="bg-danger">
              usunięcie wszystkich wprowadzonych danych!
            </span>
          </b>
        </Alert>
      )
    return (
      <>
        Data sprzedaży:
        <Form.Control
          {...register("sellDate")}
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off"
        />
        Numer faktury sprzedaży:
        <Form.Control
          {...register("sellInvoice")}
          as="input"
          type="text"
          className=""
          autoComplete="chrome-off"
        />
        Wartość sprzedaży netto:
        <Form.Control
          {...register("sellAmount")}
          as="input"
          type="number"
          className=""
          step="any"
          autoComplete="chrome-off"
          min={0}
        />
        Data wyrejestrowania:
        <Form.Control
          {...register("deregisterDate")}
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off"
        />
        Data zakończenia ubezpieczenia:
        <Form.Control
          {...register("insuranceTerminationDate")}
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off"
        />
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit(submitWrapper)}>
      <Form.Check
        {...register("isSold")}
        type="checkbox"
        label="Sprzedany"
        id="isSold"
      />
      {renderSellForm()}
      <Row className="justify-content-between">
        <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
        <Button
          // TODO make it return to details modal
          onClick={() => setModalData({ show: false })}
        >
          Wróć
        </Button>
        {user.type.admin ? (
          <Button
            onClick={() => {
              console.log(watch())
              console.log("dirty: ", dirtyFields)
            }}
          >
            ?
          </Button>
        ) : null}
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export { CarSellEdit }
