import React from "react"
import { Row, Table, Col, Button } from "react-bootstrap"

import { FaCheck } from "react-icons/fa"
import sortByCreationDate from "../../../utils/sortByCreationDate"

import CustomersContactDetailsModal from "../entities/CustomersContactDetailsModal"
import CustomersContactEditModal from "../entities/CustomersContactEditModal"

//! I'm using props not CustomerCtx because I plan to use this comp to import contacts from customer to location
//! this will be fired from location, whit different context
const CustomersDetailsContactsTable = (props) => {
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="5">
            <Row>
              <Col xs={{ offset: 5, span: 3 }}>Lista kontaktów</Col>
              {props.customer.state === "aktywny" ? (
                <Col xs={{ offset: 3 }}>
                  {!props.embedded && props.user.perm.customers.e ? (
                    <Button
                      variant="secondary"
                      onClick={() =>
                        props.setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          header: "Edycja danych kontaktu",
                          hideFooter: true,
                          body: <CustomersContactEditModal contact="new" />,
                        })
                      }
                    >
                      Dodaj
                    </Button>
                  ) : null}
                </Col>
              ) : null}
            </Row>
          </th>
        </tr>
        <tr>
          <th>Rodzaj</th>
          <th>Imię i nazwisko/nazwa</th>
          <th>Telefon</th>
          <th>Mail</th>

          <th>Notatki</th>
        </tr>
      </thead>
      <tbody>
        {props.contacts.sort(sortByCreationDate).map((contact) => {
          if (contact.state === "aktywny")
            return (
              <tr
                key={`contact-row-${contact._id}`}
                className="clickable"
                onClick={() => {
                  // when using as embedded (importing contacts to location) use parent setContactSelected (set to null if already selected),
                  // else show details
                  if (
                    props.embedded &&
                    props.contactSelected?._id !== contact._id
                  )
                    props.setContactSelected(contact)
                  else if (
                    props.embedded &&
                    props.contactSelected?._id === contact._id
                  )
                    props.setContactSelected(null)
                  else
                    props.setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: (
                        <p className="mb-0">
                          Szczegóły kontaktu {contact.name || <i>brak nazwy</i>}{" "}
                          (rodzaj: {contact.type})
                        </p>
                      ),
                      body: (
                        <CustomersContactDetailsModal
                          contact_id={contact._id}
                        />
                      ),
                    })
                }}
              >
                <td>
                  {props.embedded &&
                  props.contactSelected?._id === contact._id ? (
                    <FaCheck />
                  ) : null}
                  {contact.type}
                </td>
                <td>{contact.name}</td>
                <td>{contact.phone}</td>
                <td>{contact.mail}</td>
                <td>{contact.note}</td>
              </tr>
            )
          else return null
        })}
      </tbody>
    </Table>
  )
}
export default CustomersDetailsContactsTable
