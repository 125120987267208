import React, { useState, useContext } from "react"
import { Form, Button, Row, Alert, Col, Container } from "react-bootstrap"

import UserContext from "../contexts/userContext"
import MetaContext from "../contexts/metaContext"

import MyModal from "../utils/Modals"

import CustomersDetailsEditGeneralModal from "./Details/Edit/modals/CustomersDetailsEditGeneralModal"

const CustomersSearchForm = (props) => {
  const user = useContext(UserContext)
  const { devEnv } = useContext(MetaContext)
  const [modalData, setModalData] = useState({ show: false })
  const [isToShort, setIsToShort] = useState(null)
  return (
    <Container fluid id="CustomersSearchForm">
      <Form
        className="mt-2 justify-content-center"
        onSubmit={(e) => {
          e.preventDefault()
          if (e.target.query.value.length < 3) return setIsToShort(true)
          setIsToShort(false)
          props.fetchCustomers(e.target.query.value, e.target.archiwum.checked)
        }}
      >
        <Row className="justify-content-between">
          <Col xs="2">
            <Form.Check label="Archiwum" id="archiwum" className="mr-5" />
          </Col>
          <Col xs="4">
            <Form.Control
              htmlSize="50"
              id="query"
              autoComplete="off"
              autoFocus
            />
          </Col>
          <Col xs="2">
            <Button type="submit" id="searchBtn">
              Szukaj
            </Button>
          </Col>
          {/* adding customer only if not using embedded comp: */}
          {!props.embedded && (
            <Col>
              <Button
                id="addCutmrBtn"
                variant="secondary"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Dodawanie klienta",
                    hideFooter: true,
                    xl: true,
                    body: (
                      <CustomersDetailsEditGeneralModal
                        user={user}
                        isNew={true}
                        devEnv={devEnv}
                        setModalData={setModalData}
                      />
                    ),
                  })
                }
              >
                Dodaj
              </Button>
            </Col>
          )}
          <Col xs="2">
            {isToShort && (
              <Alert variant="danger" className="ml-auto" id="searchAlert">
                min. 3 znaki
              </Alert>
            )}
          </Col>
          <Col>
            <Button
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  body: (
                    <>
                      <p>
                        Wyszukiwarka przeszukuje następujące pola (ignoruje
                        wielkość liter):
                      </p>
                      <ul>
                        <li>
                          nazwa (pełna i skrócona), również fragment nazwy
                        </li>
                        <li>
                          nip/regon/pesel (wpisywane bez myślników i spacji)
                        </li>
                        <li>nazwa ulicy</li>
                        <li>adresy @</li>
                        <li>
                          faxy i telefony (zapisane tak, jak w danych klienta -
                          z myślnikami, spacjami itd.)
                        </li>
                        <li>
                          numery i nazwy właścicieli kont bankowych (w obu
                          przypadkach działa również na fragmentach
                          numeru/nazwy)
                        </li>
                      </ul>
                    </>
                  ),
                })
              }
            >
              ?
            </Button>
          </Col>
        </Row>
      </Form>
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default CustomersSearchForm
