import { useState, useContext } from "react"
import { Row, Button, Form } from "react-bootstrap"

import { LocationCtx } from "../../Fetch"

const LocationDetailsAddOldNumberModal = () => {
  const [oldNumber, setOldNumber] = useState("")
  const { setModalData, location, refresh } = useContext(LocationCtx)

  const submit = async (e) => {
    try {
      e.preventDefault()

      const res = await fetch(`/locations/edit/${location._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          location: { oldNumber: oldNumber, _id: location._id },
          dirtyFields: { oldNumber: true },
          historyLength: location.history?.length || location.historyLength,
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      return refresh({
        header: "Sukces",
        headerColor: "bg-success",
        body: "Numer dopisany",
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `${err.message || "nieokreślony błąd"}`,
      })
    }
  }

  return (
    <Form onSubmit={submit}>
      <p>Podaj numer ze starego programu:</p>
      <Form.Control
        as="input"
        className=""
        autoComplete="chrome-off"
        onChange={(e) => {
          setOldNumber(e.target.value)
        }}
      />
      <Row className="my-2 justify-content-between">
        <Button variant="primary" onClick={() => setModalData({ show: false })}>
          Anuluj
        </Button>
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export { LocationDetailsAddOldNumberModal }
