import { differenceInCalendarDays } from "date-fns"

const getLatestSrvParam = (srv) => {
  // from service params get last one by startDate:
  return srv.params.reduce((latest, param) => {
    const diff = differenceInCalendarDays(
      new Date(param.startDate),
      new Date(latest.startDate)
    )

    if (diff > 0) return param
    else return latest
  })
}

export { getLatestSrvParam }
