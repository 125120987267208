import { Button } from "react-bootstrap"

const sortCustomers = (a, b) => {
  let aName = a.isPrivat ? a.name : a.shortName || a.name
  let bName = b.isPrivat ? b.name : b.shortName || a.name
  aName = aName.replace(" ", "")
  bName = bName.replace(" ", "")
  return bName.localeCompare(aName)
}

const createInvoiceMailBtn = (customer, user) => {
  // when customer doesn't agree to eInvoice:
  if (!customer.eInvoice) return "poczta"

  // get active customer contacts of "eFaktura" type
  const invContacts = customer.contacts.filter(
    (ctc) => ctc.state === "aktywny" && ctc.type === "eFaktura" && ctc.mail
  )
  // if no "eFaktura" contacts return nullish:
  if (!invContacts.length) return "brak"
  // if "eFaktura" contacts return btn with onClick event
  else {
    const invContactsMails = invContacts.map((ctc) => ctc.mail || "").join(";")

    // if no mails return :
    //( join returns)
    if (!invContactsMails.length) return "brak"
    else
      return (
        <a
          href={`mailto:${invContactsMails}?subject=Faktura Clipper&body=Szanowni Państwo,%0D%0Aw załączeniu przesyłam fakturę VAT.%0D%0A%0D%0AZ poważaniem,%0D%0A${
            user?.fullName ? user.fullName : ""
          }%0D%0AClipper Sp. z o.o.%0D%0A%0D%0A---wiadomość wygenerowana automatycznie---`}
          key={`invoiceMailBtn-${customer._id}`}
        >
          <Button size="sm">@</Button>
        </a>
      )
  }
}
export { sortCustomers, createInvoiceMailBtn }
