import { Row, Col, Button } from "react-bootstrap"
import { FiRefreshCw } from "react-icons/fi"

import eqpChangeReport from "../../pics/manual/reports/eqpChangeReport.png"

const ManualReportsSection = (props) => {
  if (!props.user.perm.incomeReport.r) return null

  return (
    <div>
      <h2>Raporty</h2>

      {props.user.perm.incomeReport?.r ? (
        <div
          // v 1.14.4
          className="bg-secondarySuperLight"
        >
          <Row>
            <Col>
              <h3 id="branchesStats">Statystyki oddziałów</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Raport jest aktualizowany "na bieżąco" dla obecnego i
                poprzedniego miesiąca. Dla starszych miesięcy program korzysta z
                danych zapisanych w bazie - nie przelicza ich na bieżąco.
                <u>
                  Dlatego, jeśli nastąpi jakaś zmiana np. w styczniu a jest
                  sierpień to nie będzie ona widoczna w raporcie.
                </u>
                <br />
                Takie zmiany naturalnie nie powinny mieć miejsca, ale gdyby się
                zdarzyły to należy przeliczyć dane dla danego miesiąca klikając
                przycisk "
                <Button size="sm" variant="outline-primary">
                  {" "}
                  <FiRefreshCw />{" "}
                </Button>
                " znajdujący się w nagłówku tabeli.
                <br />
              </p>
            </Col>
            <Col></Col>
          </Row>
        </div>
      ) : null}
      {props.user.perm.logistics?.r ? <EqpChangeReportManual /> : null}
    </div>
  )
}

const EqpChangeReportManual = () => {
  return (
    <div
      id="eqpChangeReport"
      //v 1.15.3
      className="bg-secondarySuperLight"
    >
      <Row>
        <Col>
          <h3>Dostarczenia i zabrania</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Filtry</h4>
          <p>W raporcie są dwa zestawy filtrów:</p>
          <ul>
            <li>
              Ogólne (czerwona ramka na zrzucie: oddział, kierowca, daty) - tu
              ustala się, jakie dane mają być widoczne w podsumowaniu, na ich
              podstawie program generuje też wstępną listę zadań poniżej.
            </li>
            <li>
              Filtry tabeli (żółta ramka na zrzucie: typ zadania, rodzaj usługi,
              kierowca) - <u>te filtry działają tylko na tabelę zadań.</u>
            </li>
          </ul>
          <p>
            Czyli ustawienie wszystkich kierowców w filtrach ogólnych i potem
            wybranie jednego z nich w filtrze tabeli <u>nie spowoduje zmiany</u>{" "}
            wartości wyświetlanych w podsumowaniu, tylko na samej liście zadań.
          </p>
          <p>
            Żółty przycisk "X" przy filtrach tabeli przywraca pierwotne
            ustawienia
          </p>
          <h4>Zadania krótkoterminowe</h4>
          <p>
            Jeśli do zadania była podpięta usługa krótkoterminowa, to zostanie
            ona wyróżniona niebieskim tłem (zielona ramka na zrzucie). Jeśli w
            tabeli jest zadanie z usługami zarówno krótko- jak i
            długoterminowymi{" "}
            <u>
              to będzie wyświetlone przy każdym ustawieniu filtra "rodzaj
              usługi"
            </u>
            .
          </p>
        </Col>
        <Col>
          <img src={eqpChangeReport} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
    </div>
  )
}

export { ManualReportsSection, EqpChangeReportManual }
