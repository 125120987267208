import { useState, useEffect } from "react"
import { Container, Alert, Toast, Row, Col, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useDropzone } from "react-dropzone"

import renderSpinner from "../utils/renderSpinner"
import MyModal from "../utils/Modals"

import { DebtCollectionTable } from "./table"
import {
  DebtCollectionFilters,
  DebtCollectionXlsxFilters,
  handleFilters,
} from "./filters"
import { excelHandler } from "./excelHandler"
import { prepareCustomers } from "./prepareCustomers"
import { sortCustomers } from "./sortHandler"
import { createManualBtn } from "../utils/buttons"

/* 
it is more complicated that it seemd at first glace
(and propably I overthought it)

there are two ways of getting customers:
1. by using query input -> fetches data from api based on name
2. by using file -> fetches all customers data from api using customers ids and merges it with data from file

if 2. user can still use query input, but it works on fetched data only (no fetching from api when query input used)
if 2. used I show two additional columns

There is also sorting by selected columns and pagination (there can be more then 500 customers when using file)
_pagination is done in table component, parent holds state only to keep it on refreshing

to keep all this as readable as possible (incuding useEffects dependencies) I use a few states:
  - customers - used only when there is file input to keep all fetched customers
  - customersFiltered - used in both situations, for 1. it is fetch result, in 2. it is result of filtering customers state
  - customersSorted - used in both situations to handle sorting of customersFiltered
! I can see no way to sort customersFiltered directly, as sorting should be fired when fetching data AND WHEN sortBy changes
  

* looking from process flow it looks somewhat like this
1.
filters changed and no file loaded -> fetch customers (setCustomersFiltered) -> sort customers (setCustomersSorted) ->
-> pass to table comp

2. 
file uploaded -> clear old data (ALL customers states, filters, sortBy, page, FILE) - there can be sth in filters from previous usage -> 
-> fetch customers from file using excelHandler() (setCustomers) -> apply filters (setCustomersFiltered) -> 
-> sort customers (setCustomersSorted) 
-> pass to table comp

so 'apply filters' has different purpose in both cases, in 1. it fires fetch, in 2. it filters already sorted data

! I was wondering if I should first filter or sort, but filtering first seems more reasonable
(in 1. it doeas no different, 
(  in 2. user will use sorting more often

I use multiple useEffect and disable eslint for dependencies arrays to reflect above logic

* what makes it more complicated is handling refresh
After firing action (like adding note or suspending jobs) I fetch fresh data AND WANT TO KEEP PAGINATION, SORTING AND FILTERS




*/

const DebtCollection = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState({ show: false })
  const [customers, setCustomers] = useState([]) // used only when loading data from xlsx, normal fetch sets customersFiltered
  const [customersFiltered, setCustomersFiltered] = useState([])
  const [customersSorted, setCustomersSorted] = useState([])

  const [fetchError, setFetchError] = useState(null)
  const [filters, setFilters] = useState({ query: "", archived: false })

  const [refreshCounter, setRefreshCounter] = useState(0)
  const [toast, setToast] = useState(null)
  const [tablePage, setTablePage] = useState(1)
  const [pageSize] = useState(25)
  const [totalPages, setTotalPages] = useState(0)
  const [file, setFile] = useState(null)
  const [sortBy, setSortBy] = useState({ field: "name", order: 1 })

  //* GET DATA when file uploaded
  // updates customers
  //( which causes filtering and sorting later on)
  useEffect(() => {
    if (file) {
      // clear filters:
      setFilters({
        query: "",
        archived: false,
      })
      document.getElementById("query").value = ""
      // clear old data:
      setCustomers([])
      setCustomersFiltered([])

      excelHandler(file, setFetchError, setCustomers, setIsLoading)
    }
  }, [file])

  //* FILTER DATA (or fetch when no file):
  // - when no file loaded -> fetch data
  // - when file loaded -> search in customers
  //( is fired after uploading file)
  useEffect(() => {
    if (filters.query && !file) {
      fetchCustomers(filters, setFetchError, setIsLoading, setCustomersFiltered)
    }
    if (file && customers.length) {
      // set page to 1
      setTablePage(1)

      setCustomersFiltered(handleFilters(filters, customers))
    }

    // prevent eslint warning for lack of file
  }, [filters, customers, file])

  //* SORT DATA
  // fired after filtering and on change of sortBy
  useEffect(() => {
    //! using ... to copy object
    //( in other case React doesn't lnow that state is updated, see note below)
    if (customersFiltered.length) {
      setCustomersSorted([...sortCustomers(sortBy, customersFiltered)])
    }
  }, [sortBy, customersFiltered])

  //* update pagination data (slicing done in table component)
  useEffect(() => {
    setTotalPages(Math.ceil(customersSorted.length / pageSize))
  }, [customersSorted, pageSize])

  //* handle refreshing
  useEffect(() => {
    // when file loaded use excelHandler to refresh data
    if (file) {
      excelHandler(file, setFetchError, setCustomers, setIsLoading)
    }

    // when no file, use standard fetch:
    //( checking filters.query to prevent firing on initial render)
    if (!file && filters.query) {
      fetchCustomers(filters, setFetchError, setIsLoading, setCustomersFiltered)
    }

    // prevent eslint warning for lack of filters and file
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter])

  const onDrop = (files) => {
    // fetching done in useEffect
    setFile(files[0])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,
  })

  const forceRefresh = (toast = null) => {
    setToast(toast)
    setRefreshCounter((prev) => prev + 1)
  }

  const renderTable = () => {
    if (isLoading) return renderSpinner("pobieram dane")
    else if (!filters.query && !file) return null
    else if (!customersFiltered.length) return <h3>Brak danych</h3>
    else
      return (
        <>
          <DebtCollectionTable
            customers={customersFiltered}
            setModalData={setModalData}
            refresh={forceRefresh}
            tablePage={tablePage}
            setTablePage={setTablePage}
            totalPages={totalPages}
            file={file}
            pageSize={pageSize}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      )
  }

  return (
    <Container fluid>
      <Helmet>
        <title>Windykacja</title>
      </Helmet>
      <Row className="my-2">
        <Col xs="4">
          <DebtCollectionFilters setFilters={setFilters} />
        </Col>
        <Col xs="5">
          {file ? (
            <Alert variant="warning">
              Załadowany plik: "{file.name}"<br />
              Wyszkiwanie <u>tylko</u> w danych z pliku!
            </Alert>
          ) : null}
        </Col>
        <Col xs="2">
          {file ? (
            <Button
              variant="danger"
              onClick={() => {
                setFile(null)
                setCustomers([])
                setFilters({ query: "", archived: false })
                document.getElementById("query").value = ""
              }}
            >
              Wyczyść dane
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="ml-auto mr-1"
              style={{
                background: isDragActive && !file ? "#69c07b" : null,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive && !file ? "Upuść tutaj!" : ".xlsx"}
            </Button>
          )}
        </Col>
        <Col xs="1">{createManualBtn("debtCollection")}</Col>
      </Row>
      {fetchError ? <Alert variant="danger">{fetchError}</Alert> : null}

      {file ? <DebtCollectionXlsxFilters setFilters={setFilters} /> : null}
      {renderTable()}
      <MyModal setModalData={setModalData} modalData={modalData} />
      <Toast
        show={toast ? true : false}
        delay={5000}
        autohide
        onClose={() => setToast(null)}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "20%",
        }}
      >
        <Toast.Header
          style={{ justifyContent: "space-between" }}
          className={toast?.headerBg || ""}
        >
          {toast?.header}
        </Toast.Header>
        <Toast.Body>{toast?.body}</Toast.Body>
      </Toast>
    </Container>
  )
}

const fetchCustomers = async (
  filters,
  setFetchError,
  setIsLoading,
  setCustomersFiltered
) => {
  try {
    setIsLoading(true)
    const res = await fetch(`/customers?query=${filters.query}&archive=false`)
    if (res.status !== 200)
      throw new Error(
        `Błąd pobierania danych: ${res.status} - ${
          (await res.text()) || "nieokreślony błąd"
        }`
      )

    const resJSON = await res.json()

    const customers = prepareCustomers(resJSON.customers)

    setCustomersFiltered(customers)
    setIsLoading(false)
  } catch (err) {
    console.log(err)
    setFetchError(err.message)
    setIsLoading(false)
  }
}

export { DebtCollection }

/* 
* why I use ... on sorting?
when comparing states React uses === operator. Sorting is done in place, so arr1 === arr1.sort() IS ALWAYS TRUE

*/
