import React, { useContext, useState } from "react"
import _ from "lodash"
import { Col, Row, Form, Button, Table, Alert, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { dateParse } from "../../utils/formats"

import { LocationCtx } from "../Fetch"

const LocationsDetailsServiceEditModal = (props) => {
  const { location, setModalData, refresh } = useContext(LocationCtx)
  const [fetchError, setFetchError] = useState(null)

  const { serviceChoosen, isNew } = props

  const incomeCategories = [
    "Toalety",
    "Sprzęt",
    "Kontenery",
    "Ogrodzenia",
    "Toalety klienta",
    "Szambo",
    "Sprzątanie",
    "Sprzedaż",
    "Transport",
    "Inna",
  ]

  const { register, handleSubmit, formState, watch } = useForm({
    defaultValues: isNew
      ? {
          netPrice: serviceChoosen.defaultNetPrice,
          grossPrice:
            serviceChoosen.defaultNetPrice *
            (1 + serviceChoosen.defaultVat / 100),
          vat: serviceChoosen.defaultVat,
          quantity: null,
          startDate: dateParse(new Date(), false),
          endDate: null,
          invLine: serviceChoosen.defaultInvLine,
        }
      : {
          ...serviceChoosen,
          startDate: dateParse(serviceChoosen.startDate, false),
          endDate: serviceChoosen.endDate
            ? dateParse(serviceChoosen.endDate, false)
            : null,
          incomeCat: serviceChoosen.incomeCat[0]?.name || "Toalety",
        },
  })

  const { dirtyFields, errors } = formState

  const submit = async (service) => {
    try {
      const body = {
        data: {
          ...service,
          invLine: service.invLine,
          mustHaveJobs: serviceChoosen.mustHaveJobs,
          type: serviceChoosen.type,
          name: serviceChoosen.name,
          chargeType: serviceChoosen.chargeType,
          state: isNew ? "aktywna" : serviceChoosen.state,
          eqpTypes: serviceChoosen.eqpTypes,
        },

        dirtyFields: dirtyFields,
      }
      // if new -> set serviceRef
      if (isNew) body.data.serviceRef = serviceChoosen._id

      //add _id if editting service:
      if (!isNew) {
        body.data._id = serviceChoosen._id
      }

      // refactor incomeCat:
      body.data.incomeCat = [{ name: service.incomeCat, share: 100 }]

      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=${
          isNew ? "true" : "false"
        }&entity=services`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      )
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status !== 200) {
        console.log(res)
        return setFetchError("Błąd komunikacji z serwerem")
      }

      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: "Dopisano usługę",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError("Błąd zapisywania danych")
    }
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Alert variant="danger">
        Uwaga! Wprowadzone zmiany będą miały wpływ na wszystkie niezafakturowane
        parametry usługi - niezależnie od ich dat.
      </Alert>
      <Table>
        <thead>
          <tr>
            <th colSpan="6">Szczegóły</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nazwa</td>
            <td className="fixed-values-table">Typ</td>
            <td className="fixed-values-table">Naliczenie</td>
            <td className="fixed-values-table">Cena netto</td>
            <td className="fixed-values-table">Vat</td>
            <td className="fixed-values-table">Cena brutto</td>
          </tr>
          <tr>
            <td>{serviceChoosen.name}</td>
            <td>{serviceChoosen.type}</td>
            <td>{serviceChoosen.chargeType}</td>
            <td>
              <Form.Control
                {...register("netPrice")}
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
                step="any"
              />
            </td>
            <td>
              <Form.Control
                {...register("vat")}
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td>
              {_.round(
                Number.parseFloat(watch("netPrice")) *
                  ((Number.parseFloat(watch("vat")) + 100) / 100),
                2
              )}
            </td>
          </tr>

          <tr>
            <td className="fixed-values-table" colSpan="4">
              Opis na FV
            </td>
            <td className="fixed-values-table" colSpan="2">
              Kategoria przychodu
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {errors.invLine && (
                <Badge pill variant="warning">
                  {errors.invLine.message}
                </Badge>
              )}
              <Form.Control
                {...register("invLine", { required: "pole wymagane" })}
                as="textarea"
                type=""
                className=""
              />
            </td>
            <td>{"{dataOd}, {dataDo}, {dataZadania}, {nazwaLok}"}</td>
            <td>
              <Form.Control
                {...register("incomeCat")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                {incomeCategories.map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </Form.Control>
            </td>
          </tr>
        </tbody>
      </Table>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Row>
        <Col>
          <Row className="justify-content-start">
            {isNew ? (
              <Button
                variant="danger"
                onClick={() => setModalData({ show: false })}
              >
                Anuluj
              </Button>
            ) : null}
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <Button variant="secondary" type="submit" className="mr-3">
              Zapisz
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}
export default LocationsDetailsServiceEditModal
