import { countCash, getSrvQty } from "./utils"

const prepareLocationsArr = (jobs) => {
  const locationsArr = jobs
    .reduce((acc, red) => {
      // find locInd
      const locInd = acc.findIndex((loc) => loc._id === red.location._id)

      // if not found -> add location with this job to locationsArr
      if (locInd === -1) {
        // reverse coords (in google API: [lng, lat], should be: lat, lng). Clone to prevent changing parent object
        const reversedCoords = [...red.location.coords.coordinates].reverse()
        const newLoc = {
          _id: red.location._id,
          locDetails: {
            address: `${red.location.street} ${red.location.city}`,
            coords: reversedCoords,
            customer: red.location.customer.shortName,
            jobsSuspended: red.location.customer.jobsSuspended,
            name: red.location.name,
            paymentMethod: red.location.customer.defaultPaymentMethod,
            jobsFreq: red.location.jobsFreq || null,
            locNumber:
              red.location.number && red.location.numberYear
                ? `${red.location.numberYear}-${red.location.number}`
                : null,
          },
          qty: 0,
          jobs: [red],
          lastDoneBy: red.lastDoneBy,
          lastDoneOnDay: red.lastDoneOnDay,
          // add order from job if exist, set to 10000 if doesn't to push it down
          order: red.order || 10000,
        }

        // fill location details:

        if (red.location.notes?.length) {
          const directions = red.location.notes.filter(
            (note) => note.type === "na kontrolkę" && note.state === "aktywna"
          )
          newLoc.locDetails.directions = directions
            .map((note) => note.body)
            .join(", ")
        }
        if (red.location.contacts?.length) {
          const contacts = red.location.contacts.filter(
            (ctc) => ctc.type === "na kontrolkę" && ctc.state === "aktywny"
          )
          newLoc.locDetails.contacts =
            contacts
              .map(
                (ctc) =>
                  `${ctc.name}: ${ctc.phone}${ctc.note ? ` - ${ctc.note}` : ""}`
              )
              .join(", ") || null
        }

        if (red.location.customer.defaultPaymentMethod === "Gotówka")
          newLoc.locDetails.cash = countCash(red)

        // add qty: for each service check if it has equipment:
        // y-> use eqp qty
        // n-> find actual service.param and use it's qty
        newLoc.qty = getSrvQty(red)

        return [...acc, newLoc]
        // if found -> add job to this locations jobs array and add qty nad cash
      } else {
        // const newLocArr = acc
        acc[locInd].jobs.push(red)
        acc[locInd].qty += getSrvQty(red)
        if (red.location.customer.defaultPaymentMethod === "Gotówka")
          acc[locInd].locDetails.cash += countCash(red)

        // if job has order compare it to locations order and update if needed
        if (
          red.order &&
          // if location order is null or larger than job order:
          (!acc[locInd].order ||
            acc[locInd].order.orderNumber > red.order.orderNumber)
        ) {
          acc[locInd].order = red.order
        }

        return acc
      }
    }, [])
    .sort((a, b) => a.order - b.order)

  return locationsArr
}

export { prepareLocationsArr }
