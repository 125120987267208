import React, { useContext, useState, useEffect } from "react"
import { Badge, Row, Col, Button, Form, Alert } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"

import { isAfter, addYears, differenceInCalendarDays } from "date-fns"
import { dateParse } from "../../utils/formats"

import { LocationCtx } from "../Fetch"

import { ChooseSrvAndEqp } from "./utils"
import { DeliverySrvAndEqpSelect } from "../../_shared/jobs/SrvAndEqpSelect/delivery"
// import LocationsDetailsEquipmentTable from "../../tables/LocationsDetailsEquipmentTable"

/*
choosing equipment is bit complicated and needs detaild description:
as it comes to state, equipment is arr of objects, each contains equipment _id AND its index in equipment table
thats because I'm using two types of equipment: template (which can be reused in many locations) and real (which can be only in one place)
both are stored in the same db, and are displayed in one table, more details - see tech doc

to make it work I decided to store array as mentionet above. When rendering table I use equipment.map(el=>el.ind) to pass indexes of certain equipment IN THE TABLE
when fetching I use equipment.map(el=>el._id) to create array of _id's

*/
const LocationJobsAdd = (props) => {
  const { location, setModalData, refresh, configs, user } =
    useContext(LocationCtx)

  // when there is only one checkbox RHF treats given name as string (empty or with value), else it creates array.
  // but I need to know if service is selected to show eqp form - when many services I use findIndex, but this causes error when only one service
  // thats why I use:
  const hasOneSrv =
    location.services.filter((srv) => srv.state === "aktywna").length === 1

  const [fetchError, setFetchError] = useState(null)
  const [validateErr, setValidateErr] = useState(null)

  const [deliveryEqpSelected, setDeliveryEqpSelected] = useState([])

  const [jobServices, setJobServices] = useState([]) // used by new (1.13.0) SrvAndEqpSelect components

  const defaultValues = {
    startDate: props.date || dateParse(new Date(), false),
    endDate: props.date || dateParse(addYears(new Date(), 3), false),
    customHours: "false",
    period: props.period || "plug",
    type: "plug",
    hasDriver: location.hasDriver,
    driver: location.hasDriver ? location.driver?._id || null : null, // 'location.driver?._id || null' is for backward compability
    services: location.services
      .filter((srv) => srv.state === "aktywna")
      .map((srv) => {
        const srvDefaults = {
          include: true,
          locationServiceRef: srv._id,
          serviceRef: srv.serviceRef,
          eqp: srv.equipment.map((eqp) => {
            return { ref: eqp.ref._id, qty: eqp.qty }
          }),
        }

        if (srv.canHaveSubSrv === true)
          srvDefaults.subSrv = srv.subSrv.map((subSrv) => subSrv._id)

        return srvDefaults
      }),
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  })

  const watchPeriod = watch("period")

  useEffect(() => {
    if (watchPeriod === "częściej") setValue("weekday", [])
    else setValue("weekday", "")
  }, [watchPeriod, setValue])

  // when type==="dostarczenie" || "zabranie" set period as "jednorazowo"
  const watchType = watch("type")
  useEffect(() => {
    if (watchType === "dostarczenie" || watchType === "zabranie")
      setValue("period", "jednorazowo")
  }, [watchType, setValue])

  if (
    !location.coords?.coordinates?.length ||
    location.services.filter((srv) => srv.state === "aktywna").length === 0
  )
    return (
      <>
        <Alert variant="danger">
          Aby utworzyć zadanie najpierw dodaj współrzędne i wybierz przynajmniej
          jedną usługę
        </Alert>
        <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
      </>
    )

  const submit = async (data) => {
    try {
      // clear previous errors:
      setFetchError(null)
      // when period==="częściej" I use "weekdays" array (checkboxes), else -> "weekday" (radio), but API accepts only one field: weekday which should be ARRAY
      // (thats effect of changes during work, especially when I learned that RHF can't handle changing input type 'radio -> check' on the run)
      // console.log(data)
      const weekday = []
      switch (watchPeriod) {
        // for one-time-job I don't need to have
        case "jednorazowo":
          weekday.push("")
          break
        case "częściej":
          weekday.push(...data.weekdays)
          break
        default:
          weekday.push(data.weekday)
      }

      let body = {
        ...data,
        //setting time to noon  to prevent timezones and summer time problems (worst case scenario out timezone offset is -120):
        startDate: `${data.startDate}T12:00`,
        endDate: `${data.endDate}T12:00`,
        period: watchPeriod, // it should be in data obj, but isn't. Don't know if my mistake ore bug, just setting it here
        location: {
          _id: location._id,
          driver: location.driver,
          branch: location.branch,
          locHistoryLength: location.history.length,
        },
        weekday: weekday,
      }

      //# when normal job -> I have services array from form,
      //# but when pickup I have only one field: service
      // (delivery is handled below)
      if (body.type === "serwis" || body.type === "zabranie") {
        // if pickup (added later, have to fit previous request body schema and create array of services with subarray of eqp):

        // cleaning services from not choosen:
        body.services = body.services.filter((srv) => srv.include)

        // check if any service choosen
        if (!body.services.length)
          return setValidateErr("Wybierz przynajmniej jedną usługę")

        // adding ref to global services collection
        body.services = body.services.map((srv) => {
          // find global location _id:
          const serviceRef = location.services.find(
            (locSrv) => locSrv._id === srv.locationServiceRef
          )
          // I also add serviceRefObj to use later, it won't be saved in mongo
          return {
            ...srv,
            serviceRef: serviceRef.serviceRef,
            serviceRefObj: serviceRef,
          }
        })

        // cleaning choosen services equipment from not choosen:
        body.services.forEach((srv, i) => {
          // when srv without eqp -> create empty array
          // when srv needs to have jobs I prevent this from happening  in form
          body.services[i].eqp = body.services[i].eqp
            ? srv.eqp.filter((eqp) => eqp.ref)
            : []

          //* when service needs to have equipment added - throw error when no eqp

          if (
            body.services[i].serviceRefObj.mustHaveEqp &&
            !body.services[i].eqp.length &&
            !body.services[i].subSrv.length
          )
            throw new Error(
              "Wybierz przynajmniej jeden sprzęt/usługę podrzędną"
            )

          // add qty for equipment that has no qty input in form
          // (mainly numbered eqp like toilets)
          body.services[i].eqp.forEach((eqp, j) => {
            if (!eqp.qty) return (body.services[i].eqp[j].qty = 1)
          })

          // remove service ref object to prevent sending it:
          delete body.services[i].serviceRefObj

          //
          if (body.services[i].subSrv === false) body.services[i].subSrv = []
        })
      }

      //* DELIVERY:
      /*
      on 1.13.0 I added new form to handle srv and eqp choosing for delivery
      it is using jobServices and deliveryEqpSelected states and puts selected service and eqp in it
      And api endpoint should receive job data as to save in mongo, so I need to do major refactor below
      _ also I'm changing request body structure and sending ready-to-save job details in body.jobData, 
      _ and meta data (like history length) in body
      */
      else {
        // I'm sending job data in sub obj body.jobData (see details in comment above)
        const jobData = { ...body }
        body = {
          jobData: jobData,
          locHistoryLength: location.history.length || location.historyLength,
        }

        //* validate service selected:
        if (!jobServices.length)
          return setValidateErr("Wybierz usługę dla dostarczenia")

        body.jobData.services = jobServices

        //* validate eqp selected and its qtys:
        if (!deliveryEqpSelected?.length)
          return setValidateErr("Wybierz sprzęt do dostarczenia")
        if (deliveryEqpSelected.find((eqp) => !eqp.qty))
          return setValidateErr(
            "Liczba dla każdego sprzętu musi być większa od zera"
          )

        // for now delivery can have only one service, so I add eqp to first in arr:
        body.jobData.services[0].eqp = deliveryEqpSelected

        // change startDate to job.date:
        body.jobData.date = body.jobData.startDate
        delete body.jobData.startDate
        delete body.jobData.endDate

        // other props:
        body.jobData.location = body.jobData.location._id
        delete body.jobData.period
        delete body.jobData.weekday
        body.jobData.branch = location.branch
        body.jobData.state = "zaplanowane"
        body.jobData.isOneTimeJob = true

        // return
        // // check if any eqp selected
        // if (!body.equipment)
        //   return setValidateErr("Coś przewieźć trzeba - wybierz sprzęt.")

        // //# delivery/pickup cleaning:
        // body.equipment = body.equipment.filter((eqp) => eqp.ref)

        // // add qty for equipment that has no qty input in form
        // // (mainly numbered eqp like toilets)
        // body.equipment.forEach((eqp, i) => {
        //   if (!eqp.qty) return (body.equipment[i].qty = 1)
        // })

        // // check if any eqp selected - part two ;)
        // if (!body.equipment.length)
        //   return setValidateErr("Coś przewieźć trzeba - wybierz sprzęt.")
      }

      let res

      if (body.type === "serwis" || body.type === "zabranie") {
        res = await fetch("/jobs/batchAdd", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
      }
      if (body.jobData?.type === "dostarczenie") {
        res = await fetch("/jobs/addEqpChangeJob", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        })
      }
      if (res.status === 403)
        return setFetchError((await res.text()) || "Brak uprawnień")
      if (res.status !== 200) {
        console.log(res)
        return setFetchError(
          (await res.text()) || "Błąd komunikacji z serwerem"
        )
      }

      return refresh({
        header: "Sukces",
        body: "zadania dodane",
        headerColor: "bg-success",
      })
    } catch (err) {
      console.log(err)
      setFetchError(err.message || "błąd zapisywania danych")
    }
  }

  const renderWeekDayForm = () => {
    const weekdaysArr = [
      ["1", "pn"],
      ["2", "wt"],
      ["3", "śr"],
      ["4", "cz"],
      ["5", "pt"],
      ["6", "sb"],
      ["0", "nd"],
    ]

    //* render RADIOs:
    if (
      watchPeriod === "co 4 tygodnie" ||
      watchPeriod === "co 3 tygodnie" ||
      watchPeriod === "co 2 tygodnie" ||
      watchPeriod === "raz w tygodniu"
    ) {
      return (
        <>
          <Row className="ml-1">
            <h5>
              {watchPeriod === "częściej" ? "dni tygodnia:" : "dzień tygodnia:"}
              {errors.weekday && (
                <Badge pill variant="warning">
                  Zaznacz zgodnie z wybraną częstotliwością
                </Badge>
              )}
            </h5>
          </Row>
          <Row className="ml-2">
            {weekdaysArr.map((day) => (
              <Form.Check
                inline
                key={`weekdayRadio-${day[0]}`}
                id={`weekdayRadio-${day[0]}`}
                {...register("weekday", { required: true })}
                type={"radio"}
                label={day[1]}
                value={day[0]}
              />
            ))}
          </Row>
        </>
      )
      //* render CHECKBOXes:
    } else if (watchPeriod === "częściej")
      return (
        <>
          <Row className="ml-1">
            <h5>
              Dni tygodnia:
              {errors.weekdays && (
                <Badge pill variant="warning">
                  Zaznacz zgodnie z wybraną częstotliwością
                </Badge>
              )}
            </h5>
          </Row>

          <Form.Check
            name="allDays"
            type="checkbox"
            label="codziennie"
            id="allDays"
            onClick={(e) => {
              if (e.target.checked)
                setValue("weekdays", ["1", "2", "3", "4", "5", "6", "0"])
              else setValue("weekdays", [])
            }}
          />

          <Row className="ml-2">
            {weekdaysArr.map((day) => (
              <Form.Check
                inline
                key={`weekdayCheckbox-${day[0]}`}
                {...register("weekdays", {
                  required: true,
                  validate: {
                    moreThenOne: (v) => {
                      if (v.length < 2) return false
                      else return true
                    },
                  },
                })}
                type={"checkbox"}
                label={day[1]}
                value={day[0]}
                id={`weekdayChcbx-${day[1]}`}
              />
            ))}
          </Row>
        </>
      )
    else return null
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        {user.type.admin ? (
          <Button onClick={() => console.log(watch())}>?</Button>
        ) : null}
        <h5>
          typ:
          {errors.type && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
        </h5>
        <Controller
          control={control}
          name="type"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Form.Control
                {...field}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
                id="jobTypeSelect"
              >
                <option value="plug" hidden={true}>
                  --wybierz--
                </option>
                {configs.jobType.map((type) => {
                  return (
                    <option
                      value={type}
                      key={`jobType-option-${type}`}
                      id={type}
                    >
                      {type}
                    </option>
                  )
                })}
              </Form.Control>
            )
          }}
        />
        <h5>
          <Controller
            control={control}
            name={"hasDriver"}
            id={"hasDriver"}
            render={({ field }) => {
              return (
                <Form.Check
                  inline
                  {...field}
                  type="checkbox"
                  id="hasDriver"
                  checked={field.value}
                  onChange={(e) => {
                    if (e.target.checked) setValue("driver", "plug")
                    else setValue("driver", null)
                    field.onChange(e)
                  }}
                />
              )
            }}
          />{" "}
          kierowca:
          {errors.driver && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
        </h5>
        <Form.Control
          {...register("driver", {
            validate: (v) => {
              if (watch("hasDriver") && (!v || v === "plug")) return false
              else return true
            },
          })}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
          disabled={!watch("hasDriver")}
        >
          {" "}
          <option value="plug" hidden>
            wybierz
          </option>
          {user.drivers.map((driver) => {
            // show only active drivers that match location branch
            if (driver.state === "aktywny" && driver.branch[location.branch])
              return (
                <option value={driver._id} key={`driver-option-${driver._id}`}>
                  {driver.fullName}
                </option>
              )
            else return null
          })}
        </Form.Control>
        <h5>
          częstotliwość:
          {errors.period && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
        </h5>
        <Form.Control
          {...register("period", {
            required: true,
            validate: (v) => v !== "plug",
          })}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
          readOnly={
            watch("type") === "dostarczenie" || watch("type") === "zabranie"
          }
          id="jobPeriodSelect"
        >
          <option value="plug" hidden>
            wybierz
          </option>
          <option value="co 4 tygodnie">co 4 tygodnie</option>
          <option value="co 3 tygodnie">co 3 tygodnie</option>
          <option value="co 2 tygodnie">co 2 tygodnie</option>
          <option value="raz w tygodniu">raz w tygodniu</option>
          <option value="częściej">częściej</option>
          <option value="jednorazowo">jednorazowo</option>
        </Form.Control>
        {renderWeekDayForm()}
        <Row className="my-3">
          <Col>
            <h5>
              data {watchPeriod === "jednorazowo" ? "wykonania" : "rozpoczęcia"}
              {errors.startDate && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
            </h5>
            <Form.Control
              {...register("startDate", { required: true })}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Col>
          <Col>
            {watchPeriod && watchPeriod !== "jednorazowo" ? (
              <>
                <h5>
                  data zakończenia:{" "}
                  {errors.endDate && (
                    <Badge pill variant="warning">
                      {errors.endDate.message}
                    </Badge>
                  )}
                </h5>
                <Form.Control
                  {...register("endDate", {
                    required: "Pole wymagane",
                    validate: {
                      cantBeBeforeStart: (val) => {
                        return (
                          isAfter(
                            new Date(val),
                            new Date(getValues("startDate"))
                          ) ||
                          "Nieprawidłowa data - wcześniejsza od daty rozpoczęcia"
                        )
                      },
                      cantBeMoreThen3Years: (val) => {
                        return (
                          differenceInCalendarDays(
                            new Date(val),
                            new Date(getValues("startDate"))
                          ) < 1100 ||
                          "Nieprawidłowa data - zadania maks na 3 lata w przód"
                        )
                      },
                    },
                  })}
                  as="input"
                  type="date"
                  className=""
                  autoComplete="chrome-off"
                  id="endDate"
                />
              </>
            ) : null}
          </Col>
        </Row>
        <h5>
          określić godziny?{" "}
          {errors.customHours && (
            <Badge pill variant="warning">
              Wybierz
            </Badge>
          )}
        </h5>
        <Form.Check
          inline
          {...register("customHours")}
          type="radio"
          label="tak"
          value="true"
          id="customHoursRadio-true"
        />
        <Form.Check
          inline
          {...register("customHours", { required: true })}
          type="radio"
          label="nie"
          value="false"
        />
        {watch("customHours") === "true" ? (
          <Row>
            <Col>
              <h5>
                od:{" "}
                {errors.startTime && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </h5>
              <Form.Control
                {...register("startTime", { required: true })}
                as="input"
                type="time"
                className=""
                autoComplete="chrome-off"
                id="startTime"
              />
            </Col>
            <Col>
              <h5>
                do:{" "}
                {errors.endTime?.type === "required" && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
                {errors.endTime?.type === "cantBeBeforeStart" && (
                  <Badge pill variant="warning">
                    zakończenie przed rozpoczęciem, serio?
                  </Badge>
                )}
              </h5>
              <Form.Control
                {...register("endTime", {
                  required: true,
                  validate: {
                    cantBeBeforeStart: (val) => {
                      const startH = getValues("startTime").slice(0, 2)
                      const startM = getValues("startTime").slice(3, 5)
                      const endH = val.slice(0, 2)
                      const endM = val.slice(3, 5)
                      if (endH > startH) return true
                      if (endH === startH && endM > startM) return true
                      else return false
                    },
                  },
                })}
                id="endTime"
                as="input"
                type="time"
                className=""
                autoComplete="chrome-off"
              />
            </Col>
          </Row>
        ) : null}
        <h5>uwagi:</h5>
        <Form.Control
          {...register("comments")}
          as="textarea"
          type=""
          className="mb-2"
          autoComplete="chrome-off"
          id="jobComments"
        />
        {/* <LocationsDetailsEquipmentTable
        chooseEquipment={chooseEquipment}
        // send array of equipment table indexes of choosen equipment
        choosen={equipment.map((eqp) => eqp.ind)}
      /> */}
        <hr />
        <h4>Usługi i sprzęt:</h4>
        {/* SERVICES AND EQUIPMENT */}

        {(watch("type") === "serwis" || watch("type") === "zabranie") && (
          <ChooseSrvAndEqp
            location={location}
            register={register}
            watch={watch}
            control={control}
            Controller={Controller}
            hasOneSrv={hasOneSrv}
            setValue={setValue}
            jobType={watch("type")}
          />
        )}
      </Form>
      {watch("type") === "dostarczenie" && (
        <DeliverySrvAndEqpSelect
          locServices={location.services}
          services={jobServices}
          setServices={setJobServices}
          eqpSelected={deliveryEqpSelected}
          setEqpSelected={setDeliveryEqpSelected}
        />
      )}
      <Row>
        {validateErr && <Alert variant="warning">{validateErr}</Alert>}
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      </Row>
      <Row className="justify-content-between mt-3">
        <Button
          variant="warning"
          className="ml-3"
          onClick={() => setModalData({ show: false })}
        >
          Anuluj
        </Button>
        <Button
          variant="secondary"
          className="mr-3"
          id="submitBtn"
          onClick={() => submit(watch())}
        >
          Dodaj
        </Button>
      </Row>
    </>
  )
}
export default LocationJobsAdd
