import React, { useState, useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import { Redirect } from "react-router-dom"

import UserContext from "../contexts/userContext"

import ConsumptionAddRender from "./consumptionAddRender"

const ConsumptionParent = () => {
  const [cookies] = useCookies()
  const [isLoading, setisLoading] = useState(true)
  const user = useContext(UserContext)
  const [noPerm] = useState(
    user.type.admin || user.perm.consumption.w ? false : true
  )
  const [goods, setGoods] = useState()
  const [employees, setEmployees] = useState()

  const [dateToDisplay, setDateToDisplay] = useState({
    month: 1,
    year: new Date().getFullYear(),
  })

  useEffect(() => {
    const fetchGoods = async () => {
      try {
        const res = await fetch("/goods/all", {
          headers: {
            authorization: cookies.jwt,
          },
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        resJSON.sort((a, b) => a.priority - b.priority)
        setGoods(resJSON)
      } catch (err) {
        alert(err)
      }
    }
    const fetchEmployees = async () => {
      try {
        const res = await fetch("/employees/all", {
          headers: {
            authorization: cookies.jwt,
          },
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        setEmployees(resJSON)
      } catch (err) {
        alert(err)
      }
    }

    Promise.all([fetchEmployees(), fetchGoods()]).then(() =>
      setisLoading(false)
    )

    // set date to display in consumptionMainTable (if today is > 10 -> display todays month, else display previous):
    const setDate = () => {
      const today = new Date()
      if (today.getMonth() === 0 && today.getDate() < 10) {
        const dateObj = { month: 12, year: today.getFullYear() - 1 }
        setDateToDisplay(dateObj)
      } else if (today.getDate() > 10) {
        setDateToDisplay((dateToDisplay) => {
          return { ...dateToDisplay, month: today.getMonth() + 1 }
        })
      } else {
        setDateToDisplay((dateToDisplay) => {
          return { ...dateToDisplay, month: today.getMonth() }
        })
      }
    }
    setDate()
  }, [cookies.jwt])

  return (
    <div>
      <br />
      <p style={{ background: "yellow" }}>
        Proszę nie używać tego komponentu, zamiast tego użyć "Magazyny {"->"}{" "}
        Zużycie" z menu nawigacji! W razie problemów proszę o kontakt.
      </p>
      {noPerm ? <Redirect to="/" /> : null}
      {isLoading ? (
        <p>wczytuję</p>
      ) : (
        <ConsumptionAddRender
          goods={goods}
          employees={employees}
          dateToDisplay={dateToDisplay}
        />
      )}
    </div>
  )
}
export default ConsumptionParent
