import _ from "lodash"
import { Row, Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { format, addDays } from "date-fns"

const LogsForm = ({ employees, setIsLoading, setLogs, setModalData }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      startDate: format(addDays(new Date(), -1), "yyyy-MM-dd'T'HH:mm"),
      endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      method: "all",
      url: null,
      employee: "any",
    },
  })

  const submit = async (formData) => {
    try {
      const res = await fetch(
        `/logs/getFiltered?startDate=${formData.startDate}&endDate=${formData.endDate}&method=${formData.method}&url=${formData.url}&employee=${formData.employee}&logger=${formData.logger}`
      )
      if (res.status === 403) return alert(await res.text())
      if (res.status !== 200) throw res
      const resJSON = await res.json()

      setLogs(_.reverse(resJSON))
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      return alert("ups")
    }
  }

  return <>
    <Row>
      <Form inline onSubmit={handleSubmit(submit)}>
        <Form.Control
          {...register('startDate')}
          as="input"
          type="datetime-local"
          className=""
          autoComplete="chrome-off" />
        <Form.Control
          {...register('endDate')}
          as="input"
          type="datetime-local"
          className=""
          autoComplete="chrome-off" />
        method:
        <Form.Control
          {...register('method')}
          as="select"
          className=""
          autoComplete="chrome-off">
          <option value="all">all</option>
          <option value="GET">GET</option>
          <option value="PUT">PUT</option>
          <option value="POST">POST</option>
          <option value="DELETE">DELETE</option>
        </Form.Control>
        logger:
        <Form.Control
          {...register('logger')}
          as="select"
          className=""
          autoComplete="chrome-off">
          <option value="any">any</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </Form.Control>
        url
        <Form.Control
          {...register('url')}
          as="input"
          type="text"
          className=""
          autoComplete="chrome-off" />
        usr:
        <Form.Control
          {...register('employee')}
          as="select"
          className=""
          autoComplete="chrome-off">
          <option value="any">any</option>
          {employees.map((empl) => (
            <option value={empl._id} key={`empl-select-opt-${empl._id}`}>
              {empl.fullName}
            </option>
          ))}
        </Form.Control>
        <Button type="submit">Pobierz</Button>
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              body: "url - matches any including via regex",
            })
          }
        >
          ke?
        </Button>
      </Form>
    </Row>
  </>;
}

export default LogsForm
