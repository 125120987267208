import { isAfter } from "date-fns"
import React, { useContext, useState, useEffect } from "react"
import { Container, Button } from "react-bootstrap"

import UserContext from "../../../contexts/userContext"
import MyModal from "../../../utils/Modals"
import renderSpinner from "../../../utils/renderSpinner"

import { SewageReportv2NewReportModal } from "../newReportModal"
import { SewageReportv2TableRender } from "./render"
const SewageReport_v2TableFetch = () => {
  const [modalData, setModalData] = useState({ show: false })
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [reportsList, setReportsList] = useState([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await fetch("/sewage/getReports_v2")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych: ${res.status} - ${await res.text()}`
          )
        const resJSON = await res.json()
        const reports = resJSON.sort((el1, el2) =>
          isAfter(new Date(el1.LastModified), new Date(el2.LastModified))
            ? -1
            : 1
        )
        setReportsList(reports)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [refreshCounter])

  const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Container id="SewageReportsRender">
      <Button
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            body: (
              <SewageReportv2NewReportModal
                user={user}
                setModalData={setModalData}
                refresh={refresh}
              />
            ),
            hideFooter: true,
          })
        }
      >
        Generuj
      </Button>
      {isLoading ? (
        renderSpinner()
      ) : (
        <SewageReportv2TableRender
          reportsList={reportsList}
          setModalData={setModalData}
          refresh={refresh}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export { SewageReport_v2TableFetch }
