import { useState, useEffect, useMemo } from "react"
import { Table, Form, Button, Row, Alert, Spinner } from "react-bootstrap"
import { GrLinkBottom, GrLinkTop, GrLinkUp, GrLinkDown } from "react-icons/gr"

import _ from "lodash"
import { createManualBtn } from "../../../utils/buttons"
import { cutString } from "../../../utils/cutString"
import { getJobNotes } from "../../../_shared/jobs/getJobNotes"

const ChangeOrderModal = ({ jobs, refresh }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const [jobsCloned, setJobsCloned] = useState(
    sortJobs(jobs) // it clones jobs and rewrites order to keep it continuous and consistent
  )

  //TODO:
  /* 
- add submit btns
- add alert about recalculating order
- show job hours if present
- show job notes in popup?
  - read again ERP-772
  */

  const changeOrder = (job_id, newOrder) =>
    handleReorder(job_id, newOrder, setJobsCloned)

  // Update order input after each change (and set default values on initial render):
  useEffect(() => {
    jobsCloned.forEach((job) => {
      document.getElementById(`orderInput-${job._id}`).value = job.order
    })
  }, [jobsCloned])

  const handleSave = async (applyToFutureJobs) => {
    try {
      setIsFetching(true)

      const res = await fetch("/jobs/reorder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobs: jobsCloned,
          applyToFutureJobs: applyToFutureJobs,
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      return refresh()
    } catch (err) {
      console.log(err)
      setFetchError(err.message)
      setIsFetching(false)
    }
  }

  return (
    <div>
      <Row className="justify-content-end mr-2 mb-3">
        <div className="mr-4">{createManualBtn("changeOrder")}</div>
        <Button
          onClick={() => handleSave(false)}
          disabled={isFetching}
          variant="secondary"
          className="mr-2"
        >
          {isFetching ? <Spinner animation="border" /> : "Zapisz ten dzień"}
        </Button>
        <Button
          onClick={() => handleSave(true)}
          disabled={isFetching}
          variant="secondary"
          className="mr-2"
        >
          {isFetching ? <Spinner animation="border" /> : "Zapisz na przyszłość"}
        </Button>
      </Row>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Row>
        <OrderTable
          jobs={jobsCloned}
          setJobsCloned={setJobsCloned}
          changeOrder={changeOrder}
        />
      </Row>
    </div>
  )
}

const OrderTable = ({ jobs, setJobsCloned, refreshSorting, changeOrder }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th
            colSpan="5"
            // putting arrows buttons in separate table columns seems to be easist way to keep them inline
          >
            Kolejność
          </th>
          <th>Typ</th>
          <th>Klient</th>
          <th>Lokalizacja</th>
          <th>Adres</th>
          <th>Uwagi</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <JobRow
            job={job}
            setJobsCloned={setJobsCloned}
            key={`JobRowComp-${job._id}`}
            refreshSorting={refreshSorting}
            changeOrder={changeOrder}
          />
        ))}
      </tbody>
    </Table>
  )
}

const JobRow = ({ job, setJobsCloned, refreshSorting, changeOrder }) => {
  const jobNotes = useMemo(() => getJobNotes(job), [job])

  const btns = [
    { icon: <GrLinkTop />, onClick: () => changeOrder(job._id, 1) },
    { icon: <GrLinkUp />, onClick: () => changeOrder(job._id, job.order - 1) },
    {
      icon: <GrLinkDown />,
      onClick: () => changeOrder(job._id, job.order + 1),
    },
    {
      icon: <GrLinkBottom />,
      onClick: () => changeOrder(job._id, job.order + 10000),
    },
  ]

  // get job notes from hours, job notes and location notes

  return (
    <tr>
      <td>
        <Form.Control
          as="input"
          type="number"
          className="no-arrows"
          style={{ width: "3rem" }}
          step="1"
          autoComplete="chrome-off"
          // update jobs and refresh table on blur or enter press:
          onBlur={(e) => changeOrder(job._id, e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter")
              changeOrder(job._id, e.target.value)
          }}
          // select all text on focus
          onFocus={(e) => {
            e.target.select()
          }}
          id={`orderInput-${job._id}`}
        />
      </td>
      {btns.map((btn, i) => (
        <td key={`jobRow-arrowBtn-${job._id}-${i}`} className="px-0">
          <Button size="sm" onClick={btn.onClick}>
            {btn.icon}
          </Button>
        </td>
      ))}
      <td>{job.type}</td>
      <td>{job.location.customer.shortName}</td>
      <td>{job.location.name}</td>
      <td>
        {job.location.street} {job.location.city}
      </td>
      <td>{cutString(jobNotes, 20)}</td>
    </tr>
  )
}

const sortJobs = (jobs) => {
  const jobsCloned = _.cloneDeep(jobs)

  // on initial there can be jobs without order, I want to keep order of jobs that has order and add order to jobs without it
  // first get jobs with order, sort them and rewrite order to keep it continuous
  //(I'm working directly on jobsCloned array as all methods should use reference of job)
  const jobsWithOrder = jobsCloned
    .filter((job) => !!job.order)
    .sort((a, b) => a.order - b.order)

  jobsWithOrder.forEach((job, i) => (job.order = i + 1))

  // for remaining jobs add order starting with last order + 1
  const jobsWithoutOrder = jobsCloned.filter((job) => !job.order)
  jobsWithoutOrder.forEach(
    (job, i) => (job.order = (jobsWithOrder?.length || 0) + i + 1)
  )

  return [...jobsWithOrder, ...jobsWithoutOrder]
}

const handleReorder = (job_id, newOrder, setJobsCloned) => {
  // check if new order is number
  newOrder = parseInt(newOrder)
  if (isNaN(newOrder)) {
    newOrder = 0
  }

  return setJobsCloned((jobsOrg) => {
    // clone jobs to force rerender:
    const jobs = _.cloneDeep(jobsOrg)

    // if new order is greater then max order, send it to bottom
    if (newOrder > jobs.length) {
      newOrder = jobs.length
    }
    // if new order is less then min order, send it to top
    if (newOrder < 1) {
      newOrder = 1
    }

    const jobToChange = jobs.find((job) => job._id === job_id)

    // return unchanged jobs if order didn't change:

    if (jobToChange.order === newOrder) {
      return jobs
    }

    if (newOrder > jobToChange.order) {
      jobs.forEach((job) => {
        if (job.order > jobToChange.order && job.order <= newOrder) {
          job.order--
        }
      })
    }
    if (newOrder < jobToChange.order) {
      jobs.forEach((job) => {
        if (job.order < jobToChange.order && job.order >= newOrder) {
          job.order++
        }
      })
    }

    jobToChange.order = newOrder

    const jobsSorted = sortJobs(jobs)

    return jobsSorted // have to clone to fire rerender
  })
}

export { ChangeOrderModal }
