import React, { useState, useEffect, useContext } from "react"
import { Container, Modal } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MetaContext from "../../contexts/metaContext"

import MyModal from "../../utils/Modals"

import { createSummary } from "./utils"

import RLineSellReportForm from "./Form"
import RLineSellReportRender from "./Render"

const RLineSellReportFetch = () => {
  const user = useContext(UserContext)
  const meta = useContext(MetaContext)

  const [formParams, setFormParams] = useState(null)
  const [modalData, setModalData] = useState({ show: false })
  const [transactions, setTransactions] = useState(null)
  const [rows, setRows] = useState(null)
  const [isFiltered, setIsFiltered] = useState(false) // set true if any filter applied (appart from date)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const userBranches = Object.keys(user.branch).filter(
          (branch) => user.branch[branch]
        )

        // set default dates:
        const today = new Date()

        const startDate = `${
          // check if now is January, if so -> decrement year
          today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear()
          // check if today is January, if so -> set startDate month to December, else use previous month. Also add padding "0"
        }-${
          today.getMonth() === 0
            ? 12
            : today.getMonth().toString().padStart(2, "0")
        }-01`

        let monthEndDate = new Date(today.getFullYear(), today.getMonth(), 0)

        const endDate =
          monthEndDate.getFullYear() +
          "-" +
          // month with padding "0"
          (monthEndDate.getMonth() + 1).toString().padStart(2, "0") +
          "-" +
          monthEndDate.getDate()

        const res = await fetch("/employees/all") // api is sending only employees that are assigned to branch that user has perms to
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        const employees = resJSON.filter((employee) => employee.type.user)
        setFormParams({
          employees: employees,
          branches: userBranches,
          defaultDates: { startDate: startDate, endDate: endDate },
        })
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    fetchData()
  }, [user, meta])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      // resseting states (to handle reloading report with different criteria)
      setIsLoading(true)
      setIsFiltered(false)
      setRows(null)
      setTransactions(null)

      // checking if any filters (apart from dates) applied. I tried to use react state, but I got problem with stale state (it didn't update fast enought to use it in conditionals below)
      let localIsFiltered = false
      if (
        e.target.seller.value !== "all" ||
        e.target.branch.value !== "all" ||
        e.target.employee.value !== "all"
      ) {
        localIsFiltered = true
        setIsFiltered(true)
      }

      const query = `startDate=${e.target.startDate.value}&endDate=${e.target.endDate.value}&seller=${e.target.seller.value}&branch=${e.target.branch.value}&employee=${e.target.employee.value}`

      const res = await fetch(`/transactions/sellReport?${query}`)
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res
      const resJSON = await res.json()

      setTransactions(resJSON)

      const data = {}

      // prepare summary of queried transactions:
      data.filtered = await createSummary(resJSON.filtered)

      // check if any filter applied  and prepare summary if so:
      if (localIsFiltered) data.all = await createSummary(resJSON.all)

      setRows(data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd pobierania danych",
      })
    }
  }

  return (
    <Container fluid>
      {formParams ? (
        <RLineSellReportForm
          handleSubmit={handleSubmit}
          formParams={formParams}
        />
      ) : (
        <p>przygotowuję formularz...</p>
      )}
      {isLoading ? null : (
        <RLineSellReportRender
          transactions={transactions}
          rows={rows}
          isFiltered={isFiltered}
          setIsLoading={setIsLoading}
        />
      )}
      <Modal
        show={modalData.show}
        onHide={() => setModalData({ show: false })}
        animation={false}
      >
        <MyModal modalData={modalData} setModalData={setModalData} />
      </Modal>
    </Container>
  )
}
export default RLineSellReportFetch
