import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import {
  Container,
  Row,
  Button,
  Form,
  Badge,
  Alert,
  Table,
} from "react-bootstrap"
import { ImNewTab } from "react-icons/im"
import { format } from "date-fns"

import { submitJobsBatchEdit } from "../submit"

const RoutesPlanningStateChangeModal = (props) => {
  const { jobs, refresh, setModalData, user, branchSelected } = props
  const [fetchError, setFetchError] = useState(false)

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      doneComments: null,
      newDriver: null,
      addInvNote: true,
      invNote: "",
    },
  })

  const watchState = watch("state")

  const submit = async (formData) => {
    try {
      const batchJobsEditData = {
        jobs: jobs.map((job) => {
          return { _id: job._id, historyLength: job.history.length }
        }),
        modifications: {},
        srvToClose: [],
      }

      // if changing driver:
      if (formData.changeDriver)
        batchJobsEditData.modifications.driver = {
          apply: true,
          newValue: formData.newDriver,
        }

      //# if selected jobs are done -> just fetch change (and set next job comment if entered)

      if (formData.state === "wykonane")
        batchJobsEditData.modifications.state = {
          apply: true,
          newValue: "wykonane",
        }
      //* jobs not done:
      else {
        batchJobsEditData.modifications.state = {
          apply: true,
          newValue: `${formData.state} (${formData.blame})`,
        }

        // if adding invoice note:
        if (formData.addInvNote === true)
          batchJobsEditData.modifications.invNote = formData.invNote
      }
      // if adding jobs doneComments
      if (watch("doneComments")) {
        batchJobsEditData.modifications.doneComments = {
          apply: true,
          newValue: getValues("doneComments"),
        }
      }

      // if adding comments to next job
      if (watch("nextJobComments"))
        batchJobsEditData.nextJobComments = getValues("nextJobComments")

      // if adding new job
      if (watch("addNewJob")) {
        batchJobsEditData.newJob = {
          date: `${formData.newJobDate}T12:00`,
          comments: formData.newJobComments,
        }
      }

      await submitJobsBatchEdit(
        batchJobsEditData,
        setFetchError,
        setModalData,
        refresh,
        jobs
      )
    } catch (err) {
      console.log(err)
      setFetchError("Błąd komunikacji z serwerem")
    }
  }

  if (jobs.length === 0)
    return <Alert variant="warning">brak zadań do edycji</Alert>
  else
    return (
      <Container>
        {/* when one job -> show details, else show simplified list */}
        {jobs.length === 1 ? (
          <Table bordered>
            <thead>
              <tr>
                <th colSpan="4">Zadanie:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixed-values-table">Klient</td>
                <td>
                  <a
                    href={`/CustomersDetails/${jobs[0].location.customer._id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {jobs[0].location.customer.shortName} <ImNewTab />
                  </a>
                </td>
                <td className="fixed-values-table">Lokalizacja</td>
                <td>
                  <a
                    href={`/Locations/${jobs[0].location._id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {jobs[0].location.street}, {jobs[0].location.city}
                    <ImNewTab />
                  </a>{" "}
                  {jobs[0].location.jobsFreq || ""}
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table">Typ</td>
                <td>{jobs[0].type}</td>
                <td className="fixed-values-table">Stan</td>
                <td>{jobs[0].state}</td>
              </tr>
              <tr>
                <td className="fixed-values-table">Kierowca</td>
                <td>{jobs[0].driver?.fullName || "BRAK"}</td>
                <td className="fixed-values-table">Godziny</td>
                <td>
                  {jobs[0].customHours
                    ? `${jobs[0].startTime} - ${jobs[0].endTime}`
                    : "Nie"}
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table" colSpan="4">
                  Uwagi
                </td>
              </tr>
              <tr>
                <td colSpan="4">{jobs[0].comments}</td>
              </tr>

              <tr>
                <td className="fixed-values-table" colSpan="4">
                  Usługi i sprzęt
                </td>
              </tr>
              <tr>
                {jobs[0].services.map((srv) => {
                  return (
                    <ul>
                      <li>
                        {srv.serviceRef.name}
                        {srv.eqp.length ? (
                          <ul>
                            {srv.eqp.map(
                              (eqp) =>
                                `${eqp.ref.type} ${
                                  eqp.ref.isTemplate
                                    ? "templatka"
                                    : eqp.ref.number
                                }`
                            )}
                          </ul>
                        ) : null}
                      </li>
                    </ul>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        ) : (
          <>
            <b>Zadania:</b>
            <ul>
              {jobs.map((job) => (
                <li key={`job-list-${job._id}`}>
                  {job.type} na{" "}
                  <a
                    href={`/Locations/${job.location._id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {job.location.street}, {job.location.city} <ImNewTab />
                  </a>{" "}
                  (
                  <a
                    href={`/CustomersDetails/${job.location.customer._id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {job.location.customer.shortName} <ImNewTab />
                  </a>
                  ), {job.location.jobsFreq || ""},{" "}
                  {job.driver?.fullName || "BŁĄD - brak kierowcy"}
                </li>
              ))}
            </ul>
          </>
        )}

        <Form onSubmit={handleSubmit(submit)}>
          <Table bordered>
            <tbody>
              <tr>
                <td className="fixed-values-table">
                  <b>
                    Oznacz jako:{" "}
                    {errors.state && (
                      <Badge pill variant="warning">
                        Pole wymagane
                      </Badge>
                    )}
                  </b>
                </td>
                <td>
                  <Form.Check
                    inline
                    {...register("state")}
                    type="radio"
                    label="wykonane"
                    value="wykonane"
                    id="state.wykonane"
                  />
                  <Form.Check
                    inline
                    {...register("state")}
                    type="radio"
                    label="niewykonane"
                    value="niewykonane"
                    id="state.niewykonane"
                  />
                  <Form.Check
                    inline
                    {...register("state", { required: true })}
                    type="radio"
                    label="odwołane"
                    value="odwołane"
                    id="state.odwołane"
                  />
                </td>
              </tr>

              {/* if job undone -> who to blame? */}
              {watchState && watchState !== "wykonane" && (
                <tr>
                  <td className="fixed-values-table">
                    Z czyjej winy?{" "}
                    {errors.blame && (
                      <Badge pill variant="warning">
                        Pole wymagane
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Form.Check
                      inline
                      {...register("blame")}
                      type="radio"
                      label="naszej"
                      value="my"
                      id="blame.my"
                    />
                    <Form.Check
                      inline
                      {...register("blame", { required: true })}
                      type="radio"
                      label="klienta"
                      value="klient"
                      id="blame.klient"
                    />
                  </td>
                </tr>
              )}

              {/* user can change driver: */}

              {watchState && (
                <tr>
                  <td className="fixed-values-table">
                    <Controller
                      name={"changeDriver"}
                      control={control}
                      render={({ field }) => (
                        <Form.Check
                          {...field}
                          type="checkbox"
                          label="Zmień kierowcę"
                          value={true}
                          id="changeDriver"
                          onChange={(e) => {
                            setValue("newDriver", null)
                            field.onChange(e)
                          }}
                        />
                      )}
                    />
                    {errors.newDriver && (
                      <Badge pill variant="warning">
                        Pole wymagane
                      </Badge>
                    )}
                  </td>
                  <td>
                    <Form.Control
                      {...register("newDriver", {
                        validate: (val) => {
                          if (getValues("changeDriver") && !val) return false
                          else return true
                        },
                      })}
                      as="select"
                      className=""
                      autoComplete="chrome-off"
                      disabled={watch("changeDriver") ? false : true}
                    >
                      {user.drivers.map((driver) => {
                        if (
                          driver.state === "aktywny" &&
                          driver.branch[branchSelected]
                        )
                          return (
                            <option
                              key={`driver-select-${driver._id}`}
                              value={driver._id}
                            >
                              {driver.fullName}
                            </option>
                          )
                        else return null
                      })}
                    </Form.Control>
                  </td>
                </tr>
              )}

              {/* doneComments: required when job undone (state==="niewykonane" or "odwołane") */}
              {watchState && (
                <>
                  <tr>
                    <td className="fixed-values-table" colSpan="2">
                      Komentarz do realizacji{" "}
                      {errors.doneComments && (
                        <Badge pill variant="warning">
                          Pole wymagane
                        </Badge>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <Form.Control
                        {...register("doneComments", {
                          validate: (val) => {
                            if (watchState !== "wykonane" && !val) return false
                            else return true
                          },
                        })}
                        as="input"
                        type=""
                        className=""
                        autoComplete="chrome-off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fixed-values-table" colSpan="2">
                      Uwagi do następnego zadania na lokalizacji (zostaną{" "}
                      <u>dopisane</u> do istniejących uwag)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <Form.Control
                        {...register("nextJobComments")}
                        as="input"
                        type=""
                        className=""
                        autoComplete="chrome-off"
                      />
                    </td>
                  </tr>
                </>
              )}

              {/* if job undone -> user can add new  */}
              {watchState && watchState !== "wykonane" && (
                <>
                  <tr>
                    <td className="fixed-values-table">
                      {errors.addNewJob && (
                        <Badge pill variant="warning">
                          {errors.addNewJob.message}
                        </Badge>
                      )}
                      <Controller
                        name={"addNewJob"}
                        control={control}
                        rules={{
                          // when delivery - user has to plan new one (see ERP-463)
                          validate: (v) => {
                            if (
                              !v &&
                              jobs.filter((job) => job.type === "dostarczenie")
                                .length
                            )
                              return (
                                <p>
                                  W przypadku dostarczenia konieczne jest
                                  zaplanowanie zadania zastępczego.
                                  <br />
                                  Odwołanie/niewykonanie dostarczenia możliwe
                                  jest z poziomu lokalizacji.
                                  <br />
                                  Szczegóły w instrukcji.
                                </p>
                              )
                            else return true
                          },
                        }}
                        render={({ field }) => (
                          <Form.Check
                            {...field}
                            type="checkbox"
                            label="Zaplanuj nowe zadanie"
                            value={true}
                            id="addNewJob"
                            onChange={(e) => {
                              setValue("newJobDate", null)
                              field.onChange(e)
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      {errors.newJobDate && (
                        <Badge pill variant="warning">
                          Pole wymagane
                        </Badge>
                      )}
                      <Form.Control
                        {...register("newJobDate", {
                          validate: (val) => {
                            if (getValues("addNewJob") && !val) return false
                            else return true
                          },
                        })}
                        as="input"
                        type="date"
                        className=""
                        autoComplete="chrome-off"
                        disabled={watch("addNewJob") ? false : true}
                      />
                    </td>
                  </tr>
                  {watch("addNewJob") && (
                    <>
                      <tr>
                        <td colSpan="2">
                          <Alert variant="warning">
                            Parametry nowego zadania zostaną przeniesione z
                            niewykonanego. Poniższe uwagi zostaną{" "}
                            <u>dopisane</u> do uwag z niewykonanego zadania.
                          </Alert>
                        </td>
                      </tr>
                      <tr>
                        <td className="fixed-values-table" colSpan="2">
                          Uwagi
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <Form.Control
                            {...register("newJobComments")}
                            as="input"
                            type=""
                            className=""
                            autoComplete="chrome-off"
                          />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className="fixed-values-table">
                      <Form.Check
                        {...register("addInvNote")}
                        type="checkbox"
                        label="Dodaj notatkę fakturową"
                        id="addInvNote"
                      />
                    </td>
                    <td>
                      {watch("addInvNote") ? (
                        <>
                          "Zadanie/zadania z dnia{" "}
                          {format(new Date(jobs[0].date), "yyyy-MM-dd")} -{" "}
                          {watchState}({watch("blame")}): + (
                          <i>poniżej dopisz dodatkowy komentarz</i>)
                          <Form.Control
                            {...register("invNote")}
                            id="invNote"
                            as="input"
                            type=""
                            className=""
                            autoComplete="chrome-off"
                            readOnly={!watch("addInvNote")}
                          />
                        </>
                      ) : (
                        <Badge variant="secondary">
                          Niewykonanie zadania może mieć wpływ na fakturę -
                          rozważ dodanie notatki
                        </Badge>
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {fetchError && <Alert variant="danger">{fetchError}</Alert>}
          <Row className="justify-content-between">
            <Button onClick={() => setModalData({ show: false })}>
              Anuluj
            </Button>
            {user.type.admin ? (
              <Button onClick={() => console.log(getValues())}>?</Button>
            ) : null}
            <Button type="submit">Zapisz</Button>
          </Row>
        </Form>
      </Container>
    )
}
export default RoutesPlanningStateChangeModal
