import React, { useContext } from "react"
import { Button, Container, Row, Col } from "react-bootstrap"

import { CustomerCtx } from "../Fetch"
import CustomersDetailsAddressesTable from "../tables/AddressesTable"
import CustomersAddressEditModal from "../entities/CustomersAddressEditModal"

const CustomersDetailsAddressesTab = () => {
  const { customer, setModalData } = useContext(CustomerCtx)
  return (
    <Container>
      <CustomersDetailsAddressesTable
        tableHeader="Główny adres"
        addresses={customer.addresses.filter(
          (adr) => adr.type === "główny" && adr.state === "aktywny"
        )}
      />
      <CustomersDetailsAddressesTable
        tableHeader={
          <Row>
            <Col>Pozostałe adresy</Col>
            {customer.state === "aktywny" ? (
              <Col>
                <Row className="justify-content-end">
                  <Button
                    variant="secondary"
                    className="mr-3"
                    size="sm"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: <CustomersAddressEditModal address="new" />,
                      })
                    }
                  >
                    Dodaj
                  </Button>
                </Row>
              </Col>
            ) : null}
          </Row>
        }
        addresses={customer.addresses.filter(
          (adr) => adr.type !== "główny" && adr.state === "aktywny"
        )}
      />
      <CustomersDetailsAddressesTable
        tableHeader="Adresy archiwalne"
        addresses={customer.addresses.filter((adr) => adr.state === "archiwum")}
      />
    </Container>
  )
}
export default CustomersDetailsAddressesTab
