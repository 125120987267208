import React, { useMemo } from "react"
import { Container, Table } from "react-bootstrap"
import { format, isWithinInterval, endOfDay, isAfter } from "date-fns"

const InvModsTable = ({
  locations,
  srvsChoosen,
  startDate,
  endDate,
  multiLoc,
}) => {
  return (
    <Container className="mt-4">
      <h4>Modyfikacje faktur dla wybranego okresu i usług:</h4>

      <Table>
        <thead>
          <tr>
            <th>Kwota</th>
            <th>Usługa</th>
            <th>Data</th>
            <th>Dodał</th>
            <th>Info na fakturę</th>
            <th>Opis</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => (
            <InvModsLocTable
              multiLoc={multiLoc}
              location={location}
              srvsChoosen={srvsChoosen}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  )
}

const InvModsLocTable = ({
  multiLoc,
  location,
  startDate,
  endDate,
  srvsChoosen,
}) => {
  const invMods = useMemo(() => {
    // get only invMods that are "oczekujące" AND within choosen interval AND matches services choosen for invoice
    return location.invMods?.filter(
      (invMod) =>
        invMod.state === "oczekująca" &&
        // before checking interval check if end date is after start to prevent fns error:
        isAfter(new Date(endDate), new Date(startDate)) &&
        isWithinInterval(new Date(invMod.applyDate), {
          start: new Date(startDate),
          end: endOfDay(new Date(endDate)),
        }) &&
        srvsChoosen.map((srv) => srv._id).includes(invMod.locSrvRef)
    )
  }, [location, srvsChoosen, startDate, endDate])
  if (!invMods?.length) return "Brak modyfikacji, "
  else
    return (
      <>
        {multiLoc ? (
          <tr className="fixed-values-table">
            <td colSpan="6">{`Klient: ${location.customer.shortName}, lokalizacja: ${location.name} - ${location.street} ${location.city}`}</td>
          </tr>
        ) : null}
        {invMods?.map((invMod) => (
          <tr key={`invModRow-${invMod._id}`}>
            <td>{invMod.amount}</td>
            <td>
              {location.services.find((srv) => srv._id === invMod.locSrvRef)
                .name || "BŁĄD"}
            </td>
            <td>{format(new Date(invMod.applyDate), "yyyy-MM-dd")}</td>
            <td>{invMod.history[0]?.user?.fullName}</td>
            <td>{invMod.invNote}</td>
            <td>{invMod.desc}</td>
          </tr>
        ))}
      </>
    )
}
export default InvModsTable
