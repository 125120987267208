import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Button, Form, Alert, Row, Badge, Col } from "react-bootstrap"
import { createManualBtn } from "../../utils/buttons"

const ServicesManageEditModal = ({ srv, setModalData, configs, refresh }) => {
  const [fetchError, setFetchError] = useState(null)

  const { handleSubmit, register, formState, setValue, watch, control } =
    useForm({
      defaultValues:
        srv === "new"
          ? {
              name: null,
              type: null,
              unit: null,
              chargeType: null,
              defaultNetPrice: null,
              defaultVat: null,
              defaultInvLine: null,
              mustHaveJobs: null,
              invHandler: null,
              canHaveSubSrv: false,
              canBeSubSrv: false,
              allowExtraFee: false,
            }
          : srv,
    })

  const { dirtyFields, errors } = formState

  const watchCanBeSubSrv = watch("canBeSubSrv")

  const submit = async (formData) => {
    try {
      const res = await fetch(`/services/${srv === "new" ? "add" : "edit"}`, {
        method: srv === "new" ? "POST" : "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          service: { ...formData, _id: srv._id || null },
          dirtyFields: dirtyFields,
        }),
      })
      if (res.status === 403) return setFetchError("brak uprawnień")
      if (res.status === 409) return setFetchError("edytujesz stare dane")
      if (res.status !== 200) throw res
      return setModalData({
        show: true,
        type: "info",
        headerColor: "green",
        header: "Sukces",
        body: "Poszło!",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError("błąd w submit()")
    }
  }

  const createIcons = () => {
    return configs.calendarIcons.map((icon, i) => (
      <span key={`icon-${i}`}>
        <svg
          className="m-1"
          viewBox="-40 -40 80 80"
          style={{ width: "2rem", height: "2rem" }}
          fill="black"
          cursor="pointer"
          onClick={() => setValue("iconPath", icon.path)}
        >
          <path d={icon.path} />
        </svg>{" "}
        - {icon.type}
      </span>
    ))
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row>
        <Col>
          nazwa:{" "}
          {errors.name && (
            <Badge pill variant="warning">
              {errors.name.message}
            </Badge>
          )}
          <Form.Control
            {...register("name", { required: "Pole wymagane" })}
            as="input"
            type=""
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col>
          priorytet {createManualBtn("srvPriority")}
          <Form.Control
            {...register("priority")}
            as="input"
            type="number"
            className="medium-number-input"
            autoComplete="chrome-off"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          typ:{" "}
          {errors.type && (
            <Badge pill variant="warning">
              {errors.type.message}
            </Badge>
          )}
          <Form.Control
            {...register("type", { required: "Pole wymagane" })}
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
          >
            <option value="toalety">toalety</option>
            <option value="szambo">szambo</option>
            <option value="sprzątanie">sprzątanie</option>
            <option value="ogrodzenia">ogrodzenia</option>
            <option value="prysznice">prysznice</option>
            <option value="inne">inne</option>
          </Form.Control>
        </Col>

        <Col>
          Kategoria przychodu:{" "}
          {errors.chargeType && (
            <Badge pill variant="warning">
              {errors.chargeType.message}
            </Badge>
          )}
          <Form.Control
            {...register("defaultIncomeCat", {
              required: "Pole wymagane",
            })}
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
          >
            <option value="Toalety">Toalety</option>
            <option value="Sprzęt">Sprzęt</option>
            <option value="Kontenery">Kontenery</option>
            <option value="Ogrodzenia">Ogrodzenia</option>
            <option value="Toalety klienta">Toalety klienta</option>
            <option value="Szambo">Szambo</option>
            <option value="Sprzątanie">Sprzątanie</option>
            <option value="Sprzedaż">Sprzedaż</option>
            <option value="Transport">Transport</option>
            <option value="Inna">Inna</option>
          </Form.Control>
        </Col>

        <Col>
          naliczenie:{" "}
          {errors.chargeType && (
            <Badge pill variant="warning">
              {errors.chargeType.message}
            </Badge>
          )}
          <Form.Control
            {...register("chargeType", {
              required: "Pole wymagane",
              validate: (v) => v !== "za sztukę" || "Pole wymagane",
            })}
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
          >
            <option value="za dzień">za dzień</option>
            <option value="za miesiąc">za miesiąc</option>
            <option value="za serwis">za serwis</option>
            <option value="krótki termin">krótki termin</option>
            <option value="ryczałt impreza">ryczałt impreza</option>
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col>
          domyślna cena netto:{" "}
          <Form.Control
            {...register("defaultNetPrice")}
            as="input"
            type="number"
            className=""
            autoComplete="chrome-off"
            step="any"
          />
        </Col>
        <Col>
          domyślny vat:{" "}
          <Form.Control
            {...register("defaultVat")}
            as="input"
            type="number"
            className=""
            autoComplete="chrome-off"
            step="any"
          />
        </Col>
        <Col>
          jm:{" "}
          {errors.unit && (
            <Badge pill variant="warning">
              {errors.unit.message}
            </Badge>
          )}
          <Form.Control
            {...register("unit", { required: "Pole wymagane" })}
            as="input"
            type=""
            className=""
            autoComplete="chrome-off"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          domyślna treść:{" "}
          {errors.defaultInvLine && (
            <Badge pill variant="warning">
              {errors.defaultInvLine.message}
            </Badge>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="8">
          <Form.Control
            {...register("defaultInvLine", { required: "pole wymagane" })}
            as="textarea"
            type=""
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col>
          <p
            style={{ border: "1px solid black" }}
          >{`{dataOd}, {dataDo}, {dataZadania}, {nazwaLok}, {adresLok}`}</p>
        </Col>
      </Row>
      <div style={{ border: "1px solid black" }}>
        <Row>
          <Col>
            <h4>Parametry:</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              {...register("mustHaveJobs")}
              type="checkbox"
              label="wymagać zadań?"
              id="mustHaveJobs"
            />

            <Form.Check
              {...register("mustHaveEqp")}
              type="checkbox"
              label="usługa sprzętowa"
              id="mustHaveEqp"
            />
          </Col>
          <Col>
            <Form.Check
              {...register("custEqp")}
              type="checkbox"
              label="sprzęt klienta"
              id="custEqp"
            />

            <Form.Check
              {...register("canHaveSubSrv")}
              type="checkbox"
              label="może mieć usługi podrzędne"
              id="custEqp"
            />
          </Col>
          <Col>
            <Controller
              control={control}
              name="canBeSubSrv"
              render={({ field: { onChange, value } }) => (
                <Form.Check
                  type="checkbox"
                  label="usługa (również) podrzędna"
                  id="custEqp"
                  checked={value}
                  onChange={(e) => {
                    if (!e.target.checked) setValue("allowExtraFee", false)
                    onChange(e)
                  }}
                />
              )}
            />
            <Form.Check
              {...register("allowExtraFee")}
              disabled={watchCanBeSubSrv === true ? false : true}
              type="checkbox"
              label="Podrzędna - można doliczać za serwis"
              id="allowExtraFee"
            />
          </Col>
        </Row>
      </div>
      <div style={{ border: "1px solid black" }}>
        <Row className="mt-2">
          <Col>
            {watch("mustHaveEqp") && (
              <>
                <h4>Sprzęt</h4>
                <br />
                {configs.eqpTypes.map((eqp) => (
                  <Form.Check
                    inline
                    {...register("eqpTypes")}
                    type="checkbox"
                    label={eqp.type}
                    value={eqp.type}
                    id={`eqpTypes-${eqp.type}`}
                    key={`eqp-checkbox-${eqp.type}`}
                  />
                ))}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Row className="mt-3">
        <Col>
          <h4>Ikona</h4>
          <p>
            <i>(kliknij wzór aby automatycznie ustawić)</i>
          </p>
          {errors.iconPath && (
            <Badge pill variant="warning">
              {errors.iconPath.message}
            </Badge>
          )}
          <Row>{createIcons()}</Row>
          <div style={{ border: "1px solid black" }}>
            <Row>
              <Col xs="auto">
                <p>
                  <i>
                    (pole techniczne - <b>proszę nic nie zmieniać!</b>)
                  </i>
                </p>
              </Col>
              <Col>
                <Form.Control
                  {...register("iconPath", { required: "Pole wymagane" })}
                  as="input"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}

      <Row className="justify-content-between mx-3 mt-3">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}

export default ServicesManageEditModal

/*
const ServiceSchema = new Schema({
  name: String,
  type: String,
  unit: String,
  chargeType: String,
  defaultNetPrice: Number,
  defaultVat: Number,
  defaultInvLine: String,
  mustHaveJobs: Boolean,
  invHandler: String,
})
*/
