import React, { useEffect } from "react"

import { Form, Row, Col, Table, Button } from "react-bootstrap"

const HeaderForm = (props) => {
  const watchBranch = props.watch("branch")
  return (
    <Row
      style={{
        alignItems: "baseline",
        justifyItems: "center",
      }}
    >
      <Col xs="auto">
        <Form.Label>
          Rok:
          <Form.Control
            {...props.register("year")}
            type="number"
            defaultValue={props.dateToDisplay.year}
            className="medium-number-input"
          />
        </Form.Label>
      </Col>
      <Col xs="auto">
        <Form.Label>
          Miesiąc:
          <Form.Control
            {...props.register("month")}
            type="number"
            defaultValue={props.dateToDisplay.month}
            className="medium-number-input"
            min={1}
            max={12}
          />
        </Form.Label>
      </Col>
      <Col xs="auto">
        <Form.Control
          {...props.register("branch", { validate: (v) => v !== "plug" })}
          id="branchSelect"
          as="select"
          defaultValue={props.user.defaultBranch || "plug"}
          isInvalid={props.errors.branch ? true : false}
        >
          <option value="plug" hidden>
            Oddział
          </option>
          {Object.keys(props.user.branch).map((branch) => {
            if (props.user.branch[branch])
              return (
                <option value={branch} key={branch}>
                  {branch}
                </option>
              )
            else return null
          })}
        </Form.Control>

        {/* {errors.branch && <p className="text-danger">wybierz oddział</p>} */}
      </Col>
      <Col xs="auto">
        {/* Show drivers to select only when branch is selected, if not dropdown will be empty*/}
        <Form.Control
          {...props.register("driver", {
            validate: (v) => v !== "plug",
          })}
          id="driverSelect"
          as="select"
          defaultValue="plug"
          isInvalid={props.errors.branch ? true : false}
        >
          <option value="plug" hidden>
            Kierowca
          </option>
          {watchBranch &&
            props.employees.map((employee) => {
              if (employee.type.driver && employee.branch[watchBranch]) {
                return (
                  <option value={employee._id} key={employee._id}>
                    {employee.fullName}
                  </option>
                )
              } else return null
            })}
        </Form.Control>

        {/* {errors.driver && <p className="text-danger">wybierz kierowcę</p>} */}
      </Col>
    </Row>
  )
}

const MainGoodsForm = (props) => {
  //destructuring props to preven ESlint warning:
  const { append } = props
  // add row with "+" button. Event listener refer to state from the moment it was created. To have actual state in it I need to mount it on every state change
  // Below effect is mounting event listener and cleaning it up after every 'rows' state change.
  // ref: https://stackoverflow.com/questions/53845595/wrong-react-hooks-behaviour-with-event-listener
  useEffect(() => {
    const keypressHandle = (e) => {
      // let user use '+' inside additionalInfo
      if (e.target.name === "additionalInfo") return
      // if '+' pressed anywhere else -> add row. preventDefault is mainly to prevent entering '+' character in number inputs
      // when it happens it is impossible to delete it with javascript, as I can't "get to it" as it's not value.
      // For ex. running command e.target.value = "1" after entering '+' in this input ends with '1+' string in it
      // also entering '+' in number input is not firing form change, so I have to handle it here
      else if (e.key === "+") {
        e.preventDefault()
        append({ date: "", consumptions: [...props.mainGoods] })
        return
        //prevent submitting on Enter press:
      } else if (e.key === "Enter") e.preventDefault()
      else return
    }
    window.addEventListener("keypress", keypressHandle)
    return () => window.removeEventListener("keypress", keypressHandle)
  }, [append, props.mainGoods])

  return (
    <Table>
      <thead>
        <tr>
          <th rowSpan="2">
            Data <br />
            <Button
              id="upperAddRowBtn"
              onClick={() =>
                props.append({ date: "", consumptions: [...props.mainGoods] })
              }
              style={{
                height: "auto",
                margin: "1px",
                padding: "5px 10px",
                fontWeight: "700",
                letterSpacing: "0.5px",
              }}
              type="button"
              size="sm"
              variant="secondary"
            >
              dodaj wiersz
            </Button>
          </th>
          {/* create headers from props.goods */}
          {props.mainGoods.map((good) => {
            return (
              <th
                key={`${good.name}-header`}
                id={`mainGoodHeader-${good.name}`}
              >
                {good.name}
              </th>
            )
          })}
        </tr>
        {/* create second header row with good unit */}
        <tr>
          {props.mainGoods.map((good) => {
            return <th key={`${good.name}-unit-header`}>{good.unit}</th>
          })}
        </tr>
      </thead>
      <tbody id="mainGoodsTableBody">
        {props.fields.map((field, index) => {
          return (
            <tr key={`mainGoodsRow-${index}`} id="mainGoodsRow">
              <td>
                <Row>
                  {index === 0 ? null : (
                    <Button
                      variant="danger"
                      type="button"
                      className="ml-1"
                      onClick={() =>
                        props.setModalData({
                          show: true,
                          type: "confirm",
                          body: "Usunąć wiersz?",
                          func: () => {
                            props.remove(index)
                            props.setModalData({ show: false })
                          },
                        })
                      }
                      id={`removeRowBtn-${index}`}
                    >
                      X
                    </Button>
                  )}
                  <Form.Control
                    {...props.register(`mainGoodsConsumptions[${index}].date`)}
                    type="number"
                    className="small-number-input ml-3"
                    key={field.id}
                    defaultValue={field.date}
                  />
                </Row>
              </td>
              {props.mainGoods.map((good, goodInd) => {
                // registering good constant properties:
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}]._id`,
                  {
                    value: good._id,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].name`,
                  {
                    value: good.name,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].unit`,
                  {
                    value: good.unit,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].finalUnit`,
                  {
                    value: good.finalUnit,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].divider`,
                  {
                    value: good.divider,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].price`,
                  {
                    value: good.price,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].type`,
                  {
                    value: good.type,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].priority`,
                  {
                    value: good.priority,
                  }
                )
                props.register(
                  `mainGoodsConsumptions[${index}].consumptions[${goodInd}].isWarehouseCost`,
                  {
                    value: good.isWarehouseCost,
                  }
                )

                //rendering input:
                return (
                  <td key={`mainGoodsCell${index}${goodInd}`}>
                    <Form.Control
                      {...props.register(
                        `mainGoodsConsumptions[${index}].consumptions[${goodInd}].quantity`
                      )}
                      type="number"
                      step="0.01"
                      className="medium-number-input"
                      defaultValue={field.consumptions[goodInd].quantity}
                      key={field.id}
                    />
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td>Sumy:</td>
          {props.mainGoods.map((good, index) => {
            // calculate each good sum from all main goods:
            const sum = props.watchMainGoods.reduce((acc, curr) => {
              if (curr.consumptions[index]?.quantity)
                // when input is empty watch is returning 'undefined', which causes problems with calculating sums. I'm checking for undefined here and returning acc if it is so
                return (
                  Number.parseFloat(curr.consumptions[index].quantity) + acc
                )
              else return acc
            }, 0)
            return (
              <td key={`goodSumCell${good.name}`} id={`mainGoodCell-${index}`}>
                {isNaN(sum) ? 0 : sum}
              </td>
            )
          })}
        </tr>
        <tr className="bg-primaryLight">
          <td rowSpan="2">
            <Button
              id="lowerAddRowBtn"
              onClick={() =>
                props.append({ date: "", consumptions: [...props.mainGoods] })
              }
              style={{
                height: "auto",
                margin: "1px",
                padding: "5px 10px",
                fontWeight: "700",
                letterSpacing: "0.5px",
              }}
              type="button"
              size="sm"
              variant="secondary"
            >
              dodaj wiersz
            </Button>
          </td>
          {/* create headers from props.goods */}
          {props.mainGoods.map((good) => {
            return <th key={`${good.name}-header`}>{good.name}</th>
          })}
        </tr>
        {/* create second header row with good unit */}
        <tr className="bg-primaryLight">
          {props.mainGoods.map((good) => {
            return <th key={`${good.name}-unit-header`}>{good.unit}</th>
          })}
        </tr>
      </tfoot>
    </Table>
  )
}

const FooterForm = (props) => {
  return (
    <Row>
      <Col>
        <Table bordered>
          {/* Table with normal goods consumption */}
          <thead>
            <tr>
              <th colSpan="3">Inne:</th>
            </tr>
            <tr>
              <th>Materiał</th>
              <th>Jednostka</th>
              <th>Zużycie</th>
            </tr>
          </thead>
          <tbody>
            {props.normalGoods.map((good, i) => {
              // registering good constant properties:
              props.register(`normalGoodsConsumptions[${i}]._id`, {
                value: good._id,
              })
              props.register(`normalGoodsConsumptions[${i}].name`, {
                value: good.name,
              })
              props.register(`normalGoodsConsumptions[${i}].unit`, {
                value: good.unit,
              })
              props.register(`normalGoodsConsumptions[${i}].finalUnit`, {
                value: good.finalUnit,
              })
              props.register(`normalGoodsConsumptions[${i}].divider`, {
                value: good.divider,
              })
              props.register(`normalGoodsConsumptions[${i}].price`, {
                value: good.price,
              })
              props.register(`normalGoodsConsumptions[${i}].isWarehouseCost`, {
                value: good.isWarehouseCost,
              })
              return (
                <tr key={`tr-${good}-${i}`}>
                  <td>{good.name}</td>
                  <td>{good.unit}</td>
                  <td>
                    <Form.Control
                      {...props.register(
                        `normalGoodsConsumptions[${i}].quantity`
                      )}
                      type="number"
                      size="sm"
                      step="any"
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Col>
      <Col>
        <Form.Control
          {...props.register("additionalInfo")}
          as="textarea"
          placeholder="Dodatkowe informacje, dane, notatki..."
          cols="80"
          rows="10"
          style={{
            gridColumn: "2",
            gridRow: "2",
            // justifySelf: "center",
            // alignSelf: "center",
          }}
        />
      </Col>
    </Row>
  )
}

export { HeaderForm, MainGoodsForm, FooterForm }
