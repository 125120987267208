import { useContext, useState } from "react"
import { Table, Container, Button, Row, Col, Form } from "react-bootstrap"

import { dateParse } from "../../../utils/formats"

import UserContext from "../../../contexts/userContext"
import { CustomerCtx } from "../Fetch"

import CustomersNoteDetailsModal from "../entities/CustomersNoteDetailsModal"
import CustomersDetailsClipMenuDetailsModal from "../modals/CustomersDetailsClipMenuDetailsModal"
import CustomersNoteEditModal from "../entities/CustomersNoteEditModal"
import CustomerDetailsEditAdditionalModal from "../modals/CustomerDetailsEditAdditionalModal"
import { CustomerDetailsBankAccModal } from "../modals/BankAccModal"
import sortByCreationDate from "../../../utils/sortByCreationDate"
import { CustomersDetailsArchNotesModal } from "../modals/ArchNotes"
import { Files } from "../../../_shared/filesComp/Files"
import { format, isValid } from "date-fns"

const CustomersDetailsAdditionalTab = () => {
  const user = useContext(UserContext)
  const { customer, setModalData, refresh } = useContext(CustomerCtx)
  const [notesFilter, setNotesFilter] = useState("wszystkie")

  return (
    <Container fluid>
      <Table>
        <thead>
          <tr>
            <th colSpan="10">
              <Row className="justify-content-around">
                <Col>Szczegóły</Col>
                {customer.state === "aktywny" ? (
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          header: "Edycja szczegółów",
                          body: <CustomerDetailsEditAdditionalModal />,
                        })
                      }
                    >
                      Edytuj
                    </Button>
                  </Col>
                ) : null}
                <Col>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setModalData({
                        show: true,
                        xl: true,
                        type: "info",
                        header: "Lista kont bankowych klienta",
                        body: (
                          <CustomerDetailsBankAccModal
                            bankAccounts={
                              customer.bankAccounts?.length
                                ? customer.bankAccounts
                                : []
                            }
                            customer_id={customer._id}
                            refresh={refresh}
                            custHistoryLength={customer.history.length}
                          />
                        ),
                      })
                    }
                  >
                    Konta bankowe
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        header: "Pliki klienta",
                        body: (
                          <Files
                            bucketSuffix={"customers"}
                            filePath={`${customer._id}/`}
                            entityId={customer._id}
                            prefix={`${customer._id}/`}
                            isAdmin={user.type.admin}
                            goBack={() => setModalData({ show: false })}
                          />
                        ),
                      })
                    }
                  >
                    Pliki
                  </Button>
                </Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Zgoda marketingowa?</td>
            <td className="fixed-values-table">Domyślny termin płatności</td>
            <td className="fixed-values-table">Forma płatności</td>
            <td className="fixed-values-table">Rabat za płatność?</td>
            <td className="fixed-values-table">
              Pokaż rabat za płatność na FV?
            </td>
            <td className="fixed-values-table">eFaktura?</td>
            <td className="fixed-values-table">Lokalizacja na FV?</td>
            <td className="fixed-values-table">Rozrachunki na FV?</td>
            <td className="fixed-values-table">Specjalne kontrolki?</td>
            <td className="fixed-values-table">Klient z clipMENU?</td>
          </tr>
          <tr>
            <td>{customer.marketingConsent ? "Tak" : "Nie"}</td>
            <td>{customer.defaultDeadline}</td>
            <td>{customer.defaultPaymentMethod}</td>
            <td>{customer.discountForPayment}%</td>
            <td>{customer.showDiscountForPayment ? "Tak" : "Nie"}</td>
            <td>{customer.eInvoice ? "Tak" : "Nie"}</td>
            <td>{customer.locOnInvoice ? "Tak" : "Nie"}</td>
            <td>{customer.printDebtDetails ? "Tak" : "Nie"}</td>
            <td>{customer.customJobCards ? "Tak" : "Nie"}</td>
            <td>
              {customer.isOld ? (
                <Row>
                  Tak
                  <Button
                    size="sm"
                    className="ml-3"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        header: "Dane z clipMENU",
                        body: <CustomersDetailsClipMenuDetailsModal />,
                      })
                    }
                  >
                    Podejrzyj dane
                  </Button>
                </Row>
              ) : (
                "Nie"
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <h4>Notatki</h4>
      <Table>
        <thead>
          <tr>
            <th
              className="fixed-values-table justify-content-center"
              colSpan="4"
            >
              <Row>
                <Col>
                  <Form.Control
                    as="select"
                    autoComplete="chrome-off"
                    onChange={(e) => setNotesFilter(e.target.value)}
                  >
                    <option value="wszystkie">wszystkie</option>
                    <option value="windykacyjna">windykacyjna</option>
                    <option value="główna">główna</option>
                    <option value="kontrolka">kontrolka</option>
                    <option value="handlowa">handlowa</option>
                    <option value="inna">inna</option>
                  </Form.Control>
                </Col>

                <Col>
                  <Row>
                    <Button
                      variant="info"
                      className="ml-auto"
                      onClick={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          header: "Notatki archiwalne",
                          body: (
                            <CustomersDetailsArchNotesModal
                              notes={customer.notes.filter(
                                (note) => note.state === "archiwum"
                              )}
                              setModalData={setModalData}
                            />
                          ),
                        })
                      }
                    >
                      Archiwalne
                    </Button>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    {user.perm.customers.e && customer.state === "aktywny" ? (
                      <Button
                        variant="secondary"
                        className="ml-auto mr-3"
                        onClick={() =>
                          setModalData({
                            show: true,
                            type: "info",
                            xl: true,
                            body: <CustomersNoteEditModal note="new" />,
                            header: <p className="mb-0">Dodawanie notatki</p>,
                          })
                        }
                      >
                        Dodaj
                      </Button>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Rodzaj</td>
            <td className="fixed-values-table">Dodał</td>
            <td className="fixed-values-table">Data</td>
            <td className="fixed-values-table">Treść</td>
          </tr>
          {customer.notes.sort(sortByCreationDate).map((note) => {
            if (notesFilter !== "wszystkie" && notesFilter !== note.type)
              return null
            if (note.state === "archiwum") return null
            let noteBody = ""
            if (note.body?.length > 65)
              noteBody = `${note.body.slice(0, 64)}[...]`
            else noteBody = note.body
            return (
              <tr
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: <CustomersNoteDetailsModal note={note} />,
                    xl: true,
                    header: (
                      <p className="mb-0">
                        Szczegóły notatki (rodzaj: {note.type})
                      </p>
                    ),
                  })
                }
                key={`note-row-${note._id}`}
              >
                <td>{note.type}</td>
                <td>
                  {note.history[0]?.user?.fullName || "import automatyczny"}
                </td>
                <td>{dateParse(note.history[0]?.date, false)}</td>
                <td>
                  {note.subtype ? `(${note.subtype}) ` : null}
                  {isValid(new Date(note.eventDate))
                    ? `(${format(new Date(note.eventDate), "yyyy-MM-dd")}) `
                    : null}
                  {noteBody}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default CustomersDetailsAdditionalTab
