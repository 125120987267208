import React from "react"
import { Row, Col, Button, Alert } from "react-bootstrap"
import mapMarkers from "../../pics/manual/mapMarkers.png"
import markerClicks from "../../pics/manual/markerClicks.png"
import planningTable from "../../pics/manual/planningTable.png"
import planningBatchJobsEdit from "../../pics/manual/planningBatchJobsEdit.png"
import planningAdditional from "../../pics/manual/planningAdditional.png"
import printPagesNumbers from "../../pics/manual/printPagesNumbers.gif"
import changeOrderBtn from "../../pics/manual/logistics/changeOrderBtn.png"
import changeOrderModal from "../../pics/manual/logistics/changeOrderModal.png"
import { TiArrowShuffle } from "react-icons/ti"

const ManualLogisticsSection = (props) => {
  if (props.user.perm.logistics.r)
    return (
      <>
        <div>
          <Row>
            <Col>
              <h3>Logistyka</h3>

              <h4 id="logisticJobsFreq">
                Liczenie częstotliwości serwisów na lokalizacji
              </h4>
              <p>
                W kilku miejscach w programie (w tym na kontrolce) pojawia się
                informacja o częstotliwości serwisowania na danej lokalizacji. W
                przeciwieństwie do sterego programu, w clipERP taka informacja
                nie jest na stałe zapisywana w lokalizacji. Program
                przygotowując dane do planowania/odhaczania na bieżąco określa
                częstotliwość na podstawie zaplanowanych serwisów na
                lokalizacji, dlatego w niektórych sytuacjach ta informacja może
                być niedokładna (np. jeśli zaplanowane serwisy zostały usunięte
                ze względu na święta, albo przy błędach w rejestracji).{" "}
                <Button
                  onClick={() =>
                    props.setModalData({
                      show: true,
                      type: "info",
                      body: (
                        <>
                          <p>
                            Dla każdego zadania program sprawdza, ile serwisów
                            jest zaplanowanych na najbliższe 6 tygodni (w
                            stosunku do tego zadania, licząc od dnia
                            następnego). W zależności od otrzymanej liczby
                            określa częstotliwość:{" "}
                          </p>

                          <ul>
                            <li>brak zadań - "na tel"</li>
                            <li>1 zadanie - "co 4 tyg"</li>
                            <li>2 zadania - "co 3 tyg"</li>
                            <li>3 lub 4 zadania - "co 2 tyg"</li>
                            <li>5 lub 6 zadań - "co 1 tyg"</li>
                            <li>więcej zadań - "kilka w tyg"</li>
                          </ul>
                        </>
                      ),
                    })
                  }
                >
                  nieobowiązkowe - szczegóły
                </Button>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Planowanie</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Mapa</h5>
              <p>
                Zadania na mapie oznaczone są markerami (pinezkami). Każdy
                marker ma "wycięcie" odpowiadające typowi usługi (kształty jak w
                kalendarzu). Na przykładzie obok od lewej: serwis toalety
                klienta, szambo, serwis naszej toalety.
                <br />
                Jeśli marker nie ma wycięcia oznacza to, że{" "}
                <u>w tym miejscu jest więcej niż jedno zadanie</u> (nawet jeśli
                chodzi o zadania dotyczące usługi tego samego typu)
              </p>
              <p>
                Kolory markerów są przypisane <b>na stałe</b> do kierowców, a
                pod mapą widoczna jest legenda kolorów.
              </p>
              <p>
                Wyjątkiem jest kolor czarny, który oznacza, że kierowca nie ma
                przypisanego koloru <b>lub wystąpił błąd</b> - tak czy inaczej{" "}
                <span className="bg-danger">proszę o informację</span>
              </p>
            </Col>
            <Col>
              <img src={mapMarkers} alt="" style={{ maxWidth: "100%" }} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>
                Po kliknięciu markera <b>lewym przyciskiem myszy</b> otwiera się
                okienko z podstawowaymi informacjami. Kliknięcie{" "}
                <b>prawym przyciskiem myszy</b> otwiera podgląd pełnych
                informacji, <u>z możliwością edytowania zadania</u>
              </p>
              <p>
                W przypadku markerów bez wycięcia (czyli z wieloma zadaniami w
                tym samym miejscu) po kliknięciu dowolnym przyciskiem pojawi się
                lista zadań. Kliknięcie na wybrane zadanie otworzy modal z
                podglądem pełnych informacji (jak prawy przycisk myszy powyżej)
              </p>
            </Col>
            <Col>
              <img
                src={markerClicks}
                alt=""
                style={{ maxWidth: "100%", height: "60%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Tabela</h5>
              <p>
                W karcie "Tabela" widoczne są wszystkie zadania, po kliknięciu
                któregokolwiek otworzy się modal z podglądem pełnych informacji
                i możliwością edycji.
              </p>
              <p>
                W tej karcie można też filtrować zadania wg typu i/lub
                kierowców, oraz korzystać z "MEZ - Masowej Edycji Zadań".
              </p>
            </Col>
            <Col>
              <img src={planningTable} alt="" style={{ maxWidth: "100%" }} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h6>MEZ</h6>
              <p>
                Po zaznaczeniu zadań do edytowania i kliknięciu przycisku MEZ
                pojawi się modal umożliwiający wprowadzanie wybranych zmian dla
                wszystkich zaznaczonych zadań.
                <br />
              </p>
              <p>
                <b>Uwaga!</b> Zaznaczenie checkboxa przy rodzaju modyfikacji
                powoduje nadpisanie dotychczasowych wartości{" "}
                <u>we wszystkich zaznaczonych zadaniach</u> wartością wpisaną w
                formularzu.
                <br />
                Powyższe nie dotyczy tylko pola "Uwagi - dodaj" - wszystko
                wpisane w tym polu zostanie dodane do istniejących uwag (które
                nie zostaną usunięte)
              </p>
            </Col>
            <Col>
              <img
                src={planningBatchJobsEdit}
                alt=""
                style={{ maxWidth: "100%", height: "70%" }}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h5>Podsumowanie</h5>
              <p>
                <i>Karta służąca do drukowania kontrolek</i>
              </p>
              <p>
                <b>Narzędzia</b>
                <br />
                Przycisk "Przenieś zadania" umożliwia szybką zmianę przypisanych
                do jednego kierowcy na innego.
                <br />
                Przycisk "Wyślij powiadomienia" powoduje wysłanie SMSów i
                wiadomości e-mail do klientów o zaplanowanych zadaniach (stan
                dla wersji 1.5.2: ta funkcja jeszcze nie działa)
              </p>
              <p>
                <br />
                Przed wydrukowaniem kontrolek należy:
              </p>
              <ul>
                <li>
                  Przypisać samochody klikając ikonę ciężarówki przy każdym
                  kierowcy w tabeli
                </li>
                <li>
                  Rozwiązać ewentualne problemy opisane powyżej tabeli (czerwona
                  ramka na przykładzie obok) -{" "}
                  <Button
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        body: <PlanningErrorsModal />,
                      })
                    }
                  >
                    przykłady
                  </Button>
                </li>
              </ul>

              <h5>Przypisywanie samochodów</h5>
              <p>
                Przed wydrukowaniem kontrolek należy każdemu kierowcy przypisać
                samochód klikając ikonę ciężarówki w tabeli "Statystyki"
                (zielona ramka na przykładzie obok)
              </p>
              <h5>Drukowanie kontrolek</h5>
              <p>
                Aby wydrukować kontrolkę należy zaznaczyć odpowiedni checkbox w
                tabeli. Spowoduje to wyświetlenie podglądu wydruku. Kliknięcie
                przycisku "Drukuj kontrolkę" otworzy okno umożliwiające wydruk.
                <br />
                Można też zaznaczyć wszystkich kierowców klikając checkbox w
                nagłówku tabeli.
              </p>
              <p>
                <b>Zmiana stanu zadań</b>
                <br />
                Po wydrukowaniu kontrolki zadania automatycznie zmieniają stan
                na "zlecone".
              </p>
            </Col>
            <Col>
              <img
                src={planningAdditional}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
          <div
            className="bg-secondarySuperLight"
            // 1.14.2
          >
            <Row>
              <Col>
                <h5 id="changeOrder">Kolejność zadań</h5>
                <p>
                  Aby zaplanować kolejność zadań należy kliknąć przycisk{" "}
                  <Button size="sm">
                    <TiArrowShuffle />{" "}
                  </Button>{" "}
                  w tabeli podsumowania.
                </p>
              </Col>
              <Col>
                <img src={changeOrderBtn} alt="" style={{ maxWidth: "100%" }} />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  W tabeli wyświetlają się podstawowe informacje dot. zadań.{" "}
                  <u>
                    Jeśli zadanie ma zaplanowane godziny to wyświetlą się one w
                    kolumnie "Uwagi".
                  </u>{" "}
                  Po godzinach wyświetlone są uwagi do zadania, a na końcu
                  notatki "na kontrolkę" z lokalizacji.
                  <br />
                  Jeśli tekst uwag jest zbyt długi to program wyświetli tylko
                  początek i znak "[...]" - najechanie na pole wyświetli całość
                  tekstu.
                </p>

                <p>
                  <b>Aby zmienić kolejność należy:</b>
                </p>
                <ul>
                  <li>
                    Wpisać liczbę w okienku{" "}
                    <u>
                      i zatwierdzić wciskając enter lub klikając gdziekolwiek
                      obok
                    </u>{" "}
                    - program odpowiednio przeliczy kolejność pozostałych zadań.
                    Można wpisać dowolną liczbę, również ujemną albo większą niż
                    liczba serwisów na liście, w takiej sytuacji zadanie trafi
                    odpowiednio na początek lub koniec listy i otrzyma
                    prawidłową liczbę porządkową.
                  </li>
                  <li>
                    Skorzystać z przycisków ("na początek", "o jeden w górę", "o
                    jeden w dół", "na koniec")
                  </li>
                </ul>

                <h6>Zapisywanie kolejności</h6>
                <p>
                  Po ustaleniu kolejności można ją zapisać tylko dla wybranego
                  dnia, lub dla wybranego dnia i zadań w przyszłości ("zapisz na
                  przyszłość").
                </p>
                <p>
                  Druga opcja powoduje zastosowanie zmian dla obecnie
                  planowanego dnia oraz <u>wszystkich zadań</u> dla lokalizacji
                  na liście w odstępie <b>4-tygodniowym</b>.<br />
                  <Alert variant="dangerLight">
                    Nowa kolejność zostanie zapisana bez brania pod uwagę, czy
                    zadania dotyczą tych samych usług.
                  </Alert>
                </p>

                <p>
                  Jeśli przy otwieraniu modala program wykryje, że jakieś
                  zadania miały już zaplanowaną kolejność a inne nie, to te bez
                  zaplanowanej kolejności trafią na koniec tabeli.
                  <br /> Program również automatycznie przeliczy kolejność, tak
                  żeby nie było niewykorzystanych numerów. <br />
                  <b>
                    Te operacje nie mają wpływu na zadania do momentu zapisania
                    zmian.
                  </b>
                </p>

                <h6>Uwagi techniczne</h6>
                <p>
                  Ze względu na sposób zorganizowania zadań na kontrolce
                  (układanie wg. lokalizacji) ustalona kolejność nie zawsze
                  będzie zastosowana w 100%. Lokalizacja z kilkoma zadaniami
                  zostanie posortowana zgodnie z kolejnością zadania, które jest
                  najwyżej na liście
                  <Button
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        body: (
                          <p>
                            Jeśli lokalizacja ma trzy zadania z kolejnościami 2,
                            15 i 20 to na kontrolce wszystkie zadania wydrukują
                            się na drugim miejscu
                          </p>
                        ),
                      })
                    }
                  >
                    przykład
                  </Button>
                </p>
              </Col>
              <Col>
                <img
                  src={changeOrderModal}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </Col>
            </Row>
          </div>
          <hr />
          <Row id="jobCard">
            <Col>
              <h4>Kontrolka</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Numerowanie stron</h6>
              <p>
                <br />
                Jeśli konieczne jest dodanie numerów stron na wydruku należy
                zmienić ustawienia przeglądarki internetowej:
                <br />
                po kliknięciu "Drukuj kontrolkę" trzeba kliknąć pozycję "Więcej
                ustawień" i zaznaczyć opcję "Nagłówki i stopki". To ustawienie
                powinno się zapisać i stosować do wydruków w przyszłości.
                <br />
                <b>Uwaga!</b> Zaznaczenie tej opcji powoduje numerowanie{" "}
                <u>wszystkich</u> stron wydruku po kolei - program nie rozpozna
                początku nowej kontrolki. <br />
                <b>
                  Aby wydrukować kontrolki dla każdego kierowcy z osobną
                  numeracją
                </b>{" "}
                trzeba po kolei zaznaczać kierowców w tabeli i klikać przycisk
                "Drukuj kontrolkę" dla każdego osobno.
              </p>

              <h5>Opis wydruku</h5>
              <p>
                Dla każdego kierowcy, poza standardową kontrolką, drukowane są
                potwierdzenia dostarczeń, kontrolki imprezowe i kontrolki
                klienta (
                <u>jeśli zaznaczono odpowiedni checkbox w edycji klienta</u>).
              </p>
              <p>
                <b>Kontrolka klienta:</b> jest drukowana dla każdej lokalizacji
                osobno
              </p>
              <p id="jobCardCashParagraph">
                <b>Informacja o gotówce do pobrania</b> jest obliczana na
                podstawie (
                <b>
                  uwzględniane są tylko usługi z rozliczeniem "za serwis" i "za
                  sztukę"
                </b>
                ):
              </p>
              <ul>
                <li>
                  liczby sprzętu zaplanowanego do serwisu - jeśli usługa
                  sprzętowa lub ze sprzętem klienta (szczegóły w części dot.
                  lokalizacji)
                </li>
                <li>
                  liczby pobranej z{" "}
                  <u>parametru aktualnego na dzień wykonywania serwisu</u>
                </li>
              </ul>
              <p>
                <b>"Ostatnio"</b>: program wstawia informację o tym, kto
                ostatnio <u>wykonał</u> zadanie na danej lokalizacji (nie ma
                znaczenia, czy dla tej samej usługi, ale zadanie musi być
                wykonane).
              </p>

              <div>
                <h6>Kontrolki imprezowe</h6>
                <p>
                  Program drukuje osobne kontrolki dla usług z naliczeniem
                  "krótki termin" i "ryczałt impreza".
                  <br />
                  Na kontrolkach imprezowych drukowane są również notatki
                  lokalizacji "na kontrolkę" oraz kontakty "na kontrolkę" i
                  "główny" o ile mają dodane numery telefonów
                </p>
              </div>
            </Col>
            <Col>
              <hr />
              <img
                src={printPagesNumbers}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
          <div
            className="bg-secondarySuperLight"
            // 1.14.2
          >
            <Row>
              <Col>
                <h3 id="jobsSettling">Odhaczanie</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>Zabrania</h5>

                <p>
                  Przy odhaczaniu zadań może się zdarzyć, że jakieś zabranie
                  będzie dotyczyło sprzętu, który nie został jeszcze
                  dostarczony. Najczęściej będzie to miało miejsce gdy:
                </p>

                <ol>
                  <li>
                    dostarczenie i zabranie jest tego samego dnia i są odhaczane
                    razem
                  </li>
                  <li>coś zostało źle zarejestrowane/odhaczone</li>
                </ol>
                <p>
                  W takiej sytuacji program nie pozwoli na odhaczenie zabrania.
                  Wszystko inne zostanie zaznaczone jako wykonane, poza
                  zabraniami jak powyżej, oraz{" "}
                  <u>zostanie wyświetlony odpowiedni komunikat.</u>
                  <br />
                  Jeśli jest to spowodowane przez sytuację z punktu 1, to
                  wystarczy jeszcze raz odhaczyć zadanie - tym razem powinno się
                  udać (ponieważ dostarczenie zostało odhaczone w poprzednim
                  kroku).
                  <br />W innym wypadku należy spróbować sprawdzić skąd wziął
                  się błąd (np. czy wszystkie dostarczenia dla lokalizacji są
                  odhaczone) i spróbować go naprawić, lub skontaktować się ze
                  mną.
                </p>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
        {props.user.type.tester && props.showNotes ? (
          <div>
            <h2>Logistyka - notatki (tylko dla testerów)</h2>
            <p>
              Dodać info o przycisku "Przenieś zadania" w karcie podsumowanie
            </p>

            <h3>odhaczanie</h3>
            <p>
              "Dodaj uwagę do kolejnego zadania" znajduje pierwsze zadanie
              ZAPLANOWANE z datą późniejszą niż dziś - niezależnie od sprzętu,
              kierowcy, typu itd
            </p>

            <p>
              Obliczanie liczby sprzętu do obsłużenia: jeśli jest dodany sprzęt
              do lokalizacji to jego liczba, inaczej liczba z aktualnego dla
              serwisu parametru
            </p>

            <p>
              Zamykanie usług przy zabraniu: możliwe tylko jeśli zabranie
              obejmuje cały sprzęt <u>i jest jakiś niezamknięty parametr</u>
            </p>
          </div>
        ) : null}
      </>
    )
  else return null
}

const PlanningErrorsModal = () => {
  return (
    <div>
      <h3>Przykładowe problemy blokujące wydruk kontrolki:</h3>
      <p>
        <ul>
          <li>
            "Zadania z podpiętymi usługami sprzętowymi, ale bez sprzętu -
            wymienione lokalizacje mają zaplanowane zadania dotyczące usług,
            które wymagają sprzętu (np. wynajem toalet), ale nie został wybrany
            żaden do obsłużenia.
          </li>
          <li>
            Zadania bez kierowcy - jakieś zadanie nie ma przypisanego kierowcy
          </li>
        </ul>
      </p>
    </div>
  )
}

export default ManualLogisticsSection
