import React, { useContext, useState, useEffect } from "react"
import { Container, Button, Form, Badge, Row, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { addDays, format } from "date-fns"

import UserContext from "../../contexts/userContext"
import { submitTodo } from "./submit"
import { renderEmployeeOptions } from "./utils"

const TodosDetailsEdit = ({
  setModalData,
  isNew,
  refresh,
  todo,
  board,
  propositionAccepted,
}) => {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [todosTags, setTodosTags] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("/configs/todosTags")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        // set available tags, excluding 'auto' tag
        setTodosTags(resJSON.todosTags.filter((tag) => tag !== "auto"))
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [setModalData])

  return (
    <Container fluid>
      {isLoading ? (
        <>
          <Spinner animation="border" />
          Pobieram dane
        </>
      ) : (
        <RenderForm
          setModalData={setModalData}
          todosTags={todosTags}
          refresh={refresh}
          user={user}
          todo={todo}
          isNew={isNew}
          board={board}
          propositionAccepted={propositionAccepted}
        />
      )}
    </Container>
  )
}

const RenderForm = ({
  todo,
  board,
  todosTags,
  setModalData,
  refresh,
  user,
  isNew,
  propositionAccepted,
}) => {
  const defaultValues = isNew
    ? { state: "zlecone", employee: user._id }
    : {
        ...todo,
        // when accepting proposition -> set deadline for 7 days
        deadline: propositionAccepted
          ? format(addDays(new Date(), 7), "yyyy-MM-dd")
          : format(new Date(todo.deadline), "yyyy-MM-dd"),
        employee: todo.employee._id,
      }

  // when accepting proposition -> set isProposition to false
  if (propositionAccepted) defaultValues.isProposition = false

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  // change 'employee' value on isProposition change
  // without it user would be able to assign no-proposition to superior
  const watchProposition = watch("isProposition")
  useEffect(() => {
    setValue(
      "employee",
      watchProposition === true ? user.superior[0]?._id : user._id
    )
  }, [watchProposition, user, setValue])

  const onSubmit = (formData) =>
    submitTodo(setModalData, formData, refresh, isNew, dirtyFields)
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Check
        {...register("isProposition")}
        type="checkbox"
        label="Propozycja (dla przełożonego)"
        id="isProposition"
      />
      <hr />
      Przypisany:
      {errors.employee && (
        <Badge pill variant="warning">
          {errors.employee.message}
        </Badge>
      )}
      <Form.Control
        {...register("employee", { required: "Wybierz przypisaną osobę" })}
        as="select"
        className=""
        autoComplete="chrome-off"
      >
        {renderEmployeeOptions(user, watch("isProposition"), board)}
      </Form.Control>
      Tytuł
      {errors.name && (
        <Badge pill variant="warning">
          {errors.name.message}
        </Badge>
      )}
      <Form.Control
        {...register("name", { required: "Tytuł jest wymagany" })}
        as="input"
        type="text"
        className=""
        autoComplete="chrome-off"
      />
      Opis
      <Form.Control
        {...register("desc")}
        as="textarea"
        type=""
        className=""
        autoComplete="chrome-off"
      />
      Tagi:
      {errors.tags && (
        <Badge pill variant="warning">
          {errors.tags.message}
        </Badge>
      )}
      <div style={{ columnCount: 2 }}>
        {todosTags.map((tag) => (
          <Form.Check
            {...register("tags", {
              required: "Wybierz przynajmniej jeden tag",
            })}
            type="checkbox"
            label={tag}
            value={tag}
            id={`tags-${tag}`}
            key={`tags-${tag}`}
          />
        ))}
      </div>
      {watch("isProposition") ? null : (
        <>
          Priorytet (1 - na później, 6 - pilne)
          {errors.priority && (
            <Badge pill variant="warning">
              {errors.priority.message}
            </Badge>
          )}
          <Form.Control
            {...register("priority", { required: "Wybierz priorytet" })}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </Form.Control>
          Termin:
          {errors.deadline && (
            <Badge pill variant="warning">
              {errors.deadline.message}
            </Badge>
          )}
          <Form.Control
            {...register("deadline", { required: "Podaj termin" })}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
          Stan:
          {errors.state && (
            <Badge pill variant="warning">
              {errors.state.message}
            </Badge>
          )}
          <Form.Control
            {...register("state", { required: "Wybierz stan zadania" })}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            <option>weryfikacja</option>
            <option>zlecone</option>
            <option>w realizacji</option>
            <option>do sprawdzenia</option>
            <option>zrobione</option>
            <option>odrzucone</option>
          </Form.Control>
        </>
      )}
      <Row className="justify-content-between mx-2">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        {user.type.admin ? (
          <Button onClick={() => console.log(watch())}>_</Button>
        ) : null}
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}

export default TodosDetailsEdit
