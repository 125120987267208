import { useState } from "react"
import { Container, Row, Table, Col, Button, Alert } from "react-bootstrap"
import { ImNewTab } from "react-icons/im"

const DailyStatsRender = ({ stats }) => {
  const [showLocations, setShowLocations] = useState("") // branch name
  const renderToiletsCount = () => {
    if (!stats.toiletsCount || !Object.keys(stats.toiletsCount)?.length)
      return "brak danych"
    else {
      const branches = Object.keys(stats.toiletsCount)
      return branches.map((branch) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            setShowLocations((prev) => (prev === branch ? "" : branch))
          }
          key={`branchLine-${branch}`}
        >
          {branch}: {stats.toiletsCount[branch]}
          <br />
        </span>
      ))
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <h4>Dane dla daty: {stats.date}</h4>
          <h5>Liczby toalet:</h5>
          <Alert variant="secondary">
            (kliknij nazwę oddziału, żeby zobaczyć szczegóły)
          </Alert>
          <br />
          {renderToiletsCount()}
        </Col>
        <Col>
          {showLocations ? (
            <LocationsToiletsCounts stats={stats} branch={showLocations} />
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

const LocationsToiletsCounts = ({ stats, branch }) => {
  if (!stats.src?.[branch]?.length) return `Brak danych dla oddziału ${branch}`

  const locations = stats.src[branch].reduce((locationsArr, loc) => {
    // check if location is already in array and add qty to string
    const locationInd = locationsArr.findIndex(
      (locArrEl) => locArrEl.loc_id === loc.loc_id
    )
    if (locationInd !== -1) {
      locationsArr[
        locationInd
      ].qty = `${locationsArr[locationInd].qty}; ${loc.qty}`
      locationsArr[locationInd].sum += loc.qty
    } else {
      locationsArr.push({ loc_id: loc.loc_id, qty: `${loc.qty}`, sum: loc.qty })
    }

    return locationsArr
  }, [])

  return (
    <>
      <Table>
        <tbody>
          <tr>
            <td>Link do lokalizacji</td>
            <td>Liczba toalet</td>
            <td>Suma</td>
          </tr>
          {locations.map((loc) => {
            if (loc.sum === 0) return null

            return (
              <tr key={`locationRow-${loc.loc_id}`}>
                <td>
                  <a
                    href={`https://cliperp.app/Locations/${loc.loc_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button>
                      <ImNewTab />
                    </Button>
                  </a>
                </td>
                <td>{loc.qty}</td>
                <td>{loc.sum}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export { DailyStatsRender }
