import React, { useState } from "react"
import { Container, Table, Button, Alert } from "react-bootstrap"
import _ from "lodash"
import { GrDown, GrNext } from "react-icons/gr"
import { FiRefreshCw } from "react-icons/fi"
import { sumIncome } from "./utils"
import {
  JobsDone,
  JobsUndone,
  AvgJobsPrices,
  AvgJobsDonePrices,
} from "./additionalRows"

const BranchesStatsRender = ({
  setModalData,
  reportData,
  handleMonthRecalculate,
}) => {
  const [showJobsDoneRows, setShowJobsDoneRows] = useState(false)
  const [showJobsUndoneRows, setShowJobsUndoneRows] = useState(false)
  const [showAvgPriceRows, setShowAvgPriceRows] = useState(false)
  const [showAvgDonePriceRows, setShowAvgDonePriceRows] = useState(false)

  const renderReportRow = (rowName, showSum = true) => {
    const row = reportData[rowName].map((el, i) => (
      <td key={`reportRow-${rowName}-${i}`}>
        {_.round(reportData[rowName][i], 2)}
      </td>
    ))

    row.push(
      showSum ? (
        <td>
          {_.round(
            reportData[rowName].reduce((a, r) => a + r),
            2
          )}
        </td>
      ) : (
        <td>-</td>
      )
    )

    row.push(
      <td>{_.round(reportData[rowName].reduce((a, r) => a + r) / 12, 2)}</td>
    )

    return row
  }

  const renderMonthsHeaders = () => {
    const months = []
    const currMonth = new Date().getMonth()
    for (let i = 0; i < 12; i++) {
      months.push(
        <td key={`monthHeader-${i}`}>
          {i + 1}{" "}
          {i < currMonth - 1 ? (
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => handleMonthRecalculate(i)}
            >
              {" "}
              <FiRefreshCw />{" "}
            </Button>
          ) : null}
        </td>
      )
    }
    return months
  }

  if (!Object.keys(reportData).length) return null

  return (
    <Container fluid>
      {reportData.reportErrors.length ? (
        <Alert variant="warning">
          <b>Raport może zawierać błędy.</b>
          <br />
          Zgłoś to w Jirze <u>załączając zrzut całego ekranu</u> i skopiowaną
          treść poniższego komunikatu (zaznacz {"->"} kopiuj i wklej w Jirze)
          <br />
          {reportData.reportErrors.map((err) => (
            <>
              {err}
              <br />
            </>
          ))}
        </Alert>
      ) : null}
      <Table>
        <tbody>
          <tr>
            <td>Miesiąc</td>
            {renderMonthsHeaders()}
            <td>SUMA</td>
            <td>ŚR</td>
          </tr>
          <tr>
            <td>
              Przychód KT - toalety{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeShortToilets"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeShortToilets")}
          </tr>
          <tr>
            <td>
              Przychód KT - pozostałe{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeShortOther"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeShortOther")}
          </tr>
          <tr>
            <td>
              Przychód DT - toalety{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeLongToilets"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeLongToilets")}
          </tr>
          <tr>
            <td>
              Przychód DT - pozostałe
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeLongOther"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeLongOther")}
          </tr>
          <tr>
            <td>
              Przychód sprz.{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeSell"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeSell")}
          </tr>
          <tr>
            <td>
              Przychód toalety klienta{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("incomeCustomersToilets"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("incomeCustomersToilets")}
          </tr>
          {/* show amounts that couldn't be fit anywhere (if any) */}
          {reportData.incomeUnknown?.reduce((a, r) => a + r) !== 0 ? (
            <tr>
              <td>BŁĄD - NIEZNANE</td>
              {renderReportRow("incomeUnknown")}
            </tr>
          ) : null}
          <tr>
            <td>
              <b>Przychód SUMA</b>
            </td>
            {sumIncome(reportData)}
          </tr>
          <tr>
            <td>
              Średnia liczba kabin DT
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("avgToilets"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("avgToilets", false)}
          </tr>
          <tr>
            <td>
              <Button
                size="sm"
                onClick={() => setShowJobsDoneRows(!showJobsDoneRows)}
              >
                {showJobsDoneRows ? <GrDown /> : <GrNext />}
              </Button>
              Serwisów wyk.
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("jobsDone"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("jobsDoneAll")}
          </tr>
          {showJobsDoneRows ? (
            <JobsDone
              renderReportRow={renderReportRow}
              reportData={reportData}
            />
          ) : null}
          <tr>
            <td>
              <Button
                size="sm"
                onClick={() => setShowJobsUndoneRows(!showJobsUndoneRows)}
              >
                {showJobsUndoneRows ? <GrDown /> : <GrNext />}
              </Button>
              Serwisów n-wyk.
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("jobsUndone"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {renderReportRow("jobsUndoneAll")}
          </tr>
          {showJobsUndoneRows ? (
            <JobsUndone
              renderReportRow={renderReportRow}
              reportData={reportData}
            />
          ) : null}
          <tr>
            <td>
              Średnia cena za kabinę DT{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("avgLongTermPrice"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {reportData.incomeLongToilets.map((income, i) => {
              if (reportData.avgToilets[i])
                return (
                  <td key={`avgLongTermPriceCell-${i}`}>
                    {_.round(
                      Number.parseFloat(income) /
                        Number.parseFloat(reportData.avgToilets[i]),
                      2
                    )}
                  </td>
                )
              else return <td key={`avgLongTermPriceCell-${i}`}>bd</td>
            })}
            <td>-</td>
            <td>
              {_.round(
                reportData.incomeLongToilets.reduce((acc, income, i) => {
                  if (reportData.avgToilets[i])
                    return (acc +=
                      Number.parseFloat(income) /
                      Number.parseFloat(reportData.avgToilets[i]))
                  else return acc
                }, 0) / 12,
                2
              )}
            </td>
          </tr>

          <tr>
            <td>
              <Button
                size="sm"
                onClick={() => setShowAvgPriceRows(!showAvgPriceRows)}
              >
                {showAvgPriceRows ? <GrDown /> : <GrNext />}
              </Button>
              Średnia cena za serwis{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("avgJobPrice"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {reportData.incomeToiletsAll.map((income, i) => {
              if (reportData.jobsDoneAll[i])
                return (
                  <td key={`avgLongTermPriceCell-${i}`}>
                    {_.round(
                      Number.parseFloat(income) /
                        Number.parseFloat(reportData.jobsAll[i]),
                      2
                    )}
                  </td>
                )
              else return <td key={`avgLongTermPriceCell-${i}`}>bd</td>
            })}
            <td>-</td>
            <td>
              {_.round(
                reportData.incomeToiletsAll.reduce((acc, income, i) => {
                  if (reportData.jobsAll[i])
                    return (acc +=
                      Number.parseFloat(income) /
                      Number.parseFloat(reportData.jobsAll[i]))
                  else return acc
                }, 0) / 12,
                2
              )}
            </td>
          </tr>
          {showAvgPriceRows ? <AvgJobsPrices reportData={reportData} /> : null}
          <tr>
            <td>
              <Button
                size="sm"
                onClick={() => setShowAvgDonePriceRows(!showAvgDonePriceRows)}
              >
                {showAvgDonePriceRows ? <GrDown /> : <GrNext />}
              </Button>
              Średnia cena za serwis wyk.{" "}
              <Button
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: chooseTipModalBody("avgJobDonePrice"),
                  })
                }
              >
                ?
              </Button>
            </td>
            {reportData.incomeToiletsAll.map((income, i) => {
              if (reportData.jobsDoneAll[i])
                return (
                  <td key={`avgJobDoneLongTermPriceCell-${i}`}>
                    {_.round(
                      Number.parseFloat(income) /
                        Number.parseFloat(reportData.jobsDoneAll[i]),
                      2
                    )}
                  </td>
                )
              else return <td key={`avgJobDoneLongTermPriceCell-${i}`}>bd</td>
            })}
            <td>-</td>
            <td>
              {_.round(
                reportData.incomeToiletsAll.reduce((acc, income, i) => {
                  if (reportData.jobsDoneAll[i])
                    return (acc +=
                      Number.parseFloat(income) /
                      Number.parseFloat(reportData.jobsDoneAll[i]))
                  else return acc
                }, 0) / 12,
                2
              )}
            </td>
          </tr>
          {showAvgDonePriceRows ? (
            <AvgJobsDonePrices reportData={reportData} />
          ) : null}
        </tbody>
      </Table>
    </Container>
  )
}

const chooseTipModalBody = (rowName) => {
  let tipModalBody = ""

  switch (rowName) {
    case "incomeShortAll":
      tipModalBody = (
        <p>
          Przychód ze wszystkich usług krótkoterminowych (naliczenia "krótki
          termin" i "ryczałt impreza"), <u>z wyłączeniem</u> usług z kategorią
          "sprzedaż" i "toalety klienta"{" "}
        </p>
      )
      break
    case "incomeShortToilets":
      tipModalBody = (
        <p>
          Przychód ze wszystkich usług krótkoterminowych (naliczenia "krótki
          termin" i "ryczałt impreza"), których kategoria to "Toalety"
        </p>
      )
      break
    case "incomeLongAll":
      tipModalBody = (
        <p>
          Przychód ze wszystkich usług długoterminowych (naliczenia "za sztukę",
          "za miesiąc" i "za dzień"), <u>z wyłączeniem</u> usług z kategorią
          "sprzedaż" i "toalety klienta"{" "}
        </p>
      )
      break
    case "incomeLongToilets":
      tipModalBody = (
        <p>
          Przychód ze wszystkich usług długoterminowych (naliczenia "za sztukę",
          "za miesiąc" i "za dzień"), których kategoria to "Toalety"
        </p>
      )
      break
    case "incomeSell":
      tipModalBody = <p>Przychód ze wszystkich usług z kategorią "Sprzedaż"</p>
      break
    case "incomeCustomersToilets":
      tipModalBody = (
        <p>Przychód ze wszystkich usług z kategorią "Toalety klienta"</p>
      )
      break
    case "avgToilets":
      tipModalBody = (
        <p>
          Średnia obliczana na podstawie codziennych statystyk określanych na
          koniec dnia. Za okres do 2022-01-03 wartości obliczone wstecznie na
          podstawie wykonanych zabrań i dostarczeń.
        </p>
      )
      break
    case "jobsDone":
      tipModalBody = (
        <>
          <p>
            Wszystkie <b>serwisy</b> (tzn. liczba serwisowanych toalet) ze
            stanem "wykonane" (niezależnie od rodzaju usługi i sprzętu). <br />
            Po rozwinięciu widoczny bardziej szczegółowy podział.
            <br />
            <b>Program nie uwzględnia (jeszcze) usług podrzędnych</b>
            <br />
            Zadania są liczone zgodnie z parametrami podpiętych usług. Parametry
            brane pod uwagę: typ (musi być "toalety"), naliczenie ("krótki
            termin" i "ryczałt impreza" trafiają do KT), sprzęt klienta (z
            wyjątkiem klientów TLC i Boels, dla nich każdy serwis trafia do TK)
          </p>
        </>
      )
      break
    case "jobsUndone":
      tipModalBody = (
        <p>
          Wszystkie <b>serwisy</b> ze stanem "niewykonane (my)" lub "niewykonane
          (klient)" (niezależnie od rodzaju usługi i sprzętu). <br />
          Reszta zastrzeżeń jak przy serwisach wykonanych
        </p>
      )
      break
    case "avgLongTermPrice":
      tipModalBody = (
        <p>
          Przychód DT - toalety / średnia liczba kabin
          <br />
          Uwaga! Średnia roczna wyliczana zawsze przez podzielenie przez 12,
          nawet jeśli dla którychś miesięcy jest "bd"
        </p>
      )
      break
    case "avgJobPrice":
      tipModalBody = (
        <p>
          (Przychód DT - toalety + Przychód KT - toalety) / liczba serwisów
          wykonanych i niewykonanych
          <br />
          Uwaga! Średnia roczna wyliczana zawsze przez podzielenie przez 12,
          nawet jeśli dla którychś miesięcy jest "bd"
        </p>
      )
      break
    case "avgJobDonePrice":
      tipModalBody = (
        <p>
          (Przychód DT - toalety + Przychód KT - toalety) / liczba serwisów
          wykonanych
          <br />
          Uwaga! Średnia roczna wyliczana zawsze przez podzielenie przez 12,
          nawet jeśli dla którychś miesięcy jest "bd"
        </p>
      )
      break

    default:
      break
  }
  return tipModalBody
}

export { BranchesStatsRender }
