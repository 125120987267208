import { useState, useEffect, useMemo, Fragment } from "react"
import { Container, Alert, Table, Button } from "react-bootstrap"
import { ImNewTab } from "react-icons/im"

import { nF } from "../../utils/formats"
import renderSpinner from "../../utils/renderSpinner"

const IncomeReportInvListModal = ({ invoices_ids, lines }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [invoices, setInvoices] = useState([])
  const [fetchError, setFetchError] = useState(null)

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const res = await fetch(`/invoices/getByIds`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ids: invoices_ids }),
        })
        const data = await res.json()
        setInvoices(data)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchError(err.message)
        setIsLoading(false)
      }
    }

    fetchInvoices()
  }, [invoices_ids])

  return (
    <Container>
      {fetchError ? <Alert variant="danger">{fetchError}</Alert> : null}
      {isLoading ? (
        renderSpinner("pobieram dane")
      ) : (
        <IncomeReportInvListRender invoices={invoices} lines={lines} />
      )}
    </Container>
  )
}

const IncomeReportInvListRender = ({ invoices, lines }) => {
  if (!invoices.length) return "brak faktur"
  return (
    <Table>
      <tbody>
        <tr className="fixed-values-table">
          <td>Klient</td>
          <td>Wartość do raportu</td>
          <td>Całość faktury</td>
          <td></td>
        </tr>
        {invoices.map((inv) => (
          <InvRow inv={inv} lines={lines} key={`invRowComponent-${inv._id}`} />
        ))}
      </tbody>
    </Table>
  )
}

const InvRow = ({ inv, lines }) => {
  const [showDetails, setShowDetails] = useState(false)

  // get lines for this invoice:
  const linesForInv = useMemo(() => {
    const invProducts_ids = inv.InvoiceDetails.productsResults.map(
      (prod) => prod._id
    )
    return lines.filter((line) => invProducts_ids.includes(line._id))
  }, [inv, lines])

  const renderDetails = () => {
    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="2">
              Pozycje faktury zakwalifikowane do tej komórki raportu
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="fixed-values-table">
            <td>nazwa</td>
            <td>wartość</td>
          </tr>
          {inv.InvoiceDetails.productsResults.map((prod) => {
            // show invoice product only if it is on the list of lines:
            const line = linesForInv.find((line) => line._id === prod._id)

            if (!line) return null

            return (
              <Fragment key={`invProdRow-${inv._id}-${prod._id}`}>
                <tr>
                  <td>{prod.productCode}</td>
                  <td>{nF(line.amount)}</td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <tr key={`invRow-${inv._id}`}>
        <td>
          <a
            href={`/CustomersDetails/${inv.clientResult?.ref?._id}`}
            target="_blank"
            rel="noopener noreferrer"
            alt=""
          >
            {inv.clientResult?.ref?.shortName || "BŁĄD: brak nazwy klienta"}{" "}
            <ImNewTab />
          </a>
        </td>
        <td>
          <b>
            {linesForInv.length
              ? nF(linesForInv.reduce((acc, line) => acc + line.amount, 0))
              : "BŁĄD"}
          </b>
        </td>
        <td>
          {nF(
            inv.InvoiceDetails?.payments?.InvoiceCurrency?.InvoiceProductsCost
          )}
        </td>
        <td>
          <Button onClick={() => setShowDetails(!showDetails)}>
            szczegóły
          </Button>
        </td>
      </tr>
      {showDetails ? (
        <tr>
          <td colSpan="4">{renderDetails()}</td>
        </tr>
      ) : null}
    </>
  )
}

export { IncomeReportInvListModal }
