import { Fragment, useState, useEffect } from "react"
import {
  Container,
  Form,
  Alert,
  Button,
  Row,
  Table,
  Col,
} from "react-bootstrap"

import { Controller, useForm } from "react-hook-form"
import { createManualBtn } from "../../utils/buttons"

import renderSpinner from "../../utils/renderSpinner"

const LocationDetailsSubSrvEditModal = ({
  setModalData,
  refresh,
  location,
  srv,
  updateCanHaveSubSrv,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchErr, setFetchErr] = useState(null)
  const [subSrvArr, setSubSrvArr] = useState([])
  const [showShortManual, setShowShortManual] = useState(false)
  const [disableBtns, setDisableBtns] = useState(false)
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const res = await fetch("/services/getFiltered", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: { canBeSubSrv: true } }),
        })
        if (res.status !== 200)
          throw new Error(
            `Błąd komunikacji z serwerem: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )

        const resJSON = await res.json()

        setSubSrvArr(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchErr(err.message || "nieokreślony błąd komunikacji z serwerem")
      }
    }

    fetchServices()
  }, [])

  return (
    <Container fluid>
      <Row className="mb-2">
        <Button
          size="sm"
          className="ml-auto mr-2"
          onClick={() => setShowShortManual(!showShortManual)}
        >
          Skrócona instrukcja
        </Button>
        {createManualBtn("subSrvs")}
      </Row>
      {showShortManual ? renderShortManual() : null}
      {isLoading ? (
        renderSpinner("pobieram dane...")
      ) : (
        <SubSrvForm
          subSrvArr={subSrvArr}
          srv={srv}
          setModalData={setModalData}
          refresh={refresh}
          location={location}
          setFetchErr={setFetchErr}
          updateCanHaveSubSrv={updateCanHaveSubSrv}
          disableBtns={disableBtns}
          setDisableBtns={setDisableBtns}
        />
      )}
      {fetchErr ? <Alert variant="danger">{fetchErr}</Alert> : null}
    </Container>
  )
}

const SubSrvForm = ({
  subSrvArr,
  srv,
  location,
  setModalData,
  refresh,
  setFetchErr,
  updateCanHaveSubSrv,
  setDisableBtns,
  disableBtns,
}) => {
  //TODO handle default values

  const defaultValues = prepareDefaults(subSrvArr, srv)

  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: defaultValues,
  })

  /**
   * @returns confirmation modal with 'applyToJobs' checkbox
   */
  const confModalBody = () => (
    <>
      <p className="bg-warning">
        Uwaga!
        <br />
        Zaakceptowanie zmiany spowoduje <b>nadpisanie</b> usług podrzędnych dla{" "}
        <u>wszystkich</u> zaplanowanych zadań, które dotyczą tej usługi.
      </p>
      <p>
        Można to potem "poprawić" korzystając z MEZ. Szczegóły:{" "}
        {createManualBtn("subSrvJobsEdit")}
      </p>
      <Row className="justify-content-between mx-3">
        <Button
          onClick={() => setModalData({ show: false })}
          variant="warning"
          disabled={disableBtns}
        >
          Anuluj
        </Button>
        <Button
          onClick={() => submit(watch())}
          variant="secondary"
          disabled={disableBtns}
        >
          Zapisz
        </Button>
      </Row>
    </>
  )

  const submit = async (formData) => {
    try {
      setDisableBtns(true)
      // convert RHF input to proper arrays:
      const subSrvReady = Object.keys(formData.subSrv)
        .map((subSrv_id) => (formData.subSrv[subSrv_id] ? subSrv_id : null))
        .filter((el) => el)
      const extraFees = Object.keys(formData.extraFees)
        .map((extraFeesKey) =>
          formData.extraFees[extraFeesKey]?.amount
            ? {
                srvRef: extraFeesKey,
                locSrvRef: srv._id,
                amount: formData.extraFees[extraFeesKey].amount,
                invInfo: formData.extraFees[extraFeesKey].invInfo,
              }
            : null
        )
        .filter((el) => el)

      setFetchErr(null)
      const res = await fetch("/locations/editSubSrv", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subSrvArr: subSrvReady,
          extraFees: extraFees,
          location_id: location._id,
          locHistoryLength: location.history?.length || location.historyLength,
          srv_id: srv._id,
          updateCanHaveSubSrv: updateCanHaveSubSrv,
          applyToJobs: true, // maybe someday I'll add checkbox so user can decide if change should be applied to jobs
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      return refresh({
        headerColor: "bg-successLight",
        header: "Sukces",
        body: "Zmiany w usłudze zapisane",
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "info",
        headerColor: "danger",
        header: "Błąd",
        body: err.message || "nieokreślony błąd komunikacji z serwerem",
        onHide: refresh,
      })
    }
  }

  return (
    <Container fluid>
      <h5>Wybierz usługi podrzędne</h5>
      <Form onSubmit={handleSubmit(submit)}>
        <Table bordered>
          <tbody>
            <tr className="fixed-values-table">
              <td>Usługa</td>
              {/* <td>Udział w przychodzie [%]</td> */}
              <td>Doliczyć za serwis</td>
              <td>
                Info na <b>fakturę</b>
              </td>
            </tr>

            {subSrvArr.map((subSrv) => {
              return (
                <tr key={`subSrvCheckbox-${subSrv._id}`}>
                  <td>
                    <Controller
                      control={control}
                      name={`subSrv.${subSrv._id}`}
                      render={({ field: { onChange, value } }) => (
                        <Form.Check
                          type="checkbox"
                          label={subSrv.name}
                          value={true}
                          checked={value}
                          id={`subSrvCheckbox-${subSrv._id}`}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setValue(`extraFees.${subSrv._id}.amount`, null)
                              setValue(`extraFees.${subSrv._id}.invInfo`, null)
                            }
                            onChange(e)
                          }}
                        />
                      )}
                    />
                  </td>

                  {subSrv.allowExtraFee ? (
                    <Fragment>
                      <td>
                        <Row>
                          <Col>
                            <Form.Control
                              {...register(`extraFees.${subSrv._id}.amount`)}
                              as="input"
                              type="number"
                              className="medium-number-input"
                              autoComplete="chrome-off"
                              readOnly={!watch(`subSrv.${subSrv._id}`)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="m-0">
                              (wpisz coś, <u>jeśli</u> klient ma być
                              <br />
                              dodatkowo obciążony za każdy <br />
                              serwis związany z tą usługą)
                            </p>
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <Row>
                          <Col>
                            <Button
                              className="mb-1"
                              disabled={
                                !watch(`extraFees.${subSrv._id}.amount`) ||
                                !watch(`subSrv.${subSrv._id}`)
                              }
                              variant="secondary"
                              onClick={() =>
                                setValue(
                                  `extraFees.${subSrv._id}.invInfo`,
                                  subSrv.defaultInvLine
                                )
                              }
                            >
                              wczytaj domyślny opis usługi
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Control
                              {...register(`extraFees.${subSrv._id}.invInfo`)}
                              as="textarea"
                              autoComplete="chrome-off"
                              readOnly={
                                !watch(`extraFees.${subSrv._id}.amount`) ||
                                !watch(`subSrv.${subSrv._id}`)
                              }
                            />
                          </Col>
                        </Row>
                      </td>
                    </Fragment>
                  ) : (
                    <td colSpan={2}>
                      <Alert variant="secondary">
                        Dla tej usługi nie można doliczyć opłaty.
                      </Alert>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Row className="justify-content-between mx-3 mt-3">
          <Button
            variant="primary"
            onClick={() => setModalData({ show: false })}
          >
            Anuluj
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Potwierdź zmianę",
                body: confModalBody(),
                hideFooter: true,
              })
            }
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
/**
 *
 * @param {array} subSrv array of subSrv from .services collection
 * @param {object} srv currently edited service
 */
const prepareDefaults = (subSrv, srv) => {
  const defaultValues = { subSrv: {}, extraFees: {} }
  subSrv.forEach((subSrvEl) => {
    defaultValues.subSrv[subSrvEl._id] = srv.subSrv?.find(
      (srvSubSrv) => srvSubSrv._id === subSrvEl._id
    )

    const extraFeeForSubSrv = srv.extraFees?.find(
      (extraFeesEl) => extraFeesEl.srvRef === subSrvEl._id
    )

    if (extraFeeForSubSrv) {
      defaultValues.extraFees[subSrvEl._id] = {
        amount: extraFeeForSubSrv.amount,
        invInfo: extraFeeForSubSrv.invInfo,
      }
    }
  })

  return defaultValues
}

const renderShortManual = () => {
  return (
    <ul>
      <li>
        Zaznacz checkbox przy usłudze, żeby można było ją podpiąć do zadań
      </li>
      <li>
        Wpisz coś w polu "Doliczyć za serwis", żeby program automatycznie
        uwzględnił kwotę <b>na podstawie serwisów i liczby sprzętu</b>. Zostaw
        puste jeśli usługa jest w cenie usługi głównej
      </li>
      <li>
        Wpisz coś w "Info na fakturę", żeby{" "}
        <b>dodać na fakturze nową pozycję</b> opisującą skąd wzięła się
        dodatkowa kwota. Zostaw puste jeśli program ma zmodyfikować cenę
        jednostkową usługi głównej (nie będzie dodatkowej pozycji)
      </li>
      <li>Odznaczenie checkboxa powoduje wyczyszczenie wprowadzonych danych</li>
    </ul>
  )
}

export { LocationDetailsSubSrvEditModal }
