import React, { useContext, useState } from "react"
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  Alert,
  Badge,
} from "react-bootstrap"

import { useForm } from "react-hook-form"

import { CustomerCtx } from "../Fetch"
import CustomersAddressDetailsModal from "./CustomersAddressDetailsModal"

import { entityAdd, entityEdit } from "./submit"

const CustomersAddressEditModal = (props) => {
  const { setModalData, user, customer, refresh, configs } = useContext(
    CustomerCtx
  )
  const { address } = props
  const [fetchError, setFetchError] = useState(false)

  // prevent showing type "główny" when there is any OTHER (check by _id) contact of this type:
  const canBeMain =
    customer.addresses.filter(
      (adr) =>
        adr.state === "aktywny" &&
        adr.type === "główny" &&
        adr._id !== address._id
    ).length === 0
      ? true
      : false

  const {
    register,
    handleSubmit,
    formState,
  } = useForm({
    defaultValues:
      address === "new"
        ? {
            type: null,
            name: null,
            mail: null,
            phone: null,
            note: null,
            fax: null,
          }
        : { ...address },
  })

  const {
    errors,
  } = formState;

  const { dirtyFields } = formState

  const submit = (formData) => {
    try {
      if (address === "new")
        entityAdd(
          "addresses",
          { ...formData, state: "aktywny" },
          customer,
          setFetchError,
          setModalData,
          refresh
        )
      else
        entityEdit(
          "addresses",
          { ...formData, _id: address._id },
          dirtyFields,
          address.history.length,
          customer,
          setFetchError,
          setModalData,
          refresh
        )
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Container>
      <Row className="mb-1">
        {address === "new" ? null : (
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Szczegóły kontaktu",
                xl: true,
                body: <CustomersAddressDetailsModal address={address} />,
              })
            }
            className="mr-auto ml-3"
          >
            Wróć
          </Button>
        )}
      </Row>

      <Form onSubmit={handleSubmit(submit)}>
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table">
                Typ{" "}
                {errors.type && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table" style={{ width: "75%" }}>
                Nazwa{" "}
                {errors.name && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register('type', { required: true })}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off">
                  {configs.customerAddressTypes.map((type) => {
                    if (type === "główny" && !canBeMain) return null
                    else return <option value={type}>{type}</option>
                  })}
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  {...register('name', { required: true })}
                  as="input"
                  type=""
                  className=""
                  autoComplete="chrome-off" />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Adres (ulica, nr posesji, nr mieszkania){" "}
                {errors.street && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register('street', { required: true })}
                  as="input"
                  type=""
                  className=""
                  autoComplete="chrome-off" />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">
                Kod pocztowy{" "}
                {errors.postal && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
              <td className="fixed-values-table">
                Miasto{" "}
                {errors.city && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Form.Control
                  {...register('postal', { required: true })}
                  as="input"
                  type=""
                  className=""
                  autoComplete="chrome-off" />
              </td>
              <td>
                <Form.Control
                  {...register('city', { required: true })}
                  as="input"
                  type=""
                  className=""
                  autoComplete="chrome-off" />
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Notatka
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register('note')}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off" />
              </td>
            </tr>
          </tbody>
        </Table>
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row className="justify-content-between">
          <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
          {user.perm.customers.d && address !== "new" ? (
            <Button variant="danger">Usuń</Button>
          ) : null}
          <Button
            variant="secondary"
            type="submit"
            className={address === null ? "ml-auto" : null} // pushing button to right when no other buttons
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  );
}
export default CustomersAddressEditModal
