import { getEqpDesc } from "../../utils/getEqpDesc"

const createEqpSummary = (jobs) => {
  const eqpSummary = []
  /* 
eqpSummary element:

{
    name: "", // created with getEqpDesc
    srvType: "", // "shortTerm"/"longTerm"
    pickups: 0,
    deliveries: 0,
    pickupsEqpCount: 0,
    deliveriesEqpCount: 0,
}

*/

  // for each job check all equipment and create eqpSummary element or update existing

  jobs.forEach((job) => {
    job.services.forEach((srv) => {
      // get service data from location:
      const srvData = job.location.services.find(
        (locSrv) => locSrv._id === srv.locationServiceRef
      )
      if (!srvData)
        throw new Error(
          `W danych lokalizacji brakuje usługi: locationServiceRef: ${srv.locationServiceRef}, serviceRef: ${srv.serviceRef}, job: ${job._id}`
        )

      srv.eqp.forEach((eqp) => {
        const description = getEqpDesc(eqp, {}, true, false)
        const srvType = srv.srvType

        // get eqpSummary index by name and srvType
        let eqpSummaryInd = eqpSummary.findIndex(
          (eqpSummaryEl) =>
            eqpSummaryEl.name === description &&
            eqpSummaryEl.srvType === srvType
        )

        // if no eqpSummary element with this name and srvType, create new one
        // and set eqpSummaryInd to last arr element to update qtys later
        if (eqpSummaryInd === -1) {
          eqpSummary.push({
            name: description,
            srvType: srvType,
            pickups: 0,
            deliveries: 0,
            pickupsEqpCount: 0,
            deliveriesEqpCount: 0,
          })
          eqpSummaryInd = eqpSummary.length - 1
        }

        // update qtys
        if (job.type === "dostarczenie") {
          eqpSummary[eqpSummaryInd].deliveriesEqpCount += Number.parseFloat(
            eqp.qty
          )
          eqpSummary[eqpSummaryInd].deliveries += 1
        } else if (job.type === "zabranie") {
          eqpSummary[eqpSummaryInd].pickupsEqpCount += Number.parseFloat(
            eqp.qty
          )
          eqpSummary[eqpSummaryInd].pickups += 1
        }
      })
    })
  })

  return eqpSummary
}

const getSrvType = (srv, job) => {
  if (
    srv.chargeType === "za dzień" ||
    srv.chargeType === "za miesiąc" ||
    srv.chargeType === "za serwis"
  )
    return "longTerm"
  else if (
    srv.chargeType === "krótki termin" ||
    srv.chargeType === "ryczałt impreza"
  )
    return "shortTerm"
  else
    throw new Error(
      `Nieznane rodzaj naliczenia (${srv.chargeType}) dla usługi ${srv.serviceRef}, zadanie ${job._id}`
    )
}

const addSrvType = (jobs) => {
  if (!jobs?.length) return null

  jobs.forEach((job) => {
    job.services.forEach((jobSrv) => {
      // find service chargetype in location data\
      const locSrv = job.location.services.find(
        (locSrv) => locSrv._id === jobSrv.locationServiceRef
      )

      // add srvType to jobSrv
      jobSrv.srvType = getSrvType(locSrv, job)
    })
  })
}

const getDrivers = (jobs) => {
  const drivers = jobs.reduce((acc, job) => {
    if (!acc.find((accDriver) => accDriver._id === job.driver._id))
      acc.push(job.driver)

    return acc
  }, [])

  return drivers
}

export { createEqpSummary, addSrvType, getDrivers }
