import React, { useContext, useState } from "react"
import _ from "lodash"
import { Container, Row, Table, Button, Alert } from "react-bootstrap"

import { LocationCtx } from "../Fetch"
import LocationsDetailsServiceEditModal from "./LocationDetailsServiceEditModal"
import LocationsDetailsServiceAddModal from "./LocationsDetailsServiceAddModal"
import HistoryTable from "../../utils/HistoryTable"

const LocationsDetailsServiceDetailsModal = ({ srv }) => {
  const { setModalData } = useContext(LocationCtx)
  const [showSrvDetails, setShowSrvDetails] = useState(false)

  const renderSrvDetails = () => {
    const renderBool = (val) => {
      if (val === true) return "tak"
      if (val === false) return "nie"
      return "bd"
    }

    if (!showSrvDetails) return null
    return (
      <Row>
        Sprzętowa:<b> {renderBool(srv.mustHaveEqp)}</b> Sprzęt klienta:
        <b> {renderBool(srv.custEqp)}</b> Może być podusługą:
        <b> {renderBool(srv.canBeSubSrv)}</b> Może mieć podusługi:
        <b> {renderBool(srv.canHaveSubSrv)}</b> Musi mieć zadania:
        <b> {renderBool(srv.mustHaveJobs)}</b> Sprzęt:
        <b> {srv.eqpTypes.join(", ")}</b>
      </Row>
    )
  }

  return (
    <Container>
      {(srv.chargeType === "krótki termin" ||
        srv.chargeType === "ryczałt impreza") &&
      srv.params[0].invoicedTo ? (
        <Alert variant="warning">
          Ta usługa imprezowa została już zafakturowana - edycja jest{" "}
          <b>wyłączona</b>. Jeśli potrzebujesz edytować taką usługę - proszę o
          kontakt.
        </Alert>
      ) : null}
      <Row className="justify-content-between">
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: "Historia usługi",
              body: <HistoryTable history={srv.history} />,
            })
          }
        >
          Historia
        </Button>
        <Button onClick={() => setShowSrvDetails((prev) => !prev)}>
          Właściwości usługi
        </Button>

        {renderEditBtn(srv, setModalData)}
      </Row>
      {renderSrvDetails()}
      <Table>
        <thead>
          <tr>
            <th colSpan="6">Szczegóły</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Nazwa
            </td>
            <td className="fixed-values-table">Typ</td>
            <td className="fixed-values-table">Naliczenie</td>
            <td className="fixed-values-table">Cena netto</td>
            <td className="fixed-values-table">jm.</td>
            <td className="fixed-values-table">Vat</td>
            <td className="fixed-values-table">Cena brutto</td>
          </tr>
          <tr>
            <td colSpan="3">{srv.name}</td>
            <td>{srv.type}</td>
            <td>{srv.chargeType}</td>
            <td id="netPriceCell">{srv.netPrice}</td>
            <td>{srv.unit}</td>
            <td>{srv.vat}%</td>
            <td>
              {_.round(
                Number.parseFloat(srv.netPrice) *
                  ((Number.parseFloat(srv.vat) + 100) / 100),
                2
              )}
            </td>
          </tr>
          {srv.chargeType === "krótki termin" ? (
            <>
              <tr>
                <td className="fixed-values-table">cena netto - liczba dni</td>
                <td>{srv.initPeriod}</td>
                <td className="fixed-values-table">
                  cena za każdy kolejny dzień
                </td>
                <td>{srv.additionalPrice}</td>
                <td className="fixed-values-table">cena za serwis</td>
                <td>{srv.jobPrice}</td>
              </tr>
              <tr>
                <td colSpan={6} className="fixed-values-table">
                  Kalkulacja/opis
                </td>
              </tr>
              <tr>
                <td colSpan={6}>{srv.calculation}</td>
              </tr>
            </>
          ) : null}
          {srv.chargeType === "ryczałt impreza" && (
            <tr>
              <td className="fixed-values-table">Kalkulacja</td>
              <td>{srv.calculation}</td>
            </tr>
          )}
          <tr>
            <td className="fixed-values-table" colSpan="6">
              Opis na FV
            </td>
          </tr>
          <tr>
            <td colSpan="5">{srv.invLine}</td>
          </tr>
        </tbody>
      </Table>
      Sprzęt:
      <Table>
        <tbody>
          <tr className="fixed-values-table">
            <td> Typ </td>
            <td> Liczba</td>
            <td> Podtyp </td>
            <td> Charakterystyka </td>
            <td> Kolor </td>
            <td> Kategoria </td>
            <td> Modyfikacje </td>
            <td> Numer </td>
          </tr>
          {srv.equipment.length ? (
            srv.equipment.map((eqp) => (
              <tr key={`service-eqp-tr-${eqp._id}`}>
                <td> {eqp.ref.type} </td>
                <td> {eqp.qty} </td>
                <td> {eqp.ref.subtype} </td>
                <td> {eqp.ref.char} </td>
                <td> {eqp.ref.color} </td>
                <td> {eqp.ref.category} </td>
                <td> {eqp.ref.mods.join(", ")} </td>
                <td> {eqp.ref.isTemplate ? "templatka" : eqp.number} </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">Brak sprzętu</td>
            </tr>
          )}
        </tbody>
      </Table>
      {srv.subSrv?.length ? (
        <>
          <h6>Usługi podrzędne:</h6>
          <ul>
            {srv.subSrv.map((subSrv) => (
              <li key={`subSrv-li-${subSrv._id}`}>{subSrv.name}</li>
            ))}
          </ul>
        </>
      ) : null}
    </Container>
  )
}

const renderEditBtn = (srv, setModalData) => {
  // don't show edit btn for short term services if it is invoiced
  if (
    (srv.chargeType === "krótki termin" ||
      srv.chargeType === "ryczałt impreza") &&
    srv.params[0].invoicedTo
  )
    return null

  // don't show edit btn if srv is not active:
  if (srv.state !== "aktywna") return null

  return (
    <Button
      onClick={() =>
        setModalData({
          show: true,
          type: "info",
          xl: true,
          header: "Edycja usługi",
          body:
            // ok, it's sick, but for short term services edit I use service add modal, else I use sth totally different
            srv.chargeType === "ryczałt impreza" ||
            srv.chargeType === "krótki termin" ? (
              <LocationsDetailsServiceAddModal
                serviceChoosen={srv}
                isNew={false}
              />
            ) : (
              <LocationsDetailsServiceEditModal
                serviceChoosen={srv}
                isNew={false}
              />
            ),
        })
      }
    >
      Edytuj
    </Button>
  )
}
export default LocationsDetailsServiceDetailsModal
