import { useContext, useMemo, useState, useRef, useEffect } from "react"
import _ from "lodash"
import {
  Container,
  Row,
  Table,
  Button,
  Alert,
  Form,
  Col,
} from "react-bootstrap"
import { FiTruck } from "react-icons/fi"
import { TiArrowShuffle } from "react-icons/ti"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"
import { LogCtx } from "./Fetch"

import { useReactToPrint } from "react-to-print"

import { checkMustHaveEqpJobs } from "../../utils/jobs"

import RoutesPlanningChangeDriverModal from "./modals/ChangeDriverModal"
import CarAssign from "../../_shared/cars/CarAssign"
import RoutesPlanningJobCard from "./JobCard/JobCard"
import { countEqp } from "./utils"
import { createManualBtn } from "../../utils/buttons"
import { ChangeOrderModal } from "./modals/ChangeOrderModal"

const RoutesPlanningAdditionalTab = () => {
  const {
    jobs,
    date,
    setModalData,
    user,
    configs,
    refresh,
    cars,
    dateSelected,
    setPageTitle,
  } = useContext(LogCtx)
  const [jobsAlert, setJobsAlert] = useState(null)
  const [driversSelected, setDriversSelected] = useState([])
  const jobsCards = useRef()

  const handlePrint = useReactToPrint({
    content: () => jobsCards.current,
    onAfterPrint: () => refresh(),
  })

  const noDriverJobs = jobs
    .map((job) => {
      if (!job.hasDriver) return job
      else return null
    })
    .filter((el) => el)

  // check if there is any job with service that requires equipment, but there is no eqp selected for this job
  const jobsMustHaveEqpCheck = checkMustHaveEqpJobs(jobs)

  // check if there is any job without car:
  const jobsWithoutCar = jobs.filter((job) => !job.car)

  const driversStats = useMemo(() => {
    // create array to hold drivers info:
    const driversArr = []

    jobs.forEach((job) => {
      // console.log(job)
      //prevent adding jobs without driver to stats array:
      if (job.hasDriver) {
        // seek for driver in array...
        const driverInd = driversArr.findIndex(
          (driver) => driver._id === job.driver._id
        )
        // ...if not found -> add, and push data about this job
        if (driverInd === -1) {
          const newDriver = {
            _id: job.driver._id,
            fullName: job.driver.fullName,
            largeJobCardFont: job.driver.largeJobCardFont,
            stats: {
              jobs: 1,
              locations_ids: [job.location._id], // when rendering I just use lodash uniq
              eqp: countEqp(job),
              pickups: 0,
              delivers: 0,
              pickupsQty: 0,
              deliversQty: 0,
            },
            jobs: [job],
            isSelected: false,
            cars: [],
            customerCardsJobs: [],
            shortTermJobs: [], // add any job that includes short term srv
          }
          if (job.type === "dostarczenie") {
            newDriver.stats.delivers = 1
            newDriver.stats.deliversQty = countEqp(job)
          }
          if (job.type === "zabranie") {
            newDriver.stats.pickups = 1
            newDriver.stats.pickupsQty = countEqp(job)
          }

          // when job has car assigned -> add it to driver stats
          if (job.car) newDriver.cars = [job.car]

          // if customer want customJobCards
          if (job.location.customer.customJobCards)
            newDriver.customerCardsJobs.push(job)

          // if there is short term service included:
          const shortTermJobSrvs = job.services.filter(
            (jobSrv) =>
              jobSrv.serviceRef.chargeType === "krótki termin" ||
              jobSrv.serviceRef.chargeType === "ryczałt impreza"
          )
          if (shortTermJobSrvs.length) {
            newDriver.shortTermJobs.push(job)
          }

          driversArr.push(newDriver)
        } else {
          // update jobs count:
          driversArr[driverInd].stats.jobs++
          driversArr[driverInd].stats.eqp += countEqp(job)
          driversArr[driverInd].stats.locations_ids.push(job.location._id)

          if (job.type === "dostarczenie") {
            driversArr[driverInd].stats.delivers++
            driversArr[driverInd].stats.deliversQty += countEqp(job)
          }
          if (job.type === "zabranie") {
            driversArr[driverInd].stats.pickups++
            driversArr[driverInd].stats.pickupsQty += countEqp(job)
          }

          // check if car is used by driver, if not -> push new car
          if (
            job.car &&
            driversArr[driverInd].cars.findIndex(
              (driverCar) => driverCar._id === job.car._id
            ) === -1
          )
            driversArr[driverInd].cars.push(job.car)

          //push job:
          driversArr[driverInd].jobs.push(job)

          // if customer want customJobCards
          if (job.location.customer.customJobCards)
            driversArr[driverInd].customerCardsJobs.push(job)

          // if there is short term service included:
          const shortTermJobSrvs = job.services.filter(
            (jobSrv) =>
              jobSrv.serviceRef.chargeType === "krótki termin" ||
              jobSrv.serviceRef.chargeType === "ryczałt impreza"
          )
          if (shortTermJobSrvs.length) {
            driversArr[driverInd].shortTermJobs.push(job)
          }
        }
      }
    })

    return driversArr
  }, [jobs])

  useEffect(() => {
    if (driversSelected.length === 1) {
      const name =
        driversStats.find((driverObj) => driverObj._id === driversSelected[0])
          ?.fullName || ""

      setPageTitle(`Planowanie - ${name}`)
    } else setPageTitle("Planowanie")
  }, [driversSelected, setPageTitle, driversStats])

  const changeJobsState = async () => {
    try {
      const data = {
        jobs: [],
        modifications: { state: { apply: true, newValue: "zlecone" } },
      }

      // prepare jobs to change state

      data.jobs = jobs
        .map((job) => {
          if (
            driversSelected.findIndex((drv_id) => drv_id === job.driver._id) !==
            -1
          )
            return { _id: job._id, historyLength: job.history.length }
          else return null
        })
        .filter((el) => el)

      if (!data.jobs.length)
        throw new Error("Brak zadań do utworzenia kontrolki")

      const res = await fetch("/jobs/batchEdit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: "Brak uprawnień",
        })
      if (res.status === 409)
        return setModalData({
          show: true,
          type: "alert",
          body: "Ktoś w międzyczasie edytował któreś z zadań. Musisz pobrać aktualne dane - odświeżyć stronę",
        })
      if (res.status !== 200) throw res
      setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        header: "Sukces",
        body: "Stan zadań zmieniony na 'zlecone'",
        onHide: refresh,
      })
      handlePrint()
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd działania programu: ${err.message}`,
      })
    }
  }

  return (
    <Container fluid>
      <h3>Narzędzia:</h3>
      <Row className="justify-content-between">
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "Zamiana kierowców",
              hideFooter: true,
              body: <RoutesPlanningChangeDriverModal />,
            })
          }
        >
          Zamień kierowców
        </Button>
        <Button
          onClick={() =>
            alert(
              "DO ZROBIENIA: wyśle maile i smsy (w zależności od ustawień na LOKALIZACJI) do klientów o zaplanowanym zadaniu (ale tylko do tych, u których jest zaznaczona odpowiednia opcja w lokalizacji)"
            )
          }
        >
          Wyślij powiadomienia
        </Button>
      </Row>

      {/* RENDER jobs without drivers: */}
      {noDriverJobs.length && (
        <Table className="my-3">
          <tbody>
            <tr>
              <td className="bg-warning">Zadania bez kierowcy:</td>
            </tr>
            <tr className="bg-warningLight">
              <td>
                {noDriverJobs.map((job) => (
                  <Button
                    key={`noDriverJobBtn-${job._id}`}
                    variant="secondary"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        hideFooter: true,
                        header: "Edycja zadania",
                        body: (
                          <JobDetailsModal
                            parent="Logistics"
                            job={job}
                            user={user}
                            configs={configs}
                            setModalData={setModalData}
                            refresh={refresh}
                            location={job.location}
                            cars={cars}
                          />
                        ),
                      })
                    }
                  >
                    {job.location.name}
                  </Button>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
      )}

      {/* when any job has service that requires equipment, but there is no eqp attached */}
      {jobsMustHaveEqpCheck.length !== 0 && (
        <Alert variant="danger">
          Zadania z podpiętymi usługami "sprzętowymi", ale bez sprzętu (edytuj
          zadania aby umożliwić wydrukowanie kontrolek):{" "}
          {jobsMustHaveEqpCheck
            .map(
              (job) =>
                `${job.location.customer.shortName} - ${job.location.name}`
            )
            .join(", ")}
        </Alert>
      )}
      {jobsWithoutCar.length !== 0 && (
        <Alert variant="danger">
          Nie wszystkie zadania dla następujących kierowców mają przypisany
          samochód:{" "}
          {_.uniq(jobsWithoutCar.map((job) => `${job.driver?.fullName}`)).join(
            ", "
          )}
        </Alert>
      )}
      {jobsAlert && <Alert variant="warning">{jobsAlert}</Alert>}
      <Table className="my-2">
        <thead>
          <tr>
            <th colSpan="6">Statystyki</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">
              <Form.Check
                name="selectAll"
                type="checkbox"
                id="selectAll"
                onClick={(e) => {
                  if (e.target.checked) {
                    const allDriversIds = driversStats.map((drv) => {
                      // set driver checkbox as selected
                      document.getElementById(
                        `driverChbx-${drv._id}`
                      ).checked = true

                      // return id to set driversSelected

                      return drv._id
                    })
                    setDriversSelected(allDriversIds)
                  } else {
                    setDriversSelected([])
                    driversStats.forEach((drv) => {
                      // set driver checkbox as not selected
                      document.getElementById(
                        `driverChbx-${drv._id}`
                      ).checked = false
                    })
                  }
                }}
              />
            </td>
            <td className="fixed-values-table">Kierowca</td>
            <td
              className="fixed-values-table"

              //TODO show alert IN TABLE when no all jobs for driver have car assigned
            >
              Samochody
            </td>
            <td className="fixed-values-table">Zadania/lokalizacje/sprzęt</td>
            <td className="fixed-values-table">Dostarczenia (zadań/sprzęt)</td>
            <td className="fixed-values-table">Zabrania (zadań/sprzęt)</td>
            <td className="fixed-values-table">Akcje</td>
          </tr>
          {driversStats.map((driver) => (
            <tr key={`drivers-stats-${driver._id}`}>
              <td>
                <Form.Check
                  name={`driverChbx-${driver._id}`}
                  type="checkbox"
                  id={`driverChbx-${driver._id}`}
                  onChange={(e) => {
                    const newDriversArr = [...driversSelected]
                    if (e.target.checked) {
                      newDriversArr.push(driver._id)
                    } else {
                      const driverInd = driversSelected.findIndex(
                        (drv_id) => drv_id === driver._id
                      )
                      newDriversArr.splice(driverInd, 1)
                    }
                    setDriversSelected(newDriversArr)
                  }}
                />
              </td>
              <td>{driver.fullName}</td>
              <td>
                <Row className="justify-content-between">
                  {driver.cars.map((car) => car.regNum).join(", ")}
                </Row>
              </td>
              <td>
                {driver.jobs.length}/{_.uniq(driver.stats.locations_ids).length}
                /{driver.stats.eqp}
              </td>
              <td>
                {driver.stats.delivers}/{driver.stats.deliversQty}
              </td>
              <td>
                {driver.stats.pickups}/{driver.stats.pickupsQty}
              </td>
              <td>
                <Button
                  size="sm"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Przypisywanie samochodów",
                      body: (
                        <CarAssign
                          cars={cars}
                          driver={driver}
                          setModalData={setModalData}
                          date={dateSelected}
                          jobs={driver.jobs}
                          refresh={refresh}
                        />
                      ),
                    })
                  }
                >
                  <FiTruck />
                </Button>
                <Button
                  size="sm"
                  className="ml-1"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Zmiana kolejności zadań",
                      xl: true,
                      body: (
                        <ChangeOrderModal
                          driver={driver}
                          setModalData={setModalData}
                          jobs={driver.jobs}
                          refresh={refresh}
                        />
                      ),
                    })
                  }
                >
                  <TiArrowShuffle />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">Suma</td>
            <td>
              {jobs.length}/
              {driversStats.reduce((driversLocations, driver) => {
                return (
                  driversLocations + _.uniq(driver.stats.locations_ids).length
                )
              }, 0)}
              /
              {driversStats.reduce(
                (driversEqp, driver) => driversEqp + driver.stats.eqp,
                0
              )}
            </td>
            <td>
              {driversStats.reduce((acc, red) => acc + red.stats.delivers, 0)}/
              {driversStats.reduce(
                (acc, red) => acc + red.stats.deliversQty,
                0
              )}
            </td>
            <td>
              {driversStats.reduce((acc, red) => acc + red.stats.pickups, 0)}/
              {driversStats.reduce((acc, red) => acc + red.stats.pickupsQty, 0)}
            </td>
          </tr>
        </tfoot>
      </Table>
      <Row className="justify-content-around">
        {noDriverJobs.length === 0 && jobsWithoutCar.length === 0 ? null : (
          <Alert variant="warning">
            Nie można wydrukować kontrolek dopóki istnieją zadania bez
            przypisanego kierowcy lub samochodu
          </Alert>
        )}
        <Col xs={3}>
          <Button
            variant="secondary"
            onClick={() => changeJobsState()}
            disabled={
              noDriverJobs.length ||
              jobsMustHaveEqpCheck.length ||
              jobsWithoutCar.length
            }
          >
            Drukuj kontrolkę
          </Button>
          {createManualBtn("jobCard", "ml-2")}
        </Col>
      </Row>
      <hr />
      <h3 className="mt-4">Podgląd kontrolek:</h3>
      <RoutesPlanningJobCard
        drivers={driversStats}
        date={date}
        ref={jobsCards}
        setJobsAlert={setJobsAlert}
        driversSelected={driversSelected}
      />
    </Container>
  )
}
export default RoutesPlanningAdditionalTab
