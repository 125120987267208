import React, { useContext } from "react"
import { Container, Row, Button, Table, Col } from "react-bootstrap"

import HistoryTable from "../../../utils/HistoryTable"

import { CustomerCtx } from "../Fetch"

import CustomersAddressEditModal from "./CustomersAddressEditModal"
import { entityEdit } from "./submit"

const CustomersAddressDetailsModal = (props) => {
  const { user, setModalData, customer, refresh } = useContext(CustomerCtx)

  const { address } = props

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <Row>
            <Button
              className="ml-3"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Historia edycji kontaktu",
                  xl: true,
                  body: (
                    <HistoryTable
                      history={address.history}
                      goBack={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          header: "Szczegóły kontaktu",
                          xl: true,
                          body: (
                            <CustomersAddressDetailsModal address={address} />
                          ),
                        })
                      }
                    />
                  ),
                })
              }
            >
              Historia
            </Button>
          </Row>
        </Col>

        {address.state === "aktywny" &&
        user.perm.customers.e &&
        customer.state === "aktywny" ? (
          <>
            <Button
              variant="warning"
              onClick={() =>
                entityEdit(
                  "addresses",
                  { ...address, state: "archiwum" },
                  { state: true },
                  address.history.length,
                  customer,
                  // wrapper to show error message in modal
                  (msg) =>
                    setModalData({ show: true, type: "alert", body: msg }),
                  setModalData,
                  refresh
                )
              }
            >
              Archiwizuj
            </Button>
            <Col>
              <Row className="justify-content-end">
                <Button
                  className="mr-3"
                  variant="outline-secondary"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: "Edycja kontaktu",
                      hideFooter: true,
                      body: <CustomersAddressEditModal address={address} />,
                    })
                  }
                >
                  Edytuj
                </Button>
              </Row>
            </Col>
          </>
        ) : null}
        {address.state === "archiwum" && user.perm.customers.e ? (
          <Button
            variant="outline-secondary"
            onClick={() =>
              entityEdit(
                "addresses",
                { ...address, state: "aktywny" },
                { state: true },
                address.history.length,
                customer,
                // wrapper to show error message in modal
                (msg) => setModalData({ show: true, type: "alert", body: msg }),
                setModalData,
                refresh
              )
            }
          >
            Przywróć
          </Button>
        ) : null}
      </Row>
      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Typ</td>
            <td className="fixed-values-table" style={{ width: "75%" }}>
              Nazwa
            </td>
          </tr>
          <tr>
            <td>{address.type}</td>
            <td>{address.name}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Adres (ulica, nr posesji, nr lokalu)
            </td>
          </tr>
          <tr>
            <td colSpan="2">{address.street}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Kod pocztowy</td>
            <td className="fixed-values-table">Miasto</td>
          </tr>
          <tr>
            <td>{address.postal}</td>
            <td>{address.city}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Notatka
            </td>
          </tr>
          <tr>
            <td colSpan="2">{address.note}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default CustomersAddressDetailsModal
/*

const address = new Schema({
  type: String, // main address: when privat -> "zameldowania", when legal -> "rejestrowy"
  name: String,
  street: String,
  postal: String,
  city: String,
  note: String,
  history: [historyEntry],
  state: String,
})
*/
