import React, { useContext } from "react"

import { Nav, Navbar, NavLink, Dropdown, NavItem } from "react-bootstrap"

import { MdSettings } from "react-icons/md"

import UserContext from "../contexts/userContext"
import MetaContext from "../contexts/metaContext"

const NavBar = () => {
  const user = useContext(UserContext)
  const meta = useContext(MetaContext)
  return (
    <Navbar bg="primaryDark">
      <Nav>
        {user?.perm.transaction.w ? (
          // <a href="/" className="nav-el">
          <Nav.Link href="/AddTransaction" className="nav-link-main">
            Dodaj transakcję
          </Nav.Link>
        ) : null}
        {user?.perm.transactionsList.r || user?.perm.transaction.w ? (
          <>
            <Nav.Link href="/transactions/" className="nav-link-main">
              Lista transakcji
            </Nav.Link>
          </>
        ) : null}
        {/* Warehouse: */}

        {user?.perm.consumption.w ? (
          <Dropdown as={NavItem} className="nav-link-main">
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Magazyny
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              <Dropdown.Item
                href="/consumptionEdit/new"
                className="nav-link-main"
              >
                Zużycie
              </Dropdown.Item>

              <Dropdown.Item href="/consumptionsList" className="nav-link-main">
                Lista
              </Dropdown.Item>

              {user?.perm.consumptionsList.r ? (
                <Dropdown.Item
                  href="/consumptionsReport"
                  className="nav-link-main"
                >
                  Raport
                </Dropdown.Item>
              ) : null}

              {user?.type.admin ? (
                <Dropdown.Item href="/goodsList" className="nav-link-main">
                  Zarządzanie
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {/* Sewage Reports: */}
        {user?.perm.sewageReport?.r ? (
          <Dropdown as={NavItem} className="nav-link-main">
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Sprawozdania
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              <Dropdown.Item href="/SewageReports_v2" className="nav-link-main">
                Ścieki
              </Dropdown.Item>
              <Dropdown.Item
                href="/SewagePermissions"
                className="nav-link-main"
              >
                Lista zezwoleń
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {/* Customers */}
        {user?.perm.customers?.r ? (
          <Nav.Link href="/CustomersList" className="nav-link-main">
            Klienci
          </Nav.Link>
        ) : null}

        {/* Logistics: */}
        {user?.perm.logistics?.r ? (
          <Dropdown as={NavItem} className="nav-link-main">
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Logistyka
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              <Dropdown.Item href="/RoutesPlanning" className="nav-link-main">
                Planowanie
              </Dropdown.Item>
              <Dropdown.Item href="/RoutesSettling" className="nav-link-main">
                Odhaczanie
              </Dropdown.Item>
              <Dropdown.Item
                href="/InventorySettling"
                className="nav-link-main"
              >
                Inwentaryzacja
              </Dropdown.Item>
              {/* <Dropdown.Item href="/LogisticsUtils" className="nav-link-main">
                Narzędzia
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        {user?.perm.employees.r ? (
          <Nav.Link
            href="/Employees/List"
            className="nav-link-main"
            id="employeesNavBtn"
          >
            Pracownicy
          </Nav.Link>
        ) : null}
        {user?.perm.invoicing?.r ? (
          <Dropdown
            as={NavItem}
            className="nav-link-main"
            id="InvoicingDropdown"
          >
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Fakturowanie
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              <Dropdown.Item
                href="/Invoicing/List"
                className="nav-link-main"
                id="Invoicing-List"
              >
                Lista
              </Dropdown.Item>
              {user?.perm.invoicing.e ? (
                <Dropdown.Item
                  href="/Invoicing/New"
                  className="nav-link-main"
                  id="Invoicing-New"
                >
                  Nowa
                </Dropdown.Item>
              ) : null}
              <Dropdown.Item
                href="/Invoicing/Stats"
                className="nav-link-main"
                id="Invoicing-Stats"
              >
                Statystyki
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {user?.perm?.customers?.r ? (
          <Nav.Link
            href="/LocationsList"
            className="nav-link-main"
            id="LocationsByDate"
          >
            Lokalizacje
          </Nav.Link>
        ) : null}

        {/* REPORTS */}
        {user?.perm.locations?.r ||
        user?.perm.transactionsList.r ||
        user?.perm.transaction.w ||
        user?.perm.incomeReport?.r ||
        user?.perm.logistics?.r ? (
          <Dropdown as={NavItem} className="nav-link-main">
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Raporty
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              {user?.perm.locations?.r ? (
                <Dropdown.Item
                  href="/Reports/JobsPrint"
                  className="nav-link-main"
                >
                  Zadania - wydruk
                </Dropdown.Item>
              ) : null}
              {user?.perm.locations?.r ? (
                <Dropdown.Item
                  href="/Reports/LocationsByDistance"
                  className="nav-link-main"
                >
                  Odległość do lokalizacji
                </Dropdown.Item>
              ) : null}
              {user?.perm.transactionsList.r || user?.perm.transaction.w ? (
                <Dropdown.Item
                  href="/Reports/RLineSell"
                  className="nav-link-main"
                >
                  Sprzedaż RLine
                </Dropdown.Item>
              ) : null}
              {user?.perm.incomeReport?.r ? (
                <Dropdown.Item href="/Reports/Income" className="nav-link-main">
                  Przychód
                </Dropdown.Item>
              ) : null}
              {user?.perm.incomeReport?.r ? (
                <Dropdown.Item
                  href="/Reports/BranchesStats"
                  className="nav-link-main"
                >
                  Statystyki oddziałów
                </Dropdown.Item>
              ) : null}
              {user?.perm.incomeReport?.r ? (
                <Dropdown.Item
                  href="/Reports/DailyStats"
                  className="nav-link-main"
                >
                  Statystyki dzienne
                </Dropdown.Item>
              ) : null}
              {user?.perm.logistics?.r ? (
                <Dropdown.Item
                  href="/Reports/EqpChangeReport"
                  className="nav-link-main"
                >
                  Dostarczenia i zabrania
                </Dropdown.Item>
              ) : null}
              {/* <Dropdown.Item href="/LogisticsUtils" className="nav-link-main">
                Narzędzia
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {user?.perm.todos?.r ? (
          <Nav.Link href="/Todos" className="nav-link-main">
            Do zrobienia
          </Nav.Link>
        ) : null}

        {/* CARS */}
        {user?.perm.carWork?.r || user?.perm.carsManage?.r ? (
          <Dropdown as={NavItem} className="nav-link-main">
            <Dropdown.Toggle as={NavLink} className="nav-link-main">
              Samochody
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-menu">
              <Dropdown.Item href="/CarsTable" className="nav-link-main">
                Lista
              </Dropdown.Item>

              {user?.perm.carWork?.r ? (
                <Dropdown.Item href="/CarsWorks" className="nav-link-main">
                  Naprawy/przeglądy
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {user?.perm.debtCollection?.w ? (
          <Nav.Link href="/DebtCollection" className="nav-link-main">
            Windykacja
          </Nav.Link>
        ) : null}
      </Nav>
      <Nav className="ml-auto nav-link-main">
        {user?.type.admin && meta.devEnv ? (
          <Nav.Item
            as="button"
            onClick={() => window.open("/permTable", "_blank")}
          >
            perms
          </Nav.Item>
        ) : null}
        <Nav.Link href="/config/" type="button" variant="primary">
          <MdSettings />
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}
export default NavBar
