import React from "react"
import { Container, Row, Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { format } from "date-fns"

const CarWorkEdit = ({
  allowedCars,
  setModalData,
  isNew,
  carWork,
  refresh,
}) => {
  const defaultValues = isNew
    ? {
        car: allowedCars[0]._id,
        type: "naprawa",
        date: format(new Date(), "yyyy-MM-dd"),
        cost: 0,
        manHours: 0,
        manHourCost: 0,
        desc: "",
      }
    : {
        ...carWork,
        car: carWork.car?._id,
        date: format(new Date(carWork.date), "yyyy-MM-dd"),
      }
  const { register, handleSubmit } = useForm({
    defaultValues: defaultValues,
  })

  const submit = async (formData) => {
    try {
      const res = await fetch("/cars/carWork", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          carWork: formData,
          isNew: isNew,
          historyLength: isNew ? 0 : carWork.historyLength,
        }),
      })

      if (res.status !== 200)
        throw new Error(
          `Błąd serwera: ${res.status} ${
            (await res.text()) || "brak komunikatu błędu"
          }`
        )
      else
        setModalData({
          show: true,
          type: "info",
          headerColor: "success",
          header: "Sukces",
          body: "Dane zapisane",
          onHide: refresh,
        })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || "nieokreślony błąd",
      })
    }
  }

  return (
    <Container fluid>
      <Form onSubmit={handleSubmit(submit)}>
        Samochód:
        <Form.Control
          {...register("car")}
          as="select"
          className=""
          autoComplete="chrome-off"
        >
          {allowedCars.map((car) => (
            <option
              key={`carSelect-${car._id}`}
              value={car._id}
            >{`${car.brand} ${car.model} ${car.regNum} (${car.name})`}</option>
          ))}
        </Form.Control>
        Typ:
        <Form.Control
          {...register("type")}
          as="select"
          className=""
          autoComplete="chrome-off"
        >
          <option>naprawa</option>
          <option>przegląd</option>
          <option>modyfikacja</option>
          <option>inne</option>
        </Form.Control>
        Data zakończenia:
        <Form.Control
          {...register("date")}
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off"
        />
        Koszt netto:
        <Form.Control
          {...register("cost")}
          as="input"
          type="number"
          className=""
          autoComplete="chrome-off"
        />
        Roboczogodzin:
        <Form.Control
          {...register("manHours")}
          as="input"
          type="number"
          className=""
          autoComplete="chrome-off"
        />
        Koszt roboczogodziny:
        <Form.Control
          {...register("manHourCost")}
          as="input"
          type="number"
          className=""
          autoComplete="chrome-off"
        />
        Opis i uwagi:
        <Form.Control
          {...register("desc")}
          as="textarea"
          className=""
          autoComplete="chrome-off"
        />
        <Row className="justify-content-between mx-1 mt-3">
          <Button
            onClick={() => setModalData({ show: false })}
            variant="warning"
          >
            Anuluj
          </Button>
          <Button type="submit" variant="secondary">
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export { CarWorkEdit }
