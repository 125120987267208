// description should be wrapped, and reveald on click
import React, { useState, useEffect } from "react"
import { Table, Badge } from "react-bootstrap"
import { format } from "date-fns"

const HistoryRender = ({ history }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Data</th>
          <th>Rodzaj</th>
          <th>Użytkownik</th>
          <th>Opis</th>
        </tr>
      </thead>
      <tbody>
        {history.length
          ? history.map((historyEntry) => (
              <HistoryRow historyEntry={historyEntry} />
            ))
          : "Brak wpisów spełniających kryteria"}
      </tbody>
    </Table>
  )
}

const HistoryRow = ({ historyEntry }) => {
  const [isExpendable, setIsExpandable] = useState(true)
  const [expand, setExpand] = useState(false)

  // when tried to set 'isExpandable' state directly in declaration it got sometimes false-positive ('true' even when desc was 37 char long)
  useEffect(() => {
    setIsExpandable(historyEntry.desc.length > 50)
  }, [historyEntry])

  const showDesc = () => {
    if (isExpendable && expand) return historyEntry.desc
    else if (isExpendable && !expand)
      return <Badge variant="secondary">kliknij żeby zobaczyć szczegóły</Badge>
    else return historyEntry.desc
  }
  return (
    <tr
      key={`historyEntryRow-${historyEntry._id}`}
      className={isExpendable ? "clickable" : null}
      onClick={() => {
        if (isExpendable) return setExpand(!expand)
      }}
    >
      <td>{format(new Date(historyEntry.date), "yyyy-MM-dd")}</td>
      <td>{historyEntry.type}</td>
      <td>{historyEntry.user?.fullName || "BŁĄD - zgłoś!"}</td>
      <td>{showDesc()}</td>
    </tr>
  )
}

export default HistoryRender
