import { useEffect, useRef, useState } from "react"
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import { Alert } from "react-bootstrap"

const LocationsByDistanceMap = ({
  locations,
  mapRef,
  filters,
  branchCoords,
}) => {
  const map = useRef(null)

  const [reloadMap, setReloadMap] = useState(0)

  useEffect(() => {
    // timeout function needed to wait for upload window.google
    //(at first render of this component window.google is undefined and I really don't have idea how to do it better)
    const waitForMapToLoad = async () => {
      await new Promise((resolve) => setTimeout(resolve, 200))
      setReloadMap((prev) => prev + 1)
    }

    if (document.getElementById("googleMapsScript") && window.google?.maps) {
      initMap(map, mapRef, locations, filters, branchCoords)
    } else waitForMapToLoad()
  }, [mapRef, reloadMap, map, locations, filters, branchCoords])

  // render map only when there is script loaded
  if (!document.getElementById("googleMapsScript"))
    return "Problem z ładowaniem mapy. Jeśli nie załaduje się za kilka sekund, odśwież stronę. Jeśli problem będzie się powtarzał, wyślij mi maila."
  else
    return (
      <>
        <Alert
          variant="secondary"
          style={{ width: "70vw" }}
          className="mx-auto py-0 mt-1"
        >
          Kliknięcie prawym otwiera lokalizację w nowej karcie.
          {"    "}
          Info o ilości zaciągane z <u>ostatniego</u> parametru (niekoniecznie
          aktualnego)
        </Alert>
        <div
          style={{
            height: "65vh",
            width: "70vw",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          ref={mapRef}
        />
      </>
    )
}

const initMap = (map, mapRef, locations, filters, branchCoords) => {
  map.current = new window.google.maps.Map(mapRef.current, {
    zoom: 5,
    center: {
      lat: branchCoords.coordinates[0],
      lng: branchCoords.coordinates[1],
    },
  })

  // draw circle around branch with selected radius:

  new window.google.maps.Circle({
    strokeColor: "#90A4AE",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#90A4AE",
    fillOpacity: 0.35,
    map: map.current,
    zIndex: 20000,
    center: {
      lat: branchCoords.coordinates[1],
      lng: branchCoords.coordinates[0],
    },
    radius: filters.dist * 1000,
  })

  // initialize infoWindow (popup showing after marker click) and bounds to fit map to markers
  const infoWindow = new window.google.maps.InfoWindow()
  const bounds = new window.google.maps.LatLngBounds()

  // create markers
  const markers = locations.map((location) => {
    const marker = new window.google.maps.Marker({
      position: {
        lat: location.coords?.coordinates[1] || 0,
        lng: location.coords?.coordinates[0] || 0,
      },
    })

    marker.addListener("click", () => {
      infoWindow.setContent(createInfoWindowContent(location))
      infoWindow.open(map.current, marker)
    })
    marker.addListener("contextmenu", () => {
      window.open("/Locations/" + location._id, "_blank")
    })

    marker.setMap(map.current)
    bounds.extend(marker.getPosition())
    map.current.fitBounds(bounds, 100)

    return marker
  })

  new MarkerClusterer({ map: map.current, markers })
}

const createInfoWindowContent = (location) => {
  const locationSrvs = location.services
    .map((srv) => {
      if (srv.state === "aktywna") {
        return `<li>${srv.name} - ${srv.chargeType}: ${srv.netPrice} (${
          srv.params[srv.params.length - 1].qty
        } szt.)</li>`
      } else return null
    })
    .filter((el) => el)
    .join("")

  const content = `
    <div>
    <b>${
      location.customerData.shortName ||
      location.customerData.name + " " + (location.customerData.surname || "")
    }</b><br/>
    <b>${location.name}</b><br/>
    <b>${location.street}</b><br/>
    <b>${location.city}</b><br/>
    <hr/>
    <ul>
    ${locationSrvs}
    </ul>
    </div>
    `

  return content
}

export { LocationsByDistanceMap }
