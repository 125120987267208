import React, { useState } from "react"
import { Container, Row, Button, Form } from "react-bootstrap"
import { format } from "date-fns"
import { getLatestSrvParam } from "./srvClosingTable/utils"

const JobStateChangeModal = ({
  job,
  location,
  refresh,
  setModalData,
  newState,
}) => {
  // state to hold selected services on delivery done
  const [servicesChoosen, setServicesChoosen] = useState([])
  // state to hold invoice note when job changed to not done:
  const [addInvNote, setAddInvNote] = useState(
    !(
      newState === "wykonane" ||
      newState === "zaplanowane" ||
      newState === "zlecone"
    )
  )
  const [invNote, setInvNote] = useState("")

  // state used when dalivery state changes to undone:
  const [undoingDelivery] = useState(
    job.type === "dostarczenie" &&
      (newState === "usunięte" ||
        newState === "odwołane (klient)" ||
        newState === "odwołane (my)" ||
        newState === "niewykonane (klient)" ||
        newState === "niewykonane (my)") &&
      (job.state === "zaplanowane" ||
        job.state === "zlecone" ||
        job.state === "wykonane")
  )

  const handleSubmit = async () => {
    try {
      const body = {
        job: {
          ...job,
          location: location,
          state: newState,
        },
        dirtyFields: { state: true },
        historyLength: job.history.length,
        srvToClose: servicesChoosen.map((srv) => ({ srv: srv, job: job })),
        invNote: invNote,
        addInvNote: addInvNote,
      }
      delete body.job.history // why? I don't know... copy-paste from utils.js...
      //* first handle state change:
      const res = await fetch("/jobs/edit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )

      //* now check if delivery, if so -> fire second fetch to apply changes in location:
      // but only when state changes to done or planned state
      if (job.type === "dostarczenie" && !undoingDelivery) {
        // since I'm using universal endpoint (also for batch changes) it may look strange, but works
        const eqpChangeJobs = {
          deliveryArr: [job],
          newState: newState,
        }

        await changeLocEqp(eqpChangeJobs)
      }

      return setModalData({
        show: true,
        type: "info",
        body: "Zmiany naniesione",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({ show: true, type: "alert", body: `Błąd: ${err.message}` })
    }
  }

  return (
    <Container>
      {newState === "usunięte" ? (
        <Row className="bg-danger justify-content-center p-1">
          <b>Usunąć zadanie?</b>
        </Row>
      ) : (
        <h4>Zmienić stan zadania?</h4>
      )}
      {job.type === "zabranie" && newState === "wykonane" ? (
        <HandlePickupDone
          location={location}
          job={job}
          setServicesChoosen={setServicesChoosen}
          servicesChoosen={servicesChoosen}
        />
      ) : null}
      {/* when removing delivery or marking it as undone - show alert */}
      {undoingDelivery ? (
        <p className="bg-danger p-2 mt-1">
          Uwaga! <br />
          Ta operacja jest{" "}
          <u>
            <b>nieodwracalna</b>
          </u>{" "}
          i spowoduje usunięcie sprzętu, który miał być (lub został) dostarczony
          ze wszystkich zaplanowanych zadań.
          <br />
          <b>Czy na pewno nie chcesz przesunąć tego dostarczenia?</b>
        </p>
      ) : null}
      {newState === "wykonane" ||
      newState === "zaplanowane" ||
      newState === "zlecone" ? null : (
        <HandleCreatingInvNote
          addInvNote={addInvNote}
          setAddInvNote={setAddInvNote}
          invNote={invNote}
          setInvNote={setInvNote}
          newState={newState}
          job={job}
        />
      )}
      <Row className="justify-content-around mt-2">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button
          variant="secondary"
          onClick={() => handleSubmit()}
          id="jobStateConfirmBtn"
        >
          Potwierdź
        </Button>
      </Row>
    </Container>
  )
}

const HandlePickupDone = ({
  location,
  job,
  setServicesChoosen,
  servicesChoosen,
}) => {
  // get closeable services:
  const closableServices = location.services.filter((locSrv) => {
    // user can only close active services:
    if (locSrv.state !== "aktywna") return false

    // get matching job service:
    const jobSrv = job.services.find(
      (jobSrvEl) => jobSrvEl.locationServiceRef === locSrv._id
    )

    // user can always close no-eqp service:
    if (!locSrv.mustHaveEqp) return true

    // exclude services that are not in job:
    if (!jobSrv) return false

    // for each equipment element check if pickup handles all
    const anySrvEqpLeft = locSrv.equipment.filter((locSrvEqp) => {
      // find job equipment:
      const jobSrvEqp = jobSrv.eqp.find(
        (jobSrvEqpEl) => jobSrvEqpEl.ref._id === locSrvEqp.ref._id
      )

      if (jobSrvEqp?.qty === locSrvEqp?.qty) return false
      else return true
    })

    if (anySrvEqpLeft.length) return false
    else return true
  })

  const renderSrvCheckbox = (locSrv) => {
    // check if service has end date:
    const latestParam = getLatestSrvParam(locSrv)

    return (
      <span key={`closeableSrvCheckbox-${locSrv._id}`}>
        <Form.Check
          name={`${locSrv._id}`}
          type="checkbox"
          label={`${locSrv.locSrvNo || null}. ${locSrv.name}`}
          id={`${locSrv._id}`}
          onChange={(e) => {
            if (e.target.checked)
              setServicesChoosen([...servicesChoosen, locSrv])
            else {
              // get service ind:
              const srvInd = servicesChoosen.findIndex(
                (srv) => srv._id === locSrv._id
              )

              const newServicesChoosen = [...servicesChoosen]
              newServicesChoosen.splice(srvInd, 1)
              setServicesChoosen(newServicesChoosen)
            }
          }}
        />

        {latestParam.endDate ? (
          <p className="bg-warningLight">
            (Ta usługa ma zaplanowaną datę zakończenia:{" "}
            {format(new Date(latestParam.endDate), "yyyy-MM-dd")})
          </p>
        ) : null}
        <hr />
      </span>
    )
  }

  if (closableServices.length)
    return (
      <>
        <hr style={{ height: "2px", backgroundColor: "grey" }} />
        <h5>Usługi do zamknięcia:</h5>
        {closableServices.map((jobSrv) => renderSrvCheckbox(jobSrv))}
      </>
    )
  else return null
}

const HandleCreatingInvNote = ({
  setInvNote,
  job,
  newState,
  addInvNote,
  setAddInvNote,
}) => {
  return (
    <>
      <hr style={{ height: "2px", backgroundColor: "grey" }} />
      <Row>
        <Form.Check
          type="checkbox"
          label="Utwórz notatkę fakturową"
          id="createInvNote"
          defaultChecked={true}
          onChange={(e) => setAddInvNote(e.target.checked)}
        />
      </Row>
      {addInvNote ? (
        <>
          <Row>
            <p>
              <b>Treść notatki:</b>
              <br />
              Zadanie z dnia {format(new Date(job.date), "yyyy-MM-dd")} -{" "}
              {newState}:<br />
              <i>
                (poniżej dodaj dalszą część notatki fakturowej)
                <br />
              </i>
            </p>
            <Form.Control
              as="input"
              id="invNoteBody"
              type="textarea"
              className=""
              autoComplete="chrome-off"
              onChange={(e) => setInvNote(e.target.value)}
            />
            <p>
              (<u>lub zostaw puste</u> - notatka i tak zostanie dodana)
            </p>
          </Row>
        </>
      ) : (
        <p className="bg-warning" id="noInvNoteWarning">
          Ta zmiana może wpłynąć na fakturę - rozważ dodanie notatki
        </p>
      )}
    </>
  )
}

const changeLocEqp = async (eqpChangeJobsObj) => {
  // when changing state for delivery
  const res = await fetch("/locations/handleEqpChangeJobs", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(eqpChangeJobsObj),
  })

  if (res.status !== 200) {
    console.log(res)
    throw new Error(
      `Błąd zmiany sprzętu podpiętego pod usługę. Zadanie powinno zmienić stan prawidłowo, ale sprzęt nie został oznaczony jako dostarczony. Zgłoś błąd: ${
        res.status
      }: ${await res.text()}`
    )
  }
}
export default JobStateChangeModal
