import React, { useState, useEffect, useContext, createContext } from "react"
import { Container, Tabs, Tab } from "react-bootstrap"

import UserContext from "../../contexts/userContext"

import MyModal from "../../utils/Modals"

import EmployeesDetailsGeneral from "./General"
import EmployeesDetailsContracts from "./Contracts"
import EmployeesDetailsNotes from "./Notes"
import EmployeeAdministration from "./Administration"
import { Helmet } from "react-helmet"

const EmployeesDetailsCtx = createContext(null)

const EmployeesDetailsFetch = (props) => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [employee, setEmployee] = useState(null)
  const [allEmployees, setAllEmployees] = useState(null)
  const [configs, setConfigs] = useState(null)
  const [tabSelected, setTabSelected] = useState("general")

  useEffect(() => {
    const fetchEmplData = async () => {
      const res = await fetch(`/employees/getById/${props.match.params._id}`)
      if (res.status === 403) throw new Error(await res.text())
      if (res.status !== 200)
        throw new Error(`Błąd pobierania danych (${res.status})`)
      const resJSON = await res.json()

      setEmployee(resJSON)
    }

    const fetchAllEmpls = async () => {
      const res = await fetch("/employees/all?noVerbose=true")
      if (res.status === 403) throw new Error(await res.text())
      if (res.status !== 200)
        throw new Error(`Błąd pobierania danych (${res.status})`)
      const allEmployeesJSON = await res.json()

      setAllEmployees(allEmployeesJSON)
    }
    const fetchConfigs = async (params) => {
      const res = await fetch(
        "/configs/employeePositions,openEmplDataEdit,employeeTypes,perm,boolPerm"
      )
      if (res.status === 403) throw new Error(await res.text())
      if (res.status !== 200)
        throw new Error(`Błąd pobierania danych (${res.status})`)

      const configsJSON = await res.json()
      console.log(configsJSON)
      setConfigs(configsJSON)
    }
    // after submitting changes I'm using 'setIsloading(true)' to refresh data without refreshing whole app
    // that's why there is 'isLoading' in dependencies array. So I had to prevent fetching when isLoading changes to false after 'first' fetch:

    const fetchAll = async () => {
      try {
        if (isLoading) {
          setEmployee(null)
          setConfigs(null)
          setAllEmployees(null)
          await Promise.all([
            fetchConfigs(),
            fetchAllEmpls(),
            fetchEmplData(),
          ]).then(() => setIsLoading(false))
        }
      } catch (err) {
        console.log(err)
        return setModalData({
          show: true,
          type: "alert",
          body: err.message || "Błąd pobierania danych",
        })
      }
    }
    fetchAll()
  }, [props.match, isLoading])

  return (
    <EmployeesDetailsCtx.Provider
      value={{
        employee: employee,
        setModalData: setModalData,
        configs: configs,
        allEmployees: allEmployees,
        user: user, // to use just one context in child components
        // function to fire after changing data - instead of reloading whole page (which causes opening general tab) it forces data fetch
        refresh: () => {
          setModalData({ show: false })
          setIsLoading(true)
        },
      }}
    >
      <Container className="mt-1" fluid>
        {isLoading ? (
          <p>Pobieram dane...</p>
        ) : (
          <>
            <Helmet>
              <title>{employee.fullName || "dane pracownika"}</title>
            </Helmet>
            <Tabs
              activeKey={tabSelected}
              onSelect={(key) => setTabSelected(key)}
              fill
              className="tab mb-1"
              transition={false}
            >
              <Tab eventKey="general" title="Ogólne" className="tab">
                <EmployeesDetailsGeneral
                  setModalData={setModalData}
                  employee={employee}
                  configs={configs}
                  allEmployees={allEmployees}
                  user={user}
                />
              </Tab>
              <Tab eventKey="contracts" title="Umowy" className="tab">
                <EmployeesDetailsContracts
                  setModalData={setModalData}
                  employee={employee}
                  configs={configs}
                  user={user}
                />
              </Tab>
              <Tab eventKey="notes" title="Notatki" className="tab">
                <EmployeesDetailsNotes
                  setModalData={setModalData}
                  employee={employee}
                  user={user}
                />
              </Tab>
              {user.type.admin ? (
                <Tab eventKey="admin" title="administracja" className="tab">
                  <EmployeeAdministration
                    setModalData={setModalData}
                    employee={employee}
                    user={user}
                    configs={configs}
                    // function to fire after changing data - instead of reloading whole page (which causes opening general tab) it forces data fetch
                    // easier to paste it here then consume context in administration tab
                    refresh={() => {
                      setModalData({ show: false })
                      setIsLoading(true)
                    }}
                  />
                </Tab>
              ) : null}
              {/* <Tab eventKey="equipment" title="Sprzęt" className="tab">
            <p>Lista powierzonego sprzętu: |rodzaj|data|opis|</p>
            <p>Dwie tabele: aktualne i historyczne (state==="archiwum")</p>
          </Tab> */}
            </Tabs>
          </>
        )}

        <MyModal setModalData={setModalData} modalData={modalData} />
      </Container>
    </EmployeesDetailsCtx.Provider>
  )
}
export { EmployeesDetailsFetch, EmployeesDetailsCtx }
