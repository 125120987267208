import React from "react"
import { Container, Row } from "react-bootstrap"

const renderSpinner = (text) => {
  return (
    <Container fluid className="m-2">
      <Row className="justify-content-center mb-0">
        <h5 className="font-weight-bold text-primarySuperDark mb-0">
          {text || "Pobieram dane"}
        </h5>
      </Row>
      <Row className="justify-content-center mt-0">
        <svg version="1.0" width="384px" height="24px" viewBox="0 0 128 8">
          <linearGradient id="linear-gradient">
            <stop offset="0%" stopColor="#4dd0e1" />
            <stop offset="100%" stopColor="#ffffff" />
          </linearGradient>
          <linearGradient id="linear-gradient2">
            <stop offset="0%" stopColor="#ffffff" />
            <stop offset="100%" stopColor="#4dd0e1" />
          </linearGradient>
          <g>
            <rect fill="url(#linear-gradient2)" x="-64" width="64" height="4" />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="193 0"
              dur="1800ms"
              repeatCount="indefinite"
            />
          </g>
          <g>
            <rect
              fill="url(#linear-gradient)"
              x="128"
              y="4"
              width="64"
              height="4"
            />
            <animateTransform
              attributeName="transform"
              type="translate"
              from="0 0"
              to="-193 0"
              dur="1800ms"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      </Row>
    </Container>
  )
}
export default renderSpinner
