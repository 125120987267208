import React, { useState } from "react"

import { Form, Col, Row, Button, Container } from "react-bootstrap"

import { MdSystemUpdateAlt } from "react-icons/md"
import { AiOutlineLoading3Quarters } from "react-icons/ai"

import { roundToHundredth } from "../../utils/formats"

import CustomersFetch from "../../Customers/Fetch"

const CustomerDetailsForm = (props) => {
  const { register, customerType, setValue, watch } = props

  const [dataFetching, setDataFetching] = useState(false)

  const handleFetchCompanyData = async () => {
    try {
      setDataFetching(true)
      const res = await fetch(
        `/misc/rejestrIoFetch/${watch("companyData.vatNumber").replace(
          /\s|-/g,
          ""
        )}`
      )
      if (res.status === 404) throw new Error("brak wyników")
      if (res.status !== 200) {
        const resText = await res.text()
        throw resText
      }
      const resJSON = await res.json()

      setValue("companyData.name", resJSON.nazwy.pelna)
      setValue(
        "companyData.street",
        `${resJSON.adres.ulica} ${resJSON.adres.nr_domu}${
          resJSON.adres.nr_mieszkania ? `/${resJSON.adres.nr_mieszkania}` : ""
        }`
      )
      setValue("companyData.postal", resJSON.adres.kod)
      setValue("companyData.city", resJSON.adres.miejscowosc)
      setDataFetching(false)
    } catch (err) {
      setDataFetching(false)
      alert(err)
    }
  }

  // func that is passed as props to <CustomersFetch />, receives data of selected customer and sets it in form (also delivery form if rendered)
  const setCustomerFromDb = (customerData) => {
    props.setModalData({ show: false })
    const mainAddress = customerData.addresses.find(
      (add) => add.state === "aktywny" && add.type === "główny"
    )
    const corrAddress = customerData.addresses.find(
      (add) => add.state === "aktywny" && add.type === "korespondencyjny"
    )

    if (customerType === "privat") {
      setValue("privatData", {
        // I had to assume that our employees use name-surname not surname-name.
        name: customerData.name,
        surname: customerData.surname,
        street: mainAddress.street,
        postal: mainAddress.postal,
        city: mainAddress.city,
      })
    } else {
      setValue("companyData", {
        name: customerData.name,
        street: mainAddress.street,
        vatNumber: customerData.vatNumber,
        postal: mainAddress.postal,
        city: mainAddress.city,
      })
    }
    // set delivery data. If "our delivery" is not selected -> form is not rendered and refs are not registered -> fields not get filled.
    // Could do sth with this, but it is not needed for now IMHO
    setValue("realizationDetails.deliveryDetails.deliveryAddress", {
      name: corrAddress?.name || customerData.name,
      street: corrAddress?.street || mainAddress.street,
      postal: corrAddress?.postal || mainAddress.postal,
      city: corrAddress?.city || mainAddress.city,
      phone:
        customerData.contacts.find(
          (con) => con.state === "aktywny" && con.type === "główny"
        ).phone || null,
    })
  }
  //! WARNING!!! I tried for a few hours to implement conditional rendering of below form instead for changing display property,
  //! but react-hook-form (?) is not doing well when changing customer type, for ex it is treating privatData.name as companyData.vatNumber or vice versa
  //! despite which was rendered first. I tried unique names, I tried same names to put it in right place when sanitizing data, nothing worked.
  //! seems for me now, that returning both forms and hiding one while letting register all fields is only working way...
  return (
    <Container>
      <Row>
        <Button
          onClick={() =>
            props.setModalData({
              show: true,
              type: "info",
              xl: true,
              body: (
                <CustomersFetch
                  embedded={true}
                  setCustomer={setCustomerFromDb}
                />
              ),
            })
          }
          className="ml-auto mt-1"
        >
          Wyszukaj w bazie
        </Button>
      </Row>
      <Row
        className="mt-3"
        style={
          customerType === "privat" ? { display: "none" } : { display: "flex" }
        }
        id="companyDataForm"
      >
        <Col
          xs={{ offset: 3 }}
          className="border-left border-top border-bottom"
        >
          <Form.Label>
            NIP:
            <Form.Control type="text" {...register("companyData.vatNumber")} />
          </Form.Label>
          <Button
            type="button"
            onClick={handleFetchCompanyData}
            style={{ paddingTop: 0 }} // icon looks better this way
            variant="secondary"
          >
            {/* <div className="loading"> */}
            {dataFetching ? (
              <AiOutlineLoading3Quarters className="loading" />
            ) : (
              <MdSystemUpdateAlt />
            )}
            {/* </div> */}
          </Button>
          <Form.Label>
            Nazwa:
            <Form.Control type="text" {...register("companyData.name")} />
          </Form.Label>
        </Col>
        <Col className="border-top border-right border-bottom">
          <Form.Label>
            Adres:
            <Form.Control
              type="text"
              {...register("companyData.street")}
              htmlSize={80}
            />
          </Form.Label>

          <Form.Label>
            Kod pocztowy i miejscowość:
            <Row noGutters>
              <Col xs="4">
                <Form.Control type="text" {...register("companyData.postal")} />
              </Col>
              <Col xs="8">
                <Form.Control {...register("companyData.city")} type="text" />
              </Col>
            </Row>
          </Form.Label>
        </Col>
      </Row>

      <Row
        className="mt-3"
        style={
          customerType === "company" ? { display: "none" } : { display: "flex" }
        }
        id="privatDataForm"
      >
        <Col
          xs={{ offset: 3 }}
          className="border-left border-top border-bottom"
        >
          <Form.Label>
            Imię:
            <Form.Control type="text" {...register("privatData.name")} />
          </Form.Label>

          <Form.Label>
            Nazwisko:
            <Form.Control type="text" {...register("privatData.surname")} />
          </Form.Label>
        </Col>
        <Col className="border-top border-right border-bottom">
          <Form.Label>
            Adres:
            <Form.Control
              type="text"
              {...register("privatData.street")}
              htmlSize={80}
            />
          </Form.Label>

          <Form.Label>
            Kod pocztowy i miejscowość:
            <Row noGutters>
              <Col xs="4">
                <Form.Control {...register("privatData.postal")} />
              </Col>
              <Col xs="8">
                <Form.Control {...register("privatData.city")} type="text" />
              </Col>
            </Row>
          </Form.Label>
        </Col>
      </Row>
    </Container>
  )
}

const DeliveryForm = (props) => {
  const { register, setValue, Controller, control } = props
  return (
    <Container id="DeliveryForm">
      <Row>
        <h3>Szczegóły dostawy</h3>
      </Row>

      <Row className="border">
        <Col xs={{ offset: 2, span: 4 }}>
          <Form.Label>
            Nazwisko/nazwa:
            <Form.Control
              {...register(
                "realizationDetails.deliveryDetails.deliveryAddress.name"
              )}
              type="text"
              htmlSize={80}
            />
          </Form.Label>
          <Form.Label>
            Ulica:
            <Form.Control
              {...register(
                "realizationDetails.deliveryDetails.deliveryAddress.street"
              )}
              type="text"
              className="inline"
              htmlSize={80}
            />
          </Form.Label>
          <Form.Label>
            Kod pocztowy i miejscowość:
            <Row noGutters>
              <Col xs="4">
                <Form.Control
                  {...register(
                    "realizationDetails.deliveryDetails.deliveryAddress.postal"
                  )}
                  type="text"
                  // style={{ width: "70px" }}
                  inline="true"
                />
              </Col>
              <Col xs="8">
                <Form.Control
                  {...register(
                    "realizationDetails.deliveryDetails.deliveryAddress.city"
                  )}
                  type="text"
                  inline="true"
                />
              </Col>
            </Row>
          </Form.Label>
        </Col>

        <Col xs={{ span: 4 }}>
          <Form.Label>
            Numer telefonu:
            <Form.Control
              {...register(
                "realizationDetails.deliveryDetails.deliveryAddress.phone"
              )}
              type="text"
            />
          </Form.Label>
          <Form.Label>
            Data dostawy: <br />
            <Form.Control
              {...register("realizationDetails.deliveryDetails.deliveryDate")}
              type="date"
              id="deliveryDate"
              placeholder="data dostawy"
            />
          </Form.Label>
          <Form.Label>
            Cena za transport:
            <Row>
              <Col>
                <Form.Label>
                  netto:
                  <Controller
                    control={control}
                    name={`realizationDetails.deliveryDetails.netPrice`}
                    render={({ field }) => {
                      return (
                        <Form.Control
                          {...field}
                          type="number"
                          label="netto:"
                          className="medium-number-input"
                          step="any"
                          onChange={(e) => {
                            setValue(
                              "realizationDetails.deliveryDetails.grossPrice",
                              roundToHundredth(e.target.value * 1.23)
                            )
                            field.onChange(e)
                          }}
                        />
                      )
                    }}
                  />
                </Form.Label>
              </Col>
              <Col>
                <Form.Label>
                  brutto:
                  <Controller
                    control={control}
                    name={`realizationDetails.deliveryDetails.grossPrice`}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="number"
                        className="medium-number-input"
                        step="any"
                        onChange={(e) => {
                          setValue(
                            "realizationDetails.deliveryDetails.netPrice",
                            roundToHundredth(e.target.value / 1.23)
                          )
                          field.onChange(e)
                        }}
                      />
                    )}
                  />
                </Form.Label>
              </Col>
            </Row>
          </Form.Label>
        </Col>
        <Col xs="4"></Col>
      </Row>
    </Container>
  )
}

export { CustomerDetailsForm, DeliveryForm }
