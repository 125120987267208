import { format, isValid, setHours } from "date-fns"
import React, { useContext, useState } from "react"
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  Alert,
  Badge,
} from "react-bootstrap"

import { useForm } from "react-hook-form"

import { CustomerCtx } from "../Fetch"
import CustomersNoteDetailsModal from "./CustomersNoteDetailsModal"

import { entityAdd, entityEdit } from "./submit"

const CustomersNoteEditModal = ({ note }) => {
  const { setModalData, user, customer, refresh, configs } =
    useContext(CustomerCtx)

  const [fetchError, setFetchError] = useState(false)

  // prevent showing type "główny" when there is any OTHER (check by _id) contact of this type:
  const canBeMain =
    customer.notes.filter(
      (customerNote) =>
        customerNote.state === "aktywna" &&
        customerNote.type === "główna" &&
        customerNote._id !== note._id
    ).length === 0
      ? true
      : false

  const defaultValues =
    note === "new" ? { type: null, body: null } : { ...note }

  // set defaults for note type === "windykacyjna"
  if (note !== "new" && note.type === "windykacyjna") {
    defaultValues.subtype = note.subtype
    defaultValues.eventDate =
      note.eventDate && isValid(new Date(note.eventDate))
        ? format(new Date(note.eventDate), "yyyy-MM-dd")
        : null
  }

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
    watch,
  } = useForm({
    defaultValues: defaultValues,
  })

  const watchType = watch("type")

  const submit = (formData) => {
    try {
      // set eventDate if type === "windykacyjna"

      if (formData.type === "windykacyjna") {
        formData.eventDate = setHours(new Date(formData.eventDate), 12)
      }

      if (note === "new")
        entityAdd(
          "notes",
          { ...formData, state: "aktywna" },
          customer,
          setFetchError,
          setModalData,
          refresh
        )
      else
        entityEdit(
          "notes",
          { ...formData, _id: note._id },
          dirtyFields,
          note.history.length,
          customer,
          setFetchError,
          setModalData,
          refresh
        )
    } catch (err) {
      console.log(err)
    }
  }

  const renderDebtCollectionNoteForm = () => {
    if (watchType !== "windykacyjna") return null
    return (
      <tr>
        <td className="fixed-values-table">Podtyp</td>
        <td>
          {errors.subtype && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("subtype", { required: true })}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            <option>ogólna</option>
            <option>telefon</option>
            <option>sms</option>
            <option>mail</option>
            <option>@ wezwanie</option>
            <option>@ wezwanie KRD</option>
          </Form.Control>
        </td>
        <td className="fixed-values-table">Data zdarzenia</td>
        <td>
          {errors.eventDate && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("eventDate", { required: true })}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
            defaultValue={note.eventDate || format(new Date(), "yyyy-MM-dd")}
          />
        </td>
      </tr>
    )
  }

  return (
    <Container>
      <Row className="mb-1">
        {note === "new" ? null : (
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Szczegóły kontaktu",
                xl: true,
                body: <CustomersNoteDetailsModal note={note} />,
              })
            }
            className="mr-auto ml-3"
          >
            Wróć
          </Button>
        )}
      </Row>

      <Form onSubmit={handleSubmit(submit)}>
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Typ{" "}
                {errors.type && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
              </td>

              <td colSpan="2">
                <Form.Control
                  {...register("type", { required: true })}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                >
                  {configs.customerNoteTypes.map((type) => {
                    if (type === "główna" && !canBeMain) return null
                    else return <option value={type}>{type}</option>
                  })}
                </Form.Control>
              </td>
            </tr>
            {renderDebtCollectionNoteForm()}
            <tr>
              <td className="fixed-values-table" colSpan="4">
                Treść
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Form.Control
                  {...register("body", { required: true })}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row className="justify-content-between">
          <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
          {user.perm.customers.d && note !== "new" ? (
            <Button variant="danger">Usuń</Button>
          ) : null}
          <Button
            variant="secondary"
            type="submit"
            className={note === null ? "ml-auto" : null} // pushing button to right when no other buttons
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default CustomersNoteEditModal
