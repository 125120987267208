import React from "react"
import { Container, Table, Button } from "react-bootstrap"

import CarsEditModal from "./CarEditModal"
import CarDetailsModal from "./CarDetailsModal"

const CarsTableRender = ({ cars, setModalData, user, refresh }) => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>Samochody:</th>
            <th>
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Dodawanie samochodu",

                    body: (
                      <CarsEditModal
                        user={user}
                        setModalData={setModalData}
                        refresh={refresh}
                        isNew={true}
                      />
                    ),
                    hideFooter: true,
                  })
                }
              >
                Dodaj
              </Button>
            </th>
          </tr>
          <tr>
            <th>Nr rej</th>
            <th>Typ</th>
            <th>Marka</th>
            <th>Model</th>
            <th>Nazwa</th>
            <th>Oddział</th>
            <th>Stan</th>
          </tr>
        </thead>
        <tbody>
          {cars.length
            ? cars.map((car) => (
                <tr
                  key={`carsTableRow-${car._id}`}
                  className="clickable"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Szczegóły samochodu",
                      xl: true,
                      body: (
                        <CarDetailsModal
                          user={user}
                          setModalData={setModalData}
                          car={car}
                          refresh={refresh}
                        />
                      ),
                    })
                  }
                >
                  <td>{car.regNum}</td>
                  <td>{car.type}</td>
                  <td>{car.brand}</td>
                  <td>{car.model}</td>
                  <td>{car.name}</td>
                  <td>{car.branch}</td>
                  <td>{car.state}</td>
                </tr>
              ))
            : "brak wyników"}
        </tbody>
      </Table>
    </Container>
  )
}
export default CarsTableRender
