import React from "react"
import { format } from "date-fns"
import { Table } from "react-bootstrap"

import LogsDetails from "./Details"

const LogsRender = ({ logs, setModalData }) => {
  return (
    <div>
      Ileż tego: {logs.length}
      <Table>
        <thead>
          <tr>
            <th>Data</th>
            <th>v</th>
            <th>method</th>
            <th>url</th>
            <th>usr</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => {
            return (
              <tr
                key={`log-row-${log._id}`}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    body: <LogsDetails log={log} />,
                  })
                }
              >
                <td>{format(new Date(log.createdAt), "yyyy-MM-dd HH:mm")}</td>
                <td>{log.loggerVersion || "bd"}</td>
                <td>{log.request?.method || "bd"}</td>
                <td>{log.request?.url || "bd"}</td>
                <td>
                  {/* show employee fullName or name or id: */}
                  {log.employee?.fullName ||
                    log.employee?.name ||
                    log.employee?._id ||
                    "bd"}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
export default LogsRender
