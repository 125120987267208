import React from "react"
import { Container, Row, Button, Form } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"

import { ChooseSrvAndEqp } from "./utils"

const ServicesBatchEditModal = ({
  location,
  setModalData,
  formData,
  submit,
  jobsFiltered,
  user,
}) => {
  const { register, control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      services: location.services
        .filter((srv) => srv.state === "aktywna")
        .map((srv) => {
          return {
            include: true,
            locationServiceRef: srv._id,
            serviceRef: srv.serviceRef,
            eqp: srv.equipment.map((eqp) => {
              return { ref: eqp.ref._id, qty: eqp.qty }
            }),
            subSrv: srv.subSrv?.map((subSrv) => subSrv._id),
          }
        }),
    },
  })

  const batchSrvEditSubmit = (srvData) => {
    // prepare data:

    const services = srvData.services
      .map((srv) => {
        // get rid of unselected
        if (!srv.include) return null

        const service = {
          locationServiceRef: srv.locationServiceRef,
          // assign global service ref:
          serviceRef: location.services.find(
            (locSrv) => locSrv._id === srv.locationServiceRef
          ).serviceRef,
          // assign choosen equipment:
          eqp: srv.eqp ? srv.eqp.filter((eqp) => eqp.ref) : [],
        }

        // attach subSrvs data
        if (srv.subSrv?.length) service.subSrv = srv.subSrv

        return service
      })
      .filter((el) => el)

    formData.modifications.services.newValue = services

    return submit(formData, jobsFiltered)
  }
  return (
    <Container>
      <p className="bg-dangerLight">
        Uwaga! Ta operacja <b>nadpisze</b> poprzednio przypisane usługi i
        sprzęt!
        <br />
        <b>
          To znaczy, że z każdego serwisu zostaną <u>usunięte wszystkie</u>{" "}
          usługi i sprzęt jakie miał podpięte do tej pory, a w zamian zostaną
          wstawione zaznaczone poniżej.
        </b>
        <br />
        Wszelkie zmiany (również wprowadzone w poprzednim kroku) będą miały
        wpływ <b>tylko na serwisy</b> (<u>nie</u> na dostarczenia i zabrania)
      </p>
      <Form onSubmit={handleSubmit(batchSrvEditSubmit)}>
        <ChooseSrvAndEqp
          register={register}
          control={control}
          Controller={Controller}
          location={location}
          watch={watch}
          setValue={setValue}
          jobType={"serwis"}
        />
        <Row className="justify-content-between mx-3 mt-4">
          {user.type.admin ? (
            <Button onClick={() => console.log(watch())}>?</Button>
          ) : null}
          <Button
            variant="warning"
            onClick={() => setModalData({ show: false })}
          >
            Anuluj
          </Button>
          <Button type="submit">Wykonaj</Button>
        </Row>
      </Form>
    </Container>
  )
}

export { ServicesBatchEditModal }
