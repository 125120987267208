import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const ManualCarsSection = ({ user }) => {
  if (!user.perm.carsManage.r && !user.perm.carWork.r) return null
  return (
    <Container fluid>
      <Row>
        <Col>
          <h2>Samochody</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Naprawy/przeglądy</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="bg-warningLight">
            Komponent "naprawy/przeglądy" jest na wstępnym etapie tworzenia.
            Udostępniam go, żeby jak najszybciej zacząć wprowadzać dane do
            programu.
          </p>
          <p>
            W komponencie "naprawy/przeglądy" można zapisywać wszelkie koszty
            związane z samochodami wprowadzonymi do systemu. Szczegóły regulują
            osobne ustalenia, ale ogólne zasada brzmi "im więcej jak
            najdokładniejszych danych w programie, tym lepiej".
          </p>
          <p>
            Widoczność konkretnych samochodów przy filtrowaniu i dodawaniu wpisu
            zależy od oddziałów przypisanych do użytkownika.
          </p>
          <p>
            <b>Wszystkie kwoty należy podawać bez VAT</b>
          </p>
          <p>
            <b>Roboczogodziny</b>: jeśli przy naprawie pracowało kilku
            pracowników należy w <u>liczbę roboczogodzin wpisać 1</u> a w{" "}
            <u>koszt roboczogodziny</u> sumaryczny koszt wszystkich pracowników
            (kwoty ubruttowione, tzn. nasz realny koszt ze składkami i
            podatkami, ale <u>bez VAT</u> w przypadku współpracownika
            wystawiającego faktury z VATem).
          </p>
          <p>
            <b>Opis i uwagi</b>: wpisać dodatkowe informacje istotne dla kosztu.
            Obowiązkowo: <u>co było naprawiane</u>. Dodatkowo, jeśli w "koszt
            roboczogodziny" wpisano sumaryczny koszt to tu należy opisać, z
            czego się składał.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
export { ManualCarsSection }
