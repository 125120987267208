import React, { useContext, useState } from "react"
import _ from "lodash"
import { differenceInCalendarDays, format, isSameDay } from "date-fns"
import {
  Row,
  Table,
  Col,
  Button,
  Form,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im"

import { LocationCtx } from "../Fetch"

import LocationsDetailsServiceChooseModal from "../servicesModal/LocationsDetailsServiceChooseModal"
import LocationDetailsServiceCloseModal from "../servicesModal/LocationDetailsServiceCloseModal"
import LocationDetailsSrvEditParamModal from "../servicesModal/LocationDetailsSrvEditParamModal"
import LocationDetailsSrvAddParamModal from "../servicesModal/LocationDetailsSrvAddParamModal"
import LocationsDetailsServiceDetailsModal from "../servicesModal/LocationsDetailsServiceDetailsModal"
import LocationsDetailsSrvCancelModal from "../servicesModal/SrvCancelModal"
import LocationInvoiceMakeModal from "../utils/InvoiceMakeModal"

import { EditDropdown, renderSrvDetails } from "../utils/serviceTableUtils"
import { LocationDetailsInvPreviewModal } from "../servicesModal/InvPreviewModal"

const LocationsDetailsServicesTable = (props) => {
  const {
    location,
    setModalData,
    refresh,
    user,
    services: servicesRefs,
    showSrvParams,
    setShowSrvParams,
  } = useContext(LocationCtx)
  const services = location.services.filter((srv) =>
    props.filter.includes(srv.state)
  )

  const [servicesSelected, setServicesSelected] = useState([])
  const [showHiddenParams, setShowHiddenParams] = useState(false)

  const { filter } = props

  const renderParamsRows = (srv) => {
    // from 1.12.0 / 2.0.0 I hide params from users and show text info instead
    // parms can be revealed with checkbox

    if (!showSrvParams) return renderSrvDetails(srv)

    let params = srv.params
    if (!showHiddenParams)
      params = params.filter((prm) => {
        // some times 'invoicedTo' can be null (when edited), so first I'm checking if it's falsey:
        if (prm.invoicedTo)
          return !isSameDay(new Date(prm.endDate), new Date(prm.invoicedTo))
        else return true
      })

    return params
      .sort((a, b) =>
        differenceInCalendarDays(new Date(a.startDate), new Date(b.startDate))
      )
      .map((param) => {
        return (
          <tr
            key={`paramRow-${param._id}`}
            className={
              (srv.state === "aktywna" &&
                location.state === "aktywna" &&
                srv.chargeType !== "ryczałt impreza" &&
                srv.chargeType !== "krótki termin") ||
              // allow admin to change params every time:
              user.type.admin === true
                ? "clickable"
                : null
            }
            onClick={() => {
              if (
                (srv.state === "aktywna" &&
                  location.state === "aktywna" &&
                  srv.chargeType !== "ryczałt impreza" &&
                  srv.chargeType !== "krótki termin") ||
                // allow admin to change params every time:
                user.type.admin === true
              )
                return setModalData({
                  show: true,
                  type: "info",
                  hideFooter: true,
                  header: `Edycja parametru (${srv.locSrvNo || "?"}. ${
                    srv.name
                  })`,

                  body: (
                    <LocationDetailsSrvEditParamModal param={param} srv={srv} />
                  ),
                })
              else return null
            }}
          >
            <td></td>

            <td>Liczba: {param.qty}</td>
            <td>
              od:{" "}
              {param.startDate
                ? format(new Date(param.startDate), "yyyy-MM-dd")
                : "BŁĄD: brak daty"}
            </td>
            <td>
              do:{" "}
              {param.hasEndDate
                ? format(new Date(param.endDate), "yyyy-MM-dd")
                : "nieokreślona"}
            </td>
            <td id="invoicedTo">
              zafakturowano do:{" "}
              {param.invoicedTo
                ? format(new Date(param.invoicedTo), "yyyy-MM-dd")
                : "brak"}
            </td>
          </tr>
        )
      })
  }

  const renderSubSrvLine = (srv) => {
    if (!srv.subSrv?.length) return null

    return (
      <p className="my-0">
        Usługi podrzędne:{" "}
        <i>
          {srv.subSrv
            .map((subSrv) => {
              // try to find extraFee assigned to this subSrv
              if (srv.extraFees?.length) {
                const extraFee = srv.extraFees.find(
                  (srvExtraFee) =>
                    srvExtraFee.srvRef === subSrv._id &&
                    srvExtraFee.locSrvRef === srv._id
                )
                if (extraFee) return `${subSrv.name} (${extraFee.amount} zł)`
              }

              return subSrv.name
            })
            .join(", ")}
        </i>
      </p>
    )
  }

  return (
    <Table id={`servicesTable-${filter}`}>
      <thead>
        <tr>
          <th colSpan="8">
            <Row>
              <Col>Usługi</Col>
              <Col>
                <Form.Check
                  name="showParams-checkbox"
                  type="checkbox"
                  label="Pokaż parametry"
                  id="showHiddenParams-checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setShowSrvParams(true)
                      setShowHiddenParams(true)
                    } else setShowSrvParams(false)
                  }}
                />
                {
                  // user can see hidden params only if he sees params at all
                  showSrvParams ? (
                    <Form.Check
                      name="showHiddenParams-checkbox"
                      type="checkbox"
                      label="Pokaż zakończone parametry"
                      id="showHiddenParams-checkbox"
                      defaultChecked={true}
                      onChange={(e) => {
                        if (e.target.checked) setShowHiddenParams(true)
                        else setShowHiddenParams(false)
                      }}
                    />
                  ) : null
                }
              </Col>
              <Col>
                <Row>
                  <Button
                    variant="light"
                    onClick={() => alert("Do zrobienia")}
                    className="ml-auto"
                  >
                    Archiwalne
                  </Button>
                </Row>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Podgląd faktury",
                      xl: true,
                      body: (
                        <LocationDetailsInvPreviewModal location={location} />
                      ),
                    })
                  }
                >
                  Symulacja faktury
                </Button>
              </Col>
              <Col>
                <Row className="justify-content-center">
                  <p className="mt-1 mb-0">Fakturuj:</p>
                  <Button
                    variant="secondary"
                    className="mx-1"
                    id={`invoiceChecked`}
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "Dane faktury",
                        hideFooter: true,
                        xl: true,
                        body: (
                          <LocationInvoiceMakeModal
                            services={servicesSelected}
                          />
                        ),
                      })
                    }
                  >
                    <ImCheckboxChecked />
                  </Button>
                  <Button
                    variant="secondary"
                    className="mx-1"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "Dane faktury",
                        hideFooter: true,
                        xl: true,
                        body: (
                          <LocationInvoiceMakeModal
                            services={_.difference(services, servicesSelected)}
                          />
                        ),
                      })
                    }
                  >
                    <ImCheckboxUnchecked />
                  </Button>
                </Row>
              </Col>

              <Col>
                <Row>
                  {
                    // have to use lodash isEqual since filter is array:
                    _.isEqual(filter, ["aktywna"]) &&
                    location.state === "aktywna" ? (
                      <Button
                        id="addSrvBtn"
                        variant="secondary"
                        className="ml-auto mr-3"
                        onClick={() =>
                          setModalData({
                            show: true,
                            type: "info",
                            hideFooter: true,
                            header: "Dodawanie usługi",
                            xl: true,
                            body: <LocationsDetailsServiceChooseModal />,
                          })
                        }
                      >
                        Dodaj
                      </Button>
                    ) : null
                  }
                </Row>
              </Col>
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        {location.services.map((srv, i) => {
          if (filter.find((state) => state === srv.state)) {
            // check if service can be cancelled:
            const canCancel = srv.state === "aktywna" ? srv.canCancel : false

            return (
              <React.Fragment key={`services-rowFragment-${srv._id}`}>
                <tr
                  key={`services-row-${srv._id}`}
                  className="fixed-values-table"
                >
                  <td colSpan="5">
                    <Row>
                      <Col xs="1">
                        <Form.Check
                          inline
                          name={`service-checkbox-${i}`}
                          type="checkbox"
                          id={`service-checkbox-${i}`}
                          onChange={(e) => {
                            if (e.target.checked)
                              setServicesSelected([...servicesSelected, srv])
                            else {
                              const newArr = [...servicesSelected]
                              setServicesSelected(_.pull(newArr, srv))
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        {srv.shortTermNo ? (
                          <>
                            <Badge variant="secondary" pill>
                              IMP-{srv.shortTermNo}
                            </Badge>
                            <br />
                          </>
                        ) : null}
                        <b>
                          {srv.state === "anulowana" ? (
                            <Badge variant="warning">ANULOWANA</Badge>
                          ) : null}
                          {srv.locSrvNo || "?"}. {srv.name}: {srv.netPrice} zł{" "}
                          {srv.unit}{" "}
                          {srv.chargeType === "krótki termin" &&
                            `za pierwsze ${srv.initPeriod} dni, potem ${srv.additionalPrice} zł + ${srv.jobPrice} za srw `}
                          ({srv.chargeType})
                        </b>{" "}
                        vat: <b>{srv.vat}%</b>
                        {showSrvParams ? (
                          <p className="my-0">
                            Zamkniętych parametrów:{" "}
                            {
                              srv.params.filter((prm) =>
                                isSameDay(
                                  new Date(prm.endDate),
                                  new Date(prm.invoicedTo)
                                )
                              )?.length
                            }
                          </p>
                        ) : null}
                        {renderSubSrvLine(srv)}
                      </Col>
                      <Col>
                        <Row className="justify-content-around">
                          <EditDropdown
                            location={location}
                            refresh={refresh}
                            servicesRefs={servicesRefs}
                            setModalData={setModalData}
                            srv={srv}
                            user={user}
                          />
                          <Button
                            onClick={() => {
                              setModalData({
                                show: true,
                                type: "info",
                                hideFooter: true,
                                header: "Szczegóły usługi",
                                xl: true,
                                body: (
                                  <LocationsDetailsServiceDetailsModal
                                    srv={srv}
                                  />
                                ),
                              })
                            }}
                            id="srvDetailsModal"
                          >
                            szczegóły
                          </Button>
                          {srv.state === "aktywna" &&
                            location.state === "aktywna" && (
                              <>
                                {/* for short term services show only dummy buttons with tooltip */}
                                {srv.chargeType === "ryczałt impreza" ||
                                srv.chargeType === "krótki termin" ? (
                                  <OverlayTrigger
                                    trigger="hover focus"
                                    overlay={
                                      <Tooltip>
                                        Dla usług imprezowych nie można dodawać
                                        parametrów
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <Button
                                        variant="secondary"
                                        disabled
                                        style={{ pointerEvents: "none" }}
                                      >
                                        + parametr
                                      </Button>
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  <Button
                                    variant="secondary"
                                    onClick={() => {
                                      setModalData({
                                        show: true,
                                        type: "info",
                                        hideFooter: true,
                                        header: "Dodawanie parametrów usługi",
                                        body: (
                                          <LocationDetailsSrvAddParamModal
                                            srv={srv}
                                          />
                                        ),
                                      })
                                    }}
                                  >
                                    + parametr
                                  </Button>
                                )}
                                {/* for short term services show only dummy button with tooltip */}

                                {canCancel ? (
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      setModalData({
                                        show: true,
                                        type: "info",
                                        header: "Potwierdź anulowanie usługi",
                                        headerColor: "danger",
                                        hideFooter: true,
                                        body: (
                                          <LocationsDetailsSrvCancelModal
                                            setModalData={setModalData}
                                            srv={srv}
                                            location={location}
                                            refresh={refresh}
                                          />
                                        ),
                                      })
                                    }
                                    id="cancleSrv"
                                  >
                                    Anuluj
                                  </Button>
                                ) : null}
                              </>
                            )}
                          {srv.state === "aktywna" && (
                            <Button
                              variant="warning"
                              onClick={() =>
                                handleCloseBtnClick(
                                  srv,
                                  setModalData,
                                  refresh,
                                  location
                                )
                              }
                            >
                              Zamknij
                            </Button>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </td>
                </tr>
                {renderParamsRows(srv)}
                {/* {srv.params.map((param) => renderParamsRow(param, srv))} */}
              </React.Fragment>
            )
          } else return null
        })}
      </tbody>
    </Table>
  )
}

const handleCloseBtnClick = (srv, setModalData, refresh, location) => {
  if (srv.equipment?.length)
    return setModalData({
      show: true,
      type: "info",
      header: "Nie można zamknąć usługi",
      body: "Na lokalizacji znajduje się sprzęt przypisany do usługi",
    })
  else
    setModalData({
      show: true,
      type: "info",
      header: "Zamykanie usługi",
      headerColor: "warning",
      hideFooter: true,
      body: (
        <LocationDetailsServiceCloseModal
          setModalData={setModalData}
          refresh={refresh}
          srv={srv}
          location={location}
        />
      ),
    })
}
export default LocationsDetailsServicesTable
