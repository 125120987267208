/**
 * creates readable string for equipment
 * @param {*} eqp eqp object with populated ref
 * @param {*} srv service to add subSrv (optional)
 * @param {Boolean} returnString if true, returns string instead of JSX
 * @param {Boolean} includeQty if true, includes qty in string/JSX
 * @returns readable and pretty eqp string
 */

const getEqpDesc = (eqp, srv = {}, returnString = false, includeQty = true) => {
  const eqpProps = ["subtype", "char", "color", "category"]

  const renderEqpProp = (prop) => {
    if (
      !eqp.ref[prop] ||
      eqp.ref[prop] === "bez znaczenia" ||
      eqp.ref[prop] === "wybierz"
    )
      return ""
    else return eqp.ref[prop]
  }

  const renderEqpNr = () => {
    if (eqp.ref.isTemplate || !eqp.ref.number) return ""
    else
      return (
        <>
          nr: <u>{eqp.ref.number}</u>
        </>
      )
  }

  if (returnString)
    return `${includeQty ? `${eqp.qty} x ` : ""}${eqp.ref.type} ${eqpProps
      .map((prop) => renderEqpProp(prop))
      .filter((el) => el)
      .join(", ")} ${
      eqp.ref.mods?.filter((mod) => mod && mod !== "false").length
        ? `(${eqp.ref.mods.join(", ")})`
        : ""
    } ${renderEqpNr()} ${
      srv.subSrv?.length ? (
        <b>(+ {srv.subSrv.map((subSrv) => subSrv.name).join(", ")})</b>
      ) : (
        ""
      )
    }`
  else
    return (
      <>
        {includeQty ? <b>{eqp.qty} x </b> : null}
        {eqp.ref.type}{" "}
        {eqpProps
          .map((prop) => renderEqpProp(prop))
          .filter((el) => el)
          .join(", ")}{" "}
        {eqp.ref.mods?.filter((mod) => mod && mod !== "false").length
          ? `(${eqp.ref.mods.join(", ")})`
          : ""}{" "}
        {renderEqpNr()}
        {srv.subSrv?.length ? (
          <b>(+ {srv.subSrv.map((subSrv) => subSrv.name).join(", ")})</b>
        ) : (
          ""
        )}
      </>
    )
}

export { getEqpDesc }
