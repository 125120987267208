import React from "react"

const Desktop = () => {
  return (
    <div>
      <p>Pulpit tymczasowy</p>
    </div>
  )
}
export default Desktop
