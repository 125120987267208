import React, { useContext, useState, useEffect } from "react"
import { Container, Col, Row, Table, Button, Form } from "react-bootstrap"
import { createManualBtn } from "../../utils/buttons"

import { LocationCtx } from "../Fetch"

import LocationsDetailsServiceEditModal from "./LocationsDetailsServiceAddModal"

const LocationsDetailsServiceChoodeModal = () => {
  const { services, setModalData } = useContext(LocationCtx)
  const [step, setStep] = useState("chooseService")
  const [srvFiltered, setSrvFiltered] = useState([])
  const [filters, setFilters] = useState({ type: "all", chargeType: "all" })
  const [serviceChoosen, setServiceChoosen] = useState(null)

  useEffect(() => {
    //TODO clean services fetching in location: ERP-1006
    const newServices = services
      .filter((srv) => srv.state === "aktywna")
      .filter((srv) => {
        let isOk = true
        if (filters.type !== "all" && srv.type !== filters.type) isOk = false
        if (
          filters.chargeType !== "all" &&
          srv.chargeType !== filters.chargeType
        )
          isOk = false
        if (filters.name) {
          const nameSearch = new RegExp(filters.name, "gi")
          isOk = nameSearch.test(srv.name)
        }
        return isOk
      })
      .sort((a, b) => {
        const aPriority = a.priority || 0
        const bPriority = b.priority || 0

        return bPriority - aPriority
      })
    setSrvFiltered(newServices)
  }, [filters, services, setSrvFiltered])

  const renderForm = () => {
    if (step === "chooseService")
      return (
        <Container>
          <Row>
            <Col>
              Nazwa:
              <Form.Control
                as="input"
                id="serviceFiltersName"
                type=""
                className=""
                autoComplete="chrome-off"
                onChange={(e) =>
                  setFilters({ ...filters, name: e.target.value })
                }
              />
            </Col>
            <Col>
              Typ:
              <Form.Control
                as="select"
                id="serviceFiltersType"
                className=""
                autoComplete="chrome-off"
                onChange={(e) =>
                  setFilters({ ...filters, type: e.target.value })
                }
              >
                <option value="all">Wszystkie</option>
                <option value="toalety">toalety</option>
                <option value="szambo">szambo</option>
                <option value="sprzątanie">sprzątanie</option>
                <option value="ogrodzenia">ogrodzenia</option>
                <option value="prysznice">prysznice</option>
                <option value="inne">inne</option>
              </Form.Control>
            </Col>
            <Col>
              Naliczenie:
              <Form.Control
                as="select"
                id="serviceFiltersChargeType"
                className=""
                autoComplete="chrome-off"
                onChange={(e) =>
                  setFilters({ ...filters, chargeType: e.target.value })
                }
              >
                <option value="all">Wszystkie</option>
                <option>za dzień</option>
                <option>za miesiąc</option>
                <option>za serwis</option>
                <option>krótki termin</option>
                <option>ryczałt impreza</option>
              </Form.Control>
            </Col>
            <Col xs="auto">{createManualBtn("loc-services")}</Col>
          </Row>
          <Table>
            <tbody>
              <tr>
                <td className="fixed-values-table">Nazwa</td>
                <td className="fixed-values-table">Typ</td>
                <td className="fixed-values-table">Cena</td>
                <td className="fixed-values-table">Naliczenie</td>
                <td className="fixed-values-table">jm.</td>
                <td className="fixed-values-table">Sprzętowa</td>
                <td className="fixed-values-table">Wymaga zadań</td>
              </tr>
              {srvFiltered?.length
                ? srvFiltered.map((service) => {
                    return (
                      <tr
                        className="clickable"
                        onClick={() => {
                          setServiceChoosen(service)
                          setStep("serviceDetails")
                        }}
                        key={`service-row-${service._id}`}
                        id={`${service.name}-${service.chargeType}`} // for Cypress
                      >
                        <td>{service.name}</td>
                        <td>{service.type}</td>
                        <td>{service.defaultNetPrice}</td>
                        <td>{service.chargeType}</td>
                        <td>{service.unit}</td>
                        <td>
                          {service.mustHaveEqp ? "sprzętowa" : "bezsprzętowa"}
                        </td>
                        <td>
                          {service.mustHaveJobs
                            ? "zadania obowiązkowe"
                            : "zadania nieobowiązkowe"}
                        </td>
                      </tr>
                    )
                  })
                : "brak usług"}
            </tbody>
          </Table>

          <Button
            variant="warning"
            className="mr-3"
            onClick={() => setModalData({ show: false })}
          >
            Anuluj
          </Button>
        </Container>
      )
    else if (step === "serviceDetails")
      return (
        <LocationsDetailsServiceEditModal
          serviceChoosen={serviceChoosen}
          isNew={true}
          setStep={setStep}
        />
      )
  }
  return <Container>{renderForm()}</Container>
}
export default LocationsDetailsServiceChoodeModal
