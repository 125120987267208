import { useEffect, useState } from "react"

const EqpCompliance = () => {
  const [services, setServices] = useState([])
  const [fetchErr, setFetchErr] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("/reports/eqpCompliance", {
          method: "POST",
        })

        if (res.status !== 200) {
          throw new Error(
            `Error fetching data: ${(await res.text()) || "Unknown error"}`
          )
        }

        const resJSON = await res.json()
        console.log(resJSON)
        setServices(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchErr(err.message)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])
  if (isLoading) {
    return <div>Loading...</div>
  } else if (fetchErr) {
    return <div>{fetchErr}</div>
  }

  return <EqpComplianceTable services={services} />
}

const EqpComplianceTable = ({ services }) => {
  return (
    <div>
      <p>
        Tabela pokazuje wszystkie aktywne usługi zawierające obecnie trwający
        parametr, którego ilość (do faktury) jest różna od liczby dostarczonego
        sprzętu a rodzaj naliczenia !== "za serwis"
      </p>
      <table>
        <thead>
          <tr>
            <th>Oddział</th>
            <th>Nazwa</th>
            <th>Adres</th>
            <th>Usługa</th>
            <th>Sprzęt usługa</th>
            <th>Sprzęt dostarczony</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service, i) => {
            return (
              <tr key={`srvRow-${service.loc_id}-${i}`}>
                <td>{service.locBranch}</td>
                <td>{service.locName}</td>
                <td>{service.locStreet}</td>
                <td>
                  {service.srvNo}. {service.srvName}
                </td>
                <td>{service.currentParamQty}</td>
                <td>{service.eqpQty}</td>
                <td>
                  <a
                    href={`https://cliperp.app/Locations/${service.loc_id}`}
                  >{`https://cliperp.app/Locations/${service.loc_id}`}</a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { EqpCompliance }
