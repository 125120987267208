import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"

import GoodsListRender from "./goodsListRender"

const GoodsListFetch = () => {
  const [cookies] = useCookies()
  const [isLoading, setIsLoading] = useState(true)
  const [goods, setGoods] = useState()
  useEffect(() => {
    const fetchGoods = async () => {
      try {
        const res = await fetch("/goods/all", {
          headers: { authorization: cookies.jwt },
          method: "GET",
        })

        const resJSON = await res.json()
        if (res.status === 200) {
          setGoods(resJSON)
          setIsLoading(false)
        } else {
          throw resJSON
        }
      } catch (err) {
        alert(err)
      }
    }
    fetchGoods()
  }, [cookies.jwt])
  return (
    <div>
      {isLoading ? <p>wczytuję...</p> : <GoodsListRender goods={goods} />}
    </div>
  )
}
export default GoodsListFetch
