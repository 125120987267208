import { useForm } from "react-hook-form"
import { Row, Table, Button, Form, Badge } from "react-bootstrap"
import { format } from "date-fns"

const DebtCollectionNoteModal = ({ subtype, submitNote, setModalData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { eventDate: format(new Date(), "yyyy-MM-dd") },
  })

  const submitWrapper = (formData) =>
    submitNote(subtype, formData.eventDate, formData.body)

  return (
    <Form onSubmit={handleSubmit(submitWrapper)}>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Podtyp</td>
            <td>{subtype}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Data zdarzenia</td>
            <td>
              {errors.eventDate && (
                <Badge pill variant="warning">
                  {errors.eventDate.message}
                </Badge>
              )}
              <Form.Control
                {...register("eventDate", { required: true })}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="fixed-values-table">
              Treść
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Form.Control
                {...register("body")}
                as="textarea"
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Row className="justify-content-between">
        <Button variant="warning" onClick={() => setModalData({ show: false })}>
          Anuluj
        </Button>
        <Button type="submit">Zapisz</Button>
      </Row>
    </Form>
  )
}
export { DebtCollectionNoteModal }
