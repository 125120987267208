import React, { useContext } from "react"
import { Table, Container, Row, Col } from "react-bootstrap"

import { CustomerCtx } from "../Fetch"

import CustomersContactDetailsModal from "../entities/CustomersContactDetailsModal"
import CustomerDetailsContactsTable from "../tables/ContactsTable"

const CustomersDetailsContactsTab = () => {
  const ctx = useContext(CustomerCtx)

  return (
    <Container className="my-2" fluid>
      <CustomerDetailsContactsTable
        embedded={false}
        contacts={ctx.customer.contacts?.filter(
          (ctc) => ctc.state === "aktywny"
        )}
        setModalData={ctx.setModalData}
        user={ctx.user}
        customer={ctx.customer}
      />
      <Table>
        <thead>
          <tr>
            <th colSpan="5">
              <Row>
                {/* Col offset and span added only to keep it +/- in the same place as in above table */}
                <Col xs={{ offset: 5, span: 3 }}>
                  Kontakty <b>archiwalne</b>
                </Col>
              </Row>
            </th>
          </tr>
          <tr>
            <th>Rodzaj</th>
            <th>Imię i nazwisko/nazwa</th>
            <th>Telefon</th>
            <th>Mail</th>

            <th>Notatki</th>
          </tr>
        </thead>
        <tbody>
          {ctx.customer.contacts?.map((contact) => {
            if (contact.state === "archiwum")
              return (
                <tr
                  key={`contact-row-${contact._id}`}
                  className="clickable"
                  onClick={() =>
                    ctx.setModalData({
                      show: true,
                      type: "info",
                      header: (
                        <p className="mb-0">
                          Szczegóły kontaktu {contact.name || <i>brak nazwy</i>}{" "}
                          (rodzaj: {contact.type})
                        </p>
                      ),
                      body: (
                        <CustomersContactDetailsModal
                          contact_id={contact._id}
                        />
                      ),
                    })
                  }
                >
                  <td>{contact.type}</td>
                  <td>{contact.name}</td>
                  <td>{contact.phone}</td>
                  <td>{contact.mail}</td>
                  <td>{contact.note?.body}</td>
                </tr>
              )
            else return null
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default CustomersDetailsContactsTab
