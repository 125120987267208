import { clearDiacritics } from "../../../utils/formats"

const createLocName = (locationsNames, customer) => {
  let i = 1
  let name
  // func to check if name is uniq:
  const checkName = (nameToCheck) => {
    return locationsNames.findIndex((locName) => locName === name) !== -1
  }

  // start creating name until name is uniq:
  do {
    name = `${customer.shortName || customer.name} - lok. ${i
      .toString()
      .padStart(3, "0")}`
    i++
  } while (checkName())

  return name
}

const locSubmit = async (
  formData,
  isNew,
  user,
  cust_id,
  loc_id,
  dirtyFields,
  locHistLength,
  setModalData,
  setFetchError,
  refresh,
  customerContacts,
  permChoosen,
  setIsFetching
) => {
  try {
    setIsFetching(true)
    // used only for new locations:
    const notes = []
    if (isNew && formData.mainNote?.length !== 0) {
      notes.push({
        body: formData.mainNote,
        type: "główna",
        state: "aktywna",
        user: user._id,
        history: [
          {
            type: "utworzenie",
            date: new Date(),
            user: user._id,
            description: "utworzenie notatki",
          },
        ],
      })
    }
    if (isNew && formData.logisticNote.length !== 0) {
      notes.push({
        body: formData.logisticNote,
        type: "na kontrolkę",
        state: "aktywna",
        user: user._id,
        history: [
          {
            type: "utworzenie",
            date: new Date(),
            user: user._id,
            description: "utworzenie notatki",
          },
        ],
      })
    }

    if (isNew && formData.initNote.length) {
      notes.push({
        body: formData.initNote,
        type: "rejestracja wstępna",
        state: "aktywna",
        user: user._id,
        history: [
          {
            type: "utworzenie",
            date: new Date(),
            user: user._id,
            description: "utworzenie notatki",
          },
        ],
      })
    }

    // if new and contact selected -> prepare contact to add
    const contact = {}
    //TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // if (isNew && contactSelected) {
    //   contact.type = "główny"
    //   contact.state = "aktywny"
    //   contact.name = contactSelected.name
    //   contact.phone = contactSelected.phone
    //   contact.mail = contactSelected.mail
    //   contact.fax = contactSelected.fax
    //   contact.note = contactSelected.note
    //   contact.history = [
    //     {
    //       type: "import",
    //       user: user._id,
    //       date: new Date(),
    //       description: "Import z kontaktów klienta",
    //     },
    //   ]
    // }

    // prepare data to send:
    const locationData = isNew
      ? {
          customer: cust_id,
          name: formData.name,
          street: formData.street,
          postal: formData.postal,
          city: formData.city,
          description: formData.description,
          branch: formData.branch,
          hasDriver: formData.hasDriver,
          state: "aktywna",
          notes: notes,
          contacts: [contact],
        }
      : {
          _id: loc_id,
          name: formData.name,
          street: formData.street,
          postal: formData.postal,
          city: formData.city,
          description: formData.description,
          hasDriver: formData.hasDriver,
          branch: formData.branch,
        }

    // add contacts to import from customer
    if (isNew && formData.custContacts?.length) {
      // when there is only one customer contact RHF returns string not array
      // what causes later .map to break, so I'm converting it to 1-el-arr
      if (typeof formData.custContacts === "string")
        formData.custContacts = [formData.custContacts]

      locationData.contacts = formData.custContacts
        .map((ctc_id) => {
          // find contact data:
          const customerContact = customerContacts.find(
            (custCtc) => custCtc._id === ctc_id
          )
          if (!customerContact) return null
          const { type, name, phone, mail, note, fax } = customerContact
          return {
            type: type === "główny" ? "główny" : "inny",
            state: "aktywny",
            name: name,
            phone: phone,
            mail: mail,
            note: note,
            fax: fax,
            history: [
              {
                type: "import",
                user: user._id,
                date: new Date(),
                description: "Import z kontaktów klienta",
              },
            ],
          }
        })
        .filter((e) => e)
    }

    //* add permission if choosen:
    if (permChoosen) locationData.permission = permChoosen.value

    // I don't add driver key in data to send to prevent 'null' failing mongoose validation
    if (formData.hasDriver) locationData.driver = formData.driver

    const res = await fetch(`/locations/${isNew ? "add" : `edit/${loc_id}`}`, {
      method: isNew ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        location: locationData,
        dirtyFields: dirtyFields,
        historyLength: isNew ? 0 : locHistLength,
        changeJobsDriver: formData.changeJobsDriver,
      }),
    })

    if (res.status === 403) return setFetchError("Brak uprawnień")
    if (res.status === 409)
      return setFetchError(
        "Wygląda na to, że ktoś w międzyczasie już edytował tą lokalizację. Musisz odświeżyć stronę, żeby pobrać najnowsze dane. Zanim to zrobisz upewnij się, że masz gdzieś zapisane dane wprowadzone do formularza."
      )
    if (res.status !== 200) throw res

    if (isNew) {
      const resJSON = await res.json()

      // prevent opening new tab while testing:
      if (!resJSON.cypress) {
        setModalData({ show: false })
        window.open(
          `/Locations/${resJSON.location_id}`,
          "Location Details",
          "noopener noreferrer"
        )
        refresh()
      }
    } else
      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: "Zmiana zapisana",
        onHide: refresh,
      })
  } catch (err) {
    console.log(err)
    setIsFetching(false)
    return setFetchError("Błąd zapisywania")
  }
}

const getMunicipal = async (input, setPermChoosen, permissions, setPermErr) => {
  try {
    setPermErr(null)
    if (input.length <= 3) return setPermErr("najpierw wprowadź adres")
    const res = await fetch(`/misc/getMunicipal?input=${input}`)

    if (res.status !== 200) {
      setPermErr("błąd wyszukiwania")
      throw new Error(await res.text())
    }

    const municipal = await res.text()

    if (!municipal.length) return setPermErr("nie znaleziono gminy")
    // find municipal in permissions
    const perm = permissions.find((permission) => {
      const permissionMunicipalClear = clearDiacritics(permission.municipal)
      const municipalClear = clearDiacritics(municipal)
      return (
        permissionMunicipalClear.toLowerCase() === municipalClear.toLowerCase()
      )
    })
    if (!perm)
      return setPermErr(
        `Gmina: ${municipal} - brak w bazie (przypisz "bez zezwolenia" i ZGŁOŚ)`
      )

    setPermChoosen({ value: perm._id, label: perm.municipal })
  } catch (err) {
    console.log(err)
    return null
  }
}

export { locSubmit, createLocName, getMunicipal }
