import { useContext } from "react"
import { Container, Tab, Tabs, Alert, Button, Row, Col } from "react-bootstrap"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"

import { LogCtx } from "./Fetch"
import RoutesPlanningTableTab from "./tableTab"
import RoutesPlanningMapTab from "./mapTab"
import RoutesPlanningAdditionalTab from "./additionalTab"
import { RoutesPlanningShortTermTab } from "./shortTermTab"

const RoutesPlanningRender = (props) => {
  const {
    tabSelected,
    setTabSelected,
    noToiletJobs,
    shortTermJobs,
    suspendedJobs,
  } = props
  const { setModalData, user, configs, refresh, cars } = useContext(LogCtx)
  return (
    <Container fluid id="RoutesPlanningRender">
      {suspendedJobs.length ? (
        <Alert
          style={{
            animationName: "red-alert",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            textAlign: "center",
          }}
        >
          <Row>
            <Col>
              <h5>
                <b>! Windykacja - zadania wstrzymane:</b>
              </h5>
            </Col>
            <Col>
              {suspendedJobs.map((job) => (
                <Button
                  size="sm"
                  key={`noToiletJobsAlert-${job._id}`}
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Edycja zadania",
                      body: (
                        <JobDetailsModal
                          parent="Logistics"
                          job={job}
                          user={user}
                          configs={configs}
                          setModalData={setModalData}
                          refresh={refresh}
                          location={job.location}
                          cars={cars}
                        />
                      ),
                    })
                  }
                >
                  {job.location.street}
                </Button>
              ))}
            </Col>
          </Row>
        </Alert>
      ) : null}
      <Row>
        {noToiletJobs.length ? (
          <Col>
            <Alert variant="warning">
              <h5>Nie toaletowe:</h5>
              {noToiletJobs.map((job) => (
                <Button
                  size="sm"
                  key={`noToiletJobsAlert-${job._id}`}
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Edycja zadania",
                      body: (
                        <JobDetailsModal
                          parent="Logistics"
                          job={job}
                          user={user}
                          configs={configs}
                          setModalData={setModalData}
                          refresh={refresh}
                          location={job.location}
                          cars={cars}
                        />
                      ),
                    })
                  }
                >
                  {job.location.street}
                </Button>
              ))}
            </Alert>
          </Col>
        ) : null}
        {shortTermJobs.length ? (
          <Col>
            <Alert variant="warning">
              <h5>Impreza/krótki termin:</h5>
              {shortTermJobs.map((job) => (
                <Button
                  size="sm"
                  key={`shortTermAlertBtn-${job._id}`}
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Edycja zadania",
                      body: (
                        <JobDetailsModal
                          parent="Logistics"
                          job={job}
                          user={user}
                          configs={configs}
                          setModalData={setModalData}
                          refresh={refresh}
                          location={job.location}
                          cars={cars}
                        />
                      ),
                    })
                  }
                >
                  {job.location.street}
                </Button>
              ))}
            </Alert>
          </Col>
        ) : null}
      </Row>
      <Tabs
        id="location-tabs"
        activeKey={tabSelected}
        onSelect={(key) => setTabSelected(key)}
        fill
        className="tab mt-2"
        transition={false}
      >
        <Tab eventKey="map" title="Mapa" className="tab">
          <RoutesPlanningMapTab />
        </Tab>
        <Tab eventKey="table" title="Tabela" className="tab">
          <RoutesPlanningTableTab />
        </Tab>

        {shortTermJobs?.length ? (
          <Tab
            eventKey="shortTerm"
            title="Krótki termin"
            className="tab"
            tabClassName="bg-warningLight"
          >
            <RoutesPlanningShortTermTab />
          </Tab>
        ) : null}
        <Tab eventKey="summary" title="Podsumowanie" className="tab">
          <RoutesPlanningAdditionalTab />
        </Tab>
      </Tabs>
    </Container>
  )
}
export default RoutesPlanningRender
