import React from "react"
import { Row, Col, Alert } from "react-bootstrap"
import {
  MdLocalPhone,
  MdTextsms,
  MdAlternateEmail,
  MdAccountBalance,
  MdPause,
} from "react-icons/md"
import {
  IoEllipsisHorizontalSharp,
  IoDocumentOutline,
  IoPlay,
} from "react-icons/io5"

import debtCollectionCustomerCat from "../../pics/manual/debtCollection/debtCollectionCustomerCat.png"
import debtCollectionTable from "../../pics/manual/debtCollection/debtCollectionTable.png"
import debtCollectionPopup from "../../pics/manual/debtCollection/debtCollectionPopup.png"
import debtCollectionXlsxFilters from "../../pics/manual/debtCollection/debtCollectionXlsxFilters.png"

const ManualDebtCollectionSection = (props) => {
  return (
    <div>
      <Row>
        <Col>
          <h2 id="debtCollection">Windykacja</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Część ogólna</h3>
          <i>Poniższe jest uzupełnieniem dla procedury windykacyjnej.</i>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p>
            <b>Kategorie klientów</b> - klient może być oznaczony jako
            "zielony", "zwykły" lub "czerwony". Kategorię zmienia się w edycji
            szczegółow klienta w sekcji "Parametry".
            <br />
            Niektóre zmiany wymagają dodatkowych uprawnień (np. z czerwonego na
            zielony).
          </p>

          <div id="jobsSuspended">
            <h4>Wstrzymywanie serwisów/zadań</h4>

            <p>
              Wstrzymanie zadań nie powoduje ich usunięcia, będą nadal widoczne
              w kalendarzu i modułach windykacyjnych oraz wydrukują się na
              kontrolce. Jednak we wszystkich tych miescach pojawi się alert o
              wstrzymaniu zadań.
            </p>
            <p>
              Po wstrzymaniu zadań zostanie wysłane powiadomienie @ do wybranych
              osób, podobnie przy zdjęciu blokady.
              <br />
              <b>
                Dodatkowo każdego dnia rano program sprawdzi, czy są zaplanowane
                wstrzymane zadania na następny dzień i wyśle odpowiedniego maila
              </b>
              do osób odpowiedzialnych za rozliczanie przelewów przychodzących.
              W piątek program sprawdza zadania na weekend i poniedziałek, ale{" "}
              <u>nie</u> sprawdza "w przód" w przypadku świąt i dni wolnych.
            </p>
          </div>
        </Col>
        <Col>
          <img
            src={debtCollectionCustomerCat}
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Komponent "Windykacja"</h3>
          <i>
            Tylko dla osób kontaktujących się z klientami w sprawie zadłużenia
          </i>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p>
            Tabela windykacyjna umożliwia szybkie zaznaczanie podjętych działań,
            oraz wstrzymywanie/odblokowywanie serwisów. <br />
            Działanie/akcja windykacyjna to po prostu notatka klienta (można je
            też dodawać z podglądu klienta). <br />
            Notatka z wybranym typem „windykacyjna” umożliwia wybranie podtypu
            (lista niżej) oraz daty zdarzenia.
          </p>
          <p>
            Przyciski akcji w tabeli windykacyjnej powodują dodanie takiej
            notatki z wybranym podtypem i dzisiejszą datą zdarzenia – bez
            konieczności wprowadzania wszystkiego ręcznie.
          </p>
          <p>
            Jeśli chcesz dodać treść notatki (np. ustalenie daty spłaty z
            klientem) to trzeba kliknąć trójkąt po prawej stronie przycisku i
            potem kliknąć symbol „+” (jak na zrzucie obok). Pojawi się modal w
            którym można wprowadzić treść notatki i zmienić datę zdarzenia.
          </p>
          <p>
            <b>Znaczenie ikon (rodzaje działań):</b>
            <br />
            <MdLocalPhone /> - telefon (dodajemy tylko udane połączenie, nie
            same próby!)
            <br />
            <MdTextsms /> - SMS
            <br />
            <MdAlternateEmail /> - email
            <br />
            <IoDocumentOutline /> - wezwanie do zapłaty
            <br />
            <MdAccountBalance /> - wezwanie KRD
            <br />
            <IoEllipsisHorizontalSharp /> - notatka windykacyjna ogólna
            <br />
            <MdPause /> - wstrzymanie serwisów.{" "}
            <b>
              Uwaga! kliknięcie powoduje natychmiastową akcję, program nie prosi
              o potwierdzenie.
            </b>
            <br />
            <IoPlay /> - wznowienie serwisów
          </p>
        </Col>
        <Col>
          <img src={debtCollectionTable} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Poza powyższym w tabeli wyświetlane jest ostatnie działanie
            (ostatnio dodana notatka windykacyjna). Po najechaniu na ikonę
            wyświetlą się szczegóły.
            <br />
            <u>
              W przypadku kilku notatek z jednego dnia pojawi się tylko jedna z
              nich
            </u>
          </p>
          <p>
            W kolejnej kolumnie wyświetlają się kontakty: główny i wszystkie
            kontakty typu „płatności”. Najechanie na kontakt wyświetla
            szczegóły, a kliknięcie przycisku z symbolem „@” powoduje otwarcie
            nowej wiadomości w Outlooku z już wprowadzonym adresem.
          </p>
        </Col>

        <Col>
          <img src={debtCollectionPopup} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>

      <Row>
        <Col>
          <h4 id="debtColl-excelFile">Dane z pliku excela</h4>
          <br />
          <i>Dodane w 1.14.1</i>
        </Col>
      </Row>
      <Row>
        <Col>
          Do programu można wgrać arkusz excela wygenerowany z Symfonii.{" "}
          <Alert variant="danger">
            Ze względu na ilość danych komponent może działać dosyć wolno. Jeśli
            to będzie utrudniało pracę proszę o zgłoszenie.
          </Alert>
          <Alert variant="danger">
            <i>Dla osób przygotowujących plik:</i>
            <br />
            Skoroszyt musi zawierać jeden arkusz, w którym nagłówki mają
            następujące nazwy: "Numer" - z numerem dokumentu, "Data
            wystawienia", "Termin płatności", "Wartość PLN", "Kod kontrahenta",
            "Opis".
          </Alert>
          <p>
            Po wgraniu pliku i załadowaniu danych w tabeli pojawią się dwie
            dodatkowe kolumny:
          </p>
          <ul>
            <li>
              "Termin" - data płatności (jeśli w pliku było kilka faktur dla
              klienta - najstarsza data)
            </li>
            <li>
              "Zaległość" - kwota zadłużenia (nadpłaty jeśli ujemna) obliczona
              na podstawie <u>wszystkich</u> dokumentów z pliku
            </li>
          </ul>
          <p>
            Kliknięcie na którąkolwiek z powyższych komórek powoduje
            wyświetlenie wszystkich dokumentów klienta
          </p>
          <p>
            Po wgraniu pliku <b>wyszukiwarka zmienia sposób działania:</b>{" "}
            zamiast przeszukiwać bazę wszystkich naszych klientów, sprawdza
            tylko dane zawarte w pliku.{" "}
            <u>
              Aby przeszukiwać z powrotem całą bazę danych należy kliknąć
              przycisk "Wyczyść dane".
            </u>
          </p>
          <p>
            Przyciski akcji po wczytaniu pliku działają jak przy standardowym
            korzystaniu z komponentu.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 id="debtCollectionXlsxFilters">Dodatkowe filtry</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            <i>dodane w 1.14.2</i>
          </p>
          <p>
            Po wgraniu pliku można korzystać z dodatkowych filtrów widocznych na
            zrzucie obok.
            <br />
          </p>
          <h6>Działanie filtrów</h6>
          <p>
            Dodatkowe filtry działają <b>łącznie</b> z wyszukiwarką na górze.
          </p>
          <p>
            Filtry "Termin" i "Kwota" działają na zasadzie zakresów od-do, przy
            czym wpisane wartości są włączane do wyników ("większe lub równe" i
            "mniejsze lub równe").
            <br />
            Jeśli nic nie jest wpisane program ignoruje puste okienko, czyli
            można wyszukać po kwocie od 100 zł w górę wpisując "Kwota od" = 100
            i zostawiając "Kwota do" puste.
          </p>
          <p>
            Filtr "Brak akcji od" pokazuje klientów, u których nie było akcji
            windykacyjnej <u>co najmniej</u> od wpisanej liczby dni,{" "}
            <u>albo nie było jej w ogóle</u>{" "}
          </p>
          <p>
            Aby przefiltrować klientów należy kliknąć "Zastosuj" lub Enter.{" "}
          </p>
          <p>
            <b>Domyślne wartości</b>
            <br />
            Program domyślnie pokazuje klientów z terminem od -2 dni (tzn.
            termin upływa za dwa dni) i bez górnej granicy, oraz z kwotą powyżej
            0 zł (aby ukryć klientów z nadpłatami).
            <br />
            Domyślne wartości można przywrócić klikając żółty przycisk.
          </p>
        </Col>
        <Col>
          <img
            src={debtCollectionXlsxFilters}
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>
    </div>
  )
}

export { ManualDebtCollectionSection }
