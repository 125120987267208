import React, { useEffect, useState, useContext } from "react"
import { Container } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"

import { BranchesStatsRender } from "./render"
import { BranchesStatsForm } from "./form"

const BranchesStatsFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        setIsLoading(true)

        const res = await fetch("/reports/branchesStats", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(filters),
        })

        if (res.status !== 200) {
          throw new Error(
            `Błąd komunikacji z serwerem: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        }

        const resJSON = await res.json()
        setData(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: err.message,
        })

        setIsLoading(false)
      }
    }
    if (filters.branch && filters.year) fetchData()
  }, [filters])

  const handleMonthRecalculate = async (month) => {
    try {
      setIsLoading(true)

      const res = await fetch("/reports/recalculateBranchStats", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...filters, month }),
      })

      if (res.status !== 200) {
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      }

      const resJSON = await res.json()

      setData((prevData) => {
        for (const element in prevData) {
          if (element === "reportErrors") {
            // if there are any errors in fetched data check if it occurs in prevData and push only unique errors
            if (resJSON.reportErrors?.length) {
              resJSON.reportErrors.forEach((errString) => {
                if (!prevData.reportErrors.includes(errString))
                  prevData.reportErrors.push(errString)
              })
            }
          } else {
            // else just replace data:
            prevData[element][month] = resJSON[element]
          }
        }

        return prevData
      })
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message,
      })

      setIsLoading(false)
    }
  }

  const showBranchStatsRender = () => {
    if (isLoading) return renderSpinner()

    return (
      <BranchesStatsRender
        setModalData={setModalData}
        reportData={data}
        setFilters={setFilters}
        handleMonthRecalculate={handleMonthRecalculate}
      />
    )
  }
  return (
    <Container fluid>
      <BranchesStatsForm
        user={user}
        setFilters={setFilters}
        isLoading={isLoading}
      />
      {showBranchStatsRender()}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export { BranchesStatsFetch }
