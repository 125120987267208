/**
 *
 * @param {object} job job object populated with location.notes
 * @returns
 */

const getJobNotes = (job) => {
  const jobNotes = []

  // show hours:
  if (job.customHours) {
    jobNotes.push(`(${job.startTime} - ${job.endTime})`)
  }

  // show job notes:

  if (job.comments) {
    jobNotes.push(job.comments)
  }
  // show location notes if location populated:
  if (job.location?.notes?.length) {
    const locationNotes = job.location.notes
      .filter(
        (note) => note.type === "na kontrolkę" && note.state === "aktywna"
      )
      .map((note) => note.body)

    if (locationNotes.length) {
      jobNotes.push(locationNotes.join(", "))
    }
  }

  return jobNotes.join(" || ")
}
export { getJobNotes }
