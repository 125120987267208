import {
  areIntervalsOverlapping,
  differenceInCalendarDays,
  format,
  max,
  min,
} from "date-fns"

const paramsValidation = async (params) => {
  try {
    // check if only one param without endDate:
    if (
      params.filter((prm) => !prm.hasEndDate || prm.hasEndDate === "false")
        .length > 1
    )
      return "Znaleziono więcej niż jeden parametr bez daty zakończenia"

    // get index of param without endDate
    const noEndDateInd = params.findIndex(
      (prm) => !prm.hasEndDate || prm.hasEndDate === "false"
    )

    //check if ony intervals overlap:
    let overlapErr = false
    params.forEach((prm, i) => {
      // one-way check: 0 with 1, 2, 3, 4 etc, but 1 with 2, 3, 4, 5 etc - no need to check with 0 again
      //# check only when has endDate
      if (i !== noEndDateInd) {
        for (let j = i + 1; j < params.length; j++) {
          // console.log(`i: ${i}, j: ${j}`)

          // check only when has endDate:
          if (
            j !== noEndDateInd &&
            areIntervalsOverlapping(
              {
                start: new Date(params[i].startDate),
                end: new Date(params[i].endDate),
              },
              {
                start: new Date(params[j].startDate),
                end: new Date(params[j].endDate),
              },
              { inclusive: true }
            )
          )
            return (overlapErr = true)
        }
      }
    })
    if (overlapErr) return "Znaleziono parametry z kolidującymi zakresami dat"

    //* check if there are no holes between params:
    if (params.length > 1) {
      // get earliest param
      const firstParam = params.reduce((oldestParam, currParam) => {
        if (
          differenceInCalendarDays(
            new Date(oldestParam.startDate),
            new Date(currParam.startDate)
          ) > 0
        )
          return currParam
        else return oldestParam
      })

      // check all params:
      let currParam = firstParam
      do {
        // check only if param has end date, else set currParam to null (end loop)
        if (currParam.endDate) {
          // get startDates of params starting after currParam endDate:
          const olderParamsStartDates = params
            .filter(
              // eslint-disable-next-line no-loop-func
              (prm) =>
                differenceInCalendarDays(
                  new Date(currParam.endDate),
                  new Date(prm.startDate)
                ) < 0
            )
            .map((prm) => new Date(prm.startDate))

          // when there are no params starting after this param -> end loop
          if (!olderParamsStartDates.length) currParam = null
          else {
            // get next param start date:
            const nextParamStartDate = min(olderParamsStartDates)

            // check if next param startDate is one day after currParam endDate and return msg if not:
            if (
              differenceInCalendarDays(
                new Date(currParam.endDate),
                new Date(nextParamStartDate)
              ) !== -1
            ) {
              return `Błąd parametrów usługi - pomiędzy parametrami jest przerwa (jeden kończy się ${format(
                new Date(currParam.endDate),
                "yyyy-MM-dd"
              )}, a kolejny rozpoczyna ${format(
                new Date(nextParamStartDate),
                "yyyy-MM-dd"
              )})`
            }
            // if check passed -> change currParam to next one
            else {
              currParam = params.find(
                (prm) =>
                  differenceInCalendarDays(
                    new Date(nextParamStartDate),
                    new Date(prm.startDate)
                  ) === 0
              )
            }
          }
        } else currParam = null
      } while (currParam)
    }

    // when any param without endDate -> its startDate must be bigger then biggest params endDate
    if (noEndDateInd !== -1) {
      const biggestEndDate = max(
        params
          .map((prm, i) => {
            if (i !== noEndDateInd) return new Date(prm.endDate)
            else return null
          })
          .filter((el) => el)
      )

      // if difference is 0 or negative -> return alert msg
      if (
        differenceInCalendarDays(
          new Date(params[noEndDateInd].startDate),
          new Date(biggestEndDate)
        ) < 1
      )
        return "Parametr bez daty zakończenia musi rozpoczynać się po parametrze z najpóżniejszą datą zakończenia"
    }

    // return null if all test passed
    return null
  } catch (err) {
    console.log(err)
    return "Niespodziewany błąd sprawdzania poprawności danych"
  }
}

export default paramsValidation
