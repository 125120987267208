import React, { useContext } from "react"
import {
  Table,
  Container,
  Button,
  Row,
  Col,
  Badge,
  Alert,
} from "react-bootstrap"
import { ImNewTab } from "react-icons/im"

import CustomersDetailsEditGeneralModal from "../Edit/modals/CustomersDetailsEditGeneralModal"
import { CustomerCtx } from "../Fetch"

import CustomerDetailsHistoryModal from "../modals/CustomerDetailsHistoryModal"
import CustomerDetailsAddBuyer from "../modals/CustomerDetailsAddBuyer"

const CustomersDetailsGeneralTab = () => {
  const { customer, setModalData, user, refresh } = useContext(CustomerCtx)

  const mainAddress = customer.addresses.find(
    (address) => address.type === "główny" && address.state === "aktywny"
  ) || {
    name: "brak adresu",
    street: "brak adresu",
    city: "brak adresu",
    postal: "brak",
    notes: "brak adresu",
  }

  const mainContactTable = () => {
    let mainContact
    const mainContactsArr = customer.contacts.filter(
      (contact) => contact.type === "główny" && contact.state === "aktywny"
    )

    if (mainContactsArr.length === 0)
      return (
        <Table>
          <thead>
            <tr>
              <th>Główny kontakt </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Alert variant="warning">
                  Niestety nie znalazłem głównego kontaktu. To raczej
                  niepożądana sytuacja, w miarę możliwości zaktualizuj dane
                  kontaktowe klienta.
                </Alert>
              </td>
            </tr>
          </tbody>
        </Table>
      )
    else if (mainContactsArr.length > 1)
      return (
        <Table>
          <thead>
            <tr>
              <th>Główny kontakt </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Alert variant="danger">
                  Uwaga! Wykryłem wiele aktywnych kontaktów typu "głowny".
                  Niestety tak nie może być, przejdź proszę do karty kontakty i
                  popraw to. Z góry dzięki!{" "}
                </Alert>
              </td>
            </tr>
          </tbody>
        </Table>
      )
    else mainContact = mainContactsArr[0]

    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="3">
              <Row>
                <Col xs="7">
                  <Row className="justify-content-end">Główny kontakt</Row>
                </Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Imię i nazwisko</td>
            <td className="fixed-values-table">Mail</td>
            <td className="fixed-values-table">Telefon</td>
          </tr>
          <tr>
            <td>{mainContact.name}</td>
            <td>{mainContact.mail}</td>
            <td>{mainContact.phone}</td>
          </tr>
          <tr style={{ height: "48px" }}>
            <td className="fixed-values-table" colSpan="3">
              Notatki
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              {mainContact?.note || "Brak notatki dla głównego kontaktu"}
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const renderMainNote = () => (
    <Table bordered>
      <thead>
        <tr>
          <th>
            <Row>
              <Col xs="7">
                <Row className="justify-content-end">Główna notatka</Row>
              </Col>
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="keep-br">
            {customer.notes.find(
              (note) => note.type === "główna" && note.state === "aktywna"
            )?.body || <p>Brak głównej notatki</p>}
          </td>
        </tr>
      </tbody>
    </Table>
  )

  const renderParams = () => {
    const renderDebtColCat = () => {
      if (
        customer.debtCollectionCat === "zwykły" ||
        !customer.debtCollectionCat
      )
        return <td>zwykły</td>
      else if (customer.debtCollectionCat === "czerwony")
        return <td className="bg-danger">czerwony</td>
      else if (customer.debtCollectionCat === "zielony")
        return <td className="bg-success">zielony</td>
    }

    return (
      <Table>
        <thead>
          <tr>
            <th colSpan="4">Parametry</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">Oddziały:</td>
            <td>{customer.branches.join(", ")}</td>
            <td className="fixed-values-table">Kategoria windykacyjna:</td>
            <td>{renderDebtColCat()}</td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th colSpan="4">Pozostałe parametry:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4">
              <Alert variant="secondary">
                Tymczasowy sposób wyświatlania - do dopracowania po określeniu w
                miarę stałej listy parametrów klienta
              </Alert>
              <h5>
                <Row>
                  {customer.params &&
                    Object.keys(customer.params).map((param) => (
                      <Badge key={`param-${param}`}>
                        {param}: {customer.params[param]}
                      </Badge>
                    ))}
                </Row>
              </h5>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }
  const renderBuyerRow = () => {
    if (customer.buyer)
      return (
        <tr>
          <td className="fixed-values-table">Nabywca</td>
          <td colSpan="4" className="bg-warningLight">
            <a
              href={`/CustomersDetails/${customer.buyer._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {customer.buyer.shortName || customer.buyer.name} <ImNewTab />
            </a>
          </td>
        </tr>
      )
    else return null
  }

  // *PRIVAT
  if (customer.isPrivat)
    return (
      <Container className="my-2">
        <Table bordered>
          {renderHeader(true, customer, setModalData, refresh, user)}

          <tbody>
            {renderBuyerRow()}
            <tr>
              <td className="fixed-values-table">Imię</td>
              <td className="fixed-values-table">Nazwisko</td>
              <td className="fixed-values-table">PESEL</td>
              <td className="fixed-values-table">Płatnik VAT?</td>
              <td className="fixed-values-table">Rolnik?</td>
            </tr>
            <tr>
              <td>{customer.name}</td>
              <td>{customer.surname}</td>
              <td>{customer.pesel}</td>
              <td>
                {customer.isVatPayer
                  ? `Tak, NIP: ${customer.vatNumber}`
                  : "Nie"}
              </td>
              <td>{customer.isFarmer ? "Tak" : "Nie"}</td>
            </tr>

            <tr>
              <td colSpan="5" className="fixed-values-table">
                Adres zameldowania (ulica, nr posesji, nr mieszkania)
              </td>
            </tr>
            <tr>
              <td colSpan="5">{mainAddress.street}</td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Miasto
              </td>
              <td className="fixed-values-table" colSpan="2">
                Kod pocztowy
              </td>
            </tr>
            <tr>
              <td colSpan="3">{mainAddress.city}</td>
              <td colSpan="2">{mainAddress.postal}</td>
            </tr>
          </tbody>
        </Table>
        {renderParams()}
        {renderMainNote()}
        {mainContactTable()}
      </Container>
    )
  // *BUSINESS
  else if (!customer.isPrivat)
    return (
      <Container className="my-2">
        <Table bordered>
          {renderHeader(false, customer, setModalData, refresh, user)}

          <tbody>
            {renderBuyerRow()}
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Nazwa
              </td>
              <td className="fixed-values-table">NIP</td>
              <td className="fixed-values-table">Rolnik?</td>
            </tr>
            <tr>
              <td colSpan="2">{customer.name}</td>
              <td>
                {customer.vatNumber}
                {customer.isVatPayer ? null : " (nie jest płatnikiem VAT)"}
              </td>
              <td>{customer.isFarmer ? "Tak" : "Nie"}</td>
            </tr>

            <tr>
              <td className="fixed-values-table" colSpan="2">
                Nazwa skrócona
              </td>
              <td className="fixed-values-table">Nr. rejestrowy </td>
              <td className="fixed-values-table">REGON </td>
            </tr>
            <tr>
              <td colSpan="2">{customer.shortName}</td>
              <td>{customer.regNumber}</td>
              <td>{customer.regon}</td>
            </tr>

            <tr>
              <td colSpan="4" className="fixed-values-table">
                Adres rejestrowy (ulica, nr posesji, nr mieszkania)
              </td>
            </tr>
            <tr>
              <td colSpan="4">{mainAddress.street}</td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="2">
                Miasto
              </td>
              <td className="fixed-values-table" colSpan="2">
                Kod pocztowy
              </td>
            </tr>
            <tr>
              <td colSpan="2">{mainAddress.city}</td>
              <td colSpan="2">{mainAddress.postal}</td>
            </tr>
          </tbody>
        </Table>
        {renderParams()}
        {renderMainNote()}
        {mainContactTable()}
      </Container>
    )
  else
    return setModalData({
      show: true,
      type: "alert",
      body: `Błąd danych, wyślij mi maila z informacją (najlepiej przekopiuj ;) ): błąd właściwości 'isPrivat' u klienta ${customer.id}`,
    })
}

const renderHeader = (isPrivat, customer, setModalData, refresh, user) => {
  return (
    <thead>
      <tr>
        <th colSpan={isPrivat ? "5" : "4"}>
          <Row>
            <Col xs="2">
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Wybierz nabywcę",
                    xl: true,
                    body: (
                      <CustomerDetailsAddBuyer
                        customer={customer}
                        setModalData={setModalData}
                        refresh={refresh}
                      />
                    ),
                  })
                }
              >
                Przypisz nabywcę
              </Button>
            </Col>
            <Col xs="6">
              <Row className="justify-content-end">
                Dane klienta -{" "}
                {isPrivat ? (
                  <b>osoba fizyczna bez działalności</b>
                ) : (
                  <b>os. fiz. z działalnością/firma/organizacja itd</b>
                )}
              </Row>
            </Col>
            <Col xs="2">
              <Row className="justify-content-end">
                <Button
                  variant="info"
                  size="sm"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: "Historia klienta",
                      body: (
                        <CustomerDetailsHistoryModal
                          historyArr={customer.history}
                        />
                      ),
                    })
                  }
                >
                  Historia
                </Button>
              </Row>
            </Col>
            {customer.state === "aktywny" ? (
              <Col xs="2">
                <Row className="justify-content-end">
                  <Button
                    variant="secondary"
                    size="sm"
                    className="mr-3"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "Edycja danych klienta",
                        hideFooter: true,
                        xl: true,
                        body: (
                          <CustomersDetailsEditGeneralModal
                            user={user}
                            customer={customer}
                            isNew={false}
                            setModalData={setModalData}
                            refresh={refresh}
                          />
                        ),
                      })
                    }
                  >
                    Edytuj
                  </Button>
                </Row>
              </Col>
            ) : null}
          </Row>
        </th>
      </tr>
    </thead>
  )
}

export default CustomersDetailsGeneralTab
