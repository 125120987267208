import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      const now = new Date()
      return (
        <>
          <h2>Błąd programu</h2>
          <h4>{this.state.error && this.state.error.toString()}</h4>
          <br />
          <br />
          <h5 className="bg-warningLight">
            Jeśli powyższy komunikat nie pomógł Ci w samodzielnym rozwiązaniu
            problemu to{" "}
            <u>
              zrób zrzut ekranu i prześlij mi maila lub dodaj zgłoszenie w Jirze
              (koniecznie uwzględnij <b>czynności</b>, które spowodowały
              wystąpienie błędu)
            </u>
          </h5>
          <br />
          <h6>Dane techniczne (ważne dla powtórzenia błędu)</h6>
          <p>
            {now.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </p>
        </>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
