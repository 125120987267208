import React, { useContext } from "react"
import {
  Container,
  Table,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap"

import { dateParse, nF } from "../../utils/formats"

import EmployeesDetailsEditContract from "./Edit/Contract"
import EmployeesDetailsEditNote from "./Edit/Note"
import { contractSubmit, noteSubmit } from "./Edit/submit"
import { EmployeesDetailsCtx } from "./Fetch"
import EmployeesDetailsFilesModal from "./utils/FilesModal"

const contractDetailsModal = (
  contract,
  setModalData,
  user,
  configs,
  employee,
  refresh
) => {
  //show table with bonus only if anything is in netBonus. Additional conditions added "just to be sure"
  const showBonus =
    contract.netBonus &&
    contract.netBonus !== "" &&
    contract.netBonus !== "0" &&
    contract.netBonus !== 0
      ? true
      : false

  const renderChangeState = () => {
    const contractStates = ["aktywna", "ustalenia", "archiwum"]

    return (
      <DropdownButton variant="warning" title="Zmień stan">
        {contractStates.map((state) => {
          if (contract.state !== state)
            return (
              <Dropdown.Item
                key={`changeState-dropdown-${state}`}
                onClick={() =>
                  contractSubmit(
                    employee.contracts.findIndex(
                      (contr) => contr._id === contract._id
                    ),
                    {
                      ...contract,
                      state: state,
                      // keeping state before archiving to show in archived table (better UX) and return to proper state if needed
                      preArchState:
                        state === "archiwum" ? contract.state : null,
                    },
                    employee,
                    { state: true },
                    setModalData,
                    refresh
                  )
                }
              >
                {state}
              </Dropdown.Item>
            )
          else return null
        })}
      </DropdownButton>
    )
  }

  const renderContractDetailsTable = () => {
    if (contract.isHourly)
      return (
        <span>
          <Table bordered>
            <tbody>
              <tr>
                <td className="fixed-values-table"></td>
                <td className="fixed-values-table">Podstawowa brutto</td>
                <td className="fixed-values-table">Netto/godz</td>
                <td className="fixed-values-table">Koszt pracodawcy/godz</td>
              </tr>
              <tr>
                <td className="fixed-values-table">Stawka</td>
                <td>{nF(contract.grossSalary)}</td>
                <td>{nF(contract.netSalary)}</td>
                <td>{nF(contract.employerCost)}</td>
              </tr>
            </tbody>
          </Table>
          {showBonus ? (
            <Table>
              <thead>
                <tr>
                  <th colSpan="3">Bonusy miesięcznie (nagrody/dodatki)</th>
                </tr>
              </thead>
              <tbody>
                <tr className="fixed-values-table">
                  <td>Netto</td>
                  <td>Brutto</td>
                  <td>Koszt</td>
                </tr>
                <tr>
                  <td>{nF(contract.netBonus)}</td>
                  <td>{nF(contract.grossBonus)}</td>
                  <td>{nF(contract.bonusEmployerCost)}</td>
                </tr>
                <tr>
                  <td className="fixed-values-table">Notatka</td>
                  <td colspan="2">{contract.bonusNote}</td>
                </tr>
              </tbody>
            </Table>
          ) : null}
        </span>
      )
    else
      return (
        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table"></td>
              <td className="fixed-values-table">Netto</td>
              <td className="fixed-values-table">Brutto</td>
              <td className="fixed-values-table">Koszt</td>
              {contract.motivation ? (
                <td className="fixed-values-table">Koszt z mtv</td>
              ) : (
                <td className="fixed-values-table">Motivation?</td>
              )}
            </tr>
            <tr>
              <td className="fixed-values-table">Podstawa</td>
              <td>{nF(contract.netSalary)}</td>
              <td>{nF(contract.grossSalary)}</td>
              <td>{nF(contract.employerCost)}</td>
              {contract.motivation ? (
                <td>{nF(contract.employerCost * 1.1)}</td>
              ) : (
                <td>Brak</td>
              )}
            </tr>
            {showBonus ? (
              <>
                <tr>
                  <td className="fixed-values-table">Nagrody</td>
                  <td>{nF(contract.netBonus)}</td>
                  <td>{nF(contract.grossBonus)}</td>
                  <td>{nF(contract.bonusEmployerCost)}</td>
                  <td className="fixed-values-table">Notatka do nagród</td>
                </tr>
                <tr>
                  <td className="fixed-values-table">Suma</td>
                  <td>{nF(contract.netSalary + contract.netBonus)}</td>
                  <td>{nF(contract.grossSalary + contract.grossBonus)}</td>
                  <td>
                    {nF(contract.employerCost + contract.bonusEmployerCost)}
                  </td>
                  <td>{contract.bonusNote}</td>
                </tr>
              </>
            ) : null}
          </tbody>
        </Table>
      )
  }
  return (
    <Container>
      <Row className="mb-1 mr-3 justify-content-between">
        <Button onClick={() => setModalData({ show: false })} className="ml-3">
          Zamknij
        </Button>

        <Row className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() =>
              setModalData(
                historyModal(contract.history, `Historia umowy`, () =>
                  setModalData({
                    show: true,
                    type: "info",
                    body: contractDetailsModal(
                      contract,
                      setModalData,
                      user,
                      configs,
                      employee
                    ),
                    xl: true,
                  })
                )
              )
            }
          >
            Historia
          </Button>
        </Row>

        {user.type.board &&
        employee.state !== "archiwum" &&
        contract.state !== "archiwum"
          ? renderChangeState()
          : null}

        <Row className="justify-content-end">
          {/* board always can edit, no-board can edit if .perm.employees.e && superior of employee && contract.state==="ustalenia" */}
          {(contract.state !== "archiwum" &&
            employee.state !== "archiwum" &&
            user.type.board) ||
          (user.perm.employees.e &&
            employee.superior.includes(user._id) &&
            contract.state === "ustalenia") ? (
            <Button
              variant="outline-secondary"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: `Edycja umowy pracownika ${employee.fullName} ("${contract.state}")`,
                  xl: true,
                  hideFooter: true,
                  body: (
                    <EmployeesDetailsEditContract
                      configs={configs}
                      contractInd={employee.contracts.findIndex(
                        (contr) => contr._id === contract._id
                      )}
                      user={user}
                      setModalData={setModalData}
                    />
                  ),
                })
              }
            >
              Edytuj
            </Button>
          ) : null}

          {/* only board can change state of contract, and only if employee is active */}
          {user.type.board &&
          employee.state !== "archiwum" &&
          contract.state === "archiwum" ? (
            <Button
              variant="warning"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "confirm",
                  header: "Przywrócić umowę?",
                  body: "Przywrócenie umowy spowoduje zmianę jej stanu na sprzed archiwizacji, lub 'ustalenia'. Wykonać?",
                  func: () =>
                    contractSubmit(
                      employee.contracts.findIndex(
                        (contr) => contr._id === contract._id
                      ),
                      {
                        ...contract,
                        state: contract.preArchState || "ustalenia",
                      },
                      employee,
                      { state: true },
                      setModalData,
                      refresh
                    ),
                })
              }
            >
              Przywróć
            </Button>
          ) : null}
        </Row>
      </Row>
      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Rodzaj umowy</td>
            <td className="fixed-values-table">Firma</td>

            <td className="fixed-values-table">
              {contract.state === "ustalenia"
                ? "Ustalona data zawarcia"
                : "Data zawarcia"}
            </td>
            <td className="fixed-values-table">
              {contract.state === "ustalenia"
                ? "Data ustaleń"
                : "Data ostatniej zmiany"}
            </td>
            <td className="fixed-values-table">Data zakończenia</td>
          </tr>
          <tr>
            <td>{contract.mainType}</td>
            <td>{contract.company}</td>

            <td>{dateParse(contract.conclusionDate, false)}</td>
            <td>
              {dateParse(
                contract.modificationDate || contract.conclusionDate,
                false
              )}
            </td>
            <td>
              {contract.indefiniteTime ? (
                <p>nieokr.</p>
              ) : (
                dateParse(contract.endDate, false)
              )}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Stanowisko</td>
            <td className="fixed-values-table" colSpan="5">
              Notatka do umowy
            </td>
          </tr>
          <tr>
            <td>{contract.position}</td>
            <td colSpan="5">{contract.note}</td>
          </tr>
        </tbody>
      </Table>
      {renderContractDetailsTable()}
      {contract.burdens?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th colSpan="5">Dodatkowe obciążenia</th>
            </tr>
          </thead>
          <tbody>
            <tr key={`burden-headers`} className="fixed-values-table">
              <td>Nazwa</td>
              <td>Koszt pracownika</td>
              <td>Koszt pracodawcy</td>
              <td>Suma</td>
              <td>Notatka</td>
            </tr>
            {contract.burdens.map((burden) => {
              return (
                <tr key={`burden-row-${burden._id}`}>
                  <td>{burden.name}</td>
                  <td>{nF(burden.employeeCost)}</td>
                  <td>{nF(burden.employerCost)}</td>
                  <td>
                    {nF(
                      (Number.parseFloat(burden.employeeCost) || 0) +
                        (Number.parseFloat(burden.employerCost) || 0)
                    )}
                  </td>
                  <td>{burden.note}</td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className="fixed-values-table sum-row">
              <td>Suma</td>
              <td>
                {nF(
                  contract.burdens.reduce((acc, curr) => {
                    if (isNaN(Number.parseFloat(curr.employeeCost))) return acc
                    else return acc + Number.parseFloat(curr.employeeCost)
                  }, 0)
                )}
              </td>
              <td>
                {nF(
                  contract.burdens.reduce((acc, curr) => {
                    if (isNaN(Number.parseFloat(curr.employerCost))) return acc
                    else return acc + Number.parseFloat(curr.employerCost)
                  }, 0)
                )}
              </td>
              <td>
                {nF(
                  contract.burdens.reduce((acc, curr) => {
                    if (isNaN(Number.parseFloat(curr.employerCost))) return acc
                    else return acc + Number.parseFloat(curr.employerCost)
                  }, 0) +
                    contract.burdens.reduce((acc, curr) => {
                      if (isNaN(Number.parseFloat(curr.employeeCost)))
                        return acc
                      else return acc + Number.parseFloat(curr.employeeCost)
                    }, 0)
                )}
              </td>
              <td />
            </tr>
          </tfoot>
        </Table>
      ) : null}
      <EmployeesDetailsFilesModal entity={contract} entityType="contracts" />
    </Container>
  )
}

const historyModal = (history, title, goBack) => {
  if (history.length === 0)
    return {
      show: true,
      type: "info",
      header: title || "Historia wpisu",
      xl: true,
      body: (
        <Container>
          <Button onClick={() => goBack()} className="mb-1">
            Wróć
          </Button>
          <p>Brak danych - historia pusta</p>,
        </Container>
      ),
    }
  else {
    // clone array to prevent changing original object:
    const historySorted = [...history]

    historySorted.sort(
      (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
    )
    return {
      show: true,
      type: "info",
      header: title || "Historia wpisu",
      xl: true,
      body: (
        <Container>
          <Button onClick={() => goBack()} className="mb-1">
            Wróć
          </Button>
          <Table>
            <thead>
              <tr>
                <th colSpan="4">Lista modyfikacji</th>
              </tr>
              <tr>
                <th>Rodzaj</th>
                <th>Użytkownik</th>
                <th>Data</th>
                <th>Opis</th>
              </tr>
            </thead>
            <tbody>
              {historySorted.map((entry, i) => {
                return (
                  <tr key={`history-row-${entry.createdAt}-${i}`}>
                    <td>{entry.type}</td>
                    <td>{entry.user.fullName}</td>
                    <td>{dateParse(entry.createdAt)}</td>
                    <td>
                      <p className="keep-br">
                        {entry.beforeChange || entry.description}
                      </p>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Container>
      ),
    }
  }
}

const NoteDetailsModal = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)

  const note = ctx.employee.notes[props.noteInd]

  return (
    <Container>
      <Row className="mb-1">
        <Col xs="4">
          <Button onClick={() => ctx.setModalData({ show: false })}>
            Zamknij
          </Button>
        </Col>
        <Col xs="4">
          <Row className="justify-content-center">
            <Button
              variant="outline-primary"
              onClick={() =>
                ctx.setModalData(
                  historyModal(note.history, "Historia notatki", () =>
                    ctx.setModalData({
                      show: true,
                      type: "info",
                      body: <NoteDetailsModal noteInd={props.noteInd} />,
                      xl: true,
                    })
                  )
                )
              }
            >
              Historia
            </Button>
          </Row>
        </Col>
        <Col xs="4">
          <Row className="justify-content-end">
            {note.state === "aktywna" &&
            (ctx.user.type.board ||
              (ctx.user.perm.employees.e &&
                ctx.employee.superior.includes(ctx.user._id) &&
                ctx.employee.state !== "archiwum")) ? (
              <Button
                id="editNote"
                className="mr-3"
                variant="outline-secondary"
                onClick={() =>
                  ctx.setModalData({
                    show: true,
                    type: "info",
                    header: `Edycja notatki pracownika ${ctx.employee.fullName}`,
                    hideFooter: true,
                    body: <EmployeesDetailsEditNote noteInd={props.noteInd} />,
                    xl: true,
                  })
                }
              >
                Edytuj
              </Button>
            ) : null}
            {note.state === "archiwum" &&
            (ctx.user.type.board ||
              (ctx.user.perm.employees.e &&
                ctx.employee.superior.includes(ctx.user._id) &&
                ctx.employee.state !== "archiwum")) ? (
              <Button
                variant="warning"
                className="mr-3"
                onClick={() =>
                  noteSubmit(
                    props.noteInd,
                    { ...ctx.employee.notes[props.noteInd], state: "aktywna" },
                    ctx.employee,
                    { state: true },
                    ctx.setModalData
                  )
                }
              >
                Przywróć
              </Button>
            ) : null}
          </Row>
        </Col>
      </Row>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Rodzaj</td>
            <td className="fixed-values-table">Utworzył:</td>
            <td className="fixed-values-table">Ostatnio modyfikował:</td>
            <td className="fixed-values-table">Data utworzenia</td>
            <td className="fixed-values-table">Data modyfikacji</td>
          </tr>
          <tr>
            <td>{note.type}</td>
            <td>{note.history[note.history.length - 1].user.fullName}</td>
            <td>{note.history[0].user.fullName}</td>
            <td>{dateParse(note.history[0].createdAt)}</td>
            <td>
              {dateParse(
                note.history.reduce((acc, curr) => {
                  if (Date.parse(acc) < Date.parse(curr.createdAt))
                    return new Date(curr.createdAt)
                  else return acc
                }, new Date("1990-01-01"))
              )}
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="5">
              Treść
            </td>
          </tr>
          <tr>
            <td colSpan="4">{note.body}</td>
          </tr>
        </tbody>
      </Table>
      <EmployeesDetailsFilesModal entity={note} entityType="notes" />
    </Container>
  )
}

const archivedNotesModal = (notes, setModalData, employee) => {
  if (notes.length === 0)
    return {
      show: true,
      type: "info",
      header: "Notatki zarchiwizowane",

      body: "Brak zarchiwizowanych notatek",
    }
  else
    return {
      show: true,
      type: "info",
      header: "Notatki zarchiwizowane",
      xl: true,
      body: (
        <Table>
          <thead>
            <tr>
              <th className="fixed-values-table">Rodzaj</th>
              <th className="fixed-values-table">Dodał</th>
              <th className="fixed-values-table">Data archiwizacji</th>
              <th className="fixed-values-table">Treść</th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note) => {
              return (
                <tr
                  className="clickable"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: (
                        <p>
                          Szczegóły notatki <b>archiwalnej</b>
                        </p>
                      ),
                      body: (
                        <NoteDetailsModal
                          noteInd={employee.notes.findIndex(
                            (el) => el._id === note._id
                          )}
                        />
                      ),
                    })
                  }
                  key={`note-row-${note._id}`}
                >
                  <td>{note.type}</td>
                  <td>{note.history[0].user.fullName}</td>
                  <td>
                    {dateParse(note.history[note.history.length - 1].createdAt)}
                  </td>
                  <td>{note.body}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      ),
    }
}

export {
  contractDetailsModal,
  NoteDetailsModal,
  archivedNotesModal,
  historyModal,
}
