import React from "react"
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { generalSubmit } from "./Edit/submit"

const EmployeeAdministration = ({
  employee,
  setModalData,
  user,
  configs,
  refresh,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: employee,
  })
  const watchBranch = watch("branch")

  const onSubmit = async (formData) =>
    generalSubmit(formData, dirtyFields, setModalData, refresh, true)

  const renderPermTable = () => {
    return (
      <Container>
        <Row>
          <label>
            Hasło <i>(wypełnij jeśli chcesz zmienić, inaczej zostaw puste)</i>
            <br />
            <input type="text" {...register("pass")} />
          </label>
        </Row>
        <Row>
          <Col>
            <table>
              <thead>
                <tr>
                  <th>Uprawnienie</th>
                  <th>r</th>
                  <th>w</th>
                  <th>e</th>
                  <th>d</th>
                </tr>
              </thead>
              <tbody>
                {configs.perm.map((permission) => {
                  return (
                    <tr key={`${permission}-row`}>
                      <td>{permission}</td>
                      <td>
                        <input
                          type="checkbox"
                          key={`perm.${permission}.r`}
                          {...register(`perm.${permission}.r`)}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          {...register(`perm.${permission}.w`)}
                          key={`perm.${permission}.w`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          {...register(`perm.${permission}.e`)}
                          key={`perm.${permission}.e`}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          {...register(`perm.${permission}.d`)}
                          key={`perm.${permission}.d`}
                        />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td>canAddBoardTodos</td>
                  <td>
                    <input type="checkbox" {...register("canAddBoardTodos")} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Uprawnienie 'bool'</th>
                  <th>?</th>
                </tr>
              </thead>
              <tbody>
                {configs.boolPerm.map((perm) => (
                  <tr>
                    <td>{perm}</td>
                    <td>
                      <input
                        type="checkbox"
                        {...register(`perm.${perm}`)}
                        key={`perm.${perm}`}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Row>
        <Button type="submit" className="ml-auto mr-3">
          Zapisz
        </Button>
      </Row>
      <Row>
        <Col>
          id
          <br />
          <Container>
            <label>
              Imię
              <br />
              <input type="text" {...register("firstName")} />
            </label>
            <br />
            <label>
              Nazwisko
              <br />
              <input type="text" {...register("lastName")} />
            </label>
            <br />
            <label>
              Nazwa użytkownika:
              <br />
              <input type="text" {...register("name")} />
            </label>
            <br />
            <label className="not-required">
              Mail:
              <br />
              <input type="text" {...register("mail")} />
            </label>
            <br />
            <label>
              Oddział:
              <br />
              {user.allowedBranches.map((branch) => {
                return (
                  <label key={`${branch}-label`}>
                    <input type="checkbox" {...register(`branch.${branch}`)} />
                    {branch}
                    <br />
                  </label>
                )
              })}
            </label>
            <br />
            <label>
              Typ:
              <br />
              {configs.employeeTypes.map((type) => {
                return (
                  <label key={`employee-type-chckbx-${type}`}>
                    <input type="checkbox" {...register(`type.${type}`)} />
                    {type}
                  </label>
                )
              })}
            </label>
            <br />
            {watch("type.driver") && (
              <Form.Check
                {...register("largeJobCardFont")}
                type="checkbox"
                label="Powiększ czcionkę na kontrolce"
                id="largeJobCardFont"
              />
            )}
            <label>
              Stan:
              <select {...register("state")}>
                <option value="aktywny">aktywny</option>
                <option value="archiwum">archiwum</option>
              </select>
            </label>
            Domyślny oddział:
            <Form.Control
              {...register("defaultBranch")}
              as="select"
              type=""
              className=""
              autoComplete="chrome-off"
            >
              {Object.keys(getValues("branch"))
                .filter((key) => watchBranch[key])
                .map((branch) => (
                  <option
                    value={branch}
                    key={`default-branches-select-option-${branch}`}
                  >
                    {branch}
                  </option>
                ))}
            </Form.Control>
            Configs:
            {watch("type.user") && (
              <>
                <Form.Check
                  {...register("configs.staticBackdrop")}
                  type="checkbox"
                  label="staticBackdrop"
                  value=""
                  id="staticBackdrop "
                />
                <Form.Check
                  {...register("configs.showDesktop")}
                  type="checkbox"
                  label="desktop?"
                  value=""
                  id="showDesktop"
                />
                <Form.Check
                  {...register("isFemale")}
                  type="checkbox"
                  label="kobieta?"
                  value=""
                  id="isFemale"
                />
              </>
            )}
            {watch("type.driver") && (
              <Row>
                <Form.Label>
                  Kolor markera:
                  <Form.Control
                    {...register("markerColor")}
                    as="input"
                    type=""
                    className=""
                    autoComplete="chrome-off"
                  />
                </Form.Label>
              </Row>
            )}
            Opis:
            <Form.Control
              {...register("desc")}
              as="textarea"
              className=""
              autoComplete="chrome-off"
            />
          </Container>
        </Col>
        <Col style={{ display: watch("type.user") ? "block" : "none" }}>
          {watch("type.user") ? renderPermTable() : null}
        </Col>
      </Row>
    </Form>
  )
}
export default EmployeeAdministration
