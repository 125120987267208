import { subDays, format, min, differenceInCalendarDays } from "date-fns"

const jobStateChange = async (
  newState,
  job,
  setModalData,
  refresh,
  location
) => {
  try {
    const body = {
      job: {
        ...job,
        location: location,
        state: newState,
      },
      dirtyFields: { state: true },
      historyLength: job.history.length,
    }
    delete body.job.history
    const res = await fetch("/jobs/edit", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "alert",
        body: "Brak uprawnień",
      })
    if (res.status === 409)
      return setModalData({
        show: true,
        type: "alert",
        body: "Niestety ktoś właśnie edytował to zadanie. Musisz odświeżyć to okno, żeby pobrać najświeższe dane. UWAGA! Wprowadzone przez Ciebie zmiany zostaną utracone - upewnij się, że dasz radę je odtworzyć.",
      })
    if (res.status !== 200) {
      console.log(res)
      return setModalData({
        show: true,
        type: "alert",
        body: "Błąd komunikacji z serwerem",
      })
    }
    setModalData({
      show: true,
      type: "info",
      header: "Sukces",
      headerColor: "success",
      body: "Dane zadania zmienione",
      onHide: refresh,
    })
  } catch (err) {
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd komunikacji z serwerem",
    })
    console.log(err)
  }
}

const submitBatchJobsChange = async (formData, jobs, setModalData, refresh) => {
  try {
    // creating final object:
    let modifications = {
      state: { apply: false },
      driver: { apply: false },
      commentsAdd: { apply: false },
      commentsReplace: { apply: false },
      move: { apply: false },
      services: { apply: false },
    }
    if (formData.delete)
      modifications = { state: { apply: true, newValue: "usunięte" } }
    else {
      for (let key in formData.modifications) {
        modifications[key].apply =
          formData.modifications[key].apply === "true" ? true : false
        modifications[key].newValue =
          formData.modifications[key].apply === "true"
            ? formData.modifications[key].newValue
            : null
      }
    }

    // validate if any change applied:
    let changeArr = Object.keys(modifications).filter(
      (key) => modifications[key].apply
    )

    if (changeArr.length === 0) {
      return setModalData({
        show: true,
        type: "info",
        body: "Sprytnie ;). Doceniam dociekliwość, ale pozwolę sobie przerwać operację w tym miejscu - wygląda na to, że nie wybrano żadnej modyfikacji. Szkoda internetów na puste przebiegi...\nJeśli jednak jakaś modyfikacja była wybrana, to najprawdopodobniej wystąpił błąd -> proszę o informację mailową.",
      })
    }

    const body = {
      jobs: jobs.map((job) => {
        return { _id: job._id, historyLength: job.history.length }
      }),
      modifications: modifications,
    }

    const res = await fetch("/jobs/batchEdit", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })

    if (res.status !== 200)
      throw new Error(
        `Błąd ${res.status}: ${
          (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
        }`
      )
    return setModalData({
      show: true,
      type: "info",
      headerColor: "success",
      header: "Sukces",
      body: "Zmiany naniesione",
      onHide: refresh,
    })
  } catch (err) {
    setModalData({
      show: true,
      type: "alert",
      body: err.message || "Nieokreślony błąd działania programu",
    })
    console.log(err)
  }
}

const srvPriceChange = async (
  formData,
  srv,
  location,
  setModalData,
  refresh,
  setIsFetching
) => {
  try {
    if (formData.changeType === "withInv") {
      const makeInvBody = {
        customers: [
          {
            customer_id: location.customer._id,
            locations: [{ location: location, services: [srv] }],
          },
        ],
        invParams: {
          preventSettingInvoicedTo: false,
          endDate: format(
            subDays(new Date(formData.changeDate), 1),
            "yyyy-MM-dd"
          ),
          sellDate: format(new Date(), "yyyy-MM-dd"),
          invDate: format(new Date(), "yyyy-MM-dd"),
        },
        // add details to send @ notification about new invoice:
        sendMail: true,
        branch: location.branch,
        cause: "Zmiana ceny usługi",
      }

      // settle invoice start date and format it:
      makeInvBody.invParams.startDate = format(
        min(
          srv.params
            .map((prm) => {
              // get earliest not invoiced date from each param:

              // exclude fully invoiced params:
              if (
                prm.hasEndDate &&
                differenceInCalendarDays(
                  new Date(prm.invoicedTo),
                  new Date(prm.endDate)
                ) === 0
              )
                return null
              // when param not invoiced -> return invoicedTo or startDate
              else return new Date(prm.invoicedTo || prm.startDate)
            })
            .filter((el) => el)
        ),
        "yyyy-MM-dd"
      )

      const res = await fetch("/invoices/make", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(makeInvBody),
      })
      if (res.status !== 200)
        throw new Error(
          `${res.status}: ${
            (await res.text()) ||
            "błąd komunikacji z serwerem, FAKTURA NIE ZOSTAŁA WYGENEROWANA, ale cena usługi MOGŁA zostać zmieniona. OBOWIĄZKOWO ZGŁOŚ BŁĄD!"
          }`
        )
    }

    const changeSrvBody = {
      // send only new price to prevent overwriting changes made in first fetch (make invoice)
      data: { _id: srv._id, netPrice: formData.newPrice },
      dirtyFields: { netPrice: true },
    }

    const res = await fetch(
      `/locations/editEntity/${location._id}?isNew=false&entity=services`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(changeSrvBody),
      }
    )
    if (res.status !== 200)
      throw new Error(
        `${res.status}: ${
          (await res.text()) ||
          "błąd komunikacji z serwerem, CENA USŁUGI NIE ZOSTAŁĄ ZMIENIONA, ale faktura MOGŁA zostać wygenerowana. OBOWIĄZKOWO ZGŁOŚ BŁĄD!"
        }`
      )

    setModalData({
      show: true,
      type: "info",
      body: "zmiany zapisane",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setIsFetching(false)
    setModalData({
      show: true,
      type: "alert",
      header: "Bląd!",
      body: `Błąd działania programu: ${err.message || "nieokreślony błąd"}`,
    })
  }
}

const clearLoc = async (setModalData, loc_id) => {
  try {
    const res = await fetch("/locations/clearLocation", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ loc_id: loc_id }),
    })

    if (res.status !== 200) throw new Error(await res.text())
    else
      setModalData({
        show: true,
        type: "info",
        body: `usuniętych zadań: ${await res.text()}. PAMIĘTAJ O ODŚWIEŻENIU`,
      })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      header: "Bląd!",
      body: `Błąd działania programu: ${err.message || "nieokreślony błąd"}`,
    })
  }
}
const copyLoc = async (setModalData, loc_id, isDev) => {
  try {
    const res = await fetch("/locations/copyLocation", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ location_id: loc_id }),
    })

    if (res.status !== 200) throw new Error(await res.text())
    else {
      const resText = await res.text()

      window.open(
        isDev
          ? `http://localhost:3000/Locations/${resText}`
          : `https://cliperp.app/Locations/${resText}`,
        `loc-${resText}`
      )
    }
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      header: "Bląd!",
      body: `Błąd działania programu: ${err.message || "nieokreślony błąd"}`,
    })
  }
}

export {
  jobStateChange,
  submitBatchJobsChange,
  srvPriceChange,
  clearLoc,
  copyLoc,
}
