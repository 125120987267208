import {
  addMonths,
  differenceInCalendarDays,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns"
import { Badge, Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { BsBook } from "react-icons/bs"
import { EqpChangeReportManual } from "../../../doc/manual/sections/Reports"
import { createManualBtn } from "../../utils/buttons"

const EqpChangeReportForm = ({ isLoading, setFilters, user, setModalData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      branch: user.defaultBranch || user.allowedBranches[0],
      startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
      endDate: format(endOfMonth(new Date()), "yyyy-MM-dd"),
    },
  })

  const submit = (formData) => {
    setFilters(formData)
  }

  const handleMonthChange = (direction) => {
    const { startDate, endDate } = getValues()

    setValue(
      "startDate",
      format(
        startOfMonth(addMonths(new Date(startDate), direction)),
        "yyyy-MM-dd"
      )
    )
    setValue(
      "endDate",
      format(endOfMonth(addMonths(new Date(endDate), direction)), "yyyy-MM-dd")
    )
  }

  return (
    <Form inline onSubmit={handleSubmit(submit)}>
      oddział:
      <Form.Control
        {...register("branch")}
        as="select"
        type=""
        className="mr-5"
        autoComplete="chrome-off"
      >
        <option value="all">wszystkie</option>
        {user?.allowedBranches.map((branch, i) => (
          <option key={i} value={branch}>
            {branch}
          </option>
        ))}
      </Form.Control>
      kierowca:
      <Form.Control
        {...register("driver")}
        as="select"
        type=""
        className="mr-5"
        autoComplete="chrome-off"
      >
        <option value="all">wszyscy</option>
        <option value="none">brak</option>
        {user?.drivers.map((driver, i) => (
          <option key={i} value={driver._id}>
            {driver.fullName}
          </option>
        ))}
      </Form.Control>
      od:
      <Form.Control
        {...register("startDate")}
        as="input"
        type="date"
        className="mr-2"
        autoComplete="chrome-off"
      />
      do:
      <Form.Control
        {...register("endDate", {
          validate: {
            endDateAfterStartDate: (value) => {
              const startDate = getValues("startDate")
              return (
                new Date(value) >= new Date(startDate) ||
                "data 'od' musi być większa niż data 'do'"
              )
            },
            noMoreThenOneMonth: (value) => {
              const { startDate } = getValues()
              return (
                differenceInCalendarDays(
                  new Date(value),
                  new Date(startDate)
                ) <= 32 || "maksymalny zakres to 31 dni"
              )
            },
          },
        })}
        as="input"
        type="date"
        className="ml-2"
        autoComplete="chrome-off"
      />
      {errors.endDate && (
        <Badge pill variant="warning">
          {errors.endDate.message}
        </Badge>
      )}
      <Button onClick={() => handleMonthChange(-1)} className="ml-3">
        {"<"}
      </Button>
      <Button onClick={() => handleMonthChange(1)}>{">"}</Button>
      <Button type="submit" disabled={isLoading} className="ml-5">
        Pobierz
      </Button>
      <Button
        className="ml-auto"
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            header: "Podgląd instrukcji",
            xl: true,
            body: (
              <div>
                <p style={{ textAlign: "end" }}>
                  wyświetl instrukcję: {createManualBtn("eqpChangeReport")}
                </p>
                <EqpChangeReportManual />
              </div>
            ),
          })
        }
      >
        <BsBook />
      </Button>
    </Form>
  )
}
export { EqpChangeReportForm }
