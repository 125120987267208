/* 
input: array of transactions objects
output: array of products objects 

product obj:{
    names:["","",...] // array of names in case of changed names
'   _id:""
    netIncome: 0
    grossIncome: 0
    quantity: 0

    }

*/
const createSummary = async (transactions, setModalData) => {
  try {
    // create sumarry array, that will be populated with products, add transport as it is always shown:
    let summary = [
      {
        names: ["Transport"],
        _id: "transportMockId",
        quantity: 0,
        netIncome: 0,
        grossIncome: 0,
      },
    ]

    // start genereting report
    transactions.forEach((transaction, i) => {
      // check if transaction is finished:
      if (transaction.state === "zafakturowana") {
        transaction.orderDetails.prices.forEach((price) => {
          //check if price exist in summary arr:
          const summaryPriceInd = summary.findIndex(
            (summaryPrice) => summaryPrice._id === price._id
          )

          if (summaryPriceInd === -1) {
            // add price to summary array

            summary.push({
              names: [price.name],
              _id: price._id,
              quantity: price.quantity,
              netIncome: price.quantity * price.netPrice,
              grossIncome: price.quantity * price.grossPrice,
            })
          } else {
            // check name, if different -> push unique name to price.names array
            if (
              summary[summaryPriceInd].names.findIndex(
                (name) => name === price.name
              ) === -1
            ) {
              summary[summaryPriceInd].names.push(price.name)
            }
            // then increase quantity and incomes
            summary[summaryPriceInd].quantity += price.quantity
            summary[summaryPriceInd].netIncome +=
              price.quantity * price.netPrice
            summary[summaryPriceInd].grossIncome +=
              price.quantity * price.grossPrice
          }
        })

        // check if ourDelivery and increase 'Transport' object if so:
        if (transaction.realizationDetails.ourDelivery === "true") {
          summary[0].quantity++ // delivery always at first index in array
          summary[0].netIncome +=
            transaction.realizationDetails.deliveryDetails.netPrice
          summary[0].grossIncome +=
            transaction.realizationDetails.deliveryDetails.grossPrice
        }
      }
    })
    return summary
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Problem przy przetwarzaniu danych",
    })
  }
}

export { createSummary }
