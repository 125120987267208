const handleStateChange = async (
  consumptionId,
  newState,
  setModalData,
  redirectFunc = false // handles redirecting after fetching
) => {
  try {
    const res = await fetch(
      `/consumptions/stateChange/?_id=${consumptionId}&newState=${newState}`,
      {
        method: "PUT",
      }
    )

    if (res.status === 403)
      return setModalData({ show: true, type: "info", body: "Brak uprawnień" })
    else if (res.status !== 200) throw res
    else
      return setModalData({
        show: true,
        type: "info",
        body: `Zmieniono stan zużycia na ${newState}`,
        onHide: redirectFunc,
        id: "stateChanged",
      })
  } catch (err) {
    console.log(err)
    setModalData({ show: true, type: "alert", body: "Błąd pobierania danych" })
  }
}
export { handleStateChange }
