import { format, isValid } from "date-fns"
import _ from "lodash"
import { read, utils } from "xlsx"
import { prepareCustomers } from "./prepareCustomers"

const excelHandler = async (
  file,
  setFetchError,
  setCustomers,
  setIsLoading
) => {
  try {
    //TODO add checking if there are all columns I need, throw if not
    setIsLoading(true)

    const data = await file.arrayBuffer()
    const wb = read(data, { cellDates: true })

    let dataParsed = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]) // wb.SheetNames[0] is the name of the first sheet (wb.Sheets is an object with objects)

    const customers_ids = _.uniq(
      dataParsed.map((row) => row["Kod kontrahenta"])
    )

    const customersData = await fetchCustomers(customers_ids)

    // get lastAction and debtCollection contacts:
    const customers = prepareCustomers(customersData)

    const customersFullData = customers.map((cust) => {
      const paymentDocs = dataParsed.filter(
        (row) => row["Kod kontrahenta"] === cust._id
      )

      const deadline = getDeadline(paymentDocs)
      const debt = getAmount(paymentDocs)

      return {
        ...cust,
        paymentDocs: paymentDocs,
        deadline: deadline,
        debt: debt,
      }
    })

    setCustomers(customersFullData)

    setIsLoading(false)
  } catch (err) {
    console.log(err)
    setIsLoading(false)
    return setFetchError(err.message)
  }
}

const fetchCustomers = async (customers_ids) => {
  const res = await fetch(`/customers/getByIds`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ _ids: customers_ids }),
  })
  if (res.status !== 200)
    throw new Error(
      `Błąd pobierania danych klientów: ${res.status} - ${
        (await res.text()) || "nieokreślony błąd"
      }`
    )
  return await res.json()
}

const getDeadline = (paymentDocs) => {
  if (!paymentDocs?.length) return "bd"

  const earliestDate = _.minBy(paymentDocs, "Termin płatności")[
    "Termin płatności"
  ]

  if (isValid(new Date(earliestDate)))
    return format(new Date(earliestDate), "yyyy-MM-dd")
  else return "bd"
}

const getAmount = (paymentDocs) => {
  if (!paymentDocs?.length) return "bd"

  return _.round(
    _.sumBy(paymentDocs, (doc) => Number.parseFloat(doc["Wartość PLN"])),
    2
  )
}

export { excelHandler }
