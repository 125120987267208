import React, { useEffect, useState, useContext } from "react"

import UserContext from "../contexts/userContext"

import UsersTable from "./Table"
import EmployeeEdit from "./employeeEdit"
import { Redirect } from "react-router-dom"
import { Modal, Container, Button, Row } from "react-bootstrap"

const EmployeesManage = () => {
  const [noPerm, setNoPerm] = useState()
  const user = useContext(UserContext)

  const [employees, setEmployees] = useState()
  const [branches, setBranches] = useState()
  const [perm, setPerm] = useState()
  const [employeeTypes, setEmployeeTypes] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [choosenEmployee, setChoosenEmployee] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const handleDelete = async (_id) => {
    try {
      if (!window.confirm("Usunąć użytkownika?")) return

      const res = await fetch("/employees/remove", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
        body: JSON.stringify({ id: _id }),
      })

      if (res.status !== 200) {
        const resJSON = await res.json()
        throw resJSON?.msg ? resJSON.msg : res
      } else {
        window.location.reload()
      }
    } catch (err) {
      alert(err)
    }
  }

  const permBatchUpdate = async () => {
    try {
      const res = await fetch("/misc/permBatchUpdate")
      if (res.status !== 200) throw res
      else {
        const resJSON = await res.json()

        alert("done, updated: " + resJSON.counter)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        if (!user.type.admin) {
          alert("brak uprawnień")
          setNoPerm(true)
        }
        const res = await fetch("/employees/all?verbose=true", {
          method: "GET",
        })

        if (res.status === 200) {
          const resJSON = await res.json()

          setEmployees(resJSON)
        } else if (res.msg) {
          const resJSON = await res.json()
          throw resJSON
        } else throw res
      } catch (err) {
        alert(err)
      }
    }
    const fetchConfigs = async () => {
      try {
        const res = await fetch("/configs/branches,perm,employeeTypes", {
          method: "GET",
        })
        const resJSON = await res.json()

        if (res.status === 200) {
          setBranches(resJSON.branches)
          setPerm(resJSON.perm)
          setEmployeeTypes(resJSON.employeeTypes)
        } else if (res.msg) {
          const resJSON = await res.json()
          throw resJSON
        } else throw res
      } catch (err) {
        alert(err)
      }
    }
    Promise.all([fetchEmployees(), fetchConfigs()]).then(() =>
      setIsLoading(false)
    )
  }, [user.type.admin])

  const renderEmployees = () => {
    return (
      <div>
        <UsersTable
          users={employees}
          setChoosenEmployee={setChoosenEmployee}
          setShowModal={setShowModal}
        />
      </div>
    )
  }
  return (
    <Container>
      {noPerm ? <Redirect to="/" /> : null}
      <Row className="mt-2">
        <Button variant="outline-primary" onClick={() => permBatchUpdate()}>
          Aktualizuj uprawnienia
        </Button>
        <Button
          onClick={() => {
            setChoosenEmployee("new")
            setShowModal(true)
          }}
          className="ml-auto"
          variant="secondary"
        >
          Dodaj
        </Button>
      </Row>
      {isLoading ? <p>wczytuję....</p> : renderEmployees()}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        animation={false}
      >
        <EmployeeEdit
          branches={branches}
          employee={choosenEmployee}
          perm={perm}
          employeeTypes={employeeTypes}
          setChoosenEmployee={setChoosenEmployee}
          handleDelete={handleDelete}
          setShowModal={setShowModal}
        />
      </Modal>
    </Container>
  )
}
export default EmployeesManage
