import React from "react"
import { Table } from "react-bootstrap"
import { getEqpDesc } from "../../../utils/getEqpDesc"
import { renderInventoryCell } from "./renderInventoryCell"

const renderJobRow = (driver, job) => {
  return (
    <React.Fragment key={`locationJobs-job-${job._id}`}>
      <Table
        size="sm"
        // .negMarginBottom prevents thick line on conection of these two tables
        className="mb-0 jobCard negMarginBottom"
        style={{
          borderColor: "black",
          borderWidth: "1px",
          fontSize: driver.largeJobCardFont ? "1.5rem" : null,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "34%",
                fontSize: "",
              }}
              className="jobCard"
            >
              {renderJobType(job.type)}-{" "}
              {job.services.map((jobSrv) => jobSrv.serviceRef.name).join(", ")}
            </td>
            {job.comments ? (
              <td style={{ width: "33%" }}>{job.comments}</td>
            ) : null}
            {job.customHours ? (
              <td style={{ width: "33%" }}>
                <h4 className="bg-danger">
                  {job.startTime} - {job.endTime}
                </h4>
              </td>
            ) : null}
          </tr>
        </tbody>
      </Table>
      {/* list all services and equipment: */}
      <Table
        className="jobCard"
        size="sm"
        style={{
          fontSize: driver.largeJobCardFont ? "1.5rem" : null,
        }}
      >
        <tbody>
          {job.services.map((srv) => {
            try {
              if (srv.eqp.length)
                return srv.eqp.map((eqp) => {
                  return (
                    <tr key={`jobCardEqpRow-${srv._id}-${eqp._id}`}>
                      <td style={{ width: "auto" }}>
                        {getEqpDesc(eqp, srv, false, true)}
                      </td>
                      {eqp.ref.type === "Toaleta" &&
                      srv.serviceRef.chargeType !== "krótki termin" &&
                      srv.serviceRef.chargeType !== "ryczałt impreza" ? (
                        <td style={{ width: "55%" }}>
                          {renderInventoryCell(eqp, job.location, srv)}
                        </td>
                      ) : null}
                      <td style={{ width: "25%" }}>Uwagi:</td>
                    </tr>
                  )
                })
              else
                return (
                  <tr key={`jobCardEqpRow-${srv._id}`}>
                    <td>{srv.serviceRef.name} - brak sprzętu</td>
                    <td>Uwagi:</td>
                  </tr>
                )
            } catch (err) {
              console.log(err)
              console.log("job: ", job)
              console.log("srv: ", srv)
              err.message = job?.location?.name
                ? `(błąd dotyczy zadania dla lokalizacji ${job?.location?.name})${err.message}`
                : err.message
              throw err
            }
          })}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

const renderJobType = (jobType) => {
  switch (jobType) {
    case "dostarczenie":
      return (
        <h4>
          <b className="bg-danger">!!! Dostarczenie</b>
        </h4>
      )
    case "zabranie":
      return (
        <h4>
          <b className="bg-warning">!!! ZABRANIE</b>
        </h4>
      )
    case "serwis":
      return "serwis"

    default:
      break
  }
  return
}

export { renderJobRow }
