const checkMustHaveEqpJobs = (jobsArr) => {
  const resArr = jobsArr.filter((job) => {
    let isOk = true
    if (job.state !== "usunięte" && job.state !== "archiwum") {
      if (job.isFrozen) {
        job.srvFrozen.forEach((jobSrv) => {
          if (jobSrv.serviceRef.mustHaveEqp && !jobSrv.eqp.length) isOk = false
        })
      } else {
        job.services.forEach((jobSrv) => {
          if (jobSrv.serviceRef.mustHaveEqp && !jobSrv.eqp.length) isOk = false
        })
      }
    }
    if (isOk) return false
    else return true
  })

  return resArr
}

export { checkMustHaveEqpJobs }
