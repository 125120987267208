import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"

import ConsumptionsDetailsRender from "./Render"

const ConsumptionsDetailsFetch = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [consumption, setConsumption] = useState()
  const [allGoodsSums, setAllGoodsSums] = useState([]) // array of objects: [{goodName:"", type:"main/normal" quantity:1, unit:"" }, {...}]
  useEffect(() => {
    const fetchConsumption = async () => {
      try {
        const res = await fetch(
          `/consumptions/getById/?id=${props.match.params.id}`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        //create allGoodsSums array:
        //main goods:
        const goodsSumsArr = []
        resJSON.mainGoodsConsumptions.forEach((consumptionRow) => {
          consumptionRow.consumptions.forEach((goodConsumption) => {
            const goodInd = goodsSumsArr.findIndex(
              (good) => good._id === goodConsumption._id
            )

            //convert empty string to number:
            if (goodConsumption.quantity === "") goodConsumption.quantity = 0

            //if good not present in arr:
            if (goodInd === -1) {
              goodsSumsArr.push({
                _id: goodConsumption._id,
                name: goodConsumption.name,
                type: goodConsumption.type,
                quantity: goodConsumption.quantity,
                unit: goodConsumption.unit,
              })
            } else {
              goodsSumsArr[goodInd].quantity =
                Number.parseFloat(goodsSumsArr[goodInd].quantity) +
                Number.parseFloat(goodConsumption.quantity)
            }
          })
        })
        //add normal goods:
        resJSON.normalGoodsConsumptions.forEach((goodConsumption) =>
          goodsSumsArr.push({
            _id: goodConsumption._id,
            name: goodConsumption.name,
            type: goodConsumption.type,
            quantity:
              goodConsumption.quantity === ""
                ? 0
                : Number.parseFloat(goodConsumption.quantity),
            unit: goodConsumption.unit,
          })
        )
        setAllGoodsSums(goodsSumsArr)
        setConsumption(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchConsumption()
  }, [props.match])
  return (
    <Container fluid>
      {isLoading ? (
        <p>pobieram dane</p>
      ) : (
        <ConsumptionsDetailsRender
          consumption={consumption}
          setModalData={setModalData}
          allGoodsSums={allGoodsSums}
        />
      )}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default ConsumptionsDetailsFetch
