import React, { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  startOfTomorrow,
  format,
  max,
  addDays,
  differenceInCalendarDays,
} from "date-fns"
import { Row, Button, Form, Col, Alert, Badge } from "react-bootstrap"

import { LocationCtx } from "../Fetch"

import paramsValidation from "./validation"
import { createManualBtn } from "../../utils/buttons"

const LocationDetailsSrvAddParamModal = ({ srv }) => {
  const [validateErr, setValidateErr] = useState(null)
  // when any srv.param has no end date -> set "closePrevious" to true and show alert (bottom of code) about auto closing previous (when this has no endDate)
  const isOtherWithNoEndDate =
    srv.params.findIndex((param) => !param.hasEndDate) !== -1

  const { setModalData, location } = useContext(LocationCtx)
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      netPrice: srv.params[srv.params.length - 1].netPrice,
      grossPrice: srv.params[srv.params.length - 1].grossPrice,
      qty: srv.params[srv.params.length - 1].qty,
      // set start date to next date after last param endDate
      startDate: format(
        addDays(
          max(
            srv.params.map((prm) => {
              if (prm.hasEndDate) return new Date(prm.endDate)
              // when no end date return tomorrow to prevent date-fns crash
              else return startOfTomorrow()
            })
          ),
          1
        ),
        "yyyy-MM-dd"
      ),
      hasEndDate: false,
    },
  })

  // clear value of end date when hasEndDate is unchecked
  const watchHasEndDate = watch("hasEndDate")
  useEffect(() => {
    if (!watchHasEndDate) setValue("endDate", null)
  }, [watchHasEndDate, setValue])

  const submit = async (formData) => {
    try {
      // simulate after-updating params and run validate func (which returns alert msg or null if everything is ok):
      const paramsArr = [...srv.params, formData]

      const validateRes = await paramsValidation(paramsArr)
      if (validateRes) return setValidateErr(validateRes)
      else setValidateErr(null)

      const body = {
        param: { ...formData, startDate: `${formData.startDate}T12:00Z` },
        srv: srv,
        location_id: location._id,
      }

      // set T12:00 if there is end date:
      if (formData.hasEndDate) body.param.endDate = `${formData.endDate}T12:00Z`

      const res = await fetch("/locations/addSrvParam", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: await res.text(),
        })
      if (res.status === 409)
        return setModalData({
          show: true,
          type: "alert",
          body: "Ktoś w międzyczasie edytował usługę lub lokalizację, odśwież okno aby pobrać aktualne dane",
        })
      if (res.status !== 200) throw res

      return setModalData({
        show: true,
        type: "info",
        headerColor: "green",
        header: "Sukces",
        body: "Parametry usługi dodane",
        onHide: () => window.location.reload(),
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd programu. Zgłoszenie: add params for loc ${
          location._id
        }, srv ${srv._id}  ${new Date()}`,
      })
    }
  }

  if (srv.chargeType === "za serwis")
    return (
      <Alert variant="warning">
        Dla usług "za serwis" liczba zawsze musi wynosić 1. Szczegóły w
        instrukcji: {createManualBtn("perPcsSrv")}
      </Alert>
    )
  return (
    <Form onSubmit={handleSubmit(submit)}>
      {!location.driver && (
        <Badge variant="warning">Brak domyślnego kierowcy</Badge>
      )}
      liczba{" "}
      {errors.qty?.type === "min" && (
        <Badge pill variant="warning">
          Minimalna wartość to 0,01
        </Badge>
      )}
      {errors.qty?.type === "required" && (
        <Badge pill variant="warning">
          Pole wymagane
        </Badge>
      )}
      <Form.Control
        {...register("qty", { min: 0.01, required: true })}
        as="input"
        type="number"
        className=""
        autoComplete="chrome-off"
        step="any"
      />
      {srv.params.filter((prm) => !prm.hasEndDate).length > 0 ? (
        <Alert variant="danger">
          Usługa zawiera niezamknięte parametry, <b>najprawdopodobniej</b>{" "}
          musisz zamknąć poprzedni parametr przed dodaniem nowego
        </Alert>
      ) : null}
      <Row className="my-2">
        <Col>
          OD{" "}
          {errors.startDate && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("startDate", { required: true })}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col>
          <Form.Check
            {...register("hasEndDate", {
              validate: {
                onlyOneWithoutED: (val) => {
                  // there can be just one param without end date:
                  if (isOtherWithNoEndDate && val !== true)
                    return (
                      <Alert variant="warning">
                        Wykryłem inny parametr bez daty zakończnia. Wprowadź
                        datę "DO" tutaj albo edytuj tamten parametr -{" "}
                        <b>
                          nie może istnieć więcej niż jeden parametr bez daty
                          zakończenia
                        </b>
                      </Alert>
                    )
                  else return true
                },
                maxPeriodForFlatRate: (val) => {
                  if (
                    srv.chargeType === "ryczałt impreza" &&
                    differenceInCalendarDays(
                      new Date(watch("endDate")),
                      new Date("startDate")
                    ) > 32
                  )
                    return (
                      <Alert variant="warning">
                        Usługa "ryczałt impreza" może trwać maksymalnie 31 dni.
                      </Alert>
                    )
                },
              },
            })}
            type="checkbox"
            label="Data zakończenia:"
            id="hasEndDate"
          />
          {errors.endDate?.type === "required" && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("endDate", {
              required: watch("hasEndDate"),
              validate: (val) => {
                if (
                  differenceInCalendarDays(
                    new Date(val),
                    new Date(watch("startDate"))
                  ) >= 0 ||
                  !watch("hasEndDate")
                )
                  return true
                else return false
              },
            })}
            as="input"
            type="date"
            className=""
            disabled={!watch("hasEndDate")}
            autoComplete="chrome-off"
          />
        </Col>
        {errors.endDate?.type === "validate" && (
          <Badge pill variant="warning">
            Usługa nie może mieć ujemnej liczby dni
          </Badge>
        )}
      </Row>
      {errors.hasEndDate?.message}
      {validateErr && (
        <Alert variant="danger">
          <b>Wykryłem błędy w parametrach usługi:</b>
          <br />
          {validateErr}
          <br />
          <u>Jeśli nie możesz naprawić błędu ręcznie - zgłoś do mnie.</u>
        </Alert>
      )}
      <Row className="justify-content-between px-3">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button type="submit" variant="secondary">
          Dodaj
        </Button>
      </Row>
    </Form>
  )
}
export default LocationDetailsSrvAddParamModal
