import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import duplicatedNIPAlert from "../../pics/manual/duplicatedNIPAlert.png"
import customerBuyerBtn from "../../pics/manual/customerBuyerBtn.png"
import customerBuyerModal from "../../pics/manual/customerBuyerModal.png"
import customerEInvoice from "../../pics/manual/customerEInvoice.png"

const ManualCustomersSection = (props) => {
  //todo clipMENU import priv: full name as name, change whenever changing something in customer
  //todo type (klasyfikacja) explain
  if (!props.user.perm?.customers?.r) return null
  else
    return (
      <div>
        <hr />
        <h2>Klienci</h2>
        <Row>
          <Col>
            <h3>Wyszukiwarka</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            Korzystając z wyszukiwarki należy wpisać <u>jedną</u> w wymienionych
            niżej informacji.{" "}
            <b>
              Wyszukiwarka może nie zadziałać prawidłowo przy wpisaniu kilku
              fraz
            </b>{" "}
            <Button
              onClick={() =>
                props.setModalData({
                  show: true,
                  type: "info",
                  header: "Działanie wyszukiwarki",
                  body: (
                    <p>
                      przykładowo: chcę wyszukać Jana Kowalskiego, wiem że ma
                      lokalizację w Warszawie przy ulicy Malowniczej.
                      <br />
                      Aby prawidłowo wyszukać takiego klienta najlepiej wpisać
                      samo nazwisko albo samą ulicę. Wpisanie "Jan Kowalski
                      Malownicza" nie zwróci żadnego wyniku. <br />
                      Analogicznie przy innych danych z listy - ich łączenie
                      może spowodować nieprawidłowe działanie wyszukiwarki.
                    </p>
                  ),
                })
              }
            >
              Szczegóły
            </Button>
            <ul>
              <li>Nazwa</li>
              <li>Imię</li>
              <li>Nazwisko</li>
              <li>
                NIP - <b>bez myślników i spacji</b>
              </li>
              <li>
                REGON - <b>bez myślników i spacji</b>
              </li>
              <li>
                PESEL - <b>bez myślników i spacji</b>
              </li>
              <li>Ulica</li>
              <li>Adres e-mail</li>
              <li>Fax</li>
              <li>Telefon</li>
              <li>Nazwa lokalizacji</li>
              <li>Ulica lokalizacji</li>
            </ul>
            <p>
              <b>Wielkość liter nie ma znaczenia</b>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3>Lista klientów</h3>
          </Col>
        </Row>
        <div>
          <Row>
            <Col>
              <p id="customersListInvMailBtn">
                <b>Kolumna "@fv"</b> - jeśli klient wyraził zgodę na eFakturę i
                jest dodany kontakt "eFaktura" to na liście pojawi się przycisk.
                Kliknięcie spowoduje otwarcie nowej wiadomości e-mail z
                wpisanymi kontaktami "eFaktura" i przygotowaną treścią i
                tematem. <u>Plik z fakturą należy załączyć ręcznie!</u>
              </p>
            </Col>
          </Row>
        </div>

        <Row>
          <Col>
            <h3>Jeden klient, wiele oddziałów</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Baza klientów jest <u>wspólna dla wszystkich odddziałów</u>.<br />
              Przed dodaniem klienta{" "}
              <b>proszę upewnić się, że nie występuje już w bazie</b>{" "}
              (szczególnie przy os. fiz., w pozostałych przypadkach program
              zablokuje próbę dodania jeśli jest zduplikowany NIP i{" "}
              <b>umożliwi przejście do już zarejstrowanego klienta</b> -
              "Podgląd" na zrzucie obok). <br />
            </p>
            <p>
              Jeśli klient jest już w bazie, ale nie jest przypisany do
              odpowiedniego oddziału{" "}
              <u>należy powiadomić kierownika oddziału</u>, który ma uprawnienia
              pozwalające na przypisanie klienta do nowego oddziału.
            </p>
            <p>
              W związku z połączeniem baz z kilku oddziałów, oraz importami
              danych ze starego programu (głównie na potrzeby sprawozdań
              kwartalnych), w bazie klientów będą pojawiać się duplikaty.
            </p>
            <p>
              Jeśli w wyniku wyszukiwania pojawia się kilku klientów, którzy
              faktycznie stanowią jeden podmiot:
            </p>
            <ul>
              <li>
                Należy sprawdzić wszystkich i dodać lokalizację do tego, który
                już ma jakąś zarejestrowaną (lub tego, który ma ich więcej)
              </li>
              <li>
                Jeśli żaden nie ma lokalizacji: należy zarejestrować nową do
                tego, który nie ma dopiska "(imp.)" w nazwie
              </li>
              <li>
                <u>We wszystkich innych wypadkach</u>: należy zarejestrować
                lokalizację temu, który wydaje się bardziej aktualny (np. ma
                bardziej szczegółowe notatki/kontakty)
              </li>
            </ul>
          </Col>
          <Col>
            <img src={duplicatedNIPAlert} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <div>
          <Row>
            <Col>
              <h3>Nabywca i odbiorca</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Czasem klient oczekuje wystawienia faktury z różnym nabywcą i
                odbiorcą (np. odbiorca = "Miejski Ośrodek Klutury", nabywca =
                "Urząd Gminy").
              </p>
              <p>
                <b>Miejski Ośrodek Kultury</b> w tej sytuacji będzie naszym
                klientem - na dane MOKu należy założyć/dodać klienta i{" "}
                <u>do MOKu dodać lokalizację</u>
                <br />
                <b>Urząd Gminy</b> bedzie nabywcą, pojawi się na fakturze, ale
                dla nas od strony logistycznej nie ma większego znaczenia.
                <br />W takiej sytuacji należy podpiąć nabywcę (UG) do odbiorcy
                (i naszego "głównego" klienta - MOK - tam, gdzie jest
                lokalizacja). Od momentu podpięcia <u>każda</u> faktura
                wystawiana dla MOK będzie miała podany UG jako nabywcę.
              </p>
              <h4>Jak to zrobić?</h4>
              <p>
                W szczegółach klienta (karta "Ogólne") {"->"} przycisk "Przypisz
                nabywcę".
                <br />
                Wyszukać i <u>kliknąć</u> odpowiedniego klienta (który ma być
                nabywcą). Pojawi się na górze jako "wybrany nabywca" {"->"}{" "}
                kliknąć "Zapisz".
                <br />
                <b>
                  Przypisany nabywca pojawia się w szczegółach klienta na żółtym
                  polu (górny zrzut ekranu)
                </b>
                .
              </p>
              <p>
                W ten sam sposób można <b>zmienić</b> przypisanego nabywcę.
                <br />
                Aby <b>usunąć</b> nabywcę, trzeba w modalu wybierania kliknąć
                "Usuń nabywcę" {"->"} w polu "wybrany nabywca" powinno pojawić
                się "brak" {"->"} kliknąć "Zapisz"
              </p>
            </Col>
            <Col>
              <img src={customerBuyerBtn} alt="" style={{ maxWidth: "100%" }} />
              <hr />
              <img
                src={customerBuyerModal}
                alt=""
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <Row>
              <Col>
                <h3>
                  Termin płatności liczony od <u>daty otrzymania</u> faktury
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Jeśli klient oczekuje określania terminu płatności od daty
                  otrzymania faktury, to przy rejestracji należy ustalić
                  najdłuższy dostępny termin.
                </p>
                <p>
                  <b>
                    Zgoda na termin płatności "od daty dostarczenia fv" wymaga
                    każdorazowej <u>pisemnej</u> zgody mojej lub Łukasza.
                  </b>
                </p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h3>e-faktura</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Zaznaczenie checkboxa "eFaktura?" w edycji danych klienta
                  spowoduje dołączenie adresu/ów e-mail zgodnie z poniższym
                  schematem do faktury i umożliwi automatyczną wysyłkę faktury z
                  Symfonii.
                </p>
                <h5>Zasady określania adresu e-mail</h5>
                <p>Poniższe działa tylko po zaznaczeniu ww. checkboxa</p>
                <ul>
                  <li>
                    Jeśli tylko jeden kontakt klienta zawiera adres e-mail -
                    zostanie on potraktowany jako adres do wysyłki faktury (
                    <b>niezależnie od typu kontaktu</b>)
                  </li>
                  <li>
                    Jeśli jest jakikolwiek kontakt typu "eFaktura" - zostanie
                    użyty adres e-mail z tego kontaktów. Jeśli jest ich więcej
                    to program połączy wszystkie adresy.
                  </li>
                  <li>
                    Jeśli żadne z powyższych nie jest spełnione - zostanie użyty
                    adres z kontaktu głównego.
                  </li>
                  <li>
                    Jeśli żadne z powyższych nie jest spełnione (jest kilka
                    kontaktów z mailem, żaden nie jest oznaczony jako "eFaktura"
                    i nie ma kontaktu głównego) - zostaną użyte <u>wszystkie</u>{" "}
                    adresy mail ze wszystkich dostępnych kontaktów.
                  </li>
                </ul>
                <p>
                  Dla przejrzystości najlepiej korzystać z kontaktu "eFaktura"
                  (lub kilku jeśli jest potrzeba). Jednak w przypadku małych
                  klientów z jednym kontaktem można dla ułatwienia zostawić
                  tylko ten kontakt - program skorzysta z tego adresu e-mail.{" "}
                </p>
                <p>
                  <i>
                    Pozostałe warunki mają na celu zapewnienie wysyłki faktury
                    pod <u>jakikolwiek</u> adres w przypadku
                    błędnej/niedokładnej rejestracji.
                  </i>
                </p>
              </Col>
              <Col>
                <img
                  src={customerEInvoice}
                  alt=""
                  style={{ maxWidth: "100%" }}
                />
              </Col>
            </Row>
            <hr />

            <Row>
              <Col>
                <h3>Dodatkowe parametry</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul>
                  <li>
                    <b>Specjalne kontrolki</b> - zaznaczenie tej opcji powoduje
                    włączenie drukowania "kontrolek klienta" przy planowaniu
                    tras dla <u>wszystkich</u> lokalizacji klienta.
                    <br />
                    Kontrolka klienta zawiera wszystkie lokalizacje zaplanowane
                    dla danego kierowcy na dany dzień. Na kontrolce widoczna
                    jest liczba sprzętu - dla usług sprzętowych i usług ze
                    sprzętem klienta pokazywana jest faktyczna liczba
                    zaplanowana do serwisu, dla innych usług liczba to zawsze 1.
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
}

export default ManualCustomersSection
