import React, { useContext } from "react"
import { Container, Row, Button, Table, Col } from "react-bootstrap"

import HistoryTable from "../../../utils/HistoryTable"

import { CustomerCtx } from "../Fetch"

import CustomersContactEditModal from "./CustomersContactEditModal"
import { entityEdit } from "./submit"

const CustomersContactDetailsModal = (props) => {
  const { customer, user, setModalData, refresh } = useContext(CustomerCtx)

  const contact = customer.contacts.find((el) => el._id === props.contact_id)

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <Row>
            <Button
              className="ml-3"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Historia edycji kontaktu",
                  xl: true,
                  body: (
                    <HistoryTable
                      history={contact.history}
                      goBack={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          header: "Szczegóły kontaktu",
                          xl: true,
                          body: (
                            <CustomersContactDetailsModal
                              contact_id={contact._id}
                            />
                          ),
                        })
                      }
                    />
                  ),
                })
              }
            >
              Historia
            </Button>
          </Row>
        </Col>

        {contact.state === "aktywny" &&
        user.perm.customers.e &&
        customer.state === "aktywny" ? (
          <>
            <Button
              variant="warning"
              onClick={() =>
                entityEdit(
                  "contacts",
                  { ...contact, state: "archiwum" },
                  { state: true },
                  contact.history.length,
                  customer,
                  // wrapper to show error message in modal
                  (msg) =>
                    setModalData({ show: true, type: "alert", body: msg }),
                  setModalData,
                  refresh
                )
              }
            >
              Archiwizuj
            </Button>
            <Col>
              <Row className="justify-content-end">
                <Button
                  className="mr-3"
                  variant="outline-secondary"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: "Edycja kontaktu",
                      hideFooter: true,
                      body: <CustomersContactEditModal contact={contact} />,
                    })
                  }
                >
                  Edytuj
                </Button>
              </Row>
            </Col>
          </>
        ) : null}
        {contact.state === "archiwum" && user.perm.customers.e ? (
          <Button
            variant="outline-secondary"
            onClick={() =>
              entityEdit(
                "contacts",
                { ...contact, state: "aktywny" },
                { state: true },
                contact.history.length,
                customer,
                // wrapper to show error message in modal
                (msg) => setModalData({ show: true, type: "alert", body: msg }),
                setModalData,
                refresh
              )
            }
          >
            Przywróć
          </Button>
        ) : null}
      </Row>
      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Typ</td>
            <td className="fixed-values-table">Stanowisko</td>
            <td className="fixed-values-table">Imię i nazwisko/nazwa</td>
          </tr>
          <tr>
            <td>{contact.type}</td>
            <td>{contact.position}</td>
            <td>{contact.name}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">Mail</td>
            <td className="fixed-values-table">Telefon</td>
            <td className="fixed-values-table">Fax</td>
          </tr>
          <tr>
            <td>{contact.mail}</td>
            <td>{contact.phone}</td>
            <td>{contact.fax}</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="3">
              Notatka
            </td>
          </tr>
          <tr>
            <td colSpan="3">{contact.note}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default CustomersContactDetailsModal
