import { useState } from "react"
import {
  Container,
  Row,
  Table,
  Button,
  Form,
  Alert,
  Badge,
} from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FaRegSave, FaTrashAlt } from "react-icons/fa"
import renderSpinner from "../../../utils/renderSpinner"

const CustomerDetailsBankAccModal = ({
  bankAccounts,
  customer_id,
  custHistoryLength,
  refresh,
}) => {
  const [addAcc, setAddAcc] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchErr, setFetchErr] = useState(null)
  const [validationErr, setValidationErr] = useState(null)
  const [accToRemove, setAccToRemove] = useState(null)

  const handleAddAcc = async (formData) => {
    try {
      setIsLoading("zapisuję konto...")
      setValidationErr(null)

      const res = await fetch("/customers/addEntity", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entity: formData,
          dirtyFields: { number: true, type: true, owner: true },
          entityName: "bankAccounts",
          customer_id,
        }),
      })

      //* validation error
      if (res.status === 422) {
        const resJson = await res.json()
        setIsLoading(false)
        return setValidationErr(resJson)
      }

      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )

      return refresh({
        body: "Konto dodane",
        header: "Sukces",
        headerColor: "bg-successLight",
      })
    } catch (err) {
      console.log(err)
      setFetchErr(err.message || "nieokreślony błąd komunikacji z serwerem")
    }
  }

  const handleAccRemove = async (acc_id) => {
    try {
      setAccToRemove(null)
      setIsLoading("usuwam konto...")

      const res = await fetch("/customers/removeEntity", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entity_id: acc_id,
          entityName: "bankAccounts",
          customer_id,
          custHistoryLength,
        }),
      })

      if (res.status !== 200) {
        setIsLoading(false)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      }

      return refresh({
        body: "Konto usunięte",
        header: "Sukces",
        headerColor: "bg-successLight",
      })
    } catch (err) {
      console.log(err)
      setFetchErr(err.message || "nieokreślony błąd komunikacji z serwerem")
    }
  }

  return (
    <Container fluid>
      {fetchErr ? <Alert variant="danger">{fetchErr}</Alert> : null}
      {addAcc ? (
        <AddAcc
          handleAddAcc={handleAddAcc}
          isLoading={isLoading}
          validationErr={validationErr}
        />
      ) : (
        <Row>
          <Button
            onClick={() => setAddAcc(!addAcc)}
            variant="secondary"
            className="ml-auto mr-3 my-2"
          >
            Dodaj
          </Button>
        </Row>
      )}
      {isLoading ? renderSpinner(isLoading || "wysyłam dane...") : null}
      <Table>
        <tbody>
          <tr className="fixed-values-table">
            <td>Typ</td>
            <td>Nr konta</td>
            <td>Właściciel</td>
            <td></td>
          </tr>
          {renderAccTable(
            bankAccounts,
            handleAccRemove,
            accToRemove,
            setAccToRemove,
            isLoading
          )}
        </tbody>
      </Table>
    </Container>
  )
}

const renderAccTable = (
  accountsArr,
  handleAccRemove,
  accToRemove,
  setAccToRemove,
  isLoading
) => {
  if (!accountsArr.length)
    return (
      <tr>
        <td colSpan="3">
          <Row className="justify-content-center mt-2">
            <p>
              <b>Brak kont</b>
            </p>
          </Row>
        </td>
      </tr>
    )
  else
    return accountsArr.map((acc) => (
      <>
        <tr key={`bankAccTable-${acc.number}-${acc._id}`}>
          <td>{acc.type}</td>
          <td>{acc.number}</td>
          <td>{acc.owner}</td>
          <td>
            <Button
              variant="danger"
              disabled={isLoading === true}
              onClick={() => setAccToRemove(acc._id)}
            >
              <FaTrashAlt />
            </Button>
          </td>
        </tr>
        {accToRemove === acc._id ? (
          <tr className="bg-dangerLight">
            <td colSpan="2">
              <Row className="justify-content-center mt-1">
                <h5>Chcesz usunąć to konto?</h5>
              </Row>
            </td>
            <td>
              <Button variant="danger" onClick={() => handleAccRemove(acc._id)}>
                Tak
              </Button>
            </td>
            <td>
              <Button onClick={() => setAccToRemove(null)}>Nie</Button>
            </td>
          </tr>
        ) : null}
      </>
    ))
}

const AddAcc = ({ handleAddAcc, isLoading, validationErr }) => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues: { number: "", owner: "", type: "Przychód" },
  })
  return (
    <Form onSubmit={handleSubmit(handleAddAcc)}>
      <Table>
        <tbody>
          <tr>
            <td>
              <p>
                <Form.Control
                  as="select"
                  className=""
                  autoComplete="chrome-off"
                  id="accType"
                  readOnly={isLoading}
                  {...register("type")}
                >
                  <option>Przychód</option>
                  <option>Koszty</option>
                </Form.Control>
                {validationErr?.type ? (
                  <Badge pill variant="warning">
                    {validationErr.type}
                  </Badge>
                ) : null}
              </p>
            </td>
            <td>
              <p>
                <Form.Control
                  as="input"
                  type="string"
                  className="mt-1"
                  autoComplete="chrome-off"
                  id="accNumber"
                  readOnly={isLoading}
                  {...register("number")}
                />
                {validationErr?.number ? (
                  <Badge pill variant="warning">
                    {validationErr.number}
                  </Badge>
                ) : null}
              </p>
            </td>
            <td>
              <p>
                <Form.Control
                  as="input"
                  type="text"
                  autoComplete="chrome-off"
                  id="accOwner"
                  className="mt-1"
                  readOnly={isLoading}
                  {...register("owner")}
                />
                {validationErr?.owner ? (
                  <Badge pill variant="warning">
                    {validationErr.owner}
                  </Badge>
                ) : null}
              </p>
            </td>
            <td>
              <Button
                type="submit"
                variant="secondary"
                disabled={watch("number") ? false : true}
              >
                <FaRegSave />
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Form>
  )
}
export { CustomerDetailsBankAccModal }
