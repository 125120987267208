import React from "react"
import { Table } from "react-bootstrap"
import { format, differenceInCalendarDays } from "date-fns"

class JobsPrintRender extends React.Component {
  render() {
    const { jobs, customer, filters } = this.props
    return (
      <div>
        <h3>
          Zestawienie zadań dla {`${customer.name} ${customer.lastName || ""}`}
        </h3>
        <p>
          Stan: {filters.states.join(", ")} || Zakres dat: {filters.startDate} -{" "}
          {filters.endDate} || Oddziały: {filters.branches.join(", ")}{" "}
        </p>
        <Table bordered>
          <thead>
            <tr>
              <th>Data</th>
              <th>Lokalizacja</th>
              <th>Stan</th>
              <th>Typ</th>
              <th>Liczba sprzętu</th>
              <th>Kierowca</th>
              <th>Komentarz</th>
            </tr>
          </thead>
          <tbody>
            {jobs
              ? jobs.map((job) => {
                  return (
                    <tr>
                      <td>{format(new Date(job.date), "yyyy-MM-dd")}</td>
                      <td>
                        {job.location.street} {job.location.city}
                      </td>
                      <td>{job.state}</td>
                      <td>{job.type}</td>
                      <td>
                        {countQty(job)}{" "}
                        {filters.getEqpNumbers ? rendertEqpNumbers(job) : null}
                      </td>
                      <td>{job.driver.fullName}</td>
                      <td>{job.doneComments}</td>
                    </tr>
                  )
                })
              : "Brak zadań"}
          </tbody>
        </Table>
      </div>
    )
  }
}

const countQty = (job) => {
  let qty = 0
  job.services.forEach((jobSrv) => {
    // if there is equipment, just count it's qty:
    if (jobSrv.eqp?.length) {
      return (qty += jobSrv.eqp.reduce((acc, red) => {
        // for real eqp (no template or not-numbered) there is no qty, so I just add one
        if (red.qty) return (acc += red.qty)
        else return acc + 1
      }, 0))
    }

    // else find location service
    const locSrv = job.location.services.find(
      (srv) => srv._id === jobSrv.locationServiceRef
    )

    // when not found (which should be error in fact):
    if (!locSrv) return

    const actualParam = locSrv.params.find((prm) => {
      if (
        differenceInCalendarDays(new Date(), new Date(prm.startDate)) >= 0 &&
        (!prm.hasEndDate ||
          differenceInCalendarDays(new Date(prm.endDate), new Date()))
      )
        return true
      else return false
    })

    // when not found (which should be error in fact):
    if (!actualParam) return
    qty += actualParam.qty
  })

  return qty
}

const rendertEqpNumbers = (job) => {
  const numbers = []
  job.services.forEach((jobSrv) => {
    if (jobSrv.eqp?.length)
      jobSrv.eqp.forEach((jobSrvEqp) => {
        if (jobSrvEqp.number) numbers.push(jobSrvEqp.number)
      })
  })

  const numbersString = numbers.length ? ` (${numbers.join(", ")})` : ""

  return numbersString
}
export default JobsPrintRender
