import React from "react"
import { Container, Table } from "react-bootstrap"

import ServicesManageDetailsModal from "./DetailsModal"

const ServicesManageTableRender = ({
  services,
  setModalData,
  configs,
  refresh,
}) => {
  return (
    <Container fluid>
      <Table>
        <tbody>
          <tr className="fixed-values-table">
            <td>Nazwa</td>
            <td>Typ</td>
            <td>Naliczenie</td>
            <td>Sprzętowa?</td>
            <td>Zadania wymagane?</td>
            <td>Stan</td>
          </tr>
          {services.map((srv) => {
            return (
              <tr
                key={`serviceRow-${srv._id}`}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    header: "Szczegóły usługi",
                    body: (
                      <ServicesManageDetailsModal
                        srv={srv}
                        setModalData={setModalData}
                        configs={configs}
                        refresh={refresh}
                      />
                    ),
                  })
                }
              >
                <td>{srv.name}</td>
                <td>{srv.type}</td>
                <td>{srv.chargeType}</td>
                <td>{srv.mustHaveEqp ? "tak" : "nie"}</td>
                <td>{srv.mustHaveJobs ? "tak" : "nie"}</td>
                <td>{srv.state}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default ServicesManageTableRender
