import React, { useContext } from "react"
import { Container, Tabs, Tab, Table, Row, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { ImNewTab } from "react-icons/im"

import MetaContext from "../contexts/metaContext"
import LocationFAQ from "../../doc/faqs/Location"
import { LocationCtx } from "./Fetch"
import LocationsDetailsGeneralTab from "./tabs/LocationsDetailsGeneralTab"
import LocationsDetailsAdditionalTab from "./tabs/LocationsDetailsAdditionalTab"
import LocationsDetailsJobsTab from "./tabs/LocationsDetailsJobsTab"
import { clearLoc, copyLoc } from "./utils/submits"

const LocationsDetailsRender = (props) => {
  const { location, setModalData, user } = useContext(LocationCtx)
  const { devEnv } = useContext(MetaContext)

  const { tabSelected, setTabSelected } = props

  return (
    <Container fluid>
      <Helmet>
        <title>
          {location.street}/{location.customer.shortName}
        </title>
      </Helmet>
      <Row className="position-fixed bg-white" style={{ zIndex: 10 }}>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Klient:</td>
              <td>
                <ImNewTab />
                <a
                  href={`/CustomersDetails/${location.customer._id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {location.customer.shortName || location.customer.name}
                </a>
              </td>
              <td className="fixed-values-table">Adres lokalizacji:</td>
              <td>{`${location.street} ${location.city}`}</td>
              {location.number && location.numberYear ? (
                <>
                  <td className="fixed-values-table">Nr</td>
                  <td>
                    {location.numberYear}-{location.number}
                  </td>
                </>
              ) : null}
              {location.oldNumber ? (
                <>
                  <td className="fixed-values-table">Stary nr</td>
                  <td>{location.oldNumber}</td>
                </>
              ) : null}
              <td className="fixed-values-table">Oddział:</td>
              <td>{location.branch}</td>
              <td className="fixed-values-table">Stan:</td>
              <td>
                {location.state}
                {location.notes.filter(
                  (note) =>
                    note.type === "rejestracja wstępna" &&
                    note.state === "aktywna"
                ).length
                  ? " (REJ. WSTĘPNA)"
                  : null}
              </td>

              <td>
                <Button
                  size="sm"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Pomoc dla lokalizacji",
                      xl: true,
                      body: <LocationFAQ />,
                    })
                  }
                >
                  Pomoc
                </Button>
              </td>
              {user.type.admin && (devEnv || location.branch === "TEST") ? (
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "Pomoc dla lokalizacji",
                        xl: true,
                        body: (
                          <>
                            {" "}
                            <p>
                              Czy na pewno chcesz usunąć wszystkie usługi i
                              zadania?
                            </p>
                            <Button
                              onClick={() =>
                                clearLoc(setModalData, location._id)
                              }
                            >
                              Tak
                            </Button>
                            <Button
                              onClick={() => setModalData({ show: false })}
                            >
                              Nie
                            </Button>
                          </>
                        ),
                      })
                    }
                  >
                    Wyczyść lokalizację
                  </Button>
                </td>
              ) : null}
              {user.type.admin && (devEnv || location.branch === "TEST") ? (
                <td>
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "kopia lokalizacji",
                        xl: true,
                        body: (
                          <>
                            {" "}
                            <p>Chcesz utworzyć kopię lokalizacji?</p>
                            <Button
                              onClick={() =>
                                copyLoc(setModalData, location._id, devEnv)
                              }
                            >
                              Tak
                            </Button>
                            <Button
                              onClick={() => setModalData({ show: false })}
                            >
                              Nie
                            </Button>
                          </>
                        ),
                      })
                    }
                  >
                    Kopiuj lokalizację
                  </Button>
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
      </Row>

      <p className="m-0" style={{ fontSize: "0.8rem", color: "white" }}>
        {location._id}
      </p>
      <Tabs
        id="location-tabs"
        activeKey={tabSelected}
        onSelect={(key) => setTabSelected(key)}
        fill
        className="tab mt-6"
        transition={false}
      >
        <Tab eventKey="general" title="Ogólne" className="tab">
          <LocationsDetailsGeneralTab />
        </Tab>
        <Tab eventKey="additional" title="Szczegóły" className="tab">
          <LocationsDetailsAdditionalTab />
        </Tab>
        <Tab eventKey="jobs" title="Zadania" className="tab">
          <LocationsDetailsJobsTab />
        </Tab>
      </Tabs>
    </Container>
  )
}
export default LocationsDetailsRender
