import { compareDesc, isValid } from "date-fns"

const sortByCreationDate = (elA, elB) => {
  // when no history entries always put on end
  if (!elA.history?.length && !elB.history?.length) return 0
  if (!elA.history?.length || !isValid(new Date(elA.history[0].date))) return 1
  if (!elB.history?.length || !isValid(new Date(elB.history[0].date))) return -1

  return compareDesc(
    new Date(elA.history[0].date),
    new Date(elB.history[0].date)
  )
}

export default sortByCreationDate
