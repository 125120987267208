import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import EditInvoiceRender from "./Render"

const EditInvoiceFetch = ({ setModalData, invoice, refresh, isNew }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [services, setServices] = useState([])
  const [configs, setConfigs] = useState()

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const res = await fetch("/services/getAll")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        const configs = await fetch(`/configs/paymentMethods,paymentDeadlines`)
        if (res.status !== 200) throw res

        const resJSON = await res.json()
        const configsJSON = await configs.json()

        setServices(resJSON)
        setConfigs(configsJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [setModalData])

  return (
    <Container id="InvList">
      {isLoading ? (
        "pobieram dane..."
      ) : (
        <EditInvoiceRender
          setModalData={setModalData}
          invoice={invoice}
          refresh={refresh}
          services={services}
          configs={configs}
          isNew={isNew}
        />
      )}
    </Container>
  )
}
export default EditInvoiceFetch
