import React, { useContext } from "react"
import { Table } from "react-bootstrap"

import { CustomerCtx } from "../Fetch"

const CustomersDetailsClipMenuDetails = () => {
  const ctx = useContext(CustomerCtx)
  const customer = ctx.customer.oldData

  const renderAllData = () => {
    const allData = []
    for (let key in customer)
      allData.push(`${customerFieldsMap[key]}: "${customer[key]}"`)
    return allData.join("\n")
  }
  return (
    <Table>
      <tbody>
        <tr>
          <td className="fixed-values-table">Numer</td>
          <td className="fixed-values-table">Oddział</td>
          <td className="fixed-values-table">Płatnik VAT?</td>
          <td className="fixed-values-table">Rodzaj</td>
        </tr>
        <tr>
          <td>{customer.oldAutoinc}</td>
          <td>{customer.oldBranch}</td>
          <td>{customer.oldVatPayer === "1" ? "tak" : "nie"}</td>
          <td>{customer.oldType}</td>
          <td></td>
        </tr>
        <tr>
          <td className="fixed-values-table">Nazwa pełna</td>
          <td className="fixed-values-table">Nazwa krótka</td>
          <td className="fixed-values-table">Nip</td>
          <td className="fixed-values-table">REGON/PESEL</td>
        </tr>
        <tr>
          <td>{customer.oldFullName}</td>
          <td>{customer.oldShortName}</td>
          <td>{customer.oldVatNumber}</td>
          <td>{customer.oldRegon}</td>
        </tr>
        <tr>
          <td className="fixed-values-table" colSpan="4">
            Adres główny
          </td>
        </tr>
        <tr>
          <td colSpan="4">{`${customer.oldStreet} ${customer.oldPostal} ${customer.oldCity}`}</td>
        </tr>
        <tr>
          <td className="fixed-values-table" colSpan="4">
            Adres korespondencyjny
          </td>
        </tr>
        <tr>
          <td colSpan="4">
            {`${customer.oldCorrName} ${customer.oldCorrName2}`}
            <br />

            {`${customer.oldCorrStreet} ${customer.oldCorrPostal} ${customer.oldCorrCity}`}
          </td>
        </tr>
        <tr>
          <td className="fixed-values-table">Reprezentacja</td>
          <td className="fixed-values-table">Telefon</td>
          <td className="fixed-values-table">Mail</td>
          <td className="fixed-values-table">Fax</td>
        </tr>
        <tr>
          <td>{customer.oldRep}</td>
          <td>{customer.oldPhone}</td>
          <td>{customer.oldMainEmail}</td>
          <td>{customer.oldFax}</td>
        </tr>
        <tr>
          <td className="fixed-values-table">Termin płatności</td>
          <td className="fixed-values-table">eFaktura?</td>
          <td className="fixed-values-table" colSpan="2">
            Mail eFaktura
          </td>
        </tr>
        <tr>
          <td>{customer.oldDefaultDeadline}</td>
          <td>{customer.oldEInvoice === "1" ? "tak" : "nie"}</td>
          <td colSpan="2">{customer.oldInvoiceEmail}</td>
        </tr>
        <tr>
          <td className="fixed-values-table">Lokalizacja na FV?</td>
          <td className="fixed-values-table">Metoda płatności</td>
          <td className="fixed-values-table" colSpan="2">
            Nr. konta
          </td>
        </tr>
        <tr>
          <td>{customer.oldLocOnInvoice === "1" ? "tak" : "nie"}</td>
          <td>{customer.oldDefaultPaymentMethod}</td>
          <td colSpan="2">{customer.oldBankAcc}</td>
        </tr>
        <tr>
          <td className="fixed-values-table" colSpan="4">
            Wszystkie dane:
          </td>
        </tr>
        <tr>
          <td colSpan="4" className="keep-br">
            {renderAllData()}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
export default CustomersDetailsClipMenuDetails

const customerFieldsMap = {
  oldAutoinc: "AutoInc",
  autoinc: "AutoInc",
  oldBranch: "Oddzial",
  oldShortName: "NazwaS",
  oldFullName: "NazwaP",
  oldPostal: "KodPocztowy",
  oldCity: "Miasto",
  oldStreet: "Ulica",
  oldPhone: "Telefon",
  oldFax: "Fax",
  oldRep: "Reprezentant",
  oldRegon: "Regon",
  oldVatPayer: "PlatnikVAT",
  oldVatNumber: "Nip",
  oldDefaultDeadline: "TerminPlatnosci",
  oldDefaultPaymentMethod: "RodzajPlatnosci",
  oldCorrName: "NazwaKor",
  oldCorrName2: "NazwaKor2",
  oldCorrStreet: "UlicaKor",
  oldCorrPostal: "KodKor",
  oldCorrCity: "MiastoKor",
  oldLocOnInvoice: "LokalizacjaNaFakturze",
  oldBankAcc: "PlatnosciZKonta",
  oldMainEmail: "EmailOgolny",
  oldInvoiceEmail: "EmailFaktury",
  oldEInvoice: "WysylacFaktury",
  oldDiscountForPayment: "RabatZaPlatnosc",
  oldDiscountForPaymentVal: "WysokoscRabatuZaPlatnosc",
  oldIsContract: "JestUmowa",
  oldContractPrintDate: "UmowaOgolnaWydruk",
  oldContractSentDate: "UmowaOgolnaWyslana",
  oldContractReturnDate: "UmowaOgolnaWrocila",
  oldCustomJobsCard: "DrukowacSpecjalneKonr",
  oldSpecialPrices: "CenySpecjalne",
  oldPrintDebtDetails: "FVZRozrachunkami",
  oldGreen: "V_Zielony",
  oldJobReminderPhone: "SMSSerwisy",
  oldPaymentPhone: "SMSPlatnosci",
  oldMainNote: "Uwagi",
  oldVNote: "V_Uwagi",
}
