import React from "react"
import { Button, Container, Row } from "react-bootstrap"

const checkVersion = (
  ver,
  userVer,
  verDateRaw,
  setModalData,
  setVerOverdated,
  setRedirect
) => {
  const verArr = ver.split(".")
  const userVerArr = userVer.split(".")

  if (verArr[0] === userVerArr[0] && verArr[1] === userVerArr[1]) return

  const today = new Date()
  const verDateMs = Date.parse(new Date(verDateRaw))
  const verAlertDismiss = localStorage.getItem("verAlertDismiss")

  // when version older then 7 days:
  if (today > new Date(verDateMs + 7 * 24 * 60 * 60 * 1000)) {
    setVerOverdated(true)
    setModalData({
      show: true,
      type: "info",
      header: "Nowa wersja",
      headerColor: "warning",
      hideFooter: true,
      body: (
        <Container id="mustCheckUpdatesModal">
          Przed dalszą pracą w programie wymagane jest zapoznanie się ze
          zmianami
          <Row className="mt-5">
            <Button
              onClick={() => {
                setModalData({ show: false })
              }}
            >
              Pokaż listę
            </Button>
          </Row>
        </Container>
      ),
    })
  }
  //if not older then 7 days and there was no dismiss, or it is older then one hour ago -> show modal with 'dismiss' button:
  else if (
    !verAlertDismiss ||
    Date.parse(today) >
      //* prod - 1 hour
      Date.parse(new Date(verAlertDismiss)) + 1 * 60 * 60 * 1000
    //* dev and testing - 1 minute
    // Date.parse(new Date(verAlertDismiss)) + 60 * 1000
  ) {
    setModalData({
      show: true,
      type: "info",
      header: "Nowa wersja",
      headerColor: "warning",
      hideFooter: true,
      body: (
        <Container id="shouldCheckUpdatesModal">
          Zapoznaj się ze zmianami.
          <Row className="mt-5">
            <Button
              onClick={() => {
                localStorage.setItem("verAlertDismiss", today)
                setModalData({ show: false })
              }}
              id="dismissBtn"
            >
              Odłóż
            </Button>
            <Button
              className="ml-auto"
              onClick={() => {
                setRedirect("/ChangeLog")
                setModalData({ show: false })
              }}
              id="goToChangelogBtn"
            >
              Sprawdź
            </Button>
          </Row>
        </Container>
      ),
    })
  }
}

export default checkVersion
