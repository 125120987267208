import React, { useState } from "react"
import {
  Container,
  Row,
  Button,
  Dropdown,
  DropdownButton,
  Alert,
  Table,
} from "react-bootstrap"
import { Link } from "react-router-dom"
import { ImNewTab } from "react-icons/im"

import HistoryTable from "../../utils/HistoryTable"

import ServicesManageEditModal from "./EditModal"

const ServicesManageDetailsModal = ({
  srv,
  setModalData,
  configs,
  refresh,
}) => {
  const [fetchError, setFetchError] = useState(null)
  const changeState = async (newState) => {
    try {
      const res = await fetch("/services/edit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          service: { _id: srv._id, state: newState },
          dirtyFields: { state: true },
        }),
      })

      if (res.status === 403) return setFetchError("brak uprawnień")
      if (res.status === 409) return setFetchError("edytujesz stare dane")
      if (res.status !== 200) throw res
      return setModalData({
        show: true,
        type: "info",
        headerColor: "green",
        header: "Sukces",
        body: "Poszło!",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError("błąd w changeState()")
    }
  }

  return (
    <Container>
      <Row className="justify-content-between">
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: "Historia usługi",
              body: (
                <HistoryTable
                  history={srv.history}
                  goBack={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      header: "Szczegóły usługi",
                      body: (
                        <ServicesManageDetailsModal
                          srv={srv}
                          setModalData={setModalData}
                          configs={configs}
                          refresh={refresh}
                        />
                      ),
                    })
                  }
                />
              ),
            })
          }
        >
          Historia
        </Button>
        <DropdownButton variant="warning" title="Zmień stan">
          <Dropdown.Item onClick={() => changeState("aktywna")}>
            aktywna
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeState("nieaktywna")}>
            nieaktywna
          </Dropdown.Item>
        </DropdownButton>
        {/* 
        opening new tab with LocationsList and query string
        I pass srvName to show alert
        (no need to use <Link>, I just tried to pass state, no success so I'm using query)
        */}
        <Link
          to={`/LocationsList?srv_id=${srv._id}&srvName=${srv.name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>
            Szukaj lokalizacji <ImNewTab />
          </Button>
        </Link>
        <Button
          variant="secondary"
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              hideFooter: true,
              header: "Dodawanie/edycja usługi",
              body: (
                <ServicesManageEditModal
                  srv={srv}
                  setModalData={setModalData}
                  configs={configs}
                  refresh={refresh}
                />
              ),
            })
          }
        >
          Edytuj
        </Button>
      </Row>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">nazwa:</td>
            <td>{srv.name}</td>
            <td className="fixed-values-table">stan:</td>
            <td>{srv.state}</td>
            <td className="fixed-values-table">priorytet:</td>
            <td>{srv.priority}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">typ:</td>
            <td>{srv.type}</td>
            <td className="fixed-values-table">kategoria przychodu:</td>
            <td>{srv.defaultIncomeCat}</td>
            <td className="fixed-values-table">naliczenie:</td>
            <td>{srv.chargeType}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">cena netto:</td>
            <td>{srv.defaultNetPrice}</td>
            <td className="fixed-values-table">vat:</td>
            <td>{srv.defaultVat}</td>
            <td className="fixed-values-table">jm:</td>
            <td>{srv.unit}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">domyślna treść:</td>
            <td colSpan={5}>{srv.defaultInvLine}</td>
          </tr>
          <tr>
            <td colSpan="6" className="fixed-values-table">
              <h5>Parametry:</h5>
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">wymagać zadań:</td>
            <td>{srv.mustHaveJobs ? "tak" : "nie"}</td>

            <td className="fixed-values-table">usługa sprzętowa: </td>
            <td>{srv.mustHaveEqp ? "tak" : "nie (=bezsprzętowa)"}</td>

            <td className="fixed-values-table">sprzęt klienta:</td>
            <td>{srv.custEqp ? "tak" : "nie"}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">usługa (również) podrzędna</td>
            <td>{srv.canBeSubSrv ? "tak" : "nie"}</td>
            <td className="fixed-values-table">podrzędna - można doliczać </td>
            <td>{srv.allowExtraFee ? "tak" : "nie"}</td>
            <td className="fixed-values-table">może mieć usługi podrzędne </td>
            <td>{srv.canHaveSubSrv ? "tak" : "nie"}</td>
          </tr>
          <tr>
            <td className="fixed-values-table">
              <h5>Sprzęt:</h5>
            </td>
            <td colSpan="4">
              {srv.eqpTypes?.map((eqp) => {
                return ` ${eqp}`
              })}
            </td>

            <td className="fixed-values-table">
              ikona:{" "}
              <svg
                viewBox="-40 -40 80 80"
                style={{ width: "2rem", height: "2rem" }}
              >
                <path d={srv.iconPath} />
              </svg>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default ServicesManageDetailsModal
