import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Row, Table, Form, Alert, Button } from "react-bootstrap"

const LocationsDetailsEqpRender = ({
  equipment,
  srv,
  refresh,
  setModalData,
  isDelivery,
  location,
}) => {
  const [fetchError, setFetchError] = useState(false)

  const defaultValues = {
    equipment: equipment.map((eqp) => {
      // if there is any quantity it means it is equipment selected in location, so I set ref (=_id) and qty
      if (eqp.qty) return { selected: true, qty: eqp.qty }
      else return { selected: false, qty: null }
    }),
  }

  const { register, handleSubmit, setValue, watch, control } = useForm({
    defaultValues: defaultValues,
  })
  const assignEqp = async (formData) => {
    try {
      const eqpArr = formData.equipment.filter((eqp) => eqp.selected)

      // set qty = 1 for equipment without qty from form (mainly numbered eqp, like toilets)
      eqpArr.forEach((eqp, i) => {
        if (!eqp.qty) return (eqpArr[i].qty = 1)
      })

      const res = await fetch("/locations/overrideCustomerEqp", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          location: {
            _id: location._id,

            // I use 'isDelivery' when adding eqp in next step after adding service, so I have to add 1 to history length
            locHistoryLength: isDelivery
              ? location.history.length + 1
              : location.history.length,
          },
          locSrv_id: srv._id,
          equipment: eqpArr.map((eqp) => ({ ...eqp, inPlace: eqp.qty })),
        }),
      })

      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status === 409)
        return setFetchError(
          "Ktoś w międzyczasie edytował tą lokalizację. Zapisz wprowadzone zmiany w innym miejscu (word, kartka papieru etc.) i odśwież stronę"
        )
      if (res.status !== 200) throw res
      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: "Dane zadania zmienione",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError(err.message || "Błąd działania programu")
    }
  }

  if (!equipment.length) return "Brak sprzętu"
  else
    return (
      <>
        <Alert variant="danger">
          Uwaga! Sprzęt wybrany poniżej <b>nadpisze</b> aktualnie przypisany do
          usługi. (np. było 5, poniżej wpiszesz 3, to od teraz liczba będzie
          wynosić{" "}
          <b>
            <u>3</u>
          </b>
          ).
          <br />
          Zmienione zostaną też wszystkie <b>zaplanowane</b> zadania dotyczące
          tej usługi.
        </Alert>
        <Form onSubmit={handleSubmit(assignEqp)}>
          <Table>
            <thead>
              <tr>
                <th>DZ</th>
                <th>Typ/Nazwa</th>
                <th>Liczba</th>
                <th>Podtyp</th>
                <th>Charakterystyka</th>
                <th>Kolor</th>
                <th>Kategoria</th>
                <th>Wyposażenie</th>
                <th>Numer</th>
              </tr>
            </thead>
            <tbody>
              {equipment.map((eqp, i) => {
                register(`equipment.${i}.ref`, { value: eqp._id })

                return (
                  <tr key={`equipment-table-row-${eqp._id}`}>
                    <td>
                      <Controller
                        control={control}
                        name={`equipment.${i}.selected`}
                        render={({ field }) => {
                          return (
                            <Form.Check
                              {...field}
                              className=""
                              autoComplete="chrome-off"
                              checked={field.value}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setValue(
                                    `equipment[${i}].qty`,
                                    srv.params[0].qty || 1
                                  )
                                } else {
                                  setValue(`equipment.${i}.qty`, null)
                                }
                                field.onChange(e)
                              }}
                            />
                          )
                        }}
                      />
                    </td>

                    <td>{eqp.type || eqp.name}</td>
                    <td>
                      {eqp.isTemplate || eqp.number === 0 ? (
                        <Form.Control
                          {...register(`equipment.${i}.qty`)}
                          as="input"
                          type="number"
                          className="small-number-input"
                          autoComplete="chrome-off"
                          disabled={
                            watch(`equipment.${i}.selected`) ? false : true
                          }
                        />
                      ) : (
                        "nd"
                      )}
                    </td>
                    <td>{eqp.subtype}</td>
                    <td>{eqp.char}</td>
                    <td>{eqp.color}</td>
                    <td>{eqp.category}</td>
                    <td>{eqp.mods.join(", ") || null}</td>
                    <td>{eqp.isTemplate ? "templatka" : eqp.number}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          {fetchError && <Alert variant="danger">{fetchError}</Alert>}
          <Row className="justify-content-between">
            <Button variant="warning" onClick={() => refresh()}>
              Anuluj
            </Button>
            <Button
              type="submit"
              variant="secondary"
              className="ml-auto mr-3"
              id="deliveryAddSaveEqpBtn"
            >
              Zapisz
            </Button>
          </Row>
        </Form>{" "}
      </>
    )
}
export default LocationsDetailsEqpRender
