import React from "react"
import {
  Button,
  Table,
  Container,
  Row,
  DropdownButton,
  Dropdown,
} from "react-bootstrap"

const DetailsEditTemplate = () => {
  return (
    <Container>
      <h2>Podgląd:</h2>

      <h2>Podgląd:</h2>
      <Table bordered>
        <tbody>
          <tr>
            <td className="bg-primaryLight">Data utworzenia</td>
            <td>2020-01-01</td>
            <td className="bg-primaryLight">Data modyfikacji</td>
            <td>2020-01-01</td>
            <td className="bg-primaryLight">Status</td>
            <td>Zastanowię się</td>
            <td className="bg-primaryLight">Ostatnio modyfikował</td>
            <td>Cezary</td>
          </tr>
        </tbody>
      </Table>
      <Row className="justify-content-between  my-1">
        <Button>Wróć</Button>
        <Button>Zrób coś</Button>
        <Button variant="secondary">Zmień status ("w prawo")</Button>
        <Button variant="warning">Archiwizuj</Button>

        <DropdownButton title="Cofnij status" variant="warning">
          <Dropdown.Item variant="primary">oczekująca</Dropdown.Item>
        </DropdownButton>
        <Button variant="outline-primary">Edytuj</Button>
      </Row>

      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nazwa 1</td>
            <td className="fixed-values-table">Nazwa 2</td>
            <td className="fixed-values-table">Nazwa 3</td>
          </tr>
          <tr>
            <td>Wartość 1</td>
            <td>Wartość 2</td>
            <td>Wartość 3</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Nazwa 4
            </td>

            <td className="fixed-values-table">Nazwa 5</td>
          </tr>
          <tr>
            <td colSpan="2">Wartość 4</td>
            <td>Wartość 5</td>
          </tr>
          <tr className="fixed-values-table">
            <td>Nazwa 6</td>
          </tr>
          <tr>
            <td>Wartość 6</td>
          </tr>
        </tbody>
      </Table>
      <h2>Edycja:</h2>
      <Table bordered>
        <tbody>
          <tr>
            <td className="bg-primaryLight">Data utworzenia</td>
            <td>2020-01-01</td>
            <td className="bg-primaryLight">Data modyfikacji</td>
            <td>2020-01-01</td>
            <td className="bg-primaryLight">Status</td>
            <td>Zastanowię się</td>
            <td className="bg-primaryLight">Ostatnio modyfikował</td>
            <td>Cezary</td>
          </tr>
        </tbody>
      </Table>
      <Row className="justify-content-between my-1">
        <Button>Wróć</Button>
        <Button variant="danger">Anuluj</Button>
      </Row>

      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nazwa 1</td>
            <td className="fixed-values-table">Nazwa 2</td>
            <td className="fixed-values-table">Nazwa 3</td>
          </tr>
          <tr>
            <td>Wartość 1</td>
            <td>Wartość 2</td>
            <td>Wartość 3</td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Nazwa 4
            </td>

            <td className="fixed-values-table">Nazwa 5</td>
          </tr>
          <tr>
            <td colSpan="2">Wartość 4</td>
            <td>Wartość 5</td>
          </tr>
          <tr className="fixed-values-table">
            <td>Nazwa 6</td>
          </tr>
          <tr>
            <td>Wartość 6</td>
          </tr>
        </tbody>
      </Table>
      <Row>
        <Button variant="warning" className=" mr-2">
          Archiwum
        </Button>
        <Button variant="danger" className="ml-auto">
          Usuń
        </Button>
        <Button variant="secondary" className="ml-auto">
          Zapisz
        </Button>
      </Row>
    </Container>
  )
}
export default DetailsEditTemplate
