import React, { useContext } from "react"
import { Row, Table, Col, Button, Container } from "react-bootstrap"

import HistoryTable from "../../utils/HistoryTable"

import { LocationCtx } from "../Fetch"

import LocationsDetailsEditContactModal from "../Edit/modals/LocationsDetailsEditContactModal"
import sortByCreationDate from "../../utils/sortByCreationDate"

const LocationsDetailsContactsTable = (props) => {
  const { location, setModalData, refresh, user } = useContext(LocationCtx)

  const handleStateChange = async (contact) => {
    try {
      const res = await fetch(
        `/locations/editEntity/${location._id}?isNew=false&entity=contacts`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: {
              _id: contact._id,
              state: contact.state === "archiwum" ? "aktywny" : "archiwum",
            },
            dirtyFields: { state: true },
          }),
        }
      )
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res

      setModalData({
        show: true,
        type: "info",
        header: "Sukces",
        headerColor: "success",
        body: `Kontakt ${
          contact.state === "archiwum" ? "przywrócony" : "archiwizowany"
        }`,
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd komunikacji z serwerem",
      })
    }
  }

  const renderContactDetails = (contact) => {
    return (
      <Container>
        <Row className="justify-content-between mb-3">
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: true,
                header: "Historia kontaktu",
                body: <HistoryTable history={contact.history} />,
              })
            }
          >
            Historia
          </Button>
          {contact.type !== "główny" &&
            user.perm.customers.e &&
            location.state === "aktywna" && (
              <Button
                variant="warning"
                onClick={() => handleStateChange(contact)}
              >
                {contact.state === "aktywny" ? "Archiwizuj" : "Przywróć"}
              </Button>
            )}
          {user.perm.customers.e &&
            location.state === "aktywna" &&
            contact.state === "aktywny" && (
              <Button
                variant="secondary"
                className="mr-3"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    hideFooter: true,
                    header: "Edycja kontaktu",
                    body: (
                      <LocationsDetailsEditContactModal
                        isNew={false}
                        contact={contact}
                      />
                    ),
                  })
                }
              >
                Edytuj
              </Button>
            )}
        </Row>

        <Table bordered>
          <tbody>
            <tr>
              <td className="fixed-values-table">Typ</td>
              <td className="fixed-values-table" colSpan="2">
                Imię i nazwisko
              </td>
            </tr>
            <tr>
              <td>{contact.type}</td>
              <td colSpan="2">{contact.name}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Mail</td>
              <td className="fixed-values-table">Telefon</td>
              <td className="fixed-values-table">Fax</td>
            </tr>
            <tr>
              <td>{contact.mail}</td>
              <td>{contact.phone}</td>
              <td>{contact.fax}</td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Notatka
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="keep-br">
                {contact.note}
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    )
  }
  return (
    <Table>
      <thead>
        <tr>
          <th colSpan="6">
            <Row>
              <Col>Kontakty lokalizacji</Col>
              <Col>
                {props.filter !== "archiwum" && (
                  <Button
                    variant="light"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        header: "Kontakty archiwalne",
                        body: (
                          <LocationsDetailsContactsTable filter="archiwum" />
                        ),
                      })
                    }
                  >
                    Archiwum
                  </Button>
                )}
              </Col>
              {location.state === "aktywna" && (
                <Col>
                  <Row>
                    <Button
                      variant="secondary"
                      className="ml-auto mr-3"
                      onClick={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          xl: true,
                          hideFooter: true,
                          header: "Edycja kontaktu",
                          body: (
                            <LocationsDetailsEditContactModal isNew={true} />
                          ),
                        })
                      }
                    >
                      Dodaj
                    </Button>
                  </Row>
                </Col>
              )}
            </Row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="fixed-values-table">Imię i nazwisko</td>
          <td className="fixed-values-table">Typ</td>
          <td className="fixed-values-table">Telefon</td>
          <td className="fixed-values-table">Mail</td>
          <td className="fixed-values-table">Fax</td>
          <td className="fixed-values-table">Notatka</td>
        </tr>
        {location.contacts.sort(sortByCreationDate).map((contact) => {
          if (contact.state === props.filter) {
            return (
              <tr
                key={`contacts-table-${contact._id}`}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    header: "Szczegóły kontaktu",
                    body: renderContactDetails(contact),
                  })
                }
              >
                <td>{contact.name}</td>
                <td>{contact.type}</td>
                <td>{contact.phone}</td>
                <td>{contact.mail}</td>
                <td>{contact.fax}</td>
                <td>{contact.note}</td>
              </tr>
            )
          } else return null
        })}
      </tbody>
    </Table>
  )
}
export default LocationsDetailsContactsTable
