import React from "react"
import { Container, Button } from "react-bootstrap"

import { SewageReportv2DownloadReportModal } from "../downloadReportModal"

const SewageReportv2TableRender = ({ reportsList, setModalData }) => {
  return (
    <Container className="mt-5">
      <h4>
        Lista raportów{" "}
        <Button
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              body: (
                <>
                  Podczas generowania plik otrzymuje nazwę zgodnie ze schematem:{" "}
                  <br />
                  SKN-ODD-ROK-MM
                  <br />
                  gdzie:
                  <br />
                  SKN - stałe oznaczenie "Sprawozdanie Kwartalne Nieczystości",{" "}
                  <br />
                  ODD - oznaczenie oddziału, <br />
                  ROK-MM - rok i <u>ostatni miesiąc</u> kwartału
                  <br />W przypadku duplikatów program automatycznie dodaje na
                  końcu datę i czas wygenerowania.
                  <p>
                    <b>
                      Pliki są trwale i bezpiecznie przechowywane "w chmurze" -
                      nie ma potrzeby zapisywania ich na stałe na swoim
                      komputerze
                    </b>
                    <br />
                    Sprawozdania wygenerowane z poprzedniego modułu pojawią się
                    na końcu listy, z zachowanym poprzednim schematem tworzenia
                    nazw.
                  </p>
                </>
              ),
            })
          }
        >
          ?
        </Button>
      </h4>
      {reportsList.map((report) => (
        <React.Fragment key={`reportLink-${report.ETag}`}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a
            href="#"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "Pobieranie sprawozdania",
                body: <SewageReportv2DownloadReportModal s3Key={report.Key} />,
              })
            }
          >
            {report.Key}
          </a>
          <br />
        </React.Fragment>
      ))}
    </Container>
  )
}
export { SewageReportv2TableRender }
