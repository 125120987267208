import React, { useMemo, useState } from "react"
import { Redirect } from "react-router-dom"
import { Table, Container, Row, Button } from "react-bootstrap"
import { useTable, usePagination, useSortBy } from "react-table"
import { MdArrowBack, MdArrowForward } from "react-icons/md"

import { dateParse } from "../../utils/formats"
import { isBefore } from "date-fns"

const SewagePermissionsRender = (props) => {
  const [redirect, setRedirect] = useState()
  const data = useMemo(() => props.permissions, [props])
  const columns = useMemo(
    () => [
      { Header: "Rodzaj", accessor: "purpose" },
      { Header: "Gmina", accessor: "municipal" },
      {
        Header: "Ważne do",
        accessor: "deadline",

        Cell: (table) => {
          return dateParse(table.row.original.deadline, false)
        },
      },
      { Header: "Oddział", accessor: "branch" },
      { Header: "Status", accessor: "state" },
    ],
    []
  )

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: [{ id: "deadline", desc: false }],
      },
    },
    useSortBy,
    usePagination
  )

  return (
    <Container>
      {redirect ? (
        <Redirect
          to={{
            pathname: `/SewagePermissionDetails/${redirect}`,
            state: {
              tableParams: props.tableParams.tableParams,
            },
          }}
        />
      ) : null}
      <Table bordered className="mt-4">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                  className="pretty-table-header"
                >
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)

            let stateColor = isBefore(new Date(), new Date(row.values.deadline))
              ? "#9bf3ab"
              : "#ef5350"

            return (
              <tr
                {...row.getRowProps()}
                onClick={() => setRedirect(row.original._id)}
                className="transaction-details-tr"
              >
                {row.cells.map((cell) => {
                  if (cell.column.id === "deadline") {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ backgroundColor: stateColor }}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="pretty-table-data"
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row className="justify-content-end align-items-center">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
          // style={{ maxHeight: "30px" }}
        >
          <MdArrowBack />
        </Button>
        <p>
          <b>{pageIndex + 1}</b> z {pageOptions.length}
        </p>
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
        >
          <MdArrowForward />
        </Button>
        {/* below element giving possibility to go to certain page, for now I dont need it */}
        {/* <div>Idź do:</div>
            <input 
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
      </Row>
      <Row className="justify-content-end mt-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          className="mr-4"
        >
          {
            // setting pagination options - how many rows can user display
            [5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Pokaż {pageSize}
              </option>
            ))
          }
        </select>
      </Row>
    </Container>
  )
}
export default SewagePermissionsRender
