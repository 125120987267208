import React, { useState, useContext, useEffect } from "react"
import UserContext from "../../contexts/userContext"

import { Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"

import ConsumptionsReportForm from "./Form"
import ConsumptionsReportRender from "./Render"

const ConsumptionsReportFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [reportData, setReportData] = useState() // array of goods with summed quantities
  const [additionalInfo, setAdditionalInfo] = useState() // array of objects used to create table with additional infos
  const [consumptionsIds, setConsumptionsIds] = useState() //used to change fetched consumptions as "zapisane" to bloc editing
  const [formDataLoading, setFormDataLoading] = useState(true)
  const [employees, setEmployees] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [date] = useState(() => {
    const today = new Date()
    const year = today.getFullYear()
    if (today.getMonth() === 0 && today.getDate() < 10) {
      return { month: 12, year: today.getFullYear() - 1 }
    } else if (today.getDate() > 10) {
      return { year: year, month: today.getMonth() + 1 }
    } else {
      return { year: year, month: today.getMonth() }
    }
  })

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const res = await fetch("/employees/all", {
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        setEmployees(resJSON)
        setFormDataLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchEmployees()
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      if (
        Number.parseInt(e.target.month.value) >
        Number.parseInt(e.target.month2.value)
      )
        return setModalData({
          show: true,
          type: "info",
          body: `Miesiac OD musi być mniejszy lub równy miesiącowi DO`,
        })

      const dataToFetch = {
        year: e.target.year.value,
        month: e.target.month.value,
        month2: e.target.month2.value,
        branch: e.target.branch.value === "all" ? "all" : e.target.branch.value,
        driver: e.target.driver.value === "all" ? "all" : e.target.driver.value,
      }

      const res = await fetch(
        `/consumptions/getFiltered/?month=${dataToFetch.month}&month2=${dataToFetch.month2}&year=${dataToFetch.year}&driver=${dataToFetch.driver}&branch=${dataToFetch.branch}&consumptionsVer=2`,
        {
          method: "GET",
        }
      )
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res

      const rawData = await res.json()

      // create final report data with summed goods consumptions:
      //!warning! I take good properties (like divider or price) from first occurance, later I only reduce if _ids match, so if properties are different it won't be consistent!
      const reportDataArr = []
      rawData.forEach((consumption) => {
        // going through main goods, pushing good to reportDataArr if not present there, if present -> adding its quantity to quantity in reportDataArr:
        consumption.mainGoodsConsumptions.forEach((consumptionRow) => {
          consumptionRow.consumptions.forEach((goodConsumption) => {
            const goodInd = reportDataArr.findIndex(
              (good) => good._id === goodConsumption._id
            )

            //convert empty string to number and parse other properties:
            if (goodConsumption.quantity === "") goodConsumption.quantity = 0
            else
              goodConsumption.quantity = Number.parseFloat(
                goodConsumption.quantity
              )

            //if good not present in arr:
            if (goodInd === -1) {
              reportDataArr.push(goodConsumption)
            } else {
              reportDataArr[goodInd].quantity =
                Number.parseFloat(reportDataArr[goodInd].quantity) +
                Number.parseFloat(goodConsumption.quantity)
            }
          })
        })
        //add normal goods:
        consumption.normalGoodsConsumptions.forEach((goodConsumption) => {
          const goodInd = reportDataArr.findIndex(
            (good) => good._id === goodConsumption._id
          )

          //convert empty string to number:
          if (goodConsumption.quantity === "") goodConsumption.quantity = 0

          //if good not present in arr:
          if (goodInd === -1) {
            reportDataArr.push({
              _id: goodConsumption._id,
              name: goodConsumption.name,
              type: goodConsumption.type,
              quantity: Number.parseFloat(goodConsumption.quantity),
              unit: goodConsumption.unit,
              divider: Number.parseFloat(goodConsumption.divider),
              finalUnit: goodConsumption.finalUnit,
              price: goodConsumption.price,
              isWarehouseCost: goodConsumption.isWarehouseCost,
            })
          } else {
            reportDataArr[goodInd].quantity =
              Number.parseFloat(reportDataArr[goodInd].quantity) +
              Number.parseFloat(goodConsumption.quantity)
          }
        })
      })
      setReportData(reportDataArr)

      // prepare additional info:

      setAdditionalInfo(() =>
        rawData.map((consumption) => {
          return {
            branch: consumption.branch,
            driver: consumption.driver,
            user: consumption.user_id,
            additionalInfo: consumption.additionalInfo,
            dates: consumption.mainGoodsConsumptions
              .map((mainGoodConsumption) => mainGoodConsumption.date)
              .join(", "),
          }
        })
      )
      setConsumptionsIds(() => rawData.map((consumption) => consumption._id))

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd pobierania danych",
      })
    }
  }

  const handleStateChange = async () => {
    try {
      const res = await fetch("/consumptions/batchStateChange", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(consumptionsIds),
      })

      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res
      window.location.reload()
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd pobierania danych",
      })
    }
  }
  return (
    <Container>
      {formDataLoading ? (
        <p>Wczytuję dane formularza</p>
      ) : (
        <ConsumptionsReportForm
          date={date}
          user={user}
          employees={employees}
          handleSubmit={handleSubmit}
        />
      )}

      {isLoading ? null : (
        <ConsumptionsReportRender
          setModalData={setModalData}
          reportData={reportData}
          additionalInfo={additionalInfo}
          handleStateChange={handleStateChange}
        />
      )}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default ConsumptionsReportFetch
