import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Alert, Container, Form, Table, Button, Row } from "react-bootstrap"
import _ from "lodash"

const keysMap = [
  { name: "subtype", plName: "Podtyp" },
  { name: "char", plName: "Charakterystyka" },
  { name: "color", plName: "Kolor" },
  { name: "category", plName: "Kategoria" },
]

// I use location services to render form, but in job.services I have only choosen services, I have to recreate this array so RHF
//could set default values.
// in form I use checkbox to set service.locationServiceRef as _id if checked and false if unchecked
const createJobSrvArr = (services, job) => {
  // clone services for safety:
  let srvArr = [...services]

  // for delivery (added much later to edit) I use different logic:
  // for normal jobs I just allow to use eqp from location, but for delivery I allow to fetch
  // eqp from mongo -> I use filters and fetching in DeliveryEqpFilter
  // but to keep jobs equipment checked be default I have to create defaultValues bit different:
  if (job.type === "dostarczenie")
    return [
      {
        // on delivery there can be only one service:
        locationServiceRef: job.services[0].locationServiceRef,
        // eqp array is created not basing on location services and eqp like in normal job below
        // but directly from job equipment data:
        eqp: job.services[0].eqp.map((eqp) => ({
          ref: eqp.ref._id,
          qty: eqp.qty,
        })),
      },
    ]

  srvArr = srvArr.map((srv) => {
    // for each location service find matching job service, if not found return locationServiceRef = false
    const jobSrvInd = job.services.findIndex(
      (jobSrv) => jobSrv.locationServiceRef === srv._id
    )

    if (jobSrvInd === -1) return { locationServiceRef: false }
    // if job has this service - return desired object:
    else {
      // I need also to create equipment array from location service with qtys from job service:
      const srvEqpArr = srv.equipment.map((srvEqp) => {
        // find matching eqp in job to settle qty:
        const jobSrvEqpInd = job.services[jobSrvInd].eqp.findIndex(
          (jobSrvEqp) => jobSrvEqp.ref._id === srvEqp.ref._id
        )

        // when no matching eqp in job return not checked eqp (ref=false, like in services arr)
        if (jobSrvEqpInd === -1) return { ref: false }
        // if equipment found in job return object as needed for RHF: with eqp_id in ref and qty from job
        else
          return {
            ref: srvEqp.ref._id,
            qty: job.services[jobSrvInd].eqp[jobSrvEqpInd].qty,
          }
      })

      return {
        locationServiceRef: srv._id,
        eqp: srvEqpArr,
        // add subSrv array if srv can have subSrv
        subSrv:
          job.services[jobSrvInd].subSrv?.map((subSrv) => subSrv._id) || [],
      }
    }
  })

  return srvArr
}

const renderSrvAndEqp = (job, location, onlyServices = false) => {
  const renderSubSrv = (srv) => {
    if (!srv.subSrv?.length) return null
    else {
      // clone subSrv array as I will mutate it later
      const subSrvArr = _.cloneDeep(srv.subSrv)
      // check if there are any extraFees included, and push info to array to show it:

      // get location service:
      const locSrv = location.services.find(
        (locSrvEl) => locSrvEl._id === srv.locationServiceRef
      )

      // when there are any extra fees in location service...
      if (locSrv.extraFees?.length) {
        // ... check each jobs subSrv and add amount to it
        subSrvArr.forEach((jobSubSrv) => {
          // look for extra fee that locSrvRef matches this job srv locationServiceRef
          const locSubSrvExtraFee = locSrv.extraFees.find(
            (locSubSrvExtraFee) =>
              locSubSrvExtraFee.locSrvRef === srv.locationServiceRef &&
              locSubSrvExtraFee.srvRef === jobSubSrv._id
          )
          if (locSubSrvExtraFee)
            return (jobSubSrv.extraFee = locSubSrvExtraFee.amount)
        })
      }

      return (
        <>
          <tr>
            <td>
              <b>Usługi podrzędne:</b>
            </td>
          </tr>
          <tr>
            <td>
              {subSrvArr.map((subSrv) => (
                <span className="ml-3" key={`subSrvEl-${subSrv._id}`}>
                  {subSrv.name}{" "}
                  {subSrv.extraFee ? `(${subSrv.extraFee} zł za sztukę)` : null}
                  <br />
                </span>
              ))}
            </td>
          </tr>
        </>
      )
    }
  }
  const renderSrvRow = (srv) => {
    const locSrv = location.services.find(
      (locSrv) => locSrv._id === srv.locationServiceRef
    )
    return (
      <tr
        className="fixed-values-table"
        key={`srvTableSrvHead-${srv.locationServiceRef}`}
      >
        <h5 className="ml-4 mb-0">{`${locSrv.locSrvNo || "?"}. ${
          locSrv.name
        }`}</h5>
      </tr>
    )
  }
  if (job.services.length === 0)
    return (
      <Alert variant="danger">
        `NATYCHMIAST ZGŁOŚ BŁĄD! (brak usług dla zadania ${job._id})`
      </Alert>
    )

  const renderEqp = (srv) => {
    if (!srv.eqp?.length) return null
    else
      return (
        <>
          <tr>
            <td>
              <b>Sprzęt:</b>
            </td>
          </tr>
          <tr>
            <td>
              {srv.eqp.map((eqp, i) => (
                <span className="ml-3" key={`eqpLine-${eqp._id}-${i}`}>
                  {CreateEqpLine(eqp)}
                  <br />
                </span>
              ))}
            </td>
          </tr>
        </>
      )
  }

  return job.services.map((srv) => {
    return (
      <Table className="compact-table">
        {renderSrvRow(srv)}
        {onlyServices ? null : renderEqp(srv)}
        {onlyServices ? null : renderSubSrv(srv)}
      </Table>
    )
  })
}

const ChooseSrvAndEqp = ({
  register,
  control,
  Controller,
  location,
  watch,
  isFrozen,
  srvFrozen,
  eqpTypes,
  job,
  setValue,
  setModalData,
}) => {
  if (job.type === "dostarczenie") {
    return (
      <DeliveryEditEqpTableRender
        location={location}
        register={register}
        control={control}
        Controller={Controller}
        watch={watch}
        isFrozen={isFrozen}
        srvFrozen={srvFrozen}
        eqpTypes={eqpTypes}
        job={job}
        setValue={setValue}
        setModalData={setModalData}
      />
    )
  }
  // if job is frozen (it state was changed from "zaplanowane"||"zlecone" to any other) show only list of services and equipment
  // if not frozen show form
  if (isFrozen)
    return (
      <Container>
        <Alert variant="secondary">
          Zadanie zamrożone - zostało już wcześniej odhaczone. W celu zmiany
          usług i/lub sprzętu proszę o kontakt.
        </Alert>
        {srvFrozen.map((srv) => (
          <span key={`frozenSrv-${srv._id}`}>
            <b>
              {srv.locSrvNo || "?"}. {srv.name}
            </b>
            {srv.servedEqp.length ? (
              <ul>
                {srv.servedEqp.map((eqp) => (
                  <li key={`eqpLine-${eqp._id}`}>{CreateEqpLine(eqp)}</li>
                ))}
              </ul>
            ) : (
              "Brak sprzętu"
            )}
          </span>
        ))}
      </Container>
    )
  else
    return (
      <Container>
        {location.services.map((srv, i) => {
          if (srv.state === "aktywna")
            return (
              <span key={`servicesList-${srv._id}`}>
                <Form.Check
                  {...register(`services[${i}].locationServiceRef`)} // see job model
                  type="checkbox"
                  label={`${srv.locSrvNo || "?"}. ${srv.name}`}
                  value={srv._id} // see job model comments
                  id={`services-${srv._id}`}
                  key={`services-checkbox-${srv._id}-${i}`}
                />
                {watch(`services.${i}.locationServiceRef`) && (
                  <ChooseEqp
                    register={register}
                    control={control}
                    Controller={Controller}
                    service={srv}
                    arrInd={i}
                    watch={watch}
                    setValue={setValue}
                  />
                )}
                {watch(`services.${i}.locationServiceRef`) &&
                srv.canHaveSubSrv ? (
                  <Row>
                    <Table>
                      <ChooseSubSrv srv={srv} srvInd={i} register={register} />
                    </Table>
                  </Row>
                ) : null}
              </span>
            )
          else return null
        })}
      </Container>
    )
}
const ChooseEqp = ({
  register,
  control,
  Controller,
  service,
  arrInd,
  equipment,
  watch,
  setValue,
  isDelivery, // used to set max value validation for jobs other then delivery
}) => {
  let equipmentArr = []
  // when using for delivery I pass equipmentArr prop from DeliveryEqpFilter
  if (equipment) equipmentArr = equipment
  else equipmentArr = service.equipment
  if (equipmentArr.length === 0) return "Brak sprzętu dla usługi"
  else
    return (
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Typ/Nazwa</th>
            <th>Liczba</th>
            <th>Podtyp</th>
            <th>Char.</th>
            <th>Kolor</th>
            <th>Kategoria</th>
            <th>Wyposażenie</th>
            <th>Numer</th>
          </tr>
        </thead>
        <tbody>
          {equipmentArr.map((eqp, i) => {
            return (
              <tr key={`eqp-tr-${eqp.ref._id}-${i}`}>
                <td>
                  <Controller
                    control={control}
                    name={`services[${arrInd}].eqp[${i}].ref`}
                    render={({ field }) => (
                      <Form.Check
                        {...field}
                        type="checkbox"
                        checked={field.value}
                        id={`service-${service._id}-eqp-${eqp.ref._id}`}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            setValue(`services[${arrInd}].eqp[${i}].qty`, null)
                            // since RHF v7 I can't set checkbox value by prop, but I need eqp.i.ref to be _id
                            // so I set this value manually:
                            setValue(`services[${arrInd}].eqp[${i}].ref`, null)
                          } else {
                            setValue(
                              `services[${arrInd}].eqp[${i}].qty`,
                              eqp.qty || null
                            )
                            setValue(
                              `services[${arrInd}].eqp[${i}].ref`,
                              eqp.ref._id
                            )
                          }
                        }}
                      />
                    )}
                  />
                </td>
                <td>{eqp.ref.type}</td>
                <td>
                  {eqp.ref.isTemplate || eqp.ref.number === 0 ? (
                    <Form.Control
                      {...register(`services[${arrInd}].eqp[${i}].qty`)}
                      as="input"
                      type="number"
                      max={isDelivery ? null : eqp.qty}
                      min="0"
                      className="small-number-input"
                      autoComplete="chrome-off"
                      disabled={
                        watch(`services[${arrInd}].eqp[${i}].ref`)
                          ? false
                          : true
                      }
                    />
                  ) : (
                    "nd"
                  )}
                </td>
                <td>{eqp.ref.subtype}</td>
                <td>{eqp.ref.char}</td>
                <td>{eqp.ref.color}</td>
                <td>{eqp.ref.category}</td>
                <td>{eqp.ref.mods}</td>
                <td>{eqp.ref.number}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
}

const CreateEqpLine = (eqp, showCat = true) => {
  const eqpLine = [`${eqp.qty} x ${eqp.ref.type}`]
  keysMap.forEach((key) => {
    // don't show "bez znaczenia" and category when showCat set to false (for delivery confirmation)
    if (
      eqp.ref[key.name] &&
      !(
        eqp.ref[key.name] === "bez znaczenia" ||
        (key.name === "category" && !showCat)
      )
    )
      eqpLine.push(eqp.ref[key.name])
  })
  if (eqp.ref.isTemplate) eqpLine.push("TMPL.")
  else if (eqp.ref.number) eqpLine.push(`nr ${eqp.ref.number}`)
  if (eqp.ref.mods?.length) eqpLine.push(eqp.ref.mods.join(", "))

  return eqpLine.filter((el) => el).join(", ")
}

const DeliveryEditEqpTableRender = ({
  register,
  location,
  watch,
  setValue,
  control,
  Controller,
  isFrozen,
  srvFrozen,
  eqpTypes,
  job,
  setModalData,
}) => {
  if (job?.services.length !== 1)
    return setModalData({
      show: true,
      type: "alert",
      body: "Błąd programu: dostarczenie powinno dotyczyć tylko jednej usługi. Zgłoś błąd podając nazwę lokalizacji i usługi.",
    })

  // get service details from location services:
  const serviceChoosen = location.services.find(
    (locSrv) => locSrv._id === job.services[0].locationServiceRef
  )
  return (
    <Container>
      wybrana usługa: <b>{serviceChoosen.name}</b> (nie ma możliwości zmiany
      usługi przy edycji dostarczenia)
      <DeliveryEqpFilter
        parentRegister={register}
        parentWatch={watch}
        parentSetValue={setValue}
        parentControl={control}
        Controller={Controller}
        eqpTypes={eqpTypes}
        setModalData={setModalData}
        job={job}
        location={location}
      />
    </Container>
  )
}
const DeliveryEqpFilter = ({
  parentRegister,
  parentWatch,
  parentSetValue,
  parentControl,
  Controller,
  eqpTypes,
  job,
  location,
}) => {
  const [equipment, setEquipment] = useState(job.services[0].eqp)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchError, setFetchError] = useState(null)
  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      type: job.services[0].serviceRef.eqpTypes[0],
      isTemplate: "true",
    },
  })

  const fetchEqp = async (formData) => {
    try {
      // get checked equipment to preserve it later:
      const serviceEqpArr = parentWatch("services[0].eqp")
      const checked_ids = serviceEqpArr.filter((eqp) => eqp.ref)

      const checkedEqp = equipment.filter((eqp) =>
        checked_ids.find((checkedEqp) => checkedEqp.ref === eqp.ref._id)
      )

      const res = await fetch(`/equipment/getFiltered`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
      if (res.status === 403) return setFetchError("Brak uprawnień")
      if (res.status !== 200) throw res

      let eqpReady = await res.json()

      // remove from fetch result equipment that is already checked
      checkedEqp.forEach((checkedEqpEl) => {
        const matchingEqpInd = eqpReady.findIndex(
          (resEqp) => resEqp._id === checkedEqpEl.ref._id
        )

        if (matchingEqpInd !== -1) eqpReady.splice(matchingEqpInd, 1)
      })

      // equipment table (ChooseEqp) uses different structure, I have to adjust fetch result:
      eqpReady = eqpReady.map((eqp) => ({ ref: eqp }))

      // current job eqp MUST be at beginning of array (to keep it checked by parent form defaultValues)
      eqpReady = [...checkedEqp, ...eqpReady]

      setEquipment(eqpReady)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setFetchError("Błąd pobierania danych")
    }
  }

  const renderSelects = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))
    // most of equipment props are one-choose-only
    // this func creates select for each of such field

    if (eqpSelected) {
      //  using 'global' keysMap array create select field for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name]?.length) return null
        else {
          return (
            <tr key={`equipment-form-${key.name}`}>
              <td className="fixed-values-table">
                <Form.Check
                  {...register(`${key.name}.apply`)}
                  type="checkbox"
                  label={key.plName}
                  id={`${key.name}.apply`}
                  onClick={(e) => {
                    if (!e.target.checked) setValue(`${key.name}.value`, null)
                  }}
                />
              </td>
              <td>
                <Form.Control
                  key={`${eqpSelected.type}-${key.name}-select`}
                  {...register(`${key.name}.value`)}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={!watch(`${key.name}.apply`)}
                  defaultValue="null"
                >
                  <option value="null" hidden></option>
                  {eqpSelected[key.name].map((el) => {
                    // dont show "dowolny/a" - it is handled by checkbox above when template or don't apply in other case
                    if (el === "bez znaczenia") return null
                    else
                      return (
                        <option
                          key={`${eqpSelected.type}-${key.name}-${el}-select`}
                          value={el}
                        >
                          {el}
                        </option>
                      )
                  })}
                </Form.Control>
              </td>
            </tr>
          )
        }
      })

      return formArr.filter((el) => el)
    } else return null
  }

  const renderCheckboxes = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))

    if (eqpSelected) {
      // most of equipment props are multiple-choose

      // firts create map of avaliable fields:
      const keysMap = [{ name: "mods", plName: "Wyposażenie" }]

      //  create checkboxes for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name]?.length) return null
        else
          return (
            <tr key={`${key.name}-row`}>
              <td className="fixed-values-table">{key.plName}</td>
              <td>
                {eqpSelected[key.name].map((el) => (
                  <Form.Check
                    inline
                    key={`${key.name}-${el}-checkbox`}
                    {...register(`mods`)}
                    type="checkbox"
                    value={el}
                    label={el}
                    id={el}
                  />
                ))}
              </td>
            </tr>
          )
      })

      return formArr.filter((el) => el)
    } else return null
  }

  return (
    <>
      Wybierz parametry sprzętu:
      <Table bordered>
        <tbody>
          <tr>
            <td onClick={() => setValue("isTemplate", "true")}>
              <Form.Check
                {...register("isTemplate")}
                type="radio"
                value={true}
                label="Templatka"
                id="isTemplate-true"
                inline
                className="mr-auto"
              />
            </td>
            <td onClick={() => setValue("isTemplate", "false")}>
              <Form.Check
                {...register("isTemplate")}
                type="radio"
                value={false}
                label="Realny sprzęt"
                id="isTemplate-false"
                inline
                className="mr-auto"
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <tbody>
          {watch("isTemplate") === "false" && (
            <tr>
              <td>Numer</td>
              <td>
                <Form.Control
                  {...register("number")}
                  as="input"
                  type="text"
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          )}
          <tr>
            <td className="fixed-values-table">Typ:</td>
            <td>
              <Form.Control
                {...register("type")}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
              >
                {job.services[0].serviceRef.eqpTypes.map((eqp) => {
                  return <option key={`selectEqpType-${eqp}`}>{eqp}</option>
                })}
              </Form.Control>
            </td>
          </tr>
          {renderSelects()}

          {renderCheckboxes()}
        </tbody>
      </Table>
      {/* {fetchError && <Alert variant="danger">{fetchError}</Alert>} */}
      <Row>
        <Button className="ml-auto mr-5 mb-3" onClick={handleSubmit(fetchEqp)}>
          Pobierz
        </Button>
      </Row>
      {fetchError && <Alert variant="danger">{fetchError}</Alert>}
      {isLoading ? (
        "Pobieram dane"
      ) : (
        <ChooseEqp
          register={parentRegister}
          control={parentControl}
          Controller={Controller}
          equipment={equipment}
          service={location.services.find(
            (locSrv) => locSrv._id === job.services[0].locationServiceRef
          )}
          arrInd={0}
          watch={parentWatch}
          setValue={parentSetValue}
          isDelivery={true}
        />
      )}
    </>
  )
}

const ChooseSubSrv = ({ srv, srvInd, register }) => {
  if (!srv.subSrv?.length) return null
  else
    return (
      <tbody>
        <tr className="fixed-values-table">
          <td colSpan="9">
            <h6>Usługi podrzędne:</h6>
          </td>
        </tr>
        {srv.subSrv.map((subSrv) => {
          return (
            <tr>
              <td colSpan="9">
                <Form.Check
                  {...register(`services.${srvInd}.subSrv`)}
                  type="checkbox"
                  label={subSrv.name}
                  value={subSrv._id}
                  id={`subSrvCheckbox-${subSrv._id}`}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    )
}

export {
  renderSrvAndEqp,
  ChooseSrvAndEqp,
  createJobSrvArr,
  CreateEqpLine,
  ChooseSubSrv,
}
