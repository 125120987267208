import React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"

import { CarWorkEdit } from "./edit"

const CarsWorksForm = ({
  setModalData,
  carsOptions,
  allowedCars,
  filters,
  setFilters,
  refresh,
}) => {
  // prepare default choosen cars (all cars from all groups)

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: filters,
  })

  return (
    <Form onSubmit={handleSubmit(setFilters)}>
      <Row>
        <Col>
          OD:
          <Form.Control
            {...register("startDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>

        <Col>
          DO:
          <Form.Control
            {...register("endDate")}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
          />
        </Col>
        <Col xs="auto">
          Typ:
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange } }) => (
              <Select
                options={[
                  { value: "naprawa", label: "naprawa" },
                  { value: "przegląd", label: "przegląd" },
                  { value: "usprawnienie", label: "usprawnienie" },
                  { value: "inne", label: "inne" },
                ]}
                placeholder="wybierz"
                onChange={onChange}
                isMulti={true}
                value={watch("type")}
              />
            )}
          />
        </Col>

        <Col xs="auto">
          Samochody:
          <Controller
            control={control}
            name="cars"
            render={({ field: { onChange } }) => (
              <Select
                options={carsOptions}
                placeholder={renderCarsSelectPlaceholder(
                  filters.cars?.length,
                  watch("cars").length
                )}
                onChange={onChange}
                isMulti={true}
                value={watch("cars")}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                controlShouldRenderValue={false}
              />
            )}
          />
        </Col>

        <Col>
          <Button type="submit">Szukaj</Button>
        </Col>
        <Col>
          <Button
            className="ml-auto mr-4"
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                hideFooter: true,
                body: (
                  <CarWorkEdit
                    setModalData={setModalData}
                    allowedCars={allowedCars}
                    isNew={true}
                    refresh={refresh}
                  />
                ),
              })
            }
          >
            Dodaj
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const renderCarsSelectPlaceholder = (carsCount, selectedCount) => {
  return `wybrano ${selectedCount} z ${carsCount}`
}
export { CarsWorksForm }
