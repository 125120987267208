import React from "react"
import { Form, Button, Row } from "react-bootstrap"

const RLineSellReportForm = (props) => {
  return (
    <Row className="justify-content-center mt-2" id="RLineSellReportForm">
      <Form onSubmit={(e) => props.handleSubmit(e)} inline>
        <Form.Label>
          Od:
          <Form.Control
            className="ml-1" // separator from label
            type="date"
            name="startDate"
            defaultValue={props.formParams.defaultDates.startDate}
          />
        </Form.Label>
        <Form.Label>
          Do:
          <Form.Control
            className="ml-1" // separator from label
            type="date"
            name="endDate"
            defaultValue={props.formParams.defaultDates.endDate}
          />
        </Form.Label>
        <Form.Label>
          Firma
          <Form.Control as="select" name="seller">
            <option value="all">wszystkie</option>
            <option value="Ecoloxx">Ecoloxx</option>
            <option value="Clipper">Clipper</option>
          </Form.Control>
        </Form.Label>
        <Form.Label>
          Oddział
          <Form.Control as="select" name="branch">
            <option value="all">wszystkie</option>
            {props.formParams.branches.map((branch) => {
              return (
                <option value={branch} key={`branch-select-${branch}`}>
                  {branch}
                </option>
              )
            })}
          </Form.Control>
        </Form.Label>
        <Form.Label>
          Pracownik
          <Form.Control as="select" name="employee">
            <option value="all">wszyscy</option>
            {props.formParams.employees.map((employee) => {
              return (
                <option
                  value={employee._id}
                  key={`employee-select-${employee.name}`}
                >
                  {employee.fullName}
                </option>
              )
            })}
          </Form.Control>
        </Form.Label>
        <Button type="submit">Generuj</Button>
      </Form>
    </Row>
  )
}
export default RLineSellReportForm
