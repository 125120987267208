import React from "react"
import { Modal } from "react-bootstrap"

const ErrorsDetailsRender = (props) => {
  const stackKeys = []
  for (let key in props.error.err) stackKeys.push(key)

  return (
    <Modal.Body>
      <p>
        path:
        <br />
        {props.error.path}
      </p>
      {stackKeys.map((key) => (
        <p>
          {key}:
          <br />
          {props.error.err[key]}
        </p>
      ))}
    </Modal.Body>
  )
}
export default ErrorsDetailsRender
