import React from "react"
import { Table } from "react-bootstrap"
import _ from "lodash"
import { format } from "date-fns"

export class InvListPrintList extends React.Component {
  render() {
    const { invoices } = this.props
    const grossSum = invoices?.invoices?.reduce((acc, inv) => {
      return (
        acc +
        inv.InvoiceDetails.productsResults.reduce(
          (invAcc, prod) =>
            invAcc + prod.productQuantity * prod.productInvoicePrice,
          0
        )
      )
    }, 0)
    return (
      <span>
        <Table>
          <thead>
            <tr>
              <th>Data faktury</th>
              <th>Klient</th>
              <th>Usługi</th>
              <th>Wartość netto</th>

              <th>Wyeksportowana</th>
            </tr>
          </thead>
          <tbody>
            {invoices?.invoices?.map((inv) => (
              <tr key={`invPrintTable-${inv._id}`}>
                <td>
                  {format(
                    new Date(inv.InvoiceDetails.InvoiceDate),
                    "yyyy-MM-dd"
                  )}
                </td>
                <td>{inv.clientResult?.ref.shortName || "Błąd danych"}</td>
                <td>
                  {inv.InvoiceDetails.productsResults
                    .map((prod) => prod.ref?.name)
                    .join(", ")}
                </td>
                <td>
                  {
                    inv.InvoiceDetails?.payments?.InvoiceCurrency
                      ?.InvoiceProductsCost
                  }
                </td>
                <td>{inv.exportDates.length ? "tak" : "nie"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Table>
          <tbody>
            <tr>
              <td>Liczba:</td>
              <td style={{ fontWeight: "bold" }}>
                {invoices?.invoices?.length}
              </td>
              <td>Suma netto:</td>
              <td style={{ fontWeight: "bold" }}>
                {_.round(
                  invoices?.invoices?.reduce(
                    (acc, inv) =>
                      acc +
                      inv.InvoiceDetails.payments.InvoiceBaseCurrency
                        .InvoiceProductsCost,
                    0
                  ),
                  2
                )}
              </td>
              <td>Suma brutto</td>
              <td style={{ fontWeight: "bold" }}>{_.round(grossSum, 2)}</td>
            </tr>
          </tbody>
        </Table>
      </span>
    )
  }
}
