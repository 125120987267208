import { subDays, format } from "date-fns"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"

import { CarsWorksForm } from "./form"
import { CarsWorksTable } from "./table"
import { fetchAllowedCars, fetchCarsWorks } from "./utils"

const CarsWorksFetch = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState({ show: false })
  const [filters, setFilters] = useState({
    startDate: format(subDays(new Date(), 30), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    type: [
      { value: "naprawa", label: "naprawa" },
      { value: "przegląd", label: "przegląd" },
      { value: "modyfikacja", label: "modyfikacja" },
      { value: "inne", label: "inne" },
    ],
  })
  const [allowedCars, setAllowedCars] = useState([])
  const [carsOptions, setCarsOptions] = useState([])
  const [isFormLoading, setIsFormLoading] = useState(true)
  const [carsWorks, setCarsWorks] = useState([])
  const [refreshCounter, setRefreshCounter] = useState(0)

  //* initial fetch of allowed cars (used in form and on add/edit)
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        await fetchAllowedCars(
          setModalData,
          setAllowedCars,
          setCarsOptions,
          filters,
          setFilters
        )
        setIsFormLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          header: "Błąd pobierania danych formularza",
          body: err.message || "nieokreślony błąd",
        })
      }
    }
    fetchFormData()
    // this is only initial fetch, no need to do it on each filters change, so:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //* get cars works - initial and on refresh counter change
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const carsWorksRes = await fetchCarsWorks(filters)
        setCarsWorks(carsWorksRes)
        setIsLoading(false)
        setModalData({ show: false })
      } catch (err) {
        console.log(err)

        setModalData({
          show: true,
          type: "alert",
          header: "Błąd pobierania danych formularza",
          body: err.message || "nieokreślony błąd",
        })
      }
    }

    // get it only when cars are in filters obj (cars are added on initial data fetch, I just prevent fetching works before I know cars):
    if (filters.cars) fetchData()
  }, [filters, refreshCounter])

  //* func to force refresh:
  const refresh = () => setRefreshCounter(refreshCounter + 1)

  return (
    <Container fluid>
      {isFormLoading ? (
        renderSpinner("pobieram dane formularza...")
      ) : (
        <CarsWorksForm
          setModalData={setModalData}
          carsOptions={carsOptions}
          allowedCars={allowedCars}
          filters={filters}
          setFilters={setFilters}
          refresh={refresh}
        />
      )}
      {isLoading ? (
        renderSpinner()
      ) : (
        <CarsWorksTable
          carsWorks={carsWorks}
          setModalData={setModalData}
          allowedCars={allowedCars}
          refresh={refresh}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export { CarsWorksFetch }
