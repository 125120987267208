import React from "react"

import { Table } from "react-bootstrap"

import { dateParse } from "../../utils/formats"

const PermissionHeaderTable = (props) => {
  return (
    <Table bordered>
      <tbody>
        <tr>
          <td className="bg-primaryLight">Data utworzenia</td>
          <td>{dateParse(props.permission.createdAt)}</td>

          <td className="bg-primaryLight">Data ostatniej edycji</td>
          <td>{dateParse(props.permission.updatedAt)}</td>

          <td className="bg-primaryLight">Status</td>
          <td>{props.permission.state}</td>
          <td className="bg-primaryLight">Sprawozdania</td>
          <td>
            {props.permission.reports === "1" ||
            props.permission.reports === true
              ? "tak"
              : "nie"}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export { PermissionHeaderTable }
