import React, { useState } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

import clippy from "./clippy.jfif"

import JobsFixturesAddFetch from "./JobsFixuresAdd/JobsFixturesAddFetch"

const LogisticsUtilsNav = () => {
  const [utilChoosen, setUtilChoosen] = useState(null)

  const renderUtil = () => {
    if (utilChoosen === "fixtures")
      return <JobsFixturesAddFetch goBack={() => setUtilChoosen(null)} />
  }
  return (
    <Container fluid className="px-3 mt-1">
      {utilChoosen ? (
        renderUtil()
      ) : (
        <Row className="mt-4">
          <Col xs="4">
            <img src={clippy} alt="" style={{ maxWidth: "100%" }} />
          </Col>
          <Col>
            <Row className="my-1">
              {/* <Button onClick={() => setUtilChoosen("fixtures")}>
                Generuj fixtures
              </Button> */}
            </Row>
            <Row className="my-1">
              <Button>Raport miesięczny dla klienta</Button>
            </Row>
            <Row className="my-1">
              <Button>Raport miesięczny dla kierowcy/oddziału</Button>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  )
}
export default LogisticsUtilsNav
