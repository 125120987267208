import { useMemo } from "react"
import { useTable } from "react-table"

import { Container, Table } from "react-bootstrap"

import { hundrethComma } from "../../utils/formats"

const LocationsByDistanceRender = ({ locations, configs }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Klient",
        id: "customer",
        accessor: (location) => {
          return (
            location.customerData.shortName ||
            location.customerData.name +
              " " +
              (location.customerData.surname || "")
          )
        },
      },
      { Header: "Lokalizacja", accessor: "name" },
      { Header: "Ulica", accessor: "street" },
      { Header: "Miasto", accessor: "city" },
      {
        Header: "Dystans",
        accessor: "distance",
        Cell: ({ value }) => hundrethComma(value / 1000),
      },
      {
        Header: "Usługi",
        id: "services",
        accessor: (location) => {
          return <ul style={{ padding: 0 }}>{createServicesList(location)}</ul>
        },
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: locations })

  return (
    <Container fluid>
      <hr />
      <Table {...getTableProps()}>
        <thead style={{ whiteSpace: "nowrap" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="clickable"
                onClick={() =>
                  window.open("/Locations/" + row.original._id, "_blank")
                }
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const createServicesList = (location) =>
  location.services
    .map((srv) => {
      if (srv.state === "aktywna") {
        return (
          <li>{`${srv.name} - ${srv.chargeType}: ${srv.netPrice} zł (
            ${srv.params[srv.params.length - 1].qty} szt.)`}</li>
        )
      } else return null
    })
    .filter((el) => el)

export { LocationsByDistanceRender }
