import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { Container } from "react-bootstrap"

import MyModal from "../../utils/Modals"
import { dateParse } from "../../utils/formats"

import SewagePermissionDetailsRender from "./Render"
import SewagePermissionDetailsEdit from "./Edit"

const SewagePermissionDetailsFetch = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [permission, setPermission] = useState()
  const [showEdit, setShowEdit] = useState(false)
  const [goBack, setGoBack] = useState()
  const [modalData, setModalData] = useState({ show: false })

  useEffect(() => {
    const fetchPermissionDetails = async () => {
      try {
        const res = await fetch(`/sewage/permission/${props.match.params._id}`)

        if (res.status === 403) {
          return setModalData({
            show: true,
            type: "info",
            body: "brak uprawnień",
          })
        }
        if (res.status !== 200) {
          throw res
        }
        const resJSON = await res.json()

        resJSON.permission.deadline = dateParse(
          resJSON.permission.deadline,
          false
        )

        setPermission(resJSON.permission)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          body: `Błąd pobierania danych, kod błędu: ${err.status}`,
          type: "alert",
        })
      }
    }

    if (props.match.params._id === "new") {
      setPermission({}) //to prevent 'undefined' errors
      setIsLoading(false)
      setShowEdit(true)
    } else fetchPermissionDetails()
  }, [props])

  const renderContent = () => {
    if (showEdit) {
      return (
        <SewagePermissionDetailsEdit
          permission={permission}
          setShowEdit={setShowEdit}
          setModalData={setModalData}
          setGoBack={setGoBack} // used to go back to table after deleting permission
          isNew={props.match.params._id === "new" ? true : false}
        />
      )
    } else
      return (
        <SewagePermissionDetailsRender
          permission={permission}
          setGoBack={setGoBack}
          setShowEdit={setShowEdit}
          setModalData={setModalData}
        />
      )
  }

  return (
    <Container>
      {goBack ? (
        <Redirect
          to={{
            pathname: "/SewagePermissions",
            state: { tableParams: props.location.state.tableParams },
          }}
        />
      ) : null}
      {isLoading ? null : renderContent()}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default SewagePermissionDetailsFetch
