import React, { useState, useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"
import {
  Container,
  Table,
  Button,
  Form,
  Row,
  Alert,
  Col,
} from "react-bootstrap"
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im"
import { Helmet } from "react-helmet"
import { ImNewTab } from "react-icons/im"

import { LogCtx } from "./Fetch"
import { checkMustHaveEqpJobs } from "../../utils/jobs"
import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"
import RoutesSettlingStateChangeModal from "./modals/StateChangeModal"

import { submitJobsBatchEdit } from "./submit"

import { ModifyJobDetailsModalFetch } from "./modals/ModifyJobDetailsModal"

const RoutesSettlingRender = () => {
  const { jobs, setModalData, user, refresh, branchSelected, configs } =
    useContext(LogCtx)

  const [isJobsReady, setIsJobsReady] = useState(false)
  const [filtersValues, setFiltersValues] = useState(null)
  const [jobsSelected, setJobsSelected] = useState([])
  // const [jobsErrors, setJobsErrors] = useState({})
  const [filters, setFilters] = useState({
    // when filtering by object (as in driver) I'm using whole object here in filter and then _.isEqual
    driver: "all",
    type: "all",
  }) // I'm using 'all' keyword to have uniq conditional to check if I should apply filter

  const [jobsToHandleFiltered, setJobsToHandleFiltered] = useState([])
  const [jobsHandledFiltered, setJobsHandledFiltered] = useState([])

  // set filters values available for this jobs:
  useEffect(() => {
    const values = {}
    const filterKeys = Object.keys(filters)
    filterKeys.forEach((key) => {
      const allValues = jobs.map((job) => job[key])

      values[key] = _.uniqWith(allValues, _.isEqual)
    })
    setFiltersValues(values)
  }, [jobs, filters])

  // apply filters:
  useEffect(() => {
    setIsJobsReady(false)

    //  create array of all jobs to use it later for filtering:
    const jobsFilteredArr = [...jobs]

    // remove jobs that don't fit filters:
    Object.keys(filters).forEach((key) => {
      // if key isn't "all" -> remove all jobs that don't match
      // if filters[key] is document (as in driver) I use other logic to select by _id:
      if (
        filters[key] !== "all" &&
        filters[key] !== "null" &&
        key === "driver"
      ) {
        _.remove(jobsFilteredArr, (job) => job[key]._id !== filters[key]._id)
      } else if (filters[key] === "null" && key === "driver") {
        _.remove(jobsFilteredArr, (job) => job.hasDriver || job.driver?._id)
      }

      // and if filters[key] is just string:
      else if (filters[key] !== "all") {
        _.remove(jobsFilteredArr, (job) => job[key] !== filters[key])
      }
    })

    // I want selected jobs to be always shown, so:
    //push selected jobs to filtered array even if they are not fullfilling filter conditions:
    if (_.difference(jobsSelected, jobsFilteredArr) !== 0)
      jobsFilteredArr.push(..._.difference(jobsSelected, jobsFilteredArr))

    setJobsHandledFiltered(jobsFilteredArr.filter(filterJobsHandled))
    setJobsToHandleFiltered(jobsFilteredArr.filter(filterJobsToHandle))

    setIsJobsReady(true)
  }, [filters, jobs, jobsSelected])

  const fetchErrorWrapper = (error) => {
    return setModalData({
      show: true,
      type: "alert",
      body: (
        <p
          // I add pre-wrap to show '\n' send from server:
          style={{ whiteSpace: "pre-wrap" }}
        >
          {error}
        </p>
      ),
    })
  }

  //* check if all jobs data are OK:
  //# all jobs should have driver
  const noDriverJobs = jobs.filter((job) => !job.hasDriver || !job.driver)
  //# there can't be job with service that mustHaveEqp and no eqp attached:
  const jobsMustHaveEqpCheck = checkMustHaveEqpJobs(jobs)

  //* render only when filters applied:
  if (!isJobsReady) return <p>Odświeżam...</p>
  else
    return (
      <Container fluid id="RoutesSettlingRender">
        <Helmet>
          <title>Odhaczanie</title>
        </Helmet>
        {noDriverJobs.length !== 0 && (
          <Alert variant="danger">
            Przed odhaczaniem przypisz kierowcę do zdań (kliknięcie otwiera
            lokalizację w nowej karcie):
            {noDriverJobs.map((job) => (
              <React.Fragment key={`jobsWithoutDriverList-${job._id}`}>
                <br />
                <Link
                  to={`/Locations/${job.location._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImNewTab />
                  {job.type} dla {job.location.customer?.shortName || "?"} -{" "}
                  {job.location.name}
                </Link>
              </React.Fragment>
            ))}
          </Alert>
        )}
        {jobsMustHaveEqpCheck.length !== 0 && (
          <Alert variant="danger">
            Znalazłem zadania z usługami "sprzętowymi", do których nie podpięto
            sprzętu:{" "}
            {jobsMustHaveEqpCheck
              .map(
                (job) =>
                  `${job.location.customer.shortName} - ${job.location.name}`
              )
              .join(", ")}
          </Alert>
        )}
        <hr />
        <Row
          className="justify-content-around bg-white"
          style={{ position: "sticky", top: "90px", zIndex: 10 }}
        >
          {/* FILTERS: */}
          <Form inline>
            <Col>
              <Form.Label>
                Typ:
                <Form.Control
                  name="type"
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  onChange={(e) => {
                    e.preventDefault()
                    const newFilters = { ...filters }
                    newFilters.type = e.target.value
                    setFilters(newFilters)
                  }}
                >
                  <option value="all">wszystkie</option>
                  {filtersValues.type.map((type) => (
                    <option key={`type-filter-${type}`} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </Form.Label>
            </Col>
            <Col>
              <Form.Label>
                Kierowca:
                <Form.Control
                  name="driver"
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  onChange={(e) => {
                    e.preventDefault()
                    const newFilters = { ...filters }
                    if (e.target.value === "all")
                      return setFilters({ ...newFilters, driver: "all" })

                    // when filtering for jobs without driver:
                    if (e.target.value === "null")
                      return setFilters({ ...newFilters, driver: "null" })
                    const driver = filtersValues.driver.find(
                      (el) => el._id === e.target.value
                    )
                    newFilters.driver = driver
                    setFilters(newFilters)
                  }}
                >
                  <option value="all">wszyscy</option>

                  {noDriverJobs.length ? (
                    <option value="null">BRAK</option>
                  ) : null}
                  {filtersValues.driver.map((driver) => {
                    if (driver?.branch[branchSelected])
                      return (
                        <option
                          key={`drivers-filter-${driver._id}`}
                          value={driver._id}
                        >
                          {driver.fullName}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>
              </Form.Label>
            </Col>
          </Form>

          <Col>
            <h6>
              Liczby <u>wyświetlonych</u> zadań:
            </h6>
          </Col>
          <Col>
            <Row>Do odhaczenia: {jobsToHandleFiltered.length}</Row>
            <Row>Odhaczonych: {jobsHandledFiltered.length}</Row>
          </Col>
          <Col>
            <Row>
              Suma: {jobsToHandleFiltered.length + jobsHandledFiltered.length}
            </Row>
          </Col>

          <Col>
            <div
              className="py-1 px-2 mb-1"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
            >
              Wykonane:{" "}
              <Button
                variant="secondary"
                className="mr-1"
                disabled={jobsMustHaveEqpCheck.length || noDriverJobs.length}
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "confirm",
                    body: "Oznaczyć wszystkie zaznaczone zadania jako wykonane?",
                    func: () =>
                      submitJobsBatchEdit(
                        {
                          jobs: jobsSelected.map((job) => ({
                            _id: job._id,
                            historyLength: job.history.length,
                          })),
                          modifications: {
                            state: { apply: true, newValue: "wykonane" },
                          },
                        },
                        fetchErrorWrapper,
                        setModalData,
                        refresh,
                        jobsSelected
                      ),
                  })
                }
              >
                <ImCheckboxChecked />
              </Button>
              <Button
                variant="secondary"
                disabled={jobsMustHaveEqpCheck.length || noDriverJobs.length}
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "confirm",
                    body: "Oznaczyć wszystkie niezaznaczone i widoczne zadania jako wykonane?",
                    func: () => {
                      return submitJobsBatchEdit(
                        {
                          jobs: _.difference(
                            jobsToHandleFiltered,
                            jobsSelected
                          ).map((job) => ({
                            _id: job._id,
                            historyLength: job.history.length,
                          })),
                          modifications: {
                            state: { apply: true, newValue: "wykonane" },
                          },
                        },
                        fetchErrorWrapper,
                        setModalData,
                        refresh,
                        _.difference(jobsToHandleFiltered, jobsSelected)
                      )
                    },
                  })
                }
              >
                <ImCheckboxUnchecked />
              </Button>
            </div>
          </Col>
          <Col>
            <div
              className="py-1 px-2 mb-1"
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "grey",
              }}
            >
              {/* open BATCH EDIT MODAL */}
              Edycja: {/* for selected jobs: */}
              <Button
                variant="secondary"
                className="mr-1"
                disabled={jobsMustHaveEqpCheck.length || noDriverJobs.length}
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    hideFooter: true,
                    header: "Odhaczanie zadania",
                    body: (
                      <RoutesSettlingStateChangeModal
                        jobs={jobsSelected}
                        user={user}
                        setModalData={setModalData}
                        refresh={refresh}
                        branchSelected={branchSelected}
                      />
                    ),
                  })
                }
              >
                <ImCheckboxChecked />
              </Button>
              {/* for unselected jobs: */}
              <Button
                variant="secondary"
                disabled={jobsMustHaveEqpCheck.length || noDriverJobs.length}
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    hideFooter: true,
                    header: "Odhaczanie zadania",
                    body: (
                      <RoutesSettlingStateChangeModal
                        jobs={_.difference(jobsToHandleFiltered, jobsSelected)}
                        user={user}
                        setModalData={setModalData}
                        refresh={refresh}
                        branchSelected={branchSelected}
                      />
                    ),
                  })
                }
              >
                <ImCheckboxUnchecked />
              </Button>
            </div>
          </Col>
        </Row>
        <Table>
          <thead style={{ position: "sticky", top: "140px", zIndex: 10 }}>
            <tr>
              {/* select/unselect all */}
              <th style={{ width: "50px" }}>
                <Form.Check
                  name="selectFiltered"
                  type="checkbox"
                  id="selectFiltered"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setJobsSelected(jobsToHandleFiltered)
                    } else setJobsSelected([])

                    //set all checkboxes in appropriate state:
                    jobsToHandleFiltered.forEach(
                      (jobFiltered) =>
                        (document.getElementById(
                          `selected-${jobFiltered._id}`
                        ).checked = e.target.checked)
                    )
                  }}
                />
              </th>
              <th>Typ</th>
              <th>Klient</th>
              <th>Lokalizacja</th>
              <th>Kierowca</th>
              <th>Adres</th>
              <th>Częst.</th>
            </tr>
          </thead>
          <tbody>
            {jobsToHandleFiltered && jobsToHandleFiltered.length ? (
              jobsToHandleFiltered.map((job) => (
                <tr
                  className="clickable"
                  style={{
                    backgroundColor:
                      jobsMustHaveEqpCheck.find(
                        (errJob) => errJob._id === job._id
                      ) || noDriverJobs.find((errJob) => errJob._id === job._id)
                        ? "#f6a3a2"
                        : null,
                  }}
                  key={`jobs-filtered-${job._id}`}
                  onClick={(e) => {
                    // for ux: set checkbox checked/unchecked when user clicks whole cell and update 'selected' state
                    if (
                      e.target.name !== "selected" &&
                      e.target.id === "jobCheckboxCell"
                    ) {
                      const checkbox = document.getElementById(
                        `selected-${job._id}`
                      )
                      if (checkbox.checked) {
                        checkbox.checked = false
                        setJobsSelected((jobsSelected) =>
                          jobsSelected.filter(
                            (jobSelected) => jobSelected._id !== job._id
                          )
                        )
                      } else {
                        checkbox.checked = true
                        setJobsSelected((jobsSelected) => [
                          ...jobsSelected,
                          job,
                        ])
                      }
                    }
                    // if clicked anywhere else then on checkbox (but only when this job has not eqp error - see alerts above):
                    if (
                      e.target.name !== "selected" &&
                      e.target.id !== "jobCheckboxCell" &&
                      !jobsMustHaveEqpCheck.find(
                        (errJob) => errJob._id === job._id
                      )
                    ) {
                      return setModalData({
                        show: true,
                        type: "info",
                        xl: true,
                        hideFooter: true,
                        header: "Modyfikacja zadania",
                        body: (
                          <ModifyJobDetailsModalFetch
                            job={job}
                            setModalData={setModalData}
                            refresh={refresh}
                          />
                        ),
                      })
                    }
                  }}
                >
                  <td
                    id={`jobCheckboxCell`}
                    style={{ borderRight: "0.5px dotted grey" }}
                  >
                    <Form.Check
                      name="selected"
                      type="checkbox"
                      value={true}
                      id={`selected-${job._id}`}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setJobsSelected((jobsSelected) => [
                            ...jobsSelected,
                            job,
                          ])
                        } else {
                          setJobsSelected((jobsSelected) =>
                            jobsSelected.filter(
                              (jobSelected) => jobSelected._id !== job._id
                            )
                          )
                        }
                      }}
                    />
                  </td>
                  <td>{job.type}</td>
                  <td>
                    {job.location?.customer?.shortName || (
                      <b>
                        POWAŻNY BŁĄD - BRAK KLIENTA LUB LOKALIZACJI. NIC NIE
                        RÓB, OD RAZU DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>
                    {job.location?.name || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>{job.driver?.fullName || "BŁĄD - brak kierowcy"}</td>
                  <td>
                    {job.location?.street || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}{" "}
                    {job.location?.city || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>{job.location.jobsFreq || "BŁĄD"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>brak zadań spełniających kryteria</td>
              </tr>
            )}
          </tbody>
        </Table>
        <hr />

        {/* JOBS HANDLED: */}
        <Table>
          <thead>
            <tr>
              <th>Typ</th>
              <th>Stan</th>
              <th>Klient</th>
              <th>Lokalizacja</th>
              <th>Kierowca</th>
              <th>Adres</th>
              <th>Miasto</th>
            </tr>
          </thead>
          <tbody>
            {jobsHandledFiltered && jobsHandledFiltered.length ? (
              jobsHandledFiltered.map((job) => (
                <tr
                  className="clickable"
                  key={`jobs-filtered-${job._id}`}
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Szczegóły zadania",
                      xl: true,
                      body: (
                        <JobDetailsModal
                          job={job}
                          user={user}
                          setModalData={setModalData}
                          refresh={refresh}
                          location={job.location}
                          configs={configs}
                        />
                      ),
                    })
                  }
                >
                  <td>{job.type}</td>
                  <td>{job.state}</td>
                  <td>
                    {job.location.customer.shortName || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>
                    {job.location.name || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI lub klienta, NIC NIE
                        RÓB, OD RAZU DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>{job.driver?.fullName || "BRAK - zgłoś błąd!"}</td>
                  <td>
                    {job.location.street || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                  <td>
                    {job.location.city || (
                      <b>
                        POWAŻNY BŁĄD - BRAK LOKALIZACJI, NIC NIE RÓB, OD RAZU
                        DZWOŃ DO MNIE!
                      </b>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>brak zadań spełniających kryteria</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    )
}

const filterJobsHandled = (job) => {
  let show = false
  switch (job.state) {
    case "wykonane":
    case "niewykonane (my)":
    case "niewykonane (klient)":
    case "odwołane (my)":
    case "odwołane (klient)":
      show = true
      break

    default:
      break
  }

  return show
}
const filterJobsToHandle = (job) => {
  let show = false
  switch (job.state) {
    case "zaplanowane":
    case "zlecone":
      show = true
      break

    default:
      break
  }

  return show
}

export default RoutesSettlingRender
