import React, { useEffect, useState, useContext } from "react"
import { Redirect } from "react-router-dom"

import UserContext from "../contexts/userContext"

import { Modal, Container, Row } from "react-bootstrap"

import MyModal from "../utils/Modals"

import TransactionsListFilters from "./List/Filters"
import TransactionsRender from "./transactionsRender"

const TransactionsFetch = () => {
  const user = useContext(UserContext)
  const [noPerm, setNoPerm] = useState()
  const [transactions, setTransactions] = useState()
  const [transactionsFiltered, setTransactionsFiltered] = useState()
  const [filterCrit, setFilterCrit] = useState("default")
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })

  useEffect(() => {
    const transactionsFetch = async () => {
      try {
        const res = await fetch("/transactions/all", {
          method: "GET",
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) {
          const resBody = await res.text()
          throw resBody
        }
        const resJSON = await res.json()

        return resJSON
      } catch (err) {
        console.log(err)
        const errModal = {
          type: "alert",
          body: "Błąd pobierania danych",
          show: true,
        }
        setModalData(errModal)
      }
    }

    const transactionsFetchAndPrepare = async () => {
      try {
        if (
          !user.perm.transactionsList.r &&
          !user.perm.transaction.w // if user has parmission to add transaction he should also see transactions, but only added by himself (which is taken care in backend)
        ) {
          setModalData({ header: "brak uprawnień", show: true, type: "alert" })
          return setNoPerm(true)
        }
        const transactions = await transactionsFetch()
        if (!transactions) return
        setTransactions(transactions)

        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          header: "błąd",
          body:
            "nietypowy błąd, dobrze zapamiętaj jak do niego doszło i skontaktuj się ze mną",
          show: true,
          type: "alert",
        })
      }
    }
    transactionsFetchAndPrepare()
  }, [
    user.type.admin,
    user.perm.transactionsList,
    user.name,
    user.perm.transaction.w,
  ])

  // on filter criteria change:
  useEffect(() => {
    //prevent 'undefined' error
    if (!transactions) return
    if (filterCrit === "all") return setTransactionsFiltered(transactions)
    else if (filterCrit === "default")
      setTransactionsFiltered(
        transactions.filter(
          (transaction) =>
            transaction.state === "oczekująca" ||
            transaction.state === "zaproformowana"
        )
      )
  }, [filterCrit, transactions])

  return (
    <Container fluid>
      {isLoading ? (
        <p>wczytuję</p>
      ) : (
        <Row>
          <TransactionsListFilters
            handleFilter={(e) => setFilterCrit(e)}
            filterCrit={filterCrit}
          />
          <TransactionsRender transactions={transactionsFiltered} />
        </Row>
      )}
      {/* prevent redirecting when modal is open */}
      {noPerm && !modalData.show ? <Redirect to="/" /> : null}
      <Modal
        show={modalData?.show}
        onHide={() => setModalData(false)}
        animation={false}
      >
        <MyModal setModalData={setModalData} modalData={modalData} />
      </Modal>
    </Container>
  )
}
export default TransactionsFetch
