const prepare = (
  data,
  dirtyFields,
  mainContactInd,
  mainAddressInd,
  customer
) => {
  // I'm sending only changed data. When it comes to customer I'm using simple mongoose.findByIdAndUpdate
  // but with address and contact, I'm using its _id to find appropriate document and update it
  const customerReady = {}
  const mainAddress =
    mainAddressInd !== -1
      ? { _id: customer.addresses[mainAddressInd]._id }
      : null
  const mainContact =
    mainContactInd !== -1
      ? { _id: customer.contacts[mainContactInd]._id }
      : null
  // used on backend to prevent stale-data-edit (edit edited data and overwriting changes)
  const historyLength = customer.history.length

  // go through dirty fields and create object with changed values:
  for (let key in dirtyFields) {
    // subdocuments (address and contact) are handeled further
    if (!(key === "mainAddress" || key === "mainContact")) {
      customerReady[key] = data[key]

      // if change in mainAddress -> clone all addresses and use mainAddressInd:
    } else if (key === "mainAddress") {
      for (let subKey in dirtyFields[key]) {
        // if postal changed -> clear input:
        if (subKey === "postal") {
          mainAddress[subKey] = [...data[key][subKey]]
            .filter((el) => {
              if (isNaN(el)) return false
              else return true
            })
            .join("")
          // else just update:
        } else mainAddress[subKey] = data[key][subKey]
      }

      // if change in mainContact -> clone all contacts and use mainContactInd:
    } else if (key === "mainContact") {
      for (let subKey in dirtyFields[key]) {
        mainContact[subKey] = data[key][subKey]
      }
    }
  }

  return {
    customer: customerReady,
    mainAddress: mainAddress,
    mainContact: mainContact,
    dirtyFields: dirtyFields,
    historyLength: historyLength,
  }
}

const validatePesel = (pesel) => {
  // pesel is not required so when not provided -> validation ok
  if (pesel.length === 0) return true
  const peselArr = [...pesel]
    .map((el) => {
      // clearing number:
      if (isNaN(el)) return null
      else return Number.parseInt(el)
    })
    .filter((el) => el !== null)
  // check length:
  if (peselArr.length !== 11) return false

  const controlArr = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1]
  let sum = peselArr.reduce((acc, curr, i) => {
    return (acc += curr * controlArr[i])
  }, 0)
  if (sum % 10 === 0) return true
  else return false
}

const validateVatNumber = (vatNumber) => {
  let vatNumberArr = vatNumber
    .match(/([0-9])/g)
    .map((el) => Number.parseInt(el))

  // check length:
  if (vatNumberArr.length !== 10) return false

  const controlArr = [6, 5, 7, 2, 3, 4, 5, 6, 7]
  let sum = vatNumberArr.reduce((acc, curr, i) => {
    // last vat number is not taken into consideration while counting sum:
    if (i === 9) return acc

    return (acc += curr * controlArr[i])
  }, 0)

  if (sum % 11 === vatNumberArr[9]) return true
  else return false
}

const validateRegon = (regon) => {
  const regonArr = [...regon]

    .map((el) => {
      // clearing number:
      if (isNaN(el)) return null
      else return Number.parseInt(el)
    })
    .filter((el) => el !== null)
  console.log(regonArr)
  // check length:
  //_ (there are two lengths: 9 and 14)
  if (regonArr.length !== 9 && regonArr.length !== 14) return false

  const controlArr =
    regonArr.length === 9
      ? [8, 9, 2, 3, 4, 5, 6, 7]
      : [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8]
  let sum = regonArr.reduce((acc, curr, i) => {
    if (regonArr.length === 9) {
      // last number is not taken into consideration while counting sum:
      if (i === 8) return acc
      return (acc += curr * controlArr[i])
    } else {
      // last number is not taken into consideration while counting sum:
      if (i === 13) return acc
      return (acc += curr * controlArr[i])
    }
  }, 0)

  if (
    sum % 11 === regonArr[regon.length - 1] ||
    // when %11 === 10 -> control number is 0
    (sum % 11 === 10 && regonArr[regon.length - 1] === 0)
  )
    return true
  else return false
}

const atLeastOne = (obj) => {
  let notEmpty = false
  for (let key in obj) {
    if (obj[key].length > 0) notEmpty = true
  }
  return notEmpty
}

export { prepare, validatePesel, validateVatNumber, validateRegon, atLeastOne }
