import { useState } from "react"
import { Container, Row, Button, Table, Alert, Spinner } from "react-bootstrap"

import { Files } from "../_shared/filesComp/Files"
import HistoryTable from "../utils/HistoryTable"
import CarEditModal from "./CarEditModal"
import { format } from "date-fns"
import { CarSellEdit } from "./CarSellEdit"

//TODO add lease end date

const CarDetailsModal = ({ car, setModalData, user, refresh }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFetchError] = useState(null)

  // func to use in modals to return to details
  const goBack = () =>
    setModalData({
      show: true,
      type: "info",
      header: "Szczegóły samochodu",
      xl: true,
      body: (
        <CarDetailsModal
          user={user}
          setModalData={setModalData}
          car={car}
          refresh={refresh}
        />
      ),
    })

  const handleStateChange = async () => {
    try {
      setIsFetching(true)
      setFetchError(null)

      const res = await fetch(`/cars/edit`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          car: {
            ...car,
            state: car.state === "aktywny" ? "archiwum" : "aktywny",
          },
          dirtyFields: { state: true },
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `${res.status} - ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )

      return refresh({
        headerColor: "bg-success",
        header: "Sukces",
        body: "Stan samochodu został zmieniony",
      })
    } catch (err) {
      console.log(err)

      setFetchError(err.message)
      setIsFetching(false)
    }
  }

  const renderLeaseDetails = () => {
    if (car.isLeased) {
      return (
        <>
          <Row>
            <Table>
              <tbody>
                <tr>
                  <td className="fixed-values-table">Leasing</td>
                  <td>Tak</td>
                  <td className="fixed-values-table">Nr umowy:</td>
                  <td>{car.leaseAgreementNo}</td>
                  <td className="fixed-values-table">GAP</td>
                  <td>
                    {car.GAPDate
                      ? format(new Date(car.GAPDate), "yyyy-MM-dd")
                      : "BRAK"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>

          <Row>
            <Table>
              <tbody>
                <tr>
                  <td className="fixed-values-table">Właściciel (DR)</td>
                  <td colSpan="2">{car.legalOwner}</td>
                  <td className="fixed-values-table">Korzystający (DR)</td>
                  <td colSpan="2">{car.legalUser}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </>
      )
    } else
      return (
        <Row>
          <Table>
            <tbody>
              <tr>
                <td className="fixed-values-table">Leasing</td>
                <td>Nie</td>
                <td className="fixed-values-table">Właściciel (DR)</td>
                <td>{car.legalOwner}</td>
                <td className="fixed-values-table">Korzystający (DR)</td>
                <td>{car.legalUser}</td>
              </tr>
            </tbody>
          </Table>
        </Row>
      )
  }

  const renderSellDetails = () => {
    if (car.state === "sprzedany")
      return (
        <Row>
          <Table>
            <tbody>
              <tr>
                <td className="bg-warning" colSpan="6">
                  SPRZEDANY!
                </td>
              </tr>
              <tr>
                <td className="fixed-values-table">Data sprzedaży:</td>
                <td>
                  {car.sellDate
                    ? format(new Date(car.sellDate), "yyyy-MM-dd")
                    : "BRAK"}{" "}
                </td>

                <td className="fixed-values-table">Nr faktury:</td>
                <td> {car.sellInvoice} </td>
                <td className="fixed-values-table">Kwota netto:</td>
                <td> {car.sellAmount} </td>
              </tr>
              <tr>
                <td className="fixed-values-table">Data wyrejestrowania:</td>
                <td>
                  {car.deregisterDate
                    ? format(new Date(car.deregisterDate), "yyyy-MM-dd")
                    : "BRAK"}{" "}
                </td>

                <td className="fixed-values-table">
                  Data wypowiedzenia ubezp.:
                </td>
                <td>
                  {car.insuranceTerminationDate
                    ? format(
                        new Date(car.insuranceTerminationDate),
                        "yyyy-MM-dd"
                      )
                    : "BRAK"}{" "}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      )
    else return null
  }

  const renderStateBtn = () => {
    if (!user.perm.carsManage.e) return null

    if (car.state === "aktywny")
      return (
        <>
          <Button
            variant="warning"
            onClick={handleStateChange}
            disabled={isFetching}
          >
            {isFetching ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Archiwizuj"
            )}
          </Button>
          <Button
            variant="warning"
            disabled={isFetching}
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "edycja danych sprzedaży",
                xl: true,
                body: (
                  <CarSellEdit
                    user={user}
                    setModalData={setModalData}
                    refresh={refresh}
                    car={car}
                  />
                ),
              })
            }
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Sprzedaż"}
          </Button>
        </>
      )

    if (car.state === "sprzedany")
      return (
        <>
          <Button
            variant="warning"
            disabled={isFetching}
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "edycja danych sprzedaży",
                xl: true,
                body: (
                  <CarSellEdit
                    user={user}
                    setModalData={setModalData}
                    refresh={refresh}
                    car={car}
                  />
                ),
              })
            }
          >
            {isFetching ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Edytuj sprzedaż"
            )}
          </Button>
        </>
      )

    if (car.state === "archiwum")
      return (
        <>
          <Button
            variant="warning"
            onClick={handleStateChange}
            disabled={isFetching}
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Przywróć"}
          </Button>
        </>
      )
  }

  return (
    <Container>
      <Row className="justify-content-around">
        <Button
          disabled={isFetching}
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              body: <HistoryTable history={car.history} goBack={goBack} />,
            })
          }
        >
          {isFetching ? <Spinner animation="border" size="sm" /> : "Historia"}
        </Button>
        <Button
          disabled={isFetching}
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              xl: true,
              header: `Pliki dla samochodu ${car.regNum}`,
              body: (
                <Files
                  goBack={goBack}
                  bucketSuffix={"carsdocs"}
                  filePath={`${car._id}/`}
                  entityId={car._id}
                  prefix={`${car._id}/`}
                  isAdmin={user.type.admin}
                />
              ),
            })
          }
        >
          {isFetching ? <Spinner animation="border" size="sm" /> : "Dokumenty"}
        </Button>
        {renderStateBtn()}
        {user.perm.carsManage.e && car.state !== "archiwum" ? (
          <Button
            disabled={isFetching}
            variant="secondary"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                header: "edycja samochodu",
                xl: true,
                body: (
                  <CarEditModal
                    user={user}
                    setModalData={setModalData}
                    refresh={refresh}
                    isNew={false}
                    car={car}
                  />
                ),
              })
            }
          >
            {isFetching ? <Spinner animation="border" size="sm" /> : "Edytuj"}
          </Button>
        ) : null}
      </Row>

      <Row>
        <p style={{ color: "white", fontSize: "x-small" }} className="my-0">
          {car._id}
        </p>
      </Row>
      {fetchError ? (
        <Row>
          <Alert variant="danger">{fetchError}</Alert>
        </Row>
      ) : null}
      <Row>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Nr rej.</td>
              <td>{car.regNum}</td>
              <td className="fixed-values-table">Nazwa</td>
              <td>{car.name}</td>
              <td className="fixed-values-table">Marka - model</td>
              <td>
                {car.brand} - {car.model}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">Rodzaj</td>
              <td>{car.type}</td>
              <td className="fixed-values-table">Oddział</td>
              <td>{car.branch}</td>
              <td className="fixed-values-table">Stan</td>
              <td>{car.state}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Użytkownik</td>
              <td colSpan="3">{car.user?.fullName || "brak"}</td>
              <td className="fixed-values-table">Ukryty dla logistyki</td>
              <td>{car.hideForLogistic ? "tak" : "nie"}</td>
            </tr>{" "}
            <tr>
              <td className="fixed-values-table">Uwagi</td>
              <td colSpan="5">{car.comments}</td>
            </tr>
          </tbody>
        </Table>
      </Row>
      {renderSellDetails()}
      <Row>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Data ubezp.</td>
              <td>
                {car.insuranceDate
                  ? format(new Date(car.insuranceDate), "yyyy-MM-dd")
                  : "BRAK"}
              </td>{" "}
              <td className="fixed-values-table">Rodzaj ubezp.</td>
              <td>{car.insuranceType}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Data badania</td>
              <td>
                {car.reviewDate
                  ? format(new Date(car.reviewDate), "yyyy-MM-dd")
                  : "BRAK"}
              </td>
              <td className="fixed-values-table">Data tachograf</td>
              <td>
                {car.tachographReviewDate
                  ? format(new Date(car.reviewDate), "yyyy-MM-dd")
                  : "BRAK/nd."}
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      {renderLeaseDetails()}
      <Row>
        <Table>
          <tbody>
            {car.previousRegNumbers ? (
              <tr>
                <td className="fixed-values-table">Poprzednie rejestracje</td>
                <td colSpan="5">{car.previousRegNumbers}</td>
              </tr>
            ) : null}
            <tr>
              <td className="fixed-values-table">VIN:</td>
              <td colSpan="3">{car.vin}</td>
              <td className="fixed-values-table">Rok produkcji:</td>
              <td>{car.prodYear}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Beczka:</td>
              <td>{car.tankCapacity}</td>
              <td className="fixed-values-table">Platforma - ładowność:</td>
              <td>{car.platformCapacity}</td>
              <td className="fixed-values-table">DMC:</td>
              <td>{car.maxWeight}</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  )
}
export default CarDetailsModal
