import React, { useState, useEffect, useContext } from "react"

import { Container, Button } from "react-bootstrap"

import UserContext from "../../../contexts/userContext"

import JobsFixturesAddRender from "./JobsFixturesAddRender"
const JobsFixturesAddFetch = (props) => {
  const user = useContext(UserContext)
  const { goBack } = props

  const [isLoading, setIsLoading] = useState(true)
  const [jobType, setJobType] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch("/configs/jobType")
        if (res.status === 403) return alert("brak uprawnień")
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setJobType(resJSON.jobType)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        alert("błąd programu")
      }
    }
    fetchData()
  }, [])

  return (
    <Container fluid>
      <Button onClick={goBack}>Wróć</Button>
      {isLoading ? (
        "Pobieram dane konfiguracyjne"
      ) : (
        <JobsFixturesAddRender user={user} jobType={jobType} />
      )}
    </Container>
  )
}
export default JobsFixturesAddFetch
