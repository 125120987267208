import { useForm, useFieldArray, useWatch } from "react-hook-form"

import { Button, Container, Row, Form } from "react-bootstrap"

import { HeaderForm, MainGoodsForm, FooterForm } from "./forms"

const ConsumptionsEditRender = (props) => {
  // preserving original main goods when !isNew:
  const mainGoodsTemplate = []
  if (!props.isNew) {
    props.consumption.mainGoodsConsumptions[0].consumptions.forEach((good) => {
      mainGoodsTemplate.push({ ...good, quantity: "" })
    })
  } else {
    props.mainGoods.forEach((good) =>
      mainGoodsTemplate.push({ ...good, quantity: "" })
    )
  }

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: props.isNew
      ? // to prevent 'undefined' from useFieldArray:
        { mainGoodsConsumptions: [{ consumptions: mainGoodsTemplate }] }
      : props.consumption,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mainGoodsConsumptions",
  })

  const watchMainGoods = useWatch({
    name: "mainGoodsConsumptions",
    control: control,
  }) // used to calculate main goods sums

  register("consumptionsVer", { value: 2 })
  register("user_id", { value: props.user.id })

  return (
    <Container fluid id="ConsumptionsEditRender">
      {props.isNew ? null : (
        <Button href={`/consumption/${props.consumption._id}`} className="my-2">
          Wróć
        </Button>
      )}
      <Form onSubmit={handleSubmit(props.onSubmit)}>
        <HeaderForm
          register={register}
          dateToDisplay={props.dateToDisplay}
          user={props.user}
          employees={props.employees}
          watch={watch}
          errors={errors}
        />
        <MainGoodsForm
          isNew={props.isNew}
          mainGoods={
            mainGoodsTemplate // to preserve deleted or not active goods
          }
          control={control}
          register={register}
          watch={watch}
          getValues={getValues}
          watchMainGoods={watchMainGoods}
          setModalData={props.setModalData}
          fields={fields}
          append={append}
          remove={remove}
        />
        <FooterForm
          normalGoods={
            props.isNew
              ? props.normalGoods
              : props.consumption.normalGoodsConsumptions // to preserve deleted or not active goods
          }
          register={register}
        />
        <Row>
          {props.user.type.admin ? (
            <Button onClick={() => console.log(watch())}>_</Button>
          ) : null}
          <Button
            type="submit"
            className="ml-auto mt-3"
            variant="secondary"
            id="submitConsumption"
          >
            Zapisz
          </Button>
        </Row>
      </Form>
    </Container>
  )
}
export default ConsumptionsEditRender
