import React from "react"
import { Row, Col, Form } from "react-bootstrap"

const ServicesManageFilters = ({ filters, setFilters }) => {
  return (
    <>
      <Row>
        <Col>
          Nazwa
          <Form.Control
            as="input"
            type=""
            className=""
            autoComplete="chrome-off"
            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
          />
        </Col>
        <Col>
          Typ
          <Form.Control
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
          >
            <option value="wszystkie">wszystkie</option>
            <option value="toalety">toalety</option>
            <option value="szambo">szambo</option>
            <option value="sprzątanie">sprzątanie</option>
            <option value="ogrodzenia">ogrodzenia</option>
            <option value="prysznice">prysznice</option>
            <option value="inne">inne</option>
          </Form.Control>
        </Col>
        <Col>
          Naliczenie
          <Form.Control
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
            onChange={(e) =>
              setFilters({ ...filters, chargeType: e.target.value })
            }
          >
            <option value="wszystkie">wszystkie</option>
            <option value="za serwis">za serwis</option>
            <option value="za dzień">za dzień</option>
            <option value="za miesiąc">za miesiąc</option>
            <option value="krótki termin">krótki termin</option>
            <option value="ryczałt impreza">ryczałt impreza</option>
          </Form.Control>
        </Col>
        <Col>
          Sprzętowa
          <Form.Control
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
            onChange={(e) => {
              if (e.target.value === "tak")
                setFilters({ ...filters, mustHaveEqp: true })
              if (e.target.value === "nie")
                setFilters({ ...filters, mustHaveEqp: false })
              if (e.target.value === "wszystkie")
                setFilters({ ...filters, mustHaveEqp: "wszystkie" })
            }}
          >
            <option value="wszystkie">wszystkie</option>
            <option value="tak">tak</option>
            <option value="nie">nie</option>
          </Form.Control>
        </Col>
        <Col>
          Stan
          <Form.Control
            as="select"
            type=""
            className=""
            autoComplete="chrome-off"
            onChange={(e) => setFilters({ ...filters, state: e.target.value })}
          >
            <option value="wszystkie">wszystkie</option>
            <option value="aktywna">aktywna</option>
            <option value="nieaktywna">nieaktywna</option>
          </Form.Control>
        </Col>
      </Row>
    </>
  )
}
export { ServicesManageFilters }
