import React from "react"
import { Row, Col } from "react-bootstrap"
import eqpFilter from "../../pics/manual/eqpFilter.png"
const ManualEqpSection = (props) => {
  return (
    <div>
      <Row>
        <h2>Sprzęt</h2>
        <br />
      </Row>
      <p>
        Poniższe dotyczy naszego lub przez nas podnajmowanego sprzętu (np.
        kontenery). W clipERPie nie ewidencjonujemy sprzętu klienta (np. przy
        usłudze "serwis toalety klienta" nie ma podpiętego sprzętu)
      </p>
      <hr />
      <Row>
        <p id="eqpTemplate">Sprzęt dzielimy na trzy kategorie:</p>
        <ul>
          <li>
            sprzęt numerowany - nasze toalety lub inny sprzęt oznaczony
            konkretnym i unikalnym numerem
          </li>
          <li>
            sprzęt <b>nie</b>numerowany - nasz sprzęt bez numerów (ogrodzenia,
            kosze itd.), oraz sprzęt podnajmowany (np. kontenery).
            <b>Sprzęt nienumerowany to tak naprawdę sprzęt z numerem = 0</b>
          </li>
          <li>
            templatki (od angielskiego "template" - wzorzec) - sprzęt wirtualny,
            do użycia przy planowaniu dostarczenia, gdy nie znamy numeru sprzętu
            jaki ostatecznie trafi na miejsce.
          </li>
        </ul>
      </Row>
      <Row>
        <h4>Właściwości sprzętu</h4>
      </Row>
      <Row>
        <p>
          Każdy sprzęt musi mieć określone konkretne właściwości jak kolor,
          podtyp (toaleta standard/vip/ns) itd. Dodatkowo sprzęt może mieć
          wyposażenie (umywalka, system spłukujący, plandeka dla ogrodzenia)
        </p>
        <p>
          W przypadku templatek poza "normalnymi" właściwościami może się
          pojawić sformułowanie "bez znaczenia" (np. Toaleta podtyp: standard,
          kolor: bez znaczenia). Taka templatka służy do zasygnalizowania
          kierowcy, że może wziąć z placu dowolny sprzęt jeśli chodzi o tą
          właściwość (czyli np. toaletę <u>koniecznie</u> standard, ale w
          dowolnym kolorze)
        </p>
      </Row>
      <Row>
        <h4>Zarządzanie sprzętem na lokalizacji</h4>
      </Row>
      <Row>
        <p>
          Sprzęt z zasady jest podpinany pod usługę dodaną do lokalizacji.
          Jedyną możliwością dodawania i usuwania sprzętu jest zaplanowanie
          odpowiedniego zadania (dostarczenie/zabranie). <br />
          Sprzęt pojawia się w tabeli na lokalizacji już po <u>
            zaplanowaniu
          </u>{" "}
          dostarczenia. To, że jeszcze nie jest fizycznie dostarczony widać w
          kolumnie "Dostarczony". Liczba w tej kolumnie zwiększy się po
          odhaczeniu dostarczenia jako "wykonane".
          <br />
          Sprzęt będzie widoczny w tabeli do momentu odhaczenia jako wykonane{" "}
          <u>zabrania</u>, co odpowiednio zmniejszy liczby w kolumnach "Liczba"
          i "Dostarczony", albo usunie sprzęt z tabeli jeśli jest zabrany w
          całości.
        </p>
      </Row>
      <Row>
        <h3>Filtr sprzętu</h3>
      </Row>
      <Row>
        <Col>
          <p>
            W zależności od wybranego typu sprzętu mogą pojawiać się różne
            dodatkowe filtry (np. typ Toaleta ma filtr "kolor", którego nie ma
            ogrodzenie)
          </p>
          <p>
            Działanie filtra jest różne w zależności czy wyszukiwana jest
            templatka czy faktyczny sprzęt.
          </p>
          <p>
            Wyszukiwanie templatki: niezaznaczony checkbox obok danej
            właściwości powoduje wyszukanie templatek z wpisanym "bez
            znaczenia". Czyli jeśli klient chcemy, żeby kierowca dostarczył
            toaletę standard budowlaną, ale nie ma znaczenia kolor to zaznaczamy
            checkboxy przy właściwościach podtyp (wybieramy "std") i kategoria
            ("budowlana"), resztę zostawiamy niezaznaczoną.
          </p>
          <p>
            Wyszukiwanie faktycznego sprzętu: niezaznaczony checkbox powoduje
            zignorowanie tego pola przy wyszukiwaniu. Wyszukiwanie toalet z
            zaznaczonym tylko podtypem ("std") pokaże toalety standard
            niezależnie od koloru i innych właściwości. Zaznaczenie dodatkowo
            koloru i wybranie "niebieski" spowoduje wyszukanie tlyko i wyłącznie
            toalet <u>standard</u> w kolorze <u>niebieskim</u>, niezależnie od
            innych właściwości (pojawią się zarówno toalety z kotegorią
            "budowlana", "imprezowa" itd)
          </p>
          <h5>Filtr "wyposażenie"</h5>
          <p>
            Zaznaczenie checkboxa dotyczącego jakiegoś wyposażenia spowoduje
            wyszukanie sprzętu, który ma <u>conajmniej</u> to wyposażenie.
            Zaznaczenie "umywalka" prz wyszukiwaniu toalet pokaże toalety, które
            mają umywalkę, umywalkę i system spłukujący, umywalkę i zawiesia
            itd.
          </p>
        </Col>
        <Col>
          <img src={eqpFilter} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
    </div>
  )
}

export default ManualEqpSection
