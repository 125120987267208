import React, { useContext } from "react"
import {
  Tab,
  Tabs,
  Container,
  Row,
  Button,
  Table,
  Col,
  Alert,
} from "react-bootstrap"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { ImNewTab } from "react-icons/im"

import { CustomerCtx } from "./Fetch"

import CustomersDetailsGeneralTab from "./tabs/CustomersDetailsGeneralTab"
import CustomersDetailsContactsTab from "./tabs/CustomersDetailsContactsTab"
import CustomersDetailsAdditionalTab from "./tabs/CustomersDetailsAdditionalTab"
import CustomersDetailsLocationsTab from "./tabs/CustomersDetailsLocationsTab"
import CustomersDetailsAddressesTab from "./tabs/CustomersDetailsAddressesTab"

import { LocationEditGeneralModalFetch } from "../../Location/Edit/GeneralModal/Fetch"
import { renderArchiveBtn } from "./utils"

const CustomersDetailsRender = () => {
  const {
    customer,
    setModalData,
    user,
    locations,
    refresh,
    tabSelected,
    setTabSelected,
  } = useContext(CustomerCtx)

  return (
    <Container fluid>
      <Helmet>
        <title>{customer.shortName}</title>
      </Helmet>
      <Row className="mt-2 justify-content-between">
        <Col>
          <Button onClick={() => window.close()}>Wróć</Button>
        </Col>
        <Col xs="6">
          <Table bordered size="sm">
            <tbody>
              <tr>
                <td className="fixed-values-table">Nazwa klienta</td>
                <td>{customer.shortName}</td>
                <td className="fixed-values-table">Stan</td>
                <td>{customer.state}</td>
                {customer.debtCollectionCat === "czerwony" ? (
                  <td className="bg-danger">TYLKO PRZEDPŁATA!</td>
                ) : null}
              </tr>
            </tbody>
          </Table>
        </Col>

        {customer.state === "aktywny" ? (
          <Col className="">
            <Button
              variant="secondary"
              id="addLocationBtn"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  xl: true,
                  hideFooter: true,
                  header: `Dodawanie nowej lokalizacji dla klienta ${customer.shortName}`,
                  body: (
                    <LocationEditGeneralModalFetch
                      close={() => setModalData({ show: false })}
                      custHistLength={customer.history.length}
                      cust_id={customer._id}
                      isNew={true}
                      user={user}
                      setModalData={setModalData}
                      // pass customers all locations names array to use when creating default location name
                      locationsNames={locations.map((loc) => loc.name)}
                      refresh={refresh}
                    />
                  ),
                })
              }
            >
              Dodaj lokalizację
            </Button>
          </Col>
        ) : null}
        <Col>
          {renderArchiveBtn(user, customer, setModalData, refresh, locations)}
        </Col>
        <Col>
          <Link
            target="_blank"
            to={`/Invoicing/List?_id=${customer._id}&name=${
              customer.shortName || ""
            }`}
          >
            <Button>
              Faktury <ImNewTab />
            </Button>
          </Link>
        </Col>
      </Row>
      {customer.jobsSuspended ? (
        <Alert
          style={{
            animationName: "red-alert",
            animationDuration: "1s",
            animationIterationCount: "infinite",
            textAlign: "center",
          }}
        >
          <b>! WINDYKACJA - ZADANIA WSTRZYMANE !</b>
        </Alert>
      ) : null}
      <Tabs
        id="customer-tabs"
        activeKey={tabSelected}
        onSelect={(key) => setTabSelected(key)}
        fill
        className="tab"
        transition={false}
      >
        <Tab eventKey="general" title="Ogólne" className="tab">
          <CustomersDetailsGeneralTab />
        </Tab>
        <Tab eventKey="locations" title="Lokalizacje" className="tab">
          <CustomersDetailsLocationsTab />
        </Tab>
        <Tab eventKey="contacts" title="Kontakty" className="tab">
          <CustomersDetailsContactsTab />
        </Tab>
        <Tab eventKey="addresses" title="Adresy" className="tab">
          <CustomersDetailsAddressesTab />
        </Tab>
        <Tab eventKey="additional" title="Dodatkowe" className="tab">
          <CustomersDetailsAdditionalTab />
        </Tab>
      </Tabs>
    </Container>
  )
}
export default CustomersDetailsRender
