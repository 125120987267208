import { format } from "date-fns"
import { Table } from "react-bootstrap"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"

const renderPaymentDocs = (customer) => {
  if (!customer.paymentDocs?.length) return "bd"

  return (
    <Table>
      <thead>
        <tr>
          <th>Numer</th>
          <th>Data wystawienia</th>
          <th>Termin płatności</th>
          <th>Wartość PLN</th>
          <th>Użytkownik</th>
          <th>Opis</th>
        </tr>
      </thead>
      <tbody>
        {customer.paymentDocs.map((doc) => (
          <tr
            key={`paymentDocRow-${customer._id}-${doc["Numer"]}-${doc["Opis"]}`}
          >
            <td>{doc["Numer"] || "bd"}</td>
            <td>
              {format(new Date(doc["Data wystawienia"]), "yyyy-MM-dd") || "bd"}
            </td>
            <td>
              {format(new Date(doc["Termin płatności"]), "yyyy-MM-dd") || "bd"}
            </td>
            <td>{doc["Wartość PLN"] || "bd"}</td>
            <td>{doc["Użytkownik"] || "bd"}</td>
            <td>{doc["Opis"] || "bd"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const renderSortArrow = (colName, sortBy) => {
  if (colName !== sortBy.field) return null
  else {
    if (sortBy.order === -1) return <TiArrowSortedDown />
    else return <TiArrowSortedUp />
  }
}

const handleSortClick = (colName, sortBy, setSortBy) => {
  if (colName === sortBy.field) {
    setSortBy({ field: colName, order: sortBy.order === 1 ? -1 : 1 })
  } else {
    setSortBy({ field: colName, order: 1 })
  }
}

export { renderPaymentDocs, renderSortArrow, handleSortClick }
