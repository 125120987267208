// on initial fetch just get history of main entity
import React, { useState, useEffect } from "react"
import { Container, Spinner, Alert } from "react-bootstrap"
import _ from "lodash"

import HistoryFilters from "./filters"
import HistoryRender from "./render"

const HistoryFetch = ({ entity_id, subEntities }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [history, setHistory] = useState([])
  const [historyFiltered, setHistoryFiltered] = useState([])
  const [fetchError, setFetchError] = useState()
  const [users, setUsers] = useState()

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const res = await fetch("/history/get", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entity_id: entity_id }),
        })
        if (res.status === 403) return setFetchError("Brak uprawnień")
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setHistory(resJSON)
        setHistoryFiltered(resJSON)
        setUsers(
          _.uniqWith(
            resJSON.map((historyEntry) => historyEntry.user),
            _.isEqual
          )
        )
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchError(err.message || "nieokreślony błąd programu")
      }
    }
    fetchData()
  }, [entity_id])

  return (
    <Container>
      {fetchError ? (
        //   when error on fetching do not show anything apart error message:
        <Alert variant="danger">{fetchError}</Alert>
      ) : (
        <>
          {isLoading ? (
            <>
              <Spinner animation="border" />
              Pobieram dane
            </>
          ) : (
            <>
              <HistoryFilters
                users={users}
                setHistoryFiltered={setHistoryFiltered}
                history={history}
              />
              <HistoryRender history={historyFiltered} />
            </>
          )}
        </>
      )}
    </Container>
  )
}
export default HistoryFetch
