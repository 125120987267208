import React, { useState } from "react"
import { useTable } from "react-table"

import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"

import ConsumptionDetailsRender from "./consumptionDetailsRender"

const ConsumptionsTabRender = (props) => {
  console.log(props)
  const [choosenConsumption, setChoosenConsumption] = useState()

  const [columns] = useState([
    { Header: "Rok", accessor: "consumptionHeader.year" },
    { Header: "Miesiąc", accessor: "consumptionHeader.month" },
    { Header: "Oddział", accessor: "consumptionHeader.branch" },
    { Header: "Kierowca", accessor: "consumptionHeader.driver" },
    {
      Header: "Dni miesiąca",
      Cell: (table) => {
        return table.row.original.consumptionDetails.mainGoodsRows
          .map((row) => row.date)
          .join(", ")
      },
    },
    { Header: "Dodał", accessor: "user_id.name" },
  ])

  const data = props.consumptions
  const { getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  })
  return (
    <Container>
      {choosenConsumption ? (
        <div>
          <hr />
          <Button
            variant="secondary"
            onClick={() =>
              setChoosenConsumption((choosenConsumption) => !choosenConsumption)
            }
          >
            Wróć
          </Button>
          <ConsumptionDetailsRender consumption={choosenConsumption} />
        </div>
      ) : (
        <div>
          <hr />
          <Button onClick={props.changeIsLoading} variant="secondary">
            Wróć
          </Button>
          <Table {...getTableBodyProps} className="transactions-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                    <th
                      {
                        ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                      }
                      className="pretty-table-header"
                    >
                      {column.render("Header")}
                      {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => setChoosenConsumption(row.original)}
                    className="transaction-details-tr"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="pretty-table-data"
                        >
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  )
}
export default ConsumptionsTabRender
