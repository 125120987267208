import React, { useState, useMemo, useEffect } from "react"
import { Form, Row, Container, Table, Button, Col } from "react-bootstrap"
import _ from "lodash"
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs"

import BatchInvoicingModal from "./BatchInvoicingModal"
import { sorting } from "./utils"
import { handleCheckboxClick } from "./tableHelpers"

const LocationsListRender = ({
  locations,
  showUninvoiced,
  setModalData,
  refresh,
  showUninvoicedDate,
  locationsSelected,
  setLocationsSelected,
}) => {
  const [sortBy, setSortBy] = useState({
    // when api returned locations with searchResult use it on init, else use customerName
    field: locations.filter((loc) => loc.searchScore).length
      ? "searchScore"
      : "customerName",
    order: 1,
  })
  const locationsSorted = useMemo(
    () => sorting(locations, sortBy),
    [locations, sortBy]
  )

  // handle selecting many checkboxes whit shift:
  const [shiftPressed, setShiftPressed] = useState(false)
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Shift") {
        setShiftPressed(true)
      }
    }
    const handleKeyUp = (e) => {
      if (e.key === "Shift") {
        setShiftPressed(false)
      }
    }

    const clearEventListeners = () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }

    document.addEventListener("keydown", handleKeyDown)
    document.addEventListener("keyup", handleKeyUp)

    return clearEventListeners
  }, [])

  const reverseSelection = () => {
    const reversedLocations = _.difference(locations, locationsSelected)
    locations.forEach((loc) => {
      const locationCheckbox = document.getElementById(
        `locationCheckbox-${loc._id}`
      )
      if (reversedLocations.find((revLoc) => revLoc._id === loc._id))
        locationCheckbox.checked = true
      else locationCheckbox.checked = false
    })
    setLocationsSelected(reversedLocations)
  }

  //TODO: add checkbox in table header instead of selectAll. Handle changing its state depending on selected checkboxes in table
  const selectAll = () => {
    locations.forEach(
      (loc) =>
        (document.getElementById(`locationCheckbox-${loc._id}`).checked = true)
    )
    setLocationsSelected(locations)
  }

  const handleSorting = (fieldName) => {
    if (fieldName === sortBy.field)
      setSortBy({ field: fieldName, order: -sortBy.order })
    else setSortBy({ field: fieldName, order: 1 })
  }
  const renderSortArrow = (fieldName) => {
    if (sortBy.field === fieldName && sortBy.order === 1)
      return <BsFillCaretUpFill />
    else if (sortBy.field === fieldName && sortBy.order === -1)
      return <BsFillCaretDownFill />
    else return null
  }

  const isSelected = (loc) => {
    return (
      locationsSelected.findIndex(
        (locSelected) => locSelected._id === loc._id
      ) !== -1
    )
  }

  return (
    <Container fluid id="InvoicingLocationsListRender">
      {showUninvoiced ? (
        <div
          style={{
            position: "sticky",
            top: "80px",
            zIndex: 1000,
            backgroundColor: "white",
          }}
        >
          <Row className="mb-2">
            <Button onClick={selectAll} className="mr-2">
              Zaznacz wszystkie
            </Button>
            <Button onClick={reverseSelection}>Odwróć zaznaczenie</Button>
            <Col>
              <p>
                Zaznaczonych {locationsSelected.length || 0} z{" "}
                {locations.length || "bd"}
              </p>
            </Col>{" "}
            <Button
              className="ml-auto"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Generowanie faktury",
                  xl: true,
                  body: locationsSelected.length ? (
                    <BatchInvoicingModal
                      locations={locationsSelected}
                      setModalData={setModalData}
                      showUninvoicedDate={showUninvoicedDate}
                      refresh={refresh}
                      setLocationsSelected={setLocationsSelected}
                    />
                  ) : (
                    <div className="bg-warning">
                      Wybierz przynajmniej jedną lokalizację!
                    </div>
                  ),
                })
              }
            >
              Fakturuj zaznaczone
            </Button>
          </Row>
        </div>
      ) : null}

      <Table>
        <thead>
          <tr>
            {showUninvoiced ? <th>DZ</th> : false}
            <th onClick={() => handleSorting("customerName")}>
              {renderSortArrow("customerName")} klient
            </th>
            <th onClick={() => handleSorting("locName")}>
              {renderSortArrow("locName")} lokalizacja
            </th>
            <th>oddział</th>
            <th>{showUninvoiced ? "usług do zafakturowania" : "usługi"}</th>
            {/* <th>akcje</th> */}
          </tr>
        </thead>
        <tbody>
          {locationsSorted.map((loc) => {
            return (
              <tr
                className="clickable"
                onClick={(e) => {
                  if (e.target.type === "checkbox") return
                  if (e.target.id === "createInvoice")
                    return alert("do zrobienia")
                  else
                    return window.open(
                      `/Locations/${loc._id}`,
                      "_blank",
                      "noopener noreferrer"
                    )
                }}
                key={`locationRow-${loc._id}`}
              >
                {showUninvoiced ? (
                  <td>
                    <Form.Check
                      name={`locationCheckbox-${loc._id}`}
                      type="checkbox"
                      id={`locationCheckbox-${loc._id}`}
                      checked={isSelected(loc)}
                      onChange={(e) =>
                        handleCheckboxClick(
                          e,
                          loc,
                          setLocationsSelected,
                          shiftPressed,
                          locationsSorted
                        )
                      }
                    />
                  </td>
                ) : null}
                <td>{loc.customer.shortName || "bd"}</td>
                <td>
                  {loc.name}
                  <br />
                  {loc.street} {loc.city}
                </td>
                <td>{loc.branch}</td>
                <td>
                  {showUninvoiced
                    ? // when showing uninvoiced -> filter out services without uninvoced params, then show remaining services:
                      loc.services
                        .filter((srv) => srv.state !== "anulowana")
                        .filter((srv) => srv.uninvoicedParams.length)
                        .map((srv) => (
                          <li
                            key={`locationServiceLi-loc-${loc._id}-srv-${srv._id}`}
                          >
                            {`${srv.locSrvNo || "?"}.${srv.name}`} -{" "}
                            {`${srv.netPrice} ${srv.chargeType}`}
                          </li>
                        ))
                    : // else filter out closed and canceled services:
                      loc.services
                        .filter((srv) => srv.state === "aktywna")
                        .map((srv) => (
                          <li key={`locationServicesList-${srv._id}`}>
                            {`${srv.locSrvNo || "?"}.${srv.name}`} -{" "}
                            {`${srv.netPrice} ${srv.chargeType}`}
                          </li>
                        ))}
                </td>
                {/* <td>
                  <Button id="createInvoice" disabled={true}>
                    Fakturuj
                  </Button>
                </td> */}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default LocationsListRender
