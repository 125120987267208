import React from "react"
import { Container, Form, Table } from "react-bootstrap"
import _ from "lodash"
import {
  isWithinInterval,
  getDay,
  min,
  getWeek,
  differenceInCalendarDays,
  compareAsc,
} from "date-fns"
import { ChooseSubSrv } from "../../_shared/jobs/utils"

const ChooseSrvAndEqp = ({
  register,
  control,
  Controller,
  location,
  watch,
  setValue,
  jobType,
}) => {
  // depending on quantity of location services use or not findIndex to tell if service is choosen.
  // See comment about hasOneSrv in LocationJobsAdd for more details

  return (
    <Container>
      {location.services
        .filter((srv) => srv.state === "aktywna")
        .map((srv, i) => {
          if (
            jobType === "serwis" ||
            (jobType === "zabranie" && srv.mustHaveEqp)
          )
            return (
              <React.Fragment key={`servicesFragment-${srv._id}-${i}`}>
                <Controller
                  control={control}
                  name={`services.${i}.include`}
                  render={({ field }) => {
                    return (
                      <Form.Check
                        {...field}
                        checked={field.value}
                        type="checkbox"
                        label={`${srv.locSrvNo || "?"}. ${srv.name}`}
                        id={`services-${srv._id}`}
                      />
                    )
                  }}
                />
                {watch(`services.${i}.include`) && (
                  <ChooseEqp
                    register={register}
                    control={control}
                    Controller={Controller}
                    service={srv}
                    arrInd={i}
                    watch={watch}
                    setValue={setValue}
                    jobType={jobType}
                  />
                )}
                {jobType === "serwis" ? (
                  <ChooseSubSrv srv={srv} register={register} srvInd={i} />
                ) : null}
              </React.Fragment>
            )
          else return null
        })}
    </Container>
  )
}
const ChooseEqp = ({
  register,
  control,
  Controller,
  service,
  arrInd,
  watch,
  setValue,
  jobType,
}) => {
  if (service.equipment?.length === 0) return "Brak sprzętu dla usługi"
  else
    return (
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Typ/Nazwa</th>
            <th>Liczba</th>
            <th>Podtyp</th>
            <th>Char.</th>
            <th>Kolor</th>
            <th>Kategoria</th>
            <th>Wyposażenie</th>
            <th>Numer</th>
          </tr>
        </thead>
        <tbody>
          {service.equipment.map((eqp, i) => (
            <tr key={`eqp-tr-${eqp._id}-${eqp.i}`}>
              <td>
                <Controller
                  control={control}
                  name={`services.${arrInd}.eqp.${i}.ref`}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      checked={field.value}
                      value={eqp.ref._id} // see job model comments
                      id={`service-${service._id}-eqp-${eqp._id}`}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setValue(`services.${arrInd}.eqp.${i}.qty`, null)
                          // since RHF v7 I can't set checkbox value by prop, but I need eqp.i.ref to be _id
                          // so I set this value manually:
                          setValue(`services.${arrInd}.eqp.${i}.ref`, null)
                        } else {
                          setValue(
                            `services.${arrInd}.eqp.${i}.qty`,
                            eqp.qty || null
                          )
                          setValue(
                            `services.${arrInd}.eqp.${i}.ref`,
                            eqp.ref._id
                          )
                        }

                        // field.onChange(e)
                      }}
                    />
                  )}
                />
              </td>

              <td>{eqp.ref.type || eqp.ref.name}</td>
              <td>
                {eqp.ref.isTemplate || eqp.ref.number === 0 ? (
                  <Form.Control
                    {...register(`services.${arrInd}.eqp.${i}.qty`)}
                    as="input"
                    type="number"
                    max={eqp.qty}
                    min="0"
                    className="small-number-input"
                    autoComplete="chrome-off"
                    disabled={
                      watch(`services.${arrInd}.eqp.${i}.ref`) ? false : true
                    }
                  />
                ) : (
                  "nd"
                )}
              </td>
              <td>{eqp.ref.subtype}</td>
              <td>{eqp.ref.char}</td>
              <td>{eqp.ref.color}</td>
              <td>{eqp.ref.category}</td>
              <td>{eqp.ref.mods.join(", ")}</td>
              <td>{eqp.ref.number || "nd"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
}

const filterJobs = (jobs, filters) => {
  // filter by state and sort:
  let jobsFiltered = jobs
    .filter((job) => filters.jobState.includes(job.state))
    .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))

  // apply service filters
  if (filters.service !== "all") {
    jobsFiltered = jobsFiltered.filter((job) =>
      job.services.find(
        (jobSrv) => jobSrv.locationServiceRef === filters.service
      )
    )
  }
  // when should change only jobs with this one choosen service:
  //( checking also filters.service, because RHF will not unmount containOnlySrvChoosen checkbox when service select changes)
  if (filters.service !== "all" && filters.containOnlySrvChoosen === true)
    jobsFiltered = jobsFiltered.filter((job) => job.services.length === 1)

  // apply type filter:
  if (filters.jobType !== "all")
    jobsFiltered = jobsFiltered.filter((job) => job.type === filters.jobType)

  // apply dates filter:
  if (filters.useDates === "true")
    _.remove(
      jobsFiltered,
      (job) =>
        !isWithinInterval(new Date(job.date), {
          start: new Date(filters.startDate + "T00:00"),
          end: new Date(filters.endDate + "T23:59"),
        })
    )

  // apply weekdays filter:
  _.remove(jobsFiltered, (job) => {
    const jobWeekday = getDay(new Date(job.date)).toString()
    if (filters.weekday.findIndex((el) => el === jobWeekday) !== -1)
      return false
    else return true
  })

  //$ apply frequency filter -->
  if (filters.frequency === "days") {
    filterByDaysFreq(
      jobsFiltered,
      filters.frequencyValue,
      // use first job date or selected startd day:
      new Date(
        filters.useDates === "true"
          ? filters.startDate
          : min(jobsFiltered.map((job) => new Date(job.date)))
      )
    )
  }

  if (filters.frequency === "weeks")
    filterByWeeksFreq(
      jobsFiltered,
      filters.frequencyValue,
      getWeek(
        new Date(
          filters.useDates === "true"
            ? filters.startDate
            : min(jobsFiltered.map((job) => new Date(job.date)))
        ),
        { weekStartsOn: 1 }
      )
    )

  if (filters.frequency === "occurance")
    filterByOccuranceFreq(jobsFiltered, filters.frequencyValue)
  //$ <--

  return jobsFiltered
}

/**
 *
 * @param {Array} jobs array of jobs to filter
 * @param {Number} daysInterval number of days to 'jump' while filtering
 * @param {Date} firstDay day to start filtering (is included)
 */
const filterByDaysFreq = (jobs, daysInterval, firstDay) => {
  _.remove(
    jobs,
    (job) =>
      differenceInCalendarDays(firstDay, new Date(job.date)) % daysInterval !==
      0
  )
}

/**
 * takes number of week of firstDay and leaves jobs that are planned in weeks that meets criteria
 * @param {Array} jobs array of jobs to filter
 * @param {Number} weeksInterval number of weeks to 'jump' while filtering
 * @param {Date} firstWeekNum number of first week - will leave jobs in this week and next weeks that meet criteria
 */
const filterByWeeksFreq = (jobs, weeksInterval, firstWeekNum) => {
  _.remove(
    jobs,
    (job) =>
      (getWeek(new Date(job.date), { weekStartsOn: 1 }) - firstWeekNum) %
        weeksInterval !==
      0
  )
}

/**
 * leaves each X'th job
 * @param {Array} jobs
 * @param {Number} occuranceFactor
 */
const filterByOccuranceFreq = (jobs, occuranceFactor) => {
  _.remove(jobs, (job, i) => i % occuranceFactor !== 0)
}

export { ChooseSrvAndEqp, filterJobs }
