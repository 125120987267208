const dateParse = (date, time = true) => {
  const dateObj = new Date(date)

  let dateArr = []
  if (time) {
    dateArr = [
      dateObj.getFullYear().toString(),
      "-",
      ("0" + (dateObj.getMonth() + 1)).slice(-2),
      "-",
      ("0" + dateObj.getDate()).slice(-2),
      " ",
      ("0" + dateObj.getHours()).slice(-2),
      ":",
      ("0" + dateObj.getMinutes()).slice(-2),
    ]
  } else {
    dateArr = [
      dateObj.getFullYear(),
      "-",
      ("0" + (dateObj.getMonth() + 1)).slice(-2),
      "-",
      ("0" + dateObj.getDate()).slice(-2),
    ]
  }
  const dateReady = dateArr.join("")

  return dateReady
}

const nF = (n) => {
  if (typeof n === "number" || typeof n === "string") {
    return new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: "PLN",
    }).format(n)
  } else return "BŁĄD"
}

const hundrethComma = (n) => {
  if (n === null || n === undefined) return ""

  return new Intl.NumberFormat("pl-PL", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n)
}

const roundToHundredth = (n) => {
  return Math.round(n * 100) / 100
}

/**
 * Replaces polish letters with their base latin letters, no matter case
 * @param {String} string string with polish diacritics to replace with their base letters
 * @returns {String} string with replaced letters
 */
const clearDiacritics = (string) => {
  const diacrit = ["ą", "ę", "ć", "ó", "ń", "ł", "ś", "ż", "ź"]
  const base = ["a", "e", "c", "o", "n", "l", "s", "z", "z"]

  diacrit.forEach((sing, i) => {
    string = string.replaceAll(diacrit[i], base[i])
    string = string.replaceAll(diacrit[i].toUpperCase(), base[i].toUpperCase())
  })

  return string
}

export { dateParse, nF, hundrethComma, roundToHundredth, clearDiacritics }
