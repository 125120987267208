import React, { useState, useEffect, useContext, createContext } from "react"
import { Container, Toast } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"

import CustomersDetailsRender from "./Render"

const CustomerCtx = createContext(null)

const CustomersDetailsFetch = (props) => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [initialFetch, setInitialFetch] = useState(true)
  const [customer, setCustomer] = useState(null)
  const [locations, setLocations] = useState(null)
  const [configs, setConfigs] = useState(null)
  const [tabSelected, setTabSelected] = useState("general")
  const [toast, setToast] = useState(null)

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const res = await fetch(`/customers/getById/${props.match.params._id}`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setCustomer(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "błąd pobierania daych",
        })
      }
    }

    const getLocations = async () => {
      try {
        const res = await fetch(
          `/locations/getByCustomerId/${props.match.params._id}`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setLocations(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "błąd pobierania daych",
        })
      }
    }
    const getConfigs = async () => {
      try {
        const res = await fetch(
          `/configs/customerContactTypes,customerAddressTypes,customerNoteTypes`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "błąd pobierania daych",
        })
      }
    }

    const fetchAll = async () => {
      const fetchArr = [getCustomer(), getLocations()]

      //if not initial fetch -> don't get configs
      if (initialFetch) {
        fetchArr.push(getConfigs())
        setInitialFetch(false)
      }
      await Promise.all(fetchArr).then(() => setIsLoading(false))
    }

    if (isLoading === true) fetchAll()
  }, [props, isLoading, initialFetch])
  return (
    <CustomerCtx.Provider
      value={{
        customer: customer,
        user: user,
        locations: locations,
        configs: configs,
        modalData: modalData,
        setModalData: setModalData,
        refresh: (toast) => {
          setModalData({ show: false })
          setIsLoading(true)
          setToast(toast)
        },
        tabSelected: tabSelected,
        setTabSelected: setTabSelected,
      }}
    >
      <Container fluid>
        {isLoading ? <p>Wczytuję dane</p> : <CustomersDetailsRender />}
      </Container>
      <MyModal modalData={modalData} setModalData={setModalData} />
      <Toast
        show={toast ? true : false}
        delay={5000}
        autohide
        onClose={() => setToast(null)}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "20%",
        }}
      >
        <Toast.Header
          style={{ justifyContent: "space-between" }}
          className={toast?.headerColor || ""}
        >
          {toast?.header}
        </Toast.Header>
        <Toast.Body>{toast?.body}</Toast.Body>
      </Toast>
    </CustomerCtx.Provider>
  )
}
export { CustomersDetailsFetch, CustomerCtx }
