import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Row, Table, Button, Form, Alert } from "react-bootstrap"

const EqpFilter = ({
  setIsLoading,
  setEquipment,
  equipment,
  srv,
  eqpTypes,
  jobType,
  watchEqp,
}) => {
  const [fetchError, setFetchError] = useState(false)

  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: { isTemplate: "true", type: srv.eqpTypes[0] },
  })

  const fetchEqp = async (formData) => {
    try {
      // fetch equipment only for delivery, else just show eqp present in choosen service:
      //!!!!!!!!!!!!!!!!!!!!
      console.log("watchServices:", watchEqp)
      if (jobType === "dostarczenie" && !srv.custEqp) {
        // get already selected eqp:
        const eqpSelected = []

        if (watchEqp && watchEqp.length) {
          // get selected eqp _ids:
          const eqp_ids = watchEqp
            .filter((eqp) => eqp.ref)
            .map((eqp) => eqp.ref)

          // basing on eqp_ids push eqp object to eqpSelected arr
          eqp_ids.forEach((eqp_id) => {
            const eqpObj = equipment.find((eqp) => eqp._id === eqp_id)
            if (!eqpObj)
              throw new Error(
                "Błąd działania programu, ZGŁOŚ podając link do lokalizacji. (coudn't find selectedEqp object to preserve aleready checked eqp while fetching new filters"
              )
            eqpSelected.push(eqpObj)
          })
        }

        setIsLoading(true)

        const res = await fetch(`/equipment/getFiltered`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        })
        if (res.status === 403) return setFetchError("Brak uprawnień")
        if (res.status !== 200) throw res

        const eqpReady = await res.json()

        setEquipment([...eqpSelected, ...eqpReady])
        setIsLoading(false)
      } else if (srv.custEqp) {
        //$ I use this component to change customer equipment, so I have to 'hydrate' fetched equipment
        //$ with quantities from service if there is anything
        setIsLoading(true)

        const res = await fetch(`/equipment/getFiltered?getUsed=true`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...formData, type: "Sprzęt klienta" }),
        })
        if (res.status === 403) return setFetchError("Brak uprawnień")
        if (res.status !== 200) throw res

        let eqpReady = await res.json()

        eqpReady = eqpReady.map((eqp) => {
          // 'hydrating' with quantities from service:
          const srvEqpObj = srv.equipment.find(
            (srvEqp) => srvEqp.ref._id === eqp._id
          )

          if (srvEqpObj)
            return {
              ...eqp,
              qty: srvEqpObj.qty,
              ref: srvEqpObj.ref._id,
              selected: true,
            }
          else return eqp
        })

        setEquipment(eqpReady)
        setIsLoading(false)
      } else {
        // when job is not deliver -> show only eqp available for service:
        setEquipment(
          srv.equipment.map((srvEqp) => {
            return { ...srvEqp.ref, qty: srvEqp.qty }
          })
        )
      }
    } catch (err) {
      console.log(err)
      setFetchError("Błąd pobierania danych")
    }
  }

  // when customer equipment -> fetch all without waiting for user btn click
  useEffect(() => {
    const fetchCustEqp = async () => {
      await fetchEqp({ type: "Sprzęt klienta" })
    }

    if (srv.custEqp) fetchCustEqp()
    // to prevent adding fetchEqp to dependencies arr
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSelects = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))
    // most of equipment props are one-choose-only
    // this func creates select for each of such field

    if (eqpSelected) {
      // firts create map of avaliable fields:
      const keysMap = [
        { name: "subtype", plName: "Podtyp" },
        { name: "char", plName: "Charakterystyka" },
        { name: "color", plName: "Kolor" },
        { name: "category", plName: "Kategoria" },
      ]

      //  create select field for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name]?.length) return null
        else {
          return (
            <tr key={`equipment-form-${key.name}`}>
              <td className="fixed-values-table">
                <Form.Check
                  {...register(`${key.name}.apply`)}
                  type="checkbox"
                  label={key.plName}
                  id={`${key.name}.apply`}
                  onClick={(e) => {
                    if (!e.target.checked) setValue(`${key.name}.value`, null)
                  }}
                />
              </td>
              <td>
                <Form.Control
                  key={`${eqpSelected.type}-${key.name}-select`}
                  {...register(`${key.name}.value`)}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                  disabled={!watch(`${key.name}.apply`)}
                  defaultValue="null"
                >
                  <option value="null" hidden></option>
                  {eqpSelected[key.name].map((el) => {
                    // dont show "dowolny/a" - it is handled by checkbox above when template or don't apply in other case
                    if (el === "bez znaczenia") return null
                    else
                      return (
                        <option
                          key={`${eqpSelected.type}-${key.name}-${el}-select`}
                          value={el}
                        >
                          {el}
                        </option>
                      )
                  })}
                </Form.Control>
              </td>
            </tr>
          )
        }
      })

      return formArr.filter((el) => el)
    } else return null
  }

  const renderCheckboxes = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))

    if (eqpSelected) {
      // most of equipment props are multiple-choose

      // firts create map of avaliable fields:
      const keysMap = [{ name: "mods", plName: "Wyposażenie" }]

      //  create checkboxes for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name].length) return null
        else
          return (
            <tr key={`eqpFiltersCheckbox-${key.plName}`}>
              <td className="fixed-values-table">{key.plName}</td>
              <td>
                {eqpSelected[key.name].map((el) => (
                  <Form.Check
                    inline
                    key={`${key.name}-${el}-checkbox`}
                    {...register(`mods`)}
                    type="checkbox"
                    value={el}
                    label={el}
                    id={el}
                  />
                ))}
              </td>
            </tr>
          )
      })

      return formArr.filter((el) => el)
    } else return null
  }

  //# show filters only when type === "dostarczenie"
  if (jobType === "dostarczenie")
    return (
      <>
        Wybierz parametry sprzętu:
        <Table bordered>
          <tbody>
            <tr>
              <td onClick={() => setValue("isTemplate", "true")}>
                <Form.Check
                  {...register("isTemplate")}
                  type="radio"
                  value={true}
                  label="Templatka"
                  id="isTemplate-true"
                  inline
                  className="mr-auto"
                />
              </td>
              <td onClick={() => setValue("isTemplate", "false")}>
                <Form.Check
                  {...register("isTemplate")}
                  type="radio"
                  value={false}
                  label="Realny sprzęt"
                  id="isTemplate-false"
                  inline
                  className="mr-auto"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <tbody>
            {watch("isTemplate") === "false" && (
              <tr>
                <td>Numer</td>
                <td>
                  <Form.Control
                    {...register("number")}
                    as="input"
                    type="text"
                    className=""
                    autoComplete="chrome-off"
                  />
                </td>
              </tr>
            )}
            <tr>
              <td className="fixed-values-table">Typ:</td>
              <td>
                <Form.Control
                  {...register("type")}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                >
                  {eqpTypes.map((eqp) => {
                    if (
                      srv.eqpTypes.find((allowedEqp) => allowedEqp === eqp.type)
                    )
                      return (
                        <option key={`selectEqpType-${eqp.type}`}>
                          {eqp.type}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>
              </td>
            </tr>
            {renderSelects()}

            {renderCheckboxes()}
          </tbody>
        </Table>
        {fetchError && <Alert variant="danger">{fetchError}</Alert>}
        <Row>
          <Button
            className="ml-auto mr-5 mb-3"
            id="fetchEqp"
            onClick={handleSubmit(fetchEqp)}
          >
            Pobierz
          </Button>
        </Row>
      </>
    )
  else return null
}
export default EqpFilter
