import React, { useState, useContext, useEffect } from "react"
import { format } from "date-fns"
import { Table, Row, Button, Form, Alert, Badge } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"

import { LocationCtx } from "../Fetch"

import { submitBatchJobsChange } from "../utils/submits"
import { ServicesBatchEditModal } from "./ServicesBatchEdit"
import { filterJobs } from "./utils"

const weekDaysMap = [
  { number: "1", label: "pn", fullName: "Poniedziałek" },
  { number: "2", label: "wt", fullName: "Wtorek" },
  { number: "3", label: "śr", fullName: "Środa" },
  { number: "4", label: "cz", fullName: "Czwartek" },
  { number: "5", label: "pt", fullName: "Piątek" },
  { number: "6", label: "sb", fullName: "Sobota" },
  { number: "0", label: "nd", fullName: "Niedziela" },
]

const LocationJobsBatchEdit = () => {
  const { setModalData, user, refresh, jobs, location } =
    useContext(LocationCtx)

  const [filterError, setFilterError] = useState(false)

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      useDates: "false",
      weekday: ["0", "1", "2", "3", "4", "5", "6"],
      frequency: "all",
      jobType: "all",
      jobState: [{ value: "zaplanowane", label: "zaplanowane" }],
    },
  })

  // change other checkboxes when delete checkbox changes
  const watchDelete = watch("delete")
  useEffect(() => {
    if (watchDelete) {
      setValue("modifications.driver.apply", false)
      setValue("modifications.commentsAdd.apply", false)
      setValue("modifications.commentsReplace.apply", false)
      setValue("modifications.move.apply", false)
    }
  }, [watchDelete, setValue])

  // when changing services -> set job type to 'serwis'
  const watchSerivces = watch("modifications.services.apply")
  useEffect(() => {
    if (watchSerivces || watchSerivces === "true") setValue("jobType", "serwis")
    else setValue("jobType", "all")
  }, [watchSerivces, setValue])

  // when changing services (and equipment) -> fire next modal, else just go on with submit
  const submitWrapper = (formData) => {
    // prepare jobState filter:
    // no-admin can change only "zaplanowane" jobs
    if (!user.type.admin) formData.jobState = ["zaplanowane"]
    // if admin -> change react-select input to strings arr:
    else
      formData.jobState = formData.jobState.map(
        (jobStateEl) => jobStateEl.value
      )

    // filter jobs (to create data for submit AND show quantity in confirm modal)
    let jobsFiltered = filterJobs(jobs, formData)

    if (jobsFiltered.length === 0)
      return setFilterError("Brak zaplanowanych zadań spełniających kryteria")

    if (formData.modifications.services.apply) {
      // remove deliveries and pickups:
      jobsFiltered = jobsFiltered.filter((job) => job.type === "serwis")
      setModalData({
        show: true,
        type: "info",
        header: "Masowa edycja usług w serwisach",
        xl: true,
        hideFooter: true,
        body: (
          <ServicesBatchEditModal
            formData={formData}
            location={location}
            setModalData={setModalData}
            submit={submit}
            jobsFiltered={jobsFiltered}
            user={user}
          />
        ),
      })
    } else submit(formData, jobsFiltered)
  }
  const submit = async (formData, jobsFiltered) => {
    try {
      setModalData({
        show: true,
        type: "confirm",
        headerColor: "yellow",
        body: (
          <ConfirmModalBody formData={formData} jobsFiltered={jobsFiltered} />
        ),
        func: () =>
          submitBatchJobsChange(formData, jobsFiltered, setModalData, refresh),
      })
    } catch (err) {
      console.log(err)
      setFilterError(
        `Błąd programu -> proszę o kontakt (informacja o błędzie: LocationsDetailsEditJobsBatch.submit, loc ${location._id}`
      )
    }
  }

  return (
    <Form onSubmit={handleSubmit(submitWrapper)}>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Zakres dat{" "}
              {errors.useDates && (
                <Badge pill variant="warning">
                  wybierz opcję
                </Badge>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <Form.Check
                inline
                {...register("useDates")}
                type="radio"
                label="tak"
                value="true"
                id="useDates.true"
              />
            </td>
            <td>
              <Form.Check
                inline
                {...register("useDates", { required: true })}
                type="radio"
                label="nie"
                value="false"
                id="useDates.false"
              />
            </td>
          </tr>

          {watch("useDates") === "true" && (
            <>
              <tr>
                <td className="fixed-values-table">
                  data OD{" "}
                  {errors.startDate && (
                    <Badge pill variant="warning">
                      wprowadź datę
                    </Badge>
                  )}
                </td>
                <td className="fixed-values-table">
                  data DO{" "}
                  {errors.startDate && (
                    <Badge pill variant="warning">
                      wprowadź datę
                    </Badge>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Control
                    {...register("startDate", {
                      validate: (value) =>
                        getValues("useDates") === "true" && !!value,
                    })}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </td>
                <td>
                  <Form.Control
                    {...register("endDate", {
                      validate: (value) =>
                        getValues("useDates") === "true" && !!value,
                    })}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </td>
              </tr>
            </>
          )}
          <tr>
            <td colSpan="2" className="fixed-values-table">
              Dzień tygodnia (
              <Form.Check
                inline
                name="allWeekdays"
                type="checkbox"
                label=" wszystkie"
                value=""
                id="allWeekdays"
                defaultChecked={true}
                onClick={(e) => {
                  if (e.target.checked)
                    setValue("weekday", ["0", "1", "2", "3", "4", "5", "6"])
                  else setValue("weekday", [])
                }}
              />
              )
              {errors.weekday && (
                <Badge pill variant="warning">
                  wybierz przynajmniej jeden dzień
                </Badge>
              )}
            </td>
          </tr>

          <tr>
            <td colSpan="2">
              {weekDaysMap.map((day) => (
                <Form.Check
                  inline
                  key={`weekday-checkboxes-${day.number}`}
                  {...register("weekday", { required: true })}
                  type="checkbox"
                  label={day.label}
                  value={day.number}
                  id={`weekday-checkboxes-${day.number}`}
                />
              ))}
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="fixed-values-table">
              Częstotliwość
            </td>
          </tr>
          <tr>
            <td>
              <Form.Control
                {...register("frequency")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                <option value="all">wszystkie </option>
                <option value="days">co X dni</option>
                <option value="weeks">co X tygodni</option>
                <option value="occurance">co X wystąpień</option>
              </Form.Control>
            </td>
            <td>
              {watch("frequency") !== "all" ? (
                <>
                  {errors.frequencyValue && (
                    <Badge pill variant="warning">
                      {errors.frequencyValue.message}
                    </Badge>
                  )}
                  <Form.Control
                    {...register("frequencyValue", {
                      required: "Pole wymagane",
                    })}
                    as="input"
                    type="number"
                    className="small-number-input"
                    step="1"
                    autoComplete="chrome-off"
                  />
                </>
              ) : null}
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="fixed-values-table">
              Typ zadania
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Form.Control
                {...register("jobType")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                <option value="all">wszystkie</option>
                <option>serwis</option>
                <option>zabranie</option>
                <option>dostarczenie</option>
              </Form.Control>
            </td>
          </tr>
          {user.type.admin ? (
            <>
              <tr className="bg-danger">
                <td colSpan="2">Stan zadania</td>
              </tr>
              <tr className="bg-danger">
                <td colSpan="2">
                  <Controller
                    control={control}
                    name="jobState"
                    render={({ field: { onChange } }) => {
                      const optionsArr = [
                        "wszystkie",
                        "zaplanowane",
                        "zlecone",
                        "wykonane",
                        "niewykonane (my)",
                        "niewykonane (klient)",
                        "odwołane (my)",
                        "odwołane (klient)",
                        "usunięte",
                        "archiwum",
                      ].map((opt) => ({ value: opt, label: opt }))

                      return (
                        <Select
                          onChange={(val) => {
                            // when "all" selected -> change val to all options except "all" option
                            if (
                              val.find((valsEl) => valsEl.value === "wszystkie")
                            )
                              val = optionsArr.slice(1)
                            return onChange(val)
                          }}
                          value={watch("jobState")}
                          isMulti={true}
                          options={optionsArr}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                        />
                      )
                    }}
                  />
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <td colSpan="2" className="fixed-values-table">
              <Row className="ml-1">Dotyczące usługi</Row>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Form.Control
                {...register("service")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                <option value="all">wszystkie</option>
                {location.services
                  .filter((srv) => srv.state === "aktywna")
                  .map((locSrv) => (
                    <option
                      value={locSrv._id}
                      key={`serviceSelectOpt-${locSrv._id}`}
                    >
                      {locSrv.locSrvNo || "?"} {locSrv.name}
                    </option>
                  ))}
              </Form.Control>
              {
                //$ I show checkbox if changes should occure only when job contains ONLY choosen srv
                watch("service") !== "all" ? (
                  <Form.Check
                    {...register("containOnlySrvChoosen")}
                    type="checkbox"
                    label="zadania dotyczące tylko tej usługi"
                    id="containOnlySrvChoosen"
                    readOnly={watch("service") === "all"}
                  />
                ) : null
              }
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              <b>Co chcesz zrobić?</b>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Form.Check
                inline
                {...register("delete")}
                type="checkbox"
                label="usunąć"
                value={true}
                id="delete"
              />
              <Form.Check
                {...register("modifications.driver.apply")}
                type="checkbox"
                label="zmienić kierowcę"
                value={true}
                id="modifications.driver.apply"
                disabled={watch("delete") === "true"}
              />
              <Form.Check
                {...register("modifications.commentsAdd.apply")}
                type="checkbox"
                label="dodać uwagę"
                value={true}
                id="modifications.commentsAdd.apply"
                // user can't simultaniously add and replace comments
                disabled={
                  watch("delete") === "true" ||
                  watch("modifications.commentsReplace.apply") === "true"
                }
              />
              <Form.Check
                {...register("modifications.commentsReplace.apply")}
                type="checkbox"
                label="nadpisać uwagi"
                value={true}
                id="modifications.commentsReplace.apply"
                // user can't simultaniously add and replace comments
                disabled={
                  watch("delete") === "true" ||
                  watch("modifications.commentsAdd.apply") === "true"
                }
              />
              <Form.Check
                {...register("modifications.move.apply")}
                type="checkbox"
                label="przesunąć"
                value="true"
                id="modifications.move.apply"
                disabled={watch("delete") === "true"}
              />

              <Form.Check
                {...register("modifications.services.apply")}
                type="checkbox"
                label="edytować usługi i sprzęt"
                value="true"
                id="modifications.services.apply"
                disabled={watch("delete") === "true"}
                style={{ display: user.perm.jobs.e ? "block" : "none" }}
              />
            </td>
          </tr>
          {watch("delete") && (
            <tr>
              <td colSpan="2">
                <Alert variant="danger">
                  Uwaga! Zamierzasz usunąć WSZYSTKIE zadania spełniające
                  określone kryteria (nie tylko te widoczne na kalendarzu)
                </Alert>
              </td>
            </tr>
          )}
          {watch("modifications.driver.apply") && (
            <tr>
              <td colSpan="2">
                <Form.Control
                  {...register("modifications.driver.newValue")}
                  as="select"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                >
                  {user.drivers.map((drv) => {
                    if (drv.state === "aktywny" && drv.branch[location.branch])
                      return (
                        <option
                          key={`driver-select-${drv._id}`}
                          value={drv._id}
                        >
                          {drv.fullName}
                        </option>
                      )
                    else return null
                  })}
                </Form.Control>
              </td>
            </tr>
          )}
          {watch("modifications.commentsAdd.apply") && (
            <tr>
              <td colSpan="2">
                Treść do DODANIA do uwag:
                <Form.Control
                  {...register("modifications.commentsAdd.newValue")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          )}
          {watch("modifications.commentsReplace.apply") && (
            <tr>
              <td colSpan="2">
                <Alert variant="warning">
                  Uwagi wszystkich zadań spełniających kryteria zostaną usunięte
                  i zastąpione poniższą treścią
                </Alert>
                <Form.Control
                  {...register("modifications.commentsReplace.newValue")}
                  as="textarea"
                  type=""
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
            </tr>
          )}
          {watch("modifications.move.apply") && (
            <tr>
              <td colSpan="2">
                Liczba dni ("-1" - dzień wcześniej, "1" - dzień później):
                <Form.Control
                  inline
                  {...register("modifications.move.newValue", {
                    validate: (v) => v !== "0" && !!v,
                  })}
                  as="input"
                  type="number"
                  className="small-number-input"
                  step="1"
                  autoComplete="chrome-off"
                />
                {errors.modifications?.move?.newValue && (
                  <Badge pill variant="warning">
                    Podaj niezerową wartość
                  </Badge>
                )}
              </td>
            </tr>
          )}
          {watch("modifications.services.apply") && (
            <tr>
              <td colSpan="2">
                Kliknięcie "zastosuj" spowoduje przejście do wyboru usług i
                sprzętu
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {filterError && <Alert variant="warning">{filterError}</Alert>}
      <Row className="justify-content-between">
        <Button className="ml-3" onClick={() => setModalData({ show: false })}>
          Anuluj
        </Button>
        {watch("useDates") && (
          <Button variant="secondary" type="submit">
            Zastosuj
          </Button>
        )}
      </Row>
    </Form>
  )
}

const ConfirmModalBody = ({ formData, jobsFiltered }) => {
  const [showJobsDetails, setShowJobsDetails] = useState(false)

  const renderWeekdays = (weekdays) => {
    const weekdaysArr = []
    weekdays.forEach((day) => {
      weekdaysArr.push(
        weekDaysMap.find((mapDay) => mapDay.number === day).fullName
      )
    })

    return weekdaysArr.join(", ")
  }
  return (
    <span>
      <Alert variant="danger">
        Potwierdzenie spowoduje zmianę wszystkich <b>zaplanowanych</b> zadań
        spełniających kryteria:
        <br />
        Daty:
        {formData.useDates === "true"
          ? `od ${formData.startDate} do ${formData.endDate}`
          : "wszystkie zadania"}
        <br />
        Dzień/dni tygodnia: {renderWeekdays(formData.weekday)}
        <br />
        <b>Liczba zadań do modyfikacji: {jobsFiltered.length}</b>
        <Button size="sm" onClick={() => setShowJobsDetails(!showJobsDetails)}>
          {showJobsDetails ? "Ukryj" : "Pokaż"} szczegóły
        </Button>
        {showJobsDetails ? (
          <div>
            {jobsFiltered
              .map((job) => format(new Date(job.date), "yyyy-MM-dd"))
              .join(", ")}
          </div>
        ) : null}
      </Alert>
    </span>
  )
}
export default LocationJobsBatchEdit
