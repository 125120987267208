import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useRef,
} from "react"
import _ from "lodash"
import { useForm, Controller } from "react-hook-form"
import { format, startOfTomorrow } from "date-fns"
import { Container, Row, Button, Form, Alert } from "react-bootstrap"
import { Helmet } from "react-helmet"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"

import { createJobTooltip } from "./utils"
import RoutesPlanningRender from "./Render"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"
import RoutesPlanningJobSelectModal from "./modals/JobSelectModal"

import { multipleMarkerSvg, createMarker } from "./markers"

const LogCtx = createContext()

const RoutesPlanningFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isMapLoading, setIsMapLoading] = useState(true)
  // I keep opened tab state in Fetch to preserve tab when refreshing data
  //! map.fitToBounds is not working if map tab is not displayed as first
  const [tabSelected, setTabSelected] = useState("map")
  const [configs, setConfigs] = useState(null)
  const [jobs, setJobs] = useState([])
  const [noToiletJobs, setNoToiletJobs] = useState([])
  const [shortTermJobs, setShortTermJobs] = useState([])
  const [suspendedJobs, setSuspendedJobs] = useState([])
  const [branchSelected, setBranchSelected] = useState(null)
  const [cars, setCars] = useState([])
  const [showSettled, setShowSettled] = useState(false)

  const [pageTitle, setPageTitle] = useState("Planowanie")

  const [noCoords, setNoCoords] = useState([]) // arr to hold all jobs without coords

  // I use belows refs in working with maps, which means it gets changed in useEffect.
  //ESlint throw warning to not use reqular variables in useEffect... and I obey ;)
  const mapRef = useRef(null)
  const map = useRef(null)
  const mapBounds = useRef(null)
  const infoWindow = useRef(null)

  const { register, handleSubmit, getValues, watch, control } = useForm({
    defaultValues: {
      branch: user.defaultBranch || user.allowedBranches[0],
      date: format(startOfTomorrow(), "yyyy-MM-dd"),
      showSettled: false,
    },
  })

  const watchDate = watch("date")

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setBranchSelected(getValues("branch"))
        if (!user.perm.locations.r)
          return setModalData({
            show: false,
            type: "alert",
            body: "Brak uprawnień",
          })
        const res = await fetch(
          `/jobs/getByDate/${watchDate}?branch=${getValues(
            "branch"
          )}&getLastDoneBy=true`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `${(await res.text()) || "błąd przy pobieraniu zadań"}`
          )
        let resJSON = await res.json()

        // check if all jobs has coords, if not -> push it to noCoords (which stops rendering map etc)
        const noCoordsArr = resJSON
          .map((job) => {
            if (!job.location.coords.coordinates.length) return job.location
            else return null
          })
          .filter((el) => el)

        setNoCoords(_.uniqWith(noCoordsArr, _.isEqual))

        //* create array of jobs that has no-toilet services attached
        const noToiletJobsArr = resJSON.filter((job) => {
          // check job services types:
          const noToiletJobSrvs = job.services.filter((jobSrv) => {
            return jobSrv.serviceRef.type !== "toalety"
          })
          return noToiletJobSrvs.length
        })
        setNoToiletJobs(noToiletJobsArr)

        //* create array of jobs that has shortTerm services attached
        const shortTermJobsArr = resJSON
          .filter((job) => {
            // check job services chargeType:
            const noToiletJobSrvs = job.services.filter((jobSrv) => {
              return (
                jobSrv.serviceRef.chargeType === "krótki termin" ||
                jobSrv.serviceRef.chargeType === "ryczałt impreza"
              )
            })
            return noToiletJobSrvs.length
          })
          .sort((a, b) => {
            const aStartHour = a.startTime || 0
            const bStartHour = b.startTime || 0
            if (aStartHour > bStartHour) return 1
            if (aStartHour < bStartHour) return -1
            return 0
          })
        setShortTermJobs(shortTermJobsArr)

        //* create array of jobs that are suspended due to debt collection:
        const suspendedJobsArr = resJSON.filter(
          (job) => job.location.customer.jobsSuspended
        )
        setSuspendedJobs(suspendedJobsArr)

        //  prepare jobs
        let jobsReady = resJSON.map((job) => {
          // I'm also checking if there are other markers with exact same coordinates - if so I set markerMultipleJobs = true
          const coordCheck = resJSON.filter((rawJob) => {
            if (
              rawJob.location.coords.coordinates[0] ===
                job.location.coords.coordinates[0] &&
              rawJob.location.coords.coordinates[1] ===
                job.location.coords.coordinates[1]
            )
              return true
            else return false
          })

          // if there are more then one job with same loc -> check if this job is the first one if so -> set markerMultipleJobs=true, drawMarker=true
          // THIS will be only marker created for this loc and it will represent all jobs (I do it to prevent clustering)
          if (coordCheck.length > 1 && coordCheck[0]._id === job._id) {
            job.drawMarker = true
            job.markerMultipleJobs = true
          }

          // so if it is not first job on this location -> set drawMarker = false
          if (coordCheck.length > 1 && coordCheck[0]._id !== job._id) {
            job.drawMarker = false
            job.markerMultipleJobs = true
          }

          return job
        })

        // filter out settled jobs if user chooses to
        if (!showSettled)
          jobsReady = jobsReady.filter(
            (job) => job.state === "zaplanowane" || job.state === "zlecone"
          )

        setJobs(jobsReady)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `${err.message || "Błąd pobierania danych"}`,
        })
      }
    }

    const fetchConfigs = async () => {
      try {
        const res = await fetch(
          `/configs/jobState,GOOGLE_MAPS_API_KEY,jobType,eqpTypes`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        return setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `${err.message || "Błąd pobierania danych"}`,
        })
      }
    }
    const fetchCars = async () => {
      try {
        const res = await fetch(`/cars/getFiltered`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            branches: user.allowedBranches,
            query: "",
            type: "all",
          }),
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: `Brak uprawnień`,
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        return setCars(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `${err.message || "Błąd pobierania danych"}`,
        })
      }
    }

    const fetchAll = async () => {
      try {
        setIsMapLoading(true)
        if (isLoading && isInitialFetch) {
          await Promise.all([fetchJobs(), fetchConfigs(), fetchCars()])
          setIsInitialFetch(false)
        } else if (isLoading) {
          await Promise.all([fetchJobs(), fetchCars()])
        }
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchAll()
  }, [user, isLoading, getValues, isInitialFetch, watchDate, showSettled])

  //# prepare google map api script:
  useEffect(() => {
    const initMap = () => {
      if (window.google) {
        map.current = new window.google.maps.Map(mapRef.current)

        // create (or push to ref) mapBounds to use later when showing map (separate effect)
        mapBounds.current = new window.google.maps.LatLngBounds()

        infoWindow.current = new window.google.maps.InfoWindow({
          map: map.current,
        })
        try {
          // create markers:
          // eslint-disable-next-line
          const markers = jobs
            .map((job) => {
              const coords = {
                lat: job.location.coords.coordinates[1],
                lng: job.location.coords.coordinates[0],
              }

              if (job.markerMultipleJobs && !job.drawMarker) return null

              // some sources say, that this should be async, can't understand why
              //! but if it fails sometime try to create async or use for loop
              const marker = new window.google.maps.Marker({
                position: coords,
                map: map.current,
                job: job,
                icon: {
                  path: job.markerMultipleJobs
                    ? multipleMarkerSvg
                    : createMarker(job.services),
                  fillColor: job.driver?.markerColor || "black",
                  fillOpacity: 1,
                  scale: 0.15,
                  strokeColor: "black",
                  strokeOpacity: 1,
                  anchor: new window.google.maps.Point(0, 120),
                },
              })

              if (job.markerMultipleJobs) {
                const multipleJobsArr = jobs.filter((el) => {
                  if (
                    el.location.coords.coordinates[0] ===
                      job.location.coords.coordinates[0] &&
                    el.location.coords.coordinates[1] ===
                      job.location.coords.coordinates[1]
                  )
                    return el
                  else return false
                })
                const eventEffect = () =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    header: "Wybór zadania do edycji",
                    body: (
                      <RoutesPlanningJobSelectModal
                        user={user}
                        jobs={multipleJobsArr}
                        setModalData={setModalData}
                        refresh={() => {
                          setModalData({ show: false })
                          setIsLoading(true)
                        }}
                        configs={configs}
                      />
                    ),
                  })

                // when multiple marker - on left and right click open jobs list
                marker.addListener("contextmenu", eventEffect)
                marker.addListener("click", eventEffect)
              } else {
                marker.addListener("click", () => {
                  let content = createJobTooltip(job)
                  infoWindow.current.setContent(content)

                  infoWindow.current.open(map.current, marker)
                })

                marker.addListener("contextmenu", () =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Edycja zadania",
                    body: (
                      <JobDetailsModal
                        user={user}
                        job={job}
                        setModalData={setModalData}
                        refresh={() => {
                          setModalData({ show: false })
                          setIsLoading(true)
                        }}
                        configs={configs}
                        parent="Logistics"
                        location={job.location}
                        cars={cars}
                      />
                    ),
                  })
                )
              }
              mapBounds.current.extend(coords)
              return marker
            })
            .filter((el) => el)
        } catch (err) {
          setModalData({
            show: true,
            type: "alert",
            body: err.message || "błąd",
          })
        }
        setIsMapLoading(false)
      }
    }

    // push div with google api key to DOM:
    if (
      !document.getElementById("googleMapScript") &&
      !isLoading &&
      configs?.GOOGLE_MAPS_API_KEY &&
      !noCoords.length // prevent rendering when there are any jobs without coords
    ) {
      const googleMapScript = document.createElement("script")
      googleMapScript.id = "googleMapScript"
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${configs.GOOGLE_MAPS_API_KEY}&libraries=places`

      // set event listener and fire map initialization when div loaded:
      window.document.body.appendChild(googleMapScript)
      googleMapScript.addEventListener("load", initMap)

      // on refreshing run initMap to recreate object:
    } else if (
      !isLoading &&
      document.getElementById("googleMapScript") &&
      !noCoords.length // prevent rendering when there are any jobs without coords
    ) {
      initMap()
    }
  }, [isLoading, configs, jobs, user, noCoords, cars])

  // when map.fitBounds is called with hidden map it takes map div size = 0, so when user shows it again it is zoomed out.
  // thats why on every tab change I check if map is displayed, if so -> I only now run map.fitBounds()
  useEffect(() => {
    if (
      document.getElementById("googleMapScript") &&
      tabSelected === "map" &&
      mapBounds.current // to prevent error when all jobs are without coords
    ) {
      // also I set padding, because I'm using infowindow on mouseover what causes map to dance around to show it
      map.current.fitBounds(mapBounds.current, 180)
    }
  }, [isMapLoading, tabSelected])

  const renderContent = () => {
    if (noCoords.length)
      return (
        <>
          <Alert variant="danger">
            Poniższe lokalizacje nie mają ustawionych współrzędnych - kliknij w
            nazwę aby otworzyć w nowej karcie
          </Alert>
          <ul>
            {noCoords.map((loc) => (
              <li>
                <a
                  href={`/Locations/${loc._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {loc.name}
                </a>
              </li>
            ))}
          </ul>
        </>
      )
    else
      return (
        <RoutesPlanningRender
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          noToiletJobs={noToiletJobs}
          shortTermJobs={shortTermJobs}
          suspendedJobs={suspendedJobs}
        />
      )
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Row className="justify-content-center">
        {/* on submit just force refresh, fetch is getting form data with getValues() */}
        <Form inline onSubmit={handleSubmit(() => setIsLoading(true))}>
          <Form.Label>
            Dzień
            <Form.Control
              {...register("date")}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Form.Label>

          <Form.Label className="ml-3">
            Oddział
            <Form.Control
              {...register("branch")}
              as="select"
              className=""
              autoComplete="chrome-off"
            >
              {user.allowedBranches.map((branch) => {
                return (
                  <option value={branch} key={`branch-select-option-${branch}`}>
                    {branch}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Label>
          <Button type="submit" className="ml-3">
            Wczytaj
          </Button>
          <Button
            type="button"
            className="ml-3"
            onClick={() => setIsLoading(true)}
          >
            Odśwież
          </Button>
          <Controller
            name="showSettled"
            control={control}
            render={({ field }) => (
              <Form.Check
                className="ml-3"
                type="checkbox"
                label="pokaż odhaczone"
                value={field.value}
                onChange={(e) => {
                  setShowSettled(e.target.checked)
                  field.onChange(e)
                }}
                id="showSettled"
              />
            )}
          />
        </Form>
      </Row>
      <hr />
      <LogCtx.Provider
        value={{
          user: user,
          setIsLoading: setIsLoading,
          jobs: jobs,
          shortTermJobs: shortTermJobs,
          cars: cars,
          setModalData: setModalData,
          map: { mapRef: mapRef, map: map },
          date: watchDate,
          configs: configs,
          branchSelected: branchSelected,
          dateSelected: watchDate,
          refresh: () => {
            setIsLoading(true)
            setModalData({ show: false })
          },
          setPageTitle: setPageTitle,
        }}
      >
        {isLoading ? "Pobieram dane..." : renderContent()}
        <MyModal modalData={modalData} setModalData={setModalData} />
      </LogCtx.Provider>
    </Container>
  )
}
export { RoutesPlanningFetch, LogCtx }
