import React from "react"
import { Container, Table } from "react-bootstrap"
const LocationFAQ = () => {
  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <td>Nazwa</td>
            <td>Przykład</td>
            <td>Rozwiązanie</td>
            <td>Uwagi</td>
          </tr>
          <tr>
            <td>Usunięcie/edycja usługi z wielu zadań</td>
            <td>
              Serwisy zaplanowane do końca roku, klient zmienia liczbę toalet
              ale rezygnuje z części usług{" "}
            </td>
            <td>Usunąć serwisy przy pomocy MEZ, dodać nowe</td>
            <td>ERP-221</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default LocationFAQ
