const submitTodo = async (
  setModalData,
  formData,
  refresh,
  isNew,
  dirtyFields
) => {
  try {
    const res = await fetch(`${isNew ? "/todos/add" : "/todos/edit"}`, {
      method: isNew ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ todo: formData, dirtyFields: dirtyFields }),
    })

    if (res.status !== 200)
      throw new Error(
        `${res.status}: ${(await res.text()) || "brak komunikatu błędu"}`
      )
    setModalData({
      show: true,
      type: "info",
      headerColor: "success",
      header: "Sukces",
      body: isNew ? "Zadanie dodane" : "Zadanie edytowane",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: err.message || "Nieokreślony błąd działania programu",
    })
  }
}

export { submitTodo }
