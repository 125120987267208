import { Col, Form, Row, Table } from "react-bootstrap"
import { getEqpDesc } from "../../utils/getEqpDesc"
import _ from "lodash"
import { InventorySettlingCtx } from "./Fetch"
import React, { useContext } from "react"

const InventorySettlingRender = () => {
  const { jobs, inventoryFormState, setInventoryFormState } =
    useContext(InventorySettlingCtx)
  const jobsGrouped = _.groupBy(jobs, (job) => job.driver?.fullName || "brak")
  console.log("jobsGrouped", jobsGrouped)
  return (
    <>
      {Object.keys(jobsGrouped).map((driver) => {
        return (
          <React.Fragment key={`driverTable-${driver || "noDriver"}`}>
            <h3>{driver}</h3>
            <Table>
              <thead>
                <tr>
                  <th>Ulica</th>
                  <th>Nazwa</th>
                  <th>Klient</th>
                  <th>Kierowca</th>
                  <th>Sprzęt (NUMER - KOLOR - ZAWIESIA - TYP)</th>
                </tr>
              </thead>
              <tbody>
                {jobsGrouped[driver].map((job) => {
                  return (
                    <tr key={`driver-${driver._id}-job-${job._id}-row`}>
                      <td>{job.location.street}</td>
                      <td>{job.location.name}</td>
                      <td>{job.location.customer.shortName}</td>
                      <td>{job.driver?.fullName || "brak"}</td>
                      <td>
                        {renderSrvAndEqp(
                          job,
                          inventoryFormState,
                          setInventoryFormState
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </React.Fragment>
        )
      })}
    </>
  )
}

const renderSrvAndEqp = (job, inventoryFormState, setInventoryFormState) => {
  return (
    <>
      {job.services.map((srv) => {
        return (
          <React.Fragment key={`job-${job._id}-srv-${srv._id}-row`}>
            <h5>{srv.serviceRef.name}</h5>
            {srv.eqp.map((eqp) => {
              if (
                eqp.ref.type === "Toaleta" &&
                srv.serviceRef.chargeType !== "krótki termin" &&
                srv.serviceRef.chargeType !== "ryczałt impreza"
              ) {
                return (
                  <React.Fragment
                    key={`job-${job._id}-srv-${srv._id}-eqp-${eqp._id}-formRow`}
                  >
                    <Row>
                      <Col>{getEqpDesc(eqp, srv, false, true)}</Col>
                    </Row>
                    {renderFormRows(
                      eqp,
                      inventoryFormState,
                      job.location,
                      srv,
                      setInventoryFormState
                    )}
                  </React.Fragment>
                )
              } else return null
            })}
          </React.Fragment>
        )
      })}
    </>
  )
}

const renderFormRows = (
  eqp,
  inventoryFormState,
  location,
  srv,
  setInventoryFormState
) => {
  const locSrv = location.services.find(
    (locSrvEl) => locSrvEl._id === srv.locationServiceRef
  )
  const locEqp = locSrv.equipment.find(
    (locEqpEl) => locEqpEl.ref._id === eqp.ref._id
  )
  const rowsArr = []

  // when eqp is not longer on location but still in job don't render inputs
  if (!locEqp) {
    return null
  }

  for (let i = 0; i < eqp.qty; i++) {
    rowsArr.push(
      <Row
        key={`loc-${location._id}-srv-${srv._id}-eqp-${eqp._id}-inputs-${i}`}
      >
        <Col>
          <Form.Control
            value={getInputValue(
              inventoryFormState,
              location._id,
              srv.locationServiceRef,
              eqp.ref._id,
              i,
              "numbers"
            )}
            onChange={(e) => handleInputChange(e, setInventoryFormState)}
            name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.numbers.${i}`}
          />
        </Col>
        <Col>
          <Form.Control
            value={getInputValue(
              inventoryFormState,
              location._id,
              srv.locationServiceRef,
              eqp.ref._id,
              i,
              "colors"
            )}
            onChange={(e) => handleInputChange(e, setInventoryFormState)}
            name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.colors.${i}`}
          />
        </Col>
        <Col xs={"auto"}>
          <Form.Check
            checked={getInputValue(
              inventoryFormState,
              location._id,
              srv.locationServiceRef,
              eqp.ref._id,
              i,
              "liftSystems"
            )}
            onChange={(e) => handleInputChange(e, setInventoryFormState)}
            name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.liftSystems.${i}`}
          />
        </Col>
        <Col>
          <Form.Control
            value={getInputValue(
              inventoryFormState,
              location._id,
              srv.locationServiceRef,
              eqp.ref._id,
              i,
              "types"
            )}
            onChange={(e) => handleInputChange(e, setInventoryFormState)}
            name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.types.${i}`}
          />
        </Col>
      </Row>
    )
  }
  const qtyDiff = locEqp.qty - eqp.qty
  if (qtyDiff > 0) {
    rowsArr.push(
      <Row
        key={`loc-${location._id}-srv-${srv._id}-eqp-${eqp._id}-inputsDivider`}
      >
        <Col>
          <hr />
          <p>
            <b>NIE SERWISOWAĆ, TYLKO DO SPISANIA!</b>
          </p>
        </Col>
      </Row>
    )
    for (let i = eqp.qty; i < qtyDiff + eqp.qty; i++) {
      rowsArr.push(
        <Row
          key={`loc-${location._id}-srv-${srv._id}-eqp-${eqp._id}-inputs-${i}`}
        >
          <Col>
            <Form.Control
              value={getInputValue(
                inventoryFormState,
                location._id,
                srv.locationServiceRef,
                eqp.ref._id,
                i,
                "numbers"
              )}
              onChange={(e) => handleInputChange(e, setInventoryFormState)}
              name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.numbers.${i}`}
            />
          </Col>
          <Col>
            <Form.Control
              value={getInputValue(
                inventoryFormState,
                location._id,
                srv.locationServiceRef,
                eqp.ref._id,
                i,
                "colors"
              )}
              onChange={(e) => handleInputChange(e, setInventoryFormState)}
              name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.colors.${i}`}
            />
          </Col>
          <Col xs={"auto"}>
            <Form.Check
              checked={getInputValue(
                inventoryFormState,
                location._id,
                srv.locationServiceRef,
                eqp.ref._id,
                i,
                "liftSystems"
              )}
              onChange={(e) => handleInputChange(e, setInventoryFormState)}
              name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.liftSystems.${i}`}
            />
          </Col>
          <Col>
            <Form.Control
              value={getInputValue(
                inventoryFormState,
                location._id,
                srv.locationServiceRef,
                eqp.ref._id,
                i,
                "types"
              )}
              onChange={(e) => handleInputChange(e, setInventoryFormState)}
              name={`${location._id}.${srv.locationServiceRef}.${eqp.ref._id}.types.${i}`}
            />
          </Col>
        </Row>
      )
    }
  }

  return rowsArr
}

const getInputValue = (
  inventoryFormState,
  loc_id,
  srv_id,
  eqp_id,
  i,
  propName
) => {
  return inventoryFormState[loc_id][srv_id][eqp_id][propName][i] || ""
}
const handleInputChange = (e, setInventoryFormState) => {
  const [loc_id, srv_id, eqp_id, propName, i] = e.target.name.split(".")
  setInventoryFormState((prev) => {
    const prevClone = _.cloneDeep(prev)
    console.log("prev", prev)
    console.log("prevClone", prevClone)
    console.log("changed prop", prevClone[loc_id][srv_id])
    prevClone[loc_id][srv_id][eqp_id][propName][i] =
      propName === "liftSystems" ? e.target.checked : e.target.value
    return prevClone
  })
}

export { InventorySettlingRender }
