import React, { useState, useEffect, useContext } from "react"
import { useCookies } from "react-cookie"
import { Redirect } from "react-router-dom"

import { Container } from "react-bootstrap"

import UserContext from "../../contexts/userContext"

import MyModal from "../../utils/Modals"

import ConsumptionsEditRender from "./Render"

const ConsumptionsEditFetch = (props) => {
  const [cookies] = useCookies()
  const [isNew] = useState(() =>
    // used to tell if adding or editing
    props.match?.params.id === "new" || props.isNew ? true : false
  )
  const [consumption, setConsumption] = useState()
  const [redirect, setRedirect] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const user = useContext(UserContext)
  const [mainGoods, setMainGoods] = useState()
  const [normalGoods, setNormalGoods] = useState()
  const [employees, setEmployees] = useState()

  const [dateToDisplay, setDateToDisplay] = useState({
    month: 1,
    year: new Date().getFullYear(),
  })

  useEffect(() => {
    // set date to display in consumptionMainTable (if today is > 10 -> display todays month, else display previous):
    const setDate = () => {
      const today = new Date()
      // for January:
      if (today.getMonth() === 0 && today.getDate() < 10) {
        const dateObj = { month: 12, year: today.getFullYear() - 1 }
        setDateToDisplay(dateObj)
      } else if (today.getDate() > 10) {
        setDateToDisplay((dateToDisplay) => {
          return { ...dateToDisplay, month: today.getMonth() + 1 }
        })
      } else {
        setDateToDisplay((dateToDisplay) => {
          return { ...dateToDisplay, month: today.getMonth() }
        })
      }
    }
    setDate()

    const fetchGoods = async () => {
      try {
        const res = await fetch("/goods/active", {
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        resJSON.sort((a, b) => a.priority - b.priority)

        setMainGoods(
          resJSON.filter((good) => good.isActive && good.type === "main")
        )
        setNormalGoods(
          resJSON.filter((good) => good.isActive && good.type === "normal")
        )
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    const fetchEmployees = async () => {
      try {
        const res = await fetch("/employees/all", {
          method: "GET",
        })
        const resJSON = await res.json()
        if (res.status !== 200) {
          throw resJSON
        }
        setEmployees(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchConsumption = async () => {
      try {
        const res = await fetch(
          `/consumptions/getById/?id=${props.match.params.id}`
        )

        if (res.status !== 200) throw res
        const resJSON = await res.json()
        resJSON.driver = resJSON.driver._id // for react-hook-form to set proper defaultValue
        setConsumption(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchArr = [fetchEmployees(), fetchGoods()]
    if (!isNew) {
      fetchArr.push(fetchConsumption())
    } else setConsumption({ mainGoodsConsumptions: [{}] })

    Promise.all(fetchArr).then(() => setisLoading(false))
  }, [cookies.jwt, isNew, props.match])

  const onSubmit = async (data) => {
    try {
      let url = ""
      if (isNew) {
        data.state = "oczekujące"
        url = "/consumptions/add"
      } else {
        data._id = consumption._id
        url = "/consumptions/edit"
      }
      const res = await fetch(url, {
        method: isNew ? "POST" : "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      })

      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status === 409)
        return setModalData({
          show: true,
          type: "info",
          body: "Zużycie ma stan 'zapisane' - nie można edytować",
        })
      if (res.status !== 200) throw res
      else
        setModalData({
          show: true,
          type: "info",
          body: "Zużycie zapisanie",
          id: "consumptionSaved",
          onHide: isNew
            ? () => window.location.reload()
            : () => setRedirect(true),
        })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd zapisywania danych",
      })
    }
  }

  return (
    <Container fluid>
      {redirect ? <Redirect to={`/consumption/${consumption._id}`} /> : null}
      {isLoading ? null : (
        <ConsumptionsEditRender
          isNew={isNew}
          onSubmit={onSubmit}
          consumption={consumption}
          employees={employees}
          setModalData={setModalData}
          mainGoods={mainGoods}
          normalGoods={normalGoods}
          dateToDisplay={dateToDisplay}
          user={user}
        />
      )}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default ConsumptionsEditFetch
