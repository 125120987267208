import React from "react"
import _ from "lodash"

const JobsDone = ({ renderReportRow, reportData }) => {
  return (
    <>
      <tr>
        <td style={{ textAlign: "center" }}>Srw. DT wyk.</td>
        {renderReportRow("jobsDoneLong")}
      </tr>

      <tr>
        <td style={{ textAlign: "center" }}>Srw. KT wyk.</td>
        {renderReportRow("jobsDoneShort")}
      </tr>

      <tr>
        <td style={{ textAlign: "center" }}>Srw. TK wyk.</td>
        {renderReportRow("jobsDoneCust")}
      </tr>
      {reportData.jobsDoneErrors.reduce((a, b) => a + b, 0) ? (
        <tr>
          <td style={{ textAlign: "center" }}>Srw. wyk. - BŁĘDY</td>
          {renderReportRow("jobsDoneErrors")}
        </tr>
      ) : null}
    </>
  )
}
const JobsUndone = ({ renderReportRow, reportData }) => {
  return (
    <>
      <tr>
        <td style={{ textAlign: "center" }}>Srw. DT n-wyk.</td>
        {renderReportRow("jobsUndoneLong")}
      </tr>

      <tr>
        <td style={{ textAlign: "center" }}>Srw. KT n-wyk.</td>
        {renderReportRow("jobsUndoneShort")}
      </tr>

      <tr>
        <td style={{ textAlign: "center" }}>Srw. TK n-wyk.</td>
        {renderReportRow("jobsUndoneCust")}
      </tr>
      {reportData.jobsUndoneErrors.reduce((a, b) => a + b, 0) ? (
        <tr>
          <td style={{ textAlign: "center" }}>Srw. n-wyk. - BŁĘDY</td>
          {renderReportRow("jobsUndoneErrors")}
        </tr>
      ) : null}
    </>
  )
}

const AvgJobsDonePrices = ({ reportData }) => {
  const renderAvgPriceRow = (incomeRowName, jobsRowName) => {
    const row = reportData[incomeRowName].map((el, i) => {
      if (reportData[jobsRowName][i] !== 0)
        return (
          <td key={`avgJobPriceRow-${incomeRowName}-${jobsRowName}-${i}`}>
            {_.round(
              Number.parseFloat(reportData[incomeRowName][i]) /
                Number.parseFloat(reportData[jobsRowName][i]),
              2
            )}
          </td>
        )
      else
        return (
          <td key={`avgJobPriceRow-${incomeRowName}-${jobsRowName}-${i}`}>
            bd
          </td>
        )
    })

    return row
  }

  return (
    <>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. DT</td>
        {renderAvgPriceRow("incomeLongToilets", "jobsDoneLong")}
      </tr>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. KT</td>
        {renderAvgPriceRow("incomeShortToilets", "jobsDoneShort")}
      </tr>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. TK</td>
        {renderAvgPriceRow("incomeCustomersToilets", "jobsDoneCust")}
      </tr>
    </>
  )
}
const AvgJobsPrices = ({ reportData }) => {
  const renderAvgPriceRow = (
    incomeRowName,
    jobsDoneRowName,
    jobsUndoneRowName
  ) => {
    const row = reportData[incomeRowName].map((el, i) => {
      if (
        reportData[jobsDoneRowName][i] !== 0 &&
        reportData[jobsUndoneRowName][i] !== 0
      )
        return (
          <td key={`avgJobPriceRow-${incomeRowName}-${jobsDoneRowName}-${i}`}>
            {_.round(
              Number.parseFloat(reportData[incomeRowName][i]) /
                (Number.parseFloat(reportData[jobsUndoneRowName][i]) +
                  Number.parseFloat(reportData[jobsDoneRowName][i])),
              2
            )}
          </td>
        )
      else
        return (
          <td key={`avgJobPriceRow-${incomeRowName}-${jobsDoneRowName}-${i}`}>
            bd
          </td>
        )
    })

    return row
  }

  return (
    <>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. DT</td>
        {renderAvgPriceRow(
          "incomeLongToilets",
          "jobsDoneLong",
          "jobsUndoneLong"
        )}
      </tr>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. KT</td>
        {renderAvgPriceRow(
          "incomeShortToilets",
          "jobsDoneShort",
          "jobsUndoneShort"
        )}
      </tr>
      <tr>
        <td style={{ textAlign: "center" }}>Śr. cena za srw. TK</td>
        {renderAvgPriceRow(
          "incomeCustomersToilets",
          "jobsDoneCust",
          "jobsUndoneCust"
        )}
      </tr>
    </>
  )
}

export { JobsDone, JobsUndone, AvgJobsPrices, AvgJobsDonePrices }
