const generalSubmit = async (
  data,
  dirtyFields,
  setModalData,
  refresh,
  preventEmail = false
) => {
  try {
    // remove notes and contracts to prevent overriding it when update made by lower perm user (and he didn't get board notes)
    delete data.notes
    const res = await fetch(
      `/employees/update${preventEmail ? "?noEmail=true" : ""}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // source: "EmployeesDetailsGeneralEdit", // I'm setting it to prevent rewriting perms for admin, more details in api (employeesCtrl.update)
        },
        body: JSON.stringify({ data: data, dirtyFields: dirtyFields }),
      }
    )
    if (res.status === 403 || res.status === 409) {
      console.log(res)
      throw new Error(await res.text())
    } else if (res.status !== 200) throw res
    else
      return setModalData({
        show: true,
        type: "info",
        body: "Dane zapisane",
        header: "Sukces!",
        id: "success",
        onHide: refresh,
        // onHide: () => window.location.reload(),
      })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: err.message || "Błąd zapisywania danych",
    })
  }
}

const contractSubmit = async (
  contractInd,
  contract,
  employee,
  dirtyFields,
  setModalData,
  refresh
) => {
  try {
    const data = {
      contract: contract,
      employee_id: employee._id,
      dirtyFields: dirtyFields,
    }

    if (contractInd === -1) {
      data.newContract = true
    } else {
      data.newContract = false
      data.contractInd = contractInd
    }

    const res = await fetch("/employees/updateContract", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
    if (res.status === 403 || res.status === 409) {
      console.log(res)
      throw new Error(await res.text())
    }

    if (res.status !== 200) throw res

    return setModalData({
      show: true,
      type: "info",
      body: "Zapisano",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: err.message || "Błąd pobierania dancyh",
    })
  }
}

const deleteContract = async (
  employee_id,
  contractInd,
  setModalData,
  refresh
) => {
  try {
    const res = await fetch("/employees/deleteContract", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        employee_id: employee_id,
        contractInd: contractInd,
      }),
    })
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "info",
        body: "Brak uprawnień",
      })
    if (res.status !== 200) throw res

    refresh()
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd pobierania danych",
    })
  }
}

const noteSubmit = async (
  noteInd,
  note,
  employee,
  dirtyFields,
  setModalData,
  refresh
) => {
  try {
    const data = {
      note: note,
      employee_id: employee._id,
      dirtyFields: dirtyFields,
    }
    if (noteInd === -1) {
      data.newNote = true
    } else {
      data.newNote = false
    }

    const res = await fetch("/employees/updateNotes", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
    if (res.status === 403 || res.status === 409) {
      console.log(res)
      throw new Error(await res.text())
    }

    if (res.status !== 200) throw res

    return setModalData({
      show: true,
      type: "info",
      body: "Zapisano",
      onHide: refresh,
      id: "success",
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: err.message || "Błąd pobierania dancyh",
    })
  }
}

const deleteNote = async (employee_id, noteInd, setModalData, refresh) => {
  try {
    const res = await fetch("/employees/deleteNote", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        employee_id: employee_id,
        noteInd: noteInd,
      }),
    })
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "info",
        body: "Brak uprawnień",
      })
    if (res.status !== 200) throw res

    refresh()
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd pobierania dancyh",
    })
  }
}

const uploadFile = async (
  file,
  entity,
  fileName,
  entity_id,
  employee_id,
  setModalData,
  refresh
) => {
  try {
    const formData = new FormData()
    formData.append("file", file, fileName)
    const res = await fetch(
      `/employees/uploadFile?employee_id=${employee_id}&entity=${entity}&entity_id=${entity_id}`,
      {
        method: "POST",
        body: formData,
      }
    )
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "alert",
        body: "Brak uprawnień",
      })
    if (res.status !== 200) throw res
    setModalData({
      show: true,
      type: "info",
      body: "Plik zapisany",

      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd komunikacji z serwerem",
    })
  }
}

const deleteFile = async (
  entity,
  entity_id,
  employee_id,
  file_id,
  fileName,
  setModalData,
  refresh
) => {
  try {
    const res = await fetch("/employees/deleteFile", {
      headers: { "Content-Type": "application/json" },
      method: "DELETE",
      body: JSON.stringify({
        entity: entity,
        entity_id: entity_id,
        employee_id: employee_id,
        file_id: file_id,
        fileName: fileName,
      }),
    })
    if (res.status === 403)
      return setModalData({
        show: true,
        type: "alert",
        body: "Brak uprawnień",
      })
    if (res.status !== 200) throw res
    setModalData({
      show: true,
      type: "info",
      body: "Plik usunięty",
      onHide: refresh,
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: "Błąd komunikacji z serwerem",
    })
  }
}

export {
  generalSubmit,
  contractSubmit,
  deleteContract,
  noteSubmit,
  deleteNote,
  uploadFile,
  deleteFile,
}
