import React, { useState, useEffect, useContext } from "react"

import { useCookies } from "react-cookie"

import { Container, Button, Modal } from "react-bootstrap"

import UserContext from "../contexts/userContext"

import BranchConsumptionForm from "./branchConsumptionForm"
import BranchConsumptionRender from "./branchConsumptionRender"

const BranchConsumptionFetch = () => {
  const [consumptions, setConsumptions] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const [cookies] = useCookies()

  const user = useContext(UserContext)

  const [alert, setAlert] = useState({
    display: false,
    title: null,
    body: null,
    branches: null,
  })

  const [formData, setFormData] = useState({
    month: 1,
    year: 0,
    branches: [], //branches are setted from user perm or from config (for admin)
    isReady: false, //date is caluculated with useEffect, and displayed when ready and branches are setted
  })

  useEffect(() => {
    // set default date (if today < 10 => set previous month) and branches (if Admin = fetch all branches config, if normal user = set branches from UserContext)
    const getBranches = async () => {
      try {
        let userBranches = []

        const today = new Date()
        let month = 0
        let year = new Date().getFullYear()
        if (today.getMonth() === 0 && today.getDate() < 10) {
          month = 12
          year = today.getFullYear() - 1
        } else if (today.getDate() > 10) {
          month = today.getMonth() + 1
        } else {
          month = today.getMonth()
        }

        if (user.type.admin) {
          const res = await fetch("/configs/branches", {
            headers: { authorization: cookies.jwt },
            method: "GET",
          })
          const resJSON = await res.json()
          userBranches = resJSON.branches
        } else {
          userBranches = Object.keys(user.branch).filter(
            (branch) => user.branch[branch]
          )
        }
        setFormData(() => {
          return {
            month: month,
            year: year,
            branches: userBranches,

            isReady: true,
          }
        })
      } catch (err) {
        console.log(err)
        setAlert({ display: true, title: "Błąd", body: err })
      }
    }
    getBranches()
  }, [cookies.jwt, user.branch, user.type.admin])

  const handleSubmit = async (data) => {
    try {
      const res = await fetch(
        `/consumptions/getByDate/?month=${data.month}&year=${data.year}`,
        {
          headers: {
            authorization: cookies.jwt,
            "content-type": "application/json",
          },
          method: "GET",
        }
      )

      const resJSON = await res.json()

      if (res.status !== 200) throw resJSON.msg

      if (data.branch === "all") {
        setConsumptions(resJSON)
      } else {
        const branchConsumptions = resJSON.filter((cons) =>
          cons.consumptionHeader.branch === data.branch ? cons : null
        )
        setConsumptions(branchConsumptions)
      }

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setAlert({ display: true, title: "Błąd", body: err })
    }
  }

  const handleCloseAlert = () =>
    setAlert({ display: false, title: null, body: null })

  return (
    <Container>
      {formData.isReady ? (
        <BranchConsumptionForm
          formData={formData}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {isLoading ? null : (
        <BranchConsumptionRender consumptions={consumptions} />
      )}
      <Modal show={alert.display} onHide={handleCloseAlert}>
        <Modal.Header>
          <Modal.Title>{alert.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{alert.body}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseAlert}>Zamknij</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
export default BranchConsumptionFetch
