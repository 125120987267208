import React from "react"
import { Row, Col, Form, Badge } from "react-bootstrap"

const CustomerParams = ({ register, watch, errors, user, customer, isNew }) => {
  const debtCollectionValidate = (val) => {
    // when debtCollectionCat set to "czerwony" don't check anything
    if (val === "czerwony") return true

    // new customer and set to 'zwykły' or 'czerwony' - no check
    if (isNew && (val === "zwykły" || val === "czerwony")) return true
    // new customer and set to 'zielony' - only with higher perm
    if (
      isNew &&
      val === "zielony" &&
      !user.perm.debtCollection.d &&
      !user.type.board
    )
      return "brak uprawnień do nadania tej kategorii"

    // when debtCollectionCat changed to "zielony" or FROM "czerwony" check perms

    if (
      ((customer.debtCollectionCat !== "zielony" && val === "zielony") ||
        (customer.debtCollectionCat === "czerwony" &&
          (val === "zielony" || val === "zwykły"))) &&
      !user.perm.debtCollection.d &&
      !user.type.board
    ) {
      return "brak uprawnień do takiej zmiany"
    } else return true
  }

  return (
    <Row>
      <Col>
        {errors.debtCollectionCat && (
          <Badge pill variant="warning">
            {errors.debtCollectionCat.message}
          </Badge>
        )}
        Kategoria windykacyjna
        <Form.Control
          {...register("debtCollectionCat", {
            validate: debtCollectionValidate,
          })}
          as="select"
          className=""
          autoComplete="chrome-off"
        >
          <option value="zwykły">zwykły</option>
          <option value="czerwony">czerwony</option>
          <option value="zielony">zielony</option>
        </Form.Control>
        {watch("isPrivat") === "false" && (
          <>
            Forma działalności
            <Form.Control
              {...register("params.form")}
              as="select"
              type=""
              className=""
              autoComplete="chrome-off"
            >
              {[
                "sp. z o.o.",
                "działalność",
                "sp. z o.o. SK",
                "SA",
                "SK",
                "sp. os.",
                "fundacja",
                "inna",
              ].map((form) => (
                <option key={`form-select-${form}`} value={form}>
                  {form}
                </option>
              ))}
            </Form.Control>
          </>
        )}
        Zasięg:
        <Form.Control
          {...register("params.range")}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
        >
          {[
            "lokalny",
            "regionalny",
            "multi-regionalny",
            "krajowy",
            "światowy",
          ].map((range) => (
            <option key={`range-select-${range}`} value={range}>
              {range}
            </option>
          ))}
        </Form.Control>
        Typ
        <Form.Check
          {...register("params.type")}
          type="checkbox"
          label="towary"
          value="towary"
          id="params towary"
        />
        <Form.Check
          {...register("params.type")}
          type="checkbox"
          label="usługa"
          value="usługa"
          id="params usługa"
        />
      </Col>
      <Col>
        Wielkość:
        <Form.Control
          {...register("params.size")}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
        >
          {["mikro", "mały", "średni", "duży", "nd."].map((size) => (
            <option key={`size-select-${size}`} value={size}>
              {size}
            </option>
          ))}
        </Form.Control>
        Ryzyko:
        <Form.Control
          {...register("params.risk")}
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
        >
          {["brak", "małe", "średnie", "duże"].map((risk) => (
            <option key={`risk-select-${risk}`} value={risk}>
              {risk}
            </option>
          ))}
        </Form.Control>
        Branża
        <Form.Check
          {...register("params.industry")}
          type="checkbox"
          label="budowlana"
          value="budowlana"
          id="params budowlana"
        />
        <Form.Check
          {...register("params.industry")}
          type="checkbox"
          label="imprezy"
          value="imprezy"
          id="params imprezy"
        />
        <Form.Check
          {...register("params.industry")}
          type="checkbox"
          label="JAK"
          value="JAK"
          id="params JAK"
        />
        <Form.Check
          {...register("params.industry")}
          type="checkbox"
          label="mundurowa"
          value="mundurowa"
          id="params mundurowa"
        />
      </Col>
    </Row>
  )
}

const fetchCustomerData = async (
  vatNumber,
  setValue,
  setFetchingCustData,
  setAlert
) => {
  try {
    setFetchingCustData(true)
    setAlert(null)
    const vatNumberClear = vatNumber.replace(/\s|-/g, "")
    // veryfing vat number is done on backend (or should be)
    const res = await fetch(`/misc/rejestrIoFetch/${vatNumberClear}`)
    if (res.status === 404) throw new Error("brak wyników")
    if (res.status !== 200) {
      const resText = await res.text()
      throw resText
    }
    const resJSON = await res.json()

    setValue("name", resJSON.nazwy.pelna)
    setValue("shortName", resJSON.nazwy.skrocona)
    setValue("regon", resJSON.numery.regon)
    setValue("regNumber", resJSON.numery.krs)

    setFetchingCustData(false)
  } catch (err) {
    console.log(err)
    setAlert(err.message)
    setFetchingCustData(false)
  }
}

export { CustomerParams, fetchCustomerData }
