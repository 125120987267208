import React, { useState, useContext, useEffect, createContext } from "react"
import { useForm } from "react-hook-form"
import { format, startOfYesterday, differenceInCalendarDays } from "date-fns"
import { Container, Row, Form, Button, Alert, Toast } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"

import RoutesSettlingRender from "./Render"

const LogCtx = createContext()

const RoutesSettlingFetch = () => {
  const user = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isInitialFetch, setIsInitialFetch] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [configs, setConfigs] = useState(null)
  const [jobs, setJobs] = useState(null)
  // create state to hold selected branch - used in driver filter and when editting jobs to show only drivers from branch
  const [branchSelected, setBranchSelected] = useState(
    user.defaultBranch || user.allowedBranches[0]
  )
  const [formErr, setFormErr] = useState(null)
  const [toast, setToast] = useState(null)

  const { register, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      branch: user.defaultBranch || user.allowedBranches[0],
      date: format(startOfYesterday(), "yyyy-MM-dd"),
    },
  })
  const watchDate = watch("date")

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // change branch:
        setBranchSelected(getValues("branch"))

        if (!user.perm.locations.r)
          return setModalData({
            show: false,
            type: "alert",
            body: "Brak uprawnień",
          })

        const res = await fetch(
          `/jobs/getByDate/${watchDate}?branch=${getValues("branch")}`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `${res.status} - ${
              (await res.text()) || "nieokreślony błąd pobierania danych"
            }`
          )
        const resJSON = await res.json()

        setJobs(resJSON)

        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: err.message || "Nieokreślony błąd działania programu",
        })
      }
    }

    const fetchConfigs = async () => {
      try {
        const res = await fetch(`/configs/jobState,GOOGLE_MAPS_API_KEY`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()

        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchAll = async () => {
      try {
        if (isLoading && isInitialFetch) {
          await Promise.all([fetchJobs(), fetchConfigs()])
          setIsInitialFetch(false)
        } else if (isLoading) {
          // prevent setting date larger then today (except if user.type.tester chooses to do so)
          if (
            !getValues("ignoreValidating") &&
            differenceInCalendarDays(new Date(), new Date(watchDate)) < 0
          ) {
            return setFormErr(
              "Zasadniczo odhaczanie zadań z przyszłości jest niewskazane. Jeśli masz zdolności wieszczenia zgłoś się proszę do mnie - odblokuję taką możliwość i pewnie będę chciał jeszcze w innych celach wykorzystać Twoje umiejętności ;)."
            )
          } else setFormErr(null)
          await fetchJobs()
        }
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchAll()
  }, [isLoading, isInitialFetch, getValues, user, watchDate])
  return (
    <LogCtx.Provider
      value={{
        user: user,
        jobs: jobs,
        configs: configs,
        setModalData: setModalData,
        refresh: (toast = null) => {
          setModalData({ show: false })
          setIsLoading(true)
          if (toast) setToast(toast)
        },
        branchSelected: branchSelected,
      }}
    >
      <Container fluid>
        <Row className="justify-content-center">
          {/* on submit just force refresh, fetch is getting form data with getValues() */}
          <Form inline onSubmit={handleSubmit(() => setIsLoading(true))}>
            {user.type.tester && (
              <Form.Check
                {...register("ignoreValidating")}
                type="checkbox"
                label="zignoruj ograniczenia (data nie późniejsza niż dziś)"
                value={true}
                id="ignoreValidating"
              />
            )}
            <Form.Label>
              Dzień
              <Form.Control
                {...register("date")}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
            </Form.Label>

            <Form.Label>
              Oddział
              <Form.Control
                {...register("branch")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                {user.allowedBranches.map((branch) => {
                  return (
                    <option
                      value={branch}
                      key={`branch-select-option-${branch}`}
                    >
                      {branch}
                    </option>
                  )
                })}
              </Form.Control>
            </Form.Label>
            <Button type="submit">Wczytaj</Button>
          </Form>
        </Row>
        {isLoading ? "Pobieram dane..." : <RoutesSettlingRender />}
        {formErr && <Alert variant="warning">{formErr}</Alert>}
      </Container>
      <MyModal modalData={modalData} setModalData={setModalData} />
      <Toast
        show={toast ? true : false}
        delay={5000}
        autohide
        onClose={() => setToast(null)}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "20%",
        }}
      >
        <Toast.Header
          style={{ justifyContent: "space-between" }}
          className={toast?.headerColor || ""}
        >
          {toast?.header}
        </Toast.Header>
        <Toast.Body>{toast?.body}</Toast.Body>
      </Toast>
    </LogCtx.Provider>
  )
}
export { RoutesSettlingFetch, LogCtx }
