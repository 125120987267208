import React, { useState, useContext, useEffect } from "react"
import { Container } from "react-bootstrap"
import {
  differenceInCalendarDays,
  endOfMonth,
  startOfMonth,
  subMonths,
  format,
} from "date-fns"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"
import { IncomeReportForm } from "./Form"
import { IncomeReportRender } from "./Render"

const IncomeReportFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [filters, setFilters] = useState({
    startDate: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    endDate: format(endOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    branches: user.allowedBranches
      .filter((branch) => branch !== "TEST")
      .map((branch) => ({
        value: branch,
        label: branch,
      })),
    gross: false,
    invoiceDate: false,
  })
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [data, setData] = useState({})
  const [reportErrors, setReportErrors] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        //* validate:
        setIsLoading(true)
        setFormErrors({})
        if (
          differenceInCalendarDays(
            new Date(filters.endDate),
            new Date(filters.startDate)
          ) > 35
        )
          return setFormErrors({ dates: "Maksymalny okres to 1 miesiąc" })

        const reportParams = {
          ...filters,
          branches: filters.branches.map((branch) => branch.value),
        }

        const res = await fetch("/reports/income", {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reportParams),
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych: ${res.status} - ${await res.text()}`
          )
        const resJSON = await res.json()
        setData(resJSON.reportData)
        setReportErrors(resJSON.errors)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
    // don't want to rerender on each filter change:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCounter])

  return (
    <Container fluid>
      <IncomeReportForm
        user={user}
        filters={filters}
        setFilters={setFilters}
        setRefreshCounter={setRefreshCounter}
        refreshCounter={refreshCounter}
        formErrors={formErrors}
        isLoading={isLoading}
      />
      {isLoading ? (
        renderSpinner()
      ) : (
        <IncomeReportRender
          data={data}
          reportErrors={reportErrors}
          setModalData={setModalData}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default IncomeReportFetch
