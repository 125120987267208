import React from "react"

import { Table, Container } from "react-bootstrap"

const PermTable = () => {
  return (
    <Container fluid>
      <p>
        co daje <u>samodzielnie</u> dane uprawnienie
      </p>
      <p>
        jeśli nie napisałam inaczej <b>wprost</b>, to uprawnienia dotyczą tylko
        oddziałów użytkownika
      </p>
      <Table>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>-</th>
            <th>r</th>
            <th>w</th>
            <th>e</th>
            <th>d</th>
            <th>uwagi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>transaction</td>
            {/* - */}
            <td>
              Nie może nawet wyświetlić <b>żadnej</b> transakcji
            </td>
            {/* R */}
            <td>
              Może wyświetlać transakcje po bezpośrednim linku, chyba że ma też
              conajmniej transactionList.r
            </td>
            {/* W */}
            <td>
              Może tworzyć transakcje i je oglądać, także z poziomu tabeli (ale
              tylko swoje)
            </td>
            {/* E */}
            <td>Może edytować, ale tylko swoje transakcje</td>
            {/* D */}
            <td>
              Może usuwać, ale tylko swoje (tymczasowo niedostępne, issue #208,
              usuwanie tylko z transactionsList.d)
            </td>
          </tr>
          <tr>
            <td>transactionsList</td>
            <td>
              {/* - */}
              Nie może wyświetlać transakcji, chyba że ma uprawnienia
              transaction.w - wtedy widzi listę swoich transakcji
            </td>
            {/* R */}
            <td>Może wyświetlić wszystkie transakcje</td>
            {/* W */}
            <td>Brak</td>
            {/* E */}
            <td>Może edytować dowolną transakcję</td>
            {/* D */}
            <td>
              Może usunąć dowolną transakcję, może edytować dowolną transakcję
              niezależnie od jej stanu
            </td>
            <td>
              Wszystko to w zakresie oddziałów, do których jest przypisany
            </td>
          </tr>
          <tr>
            <td>transactionState</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>Brak - stan i tak jest widoczny z poziomu podglądu</td>
            {/* W */}
            <td>Może zmieniać stan dowolnej transakcji "w prawo"</td>
            {/* E */}
            <td>
              Może przywracać z 'zaproformowano' do 'oczekująca' (kierownicy)
            </td>
            {/* D */}
            <td>Może dowolnie zmieniać stan transakcji (super user)</td>
            <td></td>
          </tr>
          <tr>
            <td>consumption</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>brak</td>
            {/* W */}
            <td>
              może dodawać zużycia, na liście widzi tylko dodane przez siebie
            </td>
            {/* E */}
            <td>może edytować swoje zużycia</td>
            {/* D */}
            <td>może usuwać swoje zużycia</td>
          </tr>
          <tr>
            <td>consumptionsList</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>widzi wszystkie* zużycia</td>
            {/* W */}
            <td>brak</td>
            {/* E */}
            <td>może edytować dowolne* zużycie</td>
            {/* D */}
            <td>może usuwać dowolne* zużycie, może edytować STAN zużycia</td>
            <td>* - w ramach oddziałów, do których ma uprawnienia</td>
          </tr>
          <tr>
            <td>sewageReport</td>
            {/* - */}
            <td>brak dostępu</td>
            {/* R */}
            <td>Może wyświetlać raporty i zezwolenia</td>
            {/* W */}
            <td>Może generować raporty i dodawać zezwolenia</td>
            {/* E */}
            <td>Może edytować dane dot. zezwoleń i je archiwizować</td>
            {/* D */}
            <td>Może usuwać raporty i zezwolenia</td>
          </tr>

          <tr>
            <td>customersList</td>
            {/* - */}
            <td>
              brak dostępu - nie ma możliwości wyświetlania klientów jako
              zagnieżdżonego komponentu (np w transakcjach)
            </td>
            {/* R */}
            <td>
              Może wyświetlać klientów w komponentach z wbudowaną wyszukiwarką
              (np przy dodawaniu transakcji), nie może wyświetlać szczegółowych
              informacji
            </td>
            {/* W */}
            <td></td>
            {/* E */}
            <td></td>
            {/* D */}
            <td></td>
            <td>
              Uprawnienie wykorzystywane do zagnieżdżonej listy klientów (np.
              przy dodawaniu transakcji {"->"} "Wyszukaj w bazie")
            </td>
          </tr>

          <tr>
            <td>customers</td>
            {/* - */}
            <td>
              brak dostępu do listy klientów (jako niezależnego komponentu)
            </td>
            {/* R */}
            <td>Może wyświetlać listę klientów i wszystkie ich szczegóły</td>
            {/* W */}
            <td>Może dodawać pełne dane klienta</td>
            {/* E */}
            <td>Może edytować i archiwizować klienta</td>
            {/* D */}
            <td>
              Może zmieniać customer.branches i oddział lokalizacji (uprawnienie
              kierownicze. "d" bo może usunąć z oddziału? :P)
            </td>
          </tr>
          <tr>
            <td>employees</td>
            {/* - */}
            <td>brak dostępu do listy pracowników</td>
            {/* R */}
            <td>
              Może wyświetlać listę pracowników, których jest przełożonym.
            </td>
            {/* W */}
            <td>brak - dodawanie przez admina</td>
            {/* E */}
            <td>Może edytować widoczne dla siebie* dane</td>
            {/* D */}
            <td>
              może usuwać notatki i umowy swoich pracowników.{" "}
              <u>Usuwanie pracowników</u> tylko przez admina
            </td>
            <td>
              * - jeśli perm.board - dostęp do wszystkich pracowników, w innym
              wypadku tylko do pracowników, dla których jest wksazany jako
              przełożony. board może usuwać załączniki
            </td>
          </tr>
          <tr>
            <td>locations</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>podgląd na lokalizacje, możliwość wydruku raportu zadań</td>
            {/* W */}
            <td></td>
            {/* E */}
            <td></td>
            {/* D */}
            <td></td>
            <td>
              Zapomniałem dodać opisu uprawnienia w trakcie jego
              implementowania, dlatego jest trochę niepełny...
            </td>
          </tr>
          <tr>
            <td>jobs</td>
            {/* - */}
            <td>brak - podgląd do zadań dla każdego z locations.r</td>
            {/* R */}
            <td>brak - podgląd do zadań dla każdego z locations.r</td>
            {/* W */}
            <td>Może dodawać zadania</td>
            {/* E */}
            <td>Może edytować zadania</td>
            {/* D */}
            <td>
              Może DOWOLNIE zmieniać stan (w tym na "usunięte" - stąd "d")
            </td>
            <td>
              Faktyczne usuwanie - tylko type.admin W przyszłości (elektroniczna
              kontrolka) type.driver może zmieniać stan niezależnie od innych
              uprawnień, ale tylko w określonym zakresie
            </td>
          </tr>
          <tr>
            <td>logistics</td>
            {/* - */}
            <td>brak dostępu do modułów logistycznych</td>
            {/* R */}
            <td>Podgląd na trasy, brak możliwości nanoszenia zmian</td>
            {/* W */}
            <td>Może planować trasy</td>
            {/* E */}
            <td>Może odhaczać trasy</td>
            {/* D */}
            <td>Może przywracać zadania z zleconych na zaplanowane</td>
            <td>
              Nie to samo co jobs - z jobs można np. edytować tylko per
              lokalizacja, z logistics można korzystać z zaawansowanych
              narzędzi, w tym masowej edycji zadań wg różnych kryteriów
              GENEROWANIE FIXTURES - type.tester + logistics.d
            </td>
          </tr>

          <tr>
            <td>servicesManage</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>Dostęp (sam podgląd) do listy i szczegółów usług</td>
            {/* W */}
            <td>Dodawanie usług</td>
            {/* E */}
            <td>Edycja usług</td>
            {/* D */}
            <td>Zmiana stanu usługi</td>
            <td></td>
          </tr>
          <tr>
            <td>eqpManage</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>Dostęp (sam podgląd) do listy i szczegółów sprzętu</td>
            {/* W */}
            <td>Dodawanie sprzętu</td>
            {/* E */}
            <td>Edycja sprzętu</td>
            {/* D */}
            <td>Zmiana stanu sprzętu</td>
            <td></td>
          </tr>
          <tr>
            <td>invoicing</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>Widzi menu "Fakturowanie"</td>
            {/* W */}
            <td>?? Możliwość fakturowania zbiorczego klienta</td>
            {/* E */}
            <td>Może edytować fakturę i wystawiać "dowolną"</td>
            {/* D */}
            <td>
              Może usuwać faktury i zmieniać datę "zafakturowano do" parametru
            </td>
          </tr>
          <tr>
            <td>carsManage</td>
            {/* - */}
            <td></td>
            {/* R */}
            <td>Podgląd listy samochodów</td>
            {/* W */}
            <td>Dodawanie samochodów</td>
            {/* E */}
            <td>Edycja samochodów</td>
            {/* D */}
            <td>Usuwanie... samochodów!</td>
          </tr>
          <tr>
            <td>todos</td>
            {/* - */}
            <td>
              Nie widzi "Do zrobienia" w menu ani stopce, nie można mu zlecać
              zadań
            </td>
            {/* R */}
            <td>Ma podgląd listy "Do zrobienia"</td>
            {/* W */}
            <td>Może dodawać zadania, widzi przycisk w stopce</td>
            {/* E */}
            <td>Może edytować zadania</td>
            {/* D */}
            <td>?</td>
          </tr>
          <tr>
            <td>incomeReport</td>
            {/* - */}
            <td>Nie widzi raportu przychodu ani statystyk oddziału</td>
            {/* R */}
            <td>
              Może wyświetlać podsumowanie przychodu i statystyki oddziałów
            </td>
            {/* W */}
            <td></td>
            {/* E */}
            <td></td>
            {/* D */}
            <td></td>
          </tr>
          <tr>
            <td>carWork</td>
            {/* - */}
            <td>Nie widzi opcji serwisy/przeglądy samochodów</td>
            {/* R */}
            <td>Może wyświetlać listę i poszczególne wpisy</td>
            {/* W */}
            <td>Może dodawać wpisy i edytować swoje (ale nie usuwać)</td>
            {/* E */}
            <td>
              Może edytować <b>i usuwać</b> dowolne wpisy samochodów dotyczących
              jego oddziału
            </td>
            {/* D */}
            <td>
              Może edytować i usuwać dowolne wpisy (<b>SUPERUSER</b> - omija
              oddziały)
            </td>
          </tr>
          <tr>
            <td>debtCollection</td>
            {/* - */}
            <td>
              dostęp standardowy: kategoria windykacyjna i notatki windykacyjne.{" "}
              <u>Możliwość przypisywania kategorii "zwykły" i "czerwony"</u>
            </td>
            {/* R */}
            <td>BRAK</td>
            {/* W */}
            <td>dostęp do modułu windykacyjnego - dodawanie działań/notatek</td>
            {/* E */}
            <td>BRAK</td>
            {/* D */}
            <td>
              (<b>koordynator windykacji</b>)przypisywanie kategorii "zielony" i
              usuwanie kategorii "czerwony"
            </td>
          </tr>
          <tr>
            <td colSpan="5">admin</td>
            <td>
              Z definicji ma wszystki uprawnienia (obsłużone przy zapiywaniu
              zmian w userze) + może edytować materiały magazynowe
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

const TransactionStatusPerms = () => {
  return (
    <Container>
      <p>
        t.e - transactions.edit, tL.w - transactionsList.write etc, gdzie nie ma
        przedrostka - chodzi o uprawnienie transactionStatus
      </p>
      <Table bordered>
        <tbody>
          <tr>
            <td className="hidden-cell" />
            <td colSpan="5" className="center-content">
              Status 2
            </td>
          </tr>
          <tr>
            <td rowSpan="5" className="center-content">
              Status 1
            </td>
            <td />
            <td>oczekująca</td>
            <td>zaproformowana</td>
            <td>zafakturowana</td>
            <td>anulowana</td>
          </tr>

          <tr>
            <td>oczekująca</td>
            <td>-</td>
            <td>w</td>
            <td>w</td>
            <td>t.e (tylko swoje), tL.e</td>
          </tr>
          <tr>
            <td>zaproformowana</td>
            <td>d</td>
            <td>-</td>
            <td>w</td>
            <td>t.e (tylko swoje), tL.e</td>
          </tr>
          <tr>
            <td>zafakturowana</td>
            <td>d</td>
            <td>d</td>
            <td>-</td>
            <td>t.e (tylko swoje), tL.e</td>
          </tr>
          <tr>
            <td>anulowana</td>
            <td>t.w (tylko swoje), tL.e</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export { PermTable, TransactionStatusPerms }
