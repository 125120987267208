import React, { useContext, useState } from "react"
import { Container, Table, Form, Row, Col, Button } from "react-bootstrap"

import { CustomerCtx } from "../Fetch"
import { locationsFilter } from "../utils"

const CustomersDetailsLocationsTab = () => {
  const { locations } = useContext(CustomerCtx)
  const [filter, setFilter] = useState("")
  const [showArch, setShowArch] = useState(false)
  return (
    <Container fluid className="mt-2">
      <h4>Aktywne lokalizacje</h4>
      <Row>
        <Col xs="auto">
          <p>Filtr: </p>
        </Col>
        <Col xs="auto">
          <Form.Control
            as="input"
            autoComplete="chrome-off"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Col>
      </Row>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nazwa</td>
            <td className="fixed-values-table">Adres</td>
            <td className="fixed-values-table">Oddział</td>
          </tr>
          {locations
            .filter((loc) => locationsFilter(loc, filter))
            .map((loc) => {
              if (loc.state === "aktywna")
                return (
                  <tr
                    key={`location-row-${loc._id}`}
                    className="clickable"
                    onClick={() =>
                      window.open(
                        `/Locations/${loc._id}`,
                        "Location Details",
                        "noopener noreferrer"
                      )
                    }
                  >
                    <td>{loc.name}</td>
                    <td>
                      {loc.city || "brak miasta"} {loc.street}
                    </td>
                    <td>{loc.branch}</td>
                  </tr>
                )
              else return null
            })}
        </tbody>
      </Table>
      <Row>
        <Col xs="auto">
          <h4>Archiwalne</h4>
        </Col>
        <Col>
          <Button onClick={() => setShowArch(!showArch)}>
            {showArch ? "Ukryj" : "Pokaż"}
          </Button>
        </Col>
      </Row>
      {showArch ? (
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Nazwa</td>
              <td className="fixed-values-table">Adres</td>
              <td className="fixed-values-table">Oddział</td>
            </tr>
            {locations.map((loc) => {
              if (loc.state !== "aktywna")
                return (
                  <tr
                    key={`location-row-${loc._id}`}
                    className="clickable"
                    onClick={() =>
                      window.open(
                        `/Locations/${loc._id}`,
                        "Location Details",
                        "noopener noreferrer"
                      )
                    }
                  >
                    <td>{loc.name}</td>
                    <td>
                      {loc.city || "brak miasta"}
                      {loc.street}
                    </td>
                    <td>{loc.branch}</td>
                  </tr>
                )
              else return null
            })}
          </tbody>
        </Table>
      ) : null}
    </Container>
  )
}
export default CustomersDetailsLocationsTab
