import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Container, Row, Button, Form, Table, Alert } from "react-bootstrap"
import { format, subDays } from "date-fns"

import EqpFilter from "./EqpFilter"

const LocationChangeEqpNum = ({
  setModalData,
  oldEqp,
  srv,
  eqpTypes,
  loc_id,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [valError, setValError] = useState(false)
  const [equipment, setEquipment] = useState([])
  const [eqpSelected, setEqpSelected] = useState([])
  const [eqpFetched, setEqpFetched] = useState([])

  useEffect(() => {
    const showEqp = () => {
      const newEqp = eqpFetched.map((eqp) => {
        if (eqp.isTemplate || eqp.number === 0) return { ...eqp, qty: 0 }
        else return eqp
      })
      setEquipment(
        _.uniqWith(_.union(eqpSelected, newEqp), (a, b) => a._id === b._id)
      )
    }
    showEqp()

    // eslint wants to put eqpSelected in dependencies, but I can't see reason why should I do so
    //eslint-disable-next-line
  }, [eqpFetched])

  const fetchEqpChange = async () => {
    try {
      setValError(false)
      const newQty = eqpSelected.reduce((acc, red) => {
        if (red.qty) return (acc += red.qty)
        else return (acc += 1)
      }, 0)

      if (newQty !== oldEqp.qty)
        return setValError(
          "Wybierz tyle nowego sprzętu, aby ilość była zgodna ze starym"
        )

      const res = await fetch("/locations/handleEqpNumChange", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          loc_id: loc_id,
          srv: srv,
          oldEqp_id: oldEqp._id,
          eqp: eqpSelected,
          date: document.getElementById("date").value,
        }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )
      setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Zmiany naniesione",
        onHide: () => window.location.reload(),
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `${err.message || "Nieokreślony błąd programu"}`,
      })
    }
  }

  return (
    <Container>
      <h5>Obecny sprzęt:</h5>
      <Row>
        {oldEqp.ref.type} {oldEqp.ref.subtype} {oldEqp.ref.char}{" "}
        {oldEqp.ref.color} {oldEqp.ref.mods?.join(", ") || null}
      </Row>
      {oldEqp.ref.isTemplate || oldEqp.ref.number === 0
        ? `Ilość: ${oldEqp.qty}`
        : null}
      <hr />
      <Form>
        Data zmiany:
        <Form.Control
          id="date"
          as="input"
          type="date"
          className=""
          autoComplete="chrome-off"
          defaultValue={format(subDays(new Date(), 1), "yyyy-MM-dd")}
        />
        <h5>Nowy sprzęt:</h5>
        <EqpFilter
          setIsLoading={setIsLoading}
          setEquipment={setEqpFetched}
          srv={srv}
          eqpTypes={eqpTypes}
          jobType="dostarczenie" // just cheating EqpFilters to turn on fetching all eqp (not only available in service)
        />
        {isLoading ? "Pobieram dane..." : null}
        <Table>
          <thead>
            <tr>
              <th>[ ]</th>
              <th>Typ/nazwa</th>
              <th>Ilość</th>
              <th>Podtyp</th>
              <th>Char.</th>
              <th>Kolor</th>
              <th>Kategoria</th>
              <th>Wyposażenie</th>
              <th>Numer</th>
            </tr>
          </thead>
          <tbody>
            {equipment.length === 0 ? (
              <tr>
                <td>Brak sprzętu</td>
              </tr>
            ) : null}
            {equipment.map((eqp) => (
              <tr key={`eqpRow-${eqp._id}`}>
                <td>
                  <Form.Check
                    type="checkbox"
                    id={eqp._id}
                    onChange={(e) => {
                      if (e.target.checked)
                        setEqpSelected([...eqpSelected, eqp])
                      else {
                        const newEqp = [...eqpSelected]
                        newEqp.splice(
                          eqpSelected.findIndex(
                            (eqpToRmv) => eqpToRmv._id === eqp._id
                          ),
                          1
                        )
                        setEqpSelected(newEqp)

                        // clear qty:
                        document.getElementById(`eqpQty-${eqp._id}`).value =
                          null
                      }
                    }}
                  />
                </td>
                <td>{eqp.type || eqp.name}</td>
                <td>
                  {"qty" in eqp ? (
                    <Form.Control
                      name={`eqpQty-${eqp._id}`}
                      id={`eqpQty-${eqp._id}`}
                      as="input"
                      type="number"
                      className=""
                      autoComplete="chrome-off"
                      disabled={
                        eqpSelected.findIndex(
                          (eqpEl) => eqpEl._id === eqp._id
                        ) === -1
                      }
                      onChange={(e) => {
                        const eqpInd = eqpSelected.findIndex(
                          (eqpEl) => eqpEl._id === eqp._id
                        )
                        const newEqp = [...eqpSelected]
                        newEqp[eqpInd].qty = Number.parseFloat(e.target.value)
                        setEqpSelected(newEqp)
                      }}
                    />
                  ) : (
                    "nd"
                  )}
                </td>
                <td>{eqp.subtype}</td>
                <td>{eqp.char}</td>
                <td>{eqp.color}</td>
                <td>{eqp.category}</td>
                <td>{eqp.mods?.join(", ") || null}</td>
                <td>{eqp.number || "tmpl"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Form>
      {valError ? <Alert variant="warning">{valError}</Alert> : null}
      <Row className="justify-content-between">
        <Button variant="warning" onClick={() => setModalData({ show: false })}>
          Anuluj
        </Button>
        <Button variant="secondary" onClick={() => fetchEqpChange()}>
          Podmień
        </Button>
      </Row>
    </Container>
  )
}
export default LocationChangeEqpNum
