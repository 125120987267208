const getCustomerName = (customer) => {
  if (!customer.name && !customer.surname && !customer.shortName)
    return "BŁĄD! Brak nazwy klienta!"

  if (customer.shortName) {
    return customer.shortName
  }

  // when there is customer.surname it propably is priv customer
  if (customer.surname) {
    return `${customer.surname} ${
      customer.name || "BŁĄD! Brak imienia klienta!"
    }`
  }
  // else it propably is company and has only name
  else {
    return `${customer.name || "BŁĄD! Brak nazwy klienta!"}`
  }
}

export { getCustomerName }
