import React from "react"
import _ from "lodash"
import {
  addWeeks,
  endOfDay,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  startOfWeek,
  isSameDay,
  isSameYear,
} from "date-fns"

import { Container, Alert, Row, Col, Table } from "react-bootstrap"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"

import jobStatesPng from "./jobStates.png"
import startOfDay from "date-fns/startOfDay"
import getMonth from "date-fns/getMonth"
import { renderSrvAndEqp } from "../../_shared/jobs/utils"

const createButton = (
  job,
  setModalData,
  location,
  jobState,
  user,
  refresh,
  configs,
  cars
) => {
  // set icon color depending on job state:
  let iconColor = ""
  switch (job.state) {
    case "zaplanowane":
      iconColor = "#81d4fa"
      break
    case "zlecone":
      iconColor = "#2979ff"
      break
    case "wykonane":
      iconColor = "Green"
      break

    case "odwołane (my)":
      iconColor = "#ffbf00"
      break
    case "odwołane (klient)":
      iconColor = "#ffee00"
      break
    case "niewykonane (my)":
      iconColor = "Red"
      break
    case "niewykonane (klient)":
      iconColor = "#ff9999"
      break
    case "usunięte":
      iconColor = "Brown"
      break
    case "archiwum":
      iconColor = "Grey"
      break
    default:
      iconColor = "White"
  }

  // set content depending on type:
  let content = ""
  switch (job.type) {
    case "serwis":
      content = "S"
      break
    case "dostarczenie":
      content = "D"
      break
    case "zabranie":
      content = "Z"
      break
    default:
      content = "er"
  }

  // set icon shape:
  let icon = ""
  // create array of uniq icons paths
  const iconsPaths = _.uniq(job.services.map((srv) => srv.serviceRef?.iconPath))

  if (iconsPaths.length === 1 && iconsPaths[0]) icon = iconsPaths[0]
  else if (iconsPaths.length > 1)
    icon = "M 0 -40 Q 40 -20 40 30 Q 0 50 -40 30 Q -40 -20 0 -40 Z"
  //ERROR:
  else icon = "M 0 -40 L 30 40 L -40 -10 L 40 -10 L -30 40 Z"

  const tooltip = createTooltip(job, location.services)

  return (
    <svg
      id={`jobBtn-${job.type}-${format(new Date(job.date), "yyyy-MM-dd")}`}
      viewBox="-40 -40 80 80"
      style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
      fill={iconColor}
      onClick={() =>
        setModalData({
          show: true,
          type: "info",
          header: `Szczegóły zadania (${location.name})`,
          body: (
            <JobDetailsModal
              job={job}
              setModalData={setModalData}
              location={location}
              services={location.services}
              locationName={location.name}
              jobState={jobState}
              user={user}
              refresh={refresh}
              configs={configs}
              parent="location" // used to hide 'location' btn
              cars={cars}
            />
          ),
        })
      }
      data-tip={tooltip}
    >
      <path d={icon} />
      <text x="-20" y="20" fill="black" fontSize="3.5rem">
        {content}
      </text>
    </svg>
  )
}

const calendarLegendModalBody = (shapes) => {
  return (
    <Container fluid>
      <Row>
        <h4>Komórki kalendarza:</h4>
      </Row>
      <Row>
        <span className="bg-secondaryLight">wyróżnienie</span> daty - istnieje
        aktywny parametr na ten dzień
      </Row>
      <Row>
        <h4>Kolory</h4>
      </Row>
      <Row>
        <img src={jobStatesPng} alt="" className="mx-auto" />
      </Row>
      <Row>
        <Col>
          <h4>Litery</h4>
          <p>
            precyzują typ zadania: <b>S</b> - serwis, <b>D</b> - dostarczenie,{" "}
            <b>Z</b> - zabranie
          </p>
        </Col>
      </Row>
      <h4>Kształty</h4>
      <p>
        <svg
          viewBox="-40 -40 80 80"
          style={{ width: "2rem", height: "2rem" }}
          fill="black"
        >
          <path
            d={
              "M -40 -30 L -25 -30 L -25 -40 L 40 -40 L 40 30 L 25 30 L 25 40 L -40 40 Z"
            }
          />
        </svg>{" "}
        - ta ikona oznacza, że są więcej niż dwa zadania
        <svg
          viewBox="-40 -40 80 80"
          style={{ width: "2rem", height: "2rem" }}
          fill="black"
        >
          <path d={"M 0 -40 L 30 40 L -40 -10 L 40 -10 L -30 40 Z"} />
        </svg>{" "}
        - ta ikona oznacza, że wystąpił BŁĄD
      </p>
      <p>
        pozostałe ikony określają rodzaj sprzętu dla usług podpiętych pod
        zadanie:
      </p>
      <p>
        <svg
          viewBox="-40 -40 80 80"
          style={{ width: "2rem", height: "2rem" }}
          fill="black"
        >
          <path d={"M 0 -40 Q 40 -20 40 30 Q 0 50 -40 30 Q -40 -20 0 -40 Z"} />
        </svg>{" "}
        - zadanie z podpiętymi usługami o różnych rodzajach sprzętu
      </p>
      {shapes.map((shape) => (
        <span>
          <svg
            className="m-1"
            viewBox="-40 -40 80 80"
            style={{ width: "2rem", height: "2rem" }}
            fill="black"
          >
            <path d={shape.path} />
          </svg>{" "}
          - {shape.type}
        </span>
      ))}

      <Alert variant="warning">
        <b>
          Soczyście różowe tło ikony, ikona w kształcie gwizdy albo wystąpienie
          liter "er" to BŁĄD {"->"} proszę o natychmiastowe zgłoszenie.
        </b>
      </Alert>
    </Container>
  )
}

const ChooseJobModal = ({
  jobs,
  setModalData,
  location,
  jobState,
  user,
  refresh,
  configs,
  cars,
}) => {
  return (
    <Container id="chooseJobModal">
      <Table>
        <tbody>
          <tr className="fixed-values-table">
            <td>Typ</td>
            <td>Stan</td>
            <td>Kierowca</td>
            <td>Usługi</td>
          </tr>
          {jobs.map((job) => (
            <tr
              key={`choose-job-table-${job._id}`}
              id="jobRow"
              className="clickable"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: `Szczegóły zadania (${location.name})`,
                  body: (
                    <JobDetailsModal
                      job={job}
                      setModalData={setModalData}
                      locationName={location.name}
                      jobState={jobState}
                      user={user}
                      refresh={refresh}
                      location={location}
                      configs={configs}
                      parent="location"
                      cars={cars}
                    />
                  ),
                })
              }
            >
              <td>{job.type}</td>
              <td>{job.state}</td>
              <td>{job.hasDriver ? job.driver.fullName : "BRAK"}</td>
              <td>{renderSrvAndEqp(job, location, true)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

const createTooltip = (job, locServices) => {
  return `<Table>
         <tr>
        <td>kierowca</td>
        <td>${job.hasDriver ? job.driver.fullName : "<b>BRAK</b>"}</td>
      </tr>
      <tr>
        <td>typ</td>
        <td>${job.type}</td>
      </tr>
      <tr>
        <td>stan</td>
        <td>${job.state}</td>
      </tr>
      ${
        job.customHours && (
          <tr>
            <td>godziny</td>
            <td>
              {job.startTime}
              {" - "}
              {job.endTime}
            </td>
          </tr>
        )
      }
      <tr>
        <td colspan="2">Usługi i sprzęt:</td>
      </tr>
      <tr>
        <td colspan="2">
          <ul>${renderSrvAndEqpList(job, locServices)}</ul>
        </td>
      </tr>
    </Table>`
}

const renderSrvAndEqpList = (job, locServices) => {
  if (job.services.length === 0 && job.state !== "usunięte")
    return `NATYCHMIAST ZGŁOŚ BŁĄD (brak usług dla zadania ${job._id})`
  else if (!job.services.length && job.state === "usunięte")
    return `aby sprawdzić usługi jakie były przypisane dla usuniętego zadania sprawdź historię`

  const srvRows = job.services
    .map((srv) => {
      // I don't keep service name in job.services so I have to refer to location:

      const locSrvInd = locServices.findIndex(
        (locSrv) => locSrv._id === srv.locationServiceRef
      )
      const srvEl = [
        `<b>${locServices[locSrvInd]?.locSrvNo || ""}. ${
          locServices[locSrvInd]?.name || "ZGŁOŚ BŁĄD"
        }</b></br>`,
      ]
      if (srv.subSrv.length) {
        srv.subSrv.forEach((subSrv) => {
          if (!subSrv) return srvEl.push("błąd programu - ZGŁOŚ!")
          return srvEl.push(`<i>+ ${subSrv.name}</i><br/>`)
        })
      }
      if (srv.eqp.length)
        srv.eqp.forEach((eqp) => {
          if (!eqp || !eqp.ref) return srvEl.push("błąd programu - ZGŁOŚ!")
          return srvEl.push(
            `${eqp.ref.isTemplate || eqp.ref.number === 0 ? eqp.qty : ""} ${
              eqp.ref.type
            } ${eqp.ref.subtype} ${eqp.ref.char || ""} ${eqp.ref.color || ""} ${
              eqp.ref.category || ""
            } ${eqp.ref.number || ""}<br/>`
          )
        })
      return srvEl.join("")
    })
    .join("")
  return srvRows
}

const handleCalendarDatesChange = (jobsDates, offset, setJobsDates) => {
  //* calculate new dates:
  //$ initially (offset === 0) I display 5 rows in calendar: -1 week to +3 weeks from today.
  const newDates = {
    // I need start of week '-1 - offset' from today. 'addWeeks' cuz I add neg numbers.
    // 'startOfDay' to make sure that I cover all interval while checking
    start: startOfWeek(addWeeks(new Date(), offset - 1), { weekStartsOn: 1 }),
    // as above: end of week '+3 + offset', and take end of that day
    end: endOfWeek(addWeeks(new Date(), offset + 3), { weekStartsOn: 1 }),
  }

  //* check if dates exteeds already fetched, and update jobsDates state
  //( I create separate object in case somehow there is situation that needs updating start AND end date
  //( can't imagine how could this happen,nut it seems to be safer
  const newJobsDatesState = { ...jobsDates, exclude: { ...jobsDates } }
  let shouldUpdate = false
  // (startOfWeek and endOfWeek are returning first and last second of week,
  // so I have to use startOfDay and endOfDay to prevent fetching when dates are equal)
  if (
    isBefore(new Date(newDates.start), startOfDay(new Date(jobsDates.start)))
  ) {
    newJobsDatesState.start = format(newDates.start, "yyyy-MM-dd")
    shouldUpdate = true
  }
  if (isAfter(new Date(newDates.end), endOfDay(new Date(jobsDates.end)))) {
    newJobsDatesState.end = format(newDates.end, "yyyy-MM-dd")
    shouldUpdate = true
  }

  // change jobsDates state if needed:
  if (shouldUpdate) setJobsDates(newJobsDatesState)
}

const getMonthShortcut = (mth) => {
  switch (mth) {
    case 0:
      return "sty"
    case 1:
      return "lut"
    case 2:
      return "mar"
    case 3:
      return "kwi"
    case 4:
      return "maj"
    case 5:
      return "cze"
    case 6:
      return "lip"
    case 7:
      return "sie"
    case 8:
      return "wrz"
    case 9:
      return "paź"
    case 10:
      return "lis"
    case 11:
      return "gru"

    default:
      return "BŁĄD"
  }
}

const setCellClassName = (cellDate) => {
  if (isSameDay(cellDate, new Date())) return "bg-warningLight"
  if (!isSameYear(cellDate, new Date())) return "bg-dangerLight"

  // differentiate every second mth with other background (current always without bg)
  if (getMonth(cellDate) % 2 !== getMonth(new Date()) % 2)
    return "bg-secondarySuperLight"
}

export {
  createButton,
  calendarLegendModalBody,
  ChooseJobModal,
  handleCalendarDatesChange,
  getMonthShortcut,
  setCellClassName,
}
