import React, { useContext, useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { format, differenceInCalendarDays } from "date-fns"
import { Row, Button, Form, Col, Alert, Badge } from "react-bootstrap"

import { LocationCtx } from "../Fetch"
import paramsValidation from "./validation"
import { createManualBtn } from "../../utils/buttons"

const LocationDetailsSrvEditParamModal = ({ srv, param }) => {
  const { setModalData, location, user, refresh } = useContext(LocationCtx)
  const [validateErr, setValidateErr] = useState(null)
  const { register, handleSubmit, watch, setValue, formState } = useForm({
    defaultValues: {
      ...param,
      startDate: param.startDate
        ? format(new Date(param.startDate), "yyyy-MM-dd")
        : null,
      endDate:
        param.hasEndDate && param.endDate
          ? format(new Date(param.endDate), "yyyy-MM-dd")
          : null,
      invoicedTo: param.invoicedTo
        ? format(new Date(param.invoicedTo), "yyyy-MM-dd")
        : null,
    },
  })

  const { errors, dirtyFields } = formState

  // clear value of end date when hasEndDate is unchecked
  const watchHasEndDate = watch("hasEndDate")
  useEffect(() => {
    if (!watchHasEndDate) setValue("endDate", null)
  }, [watchHasEndDate, setValue])

  const submit = async (formData) => {
    try {
      // when param was invoiced user can't edit anything other then endDate, so I disable other inputs...
      // ... but disabled inputs aren't registered in RHF and this causes errors when validating (wrong date-fns intervals)
      // that's why I set qty and startd date below for invoiced params:
      if (param.invoicedTo) {
        formData.qty = param.qty
        formData.startDate = format(new Date(param.startDate), "yyyy-MM-dd")
      }

      // simulate after-update params and run validation:
      const paramInd = srv.params.findIndex((prm) => prm._id === param._id)
      const paramsArr = [...srv.params]
      paramsArr.splice(paramInd, 1, formData)
      const validateRes = await paramsValidation(paramsArr)
      if (validateRes) return setValidateErr(validateRes)
      else setValidateErr(null)

      //* if perPcs ("za serwis") service -> qty can be only === 1
      // I force changing it on submit, user will see alert about this
      if (srv.chargeType === "za serwis" && formData.qty !== 1) {
        formData.qty = 1
        dirtyFields.qty = true
      }

      const body = {
        param: {
          ...formData,
          _id: param._id,
          invoicedTo: param.invoicedTo,
          startDate: `${formData.startDate}T12:00`,
        },
        srv: srv,
        location_id: location._id,
        dirtyFields: dirtyFields,
      }

      // set T12:00 if there is end date:
      if (formData.hasEndDate) body.param.endDate = `${formData.endDate}T12:00Z`

      // with .perm.invoicing.d user can change invoicedTo:
      if (user.perm.invoicing.d) {
        body.param.invoicedTo = formData.invoicedTo
          ? `${formData.invoicedTo}T12:00Z`
          : null
      }
      const res = await fetch("/locations/editSrvParam", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "alert",
          body: await res.text(),
        })
      if (res.status === 409)
        return setModalData({
          show: true,
          type: "alert",
          body: await res.text(),
        })
      if (res.status !== 200) throw res
      else
        return refresh({
          header: "Sukces",
          headerColor: "bg-success",
          body: "Parametr zmieniony",
        })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd programu. Zgłoszenie: edit params for loc ${
          location._id
        }, srv ${srv._id} param ${param._id} ${new Date()}`,
      })
    }
  }
  return (
    <Form onSubmit={handleSubmit(submit)}>
      {user.type.admin ? (
        <Button onClick={() => console.log(watch())}>?</Button>
      ) : null}
      {srv.chargeType !== "za serwis" ? (
        <>
          liczba{" "}
          {errors.qty?.type === "min" && (
            <Badge pill variant="warning">
              Minimalna wartość to 0,01
            </Badge>
          )}
          {errors.qty?.type === "required" && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          {errors.qty && (
            <Badge pill variant="warning">
              {errors.qty?.message}
            </Badge>
          )}
          <Form.Control
            {...register("qty", { min: 0.01, required: true })}
            as="input"
            type="number"
            className=""
            autoComplete="chrome-off"
            step="any"
            readOnly={
              // disable editing qty when invoiced or srv is short term (qty always 1)
              param.invoicedTo ||
              srv.chargeType === "ryczałt impreza" ||
              srv.chargeType === "krótki termin"
                ? true
                : false
            }
          />
        </>
      ) : null}
      <Row className="my-2">
        <Col>
          OD{" "}
          {errors.startDate && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("startDate", { required: true })}
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
            readOnly={param.invoicedTo ? true : false}
          />
        </Col>
        <Col>
          <Form.Check
            {...register("hasEndDate", {
              validate: {
                requiredForFlatRate: (v) => {
                  if (srv.chargeType === "ryczałt impreza" && v !== true)
                    return (
                      <Badge pill variant="warning">
                        Data zakończenia wymagana dla "usługa ryczałt"
                      </Badge>
                    )
                  else return true
                },
              },
            })}
            type="checkbox"
            label="DO:"
            id="hasEndDate"
          />

          {errors.endDate?.type === "required" && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("endDate", {
              required: watch("hasEndDate"),
              validate: {
                cantBeBeforeStart: (val) => {
                  if (
                    differenceInCalendarDays(
                      new Date(val),
                      new Date(watch("startDate"))
                    ) >= 0 ||
                    !watch("hasEndDate")
                  )
                    return true
                  else
                    return (
                      <Badge pill variant="warning">
                        Parametr nie może mieć ujemnej liczby dni.
                      </Badge>
                    )
                },
                flatRateCantBeTooLong: (val) => {
                  if (
                    srv.chargeType === "ryczałt impreza" &&
                    differenceInCalendarDays(
                      new Date(val),
                      new Date(watch("startDate"))
                    ) > 32
                  )
                    return (
                      <Badge pill variant="warning">
                        Usługa "ryczałt - impreza" nie może trwać dłużej niż
                        miesiąc.
                      </Badge>
                    )
                  else return true
                },
              },
            })}
            as="input"
            type="date"
            className=""
            readOnly={!watch("hasEndDate")}
            autoComplete="chrome-off"
          />
        </Col>
        {errors.hasEndDate?.message}
        {errors.endDate?.message}
      </Row>
      {srv.chargeType === "za serwis" && watch("qty") !== 1 ? (
        <Alert variant="danger">
          Uwaga! Liczba zostanie <b>automatycznie zmieniona na 1</b> w związku z
          nowymi zasadami dla usług "za serwis" {createManualBtn("perPcsSrv")}
          <br />
          <b>Zaktualizuj cenę po zapisaniu zmian!!!</b>
          <br />
          Aktualna liczba to {param.qty}
        </Alert>
      ) : null}
      <hr />
      {user.perm.invoicing.d ? (
        <Row className="mt-2">
          <Col>
            Zafakturowano do:
            <Form.Control
              {...register("invoicedTo")}
              as="input"
              type="date"
              className=""
              autoComplete="chrome-off"
            />
          </Col>
          <Col>
            <Button
              className="mt-4"
              variant="danger"
              size="sm"
              onClick={() =>
                setValue("invoicedTo", null, {
                  // set field as dirty to fire e-mail notification
                  shouldDirty: true,
                })
              }
            >
              Usuń "zafakturowano do"
            </Button>
          </Col>
        </Row>
      ) : null}
      {validateErr && (
        <Alert variant="danger">
          <b>Wykryłem błędy w parametrach usługi:</b>
          <br />
          {validateErr}
          <br />
          <u>Jeśli nie możesz naprawić błędu ręcznie - zgłoś do mnie.</u>
        </Alert>
      )}
      <Row className="justify-content-between px-3">
        <Button onClick={() => setModalData({ show: false })}>Anuluj</Button>
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export default LocationDetailsSrvEditParamModal
