import React, { useEffect, useState, useContext } from "react"

import { Toast } from "react-bootstrap"

import UserContext from "../contexts/userContext"
import MyModal from "../utils/Modals"

import CarsTableRender from "./CarsTableRender"
import { CarsTableForm } from "./utils"

const CarTableFetch = () => {
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [filters, setFilters] = useState({
    branch: "all",
    query: "",
    type: "all",
  })
  const [cars, setCars] = useState(null)
  const [toast, setToast] = useState(null)

  useEffect(() => {
    const fetchCars = async (filters) => {
      try {
        const branches =
          filters.branch === "all" ? user.allowedBranches : [filters.branch]

        const res = await fetch("/cars/getFiltered", {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          body: JSON.stringify({
            branches: branches,
            query: filters.query,
            type: filters.type,
            verbose: true,
          }),
        })
        if (res.status !== 200)
          throw new Error(
            `${res.status} - ${
              (await res.text()) || "błąd komunikacji z serwerem"
            }`
          )

        const resJSON = await res.json()
        setCars(resJSON)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `Błąd:${err.message || "nieokreślony błąd aplikacji"}`,
        })
      }
    }
    if (isLoading) fetchCars(filters)
  }, [filters, isLoading, user])

  const refresh = (toast) => {
    setModalData({ show: false })
    setIsLoading(true)
    setToast(toast)
  }
  return (
    <div>
      <CarsTableForm
        filters={filters}
        setFilters={setFilters}
        setIsLoading={setIsLoading}
        user={user}
      />
      {isLoading ? (
        "pobieram dane..."
      ) : (
        <CarsTableRender
          cars={cars}
          setModalData={setModalData}
          user={user}
          refresh={refresh}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
      <Toast
        show={toast ? true : false}
        delay={5000}
        autohide
        onClose={() => setToast(null)}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "20%",
        }}
      >
        <Toast.Header
          style={{ justifyContent: "space-between" }}
          className={toast?.headerColor || ""}
        >
          {toast?.header}
        </Toast.Header>
        <Toast.Body>{toast?.body}</Toast.Body>
      </Toast>
    </div>
  )
}
export default CarTableFetch
