import { Fragment, useContext, useMemo, useRef } from "react"
import { Button, Container, Table, Row } from "react-bootstrap"
import { writeFile, utils } from "xlsx"
import { TbTableImport } from "react-icons/tb"
import { AiOutlinePrinter } from "react-icons/ai"

import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"
import { getEqpDesc } from "../../utils/getEqpDesc"

import { LogCtx } from "./Fetch"
import { useReactToPrint } from "react-to-print"
const RoutesPlanningShortTermTab = () => {
  const { shortTermJobs, setModalData, date } = useContext(LogCtx)
  const summaryData = useMemo(() => {
    return getSummaryData(shortTermJobs)
  }, [shortTermJobs])
  const jobsTableRef = useRef(null)
  const eqpTableRef = useRef(null)

  const exportToExcel = (id, fileName) => {
    try {
      const table = document.getElementById(id)
      const workbook = utils.table_to_book(table)
      writeFile(workbook, `${fileName}.xlsx`)
    } catch (err) {
      console.log(err)
      setModalData({
        header: "Błąd",
        body: err.message,
        show: true,
      })
    }
  }

  // used when printing to force landscape
  const pageStyle = `@media print {@page { size: landscape !important; }}`

  const handlePrintJobs = useReactToPrint({
    content: () => jobsTableRef.current,
    pageStyle: pageStyle,
  })
  const handlePrintEqp = useReactToPrint({
    content: () => eqpTableRef.current,
    pageStyle: pageStyle,
  })

  return (
    <Container fluid>
      <Container
        fluid
        // for printing
        ref={jobsTableRef}
        className="landscape"
      >
        <Row className="mb-2 mt-2">
          <h4>Zadania</h4>
          <p
            // normally hidden by 'display' prop, shows on print because of className
            className="ml-auto show-for-print"
            style={{ display: "none" }}
          >
            {date}
          </p>
          <Button
            onClick={() =>
              exportToExcel("shortTerm-jobs-table", `zadania KT ${date}`)
            }
            className="ml-auto mr-3 hide-for-print"
          >
            <TbTableImport />
          </Button>
          <Button
            onClick={handlePrintJobs}
            className="ml-4 mr-3 hide-for-print"
          >
            <AiOutlinePrinter />
          </Button>
        </Row>
        <Table id="shortTerm-jobs-table">
          <thead>
            <tr>
              <th>lp</th>
              <th>godz.</th>
              <th>klient</th>
              <th>lok.</th>
              <th>zadanie</th>
              <th>usł. i sprz.</th>
              <th>kierowca</th>
              <th>uwagi</th>
            </tr>
          </thead>
          <tbody>
            {shortTermJobs.map((job, i) => (
              <JobRow job={job} i={i} key={`JobRowComponent-${job._id}`} />
            ))}
          </tbody>
        </Table>
      </Container>
      <hr />
      <Container
        fluid
        // for printing
        ref={eqpTableRef}
      >
        <Row className="my-2">
          <h4>Podsumowanie sprzętu</h4>
          <p
            // normally hidden by 'display' prop, shows on print because of className
            className="ml-auto show-for-print"
            style={{ display: "none" }}
          >
            {date}
          </p>
          <Button
            onClick={() =>
              exportToExcel("shortTerm-eqp-table", `sprzęt KT ${date}`)
            }
            className="ml-auto mr-3 hide-for-print"
          >
            <TbTableImport />
          </Button>
          <Button onClick={handlePrintEqp} className="ml-4 mr-3 hide-for-print">
            <AiOutlinePrinter />
          </Button>
        </Row>
        <Table id="shortTerm-eqp-table">
          <thead>
            <tr>
              <th>nazwa</th>
              <th>dostarczenie</th>
              <th>zabranie</th>
              <th>suma</th>
              <th>serwis</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.eqpSummary.map((eqp) => (
              <tr key={`summaryRow-${eqp.name}`}>
                <td>{eqp.name}</td>
                <td>{eqp.deliveries}</td>
                <td>{eqp.pickups}</td>
                <td>{eqp.pickups - eqp.deliveries}</td>
                <td>{eqp.handling}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Container>
  )
}

const JobRow = ({ job, i }) => {
  const { setModalData, user, configs, refresh, cars } = useContext(LogCtx)

  return (
    <tr
      className="clickable"
      key={`shortTermJobRow-${job._id}`}
      onClick={() =>
        setModalData({
          show: true,
          type: "info",
          header: "Edycja zadania",
          body: (
            <JobDetailsModal
              parent="Logistics"
              job={job}
              user={user}
              configs={configs}
              setModalData={setModalData}
              refresh={refresh}
              location={job.location}
              cars={cars}
            />
          ),
        })
      }
    >
      <td>{i + 1}</td>
      <td>{job.customHours ? `${job.startTime}-${job.endTime}` : "brak"}</td>
      <td>
        {job.location?.customer?.name
          ? `${job.location.customer.name} ${
              job.location.customer.surname || ""
            }`
          : "brak - BŁĄD!"}
      </td>
      <td>{`${job.location.street} ${job.location.city}`}</td>
      <td>{job.type}</td>
      <td>{renderSrvsCell(job)}</td>
      <td>{job.driver?.fullName || "brak"}</td>
      <td>{job.comments}</td>
    </tr>
  )
}
const renderSrvsCell = (job) => {
  const srvsCell = []
  if (!job.services?.length) return "BŁĄD - brak usług dla zadania"
  job.services.forEach((srv) => {
    srvsCell.push(
      <Fragment key={`srvLine-${srv._id}`}>
        {
          // dnot show srv name if there is only one:
          job.services.length === 1 ? null : (
            <>
              <b>{srv.serviceRef.name}</b>
              <br />
            </>
          )
        }
        {renderEqp(srv)}
      </Fragment>
    )
  })

  return srvsCell
}

const renderEqp = (srv) => {
  const eqpAndSubSrvs = []

  if (!srv.eqp?.length && !srv.subSrv?.length)
    return "BŁĄD - brak sprzętu i usług podrzędnych"

  //# add eqp rows:
  if (srv.eqp?.length)
    srv.eqp.forEach((eqp) => {
      let eqpStr = `${eqp.qty} x ${eqp.ref.type}`
      const eqpProps = ["subtype", "category", "char", "color"]
      eqpProps.forEach((prop) => {
        if (
          eqp.ref[prop] &&
          eqp.ref[prop] !== "bez znaczenia" &&
          eqp.ref[prop] !== "wybierz" &&
          eqp.ref[prop] !== "undefined"
        )
          eqpStr = `${eqpStr} ${eqp.ref[prop]}`
      })

      if (eqp.ref.mods?.length)
        eqpStr = `${eqpStr} (${eqp.ref.mods.join(", ")})`

      eqpAndSubSrvs.push(
        <Fragment key={`srvEqpLine-${srv._id}-${eqp._id}`}>
          {eqpStr}
          <br />
        </Fragment>
      )
    })

  //# add subSrvs rows:
  if (srv.subSrv?.length) {
    srv.subSrv.forEach((subSrv) =>
      eqpAndSubSrvs.push(
        <Fragment key={`srvSubSrvLine-${srv._id}-${subSrv._id}`}>
          <i>{`+ ${subSrv.name}`}</i>
          <br />
        </Fragment>
      )
    )
  }

  return eqpAndSubSrvs
}

const getSummaryData = (shortTermJobs) => {
  const eqpSummary = []
  // get eqp summary from all jobs data in format:
  /* 
[
  {
    name: "toaleta standard",
    pickups: 5,
    deliveries: 3,
    handling: 2,
  },{
    ...
  }
]
*/

  //# for each job
  shortTermJobs.forEach((job) => {
    // just for safety:
    if (!job.services)
      throw new ReferenceError(
        `Brak usług dla zadania dla lokalizacji ${job.location.street} (identyfikator zadania: ${job._id}) `
      )

    //# for each job srv

    job.services.forEach((jobSrv) => {
      if (!jobSrv.eqp) return
      //# for each job srv eqp
      jobSrv.eqp.forEach((eqp) => {
        const eqpName = getEqpDesc(eqp, {}, true, false)

        // get eqpSummary index by name
        let eqpSummaryInd = eqpSummary.findIndex(
          (eqpSummaryEl) => eqpSummaryEl.name === eqpName
        )

        // if eqpSummary index is -1, create new eqpSummary element and set index to last arr element to update qtys later
        if (eqpSummaryInd === -1) {
          eqpSummary.push({
            name: eqpName,
            pickups: 0,
            deliveries: 0,
            handling: 0,
          })
          eqpSummaryInd = eqpSummary.length - 1
        }

        //* update qtys
        if (job.type === "dostarczenie") {
          eqpSummary[eqpSummaryInd].deliveries += Number.parseFloat(eqp.qty)
        } else if (job.type === "zabranie") {
          eqpSummary[eqpSummaryInd].pickups += Number.parseFloat(eqp.qty)
        } else if (job.type === "serwis") {
          eqpSummary[eqpSummaryInd].handling += Number.parseFloat(eqp.qty)
        }
      })
    })
  })
  return { eqpSummary: eqpSummary }
}

export { RoutesPlanningShortTermTab }
