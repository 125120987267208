import { format, setHours } from "date-fns"
import React, { useState, useContext } from "react"

import { Container, Row, Button, Form, Alert } from "react-bootstrap"
import { DeliverySrvAndEqpSelect } from "../../../_shared/jobs/SrvAndEqpSelect/delivery"

import { LocationCtx } from "../../Fetch"
import UserContext from "../../../contexts/userContext"
import EqpFilter from "../../utils/EqpFilter"

import LocationsDetailsEqpRender from "./LocationsDetailsEqpRender"

const LocationsDetailsEqpFetch = ({ srv, isDelivery }) => {
  const { location, refresh, setModalData, jobs, configs } =
    useContext(LocationCtx)
  const user = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchError, setFetchError] = useState(null)

  const [eqpSelected, setEqpSelected] = useState([])

  // at first run I'm showing already selected equipment, it needs a little refactor - that becouse Render table is designed to handle data from mongo.equipment
  // so I spread location.equipment.ref (populated in api) and add quantity
  const [equipment, setEquipment] = useState(
    srv.equipment.map((eqp) => ({ ...eqp.ref, qty: eqp.qty }))
  )

  const addDelivery = async () => {
    try {
      setFetchError(null)

      const jobData = {
        date: setHours(
          new Date(document.getElementById("deliveryDate").value),
          12
        ),
        type: "dostarczenie",
        state: "zaplanowane",
        location: location._id,
        branch: location.branch,
        hasDriver: location.hasDriver,
        driver: location.driver,
        customHours: false,
        comments: "",
        services: [
          {
            serviceRef: srv.serviceRef,
            locationServiceRef: srv._id,
            eqp: eqpSelected,
          },
        ],
      }

      const res = await fetch(
        // I have no idea why when testing with cy there is problem with sending locHistoryLength (it is undefined)
        // so I just omit checking sde for cypress
        `/jobs/addEqpChangeJob${
          user.name === "cyEmplTest" ? "?omitSDECheck=true" : ""
        }`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            jobData: jobData,
            locHistoryLength:
              (location.history.length || location.historyLength) + 1,
          }),
        }
      )

      if (res.status !== 200)
        throw new Error(
          `Błąd komunikacji z serwerem: ${res.status} - ${
            (await res.text()) || "nieokreślony błąd"
          }`
        )
      return refresh({
        header: "Sukces",
        headerColor: "bg-success",
        body: "Dodano usługę i dostarczenie",
      })
    } catch (err) {
      console.log(err)
      setFetchError(err.message || "Nieokreślony błąd działania programu")
    }
  }

  if (srv.custEqp)
    return (
      <Container fluid>
        <EqpFilter
          setEquipment={setEquipment}
          setIsLoading={setIsLoading}
          srv={srv}
          eqpTypes={configs.eqpTypes}
          jobType="dostarczenie"
        />

        {isLoading ? (
          "Pobieram dane..."
        ) : (
          <LocationsDetailsEqpRender
            equipment={equipment}
            loc_id={location._id}
            srv={srv}
            refresh={refresh}
            setModalData={setModalData}
            jobs={jobs}
            isDelivery={isDelivery}
            location={location}
          />
        )}
      </Container>
    )
  else
    return (
      <>
        <h2>Dodawanie dostarczenia</h2>
        <Form.Label>
          Data:{" "}
          <Form.Control
            as="input"
            type="date"
            className=""
            autoComplete="chrome-off"
            defaultValue={format(
              new Date(srv.params[0].startDate),
              "yyyy-MM-dd"
            )}
            id="deliveryDate"
          />
        </Form.Label>
        <DeliverySrvAndEqpSelect
          services={[srv]}
          locServices={[...location.services, srv]} // add service pushed to location in previous step
          setEqpSelected={setEqpSelected}
          eqpSelected={eqpSelected}
          hideServices={true}
        />
        {fetchError ? <Alert variant="warning">{fetchError}</Alert> : null}
        <Row className="justify-content-between mx-3">
          <Button variant="warning">Anuluj</Button>
          <Button
            onClick={() => addDelivery()}
            variant="secondary"
            id="deliveryAddSaveEqpBtn"
          >
            Dodaj
          </Button>
        </Row>
      </>
    )
}
export default LocationsDetailsEqpFetch
