import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { useCookies } from "react-cookie"
import { Container, Modal, Button, Col, Row, Form } from "react-bootstrap"

import { PermTable } from "../../doc/tables"

const EmployeeAdd = (props) => {
  // console.log(props)
  // const [isUser, setIsUser] = useState()
  // const [formData, setFormData] = useState()
  const [cookies] = useCookies()
  const [modal, setModal] = useState(false)
  const newEmployee = props.employee === "new" ? true : false

  /**
   *
   * @param {Array} branches array of aviable branches
   * @returns {Object} object with all branches as keys and all values set to false
   */
  const createNewEmplBranches = (branches) => {
    const branchesObj = {}
    branches.forEach((branch) => (branchesObj[branch] = false))
    return branchesObj
  }

  const defaultValues = newEmployee
    ? { branch: createNewEmplBranches(props.branches) }
    : { ...props.employee }

  const { register, handleSubmit, watch, getValues } = useForm({
    defaultValues: defaultValues,
  })
  const watchBranch = watch("branch")

  const onSubmit = async (data) => {
    try {
      const employeeData = newEmployee // when newEmployee -> props.employee === "new", so Im sending req === {0:"n",1:"e",2:"w", name:"..." etc}, this ternary is to prevent this
        ? { ...data }
        : { ...props.employee, ...data }

      if (props.employee !== true) {
        employeeData._id = props.employee._id
      }
      if (newEmployee) employeeData.appVersion = cookies.meta.version
      if (newEmployee) employeeData.state = "aktywny"

      employeeData.fullName = `${data.firstName} ${data.lastName}`

      const res = await fetch(
        newEmployee ? "/employees/add" : "/employees/update?noEmail=true",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: newEmployee ? "POST" : "PUT",
          body: JSON.stringify(employeeData),
        }
      )

      if (res.status !== 200) {
        const resJSON = await res.json()
        throw resJSON?.msg ? resJSON.msg : res
      } else {
        window.location.reload()
      }
    } catch (err) {
      alert(err)
    }
  }

  const renderPermTable = () => {
    return (
      <div>
        <label>
          Hasło <i>(wypełnij jeśli chcesz zmienić, inaczej zostaw puste)</i>
          <br />
          <input type="text" {...register("pass")} />
        </label>
        <Button
          variant="outline-secondary"
          onClick={() => setModal({ body: <PermTable /> })}
        >
          Ściąga
        </Button>
        <table>
          <thead>
            <tr>
              <th>Uprawnienie</th>
              <th>r</th>
              <th>w</th>
              <th>e</th>
              <th>d</th>
            </tr>
          </thead>
          <tbody>
            {props.perm.map((permission) => {
              return (
                <tr key={`${permission}-row`}>
                  <td>{permission}</td>
                  <td>
                    <input
                      type="checkbox"
                      key={`perm.${permission}.r`}
                      {...register(`perm.${permission}.r`)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      {...register(`perm.${permission}.w`)}
                      key={`perm.${permission}.w`}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      {...register(`perm.${permission}.e`)}
                      key={`perm.${permission}.e`}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      {...register(`perm.${permission}.d`)}
                      key={`perm.${permission}.d`}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row>
          <Col>
            id
            <br />
            <div>{!newEmployee && props.employee._id}</div>
            <Container>
              <label>
                Imię
                <br />
                <input type="text" {...register("firstName")} />
              </label>
              <br />
              <label>
                Nazwisko
                <br />
                <input type="text" {...register("lastName")} />
              </label>
              <br />
              <label>
                Nazwa użytkownika:
                <br />
                <input type="text" {...register("name")} />
              </label>
              <br />
              <label className="not-required">
                Mail:
                <br />
                <input type="text" {...register("mail")} />
              </label>
              <br />
              <label>
                Oddział:
                <br />
                {props.branches.map((branch) => {
                  return (
                    <label key={`${branch}-label`}>
                      <input
                        type="checkbox"
                        {...register(`branch.${branch}`)}
                      />
                      {branch}
                      <br />
                    </label>
                  )
                })}
              </label>
              <br />
              <label>
                Typ:
                <br />
                {props.employeeTypes.map((type) => {
                  return (
                    <label key={`employee-type-chckbx-${type}`}>
                      <input type="checkbox" {...register(`type.${type}`)} />
                      {type}
                    </label>
                  )
                })}
              </label>
              <br />
              {watch("type.driver") && (
                <Form.Check
                  {...register("largeJobCardFont")}
                  type="checkbox"
                  label="Powiększ czcionkę na kontrolce"
                  id="largeJobCardFont"
                />
              )}
              <label>
                Stan:
                <select {...register("state")}>
                  <option value="aktywny">aktywny</option>
                  <option value="archiwum">archiwum</option>
                </select>
              </label>
              Domyślny oddział:
              <Form.Control
                {...register("defaultBranch")}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
              >
                {Object.keys(getValues("branch"))
                  .filter((key) => watchBranch[key])
                  .map((branch) => (
                    <option
                      value={branch}
                      key={`default-branches-select-option-${branch}`}
                    >
                      {branch}
                    </option>
                  ))}
              </Form.Control>
              Configs:
              {watch("type.user") && (
                <>
                  <Form.Check
                    {...register("configs.staticBackdrop")}
                    type="checkbox"
                    label="staticBackdrop"
                    value=""
                    id="staticBackdrop "
                  />
                  <Form.Check
                    {...register("configs.showDesktop")}
                    type="checkbox"
                    label="desktop?"
                    value=""
                    id="showDesktop"
                  />
                </>
              )}
              {watch("type.driver") && (
                <Row>
                  <Form.Label>
                    Kolor markera:
                    <Form.Control
                      {...register("markerColor")}
                      as="input"
                      type=""
                      className=""
                      autoComplete="chrome-off"
                    />
                  </Form.Label>
                </Row>
              )}
              Opis:
              <Form.Control
                {...register("desc")}
                as="textarea"
                className=""
                autoComplete="chrome-off"
              />
            </Container>
          </Col>
          <Col style={{ display: watch("type.user") ? "block" : "none" }}>
            {watch("type.user") ? renderPermTable() : null}
          </Col>
        </Row>
        <Modal.Footer className="justify-content-between">
          <Button onClick={() => props.setShowModal(false)}>Anuluj</Button>
          <Button
            onClick={() => props.handleDelete(props.employee._id)}
            variant="danger"
          >
            Usuń
          </Button>
          <Button type="submit" variant="secondary">
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        animation={false}
        dialogClassName="modal-max-width"
        style={{ marginLeft: "5%", width: "90%" }}
      >
        <Modal.Body>{modal.body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false)}>Zamknij</Button>
        </Modal.Footer>
      </Modal>
    </Modal.Body>
  )
}
export default EmployeeAdd
