import React, { useContext } from "react"
import { Container, Col, Row, Button, Table } from "react-bootstrap"

import { EmployeesDetailsCtx } from "./Fetch"
import EmployeesDetailsEditContract from "./Edit/Contract"

import {
  EmployeesDetailsContractsTable,
  EmployeesDetailsHourlyContractsTable,
} from "./tables"

const EmployeesDetailsContracts = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)

  const activeContracts = {
    normal: ctx.employee.contracts.filter(
      (contract) => contract.state === "aktywna" && !contract.isHourly
    ).length,
    hourly: ctx.employee.contracts.filter(
      (contract) => contract.state === "aktywna" && contract.isHourly
    ).length,
  }
  const arrangedContracts = {
    normal: ctx.employee.contracts.filter(
      (contract) => contract.state === "ustalenia" && !contract.isHourly
    ).length,
    hourly: ctx.employee.contracts.filter(
      (contract) => contract.state === "ustalenia" && contract.isHourly
    ).length,
  }
  const archivedContracts = {
    normal: ctx.employee.contracts.filter(
      (contract) => contract.state === "archiwum" && !contract.isHourly
    ).length,
    hourly: ctx.employee.contracts.filter(
      (contract) => contract.state === "archiwum" && contract.isHourly
    ).length,
  }

  return (
    <Container fluid>
      <Table>
        <thead>
          <tr>
            <th>
              <Row>
                <Col xs={{ span: 4 }}>Pracownik: {ctx.employee.fullName}</Col>

                {ctx.employee.state !== "archiwum" &&
                (ctx.user.type.board ||
                  (ctx.user.perm.employees.e &&
                    ctx.employee.superior.includes(ctx.user._id))) ? (
                  <Col xs={{ offset: 6 }}>
                    <Button
                      id="addArrangementBtn"
                      variant="secondary"
                      size="sm"
                      onClick={() =>
                        props.setModalData({
                          show: true,
                          type: "info",
                          header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("ustalenia")`,
                          xl: true,
                          hideFooter: true,
                          body: (
                            <EmployeesDetailsEditContract
                              configs={props.configs}
                              contractInd={-1}
                              user={props.user}
                              setModalData={props.setModalData}
                              contractState="ustalenia"
                            />
                          ),
                        })
                      }
                    >
                      Dodaj ustalenie
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </th>
          </tr>
        </thead>
      </Table>
      {activeContracts.normal !== 0 ? (
        <EmployeesDetailsContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          summary={true}
          filter="aktywna"
          user={props.user}
          header={
            <Row>
              <Col xs={{ span: 4 }}>Aktualne umowy miesięczne</Col>
              <Col xs={{ offset: 7 }}>
                {ctx.user.type.board ? (
                  <Button
                    variant="secondary"
                    size="sm"
                    id="addAgreementBtn"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("aktywna")`,
                        xl: true,
                        hideFooter: true,
                        body: (
                          <EmployeesDetailsEditContract
                            configs={props.configs}
                            contractInd={-1}
                            user={props.user}
                            setModalData={props.setModalData}
                            contractState="aktywna"
                            isHourly={false}
                          />
                        ),
                      })
                    }
                  >
                    Dodaj
                  </Button>
                ) : null}
              </Col>
            </Row>
          }
        />
      ) : null}
      {activeContracts.hourly !== 0 ? (
        <EmployeesDetailsHourlyContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          header={
            <Row>
              <Col xs={{ span: 4 }}>Aktualne umowy godzinowe</Col>
              <Col xs={{ offset: 7 }}>
                {ctx.user.type.board ? (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("aktywna")`,
                        xl: true,
                        hideFooter: true,
                        body: (
                          <EmployeesDetailsEditContract
                            configs={props.configs}
                            contractInd={-1}
                            user={props.user}
                            setModalData={props.setModalData}
                            contractState="aktywna"
                            isHourly={true}
                          />
                        ),
                      })
                    }
                  >
                    Dodaj
                  </Button>
                ) : null}
              </Col>
            </Row>
          }
          summary={true}
          filter="aktywna"
          user={props.user}
          employee={props.employee}
        />
      ) : null}
      {!activeContracts.normal && !activeContracts.hourly && (
        <Table>
          <thead>
            <tr>
              <th>
                <Row>
                  <Col xs={{ span: 4 }}>Aktualne umowy</Col>
                  <Col xs={{ offset: 7 }}>
                    {ctx.user.type.board ? (
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() =>
                          props.setModalData({
                            show: true,
                            type: "info",
                            header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("aktywna")`,
                            xl: true,
                            hideFooter: true,
                            body: (
                              <EmployeesDetailsEditContract
                                configs={props.configs}
                                contractInd={-1}
                                user={props.user}
                                setModalData={props.setModalData}
                                contractState="aktywna"
                                isHourly={false}
                              />
                            ),
                          })
                        }
                      >
                        Dodaj
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brak aktywnych umów</td>
            </tr>
          </tbody>
        </Table>
      )}

      {/* ----==== ARRANGED contracts (ustalenia) ====---- */}
      <hr />
      {arrangedContracts.normal !== 0 ? (
        <EmployeesDetailsContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          summary={false}
          filter="ustalenia"
          user={props.user}
          employee={props.employee}
          header={
            <Row>
              <Col xs={{ span: 4 }}>Ustalenia miesięczne</Col>
              <Col xs={{ offset: 7 }}>
                {(ctx.employee.superior.includes(ctx.user._id) &&
                  ctx.employee.state !== "archiwum") ||
                ctx.user.type.board ? (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("ustalenia")`,
                        xl: true,
                        hideFooter: true,
                        body: (
                          <EmployeesDetailsEditContract
                            configs={props.configs}
                            contractInd={-1}
                            user={props.user}
                            setModalData={props.setModalData}
                            contractState="ustalenia"
                            isHourly={false}
                          />
                        ),
                      })
                    }
                  >
                    Dodaj
                  </Button>
                ) : null}
              </Col>
            </Row>
          }
        />
      ) : null}
      {arrangedContracts.hourly !== 0 ? (
        <EmployeesDetailsHourlyContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          header={
            <Row>
              <Col xs={{ span: 4 }}>Ustalenia godzinowe</Col>
              <Col xs={{ offset: 7 }}>
                {(ctx.employee.superior.includes(ctx.user._id) &&
                  ctx.employee.state !== "archiwum") ||
                ctx.user.type.board ? (
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() =>
                      props.setModalData({
                        show: true,
                        type: "info",
                        header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("ustalenia")`,
                        xl: true,
                        hideFooter: true,
                        body: (
                          <EmployeesDetailsEditContract
                            configs={props.configs}
                            contractInd={-1}
                            user={props.user}
                            setModalData={props.setModalData}
                            contractState="ustalenia"
                            isHourly={true}
                          />
                        ),
                      })
                    }
                  >
                    Dodaj
                  </Button>
                ) : null}
              </Col>
            </Row>
          }
          summary={true}
          filter="ustalenia"
          user={props.user}
          employee={props.employee}
        />
      ) : null}
      {!arrangedContracts.normal && !arrangedContracts.hourly && (
        <Table>
          <thead>
            <tr>
              <th>
                <Row>
                  <Col xs={{ span: 4 }}>Ustalenia</Col>
                  <Col xs={{ offset: 7 }}>
                    {(ctx.employee.superior.includes(ctx.user._id) &&
                      ctx.employee.state !== "archiwum") ||
                    ctx.user.type.board ? (
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() =>
                          props.setModalData({
                            show: true,
                            type: "info",
                            header: `Dodawanie umowy dla pracownika ${props.employee.fullName} ("ustalenia")`,
                            xl: true,
                            hideFooter: true,
                            body: (
                              <EmployeesDetailsEditContract
                                configs={props.configs}
                                contractInd={-1}
                                user={props.user}
                                setModalData={props.setModalData}
                                contractState="ustalenia"
                                isHourly={false}
                              />
                            ),
                          })
                        }
                      >
                        Dodaj
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brak ustaleń</td>
            </tr>
          </tbody>
        </Table>
      )}
      <hr />

      {/* ----==== ARCHIVED contracts (archiwum) ====---- */}

      {archivedContracts.normal !== 0 ? (
        <EmployeesDetailsContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          summary={false}
          filter="archiwum"
          user={props.user}
          employee={props.employee}
          header="Archiwalne umowy miesięczne"
        />
      ) : null}
      {archivedContracts.hourly !== 0 ? (
        <EmployeesDetailsHourlyContractsTable
          contracts={props.employee.contracts}
          setModalData={props.setModalData}
          configs={props.configs}
          header="Archiwalne umowy godzinowe"
          summary={false}
          filter="archiwum"
          user={props.user}
          employee={props.employee}
        />
      ) : null}
      {!archivedContracts.normal && !archivedContracts.hourly && (
        <Table>
          <thead>
            <tr>
              <th>Umowy archiwalne</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brak archiwalnych umów</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Container>
  )
}
export default EmployeesDetailsContracts
