import React from "react"
import { Row, Col } from "react-bootstrap"

const ManualTodosSection = (props) => {
  if (!props.user.perm.todos.r) return null
  else
    return (
      <>
        <div>
          <Row>
            <Col>
              <h3>"Do zrobienia" - lista zadań użytkownika</h3>
              <p></p>
              <p>
                Komponent służy za listę zadań do realizacji. Zadania można
                dodawać samodzielnie, może je też zlecać uprawniony przełożony
                swoim podwładnym. W przypadku przeterminowanego zadania
                codziennie wysyłany jest mail do osób obserwujących (w wersji
                1.5.2 - osoba przypisana i zlecająca).{" "}
              </p>
              <p>
                Docelowo komponent ma, poza porządkowaniem delegowania zadań,
                zastąpić Jirę oraz przypominać o cyklicznych zadaniach (np.
                odnawianie zezwoleń)
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Opis pól</h4>

              <ul>
                <li>Przypisany - osoba, która ma zrealizować zadanie</li>
                <li>Zlecający - osoba, która utworzyła zadanie</li>
                <li>Tytuł, opis - szczegóły zadania</li>
                <li>
                  Stan - etap realizacji zadania (weryfikacja, zlecone, w
                  realizacji, do sprawdzenia, zrobione, odrzucone).
                </li>
                <li>
                  Priorytet - ważność zadania w skali od 1 (najmniej ważne) do 6
                  (najważniejsze)
                </li>
                <li>Tagi - słowa kluczowe kategoryzujące zadanie</li>
                <li>Termin - data do której ma być zrealizowane</li>
                <li>
                  Obserwujący - osoby, które będą powiadamiane o przekroczeniu
                  terminu (a w przyszłości też o zmianach w zadaniu)
                </li>
                <li>
                  (do zrobienia w przyszłości) Komentarze - miejsce na dyskusję
                </li>
                <li>(do zrobienia) Załączniki - pliki związane z zadaniem</li>
              </ul>
              <h4>Lista zadań</h4>
              <p>
                Nad listą zadań do zrobienia pojawją się filtry wielokrotnego
                wyboru - pierwszy raz w clipERPie. Aby skorzystać z filtra
                trzeba kliknąć strzałkę w dół (jak w zwykłym polu wyboru) i z
                menu które się pokaże wybrać pożądane pozycje.
              </p>
            </Col>
            <Col></Col>
          </Row>
          <div>
            <Row>
              <Col>
                <h3>Dodawanie zadań dla przełożonych</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Pracownik może dodać zadania dla przełożonego, zaznaczając
                  checkbox "Propozycja (dla przełożonego)".
                  <br />
                  <u>
                    Pracownik nie może określić daty ani priorytetu dla
                    propozycji - ustala je przełożony przyjmując propozycję
                    (domyślnie tydzień od "dziś" i priorytet 1)
                  </u>
                </p>
                <p>
                  Takie zadanie jest widoczne (u przełożonego i dodającego) w
                  osobnej tabeli. Przełożony może je przyjąć, wtedy propozycja
                  stanie się normalnym zadaniem.
                </p>
              </Col>
              <Col></Col>
            </Row>

            <Row>
              <Col>
                <h3>Uprawnienia do zadań</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul>
                  <li>
                    Do wyświetlania zadań/propozycji (po odpowiednim ustawieniu
                    filtrów) uprawnieni są:
                    <ul>
                      <li>autor</li>
                      <li>przypisany</li>
                      <li>obserwujący</li>
                      <li>wszyscy przełożeni przypisanego pracownika</li>
                    </ul>
                  </li>
                  <li>
                    Do edycji zadania/propozycji uprawnieni są:
                    <ul>
                      <li>przypisany</li>
                      <li>wszyscy przełożeni przypisanego pracownika</li>
                      <li>autor - tylko w przypadku propozycji</li>
                    </ul>
                  </li>
                </ul>
              </Col>
              <Col></Col>
            </Row>
          </div>
        </div>
      </>
    )
}
export default ManualTodosSection
