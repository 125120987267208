import { format, min } from "date-fns"
import { useMemo, useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Alert,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap"
import { FiAlertTriangle, FiInfo } from "react-icons/fi"
import { createEqpString } from "../../../utils/createEqpString"

import { getLatestSrvParam } from "./utils"

/**
 * just pass jobs that close any service, this component does checking which services can be closed
 * then renders table of jobs and services with checkboxes
 * when any srv selected adds srvToClose obj to fetch data
 * also handles errors
 */

const SrvClosingTable = ({
  jobs,
  fetchData,
  submit,
  refresh,
  setModalData,
}) => {
  const [srvSelected, setSrvSelected] = useState([])
  const [fetchAlert, setFetchAlert] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const submitWrapper = async () => {
    try {
      setIsFetching(true)
      await submit(
        { ...fetchData, srvToClose: srvSelected },
        refresh,
        setModalData
      )
    } catch (err) {
      console.log(err)
      setFetchAlert(err.message || "nieokreślony błąd")
      setIsFetching(false)
    }
  }

  return (
    <Container>
      <Alert variant="warning">
        <i>
          Zaznaczenie usługi spowoduje jej zamknięcie <u>z datą zabrania</u>,
          oraz usunięcie z zaplanowanych zadań.
        </i>
      </Alert>
      {jobs
        .sort((a, b) => {
          if (a.location.name > b.location.name) return 1
          if (a.location.name < b.location.name) return -1
          return 0
        })
        .map((job) => (
          <JobRow
            job={job}
            setSrvSelected={setSrvSelected}
            srvSelected={srvSelected}
            key={`JobRowComp-${job._id}`}
          />
        ))}
      {fetchAlert ? <Alert variant="warning">{fetchAlert}</Alert> : null}
      <Row>
        <Col>
          <Row>
            <Button
              onClick={submitWrapper}
              disabled={isFetching}
              variant="secondary"
              className="ml-auto"
            >
              {isFetching ? <Spinner animation="border" size="sm" /> : "Zapisz"}
            </Button>
          </Row>
        </Col>
        <Col>
          <Row>
            <Button
              variant="warning"
              disabled={isFetching}
              className="ml-auto"
              onClick={() =>
                refresh({
                  headerColor: "bg-warning",
                  header: "ANULOWANO",
                  body: "Zmiany nie zostały zapisane",
                })
              }
            >
              {isFetching ? <Spinner animation="border" size="sm" /> : "Anuluj"}
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

const JobRow = ({ job, srvSelected, setSrvSelected }) => {
  // 'normal' services - services that can be closed by this pickup and has no planned closing date
  // 'noEqp' services - services from this loacation without eqp
  // 'plannedToClose' services - services that have planned closing date

  const renderNormalServices = () => {
    const closableSrvs = getClosableJobSrvs(job)

    return closableSrvs.map((srv) => (
      <SrvRow
        job={job}
        srv={srv}
        setSrvSelected={setSrvSelected}
        srvSelected={srvSelected}
        key={`SrvRowComp-${srv._id}-job-${job._id}`}
      />
    ))
  }
  const renderNoEqpServices = () => {
    // create array no-eqp locations services
    const noEqpLocServices = job.location.services.filter(
      // get only active no-eqp services
      (locSrv) => locSrv.state === "aktywna" && !locSrv.mustHaveEqp
    )

    if (noEqpLocServices.length)
      return (
        <>
          <h5>Usługi bezsprzętowe</h5>
          {noEqpLocServices.map((srv) => (
            <SrvRow
              key={`SrvRowComp-${srv._id}-job-${job._id}`}
              srv={srv}
              job={job}
              setSrvSelected={setSrvSelected}
              srvSelected={srvSelected}
            />
          ))}
        </>
      )

    return null
  }

  return (
    <Container>
      <Row>
        <h5>
          <b>Lokalizacja: </b>
          {job.location?.name || "brak"}
        </h5>
      </Row>
      {renderNormalServices()}
      {renderNoEqpServices()}
      <hr />
    </Container>
  )
}

const getClosableJobSrvs = (pickup) => {
  try {
    const closableSrvs = []
    // for each active service on location check if it has eqp
    // if yes -> check if all eqp are on pickup (then srv can be closed)
    // if no -> it can be closed by default (unchecking should be done in render comp)
    // 'mustHaveEqp' services are handled in renderNoEqpServices in JobRow component
    pickup.location.services.forEach((locSrv) => {
      if (locSrv.state !== "aktywna") return
      if (!locSrv.mustHaveEqp) return
      if (!locSrv.equipment?.length) closableSrvs.push(locSrv)
      else {
        let canBeClosed = true
        locSrv.equipment.forEach((locSrvEqp) => {
          // find matching job service:
          const jobSrv = pickup.services.find(
            (jobSrvEl) => jobSrvEl.locationServiceRef === locSrv._id
          )

          // when service found in pickup services it cannot be closed
          if (!jobSrv) return (canBeClosed = false)

          // find matching job service equipment:
          const jobSrvEqp = jobSrv.eqp.find(
            (jobSrvEqpEl) => jobSrvEqpEl.ref._id === locSrvEqp.ref._id
          )

          // when eqp not found in job service or qty is not equal to inPlace -> srv can't be closed
          if (!jobSrvEqp || jobSrvEqp.qty !== locSrvEqp.inPlace)
            canBeClosed = false
        })
        if (canBeClosed) closableSrvs.push(locSrv)
      }
    })

    return closableSrvs
  } catch (err) {
    err.message = `getClosableJobSrvs for job ${pickup._id}:  ${err.message}`
    throw err
  }
}

const SrvRow = ({ job, srv, setSrvSelected, srvSelected }) => {
  // prepare object to send to API:
  const srvEl = useMemo(
    () => ({
      srv_id: srv._id, // aka serviceLocationRef
      loc_id: job.location._id,
      closeDate: job.date,
    }),
    [job, srv]
  )

  // get srv start date:
  const srvStartDate = useMemo(() => {
    const parmsStartDates = srv.params.map((prm) => new Date(prm.startDate))
    return min(parmsStartDates)
  }, [srv])

  // check if service is planned to close:
  const latestParam = getLatestSrvParam(srv)
  const [endDate] = useState(latestParam.endDate)

  // service is by default checked if there is no end date and it has eqp
  useEffect(() => {
    if (!endDate && srv.mustHaveEqp)
      setSrvSelected((prevSrvSelected) => [...prevSrvSelected, srvEl])
  }, [srvEl, setSrvSelected, endDate, srv])

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSrvSelected((prevSrvSelected) => [...prevSrvSelected, srvEl])
    } else {
      setSrvSelected((prevSrvSelected) =>
        prevSrvSelected.filter(
          (prevSrvSelected) => prevSrvSelected.srv_id !== srvEl.srv_id
        )
      )
    }
  }

  const renderEqpTooltip = (props) => {
    return (
      <Tooltip id={`eqpTooltip-${job._id}-${srv._id}`} {...props}>
        {srv.equipment?.length
          ? srv.equipment.map((srvEqp) => (
              <span key={`tooltipEqpLine-${job._id}-${srv._id}-${srvEqp._id}`}>
                {createEqpString(srvEqp)}
                <br />
              </span>
            ))
          : "Brak"}
      </Tooltip>
    )
  }
  return (
    <>
      <Row>
        <Col xs="auto">
          <Form.Check
            type="checkbox"
            label={`${srv.locSrvNo || "?"}. ${srv.name}`}
            value=""
            id={`SrvRowCheckbox-${srv._id}-job-${job._id}`}
            onChange={(e) => handleCheckboxChange(e)}
            checked={
              srvSelected.findIndex((srvEl) => srvEl.srv_id === srv._id) !== -1
            }
          />
        </Col>
        <Col xs="auto">
          {endDate ? (
            <p className="bg-warning mx-1 my-0">
              <FiAlertTriangle /> zamknięcie usługi zaplanowano na{" "}
              {format(new Date(endDate), "yyyy-MM-dd")}
            </p>
          ) : null}
        </Col>
        <Col xs="auto">
          (data rozpoczęcia usługi: {format(srvStartDate, "yyyy-MM-dd")},
          sprzęt:{" "}
          <OverlayTrigger placement="right" overlay={renderEqpTooltip}>
            <FiInfo />
          </OverlayTrigger>{" "}
          )
        </Col>
      </Row>
    </>
  )
}

export { SrvClosingTable, getClosableJobSrvs }
