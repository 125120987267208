import { useState, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { Row, Col, Button, Form, Alert } from "react-bootstrap"
import { ImNewTab } from "react-icons/im"
import renderSpinner from "../../../utils/renderSpinner"
import { ChooseSrvAndEqp, createJobSrvArr } from "../../../_shared/jobs/utils"

const ModifyJobDetailsModalFetch = ({ job, setModalData, refresh }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [eqpTypes, setEqpTypes] = useState([])

  useEffect(() => {
    const fetchEqpTypes = async () => {
      try {
        const res = await fetch(`/configs/eqpTypes`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych konfiguracyjnych: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        setEqpTypes(resJSON.eqpTypes)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        return setModalData({
          show: true,
          type: "alert",
          body: err.message || "nieokreślony błąd pobierania danych",
        })
      }
    }
    fetchEqpTypes()
  }, [setModalData])

  return (
    <div>
      {isLoading ? (
        renderSpinner("pobieram dane...")
      ) : (
        <ModifyJobDetailsModalRender
          job={job}
          setModalData={setModalData}
          eqptypes={eqpTypes}
          refresh={refresh}
        />
      )}{" "}
    </div>
  )
}

const ModifyJobDetailsModalRender = ({
  job,
  setModalData,
  eqpTypes,
  refresh,
}) => {
  const [submitError, setSubmitError] = useState(null)

  const defaultValues = {
    ...job,
    services: createJobSrvArr(job.location?.services, job),
  }

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: defaultValues,
  })

  const submit = async (formData) => {
    try {
      const body = {
        job: {
          ...formData,
          _id: job._id,
          // RHF prepares array of all locations eqp, I want only selected (ref!==false)
          equipment: formData.equipment?.filter((eqp) => eqp.ref),
        },
        dirtyFields: dirtyFields,
        historyLength: job.history.length,
        changeLocDriver: formData.changeLocDriver,
      }

      delete body.job.changeLocDriver // just delete it from job data, as I put it directly in req.body

      // clean up services and equipment info if job is not frozen:
      if (!job.isFrozen) {
        // cleaning services from not choosen:
        body.job.services = body.job.services.filter(
          (srv) => srv.locationServiceRef
        )

        // adding ref to global services collection
        body.job.services = body.job.services.map((srv) => {
          // find global location _id:
          const serviceRef = job.location.services.find(
            (locSrv) => locSrv._id === srv.locationServiceRef
          ).serviceRef
          return { ...srv, serviceRef: serviceRef }
        })

        // cleaning choosen services equipment from not choosen:
        body.job.services.forEach((srv, i) => {
          // when srv without eqp -> create empty array
          // when srv needs to have jobs I prevent this from happening  in form
          body.job.services[i].eqp = body.job.services[i].eqp
            ? srv.eqp.filter((eqp) => eqp.ref)
            : []
        })

        // when job is frozen:
      } else {
        body.job.services = job.services.map((jobSrv) => ({
          ...jobSrv,
          serviceRef: jobSrv.serviceRef._id,
        }))
      }

      // check if job has services requiring equipment

      body.job.services.forEach((jobSrv) => {
        // find location service (to get it's params)
        const locSrv = job.location.services.find(
          (locService) => locService._id === jobSrv.locationServiceRef
        )
        if (!locSrv)
          throw new Error(`brak usługi lokalizacji (jobSrv._id: ${jobSrv._id})`)
        if (locSrv.mustHaveEqp && jobSrv.eqp.length === 0)
          throw new Error(
            "Nie można utworzyć zadania z usługą sprzętową bez wybrania sprzętu"
          )
      })

      const res = await fetch("/jobs/edit", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
      if (res.status === 403) return setSubmitError("Brak uprawnień")
      if (res.status === 409)
        return setSubmitError(
          "Niestety ktoś właśnie edytował to zadanie. Musisz odświeżyć to okno, żeby pobrać najświeższe dane. UWAGA! Wprowadzone przez Ciebie zmiany zostaną utracone - upewnij się, że dasz radę je odtworzyć."
        )
      if (res.status !== 200) {
        console.log(res)
        return setSubmitError("Błąd komunikacji z serwerem")
      }
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "zmiana zapisana",
        header: "Sukces",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setSubmitError(err.message || "nieokreślony błąd komunikacji z serwerem")
    }
  }

  if (job.type === "dostarczenie")
    return (
      <div>
        <b>Klient: </b>
        <a
          href={`/CustomersDetails/${job.location.customer._id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {job.location.customer.shortName} <ImNewTab />
        </a>
        <br />
        <b>Lokalizacja: </b>
        <a
          href={`/Locations/${job.location._id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {job.location.street}, {job.location.city}
          <ImNewTab />
        </a>
        <Alert variant="warning">
          Jeszcze nie ma możliwości edytowania dostarczeń z tego poziomu
        </Alert>
        <Row>
          <Button
            onClick={() => setModalData({ show: false })}
            className="ml-auto mr-3"
          >
            Zamknij
          </Button>
        </Row>
      </div>
    )

  return (
    <div>
      <b>Klient: </b>
      <a
        href={`/CustomersDetails/${job.location.customer._id}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {job.location.customer.shortName} <ImNewTab />
      </a>
      <br />
      <b>Lokalizacja: </b>
      <a
        href={`/Locations/${job.location._id}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {job.location.street}, {job.location.city}
        <ImNewTab />
      </a>
      <Form onSubmit={handleSubmit(submit)}>
        <h5 className="mt-3">Typ zadania </h5>
        <Row className="mb-3">
          <Col xs="4">
            <Form.Control
              {...register("type")}
              as="select"
              className=""
              autoComplete="chrome-off"
            >
              <option value="serwis">serwis</option>
              <option value="zabranie">zabranie</option>
            </Form.Control>
          </Col>
        </Row>
        <hr />
        <h5>Usługi i sprzęt</h5>
        <ChooseSrvAndEqp
          control={control}
          register={register}
          Controller={Controller}
          location={job.location}
          watch={watch}
          isFrozen={job.isFrozen}
          srvFrozen={job.srvFrozen}
          eqpTypes={eqpTypes}
          job={job}
          setValue={setValue}
          setModalData={setModalData}
        />
        <Row className="justify-content-between mx-3 mt-2">
          <Button
            variant="warning"
            onClick={() => setModalData({ show: false })}
          >
            Anuluj
          </Button>
          <Button type="submit" variant="secondary">
            Zapisz
          </Button>
        </Row>
      </Form>
      {submitError ? <Alert variant="danger">{submitError}</Alert> : null}
    </div>
  )
}
export { ModifyJobDetailsModalFetch }
