import { useState, useEffect } from "react"
import { format, getYear } from "date-fns"
import { Row, Button, Form, Badge, Table, Alert, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { MdClear } from "react-icons/md"

import renderSpinner from "../utils/renderSpinner"
import { submit } from "./utils"

//TODO add vin validation

const CarsEditModal = ({ user, setModalData, refresh, isNew, car }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [carsOwnersOpt, setCarsOwnersOpt] = useState([])
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    const fetchConfigData = async () => {
      const res = await fetch("/configs/carsOwnersOpt")

      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania danych konfguracyjnych (lista właścicieli): ${
            res.status
          } - ${(await res.text()) || "nieokreślony błąd"}`
        )

      const resJSON = await res.json()

      setCarsOwnersOpt(resJSON.carsOwnersOpt)
    }

    const fetchEmployees = async () => {
      const res = await fetch(
        `/employees/all?verbose=false&onlyActive=true&allBranches=true`
      )

      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania danych konfguracyjnych (lista pracowników): ${
            res.status
          } - ${(await res.text()) || "nieokreślony błąd"}`
        )

      const resJSON = await res.json()

      setEmployees(resJSON)
    }

    const fetchAll = async () => {
      try {
        await Promise.all([fetchConfigData(), fetchEmployees()])
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setFetchError(err.message)
        setIsLoading(false)
      }
    }

    fetchAll()
  }, [])

  if (isLoading && !fetchError)
    return renderSpinner("Pobieram dane konfiguracyjne")
  if (fetchError) return <Alert variant="danger">{fetchError}</Alert>
  else
    return (
      <CarsEditForm
        user={user}
        setModalData={setModalData}
        refresh={refresh}
        isNew={isNew}
        car={car}
        carsOwnersOpt={carsOwnersOpt}
        employees={employees}
      />
    )
}

const CarsEditForm = ({
  user,
  setModalData,
  refresh,
  isNew,
  car,
  carsOwnersOpt,
  employees,
}) => {
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
    setValue,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: isNew
      ? {
          branch: user.defaultBranch || user.allowedBranches[0],
          type: null,
          brand: null,
          model: null,
          name: null,
          vin: null,
          regNum: null,
          tankCapacity: null,
          platformCapacity: null,
          maxWeight: null,
          reviewDate: null,
          insuranceDate: null,
          comments: null,
          prodYear: null,
          hideForLogistic: false,
          state: "aktywny",
          legalOwner: null,
          legalUser: null,
          insuranceType: null,
          GAPDate: null,
          leaseAgreementNo: null,
          previousRegNumbers: null,
          isLeased: null,
          tachographReviewDate: null,
          user: null,
        }
      : {
          ...car,
          user: car.user?._id || "null", // user is populated on server side
          reviewDate: format(new Date(car.reviewDate), "yyyy-MM-dd"),
          insuranceDate: format(new Date(car.insuranceDate), "yyyy-MM-dd"),
          GAPDate: format(new Date(car.insuranceDate), "yyyy-MM-dd"),
        },
  })

  const submitWrapper = (formData) =>
    submit(formData, dirtyFields, car, isNew, refresh, setModalData)

  const watchIsLeased = watch("isLeased")

  useEffect(() => {
    // when isLeased is changed to true try to restore lease agreement number and GAP date:

    if (watchIsLeased) {
      setValue("leaseAgreementNo", car.leaseAgreementNo || null)
      setValue("GAPDate", car.GAPDate || null)
    } else {
      setValue("leaseAgreementNo", null)
      setValue("GAPDate", null)
    }

    // clear errors (when isLeased changed to false it keeps 'empty leaseAgreementNo' error)
    clearErrors("leaseAgreementNo")

    // disable warning to add watch and setValue:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIsLeased, car])

  const renderGeneralTable = () => {
    return (
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Nr rej.</td>
            <td>
              {errors.regNum && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("regNum", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Nazwa</td>
            <td>
              {errors.name && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("name", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Marka - model</td>
            <td>
              {errors.brand && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("brand", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Model</td>
            <td>
              {errors.model && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("model", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Rodzaj</td>
            <td>
              {errors.type && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("type", { required: true })}
                as="select"
                type=""
                className="m-0"
                autoComplete="chrome-off"
              >
                <option value="ciężarowy" key="typeSelect-ciezarowy">
                  ciężarowy
                </option>
                <option value="osobowy" key="typeSelect-osobowy">
                  osobowy
                </option>
                <option value="przyczepa" key="typeSelect-przyczepa">
                  przyczepa
                </option>
                <option value="inny" key="typeSelect-inny">
                  inny
                </option>
              </Form.Control>
            </td>
            <td className="fixed-values-table">Oddział</td>
            <td>
              {errors.branch && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("branch", { required: true })}
                as="select"
                type=""
                className="m-0"
                autoComplete="chrome-off"
              >
                {user.allowedBranches.map((branch) => (
                  <option key={`carsBranchSelect-${branch}`} value={branch}>
                    {branch}
                  </option>
                ))}
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Użytkownik</td>
            <td colSpan="3">
              <Form.Control
                {...register("user")}
                as="select"
                className="m-0"
                autoComplete="chrome-off"
              >
                <option value="null">brak</option>
                {employees.map((employee) => (
                  <option
                    key={`carsUserSelect-${employee._id}`}
                    value={employee._id}
                  >
                    {employee.fullName}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td className="fixed-values-table">Ukryty dla logistyki</td>
            <td>
              <Form.Check
                {...register("hideForLogistic")}
                type="checkbox"
                label="tak"
                id="hideForLogistic"
              />
            </td>
          </tr>{" "}
          <tr>
            <td className="fixed-values-table">Uwagi</td>
            <td colSpan="5">
              <Form.Control
                {...register("comments")}
                as="textarea"
                type=""
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const renderInsuranceAndDatesTable = () => {
    return (
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Data ubezp.</td>
            <td>
              {errors.insuranceDate && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("insuranceDate", { required: true })}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Rodzaj ubezp.</td>
            <td>
              {errors.insuranceType && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}

              <Form.Control
                {...register("insuranceType")}
                as="select"
                className="m-0"
                autoComplete="chrome-off"
              >
                <option value="null">brak</option>
                <option value="flota">flota</option>
                <option value="leasing">leasing</option>
                <option value="inne">inne</option>
              </Form.Control>
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Data badania</td>
            <td>
              {errors.reviewDate && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("reviewDate", { required: true })}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Data tachograf</td>
            <td>
              <Row>
                <Col>
                  <Form.Control
                    {...register("tachographReviewDate")}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </Col>
                <Col>
                  <Button
                    variant="danger"
                    size="sm"
                    className="ml-1"
                    onClick={() => setValue("tachographReviewDate", null)}
                  >
                    <MdClear />
                  </Button>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  const renderLeaseTable = () => {
    const validateLeaseAgreementNo = (val) => {
      if (!watchIsLeased) return true
      else if (!val) {
        return "Pole wymagane"
      }

      const valClear = val.replace(/\s/g, "")
      if (valClear.length === 0) {
        return "Pole wymagane"
      }
      return true
    }
    return (
      <>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Leasing</td>
              <td>
                <Form.Check
                  {...register("isLeased")}
                  type="checkbox"
                  label="tak"
                  id="isLeased"
                />
              </td>
              <td className="fixed-values-table">
                {errors.leaseAgreementNo && (
                  <>
                    <Badge pill variant="warning">
                      Pole wymagane
                    </Badge>{" "}
                    <br />
                  </>
                )}
                Nr umowy:
              </td>
              <td>
                <Form.Control
                  {...register("leaseAgreementNo", {
                    validate: validateLeaseAgreementNo,
                  })}
                  as="input"
                  type="text"
                  className=""
                  autoComplete="chrome-off"
                  readOnly={!watchIsLeased}
                />
              </td>
              <td className="fixed-values-table">GAP</td>
              <td>
                <Row>
                  <Col>
                    <Form.Control
                      {...register("GAPDate")}
                      as="input"
                      type="date"
                      className=""
                      autoComplete="chrome-off"
                      readOnly={!watchIsLeased}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ml-1"
                      onClick={() => setValue("GAPDate", null)}
                    >
                      <MdClear />
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    )
  }

  const renderDetailsTable = () => {
    return (
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Poprzednie rejestracje</td>
            <td colSpan="5">
              <Form.Control
                {...register("previousRegNumbers")}
                as="input"
                type="text"
                className=""
                autoComplete="chrome-off"
                htmlSize="35"
              />
            </td>
          </tr>

          <tr>
            <td className="fixed-values-table">VIN:</td>
            <td colSpan="3">
              {errors.vin && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("vin", { required: true })}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
                htmlSize="20"
              />
            </td>
            <td className="fixed-values-table">Rok produkcji:</td>
            <td>
              {errors.prodYear && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("prodYear", { required: true })}
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
                step="1"
                min="1950"
                max={getYear(new Date())}
              />
            </td>
          </tr>
          <tr>
            <td className="fixed-values-table">Beczka:</td>
            <td>
              <Form.Control
                {...register("tankCapacity")}
                step="any"
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">Platforma - ładowność:</td>
            <td>
              <Form.Control
                {...register("platformCapacity")}
                step="any"
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
              />
            </td>
            <td className="fixed-values-table">DMC:</td>
            <td>
              {errors.maxWeight && (
                <Badge pill variant="warning">
                  Pole wymagane
                </Badge>
              )}
              <Form.Control
                {...register("maxWeight", { required: true })}
                step="any"
                as="input"
                type="number"
                className=""
                autoComplete="chrome-off"
              />
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <Form onSubmit={handleSubmit(submitWrapper)}>
      {renderGeneralTable()}
      {renderInsuranceAndDatesTable()}
      {renderLeaseTable()}
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">
              {errors.legalOwner && (
                <>
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                  <br />
                </>
              )}
              Właściciel (DR)
              {watch("legalOwner") === "inny" ? (
                <>
                  <br />
                  <Badge pill variant="secondary">
                    Wyślij @ z prośbą o dodanie!
                  </Badge>
                </>
              ) : null}
            </td>
            <td colSpan="2">
              <Form.Control
                {...register("legalOwner", { required: true })}
                as="select"
                className="m-0"
                autoComplete="chrome-off"
              >
                <option value="inny">inny</option>

                {carsOwnersOpt.map((owner) => (
                  <option key={`carLegalOwnerSelect-${owner}`} value={owner}>
                    {owner}
                  </option>
                ))}
              </Form.Control>
            </td>
            <td className="fixed-values-table">
              {errors.legalUser && (
                <>
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                  <br />
                </>
              )}
              Korzystający (DR)
              {watch("legalUser") === "inny" ? (
                <>
                  <br />
                  <Badge pill variant="secondary">
                    Wyślij @ z prośbą o dodanie!
                  </Badge>
                </>
              ) : null}
            </td>
            <td colSpan="2">
              <Form.Control
                {...register("legalUser", { required: true })}
                as="select"
                className="m-0"
                autoComplete="chrome-off"
              >
                <option value="inny">inny</option>

                {carsOwnersOpt.map((owner) => (
                  <option key={`carLegalUserSelect-${owner}`} value={owner}>
                    {owner}
                  </option>
                ))}
              </Form.Control>
            </td>
          </tr>
        </tbody>
      </Table>
      {renderDetailsTable()}

      <Row className="justify-content-between">
        <Button onClick={() => setModalData({ show: false })}>Zamknij</Button>
        <Button
          // TODO make it return to details modal
          onClick={() => setModalData({ show: false })}
        >
          Wróć
        </Button>
        {user.type.admin ? (
          <Button
            onClick={() => {
              console.log(watch())
              console.log(errors)
            }}
          >
            ?
          </Button>
        ) : null}
        <Button type="submit" variant="secondary">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}

export default CarsEditModal
