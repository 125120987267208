import _ from "lodash"

const handleCheckboxClick = (
  e,
  loc,
  setLocationsSelected,
  shiftPressed,
  locationsSorted
) => {
  // when shift pressed select all locations between last selected and this one
  // when no location selected, select all from beginning

  if (shiftPressed && e.target.checked) {
    return setLocationsSelected((prev) => {
      //TODO handle using shift when no location selected earlier (should select from index = 0)
      const lastSelectedLoc = prev[prev.length - 1]

      const lastSelectedLocInd = locationsSorted.findIndex(
        (locationsSortedEl) => locationsSortedEl._id === lastSelectedLoc._id
      )

      const locInd = locationsSorted.findIndex(
        (locSortedEl) => locSortedEl._id === loc._id
      )

      const locationsToSelect = locationsSorted.slice(
        Math.min(lastSelectedLocInd, locInd),
        Math.max(lastSelectedLocInd, locInd) + 1
      )

      return _.uniqWith([...prev, ...locationsToSelect], _.isEqual)
    })
  }

  if (e.target.checked) {
    return setLocationsSelected((prev) => [...prev, loc])
  } else {
    return setLocationsSelected((prev) => handlePullLocation(prev, loc._id))
  }
}

const handlePullLocation = (locationsSelected, loc_id) => {
  const locationInd = locationsSelected.findIndex((loc) => loc._id === loc_id)
  if (locationInd === -1) return locationsSelected

  const locationsSelectedCopy = [...locationsSelected]
  locationsSelectedCopy.splice(locationInd, 1)

  return locationsSelectedCopy
}

export { handleCheckboxClick }
