import React, { useState } from "react"
import _ from "lodash"
import { format } from "date-fns"
import { Container, Row, Table, Button, Form, Badge } from "react-bootstrap"

import { useForm, useFieldArray, Controller } from "react-hook-form"

import { nF } from "../../utils/formats"
import { RowRemoveConfirm, ServicesSelect, prepareData } from "./utils"

const EditInvoiceRender = ({
  invoice,
  services,
  setModalData,
  refresh,
  configs: { paymentMethods, paymentDeadlines },
  isNew,
}) => {
  const [showSrvSelect, setShowSrvSelect] = useState(false)
  const [showRowRemoveConfirm, setShowRowRemoveConfirm] = useState(false) // I set this as row number AFTER WHICH confirmation should be shown
  const [fetchError, setFetchError] = useState()

  const defaultValues = { ...invoice }
  defaultValues.InvoiceDetails.InvoiceDate = format(
    new Date(invoice.InvoiceDetails.InvoiceDate),
    "yyyy-MM-dd"
  )
  if (invoice.InvoiceDetails.SellDate) {
    defaultValues.InvoiceDetails.SellDate = format(
      new Date(invoice.InvoiceDetails.SellDate),
      "yyyy-MM-dd"
    )
  } else {
    defaultValues.InvoiceDetails.SellDate = format(
      new Date(invoice.InvoiceDetails.InvoiceDate),
      "yyyy-MM-dd"
    )
  }

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: defaultValues,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "InvoiceDetails.productsResults",
  })

  const submit = async (formData) => {
    try {
      setFetchError(false)
      // console.log("invoice before edit:")
      // console.log(invoice)
      // console.log("form data:")
      // console.log(formData)
      if (!formData.InvoiceDetails.productsResults?.length)
        return setFetchError("Dodaj przynajmniej jeden wiersz faktury.")
      const updatedInv = {
        ...invoice,
        ...formData,
        // to make API proof for changes planned in ERP-322 (and setting '0' for new invoice):
        historyLength: invoice.history?.length || 0,
      }
      prepareData(updatedInv)
      // console.log("invoiceAfterEdit:")
      // console.log(updatedInv)

      const res = await fetch(`/invoices/${isNew === true ? "new" : "edit"}`, {
        method: isNew === true ? "POST" : "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ inv: updatedInv, dirtyFields: dirtyFields }),
      })
      if (res.status !== 200) throw new Error(await res.text())
      return setModalData({
        show: true,
        type: "info",
        headerColor: "success",
        body: "Zmiany zapisane",
        onHide: refresh,
      })
    } catch (err) {
      console.log(err)
      setFetchError(
        <p className="bg-danger">
          {err.message || "Błąd komunikacji z serwerem"}
        </p>
      )
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submit)}>
        <Table bordered>
          <tbody>
            {invoice.exportDates.length ? (
              <tr>
                <td className="fixed-values-table">Faktura wyeksportowana:</td>
                <td>{invoice.exportDates.join(", ")}</td>
              </tr>
            ) : null}
            <tr>
              <td className="fixed-values-table">Data faktury</td>
              <td>
                <Form.Control
                  as="input"
                  type="date"
                  {...register("InvoiceDetails.InvoiceDate")}
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
              <td className="fixed-values-table">Data sprzedaży</td>
              <td>
                <Form.Control
                  as="input"
                  type="date"
                  {...register("InvoiceDetails.SellDate")}
                  className=""
                  autoComplete="chrome-off"
                />
              </td>
              <td className="fixed-values-table">Adres @</td>
              <td>{invoice.clientResult.clientEmail}</td>
            </tr>
            <tr>
              <td className="fixed-values-table">Dane klienta</td>
              <td colSpan="3">
                {invoice.clientResult?.clientBillingAddress &&
                  Object.values(invoice.clientResult.clientBillingAddress).join(
                    ", "
                  )}
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table">Rodzaj płatności</td>
              <td>
                <Form.Control
                  {...register("InvoiceDetails.payments.InvoicePaymentType")}
                  as="select"
                  className=""
                  autoComplete="chrome-off"
                >
                  {paymentMethods.map((type) => (
                    <option value={type} key={`paymentTypesSelect-${type}`}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td className="fixed-values-table">Termin</td>
              <td>
                <Form.Control
                  {...register("InvoiceDetails.payments.InvoicePaymentDays")}
                  as="select"
                  className=""
                  autoComplete="chrome-off"
                >
                  {paymentDeadlines
                    .filter((deadline) => typeof deadline === "number")
                    .map((deadline) => (
                      <option
                        key={`paymentDeadlineSelect-${deadline}`}
                        value={deadline}
                      >
                        {deadline}
                      </option>
                    ))}
                </Form.Control>
              </td>
            </tr>
          </tbody>
        </Table>
        <h4>Pozycje</h4>
        <Table>
          <tbody>
            <tr>
              <td>Nazwa</td>
              <td>Cena netto</td>
              <td>VAT %</td>
              <td>Cena brutto</td>
              <td>Ilość</td>
              <td>Wartość netto</td>
              <td>Wartość brutto</td>
            </tr>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <tr>
                    <td>
                      {errors.InvoiceDetails?.productsResults &&
                        errors.InvoiceDetails?.productsResults[index]
                          ?.productName && (
                          <Badge pill variant="warning">
                            Pole wymagane
                          </Badge>
                        )}
                      <Form.Control
                        {...register(
                          `InvoiceDetails.productsResults.${index}.productName`,
                          {
                            required: "Pole wymagane",
                          }
                        )}
                        as="textarea"
                        type=""
                        className=""
                        autoComplete="chrome-off"
                        defaultValue={field.productName}
                      />
                    </td>
                    <td>
                      {errors.InvoiceDetails?.productsResults &&
                        errors.InvoiceDetails?.productsResults[index]
                          ?.productInvoicePriceNet && (
                          <Badge pill variant="warning">
                            !
                          </Badge>
                        )}
                      <Controller
                        name={`InvoiceDetails.productsResults.${index}.productInvoicePriceNet`}
                        control={control}
                        rules={{ required: true, min: 0.01 }}
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            as="input"
                            type="number"
                            step="any"
                            className="medium-number-input"
                            autoComplete="chrome-off"
                            onChange={(e) => {
                              setValue(
                                `InvoiceDetails.productsResults.${index}.productInvoicePrice`,
                                _.round(
                                  e.target.value *
                                    (1 +
                                      getValues(
                                        `InvoiceDetails.productsResults.${index}.productVat`
                                      ) /
                                        100),
                                  2
                                )
                              )
                              field.onChange(e)
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      {errors.InvoiceDetails?.productsResults &&
                        errors.InvoiceDetails?.productsResults[index]
                          ?.productVat && (
                          <Badge pill variant="warning">
                            !
                          </Badge>
                        )}
                      <Controller
                        name={`InvoiceDetails.productsResults.${index}.productVat`}
                        control={control}
                        rules={{
                          required: true,
                          min: 0,
                        }}
                        render={({ field }) => {
                          return (
                            <Form.Control
                              {...field}
                              as="input"
                              type="number"
                              step="any"
                              className="small-number-input"
                              autoComplete="chrome-off"
                              onChange={(e) => {
                                setValue(
                                  `InvoiceDetails.productsResults.${index}.productInvoicePrice`,
                                  _.round(
                                    getValues(
                                      `InvoiceDetails.productsResults.${index}.productInvoicePriceNet`
                                    ) *
                                      (1 + e.target.value / 100),
                                    2
                                  )
                                )
                                field.onChange(e)
                              }}
                            />
                          )
                        }}
                      />
                    </td>
                    <td>
                      {errors.InvoiceDetails?.productsResults &&
                        errors.InvoiceDetails?.productsResults[index]
                          ?.productInvoicePrice && (
                          <Badge pill variant="warning">
                            !
                          </Badge>
                        )}
                      <Controller
                        name={`InvoiceDetails.productsResults.${index}.productInvoicePrice`}
                        control={control}
                        rules={{ required: true, min: 0.01 }}
                        render={({ field }) => (
                          <Form.Control
                            {...field}
                            as="input"
                            type="number"
                            step="any"
                            className="medium-number-input"
                            autoComplete="chrome-off"
                            onChange={(e) => {
                              setValue(
                                `InvoiceDetails.productsResults.${index}.productInvoicePriceNet`,
                                _.round(
                                  e.target.value /
                                    (1 +
                                      getValues(
                                        `InvoiceDetails.productsResults.${index}.productVat`
                                      ) /
                                        100),
                                  2
                                )
                              )
                              field.onChange(e)
                            }}
                          />
                        )}
                      />
                    </td>
                    <td>
                      {errors.InvoiceDetails?.productsResults &&
                        errors.InvoiceDetails?.productsResults[index]
                          ?.productQuantity && (
                          <Badge pill variant="warning">
                            !
                          </Badge>
                        )}
                      <Form.Control
                        {...register(
                          `InvoiceDetails.productsResults.${index}.productQuantity`,
                          { required: true, min: 0.01 }
                        )}
                        as="input"
                        type="number"
                        step="any"
                        className="medium-number-input"
                        autoComplete="chrome-off"
                        defaultValue={field.productQuantity}
                      />
                    </td>
                    <td>
                      {nF(
                        watch(
                          `InvoiceDetails.productsResults.${index}.productInvoicePriceNet`
                        ) *
                          watch(
                            `InvoiceDetails.productsResults.${index}.productQuantity`
                          )
                      )}
                    </td>
                    <td>
                      {" "}
                      {nF(
                        watch(
                          `InvoiceDetails.productsResults.${index}.productInvoicePrice`
                        ) *
                          watch(
                            `InvoiceDetails.productsResults.${index}.productQuantity`
                          )
                      )}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => setShowRowRemoveConfirm(index)}
                      >
                        X
                      </Button>
                    </td>
                  </tr>
                  {showRowRemoveConfirm === index ? (
                    <tr>
                      <td colSpan="8">
                        <RowRemoveConfirm
                          remove={remove}
                          setShowRowRemoveConfirm={setShowRowRemoveConfirm}
                          index={index}
                        />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
          </tbody>
          <tfoot>
            <tr style={{ fontWeight: 700 }}>
              <td colSpan="5"></td>
              <td>
                {getTotal(
                  watch("InvoiceDetails.productsResults"),
                  "productInvoicePriceNet"
                )}
              </td>
              <td>
                {getTotal(
                  watch("InvoiceDetails.productsResults"),
                  "productInvoicePrice"
                )}
              </td>
            </tr>
          </tfoot>
        </Table>
        <Row className="justify-content-between">
          <Button onClick={() => setShowSrvSelect(true)}>Dodaj wiersz</Button>
          <Button type="submit" variant="secondary">
            Zapisz
          </Button>
        </Row>
        {showSrvSelect ? (
          <ServicesSelect
            services={services}
            append={append}
            setShowSrvSelect={setShowSrvSelect}
          />
        ) : null}
      </Form>

      {fetchError ? (
        <Row className="bg-warningLight mt-2 justify-content-around">
          {fetchError}
        </Row>
      ) : null}
    </Container>
  )
}

/**
 *
 * @param {Array} rows of invoice (fieldArray)
 * @param {String} fieldName to sum
 * @returns sum of all rows
 */
const getTotal = (rows, fieldName) => {
  let sum = 0
  rows.forEach((element) => {
    sum += element[fieldName] * element.productQuantity
  })
  return nF(sum)
}
export default EditInvoiceRender
