import React from "react"
import { Table, Container } from "react-bootstrap"

const BranchConsumptionRender = (props) => {
  const goodsHeaders = []
  //create header from all consumptions:
  const createHeaders = () => {
    props.consumptions.map((consumption) => {
      //map through all consumptionsSums in all consumptions objects...
      consumption.consumptionDetails.consumptionsSums
        //  in each object...
        .map((sum) => {
          // ...for each consumption return consumption name -> create consumption names array for each object
          return sum.name
        })
        //map through this array, check if each item is present in final arr (goodsHeaders) if so return null, else push this item to final arr
        //returning null does not complicate, since i'm not doing anything with resultant array. Propably should replace map fith forEach, maybe some day...
        .map((name) => {
          if (goodsHeaders.includes(name)) return null
          else {
            goodsHeaders.push(name)
            return null
          }
        })
      return null
    })
  }
  createHeaders()
  //gather all additional infos from consumptions to one array, with employee name
  const createInfo = () => {
    return props.consumptions.map((consumption) => {
      if (consumption.additionalInfo !== "") {
        return consumption.user_id.name + ": " + consumption.additionalInfo
      } else return ""
    })
  }
  const additionalInfo = createInfo()

  // checking if units in saved consumptions are equal (per good.name, more details below rendering table)
  const checkUnitsCompliance = (arr, headerName) => {
    if (arr.length === 1) return true
    const unitsArr = arr.map((el) => el.unit)
    const finalUnitsArr = arr.map((el) => el.finalUnit)
    const dividersArr = arr.map((el) => el.divider)

    const testArr = (arrToTest) => {
      if (arrToTest.every((el) => el === arrToTest[0])) return true
      else {
        return false
      }
    }

    if (testArr(unitsArr) && testArr(finalUnitsArr) && testArr(dividersArr))
      return true
    else {
      alert("Błąd krytyczny: niespójne jednostki lub dzielniki!")
      console.log("problem with: " + headerName)
      window.location.reload()
      return
    }
  }

  return (
    <Container className="mb-5">
      <Table>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Zużycie</th>
            <th>Przelicznik jednostek</th>
            <th>Zużycie przeliczone</th>
          </tr>
        </thead>
        <tbody>
          {goodsHeaders.map((header) => {
            // create array of consumptions for EACH good from all fetched consumptions (so this array contains n object, each with: name|quantity|unit|finalUnit|divider etc )
            const sumsArr = props.consumptions.map((consumption) => {
              const value = consumption.consumptionDetails.consumptionsSums.find(
                (a) => a.name === header
              )
              return value
            })
            // check if above array is consistent when it comes to unit, finalUnit and divider, if no throw alert and reload window
            checkUnitsCompliance(sumsArr, header)
            return (
              <tr key={`tr-${header}`}>
                <td>{header}</td>
                <td>
                  {sumsArr
                    .map((a) => {
                      if (a === undefined) return 0
                      else return a.quantity
                    })
                    .reduce((a, b) => a + b)
                    .toLocaleString()}
                </td>
                {/* render "unit recalc string" (for ex "opk. 5l. / 5 -> l.") */}
                <td>
                  <b>
                    {sumsArr[0].unit}
                    {" / "}
                    {sumsArr[0].divider}
                    {" -> "}
                    <u>{sumsArr[0].finalUnit}</u>
                  </b>
                </td>
                <td>
                  {sumsArr
                    .map((a) => {
                      if (a === undefined) return 0
                      else return a.quantity * a.divider
                    })
                    .reduce((a, b) => a + b)
                    .toLocaleString()}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <b>Dodatkowe informacje</b>
      {additionalInfo.map((text, i) => {
        if (text.length)
          return (
            <p className="keep-br" key={`text-p-${i}`}>
              {text}
            </p>
          )
        else return null
      })}
    </Container>
  )
}
export default BranchConsumptionRender
