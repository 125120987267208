import { getYear } from "date-fns"
import React from "react"
import { Container, Button, Form } from "react-bootstrap"
import { createManualBtn } from "../../utils/buttons"

const BranchesStatsForm = ({ user, setFilters, isLoading }) => {
  const handleSubmit = (e) => {
    e.preventDefault()

    setFilters({ branch: e.target.branch.value, year: e.target.year.value })
  }

  return (
    <Container fluid>
      <Form inline onSubmit={handleSubmit}>
        <Form.Control
          name="branch"
          as="select"
          type=""
          className=""
          autoComplete="chrome-off"
          defaultValue={user.defaultBranch || user.allowedBranches[0]}
        >
          {user.allowedBranches.map((branch) => (
            <option key={`branchSelectOptions-${branch}`}>{branch}</option>
          ))}
        </Form.Control>
        <Form.Control
          name="year"
          as="input"
          type="number"
          step="1"
          max={getYear(new Date())}
          min="2021"
          defaultValue={getYear(new Date())}
          className=""
          autoComplete="chrome-off"
        />
        <Button type="submit" className="mr-auto" disabled={isLoading}>
          Oblicz
        </Button>
        {createManualBtn("branchesStats")}
      </Form>
    </Container>
  )
}
export { BranchesStatsForm }
