import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { LocationEditGeneralModalRender } from "./Render"

const LocationEditGeneralModalFetch = ({
  user,
  cust_id,
  custHistLength,
  isNew,
  loc_id,
  locHistLength,
  setModalData,
  locationsNames,
  refresh,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [permissions, setPermissions] = useState([])

  // state for fresh data of location and customer
  const [locationFetched, setLocationFetched] = useState()
  const [customerFetched, setCustomerFetched] = useState()
  // when there are diffreneces in loc or cust passed by props and fetched -> show info to user
  const [staleDataErrorAlert, setStaleDataErrorAlert] = useState()

  useEffect(() => {
    const fetchLoc = async () => {
      const res = await fetch(`/locations/getById/${loc_id}`)
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania danych: ${res.status} - ${await res.text()}`
        )
      const resJSON = await res.json()
      setLocationFetched(resJSON)
      if (resJSON.history.length !== locHistLength) setStaleDataErrorAlert(true)
    }
    const fetchCust = async () => {
      const res = await fetch(`/customers/getById/${cust_id}`)
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania danych: ${res.status} - ${await res.text()}`
        )
      const resJSON = await res.json()
      setCustomerFetched(resJSON)
      if (resJSON.history.length !== custHistLength)
        setStaleDataErrorAlert(true)
    }
    const fetchPerms = async () => {
      const res = await fetch(`/sewage/getAllPerm?verbose=false`)
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200)
        throw new Error(
          `Błąd pobierania danych: ${res.status} - ${await res.text()}`
        )
      const resJSON = await res.json()

      setPermissions(resJSON)
    }

    const fetchAll = async () => {
      try {
        setStaleDataErrorAlert(false)
        const fetchArr = [fetchCust(), fetchPerms()]
        if (!isNew) fetchArr.push(fetchLoc())
        await Promise.all(fetchArr)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: `Błąd: ${err.message}`,
        })
      }
    }
    fetchAll()
  }, [custHistLength, cust_id, isNew, locHistLength, loc_id, setModalData])

  if (isLoading)
    return (
      <>
        <Spinner animation="border" />
        Pobieram dane
      </>
    )
  else
    return (
      <LocationEditGeneralModalRender
        permissions={permissions}
        user={user}
        customer={customerFetched}
        isNew={isNew}
        location={locationFetched}
        setModalData={setModalData}
        locationsNames={locationsNames}
        refresh={refresh}
        staleDataErrorAlert={staleDataErrorAlert}
      />
    )
}
export { LocationEditGeneralModalFetch }
