import React, { useState, useContext } from "react"
import { useForm } from "react-hook-form"

import { Button, Form, Row, Col, Alert, Badge } from "react-bootstrap"

import UserContext from "../../contexts/userContext"
import { createManualBtn } from "../../utils/buttons"
const EquipmentEditModal = (props) => {
  const user = useContext(UserContext)
  const { configs, setModalData, eqp } = props
  const [fetchAlert, setFetchAlert] = useState(null)
  const eqpTypes = configs.eqpTypes
  const isNew = eqp === "new"
  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: isNew ? { type: eqpTypes[0].type, isTemplate: true } : eqp,
  })

  const submit = async (formData) => {
    try {
      if (
        formData.type !== "Toaleta" &&
        formData.type !== "Prysznic" &&
        formData.isTemplate
      )
        return setError("isTemplate", {
          message: (
            <p>
              Tylko toaleta i prysznic mogą być templatkami.
              <br />
              Dla innych typów dodaj realny sprzęt z numerem 0
            </p>
          ),
        })

      const data = {
        type: formData.type,
        mods: formData.mods || [],
        multiple: formData.multiple,
        desc: formData.desc,
        lastNumber: formData.lastNumber,
        branch: formData.branch,
        isTemplate: formData.isTemplate,
        number: formData.number,
      }

      //* clean eqp props that not apply for this eqp type
      //( RHF does't unregister selects, so eqp types without all eqp fields end up with "wybierz" value for this props)
      // find eqpType configs:
      const selectedEqpTypeConfig = eqpTypes.find(
        (eqpTypesEl) => eqpTypesEl.type === formData.type
      )

      // for each prop name add it into data obj if applies to selectedEqpType:
      const propsArr = ["subtype", "char", "color", "category"]
      propsArr.forEach((prop) => {
        if (selectedEqpTypeConfig[prop]?.length) data[prop] = formData[prop]
      })

      let res

      if (!isNew) data._id = eqp._id

      if (formData.multiple) res = await batchAdd(data)
      else
        res = await fetch(`/equipment/${isNew ? "add" : "edit"}`, {
          method: isNew ? "POST" : "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ eqp: data, dirtyFields: null }),
        })

      if (res.status === 409) return setFetchAlert(await res.text())
      if (res.status !== 200) {
        console.log(res)
        throw new Error(await res.text())
      }
      setModalData({
        show: true,
        type: "info",
        headerColor: "green",
        body: `Sprzęt ${isNew ? "dodany" : "zmodyfikowany"}`,
        header: "Sukces",
        onHide: () => window.location.reload(),
      })
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: `Błąd\n${err.message}`,
      })
    }
  }

  const batchAdd = async (formData) => {
    const res = await fetch("/equipment/batchAdd", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
    return res
  }

  const renderSelects = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))

    // most of equipment props are one-choose-only
    // this func creates select for each of such field

    // firts create map of avaliable fields:
    const keysMap = [
      { name: "subtype", plName: "Podtyp" },
      { name: "char", plName: "Charakterystyka" },
      { name: "color", plName: "Kolor" },
      { name: "category", plName: "Kategoria" },
    ]

    //  create select field for each non-empty key:
    const formArr = keysMap.map((key) => {
      // console.log(key)
      // if no values for selected type -> return null

      if (eqpSelected[key.name]?.length === 0) return null
      else
        return (
          <Col key={`equipment-form-${key.name}`}>
            {key.plName}:{" "}
            {errors[key.name]?.type === "valueChoosen" && (
              <Badge pill variant="warning">
                Pole wymagane
              </Badge>
            )}
            {errors[key.name]?.type === "noTemplateValue" && (
              <Badge pill variant="warning">
                wybierz konkretną wartość albo dodaj templatkę
              </Badge>
            )}
            <Form.Control
              key={`${eqpSelected.type}-${key.name}-select`}
              {...register(key.name, {
                required: true,
                validate: {
                  valueChoosen: (v) => v !== "wybierz",
                  noTemplateValue: (v) => {
                    if (!watch("isTemplate") && v === "bez znaczenia")
                      return false
                    else return true
                  },
                },
              })}
              as="select"
              type=""
              className=""
              autoComplete="chrome-off"
            >
              {/* null option */}
              <option value={null} hidden>
                wybierz
              </option>
              {eqpSelected[key.name].map((el) => {
                // prevent showing "bez znaczenia" options for non-template
                if (!watch("isTemplate") && el === "bez znaczenia") return null
                else
                  return (
                    <option
                      key={`${eqpSelected.type}-${key.name}-${el}-select`}
                      value={el}
                    >
                      {el}
                    </option>
                  )
              })}
            </Form.Control>
          </Col>
        )
    })

    return formArr.filter((el) => el)
  }

  const renderCheckboxes = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))
    // most of equipment props are multiple-choose

    // firts create map of avaliable fields:
    const keysMap = [{ name: "mods", plName: "Modyfikacje" }]

    //  create checkboxes for each non-empty key:
    const formArr = keysMap.map((key) => {
      // if no values for selected type -> return null
      if (!eqpSelected[key.name].length) return null
      else
        return (
          <span key={`checkboxes-${key}`}>
            <hr />
            <h5>{key.plName}</h5>
            {eqpSelected[key.name].map((el) => (
              <Form.Check
                inline
                key={`${key.name}-${el}-checkbox`}
                {...register(`mods`)}
                type="checkbox"
                value={el}
                label={el}
                id={el}
              />
            ))}
          </span>
        )
    })

    return formArr.filter((el) => el)
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Row className="ml-2">
        <Col>
          Typ:
          <Form.Control
            {...register("type")}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            {eqpTypes.map((eqp) => (
              <option key={`type-select-${eqp.type}`} value={eqp.type}>
                {eqp.type}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Form.Check
            {...register("isTemplate")}
            type="checkbox"
            label="templatka"
            id="isTemplate"
            disabled={watch("type") === "Sprzęt klienta"}
          />
          {createManualBtn("eqpTemplate")}
          {errors.isTemplate && (
            <Badge pill variant="warning">
              {errors.isTemplate.message}
            </Badge>
          )}
        </Col>
        <Col>
          Oddział:
          {errors.branch && (
            <Badge pill variant="warning">
              Pole wymagane
            </Badge>
          )}
          <Form.Control
            {...register("branch", {
              validate: (val) => {
                if (!watch("isTemplate") && !val) return false
                else return true
              },
            })}
            as="select"
            className=""
            autoComplete="chrome-off"
            defaultValue={null}
            disabled={watch("isTemplate")}
          >
            <option value={null} hidden></option>
            {user.allowedBranches.map((branch) => (
              <option key={`type-select-${branch}`} value={branch}>
                {branch}
              </option>
            ))}
          </Form.Control>
        </Col>
        {/* when 'inne' selected -> show 'name' field */}
        {watch("type") === "inny" && (
          <>
            Nazwa:
            <Form.Control
              {...register("name")}
              as="input"
              type=""
              className=""
              autoComplete="chrome-off"
            />
          </>
        )}
      </Row>
      <Row>
        {/* render select-type form elements */}
        {renderSelects()}
      </Row>
      <Form.Control
        {...register("desc")}
        as="textarea"
        type=""
        className=""
        autoComplete="chrome-off"
      />
      {/* render multiple choice form elements */}
      <Row className="ml-2">{renderCheckboxes()}</Row>
      {/* if not template -> set number */}
      <hr />
      <h5>Numerowanie</h5>
      <Row>
        <Col>
          <br />
        </Col>
        <Col>
          {/* when is template -> disable number */}
          Numer/oznaczenie:{" "}
          <Form.Control
            {...register("number")}
            as="input"
            type=""
            className=""
            autoComplete="chrome-off"
            disabled={watch("isTemplate") ? true : false}
          />
        </Col>
      </Row>

      {/* when not template and not editing existing -> user can choose to add multiple equipment */}
      {isNew && (
        <Row>
          <Col>
            <Form.Check
              {...register("multiple")}
              type="checkbox"
              label="dodaj wiele (DO ZROBIENIA)"
              id="multiple"
              disabled={watch("isTemplate") ? true : false}
            />
          </Col>
          <Col>
            Numer/oznaczenie - OSTATNIE:{" "}
            <Form.Control
              {...register("lastNumber")}
              as="input"
              type=""
              className=""
              autoComplete="chrome-off"
              disabled={
                watch("isTemplate") || !watch("multiple") ? true : false
              }
            />
          </Col>
        </Row>
      )}

      {fetchAlert && <Alert variant="warning">{fetchAlert}</Alert>}
      <Row>
        <Button type="submit" variant="secondary" className="ml-auto">
          Zapisz
        </Button>
      </Row>
    </Form>
  )
}
export default EquipmentEditModal
