import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns"
import format from "date-fns/format"
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react"

import { Container, Toast } from "react-bootstrap"

import UserContext from "../contexts/userContext"

import MyModal from "../utils/Modals"

import LocationsDetailsRender from "./Render"
import { jobsFetch } from "./utils/jobsFetch"

const LocationCtx = createContext(null)

const LocationsDetailsFetch = (props) => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState(null)
  const [services, setServices] = useState(null)
  const [jobs, setJobs] = useState([])
  const [configs, setConfigs] = useState(null)
  const [cars, setCars] = useState([])
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [toast, setToast] = useState(null)
  const [showSrvParams, setShowSrvParams] = useState(false)

  //$ see coment at the end for more inf about fetching jobs
  //!!! DATES MUST BE STRINGS 'yyyy-MM-dd'!!!
  //! this state can contain jobsDates.exclude = {start:..., end:...}, more info at the end
  const [jobsDates, setJobsDates] = useState({
    start: format(
      startOfWeek(subMonths(new Date(), 3), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
    end: format(
      endOfWeek(addMonths(new Date(), 1), { weekStartsOn: 1 }),
      "yyyy-MM-dd"
    ),
  })
  const [areJobsLoading, setAreJobsLoading] = useState(true)

  // keep selected tab in Fetch to persist it when refreshing:
  const [tabSelected, setTabSelected] = useState("general")

  // declare vars and refs used later with map elements in map modal:
  const mapRef = useRef(null)
  const formRef = useRef(null)

  let autocomplete
  let map
  let marker = null
  let place = null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`/locations/getById/${props.match.params._id}`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych lokalizacji: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        setLocation(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    //!!! when removing below (since it should be removed) handle quering services collection for services already used on loc
    //( it is needed when there is change to canHaveSubSrv in services to allow assigning subSrv to this srv)
    //( more details in SubSrvEditModal)
    const fetchServices = async () => {
      try {
        const res = await fetch(`/services/getAll`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania katalogu usług: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()
        setServices(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    const fetchConfigs = async () => {
      try {
        const res = await fetch(
          `/configs/jobType,jobState,GOOGLE_MAPS_API_KEY,equipmentNames,calendarIcons,eqpTypes`
        )
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych konfiguracyjnych: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        setConfigs(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }
    // const fetchEquipment = async () => {
    //   try {
    //     const res = await fetch(`/equipment/getAll`)
    //     if (res.status === 403)
    //       return setModalData({
    //         show: true,
    //         type: "info",
    //         body: "Brak uprawnień",
    //       })
    //     if (res.status !== 200)
    //       throw new Error(
    //         `Błąd pobierania danych sprzętu: ${res.status} - ${
    //           (await res.text()) || "nieokreślony błąd"
    //         }`
    //       )
    //     const resJSON = await res.json()

    //     setEquipment(resJSON)
    //   } catch (err) {
    //     console.log(err)
    //     throw err
    //   }
    // }

    const fetchCars = async () => {
      try {
        const res = await fetch(`/cars/getFiltered`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            branches: user.allowedBranches, // don't know location branch yet, so I fetch cars for all user branches
            query: "",
            type: "all",
          }),
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200)
          throw new Error(
            `Błąd pobierania danych samochodów: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        const resJSON = await res.json()

        return setCars(resJSON)
      } catch (err) {
        console.log(err)
        throw err
      }
    }

    const fetchAll = async () =>
      await Promise.all([
        fetchServices(),
        fetchData(),
        fetchConfigs(),
        // fetchEquipment(),
        fetchCars(),
      ])
        .then(() => setIsLoading(false))
        .catch((err) =>
          setModalData({
            show: true,
            type: "alert",
            header: "Błąd pobierania danych",
            body: err.message || "nieokreślony błąd działania programu",
          })
        )

    fetchAll()
  }, [isLoading, props.match.params._id, user, refreshCounter])

  //* FETCH JOBS
  // used on initial fetch and then after jobsDates changes (there is need to fetch missing jobs)
  // after changing anything on location (including job) I use refresh() below
  useEffect(() => {
    jobsFetch(
      props.match.params._id,
      jobsDates,
      setJobs,
      setAreJobsLoading,
      setModalData,
      jobs,
      false
    )

    // disable eslint warn to add jobs to dep arr, as it would cause double fetch
    //eslint-disable-next-line
  }, [jobsDates, props.match])

  /**
   *
   * @param {object} toast toast object to show
   * @param {string} toast.header toast header content
   * @param {string} toast.headerColor toast header bg color ('bg-success' etc.)
   * @param {string} toast.body toast body content
   */
  const refresh = (toast) => {
    setModalData({ show: false })
    setIsLoading(true)
    setRefreshCounter(refreshCounter + 1)
    jobsFetch(
      props.match.params._id,
      jobsDates,
      setJobs,
      setAreJobsLoading,
      setModalData,
      jobs,
      true
    )
    map = null
    marker = null
    if (toast) setToast(toast)
  }

  // prepare google map api script:
  useEffect(() => {
    // push div with google api key to DOM:

    if (
      !document.getElementById("googleMapScript") &&
      !isLoading &&
      configs?.GOOGLE_MAPS_API_KEY
    ) {
      var create = document.createElement.bind(document)
      const googleMapScript = create("script")
      googleMapScript.id = "googleMapScript"
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${configs.GOOGLE_MAPS_API_KEY}&libraries=places`
      // set event listener and fire map initialization when div loaded:
      // googleMapScript.addEventListener("load", initMap)
      window.document.body.appendChild(googleMapScript)
    }
  }, [isLoading, configs])

  //map initialization fired in map modal with useEffect:
  const initMap = () => {
    map =
      location.coords?.coordinates?.length > 0
        ? new window.google.maps.Map(mapRef.current, {
            center: {
              lng: location.coords.coordinates[0],
              lat: location.coords.coordinates[1],
            },
            zoom: 16,
          })
        : new window.google.maps.Map(mapRef.current, {
            center: { lat: 51.919438, lng: 19.145136 },
            zoom: 6,
          })
    autocomplete = new window.google.maps.places.Autocomplete(formRef.current)
    autocomplete.addListener("place_changed", onPlaceChange)

    //if there are coordinates in location, prepare marker:
    if (location.coords?.coordinates?.length > 0) {
      marker = new window.google.maps.Marker({
        position: {
          lng: location.coords.coordinates[0],
          lat: location.coords.coordinates[1],
        },
        map: map,
        draggable: true,
      })
      map.panTo(marker.getPosition())
      map.setZoom(16)
    }
  }
  const onPlaceChange = () => {
    place = autocomplete.getPlace()

    // break if there is no marker
    if (!place.geometry) return

    map.panTo(place.geometry.location)
    map.setZoom(16)
    marker = new window.google.maps.Marker({
      position: place.geometry.location,
      map: map,
      draggable: true,
    })
  }
  const getMarkerPosition = () => {
    if (marker) return marker.getPosition().toJSON()
    else return null
  }

  return (
    <LocationCtx.Provider
      value={{
        location: location,
        user: user,
        configs: configs,
        services: services,
        jobs: jobs,
        cars: cars,
        setModalData: setModalData,
        refresh: refresh,
        initMap: initMap,
        mapRef: mapRef,
        formRef: formRef,
        getMarkerPosition: getMarkerPosition,
        areJobsLoading: areJobsLoading,
        setAreJobsLoading: setAreJobsLoading,
        jobsDates: jobsDates,
        setJobsDates: setJobsDates,
        showSrvParams: showSrvParams,
        setShowSrvParams: setShowSrvParams,
      }}
    >
      <Container fluid>
        {isLoading ? (
          <p>Pobieram dane</p>
        ) : (
          <LocationsDetailsRender
            // pass state and setState used to control selected tab - this is hold in Fetch to preserve state during refresh
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
          />
        )}
        <MyModal setModalData={setModalData} modalData={modalData} />
      </Container>

      <Toast
        show={toast ? true : false}
        delay={5000}
        autohide
        onClose={() => setToast(null)}
        style={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "20%",
        }}
      >
        <Toast.Header
          style={{ justifyContent: "space-between" }}
          className={toast?.headerColor || ""}
        >
          {toast?.header}
        </Toast.Header>
        <Toast.Body>{toast?.body}</Toast.Body>
      </Toast>
    </LocationCtx.Provider>
  )
}
export { LocationsDetailsFetch, LocationCtx }

/* 
*FETCHING JOBS
at first I fetch jobs 3 months older and 1 month younger then today (rounding to weeks: monday - sunday)
when user changes date in calendar I check  if it first and last displayed dates are still within initial interval, 
if not I fetch missing jobs
_BUT I fetch only missing jobs, so when calendar change forces fetch it also adds jobsDates.exclude object, which causes api to 
_send only needed data

same with table: when any of dates gets out of given interval I fetch missing jobs

after fetching I make sure that there are no dups, push new jobs to jobs state and update start and end dates

*/
