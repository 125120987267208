import React, { useEffect, useState, useContext } from "react"
import { Container, Row, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import TodosListForm from "./form"
import TodosListRender from "./render"
import TodosDetailsEdit from "../details/edit"
import { handleSorting } from "./utils"
import renderSpinner from "../../utils/renderSpinner"

const TodosListFetch = () => {
  const user = useContext(UserContext)

  const [modalData, setModalData] = useState({ show: false })
  const [formConfigs, setFormConfigs] = useState()
  const [isFormConfigsLoading, setIsFormConfigsLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [todos, setTodos] = useState([])
  const [propositions, setPropositions] = useState([])
  const [board, setBoard] = useState([])

  const [refreshCounter, setRefreshCounter] = useState(0)
  const [filters, setFilters] = useState({
    employee: user._id,
    tags: [],
    states: ["weryfikacja", "zlecone", "w realizacji", "do sprawdzenia"],
    creator: "any",
  })
  const [sortBy, setSortBy] = useState({ field: "priority", order: 1 })

  // fetch form configs:
  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const res = await fetch("/configs/todosTags,todosStates")
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setFormConfigs(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchBoard = async () => {
      try {
        const res = await fetch(`/employees/getBoard`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setBoard(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchAll = async () => {
      await Promise.all([fetchConfigs(), fetchBoard()])
      setIsFormConfigsLoading(false)
    }
    fetchAll()
  }, [])

  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const res = await fetch("/todos/getFiltered", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(filters),
        })
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        let resJSON = await res.json()

        // parse dates to enable sorting:
        resJSON = resJSON.map((todo) => ({
          ...todo,
          deadline: new Date(todo.deadline),
        }))
        const resSorted = handleSorting(sortBy, resJSON)
        setTodos(resSorted.filter((el) => !el.isProposition))
        setPropositions(resSorted.filter((el) => el.isProposition))

        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    const fetchBoard = async () => {
      try {
        const res = await fetch(`/employees/getBoard`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setBoard(resJSON)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }

    const fetchAll = async () => {
      await Promise.all([fetchTodos(), fetchBoard()])
    }

    fetchAll()
    //disabling eslint to prevent it from forcing sortBy in dependencies arr, as I don't want to fetch on each sorting change
    //eslint-disable-next-line
  }, [refreshCounter, filters])

  const refresh = () => {
    setModalData({ show: false })
    setRefreshCounter(refreshCounter + 1)
  }

  useEffect(() => {
    const todosReady = handleSorting(sortBy, todos)

    setTodos(todosReady)

    // eslint forces todos into dependencies, which causes infinite loop
    //eslint-disable-next-line
  }, [sortBy])
  return (
    <Container fluid>
      <Helmet>
        <title>Do zrobienia</title>
      </Helmet>

      {isFormConfigsLoading ? (
        renderSpinner("...pobieram dane konfiguracyjne formularza...")
      ) : (
        <TodosListForm
          setFilters={setFilters}
          user={user}
          formConfigs={formConfigs}
          board={board}
        />
      )}

      <Row>
        <Button
          variant="secondary"
          className="ml-auto mr-4"
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "Dodaj zadanie",
              body: (
                <TodosDetailsEdit
                  isNew={true}
                  setModalData={setModalData}
                  refresh={refresh}
                  board={board}
                />
              ),
            })
          }
        >
          Dodaj
        </Button>
      </Row>
      {isLoading ? (
        renderSpinner("...pobieram zadania...")
      ) : (
        <TodosListRender
          todos={todos}
          propositions={propositions}
          setModalData={setModalData}
          refresh={refresh}
          sortBy={sortBy}
          setSortBy={setSortBy}
          user={user}
          board={board}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default TodosListFetch
