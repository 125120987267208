const createEqpString = (srvEqp) => {
  let eqpStr = `${srvEqp.qty} x ${srvEqp.ref.type}`

  const propsArr = ["category", "char", "color", "desc", "subtype"]
  propsArr.forEach((prop) => {
    if (
      srvEqp.ref[prop] &&
      srvEqp.ref[prop] !== "bez znaczenia" &&
      srvEqp.ref[prop] !== "wybierz"
    ) {
      eqpStr = `${eqpStr} ${srvEqp.ref[prop]}`
    }
  })

  if (srvEqp.ref.number) eqpStr = `${eqpStr} nr ${srvEqp.number}`
  if (srvEqp.ref.mods?.length)
    eqpStr = `${eqpStr} (${srvEqp.ref.mods.join(", ")})`

  return eqpStr
}

export { createEqpString }
