import React, { useContext, useState, useEffect } from "react"

import {
  startOfWeek,
  addDays,
  getWeek,
  format,
  isSameDay,
  isWithinInterval,
  startOfDay,
  isBefore,
  getMonth,
} from "date-fns"
import ReactTooltip from "react-tooltip"

import { Row, Table, Button, Form, Spinner } from "react-bootstrap"

import {
  MdAddCircleOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md"

import { LocationCtx } from "../Fetch"

import LocationJobsAdd from "../Jobs/LocationJobsAdd"
import LocationJobsBatchEditFetch from "../Jobs/LocationJobsBatchEditFetch"

import {
  createButton,
  calendarLegendModalBody,
  ChooseJobModal,
  handleCalendarDatesChange,
  getMonthShortcut,
  setCellClassName,
} from "./calendarHelpers"

//TODO move all logic, states etc to child component (this component renders anything only if jobs are not loading, so all operations are not necessary here)
// BUT keep offset here, to prevent it from reseting when fetching jobs on calendar scroll

const LocationsDetailsCalendar = () => {
  const {
    setModalData,
    jobs,
    location,
    configs,
    refresh,
    user,
    cars,
    jobsDates,
    setJobsDates,
    areJobsLoading,
  } = useContext(LocationCtx)

  const currWeekNum = getWeek(new Date(), { weekStartsOn: 1 })

  // offset is used to change calendar view (offset === 0 => display from -1 to +3 weeks from today)
  const [offset, setOffset] = useState(0)
  const [revealHidden, setRevealHidden] = useState(false) // by 'hidden' I mean archived and deleted

  // fetch new jobs if needed:
  useEffect(() => {
    handleCalendarDatesChange(jobsDates, offset, setJobsDates)
    // turn off eslint: func above changes jobsDates, I don't want to fire it two times on each change
    //eslint-disable-next-line
  }, [offset])

  const renderCalendar = () => {
    const calendar = ["", "", "", "", ""].map((el, i) => {
      // calculate first-week-day date:
      let weekStartDay = startOfWeek(
        addDays(new Date(), (i + offset) * 7 - 7),
        {
          weekStartsOn: 1,
        }
      )
      // get week number:
      let weekNumber = currWeekNum + i + offset - 2

      // start creating row, first is always week number and moth number for first date in row
      // first cell for first and last row are clickable and cause to change offset
      const row = [
        <td
          className={`fixed-values-table ${
            i === 0 || i === 4 ? "clickable" : null
          }`}
          key={`calendar-weekdayCell-${weekNumber}`}
          onClick={() => {
            if (i === 0) setOffset(offset - 1)
            else if (i === 4) setOffset(offset + 1)
            else return
          }}
        >
          <>
            {i === 0 ? <MdKeyboardArrowUp /> : null}
            <br />
            <b>{getMonth(weekStartDay) + 1}</b>
            <br />
            {weekNumber}
            <br />
            {i === 4 ? <MdKeyboardArrowDown /> : null}
          </>
        </td>,
      ]

      // then create cell for each day:
      for (let j = 0; j <= 6; j++) {
        const cellDate = addDays(weekStartDay, j)

        // if this day is within any param I will highlight it:
        let isWithinAnyParam = false
        location.services.forEach((locSrv) => {
          if (locSrv.state === "aktywna")
            locSrv.params.forEach((prm) => {
              const prmEndDate = prm.hasEndDate
                ? new Date(prm.endDate)
                : // when no end date just add one year
                  addDays(cellDate, 365)
              // first check if endDate > startDate
              if (
                isBefore(new Date(prm.startDate), prmEndDate) &&
                isWithinInterval(cellDate, {
                  start: startOfDay(new Date(prm.startDate)),
                  end: prmEndDate,
                })
              )
                isWithinAnyParam = true
            })
        })

        // find jobs to show in cell:
        const cellJobs =
          jobs?.filter((jb) => {
            if (
              (jb.state === "usunięte" || jb.state === "archiwum") &&
              !revealHidden
            )
              return false
            else return isSameDay(new Date(jb.date), new Date(cellDate))
          }) || []

        // start creating content of cell, I'll add <td> at the end...
        const cell = [
          <Row
            className="justify-content-around"
            key={`calendar-cell-${cellDate}-div`}
            id={`calendarCell-${cellDate}`}
          >
            <span className={isWithinAnyParam ? "bg-secondaryLight" : null}>
              {format(cellDate, "dd")}.{getMonthShortcut(getMonth(cellDate))}
            </span>
            {location.state === "aktywna" && (
              <MdAddCircleOutline
                className="clickable"
                id={`addDayJob-${format(cellDate, "yyyy-MM-dd")}`}
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Dodawanie zadań",
                    body: (
                      <LocationJobsAdd
                        date={format(cellDate, "yyyy-MM-dd")}
                        period="jednorazowo"
                      />
                    ),
                    hideFooter: true,
                    xl: true,
                  })
                }
              />
            )}
          </Row>,
        ]

        // if cell has jobs (and not more then 2) - add buttons:
        if (cellJobs.length > 0 && cellJobs.length < 3) {
          cellJobs.forEach((job) => {
            // create services and eqp list:

            return cell.push(
              <span
                key={`calendar-job-button-${job._id}`}
                id={`calendar-job-button-${job._id}`}
              >
                {createButton(
                  job,
                  setModalData,
                  location,
                  configs.jobState,
                  user,
                  refresh,
                  configs,
                  cars
                )}

                {/* content of tooltip is created in calendarHelpers */}
                <ReactTooltip html={true} type="light" />
              </span>
            )
          })
          // when more then 2 jobs -> create 'multiple jobs' icon
        } else if (cellJobs.length > 2) {
          cell.push(
            <svg
              id="multipleJobsBtn"
              viewBox="-40 -40 80 80"
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
              fill="#404040"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  xl: true,
                  header: "Wybierz zadanie",
                  body: (
                    <ChooseJobModal
                      jobs={cellJobs}
                      setModalData={setModalData}
                      location={location}
                      jobState={configs.jobState}
                      user={user}
                      refresh={refresh}
                      configs={configs}
                      cars={cars}
                    />
                  ),
                })
              }
            >
              <path d="M -40 -30 L -25 -30 L -25 -40 L 40 -40 L 40 30 L 25 30 L 25 40 L -40 40 Z" />
            </svg>
          )
        }

        // add created cell content with proper <td> to row:
        row.push(
          <td
            key={`calendar-cell-${cellDate}`}
            id={`calendar-cell-${format(cellDate, "yyyy-MM-dd")}`}
            className={setCellClassName(cellDate)}
            style={{ width: "13.5%", height: "5.2rem" }}
          >
            {cell}
          </td>
        )
      }

      // push row into calendar array:
      return <tr key={`calendar-row-${weekNumber}`}>{row}</tr>
    })
    return calendar
  }

  //* when jobs are loading - return spinner
  if (areJobsLoading)
    return (
      <>
        <Spinner animation="border" />
        Pobieram dane
      </>
    )
  else
    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan="8">
              <Row className="justify-content-between">
                {location.state === "aktywna" && (
                  <Button
                    variant="warning"
                    className="ml-2"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        headerColor: "warning",
                        header: `Masowa Edycja Zadań(${location.name})`,
                        hideFooter: true,
                        body: <LocationJobsBatchEditFetch />,
                        draggable: false,
                      })
                    }
                  >
                    MEZ
                  </Button>
                )}

                <Button
                  variant="light"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      header: "Legenda kalendarza",
                      xl: true,
                      body: calendarLegendModalBody(configs.calendarIcons),
                    })
                  }
                >
                  legenda
                </Button>
                <Form.Check
                  name="revealHidden"
                  type="checkbox"
                  label="pokaż wszystkie"
                  id="revealHidden"
                  onChange={(e) => {
                    if (e.target.checked) setRevealHidden(true)
                    else setRevealHidden(false)
                  }}
                />
                {location.state === "aktywna" && (
                  <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={() =>
                      setModalData({
                        show: true,
                        type: "info",
                        header: "Dodawanie zadań",
                        body: <LocationJobsAdd />,
                        hideFooter: true,
                        xl: true,
                      })
                    }
                    id="addJobs"
                  >
                    Dodaj zadania
                  </Button>
                )}
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="fixed-values-table">#</td>
            <td className="fixed-values-table">pn</td>
            <td className="fixed-values-table">wt</td>
            <td className="fixed-values-table">śr</td>
            <td className="fixed-values-table">cz</td>
            <td className="fixed-values-table">pt</td>
            <td className="fixed-values-table">sb</td>
            <td className="fixed-values-table">nd</td>
          </tr>

          {renderCalendar()}
        </tbody>
      </Table>
    )
}

export default LocationsDetailsCalendar
