/*
input: (when filtered): props.rows.filtered and props.rows.all, (WHEN NOT FILTERED): only props.rows.filtered
if any filters applied (appart from dates) render rows.all, else render rows.filtered

*/

import React from "react"
import { Table, Container, Row, Col } from "react-bootstrap"

import { nF } from "../../utils/formats"

import TransactionsRender from "../../Transactions/transactionsRender"

const RLineSellReportRender = (props) => {
  // used in component to create stats table
  const renderEmployeesStats = () => {
    const transactionPerEmployee = props.transactions.filtered.reduce(
      (acc, curr) => {
        if (
          acc.findIndex((obj) => obj.employee === curr.user.fullName) === -1
        ) {
          return [...acc, { employee: curr.user.fullName, count: 1 }]
        } else {
          const ind = acc.findIndex(
            (obj) => obj.employee === curr.user.fullName
          )
          acc[ind].count += 1
          return acc
        }
      },
      []
    )
    return transactionPerEmployee.map((row) => (
      <tr key={`employees-stats-table-${row.employee}`}>
        <td>{row.employee}</td>
        <td>{row.count}</td>
      </tr>
    ))
  }

  // used in component to create stats table
  const renderBranchesStats = () => {
    const transactionPerBranch = props.transactions.filtered.reduce(
      (acc, curr) => {
        if (
          acc.findIndex(
            (obj) => obj.branch === curr.realizationDetails.branch
          ) === -1
        ) {
          return [...acc, { branch: curr.realizationDetails.branch, count: 1 }]
        } else {
          const ind = acc.findIndex(
            (obj) => obj.branch === curr.realizationDetails.branch
          )
          acc[ind].count += 1
          return acc
        }
      },
      []
    )
    return transactionPerBranch.map((row) => (
      <tr key={`employees-stats-table-${row.branch}`}>
        <td>{row.branch}</td>
        <td>{row.count}</td>
      </tr>
    ))
  }

  return (
    <Container className="mt-2">
      <h3>Podsumowanie</h3>
      <Table striped>
        <thead>
          <tr>
            <th>Produkty</th>
            <th>Ilość</th>
            <th>Przychód netto</th>
            <th>Przychód brutto</th>

            {props.isFiltered ? <th>Udział w całości</th> : null}
          </tr>
        </thead>
        <tbody>
          {props.isFiltered
            ? props.rows.all.map((row) => {
                /*
              When any filtered was applied I'm rendering rows from props.all, and for each row I'm searching in props.filtered for this good to show incomes, quantity and share
              above doesn't applie to name...
              */
                return (
                  <tr key={`main-table-row-${row._id}`}>
                    <td>
                      {row.names.length === 1 ? row.names : row.names.join("/")}
                    </td>
                    <td>
                      {props.rows.filtered.find((good) => good._id === row._id)
                        ?.quantity || 0}
                    </td>
                    <td>
                      {nF(
                        props.rows.filtered.find((good) => good._id === row._id)
                          ?.netIncome || 0
                      )}
                    </td>
                    <td>
                      {nF(
                        props.rows.filtered.find((good) => good._id === row._id)
                          ?.grossIncome || 0
                      )}
                    </td>
                    <td>
                      {Math.round(
                        ((props.rows.filtered.find(
                          (good) => good._id === row._id
                        )?.netIncome || 0) /
                          row.netIncome) *
                          100
                      )}
                      %
                    </td>
                  </tr>
                )
              })
            : // when none filteres applied I get only props.rows.filtered and I'm using them to create table:
              props.rows.filtered.map((row) => {
                return (
                  <tr key={`main-table-row-${row._id}`}>
                    <td>
                      {row.names.length === 1 ? row.names : row.names.join("/")}
                    </td>
                    <td>{row.quantity}</td>
                    <td>{nF(row.netIncome)}</td>
                    <td>{nF(row.grossIncome)}</td>
                  </tr>
                )
              })}
        </tbody>
        <tfoot>
          <tr>
            <td className="fixed-values-table" colSpan="2">
              Suma
            </td>
            <td>
              {nF(
                props.rows.filtered.reduce((acc, curr) => {
                  return (acc += curr.netIncome)
                }, 0)
              )}
            </td>
            <td>
              {nF(
                props.rows.filtered.reduce((acc, curr) => {
                  return (acc += curr.grossIncome)
                }, 0)
              )}
            </td>
            {/* If filtered I add one more column with share of total sell: */}
            {props.isFiltered ? (
              <td>
                {Math.round(
                  (props.rows.filtered.reduce((acc, curr) => {
                    return (acc += curr.netIncome)
                  }, 0) /
                    props.rows.all.reduce((acc, curr) => {
                      return (acc += curr.netIncome)
                    }, 0)) *
                    100
                )}
                %
              </td>
            ) : null}
          </tr>
        </tfoot>
      </Table>
      <h3>Dane szczegółowe</h3>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Oczekujących</td>
            <td className="fixed-values-table">Zaproformowanych</td>
            <td className="fixed-values-table">Zrealizowanych</td>
            <td className="fixed-values-table">Anulowanych</td>
            <td className="fixed-values-table">W sumie</td>
          </tr>
          <tr>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.state === "oczekująca") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.state === "zaproformowana") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.state === "zafakturowana") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.state === "anulowana") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>{props.transactions.filtered.length}</td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <tbody>
          <tr>
            <td className="fixed-values-table">Dla os. fiz.</td>
            <td className="fixed-values-table">Dla os. pr.</td>
            <td className="fixed-values-table">Clipper</td>
            <td className="fixed-values-table">Ecoloxx</td>
          </tr>
          <tr>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.customerType === "privat") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.customerType === "company") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.seller === "Clipper") return acc + 1
                else return acc
              }, 0)}
            </td>
            <td>
              {props.transactions.filtered.reduce((acc, curr) => {
                if (curr.seller === "Ecoloxx") return acc + 1
                else return acc
              }, 0)}
            </td>
          </tr>
        </tbody>
      </Table>
      <Row>
        <Col>
          <Table>
            <tbody>
              <tr>
                <td className="fixed-values-table">Użytkownik</td>
                <td className="fixed-values-table">Liczba transakcji</td>
              </tr>
              {renderEmployeesStats()}
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table>
            <tbody>
              <tr>
                <td className="fixed-values-table">Oddział</td>
                <td className="fixed-values-table">Liczba transakcji</td>
              </tr>
              {renderBranchesStats()}
            </tbody>
          </Table>
        </Col>
      </Row>
      <h3>Lista transakcji:</h3>
      <TransactionsRender
        transactions={props.transactions.filtered}
        embedded={true}
      />
    </Container>
  )
}
export default RLineSellReportRender
