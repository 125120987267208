import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Table, Button } from "react-bootstrap"

import UserContext from "../../contexts/userContext"

import { dateParse } from "../../utils/formats"
import { handleStateChange } from "./utils"

const ConsumptionsDetailsRender = (props) => {
  const user = useContext(UserContext)

  const canEdit =
    user.perm.consumptionsList.e ||
    (user.perm.consumption.e && user._id === props.consumption.user_id._id)
      ? true
      : false

  let stateCellClass = ""
  if (props.consumption.state === "zapisane") stateCellClass = "bg-success"
  if (props.consumption.state === "oczekujące") stateCellClass = "bg-warning"

  return (
    <Container fluid id="consumptionDetailsRenderContainer">
      <Container>
        <p style={{ color: "white" }}>{props.consumption._id}</p>
        <Table bordered>
          <tbody>
            <tr>
              <td className="bg-primaryLight">Data utworzenia</td>
              <td>{dateParse(props.consumption.createdAt)}</td>
              <td className="bg-primaryLight">Pracownik</td>
              <td>{props.consumption.user_id.fullName}</td>
              <td className="bg-primaryLight">Data ostatniej edycji</td>
              <td>{dateParse(props.consumption.updatedAt)}</td>
              <td className="bg-primaryLight">Stan</td>
              <td className={stateCellClass} id="consStateCell">
                {props.consumption.state}
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className="justify-content-between">
          <Link to="/consumptionsList">
            <Button>Wróć</Button>
          </Link>

          {/* CHANGE STATE button */}
          {user.perm.consumptionsList.d &&
          props.consumption.state === "oczekujące" ? (
            <Button
              variant="secondary"
              onClick={() =>
                handleStateChange(
                  props.consumption._id,
                  "zapisane",
                  props.setModalData,
                  () => window.location.reload()
                )
              }
              id="changeConsStateBtn-saved"
            >
              zapisane
            </Button>
          ) : null}
          {user.perm.consumptionsList.d &&
          props.consumption.state === "zapisane" ? (
            <Button
              variant="warning"
              onClick={() =>
                handleStateChange(
                  props.consumption._id,
                  "oczekujące",
                  props.setModalData,
                  () => window.location.reload()
                )
              }
              id="changeConsStateBtn-waiting"
            >
              oczekujące
            </Button>
          ) : null}

          {/* EDIT button */}
          {canEdit && props.consumption.state === "oczekujące" ? (
            <Link
              to={{
                pathname: `/consumptionEdit/${props.consumption._id}`,
              }}
            >
              <Button variant="outline-primary" id="editConsBtn">
                Edytuj
              </Button>
            </Link>
          ) : null}
        </Row>

        <Row>
          <Col>
            <h2>Podsumowanie</h2>
            <Table bordered>
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Jednostka</th>
                  <th>Zużycie</th>
                </tr>
              </thead>
              <tbody>
                {props.allGoodsSums.map((goodRow, i) => (
                  <tr key={`sums-row-${goodRow.name}`}>
                    <td>{goodRow.name}</td>
                    <td>{goodRow.unit}</td>
                    <td id={`mainGoodCell-${i}`}>{goodRow.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <h2>Dodatkowe informacje:</h2>
            <p>
              <b>Oddział:</b> {props.consumption.branch}
              <br />
              <b>Kierowca:</b> {props.consumption.driver.fullName}
            </p>
            <p className="keep-br" id="consumptionNotes">
              <b>Uwagi/notatki:</b>
              <br />
              {props.consumption.additionalInfo}
            </p>
          </Col>
        </Row>
      </Container>
      <h2>Zużycie głównych materiałów</h2>
      {/* checking if there are any rows in main goods consumptions: */}
      {props.consumption.mainGoodsConsumptions.length ? (
        <Table bordered>
          <thead>
            <tr>
              <th rowSpan="2">Data</th>
              {props.consumption.mainGoodsConsumptions[0].consumptions.map(
                (good) => (
                  <th key={`mainGoodsTab-header-${good.name}`}>{good.name}</th>
                )
              )}
            </tr>
            <tr>
              {props.consumption.mainGoodsConsumptions[0].consumptions.map(
                (good) => (
                  <th key={`mainGoodsTab-header-${good.name}-unit`}>
                    {good.unit}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody id="mainGoodsTableBody">
            {props.consumption.mainGoodsConsumptions.map((consumptionRow) => (
              <tr key={`mainGoodsTab-dateCell-${consumptionRow.date}`}>
                <td>{consumptionRow.date}</td>
                {consumptionRow.consumptions.map((goodConsumption) => (
                  <td
                    key={`mainGoodsTab-body-${consumptionRow.date}-${goodConsumption.name}`}
                  >
                    {/* preventing displaying zeros for better readbility */}
                    {goodConsumption.quantity === 0
                      ? ""
                      : goodConsumption.quantity}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Container>
  )
}
export default ConsumptionsDetailsRender
