/* 
TODO
- add month-changing-arrows
*/

import { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import MyModal from "../../utils/Modals"
import renderSpinner from "../../utils/renderSpinner"
import { InvStatsForm } from "./Form"
import { InvStatsRender } from "./Render"

const InvStatsFetch = () => {
  const [modalData, setModalData] = useState({ show: false })
  const [initFetch, setInitFetch] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await fetch("/invoices/stats", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        })
        if (res.status !== 200)
          throw new Error(
            `Błąd komunikacji z serwerem: ${res.status} - ${
              (await res.text()) || "nieokreślony błąd"
            }`
          )
        // do sth with res
        const resJSON = await res.json()
        setData(resJSON)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
        setModalData({
          show: true,
          type: "info",
          header: "błąd komunikacji z serwerem",
          headerColor: "danger",
          body: err.message,
        })
      }
    }
    if (!initFetch) {
      fetchData()
    }
  }, [formData, initFetch])

  const renderData = () => {
    if (isLoading) {
      return renderSpinner("pobieram dane...")
    } else if (initFetch) {
      return null
    } else {
      return <InvStatsRender data={data} />
    }
  }

  return (
    <Container fluid>
      <InvStatsForm
        setFormData={setFormData}
        setInitFetch={setInitFetch}
        isLoading={isLoading}
      />
      <hr />
      {renderData()}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}

export { InvStatsFetch }
