import React, { useState, useEffect, useContext } from "react"
import { format } from "date-fns"
import { useForm } from "react-hook-form"
import {
  Container,
  Row,
  Button,
  Form,
  Badge,
  Col,
  Alert,
} from "react-bootstrap"
import { FiRefreshCw } from "react-icons/fi"
import { Helmet } from "react-helmet"

import UserContext from "../contexts/userContext"
import MyModal from "../utils/Modals"
import renderSpinner from "../utils/renderSpinner"

import LocationsListRender from "./Render"
import { getUninvoicedSrvs } from "./utils"
import uninvoicedLocationsImg from "../../doc/pics/uninvoicedLocations.png"
import { createManualBtn } from "../utils/buttons"
import { useLocation } from "react-router-dom"

//TODO: refactor filters form and fetch
//(maybe use "show advanced search"?)

const LocationsListFetch = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [locations, setLocations] = useState([])
  const [locationsSelected, setLocationsSelected] = useState([])
  // I use counter to force refresh without changing form data (refresh() below):
  const [refreshCounter, setRefreshCounter] = useState(0)
  const refresh = () => {
    setModalData({ show: false })
    setRefreshCounter(refreshCounter + 1)
  }
  const [showUninvoiced, setShowUninvoiced] = useState(false)
  // get query string (used for searching locations by service used)
  //( srv_id and srvName)
  const { search } = useLocation()
  const [srvToUse, setSrvToUse] = useState(null)
  useEffect(() => {
    if (search) {
      const searchParsed = new URLSearchParams(search)
      setSrvToUse({
        _id: searchParsed.get("srv_id"),
        name: searchParsed.get("srvName"),
      })
      // fire fetch:
      setIsInitialRender(false)
      refresh()
    }
    // prevent eslint alert for no refresh in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: format(new Date(), "yyyy-MM-dd"),
      query: null,
      useNumber: false,
      number: null,
      branch: user.defaultBranch || user.allowedBranches[0],
      uninvoiced: false,
      locState: "wszystkie",
      noPermission: false,
    },
  })

  // clear dates on uncheck short term
  const watchShortOnly = watch("shortOnly")
  useEffect(() => {
    setValue("startDate", null)
    setValue("endDate", null)
  }, [watchShortOnly, setValue])

  // clear locations and selected locations on uninvoiced date change
  //( not 100% sure why I'm doing this, but it has low ux impact and will prevent problems with calculating invoice dates)
  const watchDate = watch("date")
  useEffect(() => {
    setLocationsSelected([])
    setLocations([])
  }, [watchDate])

  const fetchData = async (formData) => {
    try {
      setIsLoading(true)
      setIsInitialRender(false)
      const filters = {
        branches:
          formData.branch === "all" ? user.allowedBranches : [formData.branch],
        noPermission: formData.noPermission,
        initReg: formData.initReg,
        shortOnly: formData.shortOnly,
      }

      if (formData.query) filters.query = formData.query
      if (formData.number) filters.number = formData.number
      if (formData.useNumber) filters.useNumber = formData.useNumber
      if (formData.uninvoiced) filters.uninvoicedDate = formData.date
      if (formData.perPcsRefactor)
        filters.perPcsRefactor = formData.perPcsRefactor
      if (formData.locState !== "wszystkie")
        filters.locState = formData.locState
      if (srvToUse?._id) filters.srv_id = srvToUse._id
      if (formData.shortOnly) {
        filters.startDate = formData.startDate
        filters.endDate = formData.endDate
      }

      const res = await fetch(`/locations/getFiltered`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(filters),
      })
      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200)
        throw new Error(
          (await res.text()) || "nieokreślony błąd pobierania danych"
        )

      let locationsReady = await res.json()
      console.log(`locations fetched count: ${locationsReady.length}`)

      // add selected locations if not fetched:
      if (locationsSelected.length) {
        // first remove selected locations from fetched locations, as I want them to be at begginning of list:
        const locSelectedIds = locationsSelected.map((loc) => loc._id)
        locationsReady = locationsReady.filter(
          (loc) => !locSelectedIds.includes(loc._id)
        )

        // now add selected locations to the beginning of fetched locations:
        locationsReady = [...locationsSelected, ...locationsReady]
      }

      // if 'get uninvoiced on day XX' checked -> add uninvoiced params array to each service of each location
      if (formData.date)
        locationsReady = getUninvoicedSrvs(locationsReady, formData.date)

      setLocations(locationsReady)
      setIsLoading(false)
      setShowUninvoiced(formData.uninvoiced)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || `Błąd pobierania danych`,
      })
    }
  }
  useEffect(() => {
    if (!isInitialRender)
      // simply use form values:
      fetchData(getValues())
    // eslint-disable-next-line
  }, [user, refreshCounter])
  return (
    <Container fluid id="locationsListFetch">
      <Helmet>
        <title>Lokalizacje</title>
      </Helmet>
      {srvToUse?._id ? (
        // TODO: add button to clear query string
        <Alert variant="warning">
          Wyszukiwanie lokalizacji z usługą{" "}
          {srvToUse.name || "błąd programu: brak nazwy usługi"} (pokazywane są
          wszystkie lokalizacje, niezależnie czy wyszukiwana usługa jest
          zamknięta)
          <br />
          Aby przejść do zwykłego wyszukiwania{" "}
          <u>kliknij "Lokalizacje" w menu</u>
        </Alert>
      ) : null}
      <Form onSubmit={handleSubmit(fetchData)} className="mb-2">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Row>Stan lok.</Row>
            <Row>
              <Form.Control
                {...register("locState")}
                as="select"
                className=""
                autoComplete="chrome-off"
              >
                <option>wszystkie</option>
                <option>aktywna</option>
                <option>archiwum</option>
              </Form.Control>
            </Row>
          </Col>
          <Col xs="auto">
            <Row>Oddział:</Row>
            <Row>
              <Form.Control
                {...register("branch")}
                as="select"
                autoComplete="chrome-off"
              >
                <option value="all" key={`select-branch-all`}>
                  wszystkie
                </option>
                {user.allowedBranches.map((branch) => (
                  <option value={branch} key={`select-branch-${branch}`}>
                    {branch}
                  </option>
                ))}
              </Form.Control>
            </Row>
          </Col>
          <Col xs="auto">
            <Row>
              Wyszukiwarka
              <Form.Control
                {...register("query")}
                as="input"
                readOnly={watch("useNumber") === true ? true : false}
                className="mr-2 mt-1"
                autoComplete="chrome-off"
              />
            </Row>
          </Col>
          <Col xs="auto">
            <Row>
              <Form.Check
                {...register("useNumber")}
                type="checkbox"
                label="Numer"
                id="useNumber"
              />
            </Row>
            <Row>
              <Form.Control
                {...register("number")}
                as="input"
                readOnly={watch("useNumber") === true ? false : true}
                className="mr-2 mt-1 medium-number-input"
                autoComplete="chrome-off"
              />
            </Row>
          </Col>
          <Col xs="auto">
            <Form.Check
              {...register("uninvoiced")}
              type="checkbox"
              label="Niezafakturowane"
              id="uninvoiced"
              className="mt-3"
            />
            <Form.Check
              {...register("noPermission")}
              type="checkbox"
              label="Tylko bez zezwolenia"
              id="noPermission"
            />
            <Form.Check
              {...register("initReg")}
              type="checkbox"
              label="Tylko wstępne"
              id="initReg"
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              {...register("shortOnly")}
              type="checkbox"
              label="Tylko imprezy"
              id="shortOnly"
              className="mt-3"
            />
            <Form.Check
              {...register("perPcsRefactor")}
              type="checkbox"
              label="DO POPRAWY"
              id="shortOnly"
              className="mt-3"
            />
          </Col>
          <Col>
            {watch("uninvoiced") ? (
              <Row>
                <Col xs="auto">
                  {errors.date && (
                    <Badge pill variant="warning">
                      {errors.date.message}
                    </Badge>
                  )}
                  Niezafakturowane na dzień:
                  <Form.Control
                    {...register("date", { required: "Pole wymagane" })}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </Col>
              </Row>
            ) : null}
            {watch("shortOnly") ? (
              <Row>
                <Col>
                  Data OD
                  <Form.Control
                    {...register("startDate")}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </Col>
                <Col>
                  Data DO{" "}
                  <Form.Control
                    {...register("endDate")}
                    as="input"
                    type="date"
                    className=""
                    autoComplete="chrome-off"
                  />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs="auto">
                <Button type="submit" className="mt-2">
                  Pobierz
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  className="mx-2 mt-2"
                  onClick={() =>
                    setModalData({
                      show: true,
                      type: "info",
                      xl: true,
                      body: (
                        <>
                          <p>
                            <Button>
                              <FiRefreshCw />
                            </Button>{" "}
                            - odświeżanie danych z zachowaniem wartości filtrów
                            (oddział, wyszukiwarka itd.)
                          </p>
                          <p>
                            Program przeszukuje lokalizacje i pobiera wszystkie,
                            które:
                          </p>
                          <ul>
                            <li>
                              mają parametr z datą rozpoczęcia większą niż data
                              fakturowania, i:
                            </li>
                            <li>
                              nie mają ustawionego "zafakturowano do", lub:
                            </li>
                            <li>
                              mają zafakturowano do, które nie jest równe dacie
                              zakończenia, i dodatkowo data zafakturowano do
                              jest mniejsza niż data fakturowania
                            </li>
                          </ul>
                          <img src={uninvoicedLocationsImg} alt="" />
                        </>
                      ),
                    })
                  }
                >
                  ?
                </Button>
              </Col>

              <Col xs="auto">
                <Button className="ml-2 mt-2" onClick={refresh}>
                  <FiRefreshCw />
                </Button>
              </Col>
              <Col xs="auto">
                <Row className="mt-2">{createManualBtn("locationsSearch")}</Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <hr />
      {isInitialRender
        ? 'Wprowadź kryteria wyszukiwania i wciśnij "Pobierz"'
        : null}
      {isLoading && !isInitialRender ? (
        renderSpinner("pobieram dane...")
      ) : (
        <LocationsListRender
          locations={locations}
          showUninvoiced={showUninvoiced}
          showUninvoicedDate={showUninvoiced ? getValues("date") : null}
          setModalData={setModalData}
          refresh={refresh}
          locationsSelected={locationsSelected}
          setLocationsSelected={setLocationsSelected}
        />
      )}
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default LocationsListFetch
