import { format, isValid } from "date-fns"
import React, { useContext } from "react"
import { Container, Row, Button, Table, Col } from "react-bootstrap"

import HistoryTable from "../../../utils/HistoryTable"

import { CustomerCtx } from "../Fetch"

import CustomersNoteEditModal from "./CustomersNoteEditModal"

const CustomersNoteDetailsModal = (props) => {
  const { user, setModalData, customer, refresh } = useContext(CustomerCtx)

  const { note } = props

  const handleStateChange = async (newState) => {
    try {
      const res = await fetch(`/customers/editEntity`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          entityName: "notes",
          entity: { ...note, state: newState },
          dirtyFields: { state: true },
          historyLength: note.history.length,
          customer_id: customer._id,
        }),
      })

      if (res.status !== 200)
        throw new Error(
          `${res.status} - ${
            (await res.text()) || "nieokreślony błąd komunikacji z serwerem"
          }`
        )
      return refresh({
        headerColor: "bg-success",
        header: "Sukces",
        body: "Stan notatki zmieniony",
      })
    } catch (err) {
      console.log("err", err)
      setModalData({
        show: true,
        type: "alert",
        header: "Błąd",
        body: err.message || "nieokreślony błąd",
      })
    }
  }

  const renderDebtCollectionNoteDetails = () => {
    if (note.type !== "windykacyjna") return null
    return (
      <tr>
        <td className="fixed-values-table">Podtyp</td>
        <td>{note.subtype}</td>
        <td className="fixed-values-table">Data</td>
        <td>
          {isValid(new Date(note.eventDate))
            ? format(new Date(note.eventDate), "yyyy-MM-dd")
            : "brak"}
        </td>
      </tr>
    )
  }

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <Row>
            <Button
              className="ml-3"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Historia edycji kontaktu",
                  xl: true,
                  body: (
                    <HistoryTable
                      history={note.history}
                      goBack={() =>
                        setModalData({
                          show: true,
                          type: "info",
                          header: "Szczegóły kontaktu",
                          xl: true,
                          body: <CustomersNoteDetailsModal note={note} />,
                        })
                      }
                    />
                  ),
                })
              }
            >
              Historia
            </Button>
          </Row>
        </Col>
        <Col>
          {note.state === "archiwum" && user.perm.customers.e ? (
            <Button
              variant="warning"
              onClick={() => handleStateChange("aktywna")}
            >
              Przywróć
            </Button>
          ) : (
            <Button
              variant="warning"
              onClick={() => handleStateChange("archiwum")}
            >
              Archiwizuj
            </Button>
          )}
        </Col>

        {note.state === "aktywna" &&
        user.perm.customers.e &&
        customer.state === "aktywny" ? (
          <Col>
            <Row className="justify-content-end">
              <Button
                className="mr-3"
                variant="secondary"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    header: "Edycja kontaktu",
                    hideFooter: true,
                    body: <CustomersNoteEditModal note={note} />,
                  })
                }
              >
                Edytuj
              </Button>
            </Row>
          </Col>
        ) : null}
      </Row>
      <Table bordered>
        <tbody>
          <tr>
            <td className="fixed-values-table">Typ</td>

            <td>{note.type}</td>
            <td className="fixed-values-table">Ostatnia edycja</td>

            <td>{note.history?.[note.history.length - 1].user?.fullName}</td>
          </tr>
          {renderDebtCollectionNoteDetails()}
          <tr>
            <td className="fixed-values-table" colSpan="4">
              Treść
            </td>
          </tr>
          <tr>
            <td colSpan="4">{note.body}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}
export default CustomersNoteDetailsModal
