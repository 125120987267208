import React from "react"
import { Container, Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

const CarsTableForm = ({ filters, setFilters, setIsLoading, user }) => {
  const { register, handleSubmit } = useForm({ defaultValues: filters })
  return (
    <Container>
      <Form
        inline
        onSubmit={handleSubmit((formData) => {
          setFilters(formData)
          setIsLoading(true)
        })}
      >
        Oddział:{" "}
        <Form.Control
          {...register("branch")}
          as="select"
          className=""
          autoComplete="chrome-off"
        >
          <option key={`branchSelectOpt-all`} value={"all"}>
            Wszytkie
          </option>
          {user.allowedBranches.map((branch) => (
            <option key={`branchSelectOpt-${branch}`} value={branch}>
              {branch}
            </option>
          ))}
        </Form.Control>
        Marka/model/nazwa/nr rej./vin:
        <Form.Control
          {...register("query")}
          as="input"
          type="text"
          autoComplete="chrome-off"
        />
        Typ:
        <Form.Control
          {...register("type")}
          as="select"
          autoComplete="chrome-off"
        >
          <option value="all" key="typeSelect-all">
            wszystkie
          </option>
          <option value="ciężarowy" key="typeSelect-ciezarowy">
            ciężarowy
          </option>
          <option value="osobowy" key="typeSelect-osobowy">
            osobowy
          </option>
          <option value="inny" key="typeSelect-inny">
            inny
          </option>
        </Form.Control>
        <Button type="submit" className="ml-3">
          Szukaj
        </Button>
      </Form>
    </Container>
  )
}

/**
 *
 * @param {object} formData
 * @param {object} dirtyFields
 * @param {object} car
 * @param {boolean} isNew
 * @param {function} refresh
 * @param {function} setModalData
 * @returns
 */
//TODO add handling fetchErrors in parent component
//TODO add handling setIsLoading/setIsFetching to DISABLE parent comp buttons
const submit = async (
  formData,
  dirtyFields,
  car,
  isNew,
  refresh,
  setModalData
) => {
  //TODO prevRegNumbers - if there are only whitespaces change to null
  try {
    const data = { car: formData, dirtyFields: dirtyFields }
    if (!isNew) {
      data.car._id = car._id
      data.car.history = car.history
    }

    const res = await fetch(`/cars/${isNew ? "add" : "edit"}`, {
      method: isNew ? "POST" : "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
    if (res.status !== 200)
      throw new Error(
        `${res.status} - ${(await res.text()) || "błąd komunikacji z serwerem"}`
      )

    return refresh({
      headerColor: "bg-success",
      header: "Sukces",
      body: "Dane samochodu zmienione!",
    })
  } catch (err) {
    console.log(err)
    setModalData({
      show: true,
      type: "alert",
      body: `Błąd:${err.message || "nieokreślony błąd programu"}`,
    })
  }
}
export { CarsTableForm, submit }
