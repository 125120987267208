import React, { useContext } from "react"

import { Table, Row, Col, Button, Container } from "react-bootstrap"
import { dateParse } from "../../utils/formats"

import { EmployeesDetailsCtx } from "./Fetch"

import { NoteDetailsModal, archivedNotesModal } from "./modals"
import EmployeesDetailsEditNote from "./Edit/Note"

const EmployeesDetailsNotes = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)

  const renderNoteRow = (note) => {
    // when trying to render main note if there is no any:
    if (!note) return null
    else
      return (
        <tr
          className="clickable"
          onClick={() =>
            props.setModalData({
              show: true,
              type: "info",
              header: `Szczegóły notatki pracownika ${ctx.employee.fullName}`,
              xl: true,
              body: (
                <NoteDetailsModal
                  noteInd={ctx.employee.notes.findIndex(
                    (el) => el._id === note._id
                  )}
                />
              ),
            })
          }
          key={`note-row-${note._id}`}
        >
          <td>{note.type}</td>
          {/* show name of user who is in first entry of history: */}
          <td>{note.history[0]?.user.fullName}</td>
          <td>{dateParse(note.history[0]?.createdAt)}</td>
          <td>
            {note.body.length > 65
              ? `${note.body.slice(0, 64)}[...]`
              : note.body}
          </td>
        </tr>
      )
  }

  return (
    <Container fluid>
      <Table>
        <thead>
          <tr>
            <th>Pracownik: {ctx.employee.fullName}</th>
          </tr>
        </thead>
      </Table>
      <Table id="notesTable">
        <thead>
          <tr>
            <th colSpan="4">
              <Row>
                <Col xs={{ offset: 5, span: 2 }}>Notatki</Col>
                <Col xs={{ offset: 1 }}>
                  <Button
                    id="archivedNotes"
                    variant="info"
                    size="sm"
                    onClick={() =>
                      ctx.setModalData(
                        archivedNotesModal(
                          ctx.employee.notes.filter(
                            (note) => note.state === "archiwum"
                          ),
                          ctx.setModalData,
                          ctx.employee
                        )
                      )
                    }
                  >
                    Archiwalne
                  </Button>
                </Col>
                <Col xs={{ offset: 1 }}>
                  {ctx.user.type.board ||
                  (ctx.user.perm.employees.e &&
                    ctx.employee.superior.includes(ctx.user._id) &&
                    ctx.employee.state !== "archiwum") ? (
                    <Button
                      variant="secondary"
                      size="sm"
                      id="addNoteBtn"
                      onClick={() =>
                        ctx.setModalData({
                          show: true,
                          type: "info",
                          header: `Dodawanie notatki dla pracownika ${ctx.employee.fullName}`,
                          body: <EmployeesDetailsEditNote noteInd={-1} />,
                          hideFooter: true,
                        })
                      }
                    >
                      Dodaj
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </th>
          </tr>
          <tr>
            <th className="fixed-values-table">Rodzaj</th>
            <th className="fixed-values-table">Dodał</th>
            <th className="fixed-values-table">Data dodania</th>
            <th className="fixed-values-table">Treść</th>
          </tr>
        </thead>
        <tbody>
          {/* show main note in first row: */}
          {renderNoteRow(
            ctx.employee.notes.find((note) => note.type === "główna")
          )}
          {/* render all notes other then main and arch: */}
          {props.employee.notes.map((note) => {
            if (note.state !== "archiwum" && note.type !== "główna")
              return renderNoteRow(note)
            else return null
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default EmployeesDetailsNotes
