import { useMemo, Fragment, useCallback } from "react"
import { format } from "date-fns"
import { Button, Container, Form, Table } from "react-bootstrap"
import { useFilters, useTable } from "react-table"
import { getCustomerName } from "../../utils/getCustomerName"
import JobDetailsModal from "../../_shared/jobs/JobDetailsModal"

const EqpChangeReportTable = ({
  jobs,
  setModalData,
  user,
  cars,
  configs,
  drivers,
}) => {
  const srvTypeFilterFn = useCallback((rows, id, filterVal) => {
    // if no filterVal - return all rows
    if (!filterVal) return rows
    return rows.filter((row) => {
      // if any job service is of type filterVal, return true

      return row.original.services.some(
        (jobSrv) => jobSrv.srvType === filterVal
      )
    })
  }, [])

  const data = useMemo(() => jobs, [jobs])

  const columns = useMemo(
    () => [
      {
        Header: "data",
        accessor: (job) => format(new Date(job.date), "yyyy-MM-dd"),
      },
      {
        Header: "kierowca",
        accessor: (job) =>
          job.driver.fullName ||
          `${job.driver.firstName} ${job.driver.lastName}`,
        id: "driver",
      },
      {
        Header: "klient",
        accessor: (job) => getCustomerName(job.location.customer),
        id: "customer",
      },
      {
        Header: "lokalizacja",
        accessor: (job) => (
          <>
            {job.location.name}
            <br />
            {job.location.street} {job.location.city}
          </>
        ),
        id: "location",
      },

      {
        Header: "typ",
        accessor: "type",
      },
      {
        Header: "sprzęt",
        accessor: (job) => renderSrvsCell(job),
        id: "eqp",
        filter: srvTypeFilterFn,
      },
    ],
    [srvTypeFilterFn]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable({ columns, data }, useFilters)

  return (
    <Container fluid>
      <EqpChangeReportTableFilters setFilter={setFilter} drivers={drivers} />
      <Table {...getTableProps()}>
        <thead style={{ whiteSpace: "nowrap" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Szczegóły zadania",
                    body: (
                      <JobDetailsModal
                        job={row.original}
                        location={row.original.location}
                        setModalData={setModalData}
                        user={user}
                        configs={configs}
                        cars={cars}
                        parent="eqpChangeReport"
                        allowConfirmationPrint={false}
                        hideHistoryBtn={true}
                      />
                    ),
                  })
                }
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

const EqpChangeReportTableFilters = ({ setFilter, drivers }) => {
  const typeFilter = (e) => {
    if (e.target.value === "wszystkie") setFilter("type", null)
    else setFilter("type", e.target.value)
  }
  const srvFilter = (e) => {
    if (e.target.value === "wszystkie") setFilter("eqp", null)
    else setFilter("eqp", e.target.value)
  }
  const driverFilter = (e) => {
    if (e.target.value === "wszyscy") setFilter("driver", null)
    else setFilter("driver", e.target.value)
  }

  const resetFilters = () => {
    document.getElementById("jobTypeFilter").value = "wszystkie"
    document.getElementById("srvTypeFilter").value = "wszystkie"
    document.getElementById("driverFilter").value = "wszyscy"
    setFilter("type", null)
    setFilter("eqp", null)
    setFilter("driver", null)
  }
  return (
    <Form inline className="ml-5">
      typ zadania:{" "}
      <Form.Control
        as="select"
        className="mr-2"
        id="jobTypeFilter"
        autoComplete="chrome-off"
        onChange={typeFilter}
      >
        <option>wszystkie</option>
        <option>dostarczenie</option>
        <option>zabranie</option>
      </Form.Control>
      rodzaj usługi:{" "}
      <Form.Control
        as="select"
        className=""
        id="srvTypeFilter"
        autoComplete="chrome-off"
        onChange={srvFilter}
      >
        <option>wszystkie</option>
        <option value="shortTerm">krótki termin</option>
        <option value="longTerm">długi termin</option>
      </Form.Control>
      kierowca:{" "}
      <Form.Control
        as="select"
        className=""
        id="driverFilter"
        autoComplete="chrome-off"
        onChange={driverFilter}
      >
        <option>wszyscy</option>
        {drivers.map((driver) => (
          <option key={driver._id} value={driver.fullName}>
            {driver.fullName}
          </option>
        ))}
      </Form.Control>
      <Button
        variant="warning"
        size="sm"
        className="ml-2"
        onClick={resetFilters}
      >
        X
      </Button>
      <span className="bg-secondary ml-auto">
        sprzęt dot. <b>imprez</b> jest oznaczony takim tłem
      </span>
    </Form>
  )
}

//# copy-paste from short term planning with minor changes
const renderSrvsCell = (job) => {
  const srvsCell = []
  if (!job.services?.length) return `BŁĄD - brak usług dla zadania ${job._id}`
  job.services.forEach((srv) => {
    // get srv name from location:
    const locSrv = job.location.services.find(
      (locSrvEl) => locSrvEl._id === srv.locationServiceRef
    )

    if (!locSrv)
      return srvsCell.push(
        `BŁĄD - brak danych usługi zadania w danych lokalizacji (zadanie ${job._id})`
      )

    srvsCell.push(
      <Fragment key={`srvLine-${srv._id}`}>
        {
          // don't show srv name if there is only one:
          job.services.length === 1 ? null : (
            <>
              <b>
                {locSrv.locSrvNo || "?"}. {locSrv.name}
              </b>
              <br />
            </>
          )
        }
        {renderEqp(srv, locSrv, job)}
      </Fragment>
    )
  })

  return srvsCell
}

const renderEqp = (srv, locSrvData, job) => {
  const eqpArr = []

  // for short term services add secondary background
  const getClassName = () => {
    if (
      locSrvData.chargeType === "za dzień" ||
      locSrvData.chargeType === "za miesiąc" ||
      locSrvData.chargeType === "za serwis"
    )
      return ""
    else if (
      locSrvData.chargeType === "krótki termin" ||
      locSrvData.chargeType === "ryczałt impreza"
    )
      return "bg-secondary"
    else
      throw new Error(
        `Nieznane rodzaj naliczenia (${locSrvData.chargeType}) dla usługi ${srv.serviceRef}, zadanie ${job._id}`
      )
  }

  if (!srv.eqp?.length && !srv.subSrv?.length)
    return "BŁĄD - brak sprzętu i usług podrzędnych"

  //# add eqp rows:
  if (srv.eqp?.length)
    srv.eqp.forEach((eqp) => {
      let eqpStr = `${eqp.qty} x ${eqp.ref.type}`
      const eqpProps = ["subtype", "category", "char", "color"]
      eqpProps.forEach((prop) => {
        if (
          eqp.ref[prop] &&
          eqp.ref[prop] !== "bez znaczenia" &&
          eqp.ref[prop] !== "wybierz" &&
          eqp.ref[prop] !== "undefined"
        )
          eqpStr = `${eqpStr} ${eqp.ref[prop]}`
      })

      if (eqp.ref.mods?.length)
        eqpStr = `${eqpStr} (${eqp.ref.mods.join(", ")})`

      eqpArr.push(
        <span
          className={getClassName()}
          key={`srvEqpLine-${srv._id}-${eqp._id}`}
        >
          {eqpStr}
          <br />
        </span>
      )
    })

  return eqpArr
}
export { EqpChangeReportTable }
