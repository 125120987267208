import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"

import { Container, Row, Button, Form, Table } from "react-bootstrap"

import { EmployeesDetailsCtx } from "../Fetch"

import { NoteDetailsModal } from "../modals"

import { noteSubmit } from "./submit"
import { deleteNote } from "./submit"

const EmployeesDetailsEditNote = (props) => {
  const ctx = useContext(EmployeesDetailsCtx)
  const [mainNoteError, setMainNoteError] = useState(false)
  const note =
    props.noteInd !== -1
      ? ctx.employee.notes[props.noteInd]
      : { type: "zwykła", body: null, history: [] }

  const onSubmit = async (data) => {
    // can't archiwe main note:
    if (data.type === "główna" && data.state === "archiwum")
      return setMainNoteError([
        "Nie nie nie! Nie możesz archiwizować głównej notatki, no co Ty? Zmień typ na 'zwykła', zapisz i potem archiwizuj.",
      ])
    //validate if only one "główna" note:
    if (
      data.type === "główna" &&
      // find notes that are main and index not equal noteInd:
      ctx.employee.notes.findIndex(
        (el, i) => el.type === "główna" && i !== props.noteInd
      ) !== -1
    )
      return setMainNoteError([
        "Nie można dodać więcej niż jednej notatki głównej. ",
        "Zmień typ, żeby zapisać, ewentualnie zmień obecną główną na inny typ, żeby zapisać tą jako główną. ",
        "Dziękuję za uwagę ;)",
      ])
    await noteSubmit(
      props.noteInd,
      { ...data, history: note.history },
      ctx.employee,
      dirtyFields,
      ctx.setModalData,
      ctx.refresh
    )
  }

  const { handleSubmit, register, formState } = useForm({
    //   I would prefere tenery and setting default values to null, but react-hook-form seems to prefere undefined when there should be no default values (null causes error)
    defaultValues: note,
  })
  const { dirtyFields } = formState

  if (props.noteInd !== -1) {
    register("state", note.state)
    register("_id", note._id)
    register("files", note.files)
  }

  return (
    <Container>
      <Row className="justify-content-between my-1">
        {props.noteInd === -1 ? null : (
          <Button
            onClick={() => {
              if (!note) return ctx.setModalData({ show: false })
              else
                return ctx.setModalData({
                  show: true,
                  type: "info",
                  body: <NoteDetailsModal noteInd={props.noteInd} />,
                  xl: true,
                })
            }}
          >
            Wróć
          </Button>
        )}
        <Button
          variant="danger"
          className={props.noteInd === -1 ? "ml-auto" : null} // pushing button to left when no "wróć" button (=== when adding new note)
          onClick={() => {
            if (!note) return ctx.setModalData({ show: false })
            else
              return ctx.setModalData({
                show: true,
                type: "info",
                body: <NoteDetailsModal noteInd={props.noteInd} />,
                xl: true,
              })
          }}
        >
          Anuluj
        </Button>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Table>
          <tbody>
            <tr>
              <td className="fixed-values-table">Rodzaj</td>
            </tr>
            <tr>
              <td>
                <Form.Control as="select" {...register('type')} id="noteType">
                  <option value="zwykła" id="noteOptNormal">
                    zwykła
                  </option>
                  <option value="główna" id="noteOptMain">
                    główna
                  </option>
                  {ctx.employee.superior.includes(ctx.user._id) ||
                  ctx.user.type.board ? (
                    <option value="kierownicza" id="noteOptSuperior">
                      kierownicza
                    </option>
                  ) : null}
                  {ctx.user.type.board ? (
                    <option value="zarząd" id="noteOptBoard">
                      zarząd
                    </option>
                  ) : null}
                </Form.Control>
              </td>
            </tr>
            <tr>
              <td className="fixed-values-table" colSpan="3">
                Treść
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <Form.Control {...register('body')} id="noteBody" as="textarea" />
              </td>
            </tr>
          </tbody>
        </Table>
        <Row className="justify-content-between">
          {note && note.state === "aktywna" ? (
            <Button
              id="archiveNote"
              variant="warning"
              onClick={() => {
                dirtyFields.state = true
                onSubmit({ ...note, state: "archiwum" })
              }}
            >
              Archiwum
            </Button>
          ) : null}

          {ctx.user.perm.employees.d && note ? (
            <Button
              variant="danger"
              onClick={() =>
                ctx.setModalData({
                  show: true,
                  type: "delete",
                  handleDelete: () =>
                    deleteNote(
                      ctx.employee._id,
                      props.noteInd,
                      ctx.setModalData
                    ),
                })
              }
            >
              Usuń
            </Button>
          ) : null}
          <Button
            variant="secondary"
            type="submit"
            id="submitNote"
            className={!note ? "ml-auto" : null} // pushing button to right when no other buttons
          >
            Zapisz
          </Button>
        </Row>
      </Form>
      {mainNoteError && <p className="bg-warningLight my-2">{mainNoteError}</p>}
    </Container>
  );
}
export default EmployeesDetailsEditNote
