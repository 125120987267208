import { differenceInCalendarDays, format, isValid, max, min } from "date-fns"
import { DropdownButton, Dropdown } from "react-bootstrap"

import ChangePriceModal from "../utils/ChangePriceModal"
import LocationsDetailsEqpFetch from "../servicesModal/Eqp/LocationsDetailsEqpFetch"
import { LocationDetailsSubSrvEditModal } from "../servicesModal/SubSrvEditModal"
import { LocationDetailsEditSrvQtyModal } from "../servicesModal/EditSrvQtyModal"

const renderSrvDetails = (srv) => {
  const startDate = min(srv.params.map((prm) => new Date(prm.startDate)))

  // get oldest param (by startDate) and get it's endDate or null
  const oldestPrm = srv.params.reduce(
    (oldestPrm, srvPrm) => {
      if (
        differenceInCalendarDays(
          new Date(oldestPrm.startDate),
          new Date(srvPrm.startDate)
        ) > 1
      )
        return oldestPrm
      else return srvPrm
    },
    { endDate: null }
  )

  // current param must have start date earlier or equal today and not have end date, or end date after today
  const currPrm = srv.params.find(
    (srvPrm) =>
      differenceInCalendarDays(new Date(), new Date(srvPrm.startDate)) >= 0 &&
      (!srvPrm.endDate ||
        differenceInCalendarDays(new Date(), new Date(srvPrm.endDate)) <= 0)
  )

  const invoicedParams = srv.params.filter(
    (srvPrm) => srvPrm.invoicedTo && isValid(new Date(srvPrm.invoicedTo))
  )

  const invoicedTo = invoicedParams.length
    ? max(invoicedParams.map((invPrm) => new Date(invPrm.invoicedTo)))
    : null

  const renderPlannedQtys = () => {
    return (
      srv.params
        .filter(
          (srvPrm) =>
            differenceInCalendarDays(new Date(), new Date(srvPrm.startDate)) < 0
        )
        .map(
          (plannedPrm) =>
            `(${plannedPrm.qty} od ${format(
              new Date(plannedPrm.startDate),
              "yyyy-MM-dd"
            )})`
        ) || null
    )
  }

  const renderQty = () => {
    if (!srv.mustHaveEqp) return <td></td>
    else
      return (
        <td>
          Liczba: {currPrm?.qty || oldestPrm.qty} {renderPlannedQtys()}
        </td>
      )
  }

  return (
    <tr>
      <td>od {format(startDate, "yyyy-MM-dd") || "BŁĄD - zgłoś!"}</td>
      <td>
        do{" "}
        {oldestPrm.endDate
          ? format(new Date(oldestPrm.endDate), "yyyy-MM-dd")
          : "brak"}
      </td>
      {renderQty()}

      <td id="invoicedToCell">
        Zafakturowana do:{" "}
        {invoicedTo ? format(invoicedTo, "yyyy-MM-dd") : "brak"}
      </td>
    </tr>
  )
}

const EditDropdown = ({
  srv,
  setModalData,
  refresh,
  location,
  servicesRefs,
  user,
}) => {
  return (
    <DropdownButton
      title="Edycja"
      id="srvEditDropdown"

      //TODO move all options (or whole dropdown?) to srvTableEditDropdownUtils.js
    >
      <Dropdown.Item
        id="editQty"
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            header: "Zmiana ",
            hideFooter: true,
            body: (
              <LocationDetailsEditSrvQtyModal
                srv={srv}
                location={location}
                refresh={refresh}
                setModalData={setModalData}
                user={user}
              />
            ),
          })
        }
      >
        liczba
      </Dropdown.Item>

      {/* only for services with customer equipment */}
      {srv.custEqp ? (
        <Dropdown.Item
          onClick={() =>
            setModalData({
              show: true,
              type: "info",
              header: "Zmiana sprzętu klienta",
              xl: true,
              body: <LocationsDetailsEqpFetch srv={srv} isDelivery={false} />,
            })
          }
        >
          sprzęt
        </Dropdown.Item>
      ) : null}
      <Dropdown.Item
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            header: "Zmiana ceny usługi",
            hideFooter: true,
            body: (
              <ChangePriceModal
                setModalData={setModalData}
                srv={srv}
                location={location}
                refresh={refresh}
              />
            ),
          })
        }
        id="srvPriceChange"
      >
        cena
      </Dropdown.Item>
      {/* only for services that can have sub-services */}
      {/* but also check fresh services data */}
      {renderSubSrvBtn(setModalData, srv, refresh, location, servicesRefs)}
    </DropdownButton>
  )
}

const renderSubSrvBtn = (setModalData, srv, refresh, location, services) => {
  let shouldShow = false
  let shouldUpdateCanHaveSubSrv = false // when location.service has old param data

  // check if button should be shown
  if (srv.canHaveSubSrv) shouldShow = true
  // search for service ref and check actual param value, if true show btn and send info that it should be updated
  else {
    const srvRef = services.find((service) => service._id === srv.serviceRef)
    if (srvRef?.canHaveSubSrv) {
      shouldShow = true
      shouldUpdateCanHaveSubSrv = true
    } else shouldShow = false
  }

  if (shouldShow)
    return (
      <Dropdown.Item
        onClick={() =>
          setModalData({
            show: true,
            type: "info",
            header: "Zmiana usług podrzędnych",
            xl: true,
            body: (
              <LocationDetailsSubSrvEditModal
                srv={srv}
                setModalData={setModalData}
                refresh={refresh}
                location={location}
                updateCanHaveSubSrv={shouldUpdateCanHaveSubSrv}
              />
            ),
          })
        }
      >
        usługi podrzędne
      </Dropdown.Item>
    )
  else return null
}

export { renderSrvDetails, EditDropdown }
