import React from "react"
import _ from "lodash"
import { Container, Row, Col, Button, Form } from "react-bootstrap"

const ServicesSelect = ({ services, append, setShowSrvSelect }) => {
  const handleSrvSelect = () => {
    const srvSelected = services.find(
      (srv) => srv._id === document.getElementById("servicesSelect").value
    )

    append({
      productInvoicePrice:
        srvSelected.defaultNetPrice * (1 + srvSelected.defaultVat / 100) ||
        null,
      productInvoicePriceNet: srvSelected.defaultNetPrice || null,
      productQuantity: null,
      productVat: srvSelected.defaultVat || null,
      productName: srvSelected.defaultInvLine || null,
      productCode: srvSelected.name,
      productId: srvSelected._id,
      productInvoiceAdditional: "",
      productInvoicePriceBaseCurrency: srvSelected.defaultVat + 1 || null,
      productInvoicePriceNetBaseCurrency: "1",
      productWeight: 0,
      remarksToProduct: "",
      source: "Linia faktury dodana ręcznie przy edycji",
      ref: { ...srvSelected },
    })
    setShowSrvSelect(false)
  }
  return (
    <Container style={{ border: "1px solid black" }} className="my-2">
      Wybierz usługę:
      <Form.Control
        inline
        name="servicesSelect"
        as="select"
        autoComplete="chrome-off"
        id="servicesSelect"
      >
        {services.map((srv) => (
          <option key={`servicesSelect.option-${srv._id}`} value={srv._id}>
            {srv.type}: {srv.name} - {srv.chargeType}
          </option>
        ))}
      </Form.Control>
      <Row className="justify-content-around">
        <Button variant="warning" onClick={() => setShowSrvSelect(false)}>
          Anuluj
        </Button>
        <Button variant="secondary" onClick={handleSrvSelect}>
          Potwierdź
        </Button>
      </Row>
    </Container>
  )
}
const RowRemoveConfirm = ({ remove, index, setShowRowRemoveConfirm }) => {
  return (
    <Container className="bg-warning">
      <Row>
        <Col>
          <p>Usunąć wiersz faktury?</p>
        </Col>
        <Col>
          <Row className="justify-content-between">
            <Button onClick={() => setShowRowRemoveConfirm(false)}>Nie</Button>
            <Button
              variant="danger"
              onClick={() => {
                remove(index)
                setShowRowRemoveConfirm(false)
              }}
            >
              Tak
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

/**
 * Function recalculates values and prepares data for SAGE Symfonia
 * @param {Object} invoice invoice object to recalculate
 */
const prepareData = (invoice) => {
  // calculate sums for InvoiceDetails.payments:
  const netSum = invoice.InvoiceDetails.productsResults.reduce((a, r) => {
    return _.round(a + r.productInvoicePriceNet * r.productQuantity, 2)
  }, 0)
  invoice.InvoiceDetails.payments.InvoiceBaseCurrency.InvoiceProductsCost =
    netSum
  invoice.InvoiceDetails.payments.InvoiceCurrency.InvoiceProductsCost = netSum

  // set payment registry:
  invoice.InvoiceDetails.payments.InvoicePaymentRegistry =
    invoice.InvoiceDetails.payments.InvoicePaymentType === "Gotówka"
      ? "KASA"
      : "BANK"

  // remove service refs, so creating history entry on API won't save all service details:
  invoice.InvoiceDetails.productsResults.forEach(
    (prod) => (prod.ref = prod.ref._id)
  )
}

/**
 * returns first active address with choosen type
 * @param {String} addressType
 * @param {Array} addresses
 */
const getCustomerAddress = (addressType, addresses) => {
  return addresses.filter((address) => {
    if (address.state === "aktywny" && addressType === address.type) return true
    else return false
  })[0]
}
export { ServicesSelect, RowRemoveConfirm, prepareData, getCustomerAddress }
