import React from "react"

import { Table, Container } from "react-bootstrap"

import JobDetailsModal from "../../../_shared/jobs/JobDetailsModal"

const RoutesPlanningJobSelectModal = (props) => {
  const { jobs, user, setModalData, refresh, configs } = props
  return (
    <Container>
      <Table>
        <tbody>
          <tr className="fixed-values-table">
            <td>Klient</td>
            <td>Lokalizacja</td>
            <td>Typ</td>
            <td>Kierowca</td>
          </tr>
          {jobs.map((job) => (
            <tr
              key={`choose-job-row-${job._id}`}
              className="clickable"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Edycja zadania",
                  hideFooter: true,
                  body: (
                    <JobDetailsModal
                      user={user}
                      job={job}
                      setModalData={setModalData}
                      refresh={refresh}
                      configs={configs}
                      location={job.location}
                      parent="Logistics"
                    />
                  ),
                })
              }
            >
              <td>{job.location.customer.shortName}</td>
              <td>{job.location.name}</td>
              <td>{job.type}</td>
              <td>{job.hasDriver ? job.driver.fullName : "BRAK"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default RoutesPlanningJobSelectModal
