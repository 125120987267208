import React from "react"
import { Container, Table } from "react-bootstrap"
import { format, differenceInCalendarDays } from "date-fns"
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs"

import TodosDetailsRender from "../details/render"
import { renderPropositionsTable } from "./utils"

const TodosListRender = ({
  todos,
  propositions,
  setModalData,
  refresh,
  setSortBy,
  sortBy,
  user,
  board,
}) => {
  const changeSorting = (fieldName) => {
    // when clicked field that was already sorted -> change order, else set new sorting field
    if (fieldName === sortBy.field)
      return setSortBy({ field: fieldName, order: -sortBy.order })
    else return setSortBy({ field: fieldName, order: -1 })
  }
  return (
    <Container fluid>
      {renderPropositionsTable(propositions, setModalData, refresh, user)}
      <Table>
        <thead>
          <tr>
            <th>Zadanie</th>
            <th className="clickable" onClick={() => changeSorting("priority")}>
              {sortBy.field === "priority" && sortBy.order > 0 ? (
                <BsFillCaretDownFill />
              ) : null}
              {sortBy.field === "priority" && sortBy.order < 0 ? (
                <BsFillCaretUpFill />
              ) : null}{" "}
              Priorytet
            </th>
            <th>Tagi</th>
            <th className="clickable" onClick={() => changeSorting("deadline")}>
              {sortBy.field === "deadline" && sortBy.order > 0 ? (
                <BsFillCaretDownFill />
              ) : null}
              {sortBy.field === "deadline" && sortBy.order < 0 ? (
                <BsFillCaretUpFill />
              ) : null}{" "}
              Data
            </th>
            <th>Autor</th>
            <th>Przypisany</th>
            <th className="clickable" onClick={() => changeSorting("state")}>
              {sortBy.field === "state" && sortBy.order > 0 ? (
                <BsFillCaretDownFill />
              ) : null}
              {sortBy.field === "state" && sortBy.order < 0 ? (
                <BsFillCaretUpFill />
              ) : null}{" "}
              Stan
            </th>
          </tr>
        </thead>
        <tbody>
          {todos.map((todo) => {
            return (
              <tr
                key={`todoRow-${todo._id}`}
                className="clickable"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    header: "Szczegóły zadania",
                    body: (
                      <TodosDetailsRender
                        todo={todo}
                        isModal={true}
                        setModalData={setModalData}
                        refresh={refresh}
                        user={user}
                        board={board}
                      />
                    ),
                  })
                }
              >
                <td>{todo.name}</td>
                <td>{todo.priority}</td>
                <td>{todo.tags.join(", ")}</td>
                <td>{format(new Date(todo.deadline), "yyyy-MM-dd")}</td>
                <td>{todo.creator?.fullName || "BŁĄD - zgłoś!"}</td>
                <td>{todo.employee?.fullName || "BŁĄD - zgłoś!"}</td>
                {/* for overdated todos add "!!!" prefix when in not-done state */}
                <td>
                  {differenceInCalendarDays(
                    new Date(todo.deadline),
                    new Date()
                  ) < 0 &&
                  [
                    "weryfikacja",
                    "zlecone",
                    "w realizacji",
                    "do sprawdzenia",
                  ].find((el) => el === todo.state) ? (
                    <b>!!!</b>
                  ) : null}
                  {todo.state}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}
export default TodosListRender
