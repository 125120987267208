import _ from "lodash"

const sumIncome = (reportData) => {
  const sum = []

  const {
    incomeShortOther,
    incomeShortToilets,
    incomeLongOther,
    incomeLongToilets,
    incomeSell,
    incomeCustomersToilets,
    incomeUnknown,
  } = reportData

  for (let month = 0; month <= 11; month++) {
    let monthSum =
      incomeShortOther[month] +
      incomeShortToilets[month] +
      incomeLongOther[month] +
      incomeLongToilets[month] +
      incomeSell[month] +
      incomeCustomersToilets[month] +
      incomeUnknown[month]

    sum.push(monthSum)
  }
  sum.push(sum.reduce((a, b) => a + b))
  return sum.map((el, i) => (
    <td key={`incomeSumRow-${i}`}>{_.round(Number.parseFloat(el), 2)}</td>
  ))
}

/* 
 incomeShortOther,
      incomeShortToilets,
      incomeLongOther,
      incomeLongToilets,
      incomeSell,
      incomeCustomersToilets,
      incomeUnknown,
*/

export { sumIncome }
