import React, { useEffect, useState, useContext } from "react"

import { Container, Modal } from "react-bootstrap"
import { Helmet } from "react-helmet"

import MyModal from "../../utils/Modals"

import UserContext from "../../contexts/userContext"

import EmployeesListForm from "./Form"
import EmployeesListRender from "./Render"

const EmployeesListFetch = () => {
  const user = useContext(UserContext)
  // const [formConfigsLoading, setFormConfigsLoading] = useState(true)
  const [formConfigs, setFormConfigs] = useState({
    query: "state=aktywny&company=all&branch=all",
    tablePage: 0,
  })
  const [isLoading, setisLoading] = useState(true)
  const [modalData, setModalData] = useState({ show: false })
  const [employees, setEmployees] = useState(null)

  useEffect(() => {
    const fetchData = async (params) => {
      try {
        const res = await fetch(`/employees/getFiltered?${formConfigs.query}`)
        if (res.status === 403)
          return setModalData({
            show: true,
            type: "info",
            body: "Brak uprawnień",
          })
        if (res.status !== 200) throw res
        const resJSON = await res.json()
        setEmployees(resJSON)

        setisLoading(false)
      } catch (err) {
        console.log(err)
        setModalData({
          show: true,
          type: "alert",
          body: "Błąd pobierania danych",
        })
      }
    }
    fetchData()
  }, [formConfigs])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setisLoading(true)

      const query = `state=${e.target.state.value}&company=${e.target.company.value}&branch=${e.target.branch.value}&search=${e.target.search.value}`
      setFormConfigs({ query: query, page: 0 })

      const res = await fetch(`/employees/getFiltered?${query}`)

      if (res.status === 403)
        return setModalData({
          show: true,
          type: "info",
          body: "Brak uprawnień",
        })
      if (res.status !== 200) throw res

      const resJSON = await res.json()
      setEmployees(resJSON)

      setisLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: "Błąd pobierania danych",
      })
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Pracownicy</title>
      </Helmet>
      {/* {!formConfigsLoading ? null : ( */}
      <EmployeesListForm user={user} handleSubmit={handleSubmit} />
      {/* )} */}
      {isLoading ? (
        <p>Pobieram dane...</p>
      ) : (
        <EmployeesListRender employees={employees} />
      )}

      <Modal
        show={modalData.show}
        onHide={() => setModalData({ show: false })}
        animation={false}
      >
        <MyModal modalData={modalData} setModalData={setModalData} />
      </Modal>
    </Container>
  )
}
export default EmployeesListFetch
