import React, { useState, useContext, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Badge,
  Spinner,
} from "react-bootstrap"
import { useForm } from "react-hook-form"
import {
  subDays,
  endOfMonth,
  startOfMonth,
  format,
  differenceInCalendarDays,
} from "date-fns"
import { useReactToPrint } from "react-to-print"
import { BsSearch } from "react-icons/bs"
import { MdPrint } from "react-icons/md"

import UserContext from "../../contexts/userContext"

import MyModal from "../../utils/Modals"
import CustomersFetch from "../../Customers/Fetch"
import JobsPrintRender from "./Render"

const JobsPrintFetch = () => {
  const user = useContext(UserContext)
  const todayMinusTen = subDays(new Date(), 10)

  const [modalData, setModalData] = useState({ show: false })
  const [customer, setCustomer] = useState(null)
  const [formAlert, setFormAlert] = useState(null)
  const [jobs, setJobs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const jobsReport = useRef()
  const handlePrint = useReactToPrint({
    content: () => jobsReport.current,
  })

  // I use this state for default values, and to show criteria on report
  const [filters, setFilters] = useState({
    states: ["wykonane"],
    branches: user.allowedBranches.filter(
      (branch) => branch !== "TEST" && branch !== "cy"
    ),
    allLoc: true,
    startDate: format(startOfMonth(todayMinusTen), "yyyy-MM-dd"),
    endDate: format(endOfMonth(todayMinusTen), "yyyy-MM-dd"),
  })

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: filters,
  })

  const setCustomerFromDb = (customerData) => {
    setCustomer(customerData)
    setModalData({ show: false })
  }

  const fetchJobs = async (formData) => {
    try {
      // clear custom alerts:
      setFormAlert(null)

      setFilters(formData)

      // check if customer selected:
      if (!customer) return setFormAlert("wybierz klienta")
      setIsLoading(true)

      const res = await fetch("/jobs/getFiltered", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...formData, customer_id: customer._id }),
      })
      if (res.status !== 200)
        throw new Error(
          `Błąd: ${res.status} - ${
            (await res.text()) || "błąd komunikacji z serwerem"
          }`
        )
      const resJSON = await res.json()
      setJobs(
        resJSON.sort((a, b) =>
          differenceInCalendarDays(new Date(a.date), new Date(b.date))
        )
      )
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setModalData({
        show: true,
        type: "alert",
        body: err.message || "Nieokreślony błąd programu",
      })
    }
  }
  return (
    <Container fluid id="jobsPrintReport">
      {formAlert && <Alert variant="warning">{formAlert}</Alert>}
      <Form onSubmit={handleSubmit(fetchJobs)}>
        <Row>
          <Col xs="3">
            Stan:{" "}
            {errors.states && (
              <Badge pill variant="warning">
                Wybierz min. jeden stan
              </Badge>
            )}
            {[
              "zaplanowane",
              "zlecone",
              "wykonane",
              "odwołane (my)",
              "odwołane (klient)",
              "niewykonane (my)",
              "niewykonane (klient)",
              "usunięte",
              "archiwum",
            ].map((state) => (
              <Form.Check
                key={`stateChbx-${state}`}
                {...register("states", { required: true })}
                type="checkbox"
                label={state}
                value={state}
                id={state}
              />
            ))}
          </Col>
          <Col>
            <Row>
              <Col>
                OD
                {errors.startDate && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
                <Form.Control
                  {...register("startDate", { required: true })}
                  id="startDate"
                  as="input"
                  type="date"
                  className=""
                  autoComplete="chrome-off"
                />
              </Col>
              <Col>
                DO
                {errors.endDate && (
                  <Badge pill variant="warning">
                    Pole wymagane
                  </Badge>
                )}
                <Form.Control
                  {...register("endDate", { required: true })}
                  as="input"
                  type="date"
                  className=""
                  autoComplete="chrome-off"
                />
              </Col>
            </Row>
            <hr />
            <Row className="my-1">
              <Button
                size="sm"
                className="mr-2"
                onClick={() =>
                  setModalData({
                    show: true,
                    type: "info",
                    xl: true,
                    body: (
                      <CustomersFetch
                        embedded={true}
                        setCustomer={setCustomerFromDb}
                      />
                    ),
                  })
                }
              >
                <BsSearch />
              </Button>
              {customer?.shortName || "wybierz klienta"}
            </Row>
            <hr />
            <Row>
              {errors.branches && (
                <Badge pill variant="warning">
                  Wybierz min. jeden stan
                </Badge>
              )}
              {user.allowedBranches.map((branch) => (
                <Col key={`branchChbx-${branch}`}>
                  <Form.Check
                    {...register("branches", { required: true })}
                    type="checkbox"
                    label={branch}
                    value={branch}
                    id="branches"
                  />
                </Col>
              ))}
            </Row>
            <hr />
            <Row>
              <Form.Check
                {...register("getEqpNumbers")}
                type="checkbox"
                label="pokaż numery sprzętu"
                id="query.getEqpNumbers"
              />
            </Row>
          </Col>
          <Col xs="2">
            <Button type="submit" className="mt-3" variant="secondary">
              {isLoading ? <Spinner animation="border" size="sm" /> : "Pobierz"}
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />

      {jobs.length ? (
        <>
          <Button onClick={() => handlePrint()}>
            <MdPrint />
          </Button>
          <JobsPrintRender
            jobs={jobs}
            customer={customer}
            filters={filters}
            ref={jobsReport}
          />
        </>
      ) : null}

      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default JobsPrintFetch
