import { Container, Table } from "react-bootstrap"

const EqpChangeReportSummary = ({ summary }) => {
  const renderData = (srvType) => {
    const data = summary.filter((row) => row.srvType === srvType)

    if (data.length === 0)
      return (
        <tr>
          <td colSpan="4">Brak danych</td>
        </tr>
      )

    return data.map((row, i) => (
      <tr key={`${srvType}Row-${i}`}>
        <td>{row.name}</td>
        <td>
          {row.deliveriesEqpCount} ({row.deliveries})
        </td>
        <td>
          {row.pickupsEqpCount} ({row.pickups})
        </td>
        <td>{row.pickups - row.deliveries}</td>
      </tr>
    ))
  }

  return (
    <Container className="mt-2">
      <Table>
        <thead>
          <tr>
            <th>sprzęt</th>
            <th>dostarczenia (liczba zadań)</th>
            <th>zabrania (liczba zadań)</th>
            <th>suma sprzętu</th>
          </tr>
        </thead>
        <tbody>
          <tr className="fixed-values-table">
            <td colSpan="4" style={{ textAlign: "center", paddingBottom: 0 }}>
              <h6>Długi termin</h6>
            </td>
          </tr>
          {renderData("longTerm")}
          <tr className="fixed-values-table">
            <td colSpan="4" style={{ textAlign: "center", paddingBottom: 0 }}>
              <h6>Krótki termin</h6>
            </td>
          </tr>
          {renderData("shortTerm")}
        </tbody>
      </Table>
    </Container>
  )
}
export { EqpChangeReportSummary }
