import React from "react"
import { Row, Col, Button } from "react-bootstrap"

import employeeGeneral from "../../pics/manual/employeeGeneral.png"
import employeeContracts from "../../pics/manual/employeeContracts.png"
import employeeAgreementHistory from "../../pics/manual/employeeAgreementHistory.png"
import employeeNotes from "../../pics/manual/employeeNotes.png"
import employeeNoteDetails from "../../pics/manual/employeeNoteDetails.png"
import employeeArchivedNotes from "../../pics/manual/employeeArchivedNotes.png"
import employeeContractWithBonus from "../../pics/manual/employeeContractWithBonus.png"
import employeeContractNoBonus from "../../pics/manual/employeeContractNoBonus.png"
import employeeContractExplanation from "../../pics/manual/employeeContractsExplanation.png"
import employeeContractsExample1 from "../../pics/manual/employeeContractsExample1.png"
import employeeContractsExample2 from "../../pics/manual/employeeContractsExample2.png"
import employeeContractsExample3 from "../../pics/manual/employeeContractsExample3.png"
import employeeContractsExample4 from "../../pics/manual/employeeContractsExample4.png"

import dropzone2 from "../../pics/manual/dropzone2.gif"

const ManualEmployeesSection = ({ setModalData, user }) => {
  return (
    <div>
      <h2 id="5">Pracownicy</h2>
      <p>
        W wersji 0.5.0 (2020-12-26) dodałem komponent Pracownicy. Poniższy
        paragraf opisuje dlaczego jest potrzebny i jakie cele ma realizować.
        Lektura jest nieobowiązkowa, można od razu przejść do punktu
        "Uprawnienia".
        {/* NEW RULE FOR EMPLOYEES, uncomment when consulted... */}
        {/* , ma jednak uzasadnić nowo wprowadzoną
          zasadę, która znajduje się w polu poniżej. */}
      </p>
      {/* <Jumbotron>
          Informacje znajdujące się w module Pracownicy uznajemy za nadrzędny
          stan faktyczny. W przypadku jakichkolwiek wątpliwości, dane te mają
          charakter rozstrzygający. <br />
          Dlatego proszę wszystkich o uważne sprawdzanie, czy wszystkie zawarte
          tam informacje są aktualne, szczególnie po różnego rodzaju
          uzgodnieniach (typu "podwyżka o 200 zł za 6 miesięcy" itp). W
          przypadku rozbieżności pracownik jest zobowiązany do wysłania maila
          przynajmniej do Łukasza i mnie, jeśli takiego maila nie ma - uznajemy,
          że tematu nie było a "prawda leży po stronie clipERPa"
          <br />
          Powyższa zasada wchodzi w życie od 2021-01-18 (do tego czasu
          wprowadzone będą dane pracowników) i dotyczy wszystkich pracowników
          biurowych. Do aktualizacji danych zobowiązani są przede wszystkim
          przełożeni (którzy mają takie uprawnienia) i zarząd (ten faktyczny,
          nie-KRSowy, czyli Basia, Łukasz i ja). Przez "uzgodnienia" rozumiem
          przede wszystkim te dotyczące wynagrodzeń, ale też obiecanych sprzętów
          (samochody, telefony, komputery) i wszystkiego co zostało obiecane lub
          zapowiedziane przez przełożonych.
        </Jumbotron> */}
      <p>
        Coraz częściej zdarzały się nam niesympatyczne pomyłki (przykładowo nie
        pamiętania o jakichś ustaleniach sprzed kilku miesięcy). Tak samo coraz
        więcej czasu poświęcaliśmy na wyszukiwanie informacji rozsianych po
        różnych źródłach (biuro kadrowe, "teczki", skany, stary program, outlook
        itd. itd.). Żeby to uciąć, chcę wprowadzić tzw. "jedno źródło prawdy",
        czyli wszystkie informacje będą zebrane w jednym miejscu. Wprowadzimy to
        powoli, bo tych danych jest mnóstwo, a oprogramowanie ich obsługi w
        sposób (przede wszystkim) bezpieczny i przystępny też jest czasochłonne.{" "}
        <br />
        Zaczynamy od danych podstawowych oraz dotyczących umów o pracę i
        pokrewnych.{" "}
        <span className="bg-warningLight">
          W przyszłości planuję przenieść do clipERPa planowanie urlopów (w tym
          całą procedurę zatwierdzania przez odpowiednie osoby) i Motivation:
          wyznaczanie i rozliczanie celów II filaru ("indywidualnych"). Jednak{" "}
          <b>w pierwszej kolejności</b> dodam powiadamianie e-mail o wszelkich
          zmianach wprowadzonych u pracownika
        </span>
      </p>
      <div>
        <h3>Powiadomienia</h3>
        <p>
          Od wersji 0.6.1 clipERP wysyła powiadomienia o edycji podstawowych
          danych pracownika (dane z karty "Ogólne" widoczne w tabelach{" "}
          <b>Dane pracownika</b> i <b>Dane kontaktowe - prywatne</b>).
          <br />
          Powiadomienie wysyłane jest mailowo na adres pracownika, Basi, Łukasza
          i mój.
        </p>
        <p className="bg-warningLight">
          W przyszłości rozbuduję powiadomienia: będą wysyłane też do
          przełożonych i będą zawierać więcej informacji. Planuję też dodać
          powiadomienia o zmianach w umowach i notatkach -{" "}
          <b>na razie to nie jest obsłużone</b>
        </p>
      </div>
      <h3>Uprawnienia:</h3>
      <p>
        W związku z wrażliwością danych osobowych, wprowadziłem następujące
        zasady:
      </p>
      <ul>
        <li>
          Każdy pracownik domyślnie ma dostęp do swoich i tylko swoich
          informacji (<b>"kółko zębate" {"->"} "Dane użytkownika"</b>).
        </li>
        <li>
          Każdemu pracownikowi można przypisać jednego lub kilku przełożonych -
          będą oni mieli możliwość podglądania danych i edytowania ich w
          ograniczonym zakresie (przy użyciu przycisku na głównym menu
          nawigacyjnym - ukrytym przy braku uprawnień)
        </li>
        <li>
          Zarząd (czyli Basia, Łukasz i ja) może edytować dane każdego
          pracownika w pełnym zakresie. <b>Tylko zarząd</b> może przypisywać
          przełożonych do pracownika.
        </li>
      </ul>
      <p>
        Dodatkowo wszelkie zmiany danych pracownika (w tym notatek, umów i
        załączników, o których poniżej) są zachowywane w bazie danych z
        zapisaniem stanu przed zmianą, oraz informacją kto i kiedy zmiany
        dokonał. Nie ma możliwości edycji czy usunięcia tych wpisów do historii,
        tworzą się automatycznie po stronie serwera.
        <br />
        Same notatki i umowy z zasady są nieusuwalne, można je tylko
        archiwizować (zarchiwizowane są nadal normalnie dostępne tylko w innym
        miescu, żeby nie zabierać miejsca).
      </p>
      <h3>Komponent "Pracownicy" - wyświetlane dane</h3>

      <Row>
        <Col>
          <h4>Ogólne</h4>
          <p>
            Wszystkie najważniejsze dane dotyczące pracownika. Informacje są
            częściowo wpisywane ręcznie a częściowo zaciągane z innych źródeł
            (onaczone czerwonymi ramkami na grafice i{" "}
            <b>omówione dalej w tekście</b>).
          </p>

          <h4>Załączniki</h4>
          <p>
            Do notatek i umów (omówionych szczegółowo poniżej) można dodawać
            załączniki.
            <br /> Lista załączników widoczna jest z podglądu szczegółów
            notatki/umowy. Po kliknięciu nazwy pliku w nowej zakładce otworzy
            się jego podgląd. Dodawanie i usuwanie załączników wymaga
            dodatkowych uprawnień.
          </p>
        </Col>
        <Col>
          <img src={employeeGeneral} alt="" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4>Historia</h4>
          <p>
            Dla wszystkich danych w komponencie Pracownicy dostępny jest podgląd
            historii edycji.
          </p>
          <ul>
            <li>Ogólne dane pracownika - przycisk na karcie Ogólne</li>
            <li>
              Umowa i notatka - przycisk na podglądzie konkretnej umowy/notatki
            </li>
          </ul>
          <p>W historii zapisywane są następujące dane:</p>
          <ul>
            <li>Kto edytował</li>
            <li>Kiedy edytował</li>
            <li>Jak edytował - zapisuje się stan sprzed edycji</li>
          </ul>
          <p>
            <u>
              Historia jest nieedytowalna i tworzy się automatycznie przy każdej
              zmianie.
            </u>
            <br />
            Ze względów technicznych nazwy zmienionych pól są po angielsku,
            gdyby coś było niezrozumiałe - prosze o kontakt.
          </p>
        </Col>
        <Col>
          <h4>Podgląd historii (na przykładzie historii umowy):</h4>
          <img
            src={employeeAgreementHistory}
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>
      <hr />
      <h3>Umowy</h3>
      <Row className="mt-2">
        <Col>
          <p>
            <b>Czym jest "umowa" w clipERP</b>: umowa to zapis faktycznych
            ustaleń między firmą a pracownikiem. Ustalenia te mogą:
          </p>
          <ul>
            <li>już obowiązywać ("umowa aktualna"),</li>
            <li>
              możemy się umówić, że zaczną obowiązywać w przyszłości
              ("ustalenia")
            </li>
            <li>albo kiedyś obowiązywały ("archiwum")</li>
          </ul>
          <p>
            <b>Ważne:</b> to są tylko ogólne ustalenia. Nie ma tu np. informacji
            o jednorazowych nagrodach za "szczególne osiągnięcia" itp.
          </p>
          <p>
            <b>Dodatkowy podział</b>: na umowy miesięczne (np. zwykła umowa o
            pracę) i umowy "godzinowe" (czyli wypłata zależy od przepracowanych
            godzin, np. umowy zlecenie)
            <br />
            Jeśli pracownik ma oba typy umów, to wyświetlane są w osobnych
            tabelach.
          </p>
        </Col>

        <Col>
          <h4>Karta "umowy":</h4>
          <img alt="" src={employeeContracts} style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
      <hr />
      <h4>Szczegóły umowy</h4>
      <Row>
        <Col>
          <p>
            Po kliknięciu umowy w tabeli wyświetli się okno (tzw. modal) ze
            szczegółowymi informacjami o umowie.
          </p>
          <p>
            <b>Czym są "dodatkowe obciążenia"?</b> Dodatkowe obciążenia to np
            Multisport czy ubezpieczenie na życie. <br />
            <u>
              Dodatkowe obciążenia w clipERPie nie są odejmowane od
              wynagrodzenia netto
            </u>
            <br />
            <Button
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  header: "Dodatkowe obciążenia - wyjaśnienie",
                  xl: true,
                  body: (
                    <p>
                      Przykład: <br />
                      ustalona pensja netto ("na rękę") to 3000, ale pracownik
                      korzysta z Multisporta, za którego potrącamy 100 zł (
                      <b>"Dodatkowe obciążenia" - "koszt pracownika"</b>).
                      <br /> w clipERP zapisana będzie umowa z{" "}
                      <b>wynagrodzeniem netto</b> = 3000, do tego będzie{" "}
                      <b>Dodatkowe obciążenie</b> = 100zł (w rubryce "Koszt
                      pracownika").
                      <br />
                      <u>W "realu" pracownik dostanie na konto 2900 zł</u> (
                      "wynagrodzenie netto" <b>minus</b> "dodatkowe
                      obciążenia").
                    </p>
                  ),
                })
              }
            >
              Przykład
            </Button>
          </p>
        </Col>
        <Col>
          <img
            src={employeeContractNoBonus}
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col>
          <h4>Przejście "ustalenia" {"->"} "aktualna" - wyjaśnienie</h4>
          <p>Gdy "ustalenia" wchodzą w życie możliwe są dwa scenariusze:</p>
          <ol>
            <li>
              Jeśli ustalenie dotyczyło <u>już obowiązującej umowy</u> (czyli
              podpisujemy aneks/porozumienie stron): edytujemy aktualną umowę a
              ustalenie archiwizujemy.
            </li>
            <li>
              Jeśli ustalenie dotyczyło nowej umowy (czyli podpisujemy zupełnie
              nowy dokument): przenosimy ustalenie do "Umowy aktualne".
            </li>
          </ol>
          <p>
            Takie podejście pozwala na zachowanie historii i załączników
            edytowanej umowy.
          </p>
          <p>
            Jeśli dla kogoś to jest niejasne, po kliknięciu przycisku bardziej
            rozbudowany opis:{" "}
          </p>

          <Button
            className="mx-3"
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: "true",
                body: (
                  <>
                    <p>
                      Moduł "Umowy" ma jak najlepiej odwzorowywać to, co ma
                      miejsce w rzeczywistości.
                    </p>
                    <p>
                      Żeby lepiej to zrozumieć przyjmijmy, że jest to "teczka
                      pracownicza": ustalenie dotyczące obowiązującej umowy to
                      kartka samoprzylepna przyklejona na trwającą umowę, z
                      zapisanymi szczegółami zaplanowanej na przyszłość zmiany.{" "}
                      <br />W momencie wprowadzania zmiany nie podpisujemy
                      karteczki tylko "edytujemy" pierwotną umowę za pomocą
                      aneksu/porozumienia - karteczka jest "archiwizowana" do
                      kosza (a w clipERPie faktycznie archiwizowana, bo twórca
                      nie lubi usuwania danych ;) ).
                    </p>
                    <p>
                      Przy tym podejściu ustalenie dotyczące nowej umowy, którą
                      planujemy zawrzeć to jej wydruk włożony do teczki ale
                      jeszcze niepodpisany. Gdy wchodzi w życie składamy podpisy
                      - sprawiamy, że staje się "aktualnie obowiązująca".
                    </p>
                  </>
                ),
              })
            }
          >
            Przykład 1
          </Button>
          <Button
            onClick={() =>
              setModalData({
                show: true,
                type: "info",
                xl: "true",
                body: (
                  <>
                    <p>
                      Powiedzmy, że pracuje u nas Pan Zbigniew. Jest zatrudniony
                      na podstawie umowy o pracę na czas określony, zarabia 2500
                      "na rękę". Uzgadnia z nami, że w dniu X przejdzie na umowę
                      o pracę na czas nieokreślony z wynagrodzeniem 2700:
                      <img
                        src={employeeContractsExample1}
                        alt=""
                        className="border-black"
                      />
                      <hr />
                      Przyjmijmy, że Pan Zbigniew dodatkowo ustalił z nami, że
                      również w dniu X podpiszemy <u>dodatkową</u> umowę
                      zlecenie na sprzątanie biura, 15 zł za godzinę:
                      <img
                        src={employeeContractsExample2}
                        alt=""
                        className="border-black"
                      />
                      <hr />
                      Przychodzi dzień X. Ponieważ biuro jednak nie wymaga
                      sprzątania, dogadujemy się ze Zbigniewem, że umowę o pracę
                      przedłużamy, ale umowa zlecenie jest nieaktualna. Zbigniew
                      spodziewał się, że dorobi na sprzątaniu, więc wynegocjował
                      podwyżkę umowy o pracę na 2750.
                      <br />
                      <br />W tej sytuacji, po podpisaniu wszystkich papierów, w
                      clipERPie widoczne będą następujące dane:
                      <img
                        src={employeeContractsExample3}
                        alt=""
                        className="border-black"
                      />
                      <hr />
                    </p>
                    <p>
                      Umowa o pracę na czas nieokreślony za 2750 - to{" "}
                      <u>ta sama umowa</u> co na początku (2500 na czas
                      określony), bo zmiana nastąpiła na podstawie porozumienia
                      stron, a nie zerwania poprzedniej umowy i podpisania
                      nowej. Modyfikacja będzie widoczna po kliknięciu przycisku
                      "Historia"
                      <img src={employeeContractsExample4} alt="" />
                      <hr />
                    </p>

                    <p>Reasumując:</p>
                    <ul>
                      <li>
                        w części dotyczącej{" "}
                        <u>
                          <b>umów aktualnych</b>{" "}
                        </u>
                        zawarte są dane odzwierciedlające faktycznie zawarte
                        porozumienia,{" "}
                        <u>
                          z zapisem pełnej historii ich zmian (na mocy
                          porozumienia stron/aneksu itd). Umowa (wraz z
                          historią) jest przenoszona do archiwum tylko w
                          sytuacji jej faktycznego rozwiązania/wygaśnięcia.
                        </u>
                      </li>
                      <li>
                        w "ustaleniach" zapisujemy to, na co się "dogadujemy" -
                        niezależnie czy dotyczy to nowej umowy (wtedy po
                        faktycznym podpisaniu papieru to "ustalenie" zmieni się
                        na aktualną umowę), czy aktualnie obowiązującej (wtedy
                        zmiana będzie naniesiona na aktualną umowę, a ustalenie
                        zostanie zarchiwizowane).
                      </li>
                      <li>
                        archiwum jest dla ustaleń, które zostały naniesione na
                        zawarte wcześniej umowy, dla ustaleń anulowanych i umów,
                        które zostały faktycznie rozwiązane (np. Zbigniew
                        sprzątał przez kilka miesięcy, ale rozwiązaliśmy umowę,
                        więc najpierw była aktywna, potem trafiła do archiwum).
                      </li>
                    </ul>
                  </>
                ),
              })
            }
          >
            Przykład 2 - historyjka
          </Button>
        </Col>
        <Col>
          <h4>Przykład wprowadzania zmiany:</h4>
          <img
            src={employeeContractExplanation}
            alt=""
            style={{ maxWidth: "100%" }}
          />
        </Col>
      </Row>

      <hr />
      <Row>
        <Col>
          <h3>Notatki</h3>
          <p>
            Tu trafiają wszelkie zapiski i informacje, które nie mają
            zaprojektowanego miejsca gdzie indziej. Zawsze jedna (i tylko jedna)
            notatka ma ustawiony typ "główna" i to bezpośrednio jej treść
            wyświetlana jest na pierwszym ekranie ("Ogólne"), stąd można ją
            edytować (oczywiście wymagane są odpowiednie uprawnienia).
          </p>
          <p>
            Dla przejrzystości, na tej liście widać tylko pierwsze kilkadziesiąt
            znaków notatki, aby zobaczyć pełną treść trzeba kliknąć odpowiedni
            wiersz, wtedy pokazuje się podgląd notatki:
          </p>
          <img src={employeeNoteDetails} alt="" className="img-fluid" />
          <p>
            W nagłówku listy notatek znajduje się przycisk "Archiwalne", który
            umożliwia dostęp do listy zarchiwizowanych notatek (grafika
            poniżej). Każdą można kliknąć, co wyświetli jej podgląd (jak przy
            zwykłej notatce) i możliwość podejrzenia historii.
            <img src={employeeArchivedNotes} alt="" className="img-fluid" />
          </p>
        </Col>
        <Col>
          <img src={employeeNotes} alt="" />
        </Col>
      </Row>
      {user.perm.employees.r ? (
        <div>
          <h3>Komponent pracownicy - szczegółowe informacje</h3>
          <i>
            Cześć widoczna tylko dla przełożonych, jednak niektórzy z Was mogą
            mieć uprawnienia tylko do odczytu, bez możliwości dodawania i edycji
            danych opisanych poniżej - tak czy inaczej zalecam lekturę, żebyście
            wiedzieli o co chodzi.
            <br />
            Jeśli uznacie, że potrzebujecie uprawnień do edycji dajcie znać.
          </i>

          <p className="mt-2">
            W komponencie uwzględniłem kilka funkcji, które są niewidoczne dla
            pracowników z niższymi uprawnieniami (lub widoczne pod pewnymi
            warunkami):
          </p>
          <ul>
            <li>
              możecie dodawać specjalne notatki (rodzaj: "kierownicza"). Taka
              notatka będzie widoczna dla Was i zarządu, pracownik nie będzie
              miał na nią podglądu.
            </li>
            <li>
              przy dodawaniu/edycji umowy (również "ustaleń") można uwzględnić w
              niej "dodatkowe nagrody". Chodzi o nagrody "stałe" (wypłacane
              regularnie w zależności od jakichś warunków, np. realizacji
              budżetu przez oddział). Żeby uniknąć nieporozumień, ta część
              tabeli będzie się pojawiać tylko jeśli w rubryce "netto" jest
              cokolwiek wpisane:
            </li>
          </ul>
          <Row>
            <Col>
              <img src={employeeContractNoBonus} alt="" className="img-fluid" />
            </Col>
            <Col>
              <img
                src={employeeContractWithBonus}
                alt=""
                className="img-fluid"
              />
            </Col>
          </Row>

          <p>
            Część z Was ma też uprawnienia do dodawanie umów z kategorii
            "ustalenia" - bardzo proszę o wpisywanie tam wszystkiego, co
            uzgadniacie z pracownikami (również przy udziale Łukasza lub moim).
            <br />
            Formularz dodawania/edycji{" "}
            <u>nie ma żadnych mechanizmów sprawdzania poprawności danych</u>.
            Można dodać datę zakończenia umowy wcześniejszą od rozpoczęcia, albo
            zapisać pusty formularz itp. Daje to maksymalną elastyczność, ale
            trzeba uważać przy jego wypełnianiu.
          </p>

          <h3>Załączniki</h3>
          <p>
            Do notatek i umów można dodawać załączniki klikając przycisk "Dodaj"
            (lub przeciągając plik bezpośrednio na ten przycisk jak na poniższej
            animacji). Po wybraniu pliku można zmienić nazwę lub zachować obecną
            i po prostu kliknąć zapisz. Rozszerzenie pliku jest niezmienialne -{" "}
            <b>
              zostanie automatycznie dodane takie jak w oryginalnie wybranym
              pliku
            </b>
            .
          </p>
          <p className="bg-dangerLight">
            Dopuszczalne typy plików: pdf, jpg (i jpeg) oraz png. W nazwie pliku
            nie może występować znak "/"
            <br />W przypadku wykrycia zduplikowania nazwy program nie odrzuci
            żądania, po prostu doda do nazwy pliku unikalny ciąg znaków po
            myślniku{" "}
            <Button
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  body: (
                    <p>
                      Jeśli istnieje już plik "umowa.pdf" i spróbujesz dodać
                      taki sam (niezależnie, czy wpiszesz "umowa" w okienku
                      formularza przed zapisaniem, czy po prostu wybierzesz ze
                      swojego komputera plik "umowa.pdf" i nie mzienisz jego
                      nazwy przed zapisaniem w programie), zostanie do niego
                      dodany ciąg cyfr (dla ciekawskich: tzw. epoch timestamp),
                      żeby zapewnić unikalność nazwy. Od strony programu
                      nieczego to nie komplikuje, oba pliki można oglądać i
                      pobierać, po prostu nazwa będzie mniej czytelna.
                    </p>
                  ),
                })
              }
              size="sm"
            >
              Przykład
            </Button>
          </p>
          <img src={dropzone2} alt="" />
        </div>
      ) : null}
    </div>
  )
}
export default ManualEmployeesSection
