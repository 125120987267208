import {
  isWithinInterval,
  startOfDay,
  endOfDay,
  addDays,
  isDate,
} from "date-fns"
/**
 * filters location.services that were active on given date. For params without end date uses today + 1000
 * @param {Date} date date to use
 * @param {Array} services location.services arr to filter
 * @returns {Array} services that were active on date
 */
const getSrvsActiveOnDate = (date, services) => {
  return services.filter((srv) => {
    let isActive = false
    srv.params.forEach((prm) => {
      if (
        isWithinInterval(new Date(date), {
          start: startOfDay(new Date(prm.startDate)),
          end:
            prm.endDate && isDate(new Date(prm.endDate))
              ? endOfDay(new Date(prm.endDate))
              : addDays(new Date(), 1000),
        })
      )
        isActive = true
    })
    return isActive
  })
}

export { getSrvsActiveOnDate }
