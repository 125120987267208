import { Button, Form, Col, Row, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { fetchFiltered } from "./utils"
import InvListPrint from "./Print"

const InvListForm = ({
  user,
  setSearchParams,
  setPage,
  searchParams,
  setModalData,
  setInvoices,
  setIsLoading,
  refresh,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: searchParams,
  })

  const submit = async (formData) => {
    setPage(1)
    await fetchFiltered(
      { ...formData, page: 1 },
      setModalData,
      setInvoices,
      setPage
    )

    setSearchParams({ ...formData, page: 1 })
  }

  // when user searched for invoices of specific customer from customer data
  // there is _id of that customer in searchParams. User can remove that -> it clears url query string,
  // 'q' input and fetched invoices

  const handleClearingId = () => {
    setSearchParams((prev) => ({ ...prev, _id: null, q: "" }))
    window.history.pushState({}, "", "/Invoicing/List")
    setValue("q", "")
    setValue("_id", "")
    setInvoices({ pages: 0 })
  }
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Form.Row>
        <Col xs="2">
          <Form.Label>Pokaż:</Form.Label>
          <Form.Check
            {...register("show")}
            type="radio"
            label="wszystkie"
            id="all"
            value="all"
          />
          <Form.Check
            {...register("show")}
            type="radio"
            label="wyeksportowane"
            id="exported"
            value="exported"
          />
          <Form.Check
            {...register("show")}
            type="radio"
            label="niewyeksportowane"
            id="unexported"
            value="unexported"
          />
        </Col>
        <Col xs="4">
          <Row>
            <Form.Check
              {...register("dateType")}
              type="radio"
              label="data faktury"
              value="invoice"
              id="dateType"
            />

            <Form.Check
              {...register("dateType")}
              type="radio"
              label="data wygenerowania"
              value="createdAt"
              id="dateType"
            />
          </Row>
          <hr className="my-1" />
          <Row>
            <Col>
              data OD:
              <Form.Control
                {...register("startDate")}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
              brutto OD:
              {errors.sumFrom && (
                <Badge pill variant="warning">
                  {errors.sumFrom.message}
                </Badge>
              )}
              <Form.Control
                {...register("sumFrom", {
                  min: { value: 0, message: "kwota musi być większa od zera" },
                })}
                as="input"
                type="number"
                step="any"
                className=""
                autoComplete="chrome-off"
              />
            </Col>
            <Col>
              data DO:
              <Form.Control
                {...register("endDate")}
                as="input"
                type="date"
                className=""
                autoComplete="chrome-off"
              />
              brutto DO:
              {errors.sumTo && (
                <Badge pill variant="warning">
                  {errors.sumTo.message}
                </Badge>
              )}
              <Form.Control
                {...register("sumTo", {
                  min: { value: 0, message: "kwota musi być większa od zera" },
                })}
                as="input"
                type="number"
                step="any"
                className=""
                autoComplete="chrome-off"
              />
            </Col>
          </Row>
        </Col>
        <Col className="mx-4" xs="3">
          <Row>
            <Col>
              Klient:
              <Form.Control
                {...register("q")}
                as="input"
                type=""
                className=""
                autoComplete="chrome-off"
                readOnly={!!searchParams._id}
              />
            </Col>
            {/* if using customer _id from query string -> show btn to clear, details in func comments above */}
            {searchParams._id ? (
              <Col xs="auto">
                <Button
                  variant="danger"
                  className="mt-4"
                  size="sm"
                  onClick={handleClearingId}
                >
                  X
                </Button>
              </Col>
            ) : null}
          </Row>
          <Row>
            filia:
            <Form.Control
              {...register("branch")}
              as="select"
              className=""
              autoComplete="chrome-off"
            >
              <option value={"all"}>wszystkie</option>
              {user.allowedBranches.map((branch) => (
                <option value={branch} key={`branchSelect-${branch}`}>
                  {branch}
                </option>
              ))}
            </Form.Control>
          </Row>
        </Col>

        <Col>
          wyświetl pozycji:
          <Form.Control
            {...register("pageSize")}
            as="select"
            className=""
            autoComplete="chrome-off"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </Form.Control>
        </Col>
        <Col>
          <Row>
            <Button className="ml-auto mr-3 mt-3" type="submit">
              Szukaj!
            </Button>
          </Row>

          <InvListPrint
            getValues={getValues}
            setModalData={setModalData}
            setPage={setPage}
            setIsLoading={setIsLoading}
            refresh={refresh}
            setSearchParams={setSearchParams}
          />
        </Col>
      </Form.Row>
    </Form>
  )
}
export default InvListForm
