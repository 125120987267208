import React, { useState } from "react"

import { useTable, usePagination } from "react-table"

import { Table, Row, Button } from "react-bootstrap"

import { MdArrowBack, MdArrowForward } from "react-icons/md"

const EmployeesListRender = (props) => {
  const [columns] = useState([
    { Header: "Imię i nazwisko", accessor: "fullName" },
    {
      Header: "Umowy",
      accessor: (row) => {
        const activeContracts = row.contracts.filter(
          (contract) => contract.state === "aktywna"
        )
        if (activeContracts.length === 0) return "Brak aktywnych umów"
        else
          return activeContracts
            .map((contract) => {
              return `${contract.mainType} (${
                contract.indefiniteTime ? "n-okr" : "okr"
              })`
            })
            .join(", ")
      },
      id: "contracts",
    },
    {
      Header: "Stanowiska",
      accessor: (row) => {
        const activeContracts = row.contracts.filter(
          (contract) => contract.state === "aktywna"
        )
        if (activeContracts.length === 0) return "-"
        else
          return activeContracts
            .map((contract) => {
              return contract.position
            })
            .reduce((acc, curr) => {
              if (acc.includes(curr)) return acc
              else {
                acc.push(curr)
                return acc
              }
            }, [])
            .filter((position) => position !== null)
            .join(", ")
      },
      id: "positions",
    },
    { Header: "Stan", accessor: "state" },
  ])

  const data = props.employees

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 20, pageIndex: 0 },
    },
    usePagination
  )

  return (
    <span id="EmployeesListRender">
      <Table {...getTableBodyProps} className="transactions-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // if uncomment below fragments you get sorting when clicking on header, now sorting is default descending
                <th
                  {
                    ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                  }
                  className="pretty-table-header"
                >
                  {column.render("Header")}
                  {/* <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className="transaction-details-tr"
                id="employeeRow"
                onClick={() => {
                  window.open(`/Employees/Details/${row.original._id}`)
                }}
              >
                {row.cells.map((cell) => {
                  if (cell.column.id === "state") {
                    let stateCellClass = ""
                    if (cell.value === "aktywny") stateCellClass = "bg-success"
                    else if (cell.value === "archiwum")
                      stateCellClass = "bg-warning"
                    return (
                      <td {...cell.getCellProps()} className={stateCellClass}>
                        {cell.render("Cell")}
                      </td>
                    )
                  } else
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="pretty-table-data"
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row className="justify-content-end align-items-center">
        <Button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
          // style={{ maxHeight: "30px" }}
        >
          <MdArrowBack />
        </Button>
        <b>{pageIndex + 1}</b> z {pageOptions.length}
        <Button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="py-0 mx-2"
          variant="outline-secondary"
          size="sm"
        >
          <MdArrowForward />
        </Button>
        {/* below element giving possibility to go to certain page, for now I dont need it */}
        {/* <div>Idź do:</div>
            <input 
            type="number"
            defaultValue={pageIndex + 1 || 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
          /> */}
      </Row>
      <Row className="justify-content-end mt-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
          className="mr-4"
        >
          {
            // setting pagination options - how many rows can user display
            [5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Pokaż {pageSize}
              </option>
            ))
          }
        </select>
      </Row>
    </span>
  )
}
export default EmployeesListRender
