import React, { useContext } from "react"

import { useCookies } from "react-cookie"

import UserContext from "../contexts/userContext"
import {
  Container,
  Button,
  Row,
  DropdownButton,
  Dropdown,
} from "react-bootstrap"

const ConfigNav = () => {
  const user = useContext(UserContext)
  //eslint-disable-next-line
  const [cookies, setCookie] = useCookies()

  const handleLogut = async () => {
    try {
      const res = await fetch("/employees/logout", {
        method: "DELETE",
      })
      if (res.status !== 200) {
        throw res
      } else window.location.reload()
    } catch (err) {
      console.log(err)
      alert(err)
    }
  }

  return (
    <Container id="ConfigNav">
      <Row className="mt-4 justify-content-between">
        {user.perm.consumption.w || user.perm.consumptionsList.r ? (
          <DropdownButton title="STARE magazyny" variant="secondary">
            <Dropdown.Item href="/consumptionsTab" className="nav-link">
              Lista
            </Dropdown.Item>

            {user?.perm.consumptionsList.r ? (
              <Dropdown.Item href="/branchConsumption" className="nav-link">
                Sumy oddziałowe
              </Dropdown.Item>
            ) : null}
          </DropdownButton>
        ) : null}
        <Button href="/manual">Instrukcja</Button>
        <Button href="/ChangeLog">Lista zmian</Button>
        <Button href={`/Employees/Details/${user._id}`} id="userDataBtn">
          Dane użytkownika
        </Button>
        <Button onClick={handleLogut}>Wyloguj</Button>
      </Row>

      {/* SUPER USER: */}
      <Row className="justify-content-around mt-2">
        {user.perm.servicesManage.r && (
          <Button href="/ServicesManageTable">Usługi - zarządzanie</Button>
        )}
        {user.perm.eqpManage.r && (
          <Button href="/EquipmentTable">Sprzęt - zarządzanie</Button>
        )}
      </Row>

      {/* ADMIN ONLY: */}
      {user.type.admin ? (
        <Row className="mt-2 justify-content-between">
          <Button href="/tech">Dokumentacja techniczna</Button>

          <Button href="/employees">Zarządzanie pracownikami</Button>
          <Button href="/errorsLog">ErrorLog</Button>
          <Button href="/tests" variant="outline-danger">
            Komponent testowy
          </Button>

          <Button
            onClick={() =>
              Navigator.clipboard.writeText("no cześć").then(() => alert("ok"))
            }
          >
            Kopiuj
          </Button>
          <Button href="/Logs">Logi</Button>
          <Button href="/Reports/EqpCompliance">Sprawdzenie sprzętu</Button>
        </Row>
      ) : null}
    </Container>
  )
}
export default ConfigNav
