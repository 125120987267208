import React from "react"
import { Row, Col } from "react-bootstrap"

import newVersionModal from "../../pics/manual/newVersionModal.png"
import changeLog from "../../pics/manual/changeLog.png"
import mainScreenImg from "../../pics/manual/mainScreen.png"
import modals from "../../pics/manual/modals.png"
import selectAndDrag from "../../pics/manual/selectAndDrag.gif"
import modalsScrolling from "../../pics/manual/general/modalsScrolling.gif"
import { createManualBtn } from "../../../comp/utils/buttons"

/*

info o działaniu checkboxa w nagłówku tabeli
info o rozciąganiu textarea
historia: mogą się pojawiać daty w pełnym formacie (z godziną, minutą, sekundą i milisekundą)
ikona otwierania w nowej karcie przeglądarki
*/
const ManualGeneralSection = () => {
  return (
    <div>
      <h2 id="1">1. Informacje ogólne</h2>
      <h3>Korzystanie z programu</h3>
      <p>
        Program po zalogowaniu przy standardowych ustawieniach utrzymuje sesję
        przez 7 dni - tzn. że przez tydzień nie będzie konieczności ponownego
        logowania się.
        <br />
        Oczywiście nie ma żadnych przeciwwskazań, żeby korzystać z wbudowanego w
        przeglądarkę menedżera haseł.
        <br />
        <br />
        Dla ułatwienia korzystania polecam stworzyć link na pulpicie, zgodnie z
        poniższą instrukcją (działa zarówno dla Firefox jak i Chrome):
        <br />
        <a
          href="https://support.mozilla.org/pl/kb/tworzenie-na-pulpicie-skrotu-do-strony-internetowe"
          target="blank"
        >
          Instrukcja tworzenia skrótu
        </a>
      </p>
      <p id="manualBtnParagraph">
        Na liście zmian oraz w różnych miejscach programu może pojawiać się
        przycisk {createManualBtn("")}. Kliknięcie powoduje otwarcie{" "}
        <b>w nowej karcie</b> instrukcji przewiniętej do odpowiedniej części.
        <br />W przypadku listy zmian zapoznanie się z wskazywanym fragmentem
        jest obowiązkowe. W innych miejscach przycisk służy szybkiemu
        odświeżeniu wiadomości.
      </p>
      <h3>Błędy:</h3>
      <p>
        Jedna z podstawowych zasad programowania brzmi: "błędy były, są i będą".
        Dlatego ważne jest, żeby korzystając z programu stosować zasadę
        ograniczonego zaufania. Oczywiście proszę o zgłaszanie błędów, o czym
        poniżej, ale również niesprecyzowanych wrażeń, że coś może być nie tak -
        wszystko sprawdzę i najwyżej okaże się że jest ok, a może się to
        przyczynić do wyłapania poważnej wady.
      </p>
      <p>
        <b>Zgłaszanie błędów:</b>
        <br />
        Przy zgłaszaniu błędów bardzo ważne jest dla mnie, żebyście podawali jak
        najwięcej informacji. A najważniejsze to kroki, jakie doprowadziły do
        wygenerowania błędu - co po kolei klikaliście, zmienialiście itd., im
        dokładniej tym lepiej.
        <b>Niezwykle pomocne mogą być też zrzuty albo zdjęcia ekranu.</b>
      </p>
      <p>
        Błędy można zgłaszać przez wbudowany formularz dostępny przez "Wyślij
        wiadomość" w dolnym prawym rogu albo wysyłając maila na{" "}
        <a href="mailto:krzysiek@clipper.pl">krzysiek@clipper.pl</a>, przy czym
        możliwość wysłania zrzutu/zdjęcia ekranu na razie jest tylko przy
        wykorzystaniu standardowego maila.
      </p>

      <h4>Nieaktualne grafiki</h4>
      <p>
        W związku z ciągłym rozwojem programu grafiki w instrukcji mogą być
        nieaktualne. <b>To nie jest błąd</b>, aktualizuję grafiki, gdy uznam, że
        to konieczne dla zrozumienia przekazu.
        <br />
        Oczywiście jesli uznacie, że coś jest niejasne przez brak aktualizacji -{" "}
        <u>proszę o informację</u>.
      </p>

      <h3>Zgłaszanie poprawek/ulepszeń</h3>
      <p>
        Zachęcam do zgłaszania wszystkich pomysłów, jakie przyjdą Wam do głowy,
        które mogłyby ułatwić Wam korzystanie z programu. W miarę możliwości
        bardzo proszę, żeby te zgłoszenia były jak najbardziej konstruktywne, to
        znaczy zawierały propozycje konkretnych rozwiązań. Czyli zamiast
        informacji, że jakiś formularz mógłby być lepszy proszę o sugestie, co
        konkretnie zrobić, żeby był lepszy ;).
      </p>
      <p>
        Sugestie można zgłaszać przez wbudowany formularz dostępny przez "Wyślij
        wiadomość" w dolnym prawym rogu albo wysyłając maila na{" "}
        <a href="mailto:krzysiek@clipper.pl">krzysiek@clipper.pl</a>
      </p>
      <h3>Uprawnienia</h3>
      <p>
        Wraz z rozwojem programu pojawia się coraz więcej komponentów i danych,
        do których dostęp ograniczam osobnymi uprawnieniami.
        <br />
        Po pierwsze nie ma potrzeby, żeby osoba zajmująca się np. tylko
        magazynami miała dodatkowy bałagan w menu nawigacji, oraz żeby musiała
        zapoznawać się z dodatkowymi wpisami na liście zmian i w instrukcji.
        <br />
        Dodatkowo muszę brać pod uwagę ochronę danych: przed dostępem (dane
        wrażliwe jak np. w komponencie "Pracownicy") ale i przypadkową
        modyfikacją, która mogłaby wprowadzić zamieszanie (stąd blokowanie
        zafakturowanych transakcji czy przekazanych do biura rachunkowego zużyć
        magazynowych)
      </p>
      <p>
        Biorąc powyższe pod uwagę przyjmuję metodę ograniczania dostępu "gdzie
        tylko się da" dopóki jest to bez szkody dla normalnej pracy. Może się
        jednak zdażyć, że przesadzę. Dlatego{" "}
        <b>
          jeśli uważacie, że jakieś ograniczenie uprawnień, możliwości edycji
          czy dostępu do danych jest dla Was utrudnieniem
        </b>{" "}
        nie krępujcie się, żeby mi to zgłosić. Bardzo możliwe, że faktycznie za
        bardzo obciąłem uprawnienia, albo po prostu zapomniałem dodać Wam
        odpowiednie uprawnienie przy aktualizacji programu.
      </p>

      <h3>Zmiany i wersje programu</h3>
      <div>
        <p>
          Program jest aktualizowany na bieżąco według potrzeb. Po każdej
          aktualizacji zmienia się numer wersji. Składa się on z trzech liczb
          (X.Y.Z, np 1.5.28)
        </p>
        <ul>
          <li>
            W przypadku drobnych zmian i poprawek błędów zmienia się ostatnia
            liczba. Informacja o takich zmianach może, ale nie musi, pojawić się
            na liście zmian.
          </li>
          <li>
            W przypadku dodania nowych, istotnych funkcjonalności zmienia się
            środkowa i ostatnia liczba (ostatnia się zeruje). Wszystkie ważne
            zmiany pojawią się na liście zmian.
          </li>
          <li>
            Zmiana pierwszej liczby oznacza gruntowną modyfikację programu. Poza
            informacją na liscie zmian na pewno pojawi się korespondencja
            mailowa ode mnie.
          </li>
        </ul>

        <Row className="mt-4">
          <Col>
            <p>
              Po <b>"średniej"</b> aktualizacji każdemu użytkownikowi pojawi się
              alert o nowej wersji. Przez pierwsze 7 dni istnieje możliwość
              "odłożenia" ponownego wyświetlenia alertu o godzinę. <br />
              Po 7 dniach od takiej aktualizacji nie ma możliwości zrobienia
              czegokolwiek poza przejściem do instrukcji. Każde inne działanie
              przekieruje do listy zmian.
            </p>
          </Col>
          <Col>
            <img src={newVersionModal} alt="" />
          </Col>
        </Row>
      </div>
      <Row className="mt-4">
        <Col>
          <p>
            Lista zmian dostępna jest po kliknięciu przycisku "Sprawdź" na
            alercie, lub z menu technicznego (trybik na górze po prawej).
          </p>
          <p>
            W tabeli wpisuję numer wersji, datę aktualizacji i informację o
            zmianach z podziałem na moduły ("Ogólne", "Magazyny", "Pracownicy"
            itd.).
          </p>
          <p>
            Informacje wyświetlane są w zależności od uprawnień użytkownika,
            może się zdarzyć że lista będzie pusta (użytkownik z innymi
            uprawnieniami będzie już coś widział).
          </p>
          <p>
            W opisie zmian mogą widnieć zapisy odsyłające do instrukcji
            ("szczegóły w instrukcji" itp.). Zmiany w instrukcji w stosunku do
            poprzedniej wersji zaznaczam na niebiesko.
          </p>
          <p>
            Każdy użytkownik musi przeczytać i zrozumieć (a w razie niejasności
            - dopytać) wprowadzone zmiany, również te w instrukcji.
            <br />
            Następnie należy kliknąć przycisk "Zapoznałem się" - to spowoduje
            wyłączenie pojawiania się alertu.
          </p>
          <p>
            Gdyby lista była pusta (brak zmian w komponentach, do których
            użytkownik ma uprawnienia) również konieczne jest potwierdzenie -
            inaczej ciągle będzie wyświetlany alert.
          </p>
        </Col>
        <Col>
          <img src={changeLog} alt="" />
        </Col>
      </Row>

      <h3>Ekran programu</h3>
      <img src={mainScreenImg} width="50%" alt="widok głównego ekranu" />
      <ol>
        <li>
          Menu - umożliwia nawigację między komponentami, wyświetlone pozycje
          zależą od uprawnień
        </li>
        <li>
          Dostęp do instrukcji, listy zmian i możliwość wylogowania, w
          przyszłości również np. konfiguracja itd.
        </li>
        <li>
          Możliwość wysłania szybkiej wiadomości na mój adres - w celu
          zgłoszenia błędu lub dowolnym innym
        </li>
      </ol>
      <hr />
      <div>
        <h3>Praca z programem</h3>
        <Row className="mt-2">
          <Col>
            <h4 id="modals">Modale</h4>
            <p>
              Modal to okienko wyskakujące na tle głównej treści strony. Może
              zawierać dodatkowe informacje, komunikat błędu, formularz itd.
            </p>
            <p>
              Standardem jest, że kliknięcie w wyszarzone tło poza modalem
              powoduje zamknięcie okienka. Jeśli był formularz to <u>porzuca</u>{" "}
              wprowadzone zmiany. <br />
              Jednak w clipERPie wyłączyłem to działanie, żeby uniknąć
              przypadkowej utraty danych {"->"} każdy modal wymaga kliknięcia
              odpowiedniego przycisku, żeby go zamknąć.
            </p>
            <p>
              Jeśli dla kogoś taka konfiguracja byłaby uciążliwa i wolałby
              standardowy sposób zamykania (jak ja) to proszę o informację. Mogę
              to modyfikować dla konkretnych użytkowników.
            </p>
          </Col>
          <Col>
            <p>
              <b>Przykłady modali w clipERP</b>
            </p>
            <img src={modals} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="bg-secondarySuperLight"
              // v 1.15.0
            >
              <p>
                Od wersji 1.15.0 istnieje możliwość przesuwania modali po
                złapaniu za nagłówek. Dodatkowo można przewijać zawartość{" "}
                <u>pod modalem</u> korzystając z kółka myszy lub paska
                przewijania (jeśli modal jest przesunięty poza ekran pojawiają
                się dwa paski, do przewijania zawartości pod modalem służy
                zewnętrzny, jak na animacji poniżej).
              </p>
              <br />
              <img src={modalsScrolling} alt="" style={{ maxWidth: "100%" }} />
            </div>
          </Col>
        </Row>
        <hr />
        <h4>Porady różne</h4>
        <Row className="mt-2">
          <Col>
            <h5>Zaznacz i przeciągnij (skopiuj)</h5>
            <p>
              (ten mechanizm działa też na innych stronach, w wordze, excelu
              itd.) Podwójne kliknięcie ciągu znaków (słowa, liczby, numeru NIP)
              zaznacza go. Po zaznaczeniu można go skopiować do schowka, albo
              przeciągnąć jak na animacji z boku.
            </p>
            <p>
              To zaznaczenie działa na ciągi znaków rozdzielone spacjami,
              myślnikami itd (np. w 113-00-10-397 dwu-klik na "113" zaznaczy
              tylko "113"). Aby zaznaczyć więcej trzeba szybko kliknąć trzy
              razy.
            </p>
            <p>
              <b>Możecie popróbować na tym tekście ;).</b>
            </p>
            <h5>Powiększenie obrazka</h5>
            <p>
              Jeśli jakaś grafika jest za mała to można ją otworzyć w nowej
              karcie, powiększoną do oryginalnych rozmiarów: PPM (prawy przycisk
              myszy) na grafice {"->"} otwórz w nowej karcie
            </p>
          </Col>
          <Col>
            <img src={selectAndDrag} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default ManualGeneralSection
