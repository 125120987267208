import React, { useState, useMemo } from "react"
import { Row, Col, Button, Form, Badge } from "react-bootstrap"
import { useForm } from "react-hook-form"
import Select from "react-select"
import _ from "lodash"

const TodosListForm = ({ setFilters, user, formConfigs, board }) => {
  // by default only user is selected
  const [employeesSelected, setEmployeesSelected] = useState([
    { value: user._id, label: user.fullName },
  ])

  // get tags array for Select from configs:

  const tagsSelectOptions = formConfigs.todosTags.map((tag) => ({
    value: tag,
    label: tag,
  }))

  const [tagsSelected, setTagsSelected] = useState(tagsSelectOptions)

  const statesSelectOptions = formConfigs.todosStates.map((state) => ({
    value: state,
    label: state,
  }))
  // remove not done-states from default states selected:
  const [statesSelected, setStatesSelected] = useState(
    getDoneStates(statesSelectOptions)
  )

  const [selectErr, setSelectErr] = useState()
  const { register, handleSubmit } = useForm({
    defaultValues: { creator: "any" },
  })

  const employeeSelectOptions = useMemo(() => {
    const options = [
      { value: user._id, label: user.fullName },
      {
        label: "Pracownicy",
        options: [
          ...user.subordinates.map((subordinate) => ({
            value: subordinate._id,
            label: subordinate.fullName,
          })),
        ],
      },

      {
        label: "Przełożeni",
        options: [
          ...user.superior.map((superior) => ({
            value: superior._id,
            label: superior.fullName,
          })),
        ],
      },
    ]

    if (user.canAddBoardTodos === true)
      options.push({
        label: "Zarząd",
        options: [
          ...board.map((boardMember) => ({
            value: boardMember._id,
            label: boardMember.fullName,
          })),
        ],
      })

    return options
  }, [user, board])

  const onSubmit = (formData) => {
    setSelectErr("")
    if (!employeesSelected.length) return setSelectErr("employee")
    if (!tagsSelected.length) return setSelectErr("tags")
    if (!statesSelected.length) return setSelectErr("states")
    setFilters({
      ...formData,
      employee: employeesSelected.map((empl) => empl.value),
      tags: tagsSelected.map((tag) => tag.value),
      states: statesSelected.map((state) => state.value),
    })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mt-2 mx-2">
      <Row>
        <Col>
          <Row>
            <Col>Autor</Col>
          </Row>
          <Row>
            <Form.Control
              {...register("creator")}
              as="select"
              className="w-auto"
              autoComplete="chrome-off"
            >
              <option value={user._id}>ja</option>
              <option value={"any"}>dowolny</option>
            </Form.Control>
          </Row>
        </Col>
        <Col>
          {selectErr === "employee" ? (
            <Badge pill variant="warning">
              wybierz pracownika
            </Badge>
          ) : null}
          <Row className="mb-1">Przypisany</Row>
          <Row>
            <Select
              options={employeeSelectOptions}
              isMulti={true}
              placeholder="wybierz"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              // set default for user:
              defaultValue={employeeSelectOptions[0]}
              onChange={setEmployeesSelected}
            />
          </Row>
        </Col>
        <Col>
          {selectErr === "tags" ? (
            <Badge pill variant="warning">
              wybierz min. 1 tag
            </Badge>
          ) : null}
          <Row>Tagi</Row>
          <Row>
            <Select
              options={tagsSelectOptions}
              isMulti={true}
              placeholder={renderTagsPlaceholder(
                tagsSelectOptions,
                tagsSelected
              )}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              // set default for user:
              defaultValue={tagsSelectOptions}
              onChange={setTagsSelected}
              controlShouldRenderValue={false}
            />
          </Row>
        </Col>
        <Col>
          {selectErr === "states" ? (
            <Badge pill variant="warning">
              wybierz min. 1 stan
            </Badge>
          ) : null}
          <Row>Stan zadania</Row>
          <Row>
            <Select
              options={statesSelectOptions}
              isMulti={true}
              placeholder={renderSelectStatesPlaceholder(
                statesSelectOptions,
                statesSelected
              )}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              // set default for user:
              defaultValue={statesSelected}
              onChange={setStatesSelected}
              controlShouldRenderValue={false}
            />
          </Row>
        </Col>

        <Col xs={{ span: 2 }}>
          <Row>
            <Button type="submit" className="ml-auto mt-3 mr-3">
              Wyszukaj
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

const renderSelectStatesPlaceholder = (allOptions, selected) => {
  const compareFunc = (a, b) => (a.value < b.value ? -1 : 1)

  if (allOptions.length === selected.length) return "Wybrano wszystkie"
  else if (
    // check if all (and only) done-states are selected (sorting is neccesary fo isEqual to work)
    _.isEqualWith(
      [...selected].sort(compareFunc),
      [...getDoneStates(allOptions)].sort(compareFunc),
      _.isEqual
    )
  )
    return "wybrano oczekujące"
  else return `Wybrano: ${selected.length}`
}

const getDoneStates = (allStates) => {
  return _.differenceWith(
    allStates,
    [
      { value: "odrzucone", label: "odrzucone" },
      { value: "zrobione", label: "zrobione" },
    ],
    _.isEqual
  )
}

const renderTagsPlaceholder = (tagsSelectOptions, tagsSelected) => {
  if (tagsSelectOptions.length === tagsSelected.length) return "wszystkie"
  else if (tagsSelected.length === 1) return tagsSelected[0].label
  else return `wybrano: ${tagsSelected.length}`
}

/*
zlecający: ja/dowolny
przypisany: ja/pracownik (tylko jeśli ma jakichkolwiek - pojawia się select)/ pracownicy (jeśli ma jakichś)/wszystkie (tylko jeśli ma pracowników - "chce zobaczyć zadania swoje i moich podwładnych")
tagi - multiselect
stan

    */
export default TodosListForm
