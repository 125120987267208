import { roundToHundredth } from "../utils/formats"

const customValidation = async (data, netValue) => {
  try {
    // VALIDATION:
    const customErrors = []
    if (netValue === 0) {
      customErrors.push("suma nie może być równa 0")
    }
    if (data.realizationDetails.branch === "plug" || data.seller === "plug") {
      customErrors.push("wybierz oddział i firmę sprzedającą")
    }
    if (
      data.paymentDetails.method === "plug" ||
      data.paymentDetails.saleDocument === "plug" ||
      data.paymentDetails.deadline === "plug" ||
      data.realizationDetails.ourDelivery === "plug"
    ) {
      customErrors.push(
        "Podaj szczegóły płatności: formę, rodzaj dokumentu i termin"
      )
    }
    if (data.realizationDetails.ourDelivery === "plug") {
      customErrors.push("Wybierz rodzaj dostawy")
    }

    if (data.customerType === "") {
      customErrors.push("wybierz typ klienta")
    }

    return customErrors
  } catch (err) {
    alert(err)
  }
}
const dataSanitize = async (data) => {
  try {
    if (data.customerType === "company") {
      data.companyData.vatNumber = data.companyData.vatNumber.replace(
        /\s|-/g, //all whitespaces and "-"
        ""
      )
      data.companyData.postal = data.companyData.postal.replace(
        /\s|-/g, //all whitespaces and "-"
        ""
      )
    }
    if (data.customerType === "privat") {
      data.privatData.postal = data.privatData.postal.replace(
        /\s|-/g, //all whitespaces and "-"
        ""
      )
    }
    if (
      data.realizationDetails.ourDelivery === "true" ||
      data.realizationDetails.ourDelivery === true
    ) {
      data.realizationDetails.deliveryDetails.deliveryAddress.postal = data.realizationDetails.deliveryDetails.deliveryAddress.postal.replace(
        /\s|-/g, //all whitespaces and "-"
        ""
      )
      data.realizationDetails.deliveryDetails.deliveryAddress.phone = data.realizationDetails.deliveryDetails.deliveryAddress.phone.replace(
        /\s|-/g, //all whitespaces and "-"
        ""
      )
    }

    //parsing and rounding number elements in prices array
    const pricesParsed = data.orderDetails.prices.map((price) => {
      return {
        ...price,
        quantity: roundToHundredth(parseFloat(price.quantity)),
        netPrice: roundToHundredth(parseFloat(price.netPrice)),
        grossPrice: roundToHundredth(parseFloat(price.grossPrice)),
      }
    })
    data.orderDetails.prices = pricesParsed

    data.orderDetails.prices = data.orderDetails.prices.filter((price) => {
      return price.quantity !== 0
    })

    // parse delivery prices

    if (data.realizationDetails.ourDelivery === "true") {
      data.realizationDetails.deliveryDetails.netPrice = parseFloat(
        data.realizationDetails.deliveryDetails.netPrice
      )
      data.realizationDetails.deliveryDetails.grossPrice = parseFloat(
        data.realizationDetails.deliveryDetails.grossPrice
      )
    }
    // changing payment deadline if "other" choosen: (other input is registered as .deadlineString)
    // if (
    //   data.paymentDetails.deadline === "inny - data" ||
    //   data.paymentDetails.deadline === "inny - liczba dni"
    // ) {
    //   data.paymentDetails.deadline = data.paymentDetails.deadlineString
    // }

    return data
  } catch (err) {
    alert(err)
  }
}

const handleStateChange = async (
  transactionId,
  newState,
  setModalData,
  redirectFunc = false // handles redirecting after fetching
) => {
  try {
    const res = await fetch(`/transactions/changeState/${transactionId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ state: newState }),
    })

    if (res.status === 403)
      return setModalData({ show: true, type: "info", body: "Brak uprawnień" })
    else if (res.status !== 200) throw res
    else
      return setModalData({
        show: true,
        type: "info",
        id: "success",
        body: `Zmieniono stan transakcji na ${newState}`,
        onHide: redirectFunc,
      })
  } catch (err) {
    console.log(err)
    setModalData({ show: true, type: "alert", body: "Błąd pobierania danych" })
  }
}

export { customValidation, dataSanitize, handleStateChange }
