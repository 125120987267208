import React from "react"
import { useForm } from "react-hook-form"
import { Form, Button, Row, Col } from "react-bootstrap"
import EquipmentEditModal from "../Edit/Modal"

const EquipmentTableForm = ({
  eqpTypes,
  setEquipment,
  setIsLoading,
  setModalData,
  configs,
}) => {
  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: { type: "Toaleta", isTemplate: "true" },
  })

  const fetchEqp = async (formData) => {
    try {
      const res = await fetch(`/equipment/getFiltered?getUsed=true`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
      if (res.status !== 200) {
        console.log(res)
        console.log(await res.text())
        return alert("błąd pobierania danych")
      }

      const resJSON = await res.json()

      // for each eqp check if is used on any location and if so -> check if got delivered
      const eqpReady = resJSON.map((eqp) => {
        // eqp not used on any location:
        if (!eqp.location?.length) return { ...eqp, currLoc: null }

        // if use check if it is set as inPlace (in location.services.equipment)
        let srvFound = false // when no service found -> throw Error (if it has location it must be on any service)
        let isInPlace = false

        // go through all equipment arrays for all services and find 'isInPlace'
        eqp.location[0].services.forEach((srv) => {
          srv.equipment.forEach((srvEqp) => {
            if (srvEqp.ref === eqp._id) {
              srvFound = true
              isInPlace = srvEqp.inPlace
            }
          })
        })

        if (!srvFound)
          throw new Error(
            `Niespójne dane: nie znalazłem usługi dla sprzętu. Zgłoszenie: eqpId: ${eqp._id}, loc_id:${eqp.location[0]._id}`
          )

        return {
          ...eqp,
          currLoc: {
            _id: eqp.location[0]._id,
            name: eqp.location[0].name,
            customer: {
              _id: eqp.location[0].customer._id,
              shortName: eqp.location[0].customer.shortName,
            },
            inPlace: isInPlace,
          },
        }
      })

      setEquipment(eqpReady)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderSelects = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))
    // most of equipment props are one-choose-only
    // this func creates select for each of such field

    if (eqpSelected) {
      // firts create map of avaliable fields:
      const keysMap = [
        { name: "subtype", plName: "Podtyp" },
        { name: "char", plName: "Charakterystyka" },
        { name: "color", plName: "Kolor" },
        { name: "category", plName: "Kategoria" },
      ]

      //  create select field for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name]?.length) return null
        else {
          return (
            <Col>
              <Form.Check
                {...register(`${key.name}.apply`)}
                type="checkbox"
                label={key.plName}
                id={`${key.name}.apply`}
                onClick={(e) => {
                  if (!e.target.checked) setValue(`${key.name}.value`, null)
                }}
              />

              <Form.Control
                key={`${eqpSelected.type}-${key.name}-select`}
                {...register(`${key.name}.value`)}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
                disabled={!watch(`${key.name}.apply`)}
                defaultValue="null"
              >
                <option value="null" hidden></option>
                {eqpSelected[key.name].map((el) => {
                  // dont show "dowolny/a" - it is handled by checkbox above when template or don't apply in other case
                  if (el === "bez znaczenia") return null
                  else
                    return (
                      <option
                        key={`${eqpSelected.type}-${key.name}-${el}-select`}
                        value={el}
                      >
                        {el}
                      </option>
                    )
                })}
              </Form.Control>
            </Col>
          )
        }
      })

      return formArr.filter((el) => el)
    } else return null
  }

  const renderCheckboxes = () => {
    const eqpSelected = eqpTypes.find((eqp) => eqp.type === watch("type"))

    if (eqpSelected) {
      // most of equipment props are multiple-choose

      // firts create map of avaliable fields:
      const keysMap = [{ name: "mods", plName: "Wyposażenie" }]

      //  create checkboxes for each non-empty key:
      const formArr = keysMap.map((key) => {
        // if no values for selected type -> return null
        if (!eqpSelected[key.name].length) return null
        else
          return (
            <Col>
              {key.plName}

              {eqpSelected[key.name].map((el) => (
                <Form.Check
                  inline
                  key={`${key.name}-${el}-checkbox`}
                  {...register(`mods`)}
                  type="checkbox"
                  value={el}
                  label={el}
                  id={el}
                />
              ))}
            </Col>
          )
      })

      return formArr.filter((el) => el)
    } else return null
  }
  return (
    <Form onSubmit={handleSubmit(fetchEqp)}>
      <>
        <Form.Row>
          <Form.Check
            {...register("isTemplate")}
            type="radio"
            value={true}
            label="Templatka"
            id="isTemplate-true"
            inline
            className="mr-auto"
          />
          <Form.Check
            {...register("isTemplate")}
            type="radio"
            value={false}
            label="Realny sprzęt"
            id="isTemplate-false"
            inline
            className="mr-auto"
          />
          {watch("isTemplate") === "false" && (
            <Col>
              <Form.Label>
                Numer:
                <Form.Control
                  {...register("number")}
                  as="input"
                  type="text"
                  className=""
                  autoComplete="chrome-off"
                />
              </Form.Label>
            </Col>
          )}
          <Col>
            <Form.Label>
              Typ:
              <Form.Control
                {...register("type")}
                as="select"
                type=""
                className=""
                autoComplete="chrome-off"
              >
                {eqpTypes.map((eqp) => {
                  return (
                    <option key={`selectEqpType-${eqp.type}`}>
                      {eqp.type}
                    </option>
                  )
                })}
              </Form.Control>
            </Form.Label>
          </Col>
          {watch("isTemplate") === "true" && (
            <Col className="mt-4">
              <Form.Check
                {...register("getAll")}
                type="checkbox"
                label="Pobierz wszystkie"
                id="getAll"
              />
            </Col>
          )}
        </Form.Row>
        <Form.Row>{renderSelects()}</Form.Row>
        <Form.Row>{renderCheckboxes()}</Form.Row>
        {/* {fetchError && <Alert variant="danger">{fetchError}</Alert>} */}
        <Row>
          <Col>
            {" "}
            <Button
              variant="secondary"
              onClick={() =>
                setModalData({
                  show: true,
                  type: "info",
                  xl: true,
                  header: "Szczegóły sprzętu",
                  body: (
                    <EquipmentEditModal
                      eqp="new"
                      setModalData={setModalData}
                      configs={configs}
                    />
                  ),
                })
              }
            >
              Dodaj
            </Button>
          </Col>
          <Button
            className="ml-auto mr-5 mb-3"
            onClick={handleSubmit(fetchEqp)}
          >
            Pobierz
          </Button>
        </Row>
      </>
    </Form>
  )
}
export default EquipmentTableForm
