import {
  endOfDay,
  isAfter,
  isWithinInterval,
  startOfDay,
  addDays,
} from "date-fns"
import _ from "lodash"

const prepareDeliveriesArr = (jobs) => {
  return jobs
    .map((job) => {
      if (job.type === "dostarczenie") {
        const newJob = _.cloneDeep(job)
        // remove all short term services from clonned job:
        newJob.services = newJob.services.filter(
          (newJobSrv) =>
            newJobSrv.serviceRef.chargeType !== "krótki termin" &&
            newJobSrv.serviceRef.chargeType !== "ryczałt impreza"
        )

        if (newJob.services.length) return newJob
        else return null
      } else return null
    })
    .filter((el) => el)
}

const getSrvQty = (job) => {
  let qtyToAdd = 0
  job.services.forEach((srv) => {
    // if there is eqp, sum up its quantity:
    if (srv.eqp?.length)
      qtyToAdd += srv.eqp.reduce((eqpAcc, eqpRed) => eqpAcc + eqpRed.qty, 0)
    else {
      const locSrv = job.location.services.find(
        (locationSrv) => locationSrv._id === srv.locationServiceRef
      )

      const currParam = locSrv.params.find((prm) => {
        return isWithinInterval(new Date(job.date), {
          start: new Date(prm.startDate),
          end: prm.hasEndDate
            ? new Date(prm.endDate)
            : // just add 3 yrs:
              addDays(new Date(prm.startDate), 3 * 365),
        })
      })

      if (!currParam)
        throw new Error(
          `Brak aktualnego parametru dla zadania na lokalizacji ${job.location.name}, usługa ${srv.serviceRef?.name}`
        )
      // when no param qty found - return 0 (yep, it shouldn't happen)
      qtyToAdd += currParam?.qty || 0
    }
  })

  return qtyToAdd
}

/**
 * calculates cash to collect from customer
 * @param {object} job job object with populated services and location.services
 * @returns {number} total cash to collect from all services of this job
 */
const countCash = (job) => {
  let cash = 0
  job.services.forEach((jobSrv) => {
    if (
      jobSrv.serviceRef.chargeType !== "za serwis" &&
      jobSrv.serviceRef.chargeType !== "za sztukę"
    )
      return null
    const locSrv = job.location.services.find(
      (locSrv) => locSrv._id === jobSrv.locationServiceRef
    )
    if (!locSrv)
      throw new Error(
        `Błąd: nie można określić ceny usługi ${jobSrv.serviceRef.name} dla zadania dla lokalizacji ${job.location.name}`
      )
    if (jobSrv.eqp.length) {
      const eqpQty = jobSrv.eqp.reduce(
        (acc, eqp) => acc + Number.parseInt(eqp.qty),
        0
      )

      cash += _.round(eqpQty * locSrv.netPrice * (1 + locSrv.vat / 100), 2)
    } else {
      // get actual param:
      const actParam = locSrv.params.find((prm) => {
        if (prm.endDate)
          return isWithinInterval(new Date(job.date), {
            start: startOfDay(new Date(prm.startDate)),
            end: endOfDay(new Date(prm.endDate)),
          })
        else if (
          isAfter(new Date(job.date), startOfDay(new Date(prm.startDate)))
        )
          return true
        else return false
      })
      if (!actParam)
        throw new Error(
          `Brak aktywnego parametru dla usługi ${locSrv.locSrvNo || "?"}. ${
            locSrv.name
          }, klient ${job.location.customer.shortName}, lokalizacja ${
            job.location.name
          }`
        )
      cash += _.round(
        actParam.qty * locSrv.netPrice * (1 + locSrv.vat / 100),
        2
      )
    }
  })
  return cash
}

export { prepareDeliveriesArr, getSrvQty, countCash }
