import React, { useContext, useState } from "react"
import { Container, Form } from "react-bootstrap"
import { format } from "date-fns"

import UserContext from "../../contexts/userContext"
import MyModal from "../../utils/Modals"
import CustomersFetch from "../../Customers/Fetch"
import { getCustomerAddress } from "./utils"
import EditInvoiceFetch from "./Fetch"

const NewInvoice = () => {
  const user = useContext(UserContext)
  const [modalData, setModalData] = useState({ show: false })
  const handleCustomerChoose = (customer) => {
    const branchSelected = document.getElementById("branchSelect").value

    // check if customer is assigned to selected branch:
    if (!customer.branches.find((branch) => branch === branchSelected))
      return setModalData({
        show: true,
        type: "alert",
        body: "Klient nie jest przypisany do wybranego oddziału. Poproś kierownika o modyfikację klienta",
      })

    const billingAddress = getCustomerAddress("główny", customer.addresses)
    const deliveryAddress =
      getCustomerAddress("główny", customer.addresses) || billingAddress

    const newInvoice = {
      // I have to fit into Sage invoice data structure:
      user: user._id,
      clientResult: {
        _id: customer._id,
        clientBillingAddress: {
          _id: billingAddress._id,
          clientCity: billingAddress.city,
          clientCountryId: "pl",
          clientCountryName: "Polska",
          clientFirm: customer.isPrivat ? "" : customer.name,
          clientFirstName: customer.isPrivat ? customer.name : "",
          clientLastName: customer.isPrivat ? customer.surname : "",
          clientNip: customer.vatNumber,
          clientStreet: billingAddress.street,
          clientZipCode: billingAddress.postal,
        },
        clientDeliveryAddress: {
          _id: deliveryAddress._id,
          clientDeliveryAddressCity: deliveryAddress.city,
          clientDeliveryAddressCountryId: "pl",
          clientDeliveryAddressCountryName: "Polska",
          clientDeliveryAddressFirm: deliveryAddress.name,
          clientDeliveryAddressStreet: deliveryAddress.street,
          clientDeliveryAddressZipCode: deliveryAddress.postal,
        },
        clientLastModificationDate: new Date(
          customer.history[customer.history.length - 1].date
        ),
        ref: customer._id,
      },
      InvoiceDetails: {
        InvoiceDate: new Date(document.getElementById("invDate").value),
        payments: {
          InvoiceBaseCurrency: {
            InvoiceDeliveryCost: 0,
            InvoiceDeliveryVat: 0.23,
            InvoiceProductsCost: 0,
            billingCurrency: "PLN",
          },
          InvoiceCurrency: {
            InvoiceCurrencyValue: 1,
            currencyId: "PLN",
            InvoiceDeliveryCost: 0,
            InvoiceDeliveryVat: 0.23,
            InvoiceProductsCost: 0,
            billingCurrency: "PLN",
            billingCurrencyRate: 0,
          },
          InvoicePaymentDays: customer.defaultDeadline,
          InvoicePaymentType: customer.defaultPaymentMethod,
          InvoiceRebatePercent: 0,
          InvoiceVatExists: "y",
          InvoiceWorthCalculateType: "net",
        },
        productsResults: [],
      },

      InvoiceBridgeNote: "",
      InvoiceSerialNumber: "sFV",
      InvoiceType: "FV",
      branch: branchSelected.slice(0, 3).toUpperCase(),
      exportDates: [],
    }

    setModalData({
      show: true,
      type: "info",
      xl: true,

      body: (
        <EditInvoiceFetch
          setModalData={setModalData}
          invoice={newInvoice}
          refresh={() => {
            window.open("/Invoicing/List", "_self")
          }}
          isNew={true}
        />
      ),
    })
  }

  return (
    <Container id="NewInvoice">
      Wybierz oddział:
      <Form.Control
        as="select"
        autoComplete="chrome-off"
        defaultValue={user.defaultBranch || user.allowedBranches[0]}
        id="branchSelect"
      >
        {user.allowedBranches.map((branch) => (
          <option key={`branchOption-${branch}`} value={branch}>
            {branch}
          </option>
        ))}
      </Form.Control>
      Data faktury:
      <Form.Control
        as="input"
        type="date"
        className=""
        autoComplete="chrome-off"
        id="invDate"
        defaultValue={format(new Date(), "yyyy-MM-dd")}
      />
      <p>Wyszukaj klienta:</p>
      <CustomersFetch embedded={true} setCustomer={handleCustomerChoose} />
      <MyModal modalData={modalData} setModalData={setModalData} />
    </Container>
  )
}
export default NewInvoice
