import { format } from "date-fns"
import { Container, Table, Button } from "react-bootstrap"
import { ImNewTab } from "react-icons/im"
import { Link } from "react-router-dom"
import { hundrethComma } from "../../utils/formats"

const InvStatsRender = ({ data }) => {
  if (!data) return "brak danych"

  return (
    <Container>
      <h2>Statystyki faktur</h2>
      <InvStatsTable invStats={data.invStats} />
      <ZeroStatsTable zeroInvoices={data.zeroInvoices} />
    </Container>
  )
}

const InvStatsTable = ({ invStats }) => {
  if (!invStats || !invStats.length) return "brak danych"

  return (
    <Table>
      <thead>
        <tr>
          <th>oddział</th>
          <th>liczba fv</th>
          <th>suma brutto</th>
        </tr>
      </thead>
      <tbody>
        {invStats.map((branch) => {
          return (
            <tr key={`invStatsTableRow-${branch.name}`}>
              <td>{branch.name}</td>
              <td>{branch.qty}</td>
              <td>{hundrethComma(branch.grossTotal)}</td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <b>SUMA</b>
          </td>
          <td>{invStats.reduce((acc, branch) => branch.qty + acc, 0)}</td>
          <td>
            {hundrethComma(
              invStats.reduce((acc, branch) => branch.grossTotal + acc, 0)
            )}
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}
const ZeroStatsTable = ({ zeroInvoices }) => {
  if (!zeroInvoices || !zeroInvoices.length) return null

  return (
    <>
      <h2>Faktury zerowe</h2>
      <Table>
        <thead>
          <tr>
            <th>klient</th>
            <th colSpan="2">data wygenerowania</th>
          </tr>
        </thead>
        <tbody>
          {zeroInvoices.map((invoice) => {
            return (
              <tr key={`zeroInovicesRow-${invoice._id}`}>
                <td>
                  <Link
                    target="_blank"
                    to={`/CustomersDetails/${invoice.clientResultRef._id}`}
                  >
                    {invoice.clientResultRef.shortName ||
                      invoice.clientResultRef.name}{" "}
                    <ImNewTab />
                  </Link>
                </td>
                <td>
                  {format(new Date(invoice.createdAt), "yyyy-MM-dd hh:mm")}
                </td>
                <td>
                  <Link
                    target="_blank"
                    to={`/Invoicing/List?_id=${
                      invoice.clientResultRef._id
                    }&name=${invoice.clientResultRef.shortName || ""}`}
                  >
                    <Button size="sm">
                      Faktury <ImNewTab />
                    </Button>
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export { InvStatsRender }
